/* istanbul ignore file */
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import AccountInfo from './account-info/account-info';
import DownloadHistory from './download-history/download-history';
//TODO change name of the component to Delivery
import SavedSearches from './saved-searches/saved-searches';
import SubscribedContent from './subscribed-content/subscribed-content';
import Subscriptions from './subscriptions/subscriptions';
import Metadata from '../../orders/tabs/metadata';
import MyOrders from '../../orders/tabs/my-orders';
import MySavedCarts from '../../orders/tabs/my-saved-carts';


export default {
    AccountInfo: AccountInfo,
    DownloadHistory: DownloadHistory,
    MyOrders: MyOrders,
    MySavedCarts: MySavedCarts,
    SavedSearches: SavedSearches,
    SubscribedContent: SubscribedContent,
    Subscriptions: Subscriptions,
    Metadata: Metadata
};
