/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {STATIONS_SUB_TYPES} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Grid} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {default as TitleMenuLoader} from './title-menu-loader.gif';
import {MessagesContext} from '../../messages/messages-context';
import SessionStore from '../../session/session-store';
import {SerieNavigation} from '../serie-navigation/';
import {SerieNavigationActions} from '../serie-navigation/serie-navigation-actions';
import SerieNavigationStore from '../serie-navigation/serie-navigation-store';
import {TitleConstants} from '../title-actions';
import TitleStore from '../title-store';

class NavAsset extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static calculateState() {
        return {
            assetsLoaded: TitleStore.getState().get('assetsLoaded'),
            audio: TitleStore.getState().getIn(['assets', 'audio']),
            documents: TitleStore.getState().getIn(['assets', 'documents']),
            /* The reason behind this has[AssetName] here is that we get the assetType.state on singular and the service responds with hasAssetS in plural
            */
            hasAudio: TitleStore.getState().getIn(['title', 'hasAudio']),
            hasDocuments: TitleStore.getState().getIn(['title', 'hasDocument']),
            hasImages: TitleStore.getState().getIn(['title', 'hasImage']),
            hasMerchandise: TitleStore.getState().getIn(['title', 'hasMerchandise']),
            hasScripts: TitleStore.getState().getIn(['title', 'hasScript']),
            hasVideos: TitleStore.getState().getIn(['title', 'hasVideo']),
            images: TitleStore.getState().getIn(['assets', 'images']),
            merchandise: TitleStore.getState().getIn(['assets', 'merchandise']),
            scripts: TitleStore.getState().getIn(['assets', 'scripts']),
            serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
            title: TitleStore.getState().get('title'),
            videos: TitleStore.getState().getIn(['assets', 'videos']),
        };
    }
    static getStores() {
        return [SerieNavigationStore, TitleStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        this.initNav(this.props.title);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.location.pathname !== nextProps.location.pathname ||
            this.props.title.get('categoryGroup') !== nextProps.title.get('categoryGroup')) {
            return true;
        }

        if (
            this.state.assetsLoaded !== nextState.assetsLoaded ||
            this.state.audio !== nextState.audio ||
            this.state.documents !== nextState.documents ||
            this.state.images !== nextState.images ||
            this.state.merchandise !== nextState.merchandise ||
            this.state.videos !== nextState.videos ||
            this.state.scripts !== nextState.scripts ||
            this.state.serieNavigation !== nextState.serieNavigation
        ) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    initNav(title) {
        switch (title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
            SerieNavigationActions.getMiniSerie(title.get('id'), this.state.serieNavigation);
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            SerieNavigationActions.get(title.get('id'), this.state.serieNavigation);
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            SerieNavigationActions.get(title.get('id'), this.state.serieNavigation);
            break;
        }
    }

    /**
     * Returns "active" if current path (location.pathname)
     * matches the provided link.
     */
    isActive(links, linkName) {
        if (this.props.location.pathname === links.get(linkName)) {
            return 'active';
        }

        return;
    }

    render() {
        if (!this.props.title || !this.props.title.get('id')) {
            return null;
        }

        let titleLink = `/titles/${this.props.title.get('id')}`;
        let links = Immutable.Map({
            audio: `${titleLink}/audio`,
            moments: `${titleLink}/moments`,
            documents: `${titleLink}/documents`,
            stations: `${titleLink}/stations`,
            images: `${titleLink}/images`,
            info: titleLink,
            merchandise: `${titleLink}/merchandise`,
            videos: `${titleLink}/videos`,
            scripts: `${titleLink}/scripts`
        });

        let serieNavigation;
        let titleNav = [];
        let isMomentsPage = this.props.location.pathname === links.get('moments');
        let isInfoPage = this.props.location.pathname === links.get('info');
        const isLoadedForNonInfoPage = !isInfoPage && !isMomentsPage && !this.state.assetsLoaded;
        const isAssetsAvailable = (assetType) => this.state[assetType.state].size || isLoadedForNonInfoPage;
        const hasPropsAssetsAvailable = (assetType) => this.state[`has${assetType.state.charAt(0).toUpperCase()}${assetType.state.slice(1)}`];
        [{
            predicate: () => this.state.videos.size > 0,
            state: 'videos',
            hidden: () => {
                return false;
            }
        }, {
            predicate: () => {
                const perms = SessionStore.PERMISSIONS;
                const hasPerms = SessionStore.canUser(perms.TITLE.CLIPS.CREATE) && SessionStore.canUser(perms.CART.VIDEOS.ADD);

                if (this.state.title.get('category') === TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id ||
                this.state.title.get('category') === TitleConstants.TITLE_TYPES.TALK_SHOW.id) {
                    return hasPerms;
                } else {
                    return (hasPerms && this.state.assetsLoaded) || isMomentsPage;
                }
            },
            state: 'moments',
            hidden: () => {
                return SessionStore.isMultipleTitles() || SessionStore.isSingleTitle();
            },
        }, {
            state: 'images',
            hidden: () => {
                return SessionStore.isMultipleTitles() || SessionStore.isSingleTitle();
            },
        }, {
            state: 'documents',
            hidden: () => {
                return SessionStore.isMultipleTitles() || SessionStore.isSingleTitle();
            },
        }, {
            predicate: () => {
                const perms = SessionStore.PERMISSIONS;
                const hasPerms = SessionStore.canUser(perms.ASSETS.DOCUMENTS.STATION_DOCUMENTS);

                const assetsList = this.state.documents.filter(asset => STATIONS_SUB_TYPES.includes(asset.get('contentType')));

                return hasPerms && (assetsList.size > 0);
            },
            state: 'stations',
            hidden: () => {
                // STUDIO-7711 we want the stations tab to appear for programatic home pages (ignore homepage contentType)
                return false;
            },
        }, {
            state: 'audio',
            hidden: () => {
                return SessionStore.isMultipleTitles() || SessionStore.isSingleTitle();
            },
        }, {
            state: 'scripts',
            hidden: () => {
                return SessionStore.isMultipleTitles() || SessionStore.isSingleTitle();
            },
        }, {
            state: 'merchandise',
            hidden: () => {
                return SessionStore.isMultipleTitles() || SessionStore.isSingleTitle();
            },
        }].forEach((assetType, i) => {
            let predicate;
            if (this.state.title.get('category') === TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id ||
                this.state.title.get('category') === TitleConstants.TITLE_TYPES.TALK_SHOW.id) {
                predicate = assetType.predicate || hasPropsAssetsAvailable;
            } else {
                predicate = assetType.predicate || isAssetsAvailable;
            }
            if (predicate(assetType) && !assetType.hidden()) {
                titleNav.push(
                    <li key={i}>
                        <Link className={ClassNames('title-nav-link', this.isActive(links, assetType.state))} to={links.get(assetType.state)}>{this.context.intl.messages[`title.nav.sections.${assetType.state}.title`]}</Link>
                    </li>
                );
            }
        });

        if (!SessionStore.isSingleTitle()) {
            serieNavigation = <SerieNavigation location={this.props.location} serieNavigation={this.state.serieNavigation} titleId={this.props.title.get('id')}/>;
        }

        if ((isInfoPage || isMomentsPage) && (
            !this.state.assetsLoaded
        )) {
            if (this.state.title.get('category') !== TitleConstants.TITLE_TYPES.TALK_SHOW_SEASON.id && this.state.title.get('category') !== TitleConstants.TITLE_TYPES.TALK_SHOW.id) {
                titleNav.push(
                    <li className="padding-top-25" key="loading">
                        <img
                            alt=""
                            role="presentation"
                            src={TitleMenuLoader}
                        />
                    </li>
                );
            }
        }
        return (
            <div className="title-nav">
                <Grid>
                    {serieNavigation}

                    <hr className="visible-xs-block" />

                    <div className="pull-left-sm-up">
                        <Button type="button" block className="title-nav-collapsed navbar-toggle collapsed text-uppercase padding-top-0 padding-left-10 padding-bottom-20 margin-all-0"
                            data-toggle="collapse"
                            data-target="#navbar-secondary"
                            aria-expanded="false"
                            aria-controls="navbar">
                            {this.context.intl.messages['title.nav.sections.title']} <span className="caret" />
                        </Button>
                        <div id="navbar-secondary" className="navbar-collapse collapse" aria-expanded="false">
                            <ul className="nav navbar-nav">
                                <li>
                                    <Link className={ClassNames('title-nav-link', this.isActive(links, 'info'))} to={links.get('info')}>{this.context.intl.messages['title.nav.sections.info.title']}</Link>
                                </li>
                                <>
                                    {titleNav}
                                </>
                            </ul>
                        </div>
                    </div>
                </Grid>
            </div>
        );
    }
}

export default Container.create(NavAsset);
export {NavAsset};
