/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

export default class ContactUsDomestic extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.contact-us-domestic">
                <div className="container padding-y-10 padding-x-20" id="hide-this">
                    <div className="row">
                        <h1>Contact Us - Domestic</h1>
                    </div>
                    <div className="row">
                        <table className="table cart-parent-table">
                            <thead>
                                <tr>
                                    <th scope="col" className="cart-title-column">Department</th>
                                    <th scope="col">Contacts</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="cart-parent-title">
                                        <h3>Affiliate Marketing</h3>

                                    </td>
                                    <td className="cart-parent-assets">
                                        <table className="table table-striped datatable-responsive">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="no-sort">NAME</th>
                                                    <th scope="col" className="no-sort" width="70%">RESPONSIBILITIES</th>
                                                    <th scope="col" className="no-sort">PHONE</th>
                                                    <th scope="col" className="no-sort control icon-column"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><a href="mailto:james.pettit@Warnerbros.com">James Pettit</a></td>
                                                    <td>Big Bang Theory, Mike & Molly, 2 Broke Girls</td>
                                                    <td>818-954-7283</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:courtney.stenberg@warnerbros.com">Courtney Stenberg</a></td>
                                                    <td>2.5 Men, The Middle, Friends, The Closer, POI, R&I, Movie Packages</td>
                                                    <td>818-954-5571</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:Frances.Palladino@ellentv.com">Frances Palladino</a></td>
                                                    <td>Ellen</td>
                                                    <td>818-954-6164</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:Melissa.mckeon@ellentv.com">Melissa McKeon</a></td>
                                                    <td>Ellen</td>
                                                    <td>818-954-6165</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:Joslyn.paul@wbconsultant.com">Joslyn Paul</a></td>
                                                    <td>The Real, Judge Mathis</td>
                                                    <td>818-977-1404</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:wendy.berry@warnerbros.com">Wendy Berry</a></td>
                                                    <td>The Real, Judge Mathis</td>
                                                    <td>818-957-6552</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:Max.Schlesinger@wbconsultant.com">Max Schlesinger</a></td>
                                                    <td>TMZ, TMZ Live</td>
                                                    <td>818-972-8174</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:hayley.perez@wbconsultant.com">Hayley Perez</a></td>
                                                    <td>TMZ, TMZ Live</td>
                                                    <td>818-972-8391</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:Tawny.Gestuvo@wbconsultant.com">Tawny Gestuvo</a></td>
                                                    <td>Crime Watch Daily, Extra</td>
                                                    <td>818-972-0945</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:joseph.rubbinaccio@extratv.com">Joseph Rubbinaccio</a></td>
                                                    <td>Crime Watch Daily, Extra</td>
                                                    <td>818-972-0730</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:kerstin@peoplescourt.com">Kerstin Augur</a></td>
                                                    <td>People&apos;s Court</td>
                                                    <td>203-399-0058</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:paige.draitser@warnerbros.com">Paige Draitser</a></td>
                                                    <td>Co-op</td>
                                                    <td>818-954-3725</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><a href="mailto:ari.pitchenik@warnerbros.com">Ari Pitchenik</a></td>
                                                    <td>Big Bang Theory, Mike & Molly, 2 Broke Girls, 2.5 Men, The Middle, Friends, The Closer, POI, R&I, Ellen, Judge Mathis, The Real, Extra, Crime Watch Daily, Extra, People&apos;s Court, TMZ, TMZ Live, Co-op</td>
                                                    <td>818-954-1850</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </DocumentTitle>


        );
    }
}
