/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Moment from 'moment';
import React, {Component} from 'react';
import {Button, Col, ControlLabel, FormGroup} from 'react-bootstrap';

import AccountUpdate from './account-update';
import DocumentTitle from '../../../common/document-title';
import {FormRow, FormSection} from '../../../form/form';
import HappyFoxModal from '../../../layout/happy-fox-modal';
import {MessagesContext} from '../../../messages/messages-context';
import SessionStore from '../../../session/session-store';
import {AccountActions, AccountConstants} from '../../account-actions';
import AccountStore from '../../account-store';
import Delivery from '../delivery/delivery';
import {DeliveryConstants} from '../delivery/delivery-actions';

class AccountInfo extends Component {
    static calculateState() {
        return {
            account: AccountStore.getState().get('account'),
            activeTab: AccountStore.getState().get('activeTab'),
            updatedAccount: AccountStore.getState().get('updatedAccount'),
        };
    }

    static getStores() {
        return [AccountStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showAssetRequestsModal: false
        }, this.constructor.calculateState());

        this.handleToggleRequestModal = this.handleToggleRequestModal.bind(this);
        this.handleToggleUpdateModal = this.handleToggleUpdateModal.bind(this);
    }

    componentDidMount() {
        AccountActions.setActiveTab(AccountConstants.ACCOUNT.TAB.INFO);
        AccountActions.cloneUser();
    }

    static contextType = MessagesContext;

    handleToggleRequestModal(show) {
        this.setState(() => ({showAssetRequestsModal: show}));
    }

    handleToggleUpdateModal() {
        AccountActions.clearUpdateModal();
        AccountActions.showUpdateModal(true);
    }

    render() {
        let activeTab = this.state.activeTab === AccountConstants.ACCOUNT.TAB.INFO;
        let account = this.state.account;
        let selectedLanguageName;

        DeliveryConstants.EXPORT_LANGUAGES.find((item) => {
            /* istanbul ignore if */
            if (item.id === this.state.account.get('defaultLanguageCode')) {
                selectedLanguageName = item.name;
                return;
            }
        });

        let lastLogin = SessionStore.getState().get('lastLoginTimestamp');
        const lastLoginDate = Moment(lastLogin);
        /* istanbul ignore if */
        if (lastLoginDate.isValid()) {
            lastLogin = lastLoginDate.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(this.context.intl.messages['datetime-full-ampm-tz']);
        }

        return (
            <DocumentTitle
                message="document-titles.my-account.info"
            >
                <div id="Info" className={ClassNames('tab-pane fade', {'in active': activeTab})}>
                    <AccountUpdate
                        account={account}
                    />

                    <h3 className="padding-y-10">
                        <span className="glyphicon glyphicon-info-sign" /> {this.context.intl.messages['account.tab.info.title']}
                    </h3>
                    <FormSection className="row form-wrapper">
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['account.tab.info.firstname']}</ControlLabel>
                                <Col>{account.get('name')}</Col>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['account.tab.info.lastname']}</ControlLabel>
                                <Col>{account.get('lastName')}</Col>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['account.tab.info.email']}</ControlLabel>
                                <Col>{account.get('email')}</Col>
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['account.tab.info.company']}</ControlLabel>
                                <Col>{account.get('company')}</Col>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['account.tab.info.language']}</ControlLabel>
                                <Col>{selectedLanguageName}</Col>
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['account.tab.info.country']}</ControlLabel>
                                <Col>{account.get('country')}</Col>
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['account.tab.info.last-login']}</ControlLabel>
                                <Col>{lastLogin}</Col>
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <Button bsStyle="primary"
                                    className="bg-wb-blue"
                                    onClick={this.handleToggleUpdateModal}>
                                    {this.context.intl.messages['account.tab.info.update']}
                                </Button>
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                    <Delivery onShowAdditionalAccess={this.handleToggleRequestModal} />
                    <HappyFoxModal showAssetRequestsModal={this.state.showAssetRequestsModal} onToggleModal={this.handleToggleRequestModal} />
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(AccountInfo);
export {AccountInfo};
