/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal} from 'react-bootstrap';

import Analytics from '../../analytics';
import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import {MessagesContext} from '../../messages/messages-context';


let icons = {
    [AssetTypeConstants.AUDIO.CONTENT_TYPES.EPISODIC_RADIO_SPOT]: <span className="icons8-radio"/>,
    [AssetTypeConstants.AUDIO.CONTENT_TYPES.GENERIC_RADIO_SPOT]: <span className="icons8-radio-tower"/>,
    [AssetTypeConstants.AUDIO.CONTENT_TYPES.SPOT_REEL]: <span className="icons8-film-reel"/>,
    [AssetTypeConstants.AUDIO.CONTENT_TYPES.THEME_SONG]: <span className="icons8-music-video"/>,
    [AssetTypeConstants.AUDIO.CONTENT_TYPES.SONG]: <span className="icons8-music"/>,
    [AssetTypeConstants.AUDIO.CONTENT_TYPES.INTERVIEW]: <span className="icons8-talk-male"/>,
    [AssetTypeConstants.AUDIO.CONTENT_TYPES.CLIP]: <span className="icons8-cut"/>,
    [AssetTypeConstants.AUDIO.CONTENT_TYPES.AUDIO_STEM]: <span className="icons8-tree-structure"/>
};

export default class AudioModal extends React.Component {
    static get propTypes() {
        return {
            audio: PropTypes.instanceOf(Immutable.Map).isRequired,
            close: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    static contextType = MessagesContext;

    handleLinkClick() {
        Analytics.audioDownloadEvent(this.props.audio.get('assetId'), this.props.audio.get('titleId'));
    }

    onClose() {
        this.props.close();
    }

    render() {
        let icon = icons[this.props.audio.get('contentType')];

        return (
            <Modal show={this.props.show} onHide={this.onClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center text-uppercase">{this.context.intl.messages['title.modal.audio.preview.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <audio className="modal-audio-player" controls="controls" style={{width:'100%'}}>
                        <source src={this.props.audio.get('audioURL', '')} type="audio/mp3"/>
                    </audio>
                    <hr/>
                    <h1 className="h3">{this.props.audio.get('name')}</h1>
                    <p>
                        <strong>
                            {icon} {this.context.intl.messages[`asset.audio.content-types.${this.props.audio.get('contentType')}`]}
                        </strong> {this.props.audio.get('assetComment') ? ` - ${this.props.audio.get('assetComment')}` : ''}
                    </p>
                </Modal.Body>
                <Modal.Footer className="padding-all-20">
                    <a onClick={this.handleLinkClick} href={this.props.audio.get('audioURL')} className="btn btn-primary" download="">
                        <i className="glyphicon glyphicon-download-alt"></i>
                        &nbsp;{this.context.intl.messages['title.modal.audio.preview.download']}
                    </a>
                </Modal.Footer>
            </Modal>
        );
    }

}
