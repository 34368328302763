/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import DocumentTitle from '../../common/document-title';
import Validations from '../../common/validations/validations';
import {FormRow, FormItem} from '../../form/form';
import LandingLayout from '../../layout/landing';
import {MessagesContext} from '../../messages/messages-context';
import {Alert} from '../../notification/alert';
import {AlertsWarnings} from '../../notification/alert-warning';
import {SessionActions} from '../../session/session-actions';
import SessionStore from '../../session/session-store';

export class OneTimeLogin extends Component {
    static calculateState() {
        return {
            login: SessionStore.getState().get('login')
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            showErrors: false
        };
        this.getValidations = this.getValidations.bind(this);
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.update = this.update.bind(this);
    }

    static contextType = MessagesContext;

    getValidations() {
        return {
            email: {
                label: 'one-time-login.form.email.placeholder',
                validations: [Validations.email, Validations.required, Validations.max(250)]
            }
        };
    }

    validate() {
        return Validations.validate(Immutable.fromJS(this.state), this.getValidations());
    }

    onSubmit(event) {
        event.preventDefault();
        let isValidEmail = this.validate();
        if (isValidEmail.length) {
            this.setState({showErrors: true});
        } else {
            SessionActions.sendOneTimeLoginLink(this.state.email, SessionStore.getPartner());
        }

    }

    update(attr, value) {
        this.setState({[attr]: value});
    }

    render() {
        let emailValidations = this.getValidations();
        let warning;
        let isValidEmail = this.validate();
        /* istanbul ignore if */
        if (this.state.showErrors) {
            warning = (<AlertsWarnings validations={isValidEmail} />);
        }

        const inProgress = this.state.login?.get('sendOneTimeLinkInProgress');

        let loginButtonText = this.context.intl.messages['one-time-login.form.button'];
        let loginButtonProgress;
        /* istanbul ignore if */
        if (inProgress) {
            loginButtonText = this.context.intl.messages['one-time-login.form.progress'];
            loginButtonProgress = (
                <i className="far fa-sync-alt fa-spin" />
            );
        }

        return (
            <DocumentTitle
                message="document-titles.one-time-login"
            >
                <LandingLayout>
                    <Col className="box" sm={4} smOffset={4}>
                        <Alert/>
                        {warning}
                        <div>
                            <Row componentClass="header">
                                <Col sm={12}>
                                    <h3>{this.context.intl.messages['one-time-login.title']}</h3>
                                    <p>{this.context.intl.messages['one-time-login.message']}</p>
                                </Col>
                            </Row>
                            <form onSubmit={this.onSubmit}>
                                <FormRow>
                                    <FormItem
                                        attr="email"
                                        model={Immutable.fromJS(this.state)}
                                        placeholder={this.context.intl.messages['one-time-login.form.email.placeholder']}
                                        setter={this.update}
                                        type="email"
                                        validations={emailValidations.email.validations}
                                        value={this.state.email}
                                    />
                                </FormRow>
                                <Button
                                    onClick={this.onSubmit}
                                    block
                                    bsSize="lg"
                                    disabled={inProgress}
                                    className="btn btn-wbd-primary"
                                    type="submit"
                                >{loginButtonText} {loginButtonProgress}</Button>
                            </form>
                        </div>
                        <Row>
                            <Col className="text-center"><Link className="login-links" to="/login">{this.context.intl.messages['common.button.back']}</Link></Col>
                        </Row>
                    </Col>
                </LandingLayout>
            </DocumentTitle>
        );
    }

}

export default Container.create(OneTimeLogin);
