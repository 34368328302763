/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import GallerySection from './gallery-section';
import {MerchandiseActions} from './merchandise-actions';
import MerchandiseStore from './merchandise-store';
import {MessagesContext} from '../../messages/messages-context';
import {NotificationActions} from '../../notification/notification-actions';
import {RouterActions} from '../../router/router-actions';
import {TitleConstants} from '../title-actions';
import TitleStore from '../title-store';

class Merchandise extends Component {

    static get propTypes() {
        return {
            match: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [MerchandiseStore, TitleStore];
    }

    static calculateState() {
        return {
            currentMerchandise: MerchandiseStore.getState().get('currentMerchandise'),
            merchandise: MerchandiseStore.getState().get('merchandise'),
            showCaptions: MerchandiseStore.getState().get('showCaptions'),
            assets: TitleStore.getState().get('assets'),
            assetsLoaded: TitleStore.getState().get('assetsLoaded'),
            title: TitleStore.getState().get('title')
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleToggleCaptions = this.handleToggleCaptions.bind(this);
        this.loadMerchandise = this.loadMerchandise.bind(this);

        return;
    }

    componentDidMount() {
        this.loadMerchandise(this.state);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.assets !== nextState.assets ||
            this.state.currentMerchandise !== nextState.currentMerchandise ||
            this.state.merchandise !== nextState.merchandise ||
            this.state.showCaptions !== nextState.showCaptions ||
            this.state.assetsLoaded !== nextState.assetsLoaded
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate(nextProps, nextState) {
        if (this.state.assets !== nextState.assets) {
            this.loadMerchandise(nextState);
        }

        return;
    }

    componentWillUnmount() {
        MerchandiseActions.clear();
        return;
    }

    static contextType = MessagesContext;

    handleToggleCaptions() {
        MerchandiseActions.toggleCaptions();
    }

    loadMerchandise(state) {
        // Get all merchandise ids from the list of assets.
        let ids = state.assets.get('merchandise').map(a => a.get('assetId'));

        //Redirect if there is no merchandise present.
        if (!ids.size) {
            NotificationActions.showAlertDanger('title.error.no-available-merchandise-data');
            RouterActions.redirect(`/titles/${this.props.match.params.id}`);
            return;
        }

        MerchandiseActions.getByIds(ids);

        return;
    }

    render() {
        let categoryGroup = '';
        switch (this.state.title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            categoryGroup = 'series';
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            categoryGroup = 'season';
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            categoryGroup = 'episode';
            break;
        }

        return (
            <div className="container padding-all-20">
                <div className="clearfix">
                    <h2 className="pull-left-sm-up margin-top-10">Merchandise</h2>
                </div>
                <hr />
                <h3>{this.context.intl.messages[`title.merchandise.${categoryGroup}.title`]}</h3>

                <GallerySection
                    key={1}
                    merchList={this.state.assets.get('merchandise').map(m => this.state.merchandise.get(m.get('assetId')))}
                    showCaptions={true}
                    title={this.context.intl.messages['title.image-list.title.gallery']}
                    titleId={parseInt(this.props.match.params.id)}
                />
            </div>
        );
    }
}

export default Container.create(Merchandise);
