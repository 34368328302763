/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {isEpisode, isEpisodeFromMiniseries} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {Button, Glyphicon} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Scroll from 'react-scroll';

import {SubscriptionActions} from '~/src/account/tabs/subscriptions/subscription-actions';
import {AssetTypeConstants} from '~/src/asset-types/asset-type-constants';
import CommonShareModal from '~/src/common/modal-share';
import Config from '~/src/config/config';
import {MessagesContext} from '~/src/messages/messages-context';
import {PlayerActions} from '~/src/player/player-actions';
import Preloader from '~/src/preloader';
import SessionStore from '~/src/session/session-store';
import ImageLoader, {ImageLoaderPlaceholders} from '~/src/titles/asset-title/images/image-loader';
import TitleModal from '~/src/titles/info/modal';
import {EpisodeNavigation} from '~/src/titles/serie-navigation';
import {TitleActions, TitleConstants} from '~/src/titles/title-actions';
import {Compare, CompareImmutable, FormatReleaseDate, IsNotTriggerKey} from '~/src/utils/utils';

class Description extends Component {

    static get propTypes() {
        return {
            synopsis: PropTypes.string.isRequired
        };
    }

    shouldComponentUpdate(nextProps) {
        return !Compare(this.props, nextProps, ['synopsis']);
    }

    render() {
        let {synopsis, ...props} = this.props;
        return (
            <div {...props}>
                <p dangerouslySetInnerHTML={{__html: synopsis}} />
            </div>
        );
    }
}

class SynopsisDetail extends Component {
    static get propTypes() {
        return {
            canSelectDocuments: PropTypes.bool,
            canShareTitle: PropTypes.bool,
            canSubscribeClient: PropTypes.bool,
            canViewFrontendComment: PropTypes.bool,
            canViewFullEpisode: PropTypes.bool,
            canViewLanguageAvailability: PropTypes.bool,
            canViewReleaseDate: PropTypes.bool,
            canViewMultipleReleaseDates: PropTypes.bool,
            releaseDatesTableCollapsed: PropTypes.bool,
            handleToggleExpandReleaseDatesTable: PropTypes.func,
            episodeListId: PropTypes.string,
            genres: PropTypes.instanceOf(Immutable.List).isRequired,
            releaseDate: PropTypes.string,
            releaseDateType: PropTypes.number,
            releaseTerritory: PropTypes.string,
            seasonListId: PropTypes.string,
            serieNavigation: PropTypes.instanceOf(Immutable.Map),
            themes: PropTypes.instanceOf(Immutable.List).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            videos: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get defaultProps() {
        return {
            canSelectDocuments: false,
            canShareTitle: false,
            canSubscribeClient: false,
            canViewFrontendComment: false,
            canViewFullEpisode: false,
            canViewLanguageAvailability: false,
            canViewReleaseDate: false,
            canViewMultipleReleaseDates: false,
            releaseDatesTableCollapsed: true,
            handleToggleExpandReleaseDatesTable: () => void 0,
            episodeListId: '',
            releaseDate: '',
            releaseDateType: '',
            releaseTerritory: '',
            seasonListId: '',
            serieNavigation: undefined
        };
    }

    static calculateState() {
        return {
            partner: SessionStore.getState().get('partner'),
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showLoader: true,
            showShareModal: false,
            showSubscribeClientModal: false,
            showSubscribeModal: false,
        }, this.constructor.calculateState());

        this.closeModal = this.closeModal.bind(this);
        this.createLink = this.createLink.bind(this);
        this.doneClientSubscription = this.doneClientSubscription.bind(this);
        this.doneShare = this.doneShare.bind(this);
        this.doneSubscription = this.doneSubscription.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.formatAvailability = this.formatAvailability.bind(this);
        this.handlePlayVideoButton = this.handlePlayVideoButton.bind(this);
        this.handleItemCountClick = this.handleItemCountClick.bind(this);
        this.openModal = this.openModal.bind(this);
        this.onLoadCover = this.onLoadCover.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !Compare(
            this.props, nextProps, [
                'canShareTitle',
                'canSubscribeClient',
                'canViewFullEpisode',
                'genres',
                'videos',
                'releaseDate',
                'releaseDateType',
                'releaseDatesTableCollapsed',
                'releaseTerritory',
                'serieNavigation',
                'themes'
            ]) ||
            !CompareImmutable(this.props.title, nextProps.title, ['id', 'defaultImagePreviewUrl']) ||
            !Compare(this.state, nextState, ['showLoader', 'showSubscribeModal', 'showSubscribeClientModal', 'showShareModal']);
    }

    static contextType = MessagesContext;

    closeModal(modalName, event) {
        if (event) {
            event.preventDefault();
        }

        this.setState({['show' + modalName + 'Modal']: false});
    }

    createLink(id) {
        if (!id) {
            return null;
        }
        return `/titles/${id}`;
    }

    doneClientSubscription(subscriptions) {
        TitleActions.saveSubscriptions(this.props.title.get('id'), subscriptions);
        this.setState({showSubscribeClientModal: false});
        SubscriptionActions.clear();
    }

    doneShare(email) {
        let titleId = this.props.title.get('id');
        TitleActions.shareTitle(titleId, email);
        this.setState({showShareModal: false});
    }

    doneSubscription(subscriptions) {
        TitleActions.saveSubscriptions(this.props.title.get('id'), subscriptions, true);
        this.setState({showSubscribeModal: false});
        SubscriptionActions.clear();
    }

    formatAvailability(airlineAvailabilityDate) {
        if (Moment().isAfter(Moment(airlineAvailabilityDate))) {
            if (Moment(airlineAvailabilityDate).isAfter(Moment().subtract(1, 'year').calendar())) {
                return this.formatDate(airlineAvailabilityDate);
            }
            return 'Available';

        }
        return <em>{this.formatDate(airlineAvailabilityDate)}</em>;
    }

    formatDate(d) {
        if (Moment(d).isValid()) {
            return Moment(d).format('MMMM D, YYYY');
        }
        return '-';
    }

    getPlayVideo(videos) {
        if (!videos.size) {
            return;
        }

        let videosMapped = videos.map(v => {
            v = v.set('videoCategory', AssetTypeConstants.VIDEO_CONTENT_TYPE[v.get('contentType')].category);
            return v;
        });

        // BRAIN-3390 return the first feature/episode video (if one exists)
        if (videosMapped.filter(v => v.get('videoCategory') === AssetTypeConstants.VIDEO_CATEGORIES.FULL_EPISODE_FEATURE).size) {
            return videosMapped.filter(v => v.get('videoCategory') === AssetTypeConstants.VIDEO_CATEGORIES.FULL_EPISODE_FEATURE).first();
        }

        // Or the first promo video, when no feature/episode exists
        if (videosMapped.filter(v => v.get('videoCategory') === AssetTypeConstants.VIDEO_CATEGORIES.PROMO).size) {
            return videosMapped.filter(v => v.get('videoCategory') === AssetTypeConstants.VIDEO_CATEGORIES.PROMO).first();
        }

        return;
    }

    /**
    * Watch Promo/Feature/Episode button pressed, show player
    */
    handlePlayVideoButton() {
        if (!this.props.videos.size) {
            return;
        }

        const video = this.getPlayVideo(this.props.videos);
        PlayerActions.showPlayer(video, undefined, this.props.title.get('id'));
        return;
    }

    handlePlayVideoButtonPress(ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        this.handlePlayVideoButton();
    }

    handleItemCountClick(id) {
        Scroll.animateScroll.scrollTo(
            // Use jQuery.offset to get the real value. Element.getBoundingClientRect()
            // returns a wrong value when an image is loaded before the element.
            jQuery(`#${id}`).offset().top - 50,
            0
        );

        jQuery(`#${id} > h3 > a`).focus();
    }

    handleItemCountKeyUp(id, ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        this.handleItemCountClick(id);
    }

    onLoadCover() {
        this.setState({showLoader: false});
    }

    openModal(modalName, event) {
        if (event) {
            event.preventDefault();
        }

        switch (modalName) {
        case 'SubscribeClient':
            SubscriptionActions.clear();
            break;
        case 'Subscribe':
            const subscriptionContentTypeIds = Object.keys(TitleConstants.SUBSCRIPTION_CONTENT_TYPES).map(ct => {
                return TitleConstants.SUBSCRIPTION_CONTENT_TYPES[ct];
            });
            SubscriptionActions.clear();
            SubscriptionActions.getSubscriptionsByTitle(
                SessionStore.getState().getIn(['authUser', 'id']),
                this.props.title.get('id'),
                subscriptionContentTypeIds,
                'subscription_date',
                'desc',
                99999,
                0
            );
            break;
        default:
            break;
        }

        this.setState({['show' + modalName + 'Modal']: true});
    }

    openModalKeyUp(modalName, event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        this.setState({['show' + modalName + 'Modal']: true});
    }

    render() {
        let releaseDate = Moment(this.props.releaseDate);
        if (releaseDate.isValid() && this.props.releaseDate !== undefined) {
            releaseDate = FormatReleaseDate(releaseDate, this.props.releaseDateType);
        } else {
            releaseDate = '-';
        }

        if (this.props.releaseDateType === TitleConstants.RELEASE_DATE_TYPES.DISPLAY_NAME.id && this.props.releaseDate) {
            releaseDate = this.props.releaseDate;
        }

        let releaseTerritory;
        if (this.props.releaseTerritory) {
            releaseTerritory = `(${this.props.releaseTerritory})`;
        }

        let getSection = (collection, keyword) => {
            if (!collection.size) {
                return;
            }

            let links;
            switch (this.state.partner.get('id')) {
            case Config.Partners.PRESS_SITE.id:
                links = collection.map(
                    (v, i) => (<span key={i}>{v.get('name')}</span>)
                ).interpose(', ');
                break;
            default:
                links = collection.map(
                    (v, i) => (<Link key={i} to={`/search?${QueryString.stringify({[keyword]: v.get('name')})}`}>{v.get('name')}</Link>)
                ).interpose(', ');
            }

            return (
                <p>
                    <strong>{this.context.intl.messages[`title.header.${keyword}s`]}:</strong>&nbsp;{links}
                </p>
            );
        };


        let genres = getSection(this.props.genres, 'genre');
        let themes = getSection(this.props.themes, 'theme');

        let showLoader = null;
        if (this.state.showLoader) {
            showLoader = {minHeight: '320px'};
        }

        let defaultVertical = (
            <Preloader show={this.state.showLoader} fixed>
                <img
                    aria-label={this.props.title.get('parentTitleDisplayName')}
                    className="img-responsive"
                    onContextMenu={e => e.preventDefault()}
                    onLoad={this.onLoadCover}
                    src={this.props.title.get('defaultImagePortraitPreviewUrl')}
                    style={showLoader}
                />
            </Preloader>
        );
        // no image => use default and don't preload
        if (!this.props.title.get('defaultImagePortraitPreviewUrl')) {
            defaultVertical = <ImageLoader alt="" className="img-responsive" placeholder={ImageLoaderPlaceholders.VERTICAL}/>;
        }

        let TitleModalSubscribeClient;
        let ButtonSubscribeClient;
        if (this.props.canSubscribeClient) {
            TitleModalSubscribeClient = (
                <TitleModal.Subscribe
                    canSelectDocuments={this.props.canSelectDocuments}
                    close={this.closeModal.bind(this, 'SubscribeClient')}
                    done={this.doneClientSubscription}
                    show={this.state.showSubscribeClientModal}
                    subscriptionType={TitleConstants.SUBSCRIPTION_TYPE.CLIENT}
                    titleId={this.props.title.get('id')}
                />
            );
            ButtonSubscribeClient = (
                <span>
                    &nbsp;|&nbsp;
                    <a
                        onClick={this.openModal.bind(this, 'SubscribeClient')}
                        onKeyUp={this.openModalKeyUp.bind(this, 'SubscribeClient')}
                        role="button"
                        tabIndex="0"
                    >
                        <Glyphicon glyph="check" /> {this.context.intl.messages['title.synopsis.subscribe-client']}
                    </a>
                </span>
            );
        }

        let ButtonShareTitle;
        if (this.props.canShareTitle) {
            ButtonShareTitle = (
                <span>
                    &nbsp;|&nbsp;
                    <a
                        onClick={this.openModal.bind(this, 'Share')}
                        onKeyUp={this.openModalKeyUp.bind(this, 'Share')}
                        role="button"
                        tabIndex="0"
                    >
                        <Glyphicon glyph="transfer" /> {this.context.intl.messages['title.synopsis.share']}
                    </a>
                </span>
            );
        }

        let episodeName;
        let episodeCount;
        let itemNumber = '';
        let releaseDateText = this.context.intl.messages['title.header.release-date'];
        switch (true) {
        case TitleConstants.MADE_FOR_TYPES[this.props.title.get('madeForType')] === 'TV':
            releaseDateText = this.context.intl.messages['title.header.broadcast-premiere'];
            break;
        case this.props.title.get('category') === TitleConstants.TITLE_TYPES.EVENT.id:
            releaseDateText = this.context.intl.messages['title.header.date'];
            break;
        }

        let runtime;
        let runtimeText = this.context.intl.messages['title.header.run-time'];
        if (TitleConstants.MADE_FOR_TYPES[this.props.title.get('madeForType')] === 'TV') {
            runtimeText = this.context.intl.messages['title.header.program-length'];
        }

        let frontendComment;
        if (this.props.canViewFrontendComment && this.props.title.get('frontendComment')) {
            let trimmedFrontendComment = this.props.title.get('frontendComment');
            if (this.props.title.get('frontendComment').match(/^<p>(.*)<\/p>$/)) {
                trimmedFrontendComment = this.props.title.get('frontendComment').match(/^<p>(.*)<\/p>$/)[1];
            }
            frontendComment = (
                <span>(<span dangerouslySetInnerHTML={{__html: trimmedFrontendComment}} />)</span>
            );
        }

        let itemCount;
        let itemCountRow;
        const where = this.props.serieNavigation.getIn(['serieMap', `${this.props.title.get('id')}`]);
        switch (this.props.title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
            episodeCount = (
                <p>
                    <strong>{this.context.intl.messages['title.header.total-episodes']}:</strong>&nbsp;
                    <a
                        className="item-count-link"
                        onClick={this.handleItemCountClick.bind(this, this.props.seasonListId)}
                        onKeyUp={this.handleItemCountKeyUp.bind(this, this.props.seasonListId)}
                        role="button"
                        tabIndex="0"
                    >
                        {this.props.serieNavigation.get('episodes', Immutable.List()).size || '-'}
                    </a> {frontendComment}
                </p>
            );
            if (this.props.title.get('runTime')) {
                runtime = <p><strong>{runtimeText}:</strong> {this.props.title.get('runTime', '-')} {this.context.intl.messages['title.header.run-time.minutes']}</p>;
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            episodeCount = (
                <p>
                    <strong>{this.context.intl.messages['title.header.total-episodes']}:</strong>&nbsp;
                    <a
                        className="item-count-link"
                        onClick={this.handleItemCountClick.bind(this, this.props.seasonListId)}
                        onKeyUp={this.handleItemCountKeyUp.bind(this, this.props.seasonListId)}
                        role="button"
                        tabIndex="0"
                    >
                        {this.props.serieNavigation.get('episodeCount', '-')}
                    </a> {frontendComment}
                </p>
            );
            if (!SessionStore.isSingleTitle()) {
                itemCount = this.props.serieNavigation.get('seasons', Immutable.List()).size || '-';
                itemCountRow = <p>
                    <strong>{this.context.intl.messages['title.header.total-seasons']}:</strong>&nbsp;
                    <a
                        className="item-count-link"
                        onClick={this.handleItemCountClick.bind(this, this.props.seasonListId)}
                        onKeyUp={this.handleItemCountKeyUp.bind(this, this.props.seasonListId)}
                        role="button"
                        tabIndex="0"
                    >
                        {itemCount}
                    </a>
                </p>;
            }

            if (this.props.title.get('runTime')) {
                runtime = <p><strong>{runtimeText}:</strong> {this.props.title.get('runTime', '-')} {this.context.intl.messages['title.header.run-time.minutes']}</p>;
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            itemNumber = '-';
            if (isEpisodeFromMiniseries(where)) {
                itemNumber = this.props.serieNavigation.getIn(['seasons', where.get(0), 'seasonNumber']);
            }
            itemCount = this.props.title.get('episodeCount');
            if (itemCount > 0) {
                itemCountRow = <p>
                    <strong>{this.context.intl.messages['title.header.total-episodes']}:</strong>&nbsp;
                    <a
                        className="item-count-link"
                        onClick={this.handleItemCountClick.bind(this, this.props.episodeListId)}
                        onKeyUp={this.handleItemCountKeyUp.bind(this, this.props.episodeListId)}
                        role="button"
                        tabIndex="0"
                    >
                        {itemCount}
                    </a> {frontendComment}
                </p>;
            }
            if (this.props.title.get('runTime')) {
                runtime = <p><strong>{runtimeText}:</strong> {this.props.title.get('runTime', '-')} {this.context.intl.messages['title.header.run-time.minutes']}</p>;
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            itemNumber = '-';
            episodeName = <p><em>{this.props.title.get('name')}</em></p>;
            const episodesList = this.props.serieNavigation.get('episodes');
            if (isEpisode(where)) {
                itemNumber = this.props.serieNavigation.getIn(['seasons', where.get(0), 'episodes', where.get(1), 'episodeNumber']);
            } else if (episodesList) {
                // is an episode from a mini series
                let episode = episodesList.find(e => e.get('childTitleId') === this.props.title.get('id'), null, Immutable.Map());
                itemNumber = episode.get('orderWithinParent');
            }
            itemCount = this.props.title.get('episodeCount');
            if (itemCount > 0) {
                itemCountRow = <p>
                    <strong>{this.context.intl.messages['title.header.total-episodes']}:</strong>
                    <a
                        role="button"
                        tabIndex="0"
                    >
                        {itemCount}
                    </a> {frontendComment}
                </p>;
            }
            if (this.props.title.get('runTime')) {
                runtime = <p><strong>{runtimeText}:</strong> {this.props.title.get('runTime', '-')} {this.context.intl.messages['title.header.run-time.minutes']}</p>;
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.FORMAT_RIGHTS:
        case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
            if (this.props.title.get('runTime')) {
                runtime = <p><strong>{runtimeText}:</strong> {this.props.title.get('runTime', '-')} {this.context.intl.messages['title.header.run-time.minutes']}</p>;
            }
            break;
        }

        let airlineAvailabilityDate;

        if (this.props.canViewLanguageAvailability && this.props.title.get('airlineAvailabilityDate')) {

            airlineAvailabilityDate = (
                <p>
                    <strong>{this.context.intl.messages['title.synopsis.airline-availability-date']}: </strong>
                    {this.formatAvailability(this.props.title.get('airlineAvailabilityDate'))}
                </p>
            );
        }

        let theatricalRights;

        if (this.props.canViewLanguageAvailability && this.props.title.get('nonTheatricalRights')) {

            theatricalRights = (
                <p>
                    <strong>{this.context.intl.messages['title.synopsis.non-theatrical-rights']}: </strong>
                    <span dangerouslySetInnerHTML={{__html: (this.props.title.get('nonTheatricalRights') || '').replace(/<\/?p>/, '')}}></span>
                </p>
            );
        }

        // Watch Promo button displayed when an associatedVideo prop has been assigned
        let playVideoButton;

        const video = this.getPlayVideo(this.props.videos);
        if (video) {
            let caption = this.context.intl.messages['title.synopsis.play-button.watch-video'];
            let contentTypeCategory = AssetTypeConstants.VIDEO_CONTENT_TYPE[video.get('contentType')];

            if (video.get('videoCategory') !== AssetTypeConstants.VIDEO_CATEGORIES.FULL_EPISODE_FEATURE || this.props.canViewFullEpisode) {
                if (contentTypeCategory.playVideoCaption) {
                    caption = this.context.intl.messages[contentTypeCategory.playVideoCaption];
                }

                playVideoButton = (
                    <p>
                        <Button
                            bsSize="large"
                            bsStyle="primary"
                            onClick={this.handlePlayVideoButton}
                            onKeyUp={(ev) => this.handlePlayVideoButtonPress(ev)}
                        >
                            <Glyphicon glyph="play-circle" /> <span>{caption}</span>
                        </Button>
                    </p>);
            }
        }

        let fullReleaseDate;
        if (this.props.canViewReleaseDate) {
            let viewMoreBtn;
            if (this.props.canViewMultipleReleaseDates) {
                const releaseDateElement = document.getElementById('release-dates');
                let expandBtn = <span className="collapse-button">{this.context.intl.messages['title.release-dates.view.more']} <i className="fa-solid fa-chevron-right"></i></span>;
                if (!this.props.releaseDatesTableCollapsed) {
                    expandBtn = <span className="collapse-button">{this.context.intl.messages['title.release-dates.view.less']} <i className="fa-solid fa-chevron-down"></i></span>;
                }
                if (releaseDateElement) {
                    viewMoreBtn = <a href="#view-more" onClick={() => {
                        event.preventDefault();
                        this.props.handleToggleExpandReleaseDatesTable();
                        releaseDateElement.scrollIntoView();
                    }}>{expandBtn}</a>;
                }
            }

            fullReleaseDate = <p>
                <strong>{releaseDateText}:</strong> {releaseDate} {releaseTerritory} {viewMoreBtn}
            </p>;

        }
        return (
            <div className="row">
                <TitleModal.Subscribe
                    canSelectDocuments={this.props.canSelectDocuments}
                    close={this.closeModal.bind(this, 'Subscribe')}
                    done={this.doneSubscription}
                    show={this.state.showSubscribeModal}
                    titleId={this.props.title.get('id')}
                />
                {TitleModalSubscribeClient}
                <CommonShareModal
                    close={this.closeModal.bind(this, 'Share')}
                    done={this.doneShare}
                    show={this.state.showShareModal}
                />
                <div className="col-xs-4 col-md-3 padding-right-10 vertical-image-height">
                    {defaultVertical}
                </div>
                <div className="col-xs-8 col-md-9">
                    <h3 className="margin-top-10 h2">{this.props.title.get('categoryName')} {itemNumber}
                        <EpisodeNavigation serieNavigation={this.props.serieNavigation} titleId={this.props.title.get('id')}/>
                    </h3>
                    {episodeName}
                    {playVideoButton}
                    {itemCountRow}
                    {episodeCount}
                    {runtime}
                    {fullReleaseDate}
                    {airlineAvailabilityDate}
                    {theatricalRights}
                    {genres}
                    {themes}
                    <p>
                        <a
                            onClick={this.openModal.bind(this, 'Subscribe')}
                            onKeyUp={this.openModalKeyUp.bind(this, 'Subscribe')}
                            role="button"
                            tabIndex="0"
                        >
                            <Glyphicon glyph="bell" /> {this.context.intl.messages['title.synopsis.notifications']}
                        </a>
                        {ButtonSubscribeClient}
                        {ButtonShareTitle}
                    </p>
                </div>
            </div>
        );
    }
}
export default Container.create(SynopsisDetail);

export {Description, SynopsisDetail};
