/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

export default class FullEpisodes extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    componentDidMount() {
        setTimeout(() => {
            window.location.href = 'https://app.wbtvd.com';
        }, 2000);
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.full-episodes">
                <p>This page has moved to a <a href="http://app.wbtvd.com">app.wbtvd.com</a>.</p>
            </DocumentTitle>
        );
    }
}
