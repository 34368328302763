/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';
import {components} from 'react-select';

/**
 * @param {{innerProps: object, data: any, [key: string]: any}} props
 */
const SelectOption = (props) => {

    const {innerProps, ...restProps} = props;

    /**
     * @type {string}
     */
    const title = props.selectProps?.getOptionLabel?.(props.data) ?? '';

    const labelInnerProps = useMemo(() => ({
        ...innerProps,
    }), [innerProps]);

    return <components.MultiValueLabel innerProps={labelInnerProps} {...restProps}>
        {title}
    </components.MultiValueLabel>;
};

SelectOption.defaultProps = {
    data: {},
    innerProps: {},
    selectProps: {},
};

SelectOption.propTypes = {
    data: PropTypes.any,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
};

export default memo(SelectOption);
