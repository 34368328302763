/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Spinner} from 'reactstrap';

import DocumentTitle from '../../common/document-title';
import WithPermissions from '../../decorators/with-permissions';
import {MessagesContext} from '../../messages/messages-context';
import Player from '../../player';
import SessionStore from '../../session/session-store';
import {ImageLoaderPlaceholders} from '../../titles/asset-title/images/image-loader';
import {GetThumbnail} from '../../utils/utils';
import {DigitalSalesDecksActions} from '../dsd-actions';
import DigitalSalesDecksStore from '../dsd-store';

class Info extends Component {

    static get propTypes() {
        return {
            match: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            titleId: PropTypes.number.isRequired
        };
    }

    static getPermissions() {
        return {
            canPreviewVideoPlayer: SessionStore.canUser(SessionStore.PERMISSIONS.PLAYER.VIDEO_PLAYER_PREVIEW)
        };
    }

    static calculateState() {
        return {
            titles: DigitalSalesDecksStore.getState().get('titles'),
            titleVideos: DigitalSalesDecksStore.getState().get('titleVideos')
        };
    }

    static getStores() {
        return [DigitalSalesDecksStore];
    }

    constructor(props) {
        super(props);

        this.playVideo = this.playVideo.bind(this);
    }

    static contextType = MessagesContext;

    playVideo(video, ev) {
        if (ev && (ev.type === 'keyup' && !(ev.keyCode === 32 || ev.keyCode === 13))) {
            return;
        }

        if (!video.get('streams')) {
            DigitalSalesDecksActions.prepareVideo(video, 0);
        }
        return;
    }

    /* istanbul ignore next */
    render() {
        let t = this.state.titles.find(title => title.get('id').toString() === this.props.match.params.titleId);
        let shortSynopsis;
        let synopsis;
        let thumbnail;
        let videoRuntime;
        let videoTitle;
        let videoPreview;
        if (t) {
            shortSynopsis = t.get('shortSynopsis');
            synopsis = t.get('synopsis');

            let src = t.get('defaultImageHorizontalFullResolutionUrl');

            const titleVideo = this.state.titleVideos.get(t.get('id'));

            if (!titleVideo) {
                if (!src) {
                    src = ImageLoaderPlaceholders.HORIZONTAL;
                }
                videoPreview = <div>
                    <img src={src} className="img-responsive" alt={this.context.intl.messages['player.container']} />
                </div>;
            } else {
                thumbnail = GetThumbnail(titleVideo.get('thumbnails'), 600);
                videoRuntime = titleVideo.get('runtime');
                videoTitle = titleVideo.get('displayTitle');
                videoPreview = <Spinner color="light" style={{marginLeft: '50%', marginTop: '20%', width: '5rem', height: '5rem'}} />;

                if (thumbnail) {
                    src = thumbnail.get('thumbnailUrl');
                    videoPreview = <div>
                        <img src={src} className="img-responsive" alt={this.context.intl.messages['player.container']} />
                        <span
                            className="glyphicon glyphicon-play-circle thumbnail-play-icon title-play-button"
                            onClick={this.playVideo.bind(this, titleVideo)}
                            onKeyUp={this.playVideo.bind(this, titleVideo)}
                            role="button"
                            tabIndex="0"
                        />
                    </div>;
                }

                // Only render player if it is for this title (silences hidden players in other carousel items)
                if ((this.props.titleId.toString() === this.props.match.params.titleId) && (t.getIn(['video', 'streams']))) {
                    let playerOptions = {
                        autostart: false
                    };
                    videoPreview = <Player
                        canPreviewVideoPlayer={this.props.permissions.canPreviewVideoPlayer}
                        playerOptions={playerOptions}
                        ref={this.registerVideoPlayer}
                        showPoster={!t.getIn(['video', 'streams'])}
                        titleId={Number(t.get('id'))}
                        video={t.get('video')}
                        watchlist={Immutable.Map()}
                    />;
                }
            }

        }

        return (
            <DocumentTitle
                message="document-titles.dsd.info"
            >
                <div className="title-video">
                    {videoPreview}
                    <h4 className="title-video-name">
                        {videoTitle}&nbsp;<small>{videoRuntime}</small>
                    </h4>
                </div>
                <div className="title-synopsis">
                    <p dangerouslySetInnerHTML={{__html: shortSynopsis}} />
                    <p dangerouslySetInnerHTML={{__html: synopsis}} />
                </div>
            </DocumentTitle>
        );
    }
}

export default WithPermissions(Container.create(Info));
export {Info};
