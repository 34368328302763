/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import AdditionalInfo from './sections/additional-info';
import EpisodeList, {SeasonPanel} from './sections/episode-list';
import ExternalLinksSection from './sections/external-links-section';
import RelatedTitles from './sections/related-titles';
import ReleaseDatesTable from './sections/release-dates';
import Synopsis from './sections/synopsis';
import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import DocumentTitle from '../../common/document-title';
import {EventActions} from '../../events/event-actions';
import EventStore from '../../events/event-store';
import TerritoryStore from '../../lookup/territory/territory-store';
import PlayerStore from '../../player/player-store';
import VideoOverlay from '../../player/video-overlay';
import SessionStore from '../../session/session-store';
import {AssetTitleConstants, AssetTitleActions} from '../../titles/asset-title/asset-title-actions';
import AssetTitleStore from '../../titles/asset-title/asset-title-store';
import ImageList from '../../titles/asset-title/images/image-list';
import pick from '../../utils/pick';
import {Compare} from '../../utils/utils';
import VideoList from '../asset-title/videos/video-list';
import SerieNavigationStore from '../serie-navigation/serie-navigation-store';
import Share from '../share';
import {TitleActions, TitleConstants} from '../title-actions';
import TitleStore from '../title-store';

let titleStoreAttr = [
    'aspectRatio',
    'assets',
    'audios',
    'episodes',
    'genres',
    'languageAvailability',
    'languages',
    'links',
    'networks',
    'originalSources',
    'productionCompany',
    'related',
    'seasons',
    'sourceMaster',
    'standards',
    'synopses',
    'themes',
    'thumbnails',
    'title',
    'titleTalent',
    'titles',
];

let videoStoreAttr = [
    'selectedVideo',
    'showOverlay',
    'showShare',
    'video',
    'watchlist'
];

class Info extends Component {

    static get propTypes() {
        return {
            match: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            aspectRatio: TitleStore.getState().get('aspectRatio'),
            assets: TitleStore.getState().get('assets'),
            audios: TitleStore.getState().get('audios'),
            episodes: TitleStore.getState().get('episodes'),
            eventIds: SessionStore.getState().get('eventIds'),
            eventTitles: EventStore.getState().get('eventTitles'),
            genres: TitleStore.getState().get('genres'),
            languageAvailability: TitleStore.getState().get('languageAvailability'),
            languages: TitleStore.getState().get('languages'),
            links: TitleStore.getState().get('links'),
            networks: TitleStore.getState().get('networks'),
            originalSources: TitleStore.getState().get('originalSources'),
            productionCompany: TitleStore.getState().get('productionCompany'),
            related: TitleStore.getState().get('related'),
            releaseDates: TitleStore.getState().get('releaseDates'),
            seasons: TitleStore.getState().get('seasons'),
            sourceMaster: TitleStore.getState().get('sourceMaster'),
            standards: TitleStore.getState().get('standards'),
            synopses: TitleStore.getState().get('synopses'),
            territories: TerritoryStore.getState().get('items'),
            themes: TitleStore.getState().get('themes'),
            thumbnails: TitleStore.getState().get('thumbnails'),
            title: TitleStore.getState().get('title'),
            titleTalent: TitleStore.getState().get('titleTalent'),
            titles: TitleStore.getState().get('titles'),
            selectedVideo: PlayerStore.getState().get('selectedVideo'),
            showOverlay: PlayerStore.getState().get('showOverlay'),
            showShare: AssetTitleStore.getState().get('showShare'),
            video: PlayerStore.getState().get('video'),
            watchlist: PlayerStore.getState().get('watchlist'),
            serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
        };
    }

    static getPermissions() {
        return {
            canAddToCart: SessionStore.canUser(SessionStore.PERMISSIONS.CART.VIDEOS.ADD),
            canAddToWorkOrder: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.WORK_ORDERS.ADD),
            canDownloadSalesDeck: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.WORK_ORDERS.DOWNLOAD),
            canInstantOrderVideos: SessionStore.canUser(SessionStore.PERMISSIONS.CART.VIDEOS.INSTANT_ORDER),
            canSelectDocuments: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.NOTIFICATIONS.DOCUMENTS),
            canShareTitle: SessionStore.canUser(SessionStore.PERMISSIONS.SHARE.TITLES),
            canSubscribeClient: SessionStore.canUser(SessionStore.PERMISSIONS.SHARE.SUBSCRIBE_CLIENT),
            canViewAdditionalInfo: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_ADDITIONAL_INFO),
            canViewClipLibrary: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_CLIP_LIBRARY),
            canViewExternalLink: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_EXTERNAL_LINK),
            canViewFrontendComment: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_FRONTEND_COMMENT),
            canViewFullEpisode: SessionStore.canUser(SessionStore.PERMISSIONS.VIEW.FULL_EPISODES),
            canViewInternalLink: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_INTERNAL_LINK),
            canViewLanguageAvailability: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_LANGUAGE_AVAILABILITY),
            canViewLegalLines: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_LEGAL_LINES),
            canViewMasteringInfo: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_MASTERING_INFO),
            canViewReleaseDate: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_RELEASE_DATE),
            canViewMultipleReleaseDates: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_MULTIPLE_RELEASE_DATES)
        };
    }

    static getStores() {
        return [AssetTitleStore, EventStore, PlayerStore, SerieNavigationStore, SessionStore, TerritoryStore, TitleStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.constructor.calculateState(),
            {
                releaseDatesCollapsed: true
            }
        );

        this.completeRelatedTitles = this.completeRelatedTitles.bind(this);
        this.handleCloseShare = this.handleCloseShare.bind(this);
        this.handleToggleExpandReleaseDatesTable = this.handleToggleExpandReleaseDatesTable.bind(this);
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        if (this.state.eventIds) {
            EventActions.get(this.state.eventIds);
        }
        if (SessionStore.isSingleTitle()) {
            id = this.state.title.get('id');
        }
        TitleActions.getSummaryData(id);
        TitleActions.getTitles(this.state.episodes.map(t => t.get('childTitleId')));
        TitleActions.getTitles(this.state.related.map(t => t.get('childTitleId')));
        TitleActions.getTitles(this.state.seasons.map(t => t.get('childTitleId')));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !Compare(this.state, nextState, [...titleStoreAttr, ...videoStoreAttr, 'serieNavigation']);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.episodes !== prevState.episodes) {
            TitleActions.getTitles(this.state.episodes.map(t => t.get('childTitleId')));
        }

        if (this.state.related !== prevState.related) {
            TitleActions.getTitles(this.state.related.map(t => t.get('childTitleId')));
        }

        if (this.state.seasons !== prevState.seasons) {
            TitleActions.getTitles(this.state.seasons.map(t => t.get('childTitleId')));
        }
    }

    completeRelatedTitles(titles) {
        return titles.map(t => {
            let title = this.state.titles.get(t.get('childTitleId').toString());
            if (!title) {
                return;
            }

            // Extend the episode object by adding the
            // relationship info. This is used to get
            // the running order.
            return title.merge(t);
        }).filter(
            t => !!t
        );
    }

    handleCloseShare() {
        AssetTitleActions.hideShare();
    }

    handleToggleExpandReleaseDatesTable() {
        this.setState((prevState) => ({
            releaseDatesCollapsed: !prevState.releaseDatesCollapsed
        }));
    }

    render() {
        let title = this.state.title;
        let actionType = TitleConstants.ACTION_TYPES[title.get('actionType')];
        let aspectRatio = this.state.aspectRatio.filter(ratio => ratio.get('id') === title.get('aspectRatioId'));
        let audios = this.state.audios.filter(a => a.get('id') === title.get('audioId'));
        let madeForType = TitleConstants.MADE_FOR_TYPES[title.get('madeForType')];
        let movieColor = TitleConstants.MOVIE_COLOR_TYPES[title.get('movieColor')];
        let originalSources = this.state.originalSources.filter(o => o.get('id') === title.get('sourceMediaId'));
        let releaseDate = title.get('titleReleaseDate');
        let releaseDateType = title.get('titleReleaseDateType');
        if (releaseDateType === TitleConstants.RELEASE_DATE_TYPES.DISPLAY_NAME.id && title.get('titleReleaseDateDisplayName')) {
            releaseDate = title.get('titleReleaseDateDisplayName');
        }

        let releaseTerritory = '';
        if (this.state.releaseDates.size && this.state.territories.size) {
            const territory = this.state.territories.find(t => t.get('id') === this.state.releaseDates.first().get('territoryId'));
            if (territory) {
                releaseTerritory = territory.get('name');
            }
        }
        let sourceMaster = this.state.sourceMaster.filter(sm => sm.get('id') === title.get('masteringComments'));
        let standards = this.state.standards.filter(s => s.get('id') === title.get('standardId'));

        let titleList;
        let relatedList = this.state.related;
        let season;
        let seasonPanels;
        switch (title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
            relatedList = relatedList.concat(this.state.seasons);
            titleList = <EpisodeList
                episodes={this.completeRelatedTitles(this.state.episodes)}
                titleId={this.props.match.params.id}
            />;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            let panels = [];
            this.state.seasons.forEach((s, i) => {
                const seasonTitle = this.state.titles.get(s.get('childTitleId').toString(), Immutable.Map());
                const seasonObj = s.set('seasonNumber', seasonTitle.get('season', ''));
                panels = panels.concat([<hr className="margin-all-0" key={`hr-${i}`}/>, <SeasonPanel key={i} season={seasonObj}/>]);
            });
            seasonPanels = <div className="container padding-all-10">
                {panels}
            </div>;
            season = this.state.seasons.size;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            relatedList = relatedList.concat(this.state.seasons);
            titleList = <EpisodeList
                episodes={this.completeRelatedTitles(this.state.episodes)}
                titleId={this.props.match.params.id}
            />;
            season = title.get('season');
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            relatedList = relatedList.concat(this.state.episodes);
            break;
        }

        let imageList;
        if (this.state.assets.get('images').size && !SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle()) {
            imageList = (
                <ImageList
                    categoryGroup={title.get('categoryGroup')}
                    entityName={title.get('displayName')}
                    entityType={AssetTitleConstants.ENTITY_TYPE.TITLE}
                    id={parseInt(this.props.match.params.id, 10)}
                    images={this.state.assets.get('images')}
                />
            );
        }

        let videoList;
        if (this.state.assets.get('videos').size) {
            let titleId = parseInt(this.props.match.params.id, 10) || title.get('id');
            videoList = (
                <VideoList
                    canAddToCart={this.props.permissions.canAddToCart}
                    canInstantOrderVideos={this.props.permissions.canInstantOrderVideos}
                    serieNavigation={this.state.serieNavigation}
                    entityType={AssetTitleConstants.ENTITY_TYPE.TITLE}
                    id={titleId}
                    videos={this.state.assets.get('videos')}
                />
            );
        }

        let releaseDates;
        if (this.state.releaseDates.size > 1 && this.props.permissions.canViewMultipleReleaseDates) {
            releaseDates = <ReleaseDatesTable
                titleId={this.state.title.get('id')}
                collapsed={this.state.releaseDatesCollapsed}
                onHeaderClick={this.handleToggleExpandReleaseDatesTable}
                releaseDates={this.state.releaseDates}
            />;
        }

        let image;
        let imageRight;
        if (title.get('id') === TitleConstants.BEETLEJUICE) {
            image = (
                <img
                    alt={title.get('displayName')}
                    className="title-beetlejuice-decoration-top-left"
                    src={require('../special-themes/beetlejuice/temp-images/beetlejuice-decoration-top-left.svg')}
                />
            );
            imageRight = (
                <img
                    alt={title.get('displayName')}
                    className="title-beetlejuice-decoration-bottom-right"
                    src={require('../special-themes/beetlejuice/temp-images/beetlejuice-decoration-bottom-right.svg')}
                />
            );
        }

        let additionalInfo;
        if (this.props.permissions.canViewAdditionalInfo) {
            additionalInfo = <AdditionalInfo
                actionType={actionType}
                aspectRatio={aspectRatio}
                audios={audios}
                canViewLanguageAvailability={this.props.permissions.canViewLanguageAvailability}
                canViewLegalLines={this.props.permissions.canViewLegalLines}
                canViewMasteringInfo={this.props.permissions.canViewMasteringInfo}
                title={title}
                languageAvailability={this.state.languageAvailability}
                languages={this.state.languages}
                madeForType={madeForType}
                movieColor={movieColor}
                networks={this.state.networks}
                originalSources={originalSources}
                productionCompany={this.state.productionCompany}
                season={season}
                standards={standards}
                sourceMaster={sourceMaster}
            />;
        }
        const permissionsForLinks = pick(this.props.permissions, ['canViewClipLibrary', 'canViewExternalLink', 'canViewInternalLink']);

        let selectedVideo;
        if (this.state.selectedVideo.size) {
            selectedVideo = this.state.selectedVideo;
        }

        let video;
        if (this.state.video.size) {
            video = this.state.video;
        }

        const synopsisUsageType = this.state.eventTitles.find(t => t.get('titleId') === this.state.title.get('id'))?.get('synopsisUsageType');

        return (
            <DocumentTitle
                entityName={title.get('displayName')}
                message="document-titles.title"
            >
                <div className={
                    ClassNames({
                        'title-beetlejuice-content': title.get('id') === TitleConstants.BEETLEJUICE,
                        'title-elf-content': title.get('id') === TitleConstants.ELF
                    })}
                >
                    <div className="container padding-y-10 padding-x-10">
                        <div className={ClassNames({'title-elf-container': title.get('id') === TitleConstants.ELF})}>
                            {image}

                            <ExternalLinksSection
                                permissions={permissionsForLinks}
                                links={this.state.links}
                            />

                            <Synopsis
                                canAddToWorkOrder={this.props.permissions.canAddToWorkOrder}
                                canDownloadSalesDeck={this.props.permissions.canDownloadSalesDeck}
                                canSelectDocuments={this.props.permissions.canSelectDocuments}
                                canShareTitle={this.props.permissions.canShareTitle}
                                canSubscribeClient={this.props.permissions.canSubscribeClient}
                                canViewFrontendComment={this.props.permissions.canViewFrontendComment}
                                canViewFullEpisode={this.props.permissions.canViewFullEpisode}
                                canViewLanguageAvailability={this.props.permissions.canViewLanguageAvailability}
                                canViewReleaseDate={this.props.permissions.canViewReleaseDate}
                                canViewMultipleReleaseDates={this.props.permissions.canViewMultipleReleaseDates}
                                releaseDatesTableCollapsed={this.state.releaseDatesCollapsed}
                                handleToggleExpandReleaseDatesTable={this.handleToggleExpandReleaseDatesTable}
                                episodes={this.state.episodes}
                                genres={this.state.genres}
                                related={this.state.related}
                                releaseDate={releaseDate}
                                releaseDateType={releaseDateType}
                                releaseTerritory={releaseTerritory}
                                seasons={this.state.seasons}
                                serieNavigation={this.state.serieNavigation}
                                synopses={this.state.synopses}
                                synopsisUsageType={synopsisUsageType}
                                themes={this.state.themes}
                                title={title}
                                titleTalent={this.state.titleTalent}
                                videos={this.state.assets.get('videos')}
                            />

                            {videoList}
                            {imageList}
                            {releaseDates}
                            {seasonPanels}
                            {titleList}

                            {additionalInfo}

                            <RelatedTitles
                                titles={relatedList.map(
                                    r => this.state.titles.get(r.get('childTitleId').toString())
                                ).filter(
                                    r => !!r
                                )}
                            />

                            <Share
                                asset={this.state.selectedVideo}
                                assetType={AssetTypeConstants.ASSET_TYPE.VIDEO}
                                close={this.handleCloseShare}
                                show={this.state.showShare}
                                titleId={this.props.match.params.id}
                            />

                            <VideoOverlay
                                clipping
                                selectedVideo={selectedVideo}
                                titleId={parseInt(this.props.match.params.id, 10)}
                                video={video}
                                visible={this.state.showOverlay}
                                watchlist={this.state.watchlist}
                            />

                            {imageRight}
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Info);
export {Info};
