/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import ReactSelect from 'react-select';

import AssetTable from './asset-table/asset-table';
import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import DocumentTitle from '../../common/document-title';
import {GetAttr} from '../../common/utils/utils';
import WithPermissions from '../../decorators/with-permissions';
import {FormRow, FormItem} from '../../form/form';
import {MessagesContext} from '../../messages/messages-context';
import {NotificationActions} from '../../notification/notification-actions';
import PlayerStore from '../../player/player-store';
import VideoOverlay from '../../player/video-overlay';
import Preloader from '../../preloader';
import {RouterActions} from '../../router/router-actions';
import SessionStore from '../../session/session-store';
import {AssetTitleActions} from '../../titles/asset-title/asset-title-actions';
import AssetTitleStore from '../../titles/asset-title/asset-title-store';
import Photoswipe from '../../titles/asset-title/images/photoswipe';
import {Debounce} from '../../utils/utils';
import {OrderActions} from '../order-actions';
import {OrderConstants} from '../order-constants';
import OrderStore, {OrderValidations} from '../order-store';
import StatusChangeModal from '../status-change-modal';

class Order extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canEditBrainiac: SessionStore.canUser(SessionStore.PERMISSIONS.EDIT_IN_BRAINIAC),
            orderCommentRequired: SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.COMMENT_REQUIRED),
            orderIncludeSidecar: SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.SIDECAR_FILE),
            orderRemoveIndividualItems: SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.REMOVE_INDIVIDUAL_ITEMS),
        };
    }

    static calculateState() {
        return {
            currentImage: AssetTitleStore.getState().get('currentImage'),
            downloadRenditionTypes: OrderStore.getState().get('downloadRenditionTypes'),
            downloaded: OrderStore.getState().get('downloaded'),
            downloadedRenditionType: OrderStore.getState().get('downloadedRenditionType'),
            hasVideoErrorFormat: false,
            history: OrderStore.getState().get('history'),
            itemsInCart: OrderStore.getState().get('itemsInCart'),
            loading: OrderStore.getState().get('loading'),
            loadingItems: OrderStore.getState().get('loadingItems'),
            order: OrderStore.getState().get('order'),
            orderTitles: OrderStore.getState().get('orderTitles'),
            requester: OrderStore.getState().get('requester'),
            selectedClient: OrderStore.getState().get('selectedClient'),
            showOverlay: PlayerStore.getState().get('showOverlay'),
            statusChange: OrderStore.getState().get('statusChange'),
            toRemove: OrderStore.getState().get('toRemove'),
            usersList: OrderStore.getState().get('usersList'),
            video: PlayerStore.getState().get('video'),
            videoFileFormatOptions: OrderStore.getState().get('videoFileFormatOptions'),
            watchlist: PlayerStore.getState().get('watchlist'),
        };
    }

    static getStores() {
        return [AssetTitleStore, OrderStore, PlayerStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            isDownloadRenditionTypeDirty: true,
            showClearCart: false,
            showSaveForLater: false
        }, this.constructor.calculateState());


        this.approveOrder = this.approveOrder.bind(this);
        this.denyOrder = this.denyOrder.bind(this);
        this.doCancelRemove = this.doCancelRemove.bind(this);
        this.doClearCart = this.doClearCart.bind(this);
        this.doRemove = this.doRemove.bind(this);
        this.doSave = this.doSave.bind(this);
        this.fulfillOrder = this.fulfillOrder.bind(this);
        this.getDisplaySubmitButton = this.getDisplaySubmitButton.bind(this);
        this.getIsOrderApproved = this.getIsOrderApproved.bind(this);
        this.getUserName = this.getUserName.bind(this);
        this.handleAssetTableDisplayChange = this.handleAssetTableDisplayChange.bind(this);
        this.handleClearCart = this.handleClearCart.bind(this);
        this.handleCloseSwipe = this.handleCloseSwipe.bind(this);
        this.handlePhotoSwipeRemove = this.handlePhotoSwipeRemove.bind(this);
        this.handleReprocessOrder = this.handleReprocessOrder.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSelectDownloadRenditionType = this.handleSelectDownloadRenditionType.bind(this);
        this.handleSelectVideoFileFormat = this.handleSelectVideoFileFormat.bind(this);
        this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
        this.handleSubmitRemove = this.handleSubmitRemove.bind(this);
        this.hideDownloadComplete = this.hideDownloadComplete.bind(this);
        this.hideSaveForLater = this.hideSaveForLater.bind(this);
        this.hideStatusChange = this.hideStatusChange.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onClearDownloads = this.onClearDownloads.bind(this);
        this.onCloseClearCart = this.onCloseClearCart.bind(this);
        this.openClearCartModal = this.openClearCartModal.bind(this);
        this.readOnly = this.readOnly.bind(this);
        this.searchClients = Debounce(this.searchClients.bind(this), 200);
        this.selectClient = this.selectClient.bind(this);
        this.shouldRedirect = this.shouldRedirect.bind(this);
        this.statusChangeShow = this.statusChangeShow.bind(this);
        this.wrongVideoFormatPresent = this.wrongVideoFormatPresent.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.shouldRedirect(this.props, this.state);
    }

    componentDidMount() {
        OrderActions.clear();
        OrderActions.getById(this.props.match.params.id);
        OrderActions.getUsersList();
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            OrderActions.clear();
            OrderActions.getById(nextProps.match.params.id);
        }
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        this.shouldRedirect(nextProps, nextState);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadingItems !== prevState.loadingItems && prevState.loadingItems) {
            OrderActions.getDeliverySpeed(this.state.order.get('id'), OrderStore.getVideoDeliveryFormatType(this.state.order.get('deliveryFormat')).get('idForAPI'));
        }
        return;
    }

    componentWillUnmount() {
        OrderActions.clearSelectedClient();
    }

    static contextType = MessagesContext;

    approveOrder() {
        OrderActions.approveOrder(this.state.order);
    }

    denyOrder() {
        OrderActions.denyOrder(this.state.order);
    }

    doCancelRemove() {
        OrderActions.cancelRemove();
    }

    doClearCart() {
        this.onCloseClearCart();
        OrderActions.clearCart();
    }

    doRemove(toRemove) {
        let isCart = this.props.match.params.id === undefined;
        OrderActions.doRemove(isCart,
            toRemove.get('assetType'),
            toRemove.get('cartId'),
            Immutable.List.of(toRemove)
        );
    }

    doSave() {
        this.setState({showSaveForLater: false});
        OrderActions.saveForLater(this.state.order, this.state.orderTitles);
    }

    fulfillOrder() {
        OrderActions.fulfillOrder(this.state.order);
    }

    getCartItemEmails(items) {
        let itemEmails = [];

        if (!items) {
            return itemEmails;
        }

        if (hasItems(items, OrderConstants.EMAIL_DELIVERY_ITEM_TYPES.IMAGES.itemType)) {itemEmails.push(OrderConstants.EMAIL_DELIVERY_ITEM_TYPES.IMAGES);}
        if (hasItems(items, OrderConstants.EMAIL_DELIVERY_ITEM_TYPES.MOMENTS.itemType)) {itemEmails.push(OrderConstants.EMAIL_DELIVERY_ITEM_TYPES.MOMENTS);}
        if (hasItems(items, OrderConstants.EMAIL_DELIVERY_ITEM_TYPES.QUICK_DELIVERY_VIDEOS.itemType)) {
            const videos = items.get(OrderConstants.EMAIL_DELIVERY_ITEM_TYPES.QUICK_DELIVERY_VIDEOS.itemType);

            if (videos.some(v => v.get('items').some(vi => vi.get('sourceSystemType') === 'hardac'))) {
                itemEmails.push(OrderConstants.EMAIL_DELIVERY_ITEM_TYPES.QUICK_DELIVERY_VIDEOS);
            }

            /* istanbul ignore else */
            if (videos.some(v => v.get('items').some(vi => vi.get('sourceSystemType') === 'reach'))) {
                itemEmails.push(OrderConstants.EMAIL_DELIVERY_ITEM_TYPES.TRADITIONAL_DELIVERY_VIDEOS);
            }
        }

        return itemEmails;
    }

    getDisplaySubmitButton(items, permissions) {
        // [WPB-6353] Hide submit button if:
        // - order is empty
        // - order has no videos
        // - user can not edit client
        // - all images are on demand (or not all images are on demand but he can download anyways)
        // - can download
        let hasImages = hasItems(items, 'image');
        let hasVideos = hasItems(items, 'video');
        let hasClips = hasItems(items, 'video-user-clip');
        let hasNeedsApproval = items.get('image').some(
            t => t.get('items').some(
                i => i.getIn(['asset', 'deliveryType']) === AssetTypeConstants.DELIVERY_TYPES.NEEDS_APPROVAL
            )
        );
        let canDownload = (
            permissions.canDownloadImagesFullRes ||
            permissions.canDownloadImagesLowRes ||
            permissions.canDownloadImagesSource ||
            permissions.canDownloadImagesWithNeedsApproval
        );

        if (
            (!hasVideos && !hasClips && !permissions.canEditClientUser) &&
            (!hasNeedsApproval || (hasNeedsApproval && permissions.canDownloadImagesWithNeedsApproval)) &&
            canDownload
        ) {
            return false;
        }

        if (!hasImages && !hasVideos && !hasClips) {
            return false;
        }

        return true;
    }

    getIsOrderApproved() {
        const status = this.state.order.get('status');
        return status === OrderConstants.ORDER.STATUS_BY_NAME.APPROVED ||
            status === OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED || status === OrderConstants.ORDER.STATUS_BY_NAME.FULFILMENT;
    }

    getUserName(value) {
        return value.fullName || '';
    }

    /* istanbul ignore next */
    handleAssetTableDisplayChange(display) {
        const query = QueryString.parse(this.props.location.search);
        query.display = display;
        query['keep-scroll'] = true;
        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    handleClearCart(event) {
        event.preventDefault();
        this.doClearCart();
    }

    handleCloseSwipe() {
        AssetTitleActions.closeGallery();
    }

    handlePhotoSwipeRemove(assetId) {
        OrderActions.photoSwipeRemove(AssetTypeConstants.ASSET_TYPE.IMAGE, this.state.order, this.state.orderTitles.get('image'), assetId);
    }

    handleReprocessOrder() {
        OrderActions.reprocessOrder(this.props.match.params.id);
    }

    handleSave() {
        this.setState({showSaveForLater: true});
    }

    handleSelectDownloadRenditionType(option) {
        /* istanbul ignore else */
        if (!this.state.isDownloadRenditionTypeDirty) {
            this.setState({isDownloadRenditionTypeDirty: true});
        }
        if (option === null) {
            OrderActions.updateAttr('downloadRenditionType', null);
            return;
        }
        OrderActions.updateAttr('downloadRenditionType', option.id);
        return;
    }

    handleSelectVideoFileFormat(option) {
        OrderActions.getDeliverySpeed(this.state.order.get('id'), option.idForAPI);
        OrderActions.updateAttr('deliveryFormat', option.id);
        return;
    }

    handleSubmitOrder() {
        /* istanbul ignore if */
        if (this.state.selectedClient.get('id') !== undefined) {
            OrderActions.updateAttr('clientUserId', this.state.selectedClient.get('id'));
        }
        let cart = this.state.order;

        const videoDeliveryFormat = OrderStore.getVideoDeliveryFormatType(this.state.order.get('deliveryFormat')).toJS();
        /* istanbul ignore next */
        if (this.state.hasVideoErrorFormat && (
            videoDeliveryFormat.id !== OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.H_264.id
        )) {
            // STUDIO-8327: bugfix for only-MP4 orders
            cart = cart.set('deliveryFormat', OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.H_264.idForAPI);
        }

        if (!OrderStore.getValidations().length) {
            OrderActions.placeOrder(cart, OrderStore.getState().get('orderTitles'));
        } else {
            NotificationActions.showAlertDanger('orders.create.error');
        }
    }

    handleSubmitRemove(event) {
        event.preventDefault();

        this.doRemove(this.state.toRemove);
    }

    hideDownloadComplete(isCart, toRemove, renditionType) {
        OrderActions.startDownload(
            isCart,
            this.state.order.get('id'),
            renditionType.type,
            toRemove,
            `${this.props.location.pathname}/download`
        );
        // FIXME: this action call prevents the cart from displaying
        // the prompt to remove/keep files after download.
        // The name is wrong, we should also improve how it works.
        OrderActions.clearDownloads();
    }

    hideSaveForLater() {
        this.setState({showSaveForLater: false});
    }

    hideStatusChange(success) {
        OrderActions.statusChangeHide();
        if (success) {
            OrderActions.getById(this.props.match.params.id);
        }
    }

    onChangeCheckbox(requester) {
        if (!this.state.selectedClient.get('id') ||
            this.state.selectedClient.get('id') !== requester.get('id')) {
            OrderActions.getUser(requester.get('id'), OrderConstants.CLIENT.SELECT);
        } else {
            OrderActions.clearSelectedClient();
        }
    }

    onClearDownloads() {
        OrderActions.clearDownloads();
        return;
    }

    onCloseClearCart() {
        this.setState({showClearCart: false});
    }

    openClearCartModal() {
        this.setState({showClearCart: true});
    }

    readOnly(status) {
        // Detect cart mode.
        if (this.props.match.params.id === undefined) {
            return false;
        }

        /* istanbul ignore else */
        if (!SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.APPROVE) &&
            !SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.DENY) &&
            !SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.FULFILL)) {
            return true;
        }

        switch (status) {
        case OrderConstants.ORDER.STATUS_BY_NAME.APPROVED:
        case OrderConstants.ORDER.STATUS_BY_NAME.APPROVED_WITH_MODIFICATIONS:
            if (!SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.FULFILL)) {
                return true;
            }
            break;
        case OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED:
        case OrderConstants.ORDER.STATUS_BY_NAME.DENIED:
        case OrderConstants.ORDER.STATUS_BY_NAME.FULFILMENT:
            return true;
        }

        return false;
    }

    searchClients(term) {
        OrderActions.getUsersList(term);
        return;
    }

    selectClient(value) {
        if (!value) {
            OrderActions.clearSelectedClient();
            return;
        }

        OrderActions.getUser(value.id, OrderConstants.CLIENT.SELECT);
        return;
    }

    shouldRedirect(props, state) {
        // Allow users to see their carts
        if (props.match.params.id === undefined) {
            return;
        }

        if (
            this.state.order.get('clientUserId') &&
            this.state.requester.get('id') &&
            this.state.selectedClient.get('id')
        ) {
            let userId = SessionStore.getState().getIn(['authUser', 'id']);
            let ids = [
                state.order.get('clientUserId'),
                state.order.get('deliverToUserId'),
                state.order.get('requesterUserId'),
                state.requester.get('id'),
                state.selectedClient.get('id')
            ];

            // Check if current user is one of the users involved in the current order
            // or if he has the browser orders permission.
            if (
                ids.indexOf(userId) === -1 &&
                !SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.BROWSE)
            ) {
                setTimeout(() => RouterActions.redirect('/'), 0);
            }
        }
    }

    statusChangeShow() {
        OrderActions.statusChangeShow(this.state.order.get('id'));
    }

    wrongVideoFormatPresent() {
        this.setState({hasVideoErrorFormat: true});
    }

    render() {
        const query = QueryString.parse(this.props.location.search);
        let cartButtons;
        let displayOrderId;
        let downloaded = this.state.downloaded;
        let downloadedRenditionType = this.state.downloadedRenditionType.toJS();
        let items = this.state.orderTitles;
        let isCart = this.props.match.params.id === undefined;
        let order = this.state.order;
        // In cart mode, the requester is always the current user.
        let requester = SessionStore.getState().get('authUser');
        let setter = OrderActions.updateAttr.bind(OrderActions);
        let subtitle = this.context.intl.messages['cart.view.description'];
        let title = this.context.intl.messages['cart.view.title'];
        let toRemove = this.state.toRemove;
        let documentMessage = '';
        let documentEntity = '';

        const lastAction = this.state.history.get(0) || Immutable.Map();

        if (isCart) {
            let clearCartButton;
            let submitButton;
            let permissions = {
                canEditClientUser: SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.EDIT_CLIENT_USER),
                canDownloadImagesWithNeedsApproval: SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.NEEDS_APPROVAL),
                canDownloadImagesFullRes: SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.FULL),
                canDownloadImagesLowRes: SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.LO_RES),
                canDownloadImagesSource: SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.SOURCE)
            };

            documentMessage = 'document-titles.cart-title';

            /* istanbul ignore else */
            if (this.getDisplaySubmitButton(items, permissions)) {
                submitButton = (
                    <Button bsStyle="primary"
                        bsSize="large"
                        className="bg-wb-blue pull-right"
                        onClick={this.handleSubmitOrder}>
                        {this.context.intl.messages['cart.view.submit']}
                    </Button>
                );
            }

            /* istanbul  ignore else */
            if (hasItems(items, 'image') || /* istanbul ignore next */hasItems(items, 'video') /* istanbul ignore next */|| /* istanbul ignore next */hasItems(items, 'video-user-clip')) {
                clearCartButton = <Button
                    bsSize="large"
                    className="btn-secondary bg-wb-blue pull-right Mr(10px)"
                    onClick={this.openClearCartModal}>
                    {this.context.intl.messages['cart.view.clear-cart']}
                </Button>;
            }

            cartButtons = (
                <div>
                    {submitButton}
                    <Button
                        bsSize="large"
                        className="btn-secondary bg-wb-blue pull-right Mr(10px)"
                        onClick={this.handleSave}>
                        {this.context.intl.messages['cart.view.save-for-later']}
                    </Button>
                    {clearCartButton}
                </div>
            );
        } else {
            displayOrderId = (
                <span style={{fontSize: 16}}>#{this.props.match.params.id}</span>
            );
            requester = this.state.requester;
            title = this.context.intl.messages['orders.view-order.title'];

            documentMessage = 'document-titles.order-title';
            if (this.state.order.get('id') !== undefined) {
                documentEntity = `${this.context.intl.messages['document-titles.order-title']} ${this.state.order.get('id')}`;
            }
        }

        let disable = false;

        /* istanbul ignore else */
        if (!this.state.selectedClient.get('id') ||
            !this.state.order.get('dueDate') ||
            (this.props.permissions.orderCommentRequired && !order.get('orderCommment'))) {
            disable = true;
        }

        let isSameId = ( this.state.selectedClient.get('id') &&
        (this.state.selectedClient.get('id') === requester.get('id'))) || '';

        let emailList = this.state.selectedClient.get('notificationList')
            .filter(notification => notification.get('notificationEmail'))
            .map((notification) => notification.get('notificationEmail'));
        if (isSameId) {
            emailList = emailList.concat(requester.get('email'));
        }
        emailList = emailList.join(', ');

        let updatedDate = Moment(lastAction.get('actionDate')).format(this.context.intl.messages['date-format']);

        let approveButton;
        let denyButton;

        let canRemove = isCart || this.props.permissions.orderRemoveIndividualItems;

        let selectedVideoDeliveryFormat, selectedDownloadRenditionType;
        if (order.get('deliveryFormat')) {
            selectedVideoDeliveryFormat = OrderStore.getVideoDeliveryFormatType(order.get('deliveryFormat')).toJS();
        } else {
            selectedVideoDeliveryFormat = OrderStore.getVideoDeliveryFormatType(OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.XDCAM.id).toJS();
            setTimeout(() => {
                OrderActions.updateAttr('deliveryFormat', OrderConstants.VIDEO_FILE_FORMAT_OPTIONS.XDCAM.id);
            });
        }

        if (order.get('downloadRenditionType')) {
            selectedDownloadRenditionType = OrderConstants.DOWNLOAD_RENDITION_TYPE[order.get('downloadRenditionType')];
        } else {
            selectedDownloadRenditionType = OrderConstants.DOWNLOAD_RENDITION_TYPE.HiRes;
            setTimeout(() => {
                OrderActions.updateAttr('downloadRenditionType', OrderConstants.DOWNLOAD_RENDITION_TYPE.HiRes.id);
            });
        }

        /* istanbul ignore next */
        if (!isCart && !this.readOnly(order.get('status'))) {
            if (SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.DENY)) {
                denyButton = (
                    <input
                        type="button"
                        className="btn btn-lg btn-secondary pull-right margin-x-10"
                        disabled={disable}
                        onClick={this.denyOrder}
                        value={this.context.intl.messages['orders.sent-to-me.deny']}/>
                );
            }

            /**
             * Permission check should be keep in this order:
             * APPROVE first FULFILL next
             * as fulfillment permission will approve and fulfill at once.
            */
            if (SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.APPROVE)) {
                title = this.context.intl.messages['orders.sent-to-me.client-rep.title'];
                subtitle = this.context.intl.messages['orders.sent-to-me.approve-deny'];

                if (this.state.order.get('status') !== OrderConstants.ORDER.STATUS_BY_NAME.FULFILMENT) {
                    canRemove = true;
                }

                approveButton = (
                    <input
                        type="button"
                        className="btn btn-lg bg-wb-blue pull-right"
                        disabled={disable}
                        onClick={this.approveOrder}
                        value={this.context.intl.messages['orders.sent-to-me.approve']}/>
                );
            }
            if (SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.FULFILL)) {
                title = this.context.intl.messages['orders.sent-to-me.service-admin.title'];
                subtitle = this.context.intl.messages['orders.sent-to-me.approve-deny'];

                canRemove = true;
                approveButton = (
                    <input
                        type="button"
                        className="btn btn-lg bg-wb-blue pull-right"
                        disabled={disable}
                        onClick={this.fulfillOrder}
                        value={this.context.intl.messages['orders.sent-to-me.fulfill']}/>
                );
            }
        }
        let forceStatusButton;
        /* istanbul ignore next */
        if (SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.STATUS_CHANGE)) {
            // TODO: move to some place common to avoid the same check in list-orders.js
            switch (order.get('status')) {
            case OrderConstants.ORDER.STATUS_BY_NAME.REQUESTED:
            case OrderConstants.ORDER.STATUS_BY_NAME.APPROVED:
            case OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED:
            case OrderConstants.ORDER.STATUS_BY_NAME.PARTIALLY_FULFILMENT:
            case OrderConstants.ORDER.STATUS_BY_NAME.FULFILMENT:
            case OrderConstants.ORDER.STATUS_BY_NAME.DENIED:
                forceStatusButton = <input
                    type="button"
                    className="btn btn-lg btn-secondary pull-right margin-x-10"
                    onClick={this.statusChangeShow}
                    value={this.context.intl.messages['orders.status.change.button']}/>;
            }
        }

        let reprocessButton;
        /* istanbul ignore if */
        if (!isCart && SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.REPROCESS)) {
            // STUDIO-9936 only display reprocess order button on order view
            reprocessButton = <input
                type="button"
                className="btn btn-lg btn-secondary pull-right margin-x-10"
                onClick={this.handleReprocessOrder}
                value={this.context.intl.messages['orders.reprocess-order']}/>;
        }
        let selectedClient;
        if (this.state.selectedClient.get('id')) {
            selectedClient = this.state.selectedClient.toJS();
        }

        let clientSection;
        /* istanbul ignore if */
        if (SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.EDIT_CLIENT_USER)) {
            clientSection = (
                <div>
                    <div className="row">
                        <h3>
                            <span className="glyphicon glyphicon-user"/>&nbsp;
                            {this.context.intl.messages['orders.sent-to-me.client.title']}&nbsp;
                            <span className="text-red">*</span>
                            <span className="pull-right">
                                <input type="checkbox"
                                    onChange={this.onChangeCheckbox.bind(this, requester)}
                                    checked={isSameId}
                                    value="same-as-requester"
                                    disabled={this.readOnly(this.state.order.get('status'))}/>
                                <span style={{fontSize: 16}}>
                                    {this.context.intl.messages['orders.sent-to-me.client.sameAsRequester']}
                                </span>
                            </span>
                        </h3>
                    </div>
                    <div className=" row form-wrapper">
                        <div className="col-md-6 form-group">
                            <label>{this.context.intl.messages['orders.sent-to-me.client.lookupByName']}</label>
                            <ReactSelect
                                disabled={isSameId || this.readOnly(this.state.order.get('status'))}
                                getOptionLabel={GetAttr('fullName')}
                                getOptionValue={GetAttr('id')}
                                onChange={this.selectClient}
                                onInputChange={this.searchClients}
                                options={this.state.usersList.toJS()}
                                optionRenderer={this.getUserName}
                                value={selectedClient}
                                valueRenderer={this.getUserName}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>{this.context.intl.messages['orders.sent-to-me.client.lookupByEmail']}</label><br/>
                            <ReactSelect
                                disabled={isSameId || this.readOnly(this.state.order.get('status'))}
                                getOptionLabel={GetAttr('email')}
                                getOptionValue={GetAttr('id')}
                                onInputChange={this.searchClients}
                                onChange={this.selectClient}
                                options={this.state.usersList.toJS()}
                                value={selectedClient}
                                valueKey="id"
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>{this.context.intl.messages['orders.sent-to-me.client.Number']}</label>
                            <input type="text" className="form-control" disabled
                                value={this.state.selectedClient.get('dayPhone') || ''}/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>{this.context.intl.messages['orders.sent-to-me.client.company']}</label>
                            <input type="text" className="form-control" disabled
                                value={this.state.selectedClient.get('company') || ''}/>
                        </div>
                        <div className="col-md-6 form-group">
                            <label>{this.context.intl.messages['orders.sent-to-me.client.country']}</label>
                            <input type="text" className="form-control" disabled
                                value={this.state.selectedClient.get('country') || ''}/>
                        </div>
                    </div>

                    <div className="row">
                        <h3>
                            <span className="glyphicon glyphicon-send"/>&nbsp;
                            {this.context.intl.messages['orders.sent-to-me.deliver-to.title']}
                        </h3>
                    </div>
                    <div className="row form-wrapper">
                        <div className="col-md-12 form-group">
                            <label>{this.context.intl.messages['orders.sent-to-me.deliver-to.emails']}</label><br/>
                            {emailList}
                        </div>
                    </div>
                </div>
            );
        }

        const onlyViewingClip = [OrderConstants.ORDER.STATUS_BY_NAME.CART_MODE, OrderConstants.ORDER.STATUS_BY_NAME.SAVED].every((s) => s !== order.get('status'));

        let videoOverlay;
        if (this.state.video.size !== 0) {
            videoOverlay = this.state.video;
        }

        let deliveryFormats;

        const isRequestedStatus = order.get('status') === OrderConstants.ORDER.STATUS_BY_NAME.REQUESTED;
        const isSavedStatus = order.get('status') === OrderConstants.ORDER.STATUS_BY_NAME.SAVED;
        let saveAsDefault;
        if (isCart || isSavedStatus) {
            saveAsDefault = <div className="col-md-4 form-group">
                <label>{this.context.intl.messages['orders.delivery-formats.save-as-default']}</label><br/>
                <div className="checkbox">
                    <FormItem
                        model={order}
                        type="checkbox"
                        attr="saveAsDefault"
                        setter={OrderActions.updateAttr.bind(OrderActions)}
                        label={(<span>{this.context.intl.messages['orders.delivery-formats.save-as-default.description']}</span>)}
                    />
                </div>
            </div>;
        }

        let sidecarFile;
        if (this.props.permissions.orderIncludeSidecar) {
            sidecarFile = <div className="col-md-2 form-group">
                <label>{this.context.intl.messages['orders.delivery-formats.sidecar-file']}</label><br />
                <div className="checkbox">
                    <FormItem
                        disabled={this.readOnly(this.state.order.get('status')) || !(isCart || isSavedStatus)}
                        model={order}
                        type="checkbox"
                        attr="includeSidecarFiles"
                        setter={OrderActions.updateAttr.bind(OrderActions)}
                        label={(<span>{this.context.intl.messages['orders.delivery-formats.sidecar-file.description']}</span>)}
                    />
                </div>
            </div>;
        }

        deliveryFormats = (
            <div>
                <div className="row">
                    <h3>
                        <span className="fas fa-ruler-combined"/>&nbsp;
                        {this.context.intl.messages['orders.delivery-formats']}
                    </h3>
                </div>
                <div className=" row form-wrapper">
                    <div className="col-md-3 form-group">
                        <label>{this.context.intl.messages['orders.delivery-formats.video-file-format']}</label>
                        <ReactSelect
                            isDisabled={this.readOnly(this.state.order.get('status')) || !(isCart || isSavedStatus)}
                            getOptionLabel={/* istanbul ignore next */u => this.context.intl.messages[u.name]}
                            getOptionValue={GetAttr('id')}
                            onChange={this.handleSelectVideoFileFormat}
                            options={this.state.videoFileFormatOptions.toJS()}
                            value={selectedVideoDeliveryFormat}
                        />
                    </div>
                    <div className="col-md-3 form-group">
                        <label>{this.context.intl.messages['orders.sent-to-me.client.delivery.image']}</label>
                        <ReactSelect
                            isDisabled={this.readOnly(this.state.order.get('status')) || !(isCart || isSavedStatus || isRequestedStatus)}
                            getOptionLabel={GetAttr('name')}
                            getOptionValue={GetAttr('id')}
                            name="downloadRenditionType"
                            onChange={this.handleSelectDownloadRenditionType}
                            options={this.state.downloadRenditionTypes.toJS()}
                            value={selectedDownloadRenditionType}
                        />
                    </div>
                    {sidecarFile}
                    {saveAsDefault}
                </div>
            </div>
        );

        let videosFormatError;
        if (this.state.hasVideoErrorFormat) {
            videosFormatError = (
                <div className="row">
                    <h3 className="text-danger">
                        <i className="fa-solid fa-triangle-exclamation"></i>&nbsp;{this.context.intl.messages['common.alerts']}
                    </h3>
                    <div className="row padding-bottom-20">
                        <div className="col-md-12">
                            <div className="form-wrapper alert-danger">
                                <ul className="margin-bottom-0">
                                    <li>{this.context.intl.messages['orders.alert.format']}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const modalSaveForLater = <Modal show={this.state.showSaveForLater} onHide={this.hideSaveForLater}>
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="modal-title text-center">{this.context.intl.messages['cart.view.save-for-later.dialog.title']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{this.context.intl.messages['cart.view.save-for-later.dialog.description']}</p>
                <div className="form-group">
                    <FormItem
                        type="string"
                        attr="name"
                        label={this.context.intl.messages['cart.view.save-for-later.dialog.name']}
                        model={order}
                        required
                        setter={OrderActions.updateAttr.bind(OrderActions)} />
                </div>
            </Modal.Body>
            <Modal.Footer className=" padding-all-20">
                <Button bsStyle="default" className="btn-secondary pull-right" onClick={this.doSave}>{this.context.intl.messages['common.ok']}</Button>
                <Button bsStyle="default" className="btn-secondary margin-right-5 pull-left" onClick={this.hideSaveForLater}>{this.context.intl.messages['common.close']}</Button>
            </Modal.Footer>
        </Modal>;

        const modalDownloadComplete = <Modal show={downloaded.size > 0 && isCart} onHide={this.onClearDownloads}>
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="modal-title text-center">{this.context.intl.messages['cart.view.download-complete.title']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{this.context.intl.messages['cart.view.download-complete.description']}</p>
            </Modal.Body>
            <Modal.Footer className=" padding-all-20">
                <Button bsStyle="default" className="btn-secondary pull-right"
                    onClick={this.hideDownloadComplete.bind(this, isCart, downloaded, downloadedRenditionType)}
                >{this.context.intl.messages['cart.view.download-complete.remove']}</Button>
                <Button bsStyle="default" className="btn-secondary margin-right-5 pull-right"
                    onClick={this.hideDownloadComplete.bind(this, isCart, null, downloadedRenditionType)}
                >{this.context.intl.messages['cart.view.download-complete.keep']}</Button>
            </Modal.Footer>
        </Modal>;

        const modalClearCart = <Modal show={!!this.state.showClearCart} onHide={this.onCloseClearCart}>
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="modal-title text-center">{this.context.intl.messages['cart.view.clear-cart.dialog.title']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{this.context.intl.messages['cart.view.clear-cart.dialog.description']}</p>
            </Modal.Body>
            <Modal.Footer className=" padding-all-20">
                <form onSubmit={this.handleClearCart}>
                    <Button
                        bsStyle="primary"
                        className="pull-right"
                        type="submit"
                    >{this.context.intl.messages['common.delete']}</Button>
                    <Button
                        bsStyle="default"
                        className="btn-secondary margin-right-5 pull-left"
                        onClick={this.onCloseClearCart}
                    >{this.context.intl.messages['common.close']}</Button>
                </form>
            </Modal.Footer>
        </Modal>;

        const modalConfirmRemove = <Modal show={!!toRemove} onHide={this.doCancelRemove}>
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="modal-title text-center">{this.context.intl.messages['cart.view.confirm-remove.dialog.title']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{this.context.intl.messages['cart.view.confirm-remove.dialog.description']}</p>
            </Modal.Body>
            <Modal.Footer className="padding-all-20">
                <form onSubmit={this.handleSubmitRemove}>
                    <Button
                        bsStyle="primary"
                        className="pull-right"
                        type="submit"
                    >{this.context.intl.messages['common.delete']}</Button>
                    <Button
                        bsStyle="default"
                        className="btn-secondary margin-right-5 pull-left"
                        onClick={this.doCancelRemove}
                    >{this.context.intl.messages['common.close']}</Button>
                </form>
            </Modal.Footer>
        </Modal>;

        let pleaseNoteSection;
        const cartItemEmails = this.getCartItemEmails(items);

        let hasNoSideCar = items.get('video').filter(v => {
            return v.get('items').find(i => {
                return i.get('hasSideCar') === false;
            });
        }).size;

        if ((cartItemEmails.length > 1) || (order.get('includeSidecarFiles') && hasNoSideCar)) {
            let emailNote;
            let noSidecarNote;

            if (cartItemEmails.length > 1) {
                const listChildren = cartItemEmails.map(at => {
                    return <li>{this.context.intl.messages[at.message]}</li>;
                });

                emailNote = <div className="form-wrapper alert-primary">
                    <p>{this.context.intl.messages['cart.view.please-note.email.description']}</p>
                    <ul className="margin-bottom-0">
                        {listChildren}
                    </ul>
                </div>;
            }

            if (order.get('includeSidecarFiles') && hasNoSideCar) {
                noSidecarNote = <div className="form-wrapper alert-secondary">
                    <p>{this.context.intl.messages['cart.view.please-note.sidecar.description']}</p>
                </div>;
            }

            pleaseNoteSection = <div>
                <div className="row">
                    <h3><i className="fa-solid fa-circle-info" /> {this.context.intl.messages['cart.view.please-note']}</h3>
                </div>
                <div className="col-md-12">
                    {emailNote}
                    {noSidecarNote}
                </div>
                <div className="row" />
            </div>;
        }

        let showPreloader = false;
        if (isCart) {
            showPreloader = this.state.loading || this.state.loadingItems || this.state.itemsInCart === undefined;
        } else {
            showPreloader = this.state.loading || this.state.loadingItems;
        }
        return (
            <DocumentTitle message={documentMessage} entityName={documentEntity}>
                <div className={ClassNames('container padding-y-10 padding-x-20 Pos(rel)', {'container-zero-content': SessionStore.isContentNotAvailable()})} id="hide-this">
                    <StatusChangeModal show={!!this.state.statusChange.get('show')} onClose={this.hideStatusChange} orderId={order?.get('id')} />
                    {modalSaveForLater}
                    {modalDownloadComplete}
                    {modalClearCart}
                    {modalConfirmRemove}

                    <VideoOverlay
                        noAddToCart
                        noShare
                        onlyViewingClip={onlyViewingClip}
                        titleId={this.state.video.get('titleId')}
                        video={videoOverlay}
                        visible={this.state.showOverlay}
                        watchlist={this.state.watchlist}
                    />

                    <Photoswipe
                        addToCart={false}
                        currentImage={this.state.currentImage}
                        images={
                            items.get('image').map(
                                t => t.get('items')
                            ).flatten(true).map(
                                item => item.get('asset')
                            )
                        }
                        canEditBrainiac={this.props.permissions.canEditBrainiac}
                        canRemove={canRemove}
                        hasRestrictedAuth={SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.NEEDS_APPROVAL)}
                        isModalOpen={!!toRemove}
                        onRemove={this.handlePhotoSwipeRemove}
                        share={false}
                        onClose={this.handleCloseSwipe}
                    />

                    <Preloader
                        fixed
                        show={showPreloader}
                    />

                    <div className="row">
                        <h2 className="h1 font-scale-10">{title}&nbsp;{displayOrderId}</h2>
                        <p>{subtitle}</p>
                    </div>

                    <div className="row">
                        <AssetTable
                            assetType={AssetTypeConstants.ASSET_TYPE.VIDEO}
                            canRemove={canRemove}
                            cartId={order.get('id') || 0}
                            icon="facetime-video"
                            isCart={isCart}
                            location={this.props.location}
                            orderStatus={order.get('status')}
                            selectedVideoFileFormat={selectedVideoDeliveryFormat}
                            title={this.context.intl.messages['asset.videos']}
                            titles={items.get('video')}
                            wrongVideoFormatPresent={this.wrongVideoFormatPresent}
                        />
                        <AssetTable
                            assetType={AssetTypeConstants.ASSET_TYPE.VIDEO_USER_CLIP}
                            canDownloadAle={SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.CLIPS.ALE)}
                            canRemove={canRemove}
                            cartId={order.get('id') || 0}
                            icon="scissors"
                            isCart={isCart}
                            location={this.props.location}
                            orderStatus={order.get('status')}
                            selectedVideoFileFormat={selectedVideoDeliveryFormat}
                            title={this.context.intl.messages['asset.moments']}
                            titles={items.get('video-user-clip')}
                            wrongVideoFormatPresent={this.wrongVideoFormatPresent}
                        />
                        <AssetTable
                            assetType={AssetTypeConstants.ASSET_TYPE.IMAGE}
                            cartId={order.get('id') || 0}
                            canDownloadFull={SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.FULL)}
                            canDownloadLoRes={SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.LO_RES)}
                            canDownloadSource={SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.SOURCE)}
                            canDownloadWithNeedsApproval={SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.NEEDS_APPROVAL)}
                            canDownloadNonServiceable={SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.NON_SERVICEABLE)}
                            canRemove={canRemove}
                            display={query.display}
                            downloadRenditionType={selectedDownloadRenditionType}
                            enableGrid
                            isCart={isCart}
                            icon="camera"
                            location={this.props.location}
                            onDisplayChange={this.handleAssetTableDisplayChange}
                            orderStatusCanDownload={this.getIsOrderApproved()}
                            orderStatus={order.get('status')}
                            title={this.context.intl.messages['asset.images']}
                            titles={items.get('image')}
                        />
                    </div>

                    {deliveryFormats}

                    <h3>
                        <span className="glyphicon glyphicon-pencil"/>&nbsp;
                        {this.context.intl.messages['orders.sent-to-me.order.comment-title']}&nbsp;
                        {this.props.permissions.orderCommentRequired && <span className="text-red">*</span>}&nbsp;
                        {this.props.permissions.orderCommentRequired && <small>{this.context.intl.messages['orders.sent-to-me.order.required-message']}</small>}
                    </h3>
                    <div className="row">
                        <form>
                            <FormItem attr="orderCommment"
                                md={12}
                                model={order}
                                setter={setter}
                                type="textarea"
                                disabled={this.readOnly(order.get('status'))}
                                validations={OrderValidations.orderCommment.validations}
                            />
                        </form>
                    </div>

                    <h3>
                        <span className="glyphicon glyphicon-bell"/>&nbsp;
                        {this.context.intl.messages['orders.sent-to-me.requester.title']}
                    </h3>
                    <div className=" row form-wrapper">
                        <div className="col-md-6 form-group">
                            <label>
                                {this.context.intl.messages['orders.sent-to-me.requester.name']}
                            </label><br/>
                            {requester.get('fullName') || ''}
                        </div>
                        <div className="col-md-6 form-group">
                            <label>
                                {this.context.intl.messages['orders.sent-to-me.requester.email']}
                            </label><br/>
                            {requester.get('email') || ''}
                        </div>
                        <div className="col-md-6 form-group">
                            <label>
                                {this.context.intl.messages['orders.sent-to-me.requester.phone']}
                            </label><br/>
                            {requester.get('dayPhone') || ''}
                        </div>
                        <div className="col-md-6 form-group">
                            <label>
                                {this.context.intl.messages['orders.sent-to-me.requester.company']}
                            </label><br/>
                            {requester.get('company') || ''}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>
                                {this.context.intl.messages['orders.sent-to-me.requester.country']}
                            </label><br/>
                            {requester.get('country') || ''}
                        </div>
                    </div>

                    {clientSection}

                    <h3>
                        <span className="glyphicon glyphicon-list-alt"/>&nbsp;
                        {this.context.intl.messages['orders.sent-to-me.order-info.title']}
                    </h3>
                    <div className="row form-wrapper">
                        <form>
                            <FormRow>
                                <FormItem attr="name"
                                    label={this.context.intl.messages['orders.sent-to-me.order-info.order-name']}
                                    md={8}
                                    model={order}
                                    setter={setter}
                                    type="text"
                                    disabled={this.readOnly(order.get('status'))}
                                />
                                <FormItem attr="dueDate"
                                    label={this.context.intl.messages['orders.sent-to-me.order-info.due-date']}
                                    md={4}
                                    model={order}
                                    setter={setter}
                                    type="date"
                                    disabled={this.readOnly(order.get('status'))}
                                    validations={OrderValidations.dueDate.validations}
                                />
                            </FormRow>
                        </form>

                        <div className="col-md-12"><hr/></div>
                        <div className="col-md-6 form-group">
                            <label> {this.context.intl.messages['orders.sent-to-me.order-info.request-number']}</label><br/>
                            {this.props.match.params.id}
                        </div>
                        <div className="col-md-6 form-group">
                            <label> {this.context.intl.messages['orders.sent-to-me.order-info.status']}</label><br/>
                            {OrderConstants.ORDER.STATUS[order.get('status')]}
                        </div>
                        <div className="col-md-6 form-group">
                            <label> {this.context.intl.messages['orders.sent-to-me.order-info.last-action']}</label><br/>
                            {OrderConstants.ORDER.STATUS[order.get('status')]} by {lastAction.get('actor')}
                        </div>
                        <div className="col-md-6 form-group">
                            <label> {this.context.intl.messages['orders.sent-to-me.order-info.last-action-date']}</label><br/>
                            {updatedDate}
                        </div>
                    </div>

                    {pleaseNoteSection}
                    {videosFormatError}

                    <div className="row padding-y-20">
                        {approveButton}
                        {denyButton}
                        {cartButtons}
                        {forceStatusButton}
                        {reprocessButton}
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default WithPermissions(Container.create(Order));
export {Order};

/* istanbul ignore next */
function hasItems(items, assetType) {
    if (!items) {
        return false;
    }

    return items.get(assetType).some(t => !!t.get('items').size);
}
