/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

export default class FormatsCatalog extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.formats-catalog">
                <div className="padding-y-10 padding-x-20" id="hide-this" style={{display: 'table', margin: '0 auto'}}>
                    <a href="https://www.wbtvd.com/page/formats-catalog/2017_Formats_Catalogue___digital.pdf"><img border="0" src="https://www.wbtvd.com/page/formats-catalog/2017Formats976x668.jpg" /></a>
                </div>

            </DocumentTitle>

        );
    }
}
