/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';

import selectStyles from './select-styles';
import FacetSelect from '../../../../common/facets/facet-select';
import useTalentCharacterFacet from '../../../../common/facets/hooks/use-talent-character-facet';
import TalentCharacterFacetSelect from '../../../../common/facets/talent-character-facet-select';
import omit from '../../../../common/utils/omit';

const TalentCharacterFacet = (props) => {
    const facetProps = useTalentCharacterFacet();
    const intl = useIntl();
    const title = intl.messages['search.filters.navbar.titles.talent-character'];

    return (
        <>
            <h5><strong>{title}</strong></h5>
            <TalentCharacterFacetSelect styles={selectStyles} {...props} {...facetProps} />
        </>
    );
};

TalentCharacterFacet.propTypes = {
    ...FacetSelect.propTypes,
    options: PropTypes.instanceOf(Immutable.List).isRequired,
};

TalentCharacterFacet.defaultProps = omit(FacetSelect.defaultProps, ['options']);

export default memo(TalentCharacterFacet);
