/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './app-notes.less';

export default class AppNotes extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.app-notes">
                <div id="app-notes" className="contentWrapper">
                    <div id="notice">
                        <h3>Important Notice</h3>
                        <p>When Apple released iOS 9, they changed the way our apps get installed. On your iPad, you now need to go into your Settings and &ldquo;trust&rdquo; the developer. This will happen whenever you install (or re-install) the app on a new device with iOS 9+.</p>
                    </div>

                    <div className="currently-downloading" >
                        <img src="https://www.wbtvd.com/page/full-episodes/currentlydownloading.png" alt="" />
                    </div>

                    <div className="large-text">Please follow the steps below</div>

                    <div id="steps" className="clearfix" >
                        <div className="step step1">
                            <img src="https://www.wbtvd.com/page/full-episodes/step1.png" alt="" />
                            <div>
                                <span className="num">1.</span> Open <b>Settings</b>
                            </div>
                        </div>
                        <div className="step step2">
                            <img src="https://www.wbtvd.com/page/full-episodes/step2.png" alt="" />
                            <div>
                                <span className="num">2.</span>
                                Select <b>General</b> and scroll
                                <br/>down to <b>Device Management</b>
                                <br/>or <b>Profiles &amp; Device Management</b>
                            </div>
                        </div>
                        <div className="step step3">
                            <img src="https://www.wbtvd.com/page/full-episodes/step3.png" alt="" />
                            <div>
                                <span className="num">3.</span> Select
                                <br/><b>Warner Bros Entertainment</b>
                            </div>
                        </div>
                        <div className="step step4">
                            <img src="https://www.wbtvd.com/page/full-episodes/step4.png" alt="" />
                            <div>
                                <span className="num">4.</span>
                                Select <b>Trust</b>
                                <br/><b>Warner Bros Entertainment</b>
                            </div>
                        </div>
                        <div className="step step5">
                            <img src="https://www.wbtvd.com/page/full-episodes/step5.png" alt="" />
                            <div>
                                <span className="num">5.</span>
                                Select <b>Trust</b> to Confirm
                            </div>
                        </div>
                        <div className="step step6">
                            <img src="https://www.wbtvd.com/page/full-episodes/step6.png" alt="" />
                            <div>
                                <span className="num">6.</span>
                                Go to the home screen and
                                <br/><b>Launch</b> the App
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}
