/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import {Container} from 'flux/utils';
import React from 'react';
import {Button, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {TransitionGroup} from 'react-transition-group';

import AdditionalAccessContent from './additional-access-content';
import Config from '../../config/config';
import {FormRow, FormItem} from '../../form/form';
import UserCompanySelect from '../../lookup/user-company/user-company-select';
import UserCompanyStore from '../../lookup/user-company/user-company-store';
import {MessagesContext} from '../../messages/messages-context';
import {Alert} from '../../notification/alert';
import {AlertsWarnings} from '../../notification/alert-warning';
import {RouterActions} from '../../router/router-actions';
import SessionStore from '../../session/session-store';
import ContactSelection from '../contact-select';
import {SignUpActions} from '../signup-actions';
import SignUpStore, {StepTwoValidations} from '../signup-store';

export class StepTwo extends React.Component {
    static calculateState() {
        return {
            companies: UserCompanyStore.getState().get('companies'),
            isLoading: UserCompanyStore.getState().get('isLoading'),
            partner: SessionStore.getPartner(),
            signup: SignUpStore.getState().get('signup')
        };
    }

    static getStores() {
        return [SignUpStore, UserCompanyStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            alertVisible: false,
            isFormSubmited: false,
            isTerritoryDirty: false,
        }, this.constructor.calculateState());

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (!this.state.signup.size) {
            RouterActions.redirect('/sign-up');
        }
    }

    static contextType = MessagesContext;

    handleSubmit(e) {
        e.preventDefault();
        let validations = SignUpStore.getStepTwoValidations();

        if (validations.length) {
            this.setState({isFormSubmited: true});
        } else {
            SignUpActions.signUpStepTwo(this.state.signup);
        }
    }

    render() {
        let setter = SignUpActions.updateAttr.bind(SignUpActions);
        let validations = SignUpStore.getStepTwoValidations();
        let warning;

        // if form is not submited
        /* istanbul ignore if */
        if (this.state.isFormSubmited) {
            warning = (
                <AlertsWarnings
                    title={this.context.intl.messages['signup.form.error.message']}
                    validations={validations}
                />
            );
        }

        let companyLabel = 'company';
        /* istanbul ignore if */
        if (this.state.partner.id === Config.Partners.PRESS_SITE.id) {
            companyLabel = 'company-outlet';
        }

        let companyOptions = this.state.companies;
        /* istanbul ignore if */
        if (companyOptions.size) {
            // STUDIO-8881 Sort companies alphabetically with case sensitivity (capitalized options appear first)
            companyOptions = companyOptions.sortBy(c => c.get('name'));
        }

        return (
            <div>
                <div className="col-md-2">
                </div>
                <div className="col-md-8">
                    <Row>
                        <h3>Step 1 - Complete</h3>
                        <p><em>Please <Link to="/sign-up?mode=edit">click here</Link> to edit.</em></p>
                        <TransitionGroup
                            transitionName="alert"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}>
                            <Alert />
                            {warning}
                        </TransitionGroup>
                    </Row>
                    <Row className="form-wrapper">
                        <FormGroup className="col-md-4">
                            <ControlLabel>{this.context.intl.messages['signup.form.label.name']}</ControlLabel>
                            <FormControl.Static>{this.state.signup.get('name')}</FormControl.Static>
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <ControlLabel>{this.context.intl.messages['signup.form.label.middleName']}</ControlLabel>
                            <FormControl.Static>{this.state.signup.get('middleName')}</FormControl.Static>
                        </FormGroup>
                        <FormGroup className="col-md-4">
                            <ControlLabel>{this.context.intl.messages['signup.form.label.lastName']}</ControlLabel>
                            <FormControl.Static>{this.state.signup.get('lastName')}</FormControl.Static>
                        </FormGroup>
                        <div className="col-md-12">
                            <hr />
                        </div>
                        <FormGroup className="col-md-6">
                            <ControlLabel>{this.context.intl.messages['signup.form.label.email']}</ControlLabel>
                            <FormControl.Static>{this.state.signup.get('email')}</FormControl.Static>
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <ControlLabel>{this.context.intl.messages['signup.form.label.country']}</ControlLabel>
                            <FormControl.Static>{this.state.signup.get('selectedCountry')}</FormControl.Static>
                        </FormGroup>
                    </Row>
                    <h3>Step 2</h3>
                    <p>Please provide additional information below. <span className="text-red">*</span> indicates required field.</p>
                    <Row className="form-wrapper">
                        <form autoComplete="off">
                            <FormRow>
                                <FormItem
                                    attr="jobTitle"
                                    label={this.context.intl.messages['signup.form.label.jobTitle']}
                                    model={this.state.signup}
                                    setter={setter}
                                    validations={StepTwoValidations.jobTitle.validations}
                                />
                                <UserCompanySelect
                                    attr="company"
                                    clearSuggestions
                                    getOptionLabel={/* istanbul ignore next */data => data.name}
                                    getOptionValue={/* istanbul ignore next */data => data.id}
                                    isClearable
                                    isLoading={this.state.isLoading}
                                    label={this.context.intl.messages[`signup.form.label.${companyLabel}`]}
                                    model={this.state.signup}
                                    name="company"
                                    options={companyOptions}
                                    placeholder={this.context.intl.messages[`signup.form.label.${companyLabel}.placeholder`]}
                                    required
                                    setter={setter}
                                    store={UserCompanyStore}
                                />
                            </FormRow>
                            <FormRow>
                                <FormItem
                                    attr="referrerEmail"
                                    label={this.context.intl.messages['signup.form.label.referrers-email']}
                                    model={this.state.signup}
                                    setter={setter}
                                    validations={StepTwoValidations.referrerEmail.validations}
                                />
                                <ContactSelection
                                    attr="repUserId"
                                    label={this.context.intl.messages[`signup.form.label.contact.${this.state.partner.id.toLowerCase()}`]}
                                    model={this.state.signup}
                                    setter={setter}
                                    showNotListed={!this.state.partner.showTwoStepSignup}
                                    placeholder={this.context.intl.messages['signup.form.label.wbtvd.placeholder']}
                                    required
                                    validations={StepTwoValidations.repUserId.validations}
                                />
                            </FormRow>
                            <FormRow>
                                <AdditionalAccessContent/>
                            </FormRow>
                            <div className="col-md-12">
                                <Button bsSize="large"
                                    className="pull-right"
                                    bsStyle="primary"
                                    onClick={this.handleSubmit}>
                                    {this.context.intl.messages['signup.form.label.submit']}
                                </Button>
                            </div>
                        </form>
                    </Row>
                </div>
                <div className="col-md-2">
                </div>
            </div>
        );
    }
}

export default Container.create(StepTwo);
