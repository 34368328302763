/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {AUDIO_CT_TYPES, DOCUMENT_CT_TYPES, IMAGE_CT_TYPES, VIDEO_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import React, {Component} from 'react';

import {DownloadHistoryActions} from './download-history-actions';
import DownloadHistoryStore from './download-history-store';
import {AssetTypeConstants} from '../../../asset-types/asset-type-constants';
import DocumentTitle from '../../../common/document-title';
import {MessagesContext} from '../../../messages/messages-context';
import Pagination from '../../../pagination';
import Preloader from '../../../preloader';
import SessionStore from '../../../session/session-store';
import {AssetTitleActions} from '../../../titles/asset-title/asset-title-actions';
import {HandleWithTriggerKeys} from '../../../utils/utils';

import 'datatables.net-responsive-bs/css/responsive.bootstrap.css';
import '../../../styles/app/dataTables.brainiac.css';

// Load jQuery and register the datatables plugin.
// eslint-disable-next-line @typescript-eslint/no-var-requires
let jQuery = require('jquery');
require('datatables.net-responsive-bs');

class DownloadHistory extends Component {
    static calculateState() {
        return {
            history: DownloadHistoryStore.getState().get('history'),
            assets: DownloadHistoryStore.getState().get('assets'),
            totalCount: DownloadHistoryStore.getState().get('totalCount'),
            loading: DownloadHistoryStore.getState().get('loading')
        };
    }

    static getStores() {
        return [DownloadHistoryStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            offset: 0,
            size: 20,
            userId: SessionStore.getState().getIn(['authUser', 'id'])
        }, this.constructor.calculateState());

        this.onPageChange = this.onPageChange.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    componentDidMount() {
        DownloadHistoryActions.getDownloadHistory(this.state.userId, this.state.size, this.state.offset);

        this.$table = jQuery('#download-history-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This column will
                // contain the button to show the responsive data.
                className: 'control',
                targets: -1,
                width: 20
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        this.updateRows(this.state);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.history !== this.state.history ||
            nextState.assets !== this.state.assets ||
            nextState.loading !== this.state.loading) {
            return true;
        }
        return false;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        this.updateRows(nextState);
        return;
    }

    componentWillUnmount() {
        DownloadHistoryActions.clear();
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
    }

    static contextType = MessagesContext;

    onPageChange(offset) {
        this.setState({
            offset
        });
        DownloadHistoryActions.getDownloadHistory(this.state.userId, this.state.size, offset);
        return;
    }

    handleRowClick(event) {
        if (event.target.classList.contains('image-download-link')) {
            event.preventDefault();

            const assetId = event.target.getAttribute('data-image-id');
            const renditionType = event.target.getAttribute('data-rendition');

            AssetTitleActions.downloadImageRendition(assetId, renditionType);
        }

        return;
    }

    updateRows(state) {
        if (!state) {return;}

        this.$tableAPI.clear();

        if (state.history.size) {
            let tableData = Immutable.List(state.history.map(h => {
                let contentType = '';
                let assetUrl = '';
                let canDownloadHiRes = false;
                let canDownloadLoRes = false;
                let canDownloadSource = false;
                let asset;
                /* istanbul ignore else */
                if (state.assets.size && state.assets.has(h.get('objectId'))) {
                    asset = state.assets.get(h.get('objectId'));
                    contentType = asset.get('contentType');

                    if (asset.get('browseTitleList').size) {
                        let titleId = asset.get('browseTitleList').first().get('id');

                        switch (asset.get('assetType')) {
                        case AssetTypeConstants.ASSET_TYPE.IMAGE:
                            if (IMAGE_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')]) {
                                contentType = IMAGE_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')].name;
                            }
                            assetUrl = `/titles/${titleId}/images/${h.get('objectId')}`;
                            canDownloadHiRes = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.FULL);
                            canDownloadLoRes = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.LO_RES);
                            canDownloadSource = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.SOURCE);
                            break;
                        case AssetTypeConstants.ASSET_TYPE.VIDEO:
                            if (VIDEO_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')]) {
                                contentType = VIDEO_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')].name;
                            }
                            assetUrl = `/titles/${titleId}/videos/${h.get('objectId')}`;
                            break;
                        case AssetTypeConstants.ASSET_TYPE.AUDIO:
                            if (AUDIO_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')]) {
                                contentType = AUDIO_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')].name;
                            }
                            assetUrl = `/titles/${titleId}/audio`;
                            break;
                        case AssetTypeConstants.ASSET_TYPE.MERCHANDISE:
                            contentType = 'General';
                            assetUrl = `/titles/${titleId}/merchandise`;
                            break;
                        default:
                            // Documents
                            if (DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')]) {
                                contentType = DOCUMENT_CT_TYPES.CONTENT_TYPE_MAP[asset.get('contentType')].name;
                            }
                            assetUrl = `/titles/${titleId}/documents`;
                            break;
                        }
                    }

                }

                return Immutable.Map({
                    asset,
                    assetId: h.get('objectId'),
                    assetName: h.get('displayDescription'),
                    assetUrl,
                    contentType,
                    canDownloadHiRes,
                    canDownloadLoRes,
                    canDownloadSource,
                    requestDate: Moment(h.get('actionDate')).format(this.context.intl.messages['datetime-short-format']),
                });
            }));

            // Add sorted data to the jQuery datatable.
            tableData.forEach(data => {

                // Compose download button
                let downloadButton = '<a href="#"></a>';
                if (data.get('canDownloadHiRes') || data.get('canDownloadLoRes') || data.get('canDownloadSource')) {
                    downloadButton = '<a href="#" data-toggle="dropdown" class="text-default-color dropdown-toggle"><span class="glyphicon glyphicon-download-alt"></span></a>';

                    downloadButton += '<ul class="dropdown-menu dropdown-menu-right dropdown-download-menu">';
                    if (data.get('canDownloadHiRes')) {downloadButton += `<li><a class="Cur(p) image-download-link dropdown-toggle" data-rendition="HiRes" data-image-id="${data.get('assetId')}">${this.context.intl.messages['account.tab.download-history.renditions.hi-res-rgb']}</a></li>`;}
                    if (data.get('canDownloadLoRes')) {downloadButton += `<li><a class="Cur(p) image-download-link dropdown-toggle" data-rendition="LoRes" data-image-id="${data.get('assetId')}">${this.context.intl.messages['account.tab.download-history.renditions.lo-res-rgb']}</a></li>`;}
                    if (data.get('canDownloadSource')) {downloadButton += `<li><a class="Cur(p) image-download-link dropdown-toggle" data-rendition="Source" data-image-id="${data.get('assetId')}">${this.context.intl.messages['account.tab.download-history.renditions.source']}</a></li>`;}
                    downloadButton += '</ul>';
                }

                this.$tableAPI.row.add([
                    `<a href=${data.get('assetUrl')}>${data.get('assetName')}</a>`,
                    data.get('contentType'),
                    data.get('requestDate'),
                    downloadButton,
                    ''
                ]);
                return;
            });
        }
        this.$tableAPI.draw(false);
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.my-account.download-history"
            >
                <div>
                    <Preloader fixed className="text-primary" show={this.state.loading}/>
                    <div className="container padding-y-10 padding-x-20" id="hide-this">
                        <div className="row">
                            <h3 className="padding-y-10">
                                <span className="glyphicon glyphicon-download-alt"/>&nbsp;{this.context.intl.messages['account.tab.download-history.title']}
                            </h3>
                            <table id="download-history-table" className="table table-striped responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            {this.context.intl.messages['account.tab.download-history.list.asset-name']}
                                        </th>
                                        <th scope="col">
                                            {this.context.intl.messages['account.tab.download-history.list.content-type']}
                                        </th>
                                        <th scope="col">
                                            {this.context.intl.messages['account.tab.download-history.list.request-date-time']}
                                        </th>
                                        <th className="no-sort icon-column sorting_disabled" rowSpan="1" colSpan="1" scope="col">
                                            <span className="glyphicon glyphicon-download-alt"/>
                                        </th>
                                        <th className="no-sort" scope="col"/>
                                    </tr>
                                </thead>
                                <tbody onClick={this.handleRowClick} onKeyUp={HandleWithTriggerKeys(this.handleRowClick)} tabIndex="0"/>
                            </table>
                            <div className="row">
                                <div className="text-center col-md-12">
                                    <Pagination
                                        className="margin-top-10"
                                        offset={this.state.offset}
                                        onChange={this.onPageChange}
                                        size={this.state.size}
                                        total={this.state.totalCount}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}


export default Container.create(DownloadHistory);
export {DownloadHistory};
