/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import ReactSelect from 'react-select';

import {DeliveryConstants} from './delivery-actions';
import {GetAttr} from '../../../common/utils/utils';

const MetadataExportFallbackEnglish = ({disabled, onChange, workOrder}) => {
    let fallbackEnglish = 0;
    if (workOrder.get('fallbackEnglish')) {
        fallbackEnglish = 1;
    }
    const selectedFallbackEnglish = DeliveryConstants.EXPORT_FALLBACK_ENGLISH[fallbackEnglish];
    const {messages} = useIntl();

    return (
        <ReactSelect
            getOptionLabel={/* istanbul ignore next */data => messages[data.name]}
            getOptionValue={GetAttr('id')}
            isClearable={false}
            isMulti={false}
            isDisabled={disabled}
            name="fallbackEnglish"
            onChange={onChange}
            options={DeliveryConstants.EXPORT_FALLBACK_ENGLISH}
            value={selectedFallbackEnglish}
        />
    );
};

MetadataExportFallbackEnglish.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    workOrder: PropTypes.instanceOf(Immutable.Map).isRequired
};

MetadataExportFallbackEnglish.defaultProps = {
    disabled: true
};

export default MetadataExportFallbackEnglish;
