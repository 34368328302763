/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GetDeliverableFormatData, GetVideoDisplayName} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import {OrderActions} from './../../order-actions';
import Analytics from '../../../analytics';
import {AssetTypeConstants} from '../../../asset-types/asset-type-constants';
import {CalculateRightsText, RightsTooltip} from '../../../assets/asset-rights';
import {timecodeFactory} from '../../../common/timecode/time-code';
import {MessagesContext} from '../../../messages/messages-context';
import {PlayerActions} from '../../../player/player-actions';
import SessionStore from '../../../session/session-store';
import {AssetTitleActions} from '../../../titles/asset-title/asset-title-actions';
import GalleryThumbnail from '../../../titles/asset-title/images/gallery-thumbnail';
import {ClipActions} from '../../../titles/clip/clip-actions';
import {TitleConstants} from '../../../titles/title-actions';
import {BreakIntoGroups, IsNotTriggerKey} from '../../../utils/utils';
import {OrderConstants} from '../../order-constants';
import OrderStore from '../../order-store';

import 'datatables.net-responsive-bs/css/responsive.bootstrap.css';
import '../../../styles/app/dataTables.brainiac.css';
import '../../../styles/app/responsive.brainiac.css';
import 'datatables.net-responsive-bs';
import '../../../../node_modules/bootstrap/js/dropdown';

const TitleTypesMap = Object.keys(TitleConstants.TITLE_TYPES).reduce((r, j) => {
    let titleType = TitleConstants.TITLE_TYPES[j];
    r[titleType.id] = titleType;
    return r;
}, {});

class InnerTable extends React.Component {
    static get propTypes() {
        return {
            wrongVideoFormatPresent: PropTypes.func,
            assetType: PropTypes.number.isRequired,
            canDownloadFull : PropTypes.bool.isRequired,
            canDownloadLoRes : PropTypes.bool.isRequired,
            canDownloadSource : PropTypes.bool.isRequired,
            canDownloadWithNeedsApproval : PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
            canDownloadNonServiceable : PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
            canRemove: PropTypes.bool.isRequired,
            cartId: PropTypes.number.isRequired,
            items: PropTypes.instanceOf(Immutable.List).isRequired,
            isCart: PropTypes.bool,
            orderStatus: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
            selectedVideoFileFormat: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
            tableId: PropTypes.string.isRequired,
            titleIndex: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
        };
    }

    static get defaultProps() {
        return {
            isCart: false,
            wrongVideoFormatPresent: () => {null;},
        };
    }

    constructor(props) {
        super(props);

        this.getDownloadButton = this.getDownloadButton.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.slashOnNull = this.slashOnNull.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.loadData = this.loadData.bind(this);

        return;
    }

    componentDidMount() {
        this.$table = jQuery('#'+this.props.tableId);
        let dataTableConfig = {
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 'no-sort',
                orderable: false
            }, {
                className: 'no-padding text-center',
                targets: 0
            }],
            order: [[1, 'asc']],
            iDisplayLength: 100,
            info: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        };
        this.$tableAPI = this.$table.DataTable(dataTableConfig);

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        this.loadData(this.props);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.handleResize();
        return;
    }

    UNSAFE_componentWillUpdate(nextProps/*, nextState */) {
        this.loadData(nextProps);
        this.handleResize();
        return;
    }

    componentWillUnmount() {
        // check if undefinded only to enable render test
        /* istanbul  ignore else */
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    static contextType = MessagesContext;

    /* istanbul ignore next */
    getDownloadButton(item) {
        let downloadButton = this.context.intl.messages[`cart.view.${item.get('status')}`];
        let downloadHiRes = '';
        let downloadLoRes = '';
        let downloadSource = '';

        if (this.props.canDownloadLoRes) {
            const lowResFileType = item.getIn(['asset', 'previewResolutionFileType']) || '';
            downloadLoRes = `<li><a class="Cur(p) dropdown-toggle image-download-link" data-image-id="${item.get('assetId')}" data-order-item-id="${item.get('id')}" data-title-id="${item.get('titleId')}" data-image-type="JPG" data-rendition="LoRes">${this.context.intl.messages['asset.image.gallery.renditions.low-res']} ${lowResFileType}</a></li>`;
        }
        if (this.props.canDownloadFull) {
            const hiResFileType = item.getIn(['asset', 'fullResolutionFileType']) || '';
            downloadHiRes = `<li><a class="Cur(p) dropdown-toggle image-download-link" data-image-id="${item.get('assetId')}" data-order-item-id="${item.get('id')}" data-title-id="${item.get('titleId')}" data-image-type="JPG" data-rendition="HiRes">${this.context.intl.messages['asset.image.gallery.renditions.full-res']} ${hiResFileType}</a></li>`;
        }
        if (this.props.canDownloadSource) {
            const sourceFileType = item.getIn(['asset', 'mediaFile']) || '';
            downloadSource = `<li><a class="Cur(p) dropdown-toggle image-download-link" data-image-id="${item.get('assetId')}" data-order-item-id="${item.get('id')}" data-title-id="${item.get('titleId')}" data-image-type="${item.getIn(['asset', 'mediaFile'])}" data-rendition="Source">${this.context.intl.messages['asset.image.gallery.renditions.source']} ${sourceFileType}</a></li>`;
        }

        if (downloadHiRes || downloadLoRes || downloadSource) {
            downloadButton = `
                <a href="#" data-toggle="dropdown" class="dropdown-toggle"><span class="glyphicon glyphicon-download-alt"></span> ${this.context.intl.messages['asset.image.gallery.download']}</a>
                <ul class="dropdown-menu dropdown-menu-right dropdown-download-menu">
                    ${downloadLoRes}
                    ${downloadHiRes}
                    ${downloadSource}
                </ul>
            `;
        }

        return downloadButton;
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    slashOnNull(value) {
        let val = '-';

        if (value) {
            val = value;
        }

        return val;
    }

    handleRowClick(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();
            return;
        }

        switch (true) {
        // Handle click on delete.
        case !!~event.target.className.indexOf('delete-item'):
            event.preventDefault();
            let itemId = parseInt(event.target.getAttribute('data-item-id'), 10);
            let titleIndex = parseInt(event.target.getAttribute('data-title-index'), 10);
            let assetType = parseInt(event.target.getAttribute('data-asset-type'), 10);
            OrderActions.remove(assetType, this.props.cartId, titleIndex, itemId);
            break;
        case !!~event.target.className.indexOf('play-video'):
            event.preventDefault();
            /* istanbul ignore next */
            if (!event.target.getAttribute('data-video-id') || !event.target.getAttribute('data-title-id')) {return;}
            let videoId = parseInt(event.target.getAttribute('data-video-id'), 10);
            let titleId = parseInt(event.target.getAttribute('data-title-id'), 10);
            let clipId = parseInt(event.target.getAttribute('data-clip-id'), 10);
            let clip;
            if (clipId) {
                const clipOrderItem = this.props.items.find((c) => c.get('assetId') === clipId);
                /* istanbul ignore else */
                if (clipOrderItem) {
                    clip = clipOrderItem.get('asset');
                    ClipActions.playUserClip(clip, titleId);
                }
            } else {
                PlayerActions.showPlayer(
                    Immutable.fromJS({assetId:videoId, titleId}),
                    undefined,
                    titleId,
                );
            }
            break;
        case !!~event.target.className.indexOf('show-image'):
            event.preventDefault();
            /* istanbul ignore next */
            if (!event.target.getAttribute('data-image-id')) {return;}
            let imageId = parseInt(event.target.getAttribute('data-image-id'), 10);
            let asset = this.props.items.find((item) => {
                return item.get('assetId') === imageId;
            });
            /* istanbul ignore else */
            if (asset) {
                AssetTitleActions.openGallery(asset);
            }
            break;
        case !!~event.target.className.indexOf('image-download-link'):
            const assetId = event.target.getAttribute('data-image-id');
            const orderItemId = event.target.getAttribute('data-order-item-id');
            const renditionType = event.target.getAttribute('data-rendition');
            const title = event.target.getAttribute('data-title-id');

            Analytics.imageDownloadEvent(assetId, renditionType, title, this.props.cartId, orderItemId);
            /* istanbul ignore else */
            if (renditionType === 'Source') {
                AssetTitleActions.downloadAsset(assetId);
                break;
            }

            OrderActions.download(orderItemId, renditionType);
            break;
        case !!~event.target.className.indexOf('download-video'):
            event.preventDefault();
            /* istanbul ignore next */
            if (!event.target.getAttribute('data-order-item-id')) {return;}
            const dataVideoId = parseInt(event.target.getAttribute('data-video-id'), 10);
            const dataOrderItemId = parseInt(event.target.getAttribute('data-order-item-id'), 10);
            OrderActions.downloadVideoOrderItem(this.props.cartId, dataOrderItemId, dataVideoId);
            break;
        /* istanbul ignore next */case !!~event.target.className.indexOf('dropdown-toggle'):
        /* istanbul ignore next */case !!~event.target.className.indexOf('linkto'):
            break;

        default:
            event.preventDefault();
        }

        return;
    }

    loadData(props) {
        this.$tableAPI.clear();
        const isVideo = props.assetType === AssetTypeConstants.ASSET_TYPE.VIDEO;
        const isClip = props.assetType === AssetTypeConstants.ASSET_TYPE.VIDEO_USER_CLIP;

        props.items.forEach( item => {
            let icon = 'glyphicon-picture';
            let row = [];
            let assetName = `<div class="not-available-item">${this.context.intl.messages['common.unknown.asset']}</div>`;
            let notAvailableItem = '';

            /* istanbul ignore next */
            if (!item.get('asset')) {
                notAvailableItem = 'not-available-item';
            }

            if (isVideo || isClip) {
                icon = 'glyphicon-play-circle';
                let videoIcon = `<span class="glyphicon ${icon} play-video not-available-item"></span>`;
                const asset = item.get('asset');
                /* istanbul ignore else */
                if (asset) {
                    let videoAssetId = item.get('assetId');
                    let clipAssetId = null;
                    assetName = GetVideoDisplayName(item.get('asset'));
                    if (isClip) {
                        videoAssetId = asset.get('videoId');
                        clipAssetId = item.get('assetId');
                        assetName = asset.get('name');
                    }

                    const titleId = item.get('titleId');
                    videoIcon = `<a href="#" class="play-video" data-video-id="${videoAssetId}" data-title-id="${titleId}" data-clip-id="${clipAssetId}"><span class="glyphicon ${icon} play-video" data-video-id="${videoAssetId}" data-title-id="${titleId}" data-clip-id="${clipAssetId}"></span></a>`;
                    assetName = `<a href="#" class="play-video" data-video-id="${videoAssetId}" data-title-id="${titleId}" data-clip-id="${clipAssetId}">${assetName || 'Loading...'}</a>`;
                }
                row.push(videoIcon);
                row.push(assetName);
                let description = null;

                if (AssetTypeConstants.VIDEO_CONTENT_TYPE[item.getIn(['asset', 'contentType'])]) {
                    description = AssetTypeConstants.VIDEO_CONTENT_TYPE[item.getIn(['asset', 'contentType'])].description;
                }

                if (isVideo) {
                    row.push(`<div class="${notAvailableItem}">${this.slashOnNull(description)}</div>`);
                    row.push(`<div class="${notAvailableItem}">${this.slashOnNull(item.getIn(['asset', 'runtime']))}</div>`);
                } else {
                    const frameRate = item.getIn(['asset', 'frameRate']);
                    const timecode = timecodeFactory({frameRate});
                    const framesCount = item.getIn(['asset', 'tcOutFrameRounded']) - item.getIn(['asset', 'tcInFrameRounded']);
                    const seconds = Math.round(timecode.framesToSeconds(framesCount));
                    row.push(`<div class="${notAvailableItem}">${timecode.secondsToTime(seconds, false)}</div>`);
                }
                row.push(`<span class="text-default-color ${notAvailableItem}" data-toggle="tooltip" data-placement="top" title="${RightsTooltip(item.get('asset'), this.context.intl.messages)}"><span class="glyphicon glyphicon-info-sign"></span> ${CalculateRightsText(item.get('asset'), this.context.intl.messages)}</span>`);
                if (this.props.isCart || props.orderStatus === OrderConstants.ORDER.STATUS_BY_NAME.SAVED) {
                    const videoFileFormat = GetDeliverableFormatData(item.get('asset'));

                    //If video is not what the user has set for default format we paint it red and flag it
                    if (!videoFileFormat.videoExtensions.includes(props.selectedVideoFileFormat.idForAPI)) {
                        // BRAIN-3258: We force the MXF tooltip to show on Moments temporarily
                        if (isClip) {
                            row.push(`<span class="text-default-color label label-gray" data-toggle="tooltip" data-placement="top" title="${this.context.intl.messages['common.video-delivery-format.mxf.tooltip']}">${this.context.intl.messages['common.video-delivery-format.mxf']}</span>`);
                        } else {
                            row.push(`<span class="text-default-color label label-danger" data-toggle="tooltip" data-placement="top" title="${this.context.intl.messages[videoFileFormat.tooltip]}"><i class="fa-solid fa-triangle-exclamation"></i>&nbsp;${this.context.intl.messages[videoFileFormat.text]}</span>`);
                        }
                        this.props.wrongVideoFormatPresent();
                    } else {
                        row.push(`<span class="text-default-color ${videoFileFormat.className}" data-toggle="tooltip" data-placement="top" title="${this.context.intl.messages[videoFileFormat.tooltip]}">${this.context.intl.messages[videoFileFormat.text]}</span>`);
                    }

                    //If video is N/A as format we flag as with defect as well.
                    if (videoFileFormat.videoExtensions.includes('N/A')) {
                        this.props.wrongVideoFormatPresent();
                    }
                }
                if (isVideo) {
                    // Only display status on videos, not moments/clips
                    row.push(`<div class="${notAvailableItem}">${this.slashOnNull(this.context.intl.messages[`cart.view.${item.get('status')}`])}</div>`);
                }
                row.push(`<span class="${OrderConstants.DELIVERY_SPEED_TYPE[item.get('deliverySpeedType')]?.iconClass}" href="#" data-toggle="tooltip" data-placement="top" title="${this.context.intl.messages[OrderConstants.DELIVERY_SPEED_TYPE[item.get('deliverySpeedType')]?.toggleText]}"></span>`);
            } else {
                let imageIcon = `<span class="glyphicon ${icon} linkto not-available-item"></span>`;
                if (item.get('asset')) {
                    imageIcon = `<a class="show-image" data-image-id="${item.get('assetId')}" href="#"><span class="glyphicon ${icon} show-image" data-image-id="${item.get('assetId')}" href="#"></span></a>`;
                    assetName = item.getIn(['asset', 'assetComment']) || item.getIn(['asset', 'assetName']);
                    assetName = `<a class="show-image" data-image-id="${item.get('assetId')}" href="#">${assetName || this.context.intl.messages['common.unknown.asset']}</a>`;

                }
                row.push(imageIcon);
                row.push(assetName);
                row.push(`<div class="${notAvailableItem}">${this.slashOnNull(this.context.intl.messages[`asset.image.content-types.${item.getIn(['asset', 'contentType'])}`])}</div>`);

                const isImage = props.assetType === AssetTypeConstants.ASSET_TYPE.IMAGE;
                const deliveryType = item.getIn(['asset', 'deliveryType']);

                if (isImage) {
                    const canDownloadImage = OrderStore.canDownloadImage(item.get('asset'), item.get('status'), props.orderStatus);
                    if (Object.values(canDownloadImage).some(v => v === true)) {
                        row.push(`<div class="${notAvailableItem}">${this.getDownloadButton(item)}</div>`);
                    } else {
                        row.push(`<div class="${notAvailableItem}">${this.context.intl.messages[`cart.view.${item.get('status')}`]}</div>`);
                    }
                } else if (
                    deliveryType === AssetTypeConstants.DELIVERY_TYPES.ON_DEMAND ||
                    (deliveryType === AssetTypeConstants.DELIVERY_TYPES.NEEDS_APPROVAL && props.canDownloadWithNeedsApproval) ||
                    (deliveryType === AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE && props.canDownloadNonServiceable) &&
                    ['downloaded', 'readyToDownload'].indexOf(item.get('status')) !== -1
                ) {
                    // Allow downloading the asset if:
                    // 1. delivery type is ON DEMAND
                    // 2. delivery type is NEEDS APPROVAL, but user can download NEEDS APPROVAL images
                    // 3. item status is downloaded or ready to download
                    row.push(`<div class="${notAvailableItem}">${this.getDownloadButton(item)}</div>`);
                } else {
                    row.push(`<div class="${notAvailableItem}">${this.context.intl.messages[`cart.view.${item.get('status')}`]}</div>`);
                }
            }
            const canDownload = OrderStore.canDownloadVideo(item.get('asset'));
            if (isVideo && this.props.isCart && canDownload) {
                row.push(`
                    <div class="download-video">
                        <a class="Cur(p) download-video" data-video-id="${item.get('assetId')}"
                            data-order-item-id="${item.get('id')}"
                        ><span class="glyphicon glyphicon-download-alt"></span>&nbsp;
                            ${this.context.intl.messages['cart.download.video.mp4']}
                        </a>
                    </div>
                `);
            }

            if (props.canRemove) {
                row.push(`<a class="${notAvailableItem}" href=""> <span class="glyphicon glyphicon-trash delete-item ${notAvailableItem}" data-title-index=${props.titleIndex} data-asset-type=${props.assetType} data-item-id=${item.get('id')}></span></a>`);
            }
            row.push('');
            this.$tableAPI.row.add(row);
        });

        this.$tableAPI.draw(false);
        // this.$table.find('[data-toggle="tooltip"]').tooltip?.();
        // this.$table.find('[data-toggle="dropdown"]').dropdown();
    }

    render() {
        if (this.props.items.size === 0) {
            // render nothing
            return null;
        }

        let removeHeader;
        if (this.props.canRemove) {
            removeHeader = <th className="no-sort icon-column" scope="col"><Glyphicon glyph="trash"/></th>;
        }

        let runtimeTitle;
        let rightsTitle;
        let contentType;
        let format;
        let statusHeader;
        let deliverySpeed;
        let directDownload;
        const canDownload = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.VIDEOS);
        const isMoment = this.props.assetType === AssetTypeConstants.ASSET_TYPE.VIDEO_USER_CLIP;
        const isVideo = this.props.assetType === AssetTypeConstants.ASSET_TYPE.VIDEO;
        const isImage = this.props.assetType === AssetTypeConstants.ASSET_TYPE.IMAGE;
        let assetIntlKey = 'cart.view.table.asset-name';

        if (isVideo || isImage) {
            contentType = <th className="no-sort" style={{width: '180px'}} scope="col">{this.context.intl.messages['cart.view.table.content-type']}</th>;
        }
        if (isVideo) {
            runtimeTitle = <th className="no-sort" style={{width: '100px'}} rowSpan="1" colSpan="1" scope="col">{this.context.intl.messages['cart.view.table.runtime']}</th>;
        }
        if (isMoment) {
            assetIntlKey = 'cart.view.table.moment-name';
            runtimeTitle = <th className="no-sort" style={{width: '100px'}} rowSpan="1" colSpan="1" scope="col">{this.context.intl.messages['cart.view.table.moment-length']}</th>;
        }
        if (isMoment || isVideo) {
            rightsTitle = <th className="no-sort" style={{width: '110px'}} rowSpan="1" colSpan="1" scope="col">{this.context.intl.messages['cart.view.table.rights']}</th>;
            if (this.props.isCart || this.props.orderStatus === OrderConstants.ORDER.STATUS_BY_NAME.SAVED) {
                format = <th className="no-sort" style={{width: '110px'}} rowSpan="1" colSpan="1" scope="col">{this.context.intl.messages['cart.view.table.format']}</th>;
            }
        }
        if (isVideo) {
            statusHeader = <th scope="col" className="no-sort" style={{width: '75px'}}>{this.context.intl.messages['cart.view.table.status']}</th>;
        }
        if (isMoment || isVideo) {
            deliverySpeed = <th className="no-sort" style={{width: '30px'}} rowSpan="1" colSpan="1" scope="col"><span className="glyphicon glyphicon-time"/></th>;
        }
        if ((isVideo && this.props.isCart && canDownload) || isImage) {
            directDownload = <th className="no-sort" style={{width: '110px'}} rowSpan="1" colSpan="1" scope="col">{this.context.intl.messages['cart.view.table.direct-download']}</th>;
        }

        return (
            <table className="table dataTable table-striped datatable-responsive" id={this.props.tableId}>
                <thead>
                    <tr>
                        <th scope="col" className="no-sort icon-column"></th>
                        <th scope="col">{this.context.intl.messages[assetIntlKey]}</th>
                        {contentType}
                        {runtimeTitle}
                        {rightsTitle}
                        {format}
                        {statusHeader}
                        {deliverySpeed}
                        {directDownload}
                        {removeHeader}
                        <th scope="col" className="no-sort icon-column"/>
                    </tr>
                </thead>
                <tbody role="button" tabIndex="0" onClick={this.handleRowClick} onKeyUp={this.handleRowClick}>
                </tbody>
            </table>
        );
    }
}

export default class AssetTable extends React.Component {
    static get propTypes() {
        return {
            wrongVideoFormatPresent: PropTypes.func,
            assetType: PropTypes.number.isRequired,
            canDownloadAle: PropTypes.bool,
            canDownloadFull: PropTypes.bool,
            canDownloadLoRes: PropTypes.bool,
            canDownloadSource: PropTypes.bool,
            canDownloadWithNeedsApproval: PropTypes.bool,
            canDownloadNonServiceable: PropTypes.bool,
            canRemove: PropTypes.bool,
            cartId: PropTypes.number.isRequired,
            display: PropTypes.oneOf(['grid', 'list']),
            downloadRenditionType: PropTypes.string,
            enableGrid: PropTypes.bool,
            icon: PropTypes.string.isRequired,
            isCart: PropTypes.bool.isRequired,
            location: PropTypes.object.isRequired,
            onDisplayChange: PropTypes.func,
            orderStatusCanDownload: PropTypes.bool,
            orderStatus: PropTypes.number.isRequired,
            selectedVideoFileFormat: PropTypes.object.isRequired,
            title: PropTypes.string.isRequired,
            titles: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get defaultProps() {
        return {
            canDownloadAle: false,
            canDownloadFull: false,
            canDownloadLoRes: false,
            canDownloadSource: false,
            canDownloadWithNeedsApproval: false,
            canDownloadNonServiceable: false,
            canRemove: false,
            display: 'grid',
            downloadRenditionType: '',
            enableGrid: false,
            onDisplayChange: /* istanbul ingnore next */() => /* istanbul ingnore next */void 0,
            orderStatusCanDownload: false,
            wrongVideoFormatPresent: /* istanbul ingnore next */() => /* istanbul ingnore next */void 0,
        };
    }

    constructor(props) {
        super(props);

        this.handleDisplayClick = this.handleDisplayClick.bind(this);
        this.handleDownloadAle = this.handleDownloadAle.bind(this);
        this.handleDownloadAll = this.handleDownloadAll.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleViewImageClick = this.handleViewImageClick.bind(this);

        return;
    }

    static contextType = MessagesContext;

    handleDisplayClick(type, event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }
        this.props.onDisplayChange(type);
        return;
    }

    handleDownloadAle() {
        OrderActions.downloadALE(this.props.cartId);
    }

    handleDownloadAll(renditionType, cartId, items) {
        items.forEach( item => {
            const assetId = item.get('assetId');
            const titleId = item.get('titleId');
            Analytics.imageDownloadEvent(assetId, renditionType.name, titleId, cartId, item.get('id'));
        });

        OrderActions.downloadAll(renditionType.type);

        /* istanbul ignore else */
        if (!this.props.isCart) {
            const assetIds = [];
            this.props.titles.forEach(title => {
                title.get('items').forEach(item => {
                    assetIds.push(item.get('assetId'));
                });
            });

            OrderActions.startDownload(
                false,
                cartId,
                renditionType.type,
                null,
                `${this.props.location.pathname}/download`
            );
            OrderActions.clearDownloads();
        }

        return;
    }

    handleRemove(itemId, titleIndex) {
        OrderActions.remove(this.props.assetType, this.props.cartId, titleIndex, itemId);
    }

    handleViewImageClick(image) {
        AssetTitleActions.openGallery(image);
    }

    render() {
        let isVisible = this.props.titles.reduce( (visible, title) => {
            if (visible || title.get('items').size > 0) {
                return true;
            }
            return false;
        }, false);

        if (!isVisible) {
            return null;
        }

        let displayOptions;
        if (this.props.enableGrid) {
            displayOptions = <div className="pull-right text-center">
                {this.context.intl.messages['cart.view.display']}
                <span
                    aria-label={this.context.intl.messages['common.grid-view']}
                    className={ClassNames('display-type-switch glyphicon glyphicon glyphicon-th margin-x-5', {active: this.props.display === 'grid'})}
                    onClick={this.handleDisplayClick.bind(this, 'grid')}
                    onKeyUp={this.handleDisplayClick.bind(this, 'grid')}
                    role="button" tabIndex="0"
                    title="Grid"
                />
                <span
                    aria-label={this.context.intl.messages['common.list-view']}
                    className={ClassNames('display-type-switch glyphicon glyphicon glyphicon-align-justify', {active: this.props.display === 'list'})}
                    onClick={this.handleDisplayClick.bind(this, 'list')}
                    onKeyUp={this.handleDisplayClick.bind(this, 'list')}
                    role="button" tabIndex="0"
                    title="List"
                />
            </div>;
        }

        let dwnldLowResImageButton;
        let dwnldHighResImageButton;
        let dwnldSourceImageButton;
        let dwnldAleButton;
        let dwnldLowResImageButtonLabel = this.context.intl.messages['cart.view.download.download-all-images'];
        let hasImagesToDownload = false;
        const assetItems = [];

        this.props.titles.forEach(title => {
            title.get('items').forEach(item => {
                assetItems.push(item);
                /* istanbul ignore if */
                if (OrderStore.isReadyToDownload(item.get('status'))) {
                    hasImagesToDownload = true;
                }
            });
        });

        /* istanbul ignore if */
        if (hasImagesToDownload) {
            const canDownloadSource = this.props.canDownloadSource || (this.props.orderStatusCanDownload && this.props.downloadRenditionType.id === 'Source');
            const canDownloadHiRes = this.props.canDownloadFull || canDownloadSource || (this.props.orderStatusCanDownload && this.props.downloadRenditionType.id === 'HiRes');
            const canDownloadLoRes = this.props.canDownloadLoRes || canDownloadHiRes || this.props.orderStatusCanDownload;

            if (canDownloadHiRes || canDownloadSource) {
                // This is where we change the LowRes button label
                dwnldLowResImageButtonLabel = this.context.intl.messages['cart.view.download.download-all-low-res-images'];
            }

            dwnldLowResImageButton = (
                <Button
                    className={ClassNames('btn-secondary pull-right margin-right-5', {'hidden': !canDownloadLoRes})}
                    onClick={this.handleDownloadAll.bind(this, OrderConstants.DOWNLOAD_RENDITION_TYPE.LoRes, this.props.cartId, assetItems)}>
                    <span><span className="glyphicon glyphicon-download-alt"/> {dwnldLowResImageButtonLabel}</span>
                </Button>
            );

            dwnldHighResImageButton = (
                <Button
                    className={ClassNames('btn-secondary pull-right margin-right-5', {'hidden': !canDownloadHiRes})}
                    onClick={this.handleDownloadAll.bind(this, OrderConstants.DOWNLOAD_RENDITION_TYPE.HiRes, this.props.cartId, assetItems)}>
                    <span><span className="glyphicon glyphicon-download-alt"/> {this.context.intl.messages['cart.view.download.download-all-high-res-images']}</span>
                </Button>
            );

            dwnldSourceImageButton = (
                <Button
                    className={ClassNames('btn-secondary pull-right', {'hidden': !canDownloadSource})}
                    onClick={this.handleDownloadAll.bind(this, OrderConstants.DOWNLOAD_RENDITION_TYPE.Source, this.props.cartId, assetItems)}>
                    <span><span className="glyphicon glyphicon-download-alt"/> {this.context.intl.messages['cart.view.download.download-all-source-images']}</span>
                </Button>
            );
        }

        if (this.props.canDownloadAle) {
            dwnldAleButton = (
                <Button
                    className="btn-secondary pull-right"
                    onClick={this.handleDownloadAle}
                >
                    <span className="glyphicon glyphicon-download-alt"/>
                    &nbsp;
                    <FormattedMessage id="cart.view.download.download-ale" />
                </Button>
            );
        }

        // k is the order in the items array
        // i is the order in inner assets table
        // empty titles are hidden (but still on the array)
        let i = 0;

        return (
            <div className="clearfix">
                <div className="clearfix padding-y-10">
                    <h3 className="pull-left" style={{padding:'0px', margin:'0px'}}>
                        <Glyphicon glyph={this.props.icon}/> {this.props.title}
                    </h3>
                    {displayOptions}
                </div>
                <table className="table cart-parent-table">
                    <thead>
                        <tr>
                            <th scope="col" className="no-sort control icon-column">#</th>
                            <th scope="col" className="cart-ro-column">{this.context.intl.messages['cart.view.table.full-running-order']}</th>
                            <th scope="col" className="cart-title-column">{this.context.intl.messages['cart.view.table.title']}</th>
                            <th scope="col">{this.props.title}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.titles.map((title, k) => {
                            /* istanbul ignore if */
                            if (title.get('items').size === 0) {
                                // render nothing
                                return null;
                            }
                            i++;
                            let tableId = `asset-type-${this.props.assetType}-order-${k}`;

                            let items = (
                                <InnerTable
                                    wrongVideoFormatPresent={this.props.wrongVideoFormatPresent}
                                    assetType={this.props.assetType}
                                    cartId={this.props.cartId}
                                    canDownloadFull={this.props.canDownloadFull}
                                    canDownloadLoRes={this.props.canDownloadLoRes}
                                    canDownloadSource={this.props.canDownloadSource}
                                    canDownloadWithNeedsApproval={this.props.canDownloadWithNeedsApproval}
                                    canDownloadNonServiceable={this.props.canDownloadNonServiceable}
                                    canRemove={this.props.canRemove}
                                    items={title.get('items')}
                                    isCart={this.props.isCart}
                                    orderStatus={this.props.orderStatus}
                                    selectedVideoFileFormat={this.props.selectedVideoFileFormat}
                                    tableId={tableId}
                                    titleIndex={k}
                                />
                            );

                            /* istanbul ignore else */
                            if (this.props.display === 'grid' && (this.props.assetType === AssetTypeConstants.ASSET_TYPE.IMAGE)) {
                                items = (
                                    <div className="display-results display-results-grid" key={k}>
                                        {BreakIntoGroups(title.get('items'), 4).map((g1, i1) => (
                                            <div className="row" key={i1}>
                                                {BreakIntoGroups(g1, 1).map((g2, i2) => (
                                                    <div className="col-xs-6 col-lg-3" key={i2}>
                                                        <div className="row">
                                                            <GalleryThumbnail
                                                                canRemove={this.props.canRemove}
                                                                onRemove={this.handleRemove.bind(this, g2.getIn([0, 'id']), k)}
                                                                image={g2.getIn([0, 'asset'])}
                                                                imageStatus={g2.getIn([0, 'status'])}
                                                                pathname={this.props.location.pathname}
                                                                onViewImageClick={this.handleViewImageClick.bind(this, g2.getIn([0, 'asset']))}
                                                                orderId={this.props.cartId}
                                                                orderItemId={g2.getIn([0, 'id'])}
                                                                orderStatus={this.props.orderStatus}
                                                                showPlaceholder={((g2.getIn([0, 'asset']) === undefined) && g2.getIn([0, 'titleId']))}
                                                            />
                                                            <GalleryThumbnail
                                                                canRemove={this.props.canRemove}
                                                                onRemove={this.handleRemove.bind(this, g2.getIn([0, 'id']), k)}
                                                                image={g2.getIn([1, 'asset'])}
                                                                imageStatus={g2.getIn([1, 'status'])}
                                                                pathname={this.props.location.pathname}
                                                                onViewImageClick={this.handleViewImageClick.bind(this, g2.getIn([1, 'asset']))}
                                                                orderId={this.props.cartId}
                                                                orderItemId={g2.getIn([1, 'id'])}
                                                                orderStatus={this.props.orderStatus}
                                                                showPlaceholder={((g2.getIn([1, 'asset']) === undefined) && g2.getIn([1, 'titleId']))}
                                                            />
                                                            <div className="clearfix visible-xs"></div>
                                                            <GalleryThumbnail
                                                                canRemove={this.props.canRemove}
                                                                onRemove={this.handleRemove.bind(this, g2.getIn([0, 'id']), k)}
                                                                image={g2.getIn([2, 'asset'])}
                                                                imageStatus={g2.getIn([2, 'status'])}
                                                                pathname={this.props.location.pathname}
                                                                onViewImageClick={this.handleViewImageClick.bind(this, g2.getIn([2, 'asset']))}
                                                                orderId={this.props.cartId}
                                                                orderItemId={g2.getIn([2, 'id'])}
                                                                orderStatus={this.props.orderStatus}
                                                                showPlaceholder={((g2.getIn([2, 'asset']) === undefined) && g2.getIn([2, 'titleId']))}
                                                            />
                                                            <GalleryThumbnail
                                                                canRemove={this.props.canRemove}
                                                                onRemove={this.handleRemove.bind(this, g2.getIn([0, 'id']), k)}
                                                                image={g2.getIn([3, 'asset'])}
                                                                imageStatus={g2.getIn([3, 'status'])}
                                                                pathname={this.props.location.pathname}
                                                                onViewImageClick={this.handleViewImageClick.bind(this, g2.getIn([3, 'asset']))}
                                                                orderId={this.props.cartId}
                                                                orderItemId={g2.getIn([3, 'id'])}
                                                                orderStatus={this.props.orderStatus}
                                                                showPlaceholder={((g2.getIn([3, 'asset']) === undefined) && g2.getIn([3, 'titleId']))}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                );
                            }

                            let titleCategory = title.getIn(['items', 0, 'titleCategory']);
                            let descriptionItem = '';
                            let titleName = title.get('name') || this.context.intl.messages['common.unknown.title'];

                            /* istanbul ignore else */
                            if (titleCategory !== null ) {
                                switch (TitleTypesMap[titleCategory].categoryGroup) {
                                case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                                    descriptionItem = title.getIn(['items', 0, 'fullRunningOrder']);
                                    titleName = title.getIn(['items', 0, 'parentTitleDisplayName']);
                                    break;
                                case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                                    descriptionItem = title.getIn(['items', 0, 'fullRunningOrder']);
                                    break;
                                case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
                                    descriptionItem = this.context.intl.messages['cart.view.table.series'];
                                    break;
                                case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
                                case TitleConstants.TITLE_CATEGORY_GROUPS.FORMAT_RIGHTS:
                                    descriptionItem = this.context.intl.messages['cart.view.table.feature'];
                                    break;
                                case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
                                    descriptionItem = this.context.intl.messages['cart.view.table.mini-series'];
                                    break;
                                }
                            }

                            return (
                                <tr key={k}>
                                    <td className="cart-parent-number"><strong>{i}</strong></td>
                                    <td className="cart-parent-title">{descriptionItem}</td>
                                    <td className="cart-parent-title"><strong className={ClassNames({'not-available-item': titleCategory === null})}>{titleName}</strong></td>
                                    <td className="cart-parent-assets">
                                        {items}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {dwnldSourceImageButton}
                {dwnldHighResImageButton}
                {dwnldLowResImageButton}
                {dwnldAleButton}
            </div>
        );
    }
}

export {InnerTable};
