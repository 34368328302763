/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';

import selectStyles from './select-styles';
import {FACET_NAMES} from '../../../../common/facets/constants';
import FacetSelect from '../../../../common/facets/facet-select';
import useGenericFacet from '../../../../common/facets/hooks/use-generic-facet';
import omit from '../../../../common/utils/omit';

const TagFacet = (props) => {
    const facetProps = useGenericFacet(FACET_NAMES.TAG);
    const intl = useIntl();
    const title = intl.messages['search.filters.navbar.titles.tag'];

    return (
        <>
            <h5><strong>{title}</strong></h5>
            <FacetSelect styles={selectStyles} {...props} {...facetProps} />
        </>
    );
};

TagFacet.propTypes = {
    ...FacetSelect.propTypes,
    options: PropTypes.instanceOf(Immutable.List).isRequired,
};

TagFacet.defaultProps = omit(FacetSelect.defaultProps, ['options']);

export default memo(TagFacet);
