/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
/* istanbul ignore file */
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {filter} from 'rxjs/operators';

import {AssetTitleActions, AssetTitleConstants} from './asset-title-actions';
import AssetTitleStore from './asset-title-store';
import AssetFilter from './filter';
import GridView, {GridViewConstants} from './grid-view';
import Photoswipe from './images/photoswipe';
import ListView from './list-view';
import SeasonsSummary from './seasons-summary';
import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import DocumentTitle from '../../common/document-title';
import Config from '../../config/config';
import {MessagesContext} from '../../messages/messages-context';
import {NotificationActions} from '../../notification/notification-actions';
import Pagination from '../../pagination';
import {PlayerActions} from '../../player/player-actions';
import PlayerStore from '../../player/player-store';
import VideoOverlay from '../../player/video-overlay';
import Preloader from '../../preloader';
import {ObservableRoute, RouterActions} from '../../router/router-actions';
import SessionStore from '../../session/session-store';
import SerieNavigationStore from '../../titles/serie-navigation/serie-navigation-store';
import Share from '../../titles/share';
import TitleStore from '../../titles/title-store';
import {IsNotTriggerKey} from '../../utils/utils';
import {EpisodeNavigation} from '../serie-navigation';
import {TitleConstants} from '../title-actions';

/* istanbul ignore next */
const hasFullEpisodeFeatures = (vctFilter) => {
    if (!vctFilter) {
        return true;
    }
    let isFullEpisodeFeatures = false;
    const fullEpisodeOrFeaturesTypes = Object.values(
        AssetTitleConstants.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.TYPES
    );
    if (Array.isArray(vctFilter)) {
        isFullEpisodeFeatures = vctFilter.some(vct => fullEpisodeOrFeaturesTypes.includes(vct));
    } else {
        isFullEpisodeFeatures = fullEpisodeOrFeaturesTypes.includes(vctFilter);
    }
    return isFullEpisodeFeatures;
};
/* istanbul ignore next */
const hasPartnerGroup = () => {
    const partner = SessionStore.getPartner();
    return Config.Partners.PRESS_SITE.id === partner.id || Config.Partners.WBTVD.id === partner.id;
};
/* istanbul ignore next */
const showAirDateInfo = (vctFilter) => hasFullEpisodeFeatures(vctFilter) && hasPartnerGroup();

export default assetType => {
    class AssetTitle extends Component {

        static get propTypes() {
            // TODO: enable this prop and move video requests to video / talent
            // I'm leaving it here to finish info ticket which is not related to this modification
            // entityType: React.PropTypes.oneOf([VideoConstants.ENTITY_TYPE.TITLE, VideoConstants.ENTITY_TYPE.TALENT]).isRequired,
            return {
                location: PropTypes.object.isRequired,
                match: PropTypes.object.isRequired,
                permissions: PropTypes.object.isRequired
            };
        }

        static calculateState() {
            return {
                activePageLabel: AssetTitleStore.getState().get('activePageLabel'),
                assetFacetCounts: AssetTitleStore.getState().get('assetFacetCounts'),
                assetsLoaded: AssetTitleStore.getState().get('assetsLoaded'),
                imagesSeasonCounts: TitleStore.getState().get('imagesSeasonCounts'),
                currentImage: AssetTitleStore.getState().get('currentImage'),
                entityId: AssetTitleStore.getState().get('entityId'),
                entityType: AssetTitleStore.getState().get('entityType'),
                images: AssetTitleStore.getState().get('images'),
                openSharedImage: AssetTitleStore.getState().get('openSharedImage'),
                pageSize: AssetTitleStore.getState().get('pageSize'),
                playerSelectedVideo: PlayerStore.getState().get('selectedVideo'),
                selectedVideo: AssetTitleStore.getState().get('selectedVideo'),
                serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
                seasons: TitleStore.getState().get('seasons'),
                seasonsMap: TitleStore.getState().get('seasonsMap'),
                showOverlay: PlayerStore.getState().get('showOverlay'),
                showShare: AssetTitleStore.getState().get('showShare'),
                single: AssetTitleStore.getState().get('single'),
                title: TitleStore.getState().get('title'),
                totalCount: AssetTitleStore.getState().get('totalCount'),
                unstacked: AssetTitleStore.getState().get('unstacked'),
                video: PlayerStore.getState().get('video'),
                videos: AssetTitleStore.getState().get('videos'),
                videosSeasonCounts: TitleStore.getState().get('videosSeasonCounts'),
                watchlist: PlayerStore.getState().get('watchlist')
            };
        }

        static getStores() {
            return [AssetTitleStore, PlayerStore, SerieNavigationStore, TitleStore];
        }

        static getPermissions() {
            return {
                canAddAllToCart: SessionStore.canUser(SessionStore.PERMISSIONS.CART.IMAGES.ADD_ALL_TO_CART),
                canAddToCartVideo: SessionStore.canUser(SessionStore.PERMISSIONS.CART.VIDEOS.ADD),
                canAddToCartImage: SessionStore.canUser(SessionStore.PERMISSIONS.CART.IMAGES.ADD),
                canEditBrainiac: SessionStore.canUser(SessionStore.PERMISSIONS.EDIT_IN_BRAINIAC),
                canDownloadApproval: SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.NEEDS_APPROVAL),
                canDownloadFull: SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.FULL),
                canDownloadLoRes: SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.LO_RES),
                canDownloadSource: SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.SOURCE),
                canInstantOrderVideos: SessionStore.canUser(SessionStore.PERMISSIONS.CART.VIDEOS.INSTANT_ORDER),
                hasRestrictedAuth: SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.IMAGES.NEEDS_APPROVAL)
            };
        }

        constructor(props) {
            super(props);

            this.state = Object.assign({
                gridView: true,
                showFilters: true,
                showCaptions: false
            }, this.constructor.calculateState());

            this.handleCloseShare = this.handleCloseShare.bind(this);
            this.handleCloseSwipe = this.handleCloseSwipe.bind(this);
            this.handlePageChange = this.handlePageChange.bind(this);
            this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
            this.handleSortChange = this.handleSortChange.bind(this);
            this.handleToggleCaptions = this.handleToggleCaptions.bind(this);
            this.redirectIfEmpty = this.redirectIfEmpty.bind(this);
            this.onPlayerClose = this.onPlayerClose.bind(this);
        }

        componentDidMount() {
            // Allows asset grid view component to show filtering for images/videos
            AssetTitleActions.getTitleAssetFacetCounts(parseInt(this.props.match.params.id, 10), assetType);

            if (this.props.match.params.videoId) {
                // Avoid nasty double dispatch
                setTimeout(() => {
                    PlayerActions.getAndPlay(
                        parseInt(this.props.match.params.videoId),
                        parseInt(this.props.match.params.childVideoId) || null,
                        parseInt(this.props.match.params.id)
                    );
                    return;
                }, 0);
            }

            setTimeout( () => {
                PlayerActions.hidePlayer();
                AssetTitleActions.loading();
            }, 0);

            const query = QueryString.parse(this.props.location.search);
            switch (assetType) {
            case AssetTitleConstants.ASSET_CATEGORY.VIDEO:
                AssetTitleActions.getTitleAssetsVideos(
                    AssetTitleConstants.ENTITY_TYPE.TITLE,
                    this.props.match.params.id,
                    SessionStore.getState().getIn(['authUser', 'id']),
                    query.vct,
                    parseInt(query.offset, 10) || 0,
                    parseInt(query.size, 10) || 80,
                    query['sort-field-name'],
                    query['sort-direction']
                );
                break;

            case AssetTitleConstants.ASSET_CATEGORY.IMAGE:
                let canceler = ObservableRoute.pipe(filter(x => {
                    let cancel = x.pathname.indexOf(`titles/${this.props.match.params.id}`) === -1;
                    return cancel;
                }));

                AssetTitleActions.getTitleAssetsImages(
                    AssetTitleConstants.ENTITY_TYPE.TITLE,
                    this.props.match.params.id,
                    this.props.match.params.imageId,
                    SessionStore.getState().getIn(['authUser', 'id']),
                    query.vct,
                    parseInt(query.offset, 10) || 0,
                    parseInt(query.size, 10) || 80,
                    canceler,
                    query['sort-field-name'],
                    query['sort-direction']
                );
                break;
            }
            return;
        }

        componentWillReceiveProps(nextProps) {
            const nextQuery = QueryString.parse(nextProps.location.search);
            if (this.state.showCaptions && !nextQuery.vct) {
                this.handleToggleCaptions();
            }
        }

        shouldComponentUpdate(nextProps, nextState) {
            return (
                this.state.assetFacetCounts !== nextState.assetFacetCounts ||
                this.state.assetSeasonCounts !== nextState.assetSeasonCounts ||
                this.state.currentImage !== nextState.currentImage ||
                this.state.entityId !== nextState.entityId ||
                this.state.entityType !== nextState.entityType ||
                this.state.images !== nextState.images ||
                this.props.location !== nextProps.location ||
                this.state.openSharedImage !== nextState.openSharedImage ||
                this.state.pageSize !== nextState.pageSize ||
                this.props.match.params.id !== nextProps.match.params.id ||
                this.state.seasonsMap !== nextState.seasonsMap ||
                this.state.showShare !== nextState.showShare ||
                this.state.title.get('categoryGroup') !== nextState.title.get('categoryGroup') ||
                this.state.videos !== nextState.videos
            );
        }

        UNSAFE_componentWillUpdate(nextProps) {
            let getVCT = props => {
                const query = QueryString.parse(props.location.search);
                let result = [];
                if (query.vct) {
                    if (Array.isArray(query.vct)) {
                        result = query.vct;
                    } else {
                        result = [query.vct];
                    }
                }
                return Immutable.fromJS(result);
            };

            let vct = getVCT(this.props);
            let nextVCT = getVCT(nextProps);
            const query = QueryString.parse(this.props.location.search);
            const nextQuery = QueryString.parse(nextProps.location.search);
            if (
                query.offset !== nextQuery.offset ||
                query.size !== nextQuery.size ||
                query['sort-field-name'] !== nextQuery['sort-field-name'] ||
                query['sort-direction'] !== nextQuery['sort-direction'] ||
                this.props.match.params.id !== nextProps.match.params.id ||
                !vct.equals(nextVCT)
            ) {
                AssetTitleActions.loading();

                AssetTitleActions.getTitleAssetFacetCounts(parseInt(this.props.match.params.id, 10), assetType);

                switch (assetType) {
                case AssetTitleConstants.ASSET_CATEGORY.VIDEO:
                    if (showAirDateInfo(nextVCT.toJS()) && !hasFullEpisodeFeatures(vct.toJS()) && !nextQuery['sort-field-name']) {
                        const newQuery = Object.assign(
                            {},
                            nextQuery,
                            {
                                'sort-field-name': 'titlereleasedate',
                                'sort-direction': 'desc'
                            }
                        );
                        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(newQuery)}`);
                        return;
                    }
                    AssetTitleActions.getTitleAssetsVideos(
                        AssetTitleConstants.ENTITY_TYPE.TITLE,
                        this.props.match.params.id,
                        SessionStore.getState().getIn(['authUser', 'id']),
                        nextQuery.vct,
                        parseInt(nextQuery.offset, 10) || 0,
                        parseInt(nextQuery.size, 10) || 80,
                        nextQuery['sort-field-name'],
                        nextQuery['sort-direction']
                    );
                    break;
                case AssetTitleConstants.ASSET_CATEGORY.IMAGE:
                    let canceler = ObservableRoute.pipe(filter(x => {
                        let cancel = x.pathname.indexOf(`titles/${this.props.match.params.id}`) === -1;
                        return cancel;
                    }));
                    AssetTitleActions.getTitleAssetsImages(
                        AssetTitleConstants.ENTITY_TYPE.TITLE,
                        this.props.match.params.id,
                        this.props.match.params.imageId,
                        SessionStore.getState().getIn(['authUser', 'id']),
                        nextQuery.vct,
                        parseInt(nextQuery.offset, 10) || 0,
                        parseInt(nextQuery.size, 10) || 80,
                        canceler,
                        nextQuery['sort-field-name'],
                        nextQuery['sort-direction']
                    );
                    break;
                }
            }
            return;
        }

        componentDidUpdate() {
            let assets = this.state.images;
            if (assetType === AssetTitleConstants.ASSET_CATEGORY.VIDEO) {
                assets = this.state.videos;
            }
            this.redirectIfEmpty(assets, this.state.assetsLoaded, this.props.match.params);
            return;
        }

        componentWillUnmount() {
            // Prevents a double dispatch error if documents or stations tab is loaded before the info tab, then images tab is clicked
            setTimeout(() => {
                AssetTitleActions.clear();
            }, 0);
        }

        static contextType = MessagesContext;

        handleCloseShare() {
            AssetTitleActions.hideShare();
        }

        handleCloseSwipe() {
            let singleMode = !(this.props.location.pathname.match('/titles/\\d+/images/\\d+'));

            if (singleMode) {
                let query = QueryString.parse(this.props.location.search);

                if (!query['keep-scroll'] === true) {
                    query['keep-scroll'] = true;
                }
                // navigate to images
                AssetTitleActions.closeGallery();
                RouterActions.redirect(`/titles/${this.state.title.get('id')}/images/?${QueryString.stringify(query)}`);

            } else {
                AssetTitleActions.closeGallery();
                RouterActions.back();
            }
        }

        handlePageChange(offset) {
            const q = QueryString.parse(this.props.location.search);
            let size = q.size;
            let query = Object.assign(
                {},
                q,
                {
                    offset,
                    size
                }
            );

            RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
            return;
        }

        handlePageSizeChange(event) {
            const q = QueryString.parse(this.props.location.search);
            let offset = 0;
            let size = parseInt(event.target.value);
            let query = Object.assign(
                {},
                q,
                {
                    offset,
                    size
                }
            );

            RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
            AssetTitleActions.updatePageSize(parseInt(event.target.value));
            return;
        }

        handleSortChange(sortFieldName, sortDirection) {
            const q = QueryString.parse(this.props.location.search);
            let query = Object.assign(
                {},
                q,
                {
                    'sort-field-name': sortFieldName,
                    'sort-direction': sortDirection,
                    'keep-scroll': true
                }
            );

            RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
            return;
        }

        handleToggleCaptions(event) {
            if (event && IsNotTriggerKey(event)) {
                event.preventDefault();

                return;
            }

            this.setState(prevState => ({
                showCaptions: !prevState.showCaptions
            }));
        }

        onPlayerClose() {
            if (this.props.match.params.videoId) {
                // redirect
                RouterActions.redirect(`/titles/${this.props.match.params.id}/videos`);
            }
            // hide video overlay
            PlayerActions.hidePlayer();
        }

        redirectIfEmpty(assets, loaded, params) {
            const newArray = Object.keys(assets.toJS()).map((key) => assets.toJS()[key]);
            const numberOfAssets = newArray.reduce((result, element) => result + element.totalCount, 0);
            if (!numberOfAssets && loaded) {
                setTimeout(() => {
                    RouterActions.redirect(`/titles/${params.id}`);
                    NotificationActions.showAlertWarning(`title.error.no-available-asset.${assetType.toLowerCase()}`);
                }, 0);
                return;
            }

            return;
        }

        render() {
            let assetGrid, assetSeasonCounts, categoryGroup, contentTypeFilters,
                documentMessage, assetFilters, lowerPagination, overlay, photoswipe,
                seasonSummary, share, showPreloader = false, upperPaginationAndFilters;

            //assetTypePath value is either 'images' or 'videos'
            let assetTypePath = `${assetType.toLowerCase()}s`;
            const query = QueryString.parse(this.props.location.search);
            let offset = parseInt(query.offset, 10) || 0;
            let pageSize = parseInt(query.size, 10) || 80;

            categoryGroup = this.state.title.get('categoryGroup');

            const titleId = parseInt(this.props.match.params.id, 10);

            switch (assetType) {

            case AssetTitleConstants.ASSET_CATEGORY.VIDEO:
                documentMessage = 'document-titles.title.videos';
                assetSeasonCounts = this.state.videosSeasonCounts;

                if (query.vct) {
                    if (Array.isArray(query.vct)) {
                        contentTypeFilters = Immutable.Set.of(...query.vct);
                    } else {
                        contentTypeFilters = Immutable.Set.of(query.vct);
                    }
                } else {
                    contentTypeFilters = Immutable.Set.of(
                        ...AssetTitleConstants.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.VIDEO.PROMO.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.VIDEO.ELEMENT.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.VIDEO.BEHIND_THE_SCENES.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.VIDEO.SALES.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.VIDEO.SAMPLE.ALL
                    );
                }

                let videosForList = this.state.videos.findEntry(videos => !!videos.get('results').size, undefined, []);

                let showAirDate = showAirDateInfo(query.vct);

                assetGrid = (
                    <ListView
                        assetType = {assetType}
                        canAddToCart={this.props.permissions.canAddToCartVideo}
                        canInstantOrderVideos={this.props.permissions.canInstantOrderVideos}
                        location={this.props.location}
                        onSortChange={this.handleSortChange}
                        showAirDate={showAirDate}
                        title={this.state.title}
                        videos={videosForList[1]}
                        videoSuperType={videosForList[0]}
                    />
                );

                if ((!this.state.assetsLoaded && !this.props.match.params.videoId)) {
                    showPreloader = true;
                }
                /* istanbul ignore next */
                if (this.state.gridView) {
                    assetGrid = (
                        <GridView
                            assetType = {assetType}
                            canAddToCart={this.props.permissions.canAddToCartVideo}
                            canInstantOrderVideos={this.props.permissions.canInstantOrderVideos}
                            location={this.props.location}
                            onSortChange={this.handleSortChange}
                            serieNavigation={this.state.serieNavigation}
                            showAirDate={showAirDate}
                            title={this.state.title}
                            videos={this.state.videos}
                        />
                    );
                }

                share = (
                    <div>
                        <Share
                            asset={this.state.selectedVideo}
                            assetType={AssetTypeConstants.ASSET_TYPE.VIDEO}
                            close={this.handleCloseShare}
                            show={this.state.showShare}
                            titleId={this.props.match.params.id}
                        />
                    </div>
                );

                let selectedVideo;
                if (this.state.playerSelectedVideo.size !== 0) {
                    selectedVideo = this.state.playerSelectedVideo;
                }

                let video;
                if (this.state.video.size !== 0) {
                    video = this.state.video;
                }

                overlay = (
                    <div>
                        <VideoOverlay
                            clipping
                            onClose={this.onPlayerClose}
                            selectedVideo={selectedVideo}
                            titleId={this.props.match.params.id}
                            video={video}
                            visible={this.state.showOverlay}
                            watchlist={this.state.watchlist}
                        />
                    </div>
                );
                /* istanbul ignore next */
                if (this.state.single) {
                    // get totalCount for category that has videos
                    let totalCount = this.state.videos.reduce( (r, v) => {
                        let count = v.get('totalCount') || 0;

                        if (r > count) {
                            return r;
                        }
                        return count;
                    }, 0);
                    upperPaginationAndFilters = (
                        <div className="pull-right-sm-up grid-options margin-top-10">
                            {this.context.intl.messages['titles.videos.display']}:
                            <span
                                aria-label={this.context.intl.messages['common.grid-view']}
                                className={ClassNames(['display-type-switch', 'glyphicon', 'glyphicon-th', 'margin-x-5'], {active: this.state.gridView})}
                                onClick={/* istanbul ignore next */() => /* istanbul ignore next */this.setState({gridView:true})}
                                onKeyUp={/* istanbul ignore next */() => /* istanbul ignore next */this.setState({gridView:true})}
                                role="button"
                                tabIndex="0"
                            />
                            <span
                                aria-label={this.context.intl.messages['common.list-view']}
                                className={ClassNames(['display-type-switch', 'glyphicon', 'glyphicon-align-justify'], {active: !this.state.gridView})}
                                onClick={/* istanbul ignore next */() => /* istanbul ignore next */this.setState({gridView:false})}
                                onKeyUp={/* istanbul ignore next */() => /* istanbul ignore next */this.setState({gridView:false})}
                                role="button"
                                tabIndex="0"
                            />
                            <hr className="visible-xs-block"/>
                            <span className="vertical-divider hidden-xs"/>
                            <div className="text-center inline-block Pt(1px)">
                                <select
                                    className="form-control margin-right-5 show-per-page"
                                    onChange={this.handlePageSizeChange}
                                    value={pageSize}
                                >
                                    <option value="20">20</option>
                                    <option value="40">40</option>
                                    <option value="60">60</option>
                                    <option value="80">80</option>
                                </select>
                                {this.context.intl.messages['common.per-page']}
                            </div>
                            <hr className="visible-xs-block"/>
                            <span className="vertical-divider hidden-xs"/>
                            <Pagination
                                className="margin-top-5"
                                offset={offset}
                                onChange={this.handlePageChange}
                                size={pageSize}
                                total={totalCount}
                            />
                        </div>
                    );

                    lowerPagination = (
                        <div className="container text-center margin-bottom-40">
                            <hr/>
                            <Pagination
                                className="margin-top-5"
                                offset={offset}
                                onChange={this.handlePageChange}
                                size={pageSize}
                                total={totalCount}
                            />
                        </div>
                    );
                }

                break;

            case AssetTitleConstants.ASSET_CATEGORY.IMAGE:
                let showCaptions;
                documentMessage = 'document-titles.title.images';
                assetSeasonCounts = this.state.imagesSeasonCounts;
                let singleMode = !!(this.props.location.pathname.match('/titles/\\d+/images/\\d+'));

                if (query.vct) {
                    if (Array.isArray(query.vct)) {
                        contentTypeFilters = Immutable.Set.of(...query.vct);
                    } else {
                        contentTypeFilters = Immutable.Set.of(query.vct);
                    }
                } else {
                    contentTypeFilters = Immutable.Set.of(
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.KEY_ART.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.OUTDOOR_ART.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.ONLINE.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.LOGO.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.PHOTOGRAPHY_STILLS.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.GALLERY.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.SVOD.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.CLIENT_SAMPLES.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.ANIMATION_PRODUCTON_MATERIALS.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.BTS.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.OTHER.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.SOCIAL_MEDIA.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.EVENTS.ALL,
                        ...AssetTitleConstants.CONTENT_TYPES.IMAGE.HBO_MAX.ALL
                    );
                }

                if (this.state.images.size === 0) {
                    return null;
                }

                let imagesForList = this.state.images.findEntry(images => !!images.get('results').size, undefined, []);
                let sectionImageCount = 0;

                if (imagesForList[1] && imagesForList[1].size) {
                    sectionImageCount = imagesForList[1].get('totalCount');
                }

                assetGrid = (
                    <ListView
                        assetType={assetType}
                        canAddToCart={this.props.permissions.canAddToCartImage}
                        hasRestrictedAuth={this.props.permissions.hasRestrictedAuth}
                        images={imagesForList[1]}
                        imageSuperType={imagesForList[0]}
                        location={this.props.location}
                        onSortChange={this.handleSortChange}
                        sectionImageCount={sectionImageCount}
                        title={this.state.title}
                    />
                );
                /* istanbul ignore next */
                if (this.state.gridView) {
                    assetGrid = (
                        <GridView
                            assetFacetCounts={this.state.assetFacetCounts}
                            assetType={assetType}
                            canAddAllToCart={this.props.permissions.canAddAllToCart}
                            canAddToCart={this.props.permissions.canAddToCartImage}
                            canDownloadApproval={this.props.permissions.canDownloadApproval}
                            canDownloadFull={this.props.permissions.canDownloadFull}
                            canDownloadLoRes={this.props.permissions.canDownloadLoRes}
                            canDownloadSource={this.props.permissions.canDownloadSource}
                            contentTypeFilters={contentTypeFilters}
                            location={this.props.location}
                            onSortChange={this.handleSortChange}
                            serieNavigation={this.state.serieNavigation}
                            showCaptions={this.state.showCaptions}
                            title={this.state.title}
                            images={this.state.images}
                        />
                    );

                    showCaptions = (
                        <div className="visible-xs-block visible-inline-block-sm-up text-center">
                            {this.context.intl.messages['common.show']}
                            <span
                                className={ClassNames('thumbnail-display-option image-caption-switch glyphicon glyphicon glyphicon-comment margin-left-5', {'active': this.state.showCaptions})}
                                onClick={this.handleToggleCaptions}
                                onKeyUp={this.handleToggleCaptions}
                                role="button"
                                tabIndex="0"
                            />
                        </div>
                    );
                }

                let groupedImages = Immutable.List();

                GridViewConstants.forEach(gallerySection => {
                    let filtered = this.state.images.getIn([gallerySection.imageSuperType.key, 'results']);
                    groupedImages = groupedImages.concat(filtered);
                });

                let photoSwipeList = groupedImages;
                let photoSwipeCurrentImage = this.state.currentImage;
                if (singleMode) {
                    photoSwipeList = Immutable.List();
                    if (this.state.openSharedImage) {
                        photoSwipeList = Immutable.List([this.state.openSharedImage]);
                    }

                    photoSwipeCurrentImage = this.state.openSharedImage;
                }

                photoswipe = (
                    <div>
                        <Photoswipe
                            canEditBrainiac={this.props.permissions.canEditBrainiac}
                            currentImage={photoSwipeCurrentImage}
                            hasRestrictedAuth={this.props.permissions.hasRestrictedAuth}
                            images={photoSwipeList}
                            onClose={this.handleCloseSwipe}
                            titleId={titleId}
                        />
                    </div>
                );
                /* istanbul ignore next */
                if (this.state.single) {
                    let totalCount = this.state.images.reduce( (r, v) => {
                        let count = v.get('totalCount') || 0;

                        if (r > count) {
                            return r;
                        }
                        return count;
                    }, 0);
                    upperPaginationAndFilters = (
                        <div className="pull-right-sm-up margin-top-10">
                            <div className="clearfix">
                                <hr className="visible-xs-block" />
                                <div className="pull-right-sm-up grid-options margin-top-10">
                                    {showCaptions}
                                    <hr className="visible-xs-block"/>
                                    <span className="vertical-divider hidden-xs"/>
                                    <div className="visible-xs-block visible-inline-block-sm-up text-center">
                                        {this.context.intl.messages['titles.videos.display']}:
                                        <span
                                            className={ClassNames(['display-type-switch', 'glyphicon', 'glyphicon-th', 'margin-x-5'], {active: this.state.gridView})}
                                            onClick={/* istanbul ignore next */() => /* istanbul ignore next */this.setState({gridView:true})}
                                            onKeyUp={/* istanbul ignore next */() => /* istanbul ignore next */this.setState({gridView:true})}
                                            role="button"
                                            tabIndex="0"
                                        />
                                        <span
                                            className={ClassNames(['display-type-switch', 'glyphicon', 'glyphicon-align-justify'], {active: !this.state.gridView})}
                                            onClick={/* istanbul ignore next */() => /* istanbul ignore next */this.setState({gridView:false})}
                                            onKeyUp={/* istanbul ignore next */() => /* istanbul ignore next */this.setState({gridView:false})}
                                            role="button"
                                            tabIndex="0"
                                        />
                                    </div>
                                    <hr className="visible-xs-block"/>
                                    <span className="vertical-divider hidden-xs"/>
                                    <div className="text-center inline-block Pt(1px)">
                                        <select
                                            className="form-control margin-right-5 show-per-page"
                                            onChange={this.handlePageSizeChange}
                                            value={query.size || this.state.pageSize}
                                        >
                                            <option value="40">40</option>
                                            <option value="80">80</option>
                                            <option value="120">120</option>
                                            <option value="160">160</option>
                                            <option value="200">200</option>
                                        </select>
                                        {this.context.intl.messages['common.per-page']}
                                    </div>
                                    <hr className="visible-xs-block"/>
                                    <span className="vertical-divider hidden-xs"/>
                                    <Pagination
                                        className="margin-top-5"
                                        offset={query.offset || 0}
                                        onChange={this.handlePageChange}
                                        size={query.size || this.state.pageSize}
                                        total={totalCount}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                }
                break;
            }

            if (this.state.assetFacetCounts.get(titleId) !== undefined) {
                assetFilters = <AssetFilter
                    contentTypeFilters={contentTypeFilters}
                    location={this.props.location}
                    assetFacetCounts={this.state.assetFacetCounts.get(titleId)}
                    assetType = {assetType}
                />;
            }

            if (categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SERIES) {
                seasonSummary = (
                    <div>
                        <div className="container" key="divider"><hr/></div>
                        <SeasonsSummary
                            assetTypePath={assetTypePath}
                            assetSeasonCounts={assetSeasonCounts}
                            key={'seasonsSummary'}
                            seasonsMap={this.state.seasonsMap}
                            title={assetTypePath}
                        />
                    </div>
                );
            }

            return (
                <DocumentTitle message={documentMessage}>
                    <div>
                        {share}
                        {overlay}
                        <Preloader show={showPreloader} fixed className="text-primary">
                            <div className="container padding-all-20">
                                <div className="container">
                                    <div className="col-md-2">
                                        {assetFilters}
                                    </div>
                                    <h3 className="row pull-left-sm-up margin-top-10 margin-bottom-10 margin-left-0 h2 title-and-episodes-navigator">
                                        {this.context.intl.messages[`titles.${assetTypePath}.title`]}
                                        <EpisodeNavigation serieNavigation={this.state.serieNavigation} titleId={this.state.title.get('id')} location={this.props.location} useCurrentSearch={true}/>
                                    </h3>
                                    {upperPaginationAndFilters}
                                    <div className="col-md-10">
                                        {assetGrid}
                                    </div>
                                </div>
                                {photoswipe}
                                {seasonSummary}
                                {lowerPagination}
                            </div>
                        </Preloader>
                    </div>
                </DocumentTitle>
            );
        }
    }

    return Container.create(AssetTitle);
};
