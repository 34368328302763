/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import ReactSelect from 'react-select';

import DialogueFacet from './facets/dialogue-facet';
import {DialogueFacetActions} from './facets/dialogue-facet-actions';
import DialogueFacetStore from './facets/dialogue-facet-store';
import SearchFacetsStore from './facets/search-facets-store';
import SettingFacet from './facets/setting-facet';
import TagFacet from './facets/tag-facet';
import TalentCharacterFacet from './facets/talent-character-facet';
import SearchModal from './modal';
import {SearchActions} from './search-actions';
import SearchStore from './search-store';
import TitleTypes from './title-types';
import CommonShareModal from '../common/modal-share';
import withSetSearchFilters from '../common/search-filters/hocs/with-set-search-filters';
import Config from '../config/config';
import DatePicker from '../form/date-picker';
import Dropdown from '../form/dropdown';
import {GenreActions} from '../lookup/genre/genre-actions';
import GenreStore from '../lookup/genre/genre-store';
import {LanguageActions} from '../lookup/language/language-actions';
import LanguageStore from '../lookup/language/language-store';
import {TerritoryActions} from '../lookup/territory/territory-actions';
import TerritoryStore from '../lookup/territory/territory-store';
import {ThemeActions} from '../lookup/theme/theme-actions';
import ThemeStore from '../lookup/theme/theme-store';
import {TitleCatalogActions} from '../lookup/title-catalog/title-catalog-actions';
import TitleCatalogStore from '../lookup/title-catalog/title-catalog-store';
import {MessagesContext} from '../messages/messages-context';
import SessionStore from '../session/session-store';
import {TitleConstants} from '../titles/title-actions';
import {Debounce, FilterCountry} from '../utils/utils';
import 'bootstrap/js/collapse';
import 'bootstrap/js/dropdown';

const CONSTANTS = {
    SAVE: 'Save',
    SHARE: 'Share'
};

class SearchFilters extends React.Component {
    static get propTypes() {
        return {
            hub: PropTypes.oneOf(['animation', 'documentary', 'film', 'limited-series', 'movies', 'search', 'tv']).isRequired,
            location: PropTypes.object.isRequired,
            partner: PropTypes.string,
            permissions: PropTypes.object.isRequired,
            section: PropTypes.string,
            setFilter: PropTypes.func.isRequired,
        };
    }

    static get defaultProps() {
        return {
            partner: '',
            section: '',
        };
    }

    static getStores() {
        return [
            GenreStore, LanguageStore, SearchStore,
            TerritoryStore, ThemeStore, TitleCatalogStore,
            SearchFacetsStore, DialogueFacetStore,
        ];
    }

    static calculateState() {
        return {
            genreOptions: GenreStore.getState().get('items'),
            languageOptions: LanguageStore.getState().get('items'),
            talentOptions: SearchStore.getState().getIn(['filters', 'talent']),
            tagOptions: SearchFacetsStore.getState().getIn(['data', 'videoTagFacets']),
            dialogueOptions: DialogueFacetStore.getState().get('data'),
            combinedTalentCharacterOptions: SearchFacetsStore.getState().getIn(['data', 'videoCombinedTalentCharacterFacets']),
            countryOptions: TerritoryStore.getState().get('items'),
            themeOptions: ThemeStore.getState().get('items'),
            settingOptions: SearchFacetsStore.getState().getIn(['data', 'videoSettingFacets']),
            isDialogueLoading: DialogueFacetStore.getState().get('loading'),
            // Ugly as hell, but since title-catalog is the query param name,
            // it just works.
            'title-catalogOptions': TitleCatalogStore.getState().get('items')
        };
    }

    constructor(props) {
        super(props);

        const query = QueryString.parse(props.location.search);
        this.state = Object.assign(
            this.constructor.calculateState(),
            {
                isAnimationChecked: this.props.hub === 'animation' || query.animation,
                isDocumentaryChecked: this.props.hub === 'documentary' || query.documentary,
                isFilmChecked: this.props.hub === 'film' || query.film,
                isLimitedSeriesChecked: this.props.hub === 'limited-series' || query['limited-series'],
                isMoviesChecked: this.props.hub === 'movies' || query.movies,
                isTVChecked: this.props.hub === 'tv' || query.tv,
                showSaveSearchModal: false,
                showShareSearchModal: false
            }
        );

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.doneSaveSearchModal = this.doneSaveSearchModal.bind(this);
        this.doneShareSearchModal = this.doneShareSearchModal.bind(this);
        this.searchTalent = Debounce(this.searchTalent.bind(this), 200);
    }

    componentDidMount() {
        [{
            attr: 'countryOptions',
            actions: TerritoryActions
        }, {
            attr: 'genreOptions',
            actions: GenreActions,
        }, {
            attr: 'languageOptions',
            actions: LanguageActions
        }, {
            attr: 'title-catalogOptions',
            actions: TitleCatalogActions
        }, {
            attr: 'themeOptions',
            actions: ThemeActions
        }].forEach(i => {
            // Only request the genres if the store is empty.
            if (!this.state[i.attr].size) {
                i.actions.get(0, 9999);
            }
            return;
        });

        SearchActions.getTalentFilter();
    }

    componentWillReceiveProps(nextProps) {
        const prevQuery = QueryString.parse(this.props.location.search);
        const nextQuery = QueryString.parse(nextProps.location.search);

        if (
            this.props.hub !== nextProps.hub ||
            prevQuery.film !== nextQuery.film ||
            prevQuery.tv !== nextQuery.tv ||
            prevQuery.animation !== nextQuery.animation ||
            prevQuery.documentary !== nextQuery.documentary ||
            prevQuery.movies !== nextQuery.movies ||
            prevQuery['limited-series'] !== nextQuery['limited-series'] ||
            prevQuery.new !== nextQuery.new ||
            prevQuery.returning !== nextQuery.returning ||
            prevQuery.library !== nextQuery.library
        ) {
            // FIXME: move this to render method and don't use setState().
            let isAnimationChecked = !!nextQuery.animation;
            let isDocumentaryChecked = !!nextQuery.documentary;
            let isFilmChecked = !!nextQuery.film;
            let isLimitedSeriesChecked = !!nextQuery['limited-series'];
            let isMoviesChecked = !!nextQuery.movies;
            let isTVChecked = !!nextQuery.tv;

            if (nextProps.hub === 'film') {
                isFilmChecked = true;
            }

            if (nextProps.hub === 'tv') {
                isTVChecked = true;
            }

            if (nextProps.hub === 'animation') {
                isAnimationChecked = true;
            }

            if (nextProps.hub === 'documentary') {
                isDocumentaryChecked = true;
            }

            if (nextProps.hub === 'limited-series') {
                isLimitedSeriesChecked = true;
            }

            if (nextProps.hub === 'movies') {
                isMoviesChecked = true;
            }

            this.setState({
                isAnimationChecked,
                isDocumentaryChecked,
                isFilmChecked,
                isLimitedSeriesChecked,
                isMoviesChecked,
                isTVChecked
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.hub !== nextProps.hub ||
            this.props.location.search !== nextProps.location.search ||
            this.props.location.pathname !== nextProps.location.pathname ||
            this.props.section !== nextProps.section ||
            this.props.setFilter !== nextProps.setFilter) {
            return true;
        }

        if (this.state.combinedTalentCharacterOptions !== nextState.combinedTalentCharacterOptions ||
            this.state.genreOptions !== nextState.genreOptions ||
            this.state.languageOptions !== nextState.languageOptions ||
            this.state.talentOptions !== nextState.talentOptions ||
            this.state.tagOptions !== nextState.tagOptions ||
            this.state.dialogueOptions !== nextState.dialogueOptions ||
            this.state.countryOptions !== nextState.countryOptions ||
            this.state.themeOptions !== nextState.themeOptions ||
            this.state.settingOptions !== nextState.settingOptions ||
            this.state.showSaveSearchModal !== nextState.showSaveSearchModal ||
            this.state.showShareSearchModal !== nextState.showShareSearchModal ||
            this.state['title-catalogOptions'] !== nextState['title-catalogOptions']) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    closeModal(modalName, event) {
        if (event) {
            event.preventDefault();
        }
        this.setState({[`show${modalName}SearchModal`]: false});
    }

    openModal(modalName, event) {
        if (event) {
            event.preventDefault();
        }
        this.setState({[`show${modalName}SearchModal`]: true});
    }

    doneSaveSearchModal(searchName, searchUrl) {
        let userId = SessionStore.getState().getIn(['authUser', 'id']);
        SearchActions.saveSearchName(searchName, searchUrl, userId);
        this.setState(() => ({
            showSaveSearchModal: false
        }));
    }

    doneShareSearchModal(email, searchUrl) {
        let userId = SessionStore.getState().getIn(['authUser', 'id']);
        SearchActions.shareSearch(email, searchUrl, userId);
        this.setState(() => ({
            showShareSearchModal: false
        }));
    }

    filterTalent(option, term) {
        return term.split(' ').every(
            t => !!option.data.fullName.toLowerCase().match(t.toLowerCase())
        );
    }

    initNavbar(elem) {
        jQuery(elem);
        return;
    }

    searchTalent(term) {
        SearchActions.getTalentFilter(term);
        return;
    }

    getDialogueFacet(query, value) {
        DialogueFacetActions.getDialogueFacet(query, value);
    }

    render() {
        let isSearchHub = this.props.hub === 'search';
        let categories = [];
        const query = QueryString.parse(this.props.location.search);
        let queryCategories = query.category;
        if (queryCategories) {
            if (!Array.isArray(queryCategories)) {
                queryCategories = [queryCategories];
            }
            categories = TitleTypes.filter(tt => queryCategories.indexOf(tt.id) !== -1);
        }

        let genreOperator = query['genre-operator'] || 'AND';
        const isClips = this.props.section === 'moments';

        let selects = {
            country: [],
            genre: [],
            language: [],
            talent: [],
            theme: [],
            'title-catalog': []
        };

        Object.keys(selects).forEach(f => {
            let values = query[f];
            if (values) {
                if (!Array.isArray(values)) {
                    values = [values];
                }

                switch (f) {
                case 'talent':
                    selects.talent = values.map(v => {
                        return {
                            fullName: v,
                            talentId: v
                        };
                    });
                    break;
                default:
                    selects[f] = this.state[`${f}Options`].filter(o => values.indexOf(o.get('name')) !== -1).toJS();
                    break;
                }
            }
            return;
        });
        let librarySelects = [
            <li className="divider" key="library-selects-divider"></li>,
            <li className="padding-x-10" key="library-selects-new">
                <div className="checkbox">
                    <label className={ClassNames({disabled: this.props.hub === 'search' && !query.animation && !query.film && !query.tv && !query.movies && !query['limited-series'] && !query.documentary})}>
                        <input
                            // eslint-disable-next-line
                            checked={!!query['new'] && (this.state.isAnimationChecked || this.state.isFilmChecked || this.state.isTVChecked || this.state.isMoviesChecked || this.state.isLimitedSeriesChecked || this.state.isDocumentaryChecked)} // I don't care what ESLint thinks about, don't use "new" as part of the name.
                            disabled={this.props.hub === 'search' && !query.animation && !query.film && !query.tv && !query.movies && !query['limited-series'] && !query.documentary}
                            onChange={this.props.setFilter.bind(this, 'new')}
                            type="checkbox"
                            value="new"
                        />{this.context.intl.messages['search.filters.navbar.titles.new.value']}
                    </label>
                </div>
            </li>
        ];
        if (this.props.hub !== 'film' && this.props.hub !== 'movies' && this.props.hub !== 'documentary') {
            librarySelects.push(
                <li className="padding-x-10" key="library-selects-returning">
                    <div className="checkbox">
                        <label className={ClassNames({disabled: this.props.hub === 'search' && !query.animation && !query.tv && !query['limited-series']})}>
                            <input
                                checked={!!query.returning && (this.state.isTVChecked || this.state.isAnimationChecked || this.state.isLimitedSeriesChecked)}
                                disabled={this.props.hub === 'search' && !query.animation && !query.tv && !query['limited-series']}
                                onChange={this.props.setFilter.bind(this, 'returning')}
                                type="checkbox"
                                value="returning"
                            />{this.context.intl.messages['search.filters.navbar.titles.returning.value']}
                        </label>
                    </div>
                </li>
            );
        }
        librarySelects.push(
            <li className="padding-x-10" key="library-selects-library">
                <div className="checkbox">
                    <label className={ClassNames({disabled: this.props.hub === 'search' && !query.animation && !query.film && !query.tv && !query.movies && !query['limited-series'] && !query.documentary})}>
                        <input
                            checked={!!query.library && (this.state.isAnimationChecked || this.state.isFilmChecked || this.state.isTVChecked || this.state.isMoviesChecked || this.state.isLimitedSeriesChecked || this.state.isDocumentaryChecked)}
                            disabled={this.props.hub === 'search' && !query.animation && !query.film && !query.tv && !query.movies && !query['limited-series'] && !query.documentary}
                            onChange={this.props.setFilter.bind(this, 'library')}
                            type="checkbox"
                            value="library"
                        />{this.context.intl.messages['search.filters.navbar.titles.library.value']}
                    </label>
                </div>
            </li>
        );

        let isInSection = !!this.props.section;
        let sortDropdown;
        let saveSearch;
        let shareSearch;
        let showSortDropdown = (!isSearchHub || isInSection) && !isClips;

        let runtimeFilter;
        // show this filter in search hub and in titles section only since this works just for titles endpoint
        if (((isSearchHub && !isInSection) || this.props.section === 'titles') && !isClips) {
            runtimeFilter = <Dropdown
                className={{
                    active: query['start-run-time'] ||
                        query['end-run-time']
                }}
                title={this.context.intl.messages['search.filters.navbar.titles.run-time']}
            >
                <li className="padding-all-10">
                    <span className="text-nowrap">{this.context.intl.messages['search.filters.navbar.titles.run-time.legend']}</span>
                </li>
                <li className="padding-all-10">
                    <label>{this.context.intl.messages['search.filters.navbar.titles.from']}</label>
                    <input
                        className="form-control"
                        onChange={this.props.setFilter.bind(this, 'start-run-time')}
                        placeholder={this.context.intl.messages['search.filters.navbar.titles.run-time.format']}
                        type="text"
                        value={query['start-run-time'] || ''}
                    />
                </li>
                <li className="padding-all-10">
                    <label>{this.context.intl.messages['search.filters.navbar.titles.to']}</label>
                    <input
                        className="form-control"
                        onChange={this.props.setFilter.bind(this, 'end-run-time')}
                        placeholder={this.context.intl.messages['search.filters.navbar.titles.run-time.format']}
                        type="text"
                        value={query['end-run-time'] || ''}
                    />
                </li>
            </Dropdown>;
        }
        if (showSortDropdown && !isClips) {
            sortDropdown = (
                <ul className="nav navbar-nav navbar-right">
                    <Dropdown title={this.context.intl.messages['search.filters.navbar.titles.sort']}>
                        <li className="padding-all-10">
                            <select
                                className="form-control"
                                defaultValue="desc"
                                onChange={this.props.setFilter.bind(this, 'sort-direction')}
                                value={query['sort-direction']}
                            >
                                <option value="desc">{this.context.intl.messages['search.filters.navbar.titles.sort.descending']}</option>
                                <option value="asc">{this.context.intl.messages['search.filters.navbar.titles.sort.ascending']}</option>
                            </select>
                        </li>
                        <li className="divider"></li>
                        <li className="padding-all-10">
                            <select
                                className="form-control"
                                defaultValue="releaseDate"
                                onChange={this.props.setFilter.bind(this, 'sort-field-name')}
                                value={query['sort-field-name']}
                            >
                                <option value="relevance">{this.context.intl.messages['search.filters.navbar.titles.sort.relevance']}</option>
                                <option value="alphabetical">{this.context.intl.messages['search.filters.navbar.titles.sort.alphabetical']}</option>
                                <option value="createdDate">{this.context.intl.messages['search.filters.navbar.titles.sort.date-added']}</option>
                                <option value="releaseDate">{this.context.intl.messages['search.filters.navbar.titles.sort.date-release']}</option>
                            </select>
                        </li>
                    </Dropdown>
                </ul>
            );
        }

        if (!isSearchHub || isInSection) {
            if (SessionStore.canUser(SessionStore.PERMISSIONS.SEARCH.SAVE)) {
                saveSearch = (
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a
                                href="#"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{color: '#006EC3'}}
                                onClick={this.openModal.bind(this, CONSTANTS.SAVE)}
                                role="button"><i className="glyphicon glyphicon-floppy-disk"></i>&nbsp;{this.context.intl.messages['search.save-search.title']}
                            </a>
                        </li>
                    </ul>
                );
            }

            if (SessionStore.canUser(SessionStore.PERMISSIONS.SEARCH.SHARE)) {
                shareSearch = (
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a
                                href="#"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{color: '#006EC3'}}
                                onClick={this.openModal.bind(this, CONSTANTS.SHARE)}
                                role="button"><i className="glyphicon glyphicon-transfer"></i>&nbsp;{this.context.intl.messages['common.share']}
                            </a>
                        </li>
                    </ul>
                );
            }
        }

        let titleTypeOptions = TitleTypes;
        // Hubs
        let animationHubOption;
        let documentaryHubOption;
        let filmHubOption;
        let limitedSeriesHubOption;
        let moviesHubOption;

        switch (this.props.partner) {
        case Config.Partners.HBO.id:
            documentaryHubOption = <li className="padding-x-10">
                <div className="checkbox">
                    <label className={ClassNames({disabled: !isSearchHub})}>
                        <input
                            checked={!!query.documentary || this.props.hub === 'documentary'}
                            disabled={!isSearchHub}
                            onChange={this.props.setFilter.bind(this, 'documentary')}
                            type="checkbox"
                            value="documentary"
                        />{this.context.intl.messages['search.filters.navbar.titles.documentary.value']}
                    </label>
                </div>
            </li>;
            limitedSeriesHubOption = <li className="padding-x-10">
                <div className="checkbox">
                    <label className={ClassNames({disabled: !isSearchHub})}>
                        <input
                            checked={!!query['limited-series'] || this.props.hub === 'limited-series'}
                            disabled={!isSearchHub}
                            onChange={this.props.setFilter.bind(this, 'limited-series')}
                            type="checkbox"
                            value="limited-series"
                        />{this.context.intl.messages['search.filters.navbar.titles.limited-series.value']}
                    </label>
                </div>
            </li>;
            moviesHubOption = <li className="padding-x-10">
                <div className="checkbox">
                    <label className={ClassNames({disabled: !isSearchHub})}>
                        <input
                            checked={!!query.movies || this.props.hub === 'movies'}
                            disabled={!isSearchHub}
                            onChange={this.props.setFilter.bind(this, 'movies')}
                            type="checkbox"
                            value="movies"
                        />{this.context.intl.messages['search.filters.navbar.titles.movies.value']}
                    </label>
                </div>
            </li>;
            if (!isSearchHub) {
                // Specially for HBO, the hubs must display only a few options in the Title Type filter.
                switch (this.props.hub) {
                case 'documentary':
                    titleTypeOptions = TitleTypes.filter(tt => [TitleConstants.TITLE_TYPES.DOCUMENTARIES.id, TitleConstants.TITLE_TYPES.DOCUSERIES.id].indexOf(parseInt(tt.id)) !== -1);
                    break;
                case 'limited-series':
                    titleTypeOptions = TitleTypes.filter(tt => [TitleConstants.TITLE_TYPES.MINI_SERIES.id, TitleConstants.TITLE_TYPES.LIMITED_SERIES.id].indexOf(parseInt(tt.id)) !== -1);
                    break;
                case 'movies':
                    titleTypeOptions = TitleTypes.filter(tt => [TitleConstants.TITLE_TYPES.HBO_FILMS.id, TitleConstants.TITLE_TYPES.DOCUMENTARY_FILMS.id, TitleConstants.TITLE_TYPES.WB_HBO_MAX_RELEASE.id].indexOf(parseInt(tt.id)) !== -1);
                    break;
                case 'tv':
                    titleTypeOptions = TitleTypes.filter(tt => [
                        TitleConstants.TITLE_TYPES.THEATRICAL_FEATURES.id,
                        TitleConstants.TITLE_TYPES.ANIMATED_FEATURES.id,
                        TitleConstants.TITLE_TYPES.MADE_FOR_VIDEO_FEATURES.id,
                        TitleConstants.TITLE_TYPES.CARTOONS.id,
                        TitleConstants.TITLE_TYPES.NETWORK.id,
                        TitleConstants.TITLE_TYPES.CABLE.id,
                        TitleConstants.TITLE_TYPES.PAY_TV.id,
                        TitleConstants.TITLE_TYPES.SPECIALS.id,
                        TitleConstants.TITLE_TYPES.ANIMATED_SPECIALS.id,
                        TitleConstants.TITLE_TYPES.COMEDY_SPECIALS.id,
                        TitleConstants.TITLE_TYPES.MUSIC_SPECIALS.id,
                        TitleConstants.TITLE_TYPES.SPORTS.id,
                        TitleConstants.TITLE_TYPES.SHORT_PROGRAMS.id,
                        TitleConstants.TITLE_TYPES.MAKING_OF.id,
                        TitleConstants.TITLE_TYPES.SERIES_HALF_HOUR.id,
                        TitleConstants.TITLE_TYPES.SERIES_ONE_HOUR.id,
                        TitleConstants.TITLE_TYPES.TALK_SHOW.id,
                        TitleConstants.TITLE_TYPES.GAME_SHOW.id,
                        TitleConstants.TITLE_TYPES.ANIMATED_SERIES.id,
                        TitleConstants.TITLE_TYPES.FORMAT_RIGHTS.id,
                        TitleConstants.TITLE_TYPES.REALITY.id,
                        TitleConstants.TITLE_TYPES.DIRECT_TO_VIDEO.id,
                        TitleConstants.TITLE_TYPES.ANIMATION.id,
                        TitleConstants.TITLE_TYPES.EVENT.id,
                        TitleConstants.TITLE_TYPES.OTHER_PRODUCTS.id,
                        TitleConstants.TITLE_TYPES.SEGMENT.id,
                        TitleConstants.TITLE_TYPES.PROGRAMMING_PACKAGE.id
                    ].indexOf(parseInt(tt.id)) !== -1);
                    break;
                }
            }
            break;
        case Config.Partners.WBTVD.id:
            animationHubOption = <li className="padding-x-10">
                <div className="checkbox">
                    <label className={ClassNames({disabled: !isSearchHub})}>
                        <input
                            checked={!!query.animation || this.props.hub === 'animation'}
                            disabled={!isSearchHub}
                            onChange={this.props.setFilter.bind(this, 'animation')}
                            type="checkbox"
                            value="animation"
                        />{this.context.intl.messages['search.filters.navbar.titles.animation.value']}
                    </label>
                </div>
            </li>;
            filmHubOption = <li className="padding-x-10">
                <div className="checkbox">
                    <label className={ClassNames({disabled: !isSearchHub})}>
                        <input
                            checked={!!query.film || this.props.hub === 'film'}
                            disabled={!isSearchHub}
                            onChange={this.props.setFilter.bind(this, 'film')}
                            type="checkbox"
                            value="film"
                        />{this.context.intl.messages['search.filters.navbar.titles.film.value']}
                    </label>
                </div>
            </li>;
            break;
        }

        return (
            <div className="container-fluid search-criteria-container">
                <div>
                    <div className="col-xs-12">
                        <button
                            aria-controls="navbar"
                            aria-expanded="false"
                            className="btn btn-block navbar-toggle collapsed"
                            data-target="#navbar-secondary"
                            data-toggle="collapse"
                            ref={this.initNavbar}
                            style={{position: 'relative', textAlign: 'left'}}
                            type="button"
                        >
                            {this.context.intl.messages['search.filters.navbar.titles.search_criteria']} <span className="glyphicon glyphicon-cog"/>
                        </button>
                        <div
                            id="navbar-secondary"
                            className="navbar-collapse collapse"
                            aria-expanded="false"
                            style={{height: '1px'}}>
                            <ul className="nav navbar-nav">
                                {isClips && <TalentCharacterFacet options={this.state.combinedTalentCharacterOptions} />}
                                {isClips && <SettingFacet options={this.state.settingOptions} />}
                                {isClips && <TagFacet options={this.state.tagOptions} />}
                                {isClips && <DialogueFacet
                                    isLoading={this.state.isDialogueLoading}
                                    onInputChange={this.getDialogueFacet}
                                    options={this.state.dialogueOptions}
                                />}

                                <Dropdown
                                    className={{
                                        active: query.tv ||
                                            query.film ||
                                            query.animation ||
                                            query.documentary ||
                                            query['limited-series'] ||
                                            query.movies ||
                                            query.scripted ||
                                            query.unscripted ||
                                            query['live-action'] ||
                                            query.animation ||
                                            !isSearchHub
                                    }}
                                    title={this.context.intl.messages['search.filters.navbar.titles.category-dropdown']}
                                >
                                    <li className="padding-x-10">
                                        <div className="checkbox">
                                            <label className={ClassNames({disabled: !isSearchHub})}>
                                                <input
                                                    checked={!!query.tv || this.props.hub === 'tv'}
                                                    disabled={!isSearchHub}
                                                    onChange={this.props.setFilter.bind(this, 'tv')}
                                                    type="checkbox"
                                                    value="tv"
                                                />{this.context.intl.messages['search.filters.navbar.titles.tv.value']}
                                            </label>
                                        </div>
                                    </li>
                                    {documentaryHubOption}
                                    {filmHubOption}
                                    {limitedSeriesHubOption}
                                    {moviesHubOption}
                                    {animationHubOption}
                                    <li className="divider"></li>
                                    <li className="padding-x-10">
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    checked={!!query.scripted || this.props.hub === 'scripted'}
                                                    disabled={this.props.hub === 'scripted'}
                                                    onChange={this.props.setFilter.bind(this, 'scripted')}
                                                    type="checkbox"
                                                    value="scripted"
                                                />{this.context.intl.messages['search.filters.navbar.titles.scripted.value']}
                                            </label>
                                        </div>
                                    </li>
                                    <li className="padding-x-10">
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    checked={!!query.unscripted}
                                                    onChange={this.props.setFilter.bind(this, 'unscripted')}
                                                    type="checkbox"
                                                    value="unscripted"
                                                />{this.context.intl.messages['search.filters.navbar.titles.unscripted.value']}
                                            </label>
                                        </div>
                                    </li>
                                    <li className="divider"></li>
                                    <li className="padding-x-10">
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    checked={!!query['live-action']}
                                                    onChange={this.props.setFilter.bind(this, 'live-action')}
                                                    type="checkbox"
                                                    value="live-action"
                                                />{this.context.intl.messages['search.filters.navbar.titles.live-action.value']}
                                            </label>
                                        </div>
                                    </li>
                                    <li className="padding-x-10">
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    checked={!!query.animated}
                                                    onChange={this.props.setFilter.bind(this, 'animated')}
                                                    type="checkbox"
                                                    value="animated"
                                                />{this.context.intl.messages['search.filters.navbar.titles.animated.value']}
                                            </label>
                                        </div>
                                    </li>
                                    {librarySelects}
                                </Dropdown>
                                <Dropdown
                                    className={{active: selects['title-catalog'].length}}
                                    title={this.context.intl.messages['search.filters.navbar.titles.library']}
                                >
                                    <li className="padding-all-10">
                                        <ReactSelect
                                            className="search-criteria-input"
                                            getOptionLabel={l => l.name}
                                            getOptionValue={l => l.id}
                                            isClearable
                                            isMulti
                                            name="title-type"
                                            onChange={this.props.setFilter.bind(this, 'title-catalog', 'name', true)}
                                            options={this.state['title-catalogOptions'].toJS()}
                                            value={selects['title-catalog']}
                                        />
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    className={{active: selects.talent.length, hide: isClips}}
                                    title={this.context.intl.messages['search.filters.navbar.titles.talent']}
                                >
                                    <li className="padding-all-10">
                                        <ReactSelect
                                            className="search-criteria-input"
                                            filterOption={this.filterTalent}
                                            getOptionLabel={t => t.fullName}
                                            getOptionValue={t => t.talentId}
                                            isClearable
                                            isMulti
                                            name="talent"
                                            onChange={this.props.setFilter.bind(this, 'talent', 'fullName', true)}
                                            onInputChange={this.searchTalent}
                                            options={this.state.talentOptions.toJS()}
                                            value={selects.talent}
                                        />
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    className={{active: categories.length}}
                                    title={this.context.intl.messages['search.filters.navbar.titles.category']}
                                >
                                    <li className="padding-all-10">
                                        <ReactSelect
                                            className="search-criteria-input"
                                            getOptionLabel={c => c.value}
                                            getOptionValue={c => c.id}
                                            isClearable
                                            isMulti
                                            name="title-type"
                                            onChange={this.props.setFilter.bind(this, 'category', 'id', true)}
                                            options={titleTypeOptions}
                                            value={categories}
                                        />
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    className={{active: selects.genre.length || selects.theme.length}}
                                    title={this.context.intl.messages['search.filters.navbar.titles.genre_theme']}
                                >
                                    <li className="padding-all-10">
                                        <label>{this.context.intl.messages['search.filters.navbar.titles.genre']}</label>
                                        <ReactSelect
                                            className="search-criteria-input"
                                            getOptionLabel={l => l.name}
                                            getOptionValue={l => l.id}
                                            isClearable
                                            isMulti
                                            name="genre"
                                            onChange={this.props.setFilter.bind(this, 'genre', 'name', true)}
                                            options={this.state.genreOptions.toJS()}
                                            value={selects.genre}
                                        />
                                    </li>
                                    <li className="padding-all-10">
                                        <label className="radio-inline">
                                            <input
                                                checked={genreOperator === 'AND'}
                                                name="genre-operator"
                                                onChange={this.props.setFilter.bind(this, 'genre-operator')}
                                                value="AND"
                                                type="radio"
                                            /> {this.context.intl.messages['search.filters.navbar.titles.and']}
                                        </label>
                                        <label className="radio-inline">
                                            <input
                                                checked={genreOperator === 'OR'}
                                                name="genre-operator"
                                                onChange={this.props.setFilter.bind(this, 'genre-operator')}
                                                value="OR"
                                                type="radio"
                                            /> {this.context.intl.messages['search.filters.navbar.titles.or']}
                                        </label>
                                    </li>
                                    <li className="divider"></li>
                                    <li className="padding-all-10">
                                        <label>{this.context.intl.messages['search.filters.navbar.titles.theme']}</label>
                                        <ReactSelect
                                            className="search-criteria-input"
                                            getOptionLabel={t => t.name}
                                            getOptionValue={t => t.id}
                                            isClearable
                                            isMulti
                                            name="theme"
                                            onChange={this.props.setFilter.bind(this, 'theme', 'name', true)}
                                            options={this.state.themeOptions.toJS()}
                                            value={selects.theme}
                                        />
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    className={{active: selects.language.length}}
                                    title={this.context.intl.messages['search.filters.navbar.titles.language']}
                                >
                                    <li className="padding-all-10">
                                        <ReactSelect
                                            className="search-criteria-input"
                                            getOptionLabel={l => l.name}
                                            getOptionValue={l => l.id}
                                            isClearable
                                            isMulti
                                            name="title-type"
                                            onChange={this.props.setFilter.bind(this, 'language', 'name', true)}
                                            options={this.state.languageOptions.toJS()}
                                            value={selects.language}
                                        />
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    className={{
                                        active: selects.country.length ||
                                            query['start-release-date'] ||
                                            query['end-release-date'] ||
                                            query['start-box-office'] ||
                                            query['end-box-office'],
                                    }}
                                    title={this.context.intl.messages['search.filters.navbar.titles.release']}
                                >
                                    <li className="padding-all-10">
                                        <label>{this.context.intl.messages['search.filters.navbar.titles.country']}</label>
                                        <ReactSelect
                                            className="search-criteria-input"
                                            filterOption={FilterCountry}
                                            getOptionLabel={g => g.name}
                                            getOptionValue={g => g.id}
                                            isClearable
                                            isMulti
                                            name="genre"
                                            onChange={this.props.setFilter.bind(this, 'country', 'name', true)}
                                            options={this.state.countryOptions.toJS()}
                                            value={selects.country}
                                        />
                                    </li>
                                    <li className="divider"></li>
                                    <li className="padding-all-10">
                                        <label>{this.context.intl.messages['search.filters.navbar.titles.date-from']}</label>
                                        <DatePicker
                                            className="search-criteria-input form-control"
                                            onChange={this.props.setFilter.bind(this, 'start-release-date')}
                                            selected={query['start-release-date'] || ''}
                                        />
                                    </li>
                                    <li className="padding-all-10">
                                        <label>{this.context.intl.messages['search.filters.navbar.titles.date-to']}</label>
                                        <DatePicker
                                            className="search-criteria-input form-control"
                                            onChange={this.props.setFilter.bind(this, 'end-release-date')}
                                            selected={query['end-release-date'] || ''}
                                        />
                                    </li>
                                    <li className="divider"></li>
                                    <li className="padding-all-10">
                                        <label>{this.context.intl.messages['search.filters.navbar.titles.box-office']}</label>
                                        <div className="row">
                                            <div className="col-xs-6">
                                                <input
                                                    className="form-control"
                                                    onChange={this.props.setFilter.bind(this, 'start-box-office')}
                                                    placeholder={this.context.intl.messages['search.filters.navbar.titles.from']}
                                                    type="text"
                                                    value={query['start-box-office'] || ''}
                                                />
                                            </div>
                                            <div className="col-xs-6">
                                                <input
                                                    className="form-control"
                                                    onChange={this.props.setFilter.bind(this, 'end-box-office')}
                                                    placeholder={this.context.intl.messages['search.filters.navbar.titles.to']}
                                                    type="text"
                                                    value={query['end-box-office'] || ''}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    className={{
                                        active: query['start-date-created'] ||
                                            query['end-date-created'],
                                    }}
                                    title={this.context.intl.messages['search.filters.navbar.titles.date-created']}
                                >
                                    <li className="padding-all-10">
                                        <label>{this.context.intl.messages['search.filters.navbar.titles.from_date']}</label>
                                        <DatePicker
                                            className="search-criteria-input form-control"
                                            onChange={this.props.setFilter.bind(this, 'start-date-created')}
                                            selected={query['start-date-created'] || ''}
                                        />
                                    </li>
                                    <li className="padding-all-10">
                                        <label>{this.context.intl.messages['search.filters.navbar.titles.to_date']}</label>
                                        <DatePicker
                                            className="search-criteria-input form-control"
                                            onChange={this.props.setFilter.bind(this, 'end-date-created')}
                                            selected={query['end-date-created'] || ''}
                                        />
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    className={{active: query.hd || query.sd, hide: isClips}}
                                    title={this.context.intl.messages['search.filters.navbar.titles.definition']}
                                >
                                    <li className="padding-x-10">
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    checked={!!query.hd}
                                                    onChange={this.props.setFilter.bind(this, 'hd')}
                                                    type="checkbox"
                                                    value="hd"
                                                /> {this.context.intl.messages['search.filters.navbar.titles.hd.value']}
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <label>
                                                <input
                                                    checked={!!query.sd}
                                                    onChange={this.props.setFilter.bind(this, 'sd')}
                                                    type="checkbox"
                                                    value="sd"
                                                /> {this.context.intl.messages['search.filters.navbar.titles.sd.value']}
                                            </label>
                                        </div>
                                    </li>
                                </Dropdown>
                                {runtimeFilter}
                            </ul>
                            {saveSearch}
                            {shareSearch}
                            {sortDropdown}
                        </div>
                    </div>
                </div>
                <SearchModal
                    onClose={this.closeModal.bind(this, CONSTANTS.SAVE)}
                    onDone={this.doneSaveSearchModal}
                    show={this.state.showSaveSearchModal}
                />
                <CommonShareModal
                    canIncludeTitleApiUrl={this.props.permissions.canIncludeTitleApiUrl}
                    close={this.closeModal.bind(this, CONSTANTS.SHARE)}
                    done={this.doneShareSearchModal}
                    location={this.props.location}
                    show={this.state.showShareSearchModal}
                />
            </div>
        );
    }
}

export default withSetSearchFilters(Container.create(SearchFilters));
export {SearchFilters};
