/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';

import FifoImageLoader from '../../../titles/asset-title/images/fifo-image-loader';
import {ImageLoaderPlaceholders} from '../../../titles/asset-title/images/image-loader';
import {ClipActions} from '../../../titles/clip/clip-actions';

import './styles.less';

const ClipListThumbnail = (props) => {
    const {clip, thumbnails, intl} = props;
    const {id, displayName} = clip.toJS();

    const titleId = clip.getIn([
        'browseTitleList',
        0,
        'parentTitleId',
    ]);

    const handlePlay = useCallback(() => {
        ClipActions.playAutoClip(clip, titleId);
    }, [clip, titleId]);

    const videoThumbnailUrl = thumbnails.getIn([clip.get('videoId')?.toString(), 'thumbnailUrl']);
    let thumbnailsUrl = clip.get('thumbnailsUrl');

    /* istanbul ignore if */
    if (videoThumbnailUrl) {
        thumbnailsUrl = thumbnailsUrl.push(videoThumbnailUrl);
    }

    return (
        <div
            role="button"
            onClick={handlePlay}
            onKeyUp={void 0}
            data-testid="thumbnail-button"
            className="video-thumbnail clip-list-thumbnail"
            tabIndex="0"
        >
            <div className="clip-list-thumbnail-body vertical-spacer-16x9 ">
                <div className="video-play video-play-single">
                    <span
                        aria-label={intl.messages['common.play-video']}
                        className="glyphicon glyphicon-play-circle hover-play-icon"
                        role="button"
                    />
                </div>
                <FifoImageLoader
                    alt={displayName}
                    className="img-responsive"
                    key={id}
                    placeholder={ImageLoaderPlaceholders.HORIZONTAL}
                    sources={thumbnailsUrl}
                />
            </div>
        </div>
    );
};

ClipListThumbnail.propTypes = {
    clip: PropTypes.instanceOf(Immutable.Map).isRequired,
    intl: PropTypes.object.isRequired,
    thumbnails: PropTypes.instanceOf(Immutable.List),
};

ClipListThumbnail.defaultProps = {
    clip: Immutable.Map(),
    intl: {messages: []},
    thumbnails: Immutable.List(),
};


export default ClipListThumbnail;
