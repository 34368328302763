/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';

import FacetSelect from '../../common/facets/facet-select';
import useTalentCharacterFacet from '../../common/facets/hooks/use-talent-character-facet';
import TalentCharacterFacetSelect from '../../common/facets/talent-character-facet-select';
import omit from '../../common/utils/omit';
import Dropdown from '../../form/dropdown';

const TalentCharacterFacet = (props) => {
    const intl = useIntl();
    const title = intl.messages['search.filters.navbar.titles.talent-character'];
    const facetProps = useTalentCharacterFacet();
    const isActive = !!facetProps.value.length;

    return <Dropdown
        className={{active: isActive}}
        title={title}
    >
        <li className="padding-all-10">
            <TalentCharacterFacetSelect
                className="search-criteria-input"
                openMenuOnClick={false}
                {...props}
                {...facetProps}
            />
        </li>
    </Dropdown>;
};

TalentCharacterFacet.propTypes = {
    ...FacetSelect.propTypes,
    options: PropTypes.instanceOf(Immutable.List).isRequired,
};

TalentCharacterFacet.defaultProps = omit(FacetSelect.defaultProps, ['options']);

export default memo(TalentCharacterFacet);
