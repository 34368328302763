/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Grid, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {MessagesContext} from '../../../messages/messages-context';
import SessionStore from '../../../session/session-store';
import HomeStore from '../../home-store';

export class ResumeBox extends React.Component {
    static get propTypes() {
        return {
            icon: PropTypes.string.isRequired,
            md: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            value: PropTypes.object.isRequired
        };
    }

    static contextType = MessagesContext;

    render() {
        //Note: If this startdate/enddate logic changes, the cms should be updated here: apps/cms/src/dashboard/dashboard-actions.js
        //See STUDIO-9453 for more details
        const startDate = Moment().subtract(6, 'days').format('YYYY-MM-DD');
        const endDate = Moment().format('YYYY-MM-DD');

        let weeklyLink = `/search/${this.props.type}?start-date-created=${startDate}&end-date-created=${endDate}&size=40`;
        let totalLink = `/search/${this.props.type}?size=40`;

        if (this.props.type === 'screeners') {
            weeklyLink = `/search/videos?content-super-type=Full Episode/Feature&start-date-created=${startDate}&end-date-created=${endDate}&size=40`;
            totalLink = '/search/videos?content-super-type=Full Episode/Feature&size=40';
        }

        // FIXME: Use FormatedMessages
        let weeklyLabel = `${this.props.value.weekly} new ${this.props.title} this week`;
        let totalLabel = `Total of ${this.props.value.total} ${this.props.title}`;

        return (
            <Col md={this.props.md}>
                <div className="home-icon">
                    <i className={this.props.icon} />
                </div>
                <span className="weekly h3">
                    {this.props.title}: <Link to={weeklyLink} aria-label={weeklyLabel}>{this.props.value.weekly}</Link>
                </span>
                <span className="total h4">
                    {this.context.intl.messages['home.section.new-this-week.total-count']}
                    <Link to={totalLink} aria-label={totalLabel}>{this.props.value.total}</Link>
                </span>
            </Col>
        );
    }
}

export class NewThisWeek extends React.Component {
    static calculateState() {
        return {
            newThisWeek: HomeStore.getState().get('newThisWeek')
        };
    }

    static getStores() {
        return [HomeStore];
    }

    static contextType = MessagesContext;

    render() {
        let newThisWeek = this.state?.newThisWeek;
        /* istanbul ignore else */
        if (!newThisWeek?.size) {
            return null;
        }

        let date = {
            first: Moment().subtract(6, 'days').format('DD MMM'),
            last: Moment().format('DD MMM')
        };

        let md = 4;
        let screeners;
        /* istanbul ignore next */
        if (SessionStore.canUser(SessionStore.PERMISSIONS.VIEW.FULL_EPISODES)) {
            md = 3;
            screeners = (
                <ResumeBox
                    icon="far fa-play-circle"
                    md={md}
                    title={this.context.intl.messages['home.section.new-this-week.screeners']}
                    type="screeners"
                    value={{weekly: newThisWeek.get('thisWeekScreenerVideoCount'), total: newThisWeek.get('totalScreenerVideoCount')}}
                />
            );
        }

        return (
            <div className="footer-news padding-all-30 margin-top-20 light-gray-container">
                <Grid>
                    <section className="row Mb(15px) col-xs-12">
                        <header className="news-header">
                            <span className="padding-all-15 margin-all-0">
                                {this.context.intl.messages['new-this-week.title']}&nbsp;
                                <small>{date.first} - {date.last}</small>
                            </span>
                        </header>
                        <div className="row col-xs-12">
                            <Row className="text-center padding-top-10">
                                <ResumeBox
                                    icon="far fa-video"
                                    md={md}
                                    title={this.context.intl.messages['home.section.new-this-week.videos']}
                                    value={{weekly: newThisWeek.get('thisWeekVideoCount'), total: newThisWeek.get('totalVideoCount')}}
                                    type="videos"
                                />
                                {screeners}
                                <ResumeBox
                                    icon="far fa-image"
                                    md={md}
                                    title={this.context.intl.messages['home.section.new-this-week.photos']}
                                    value={{weekly: newThisWeek.get('thisWeekImageCount'), total: newThisWeek.get('totalImageCount')}}
                                    type="images"
                                />
                                <ResumeBox
                                    icon="far fa-newspaper"
                                    md={md}
                                    title={this.context.intl.messages['home.section.new-this-week.titles']}
                                    value={{weekly: newThisWeek.get('thisWeekTitleCount'), total: newThisWeek.get('totalTitleCount')}}
                                    type="titles"
                                />
                            </Row>
                        </div>
                    </section>
                </Grid>
            </div>
        );
    }
}

export default Container.create(NewThisWeek);

