/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {Button} from 'react-bootstrap';
import Select from 'react-select';

import {TitleLanguageAvailabilityActions} from './title-language-availability-actions';
import TitleLanguageAvailabilityStore from './title-language-availability-store';
import {DownloadActions} from '../common/download/download-actions';
import Table from '../common/table';
import {MessagesContext} from '../messages/messages-context';
import Pagination from '../pagination';
import {RouterActions} from '../router/router-actions';
import {Debounce} from '../utils/utils';

const COLUMNS = [{
    getHeader: (context) => <th scope="col" key="mpm">{context.messages['common.mpm']}</th>,
    getValue: tla => tla.get('mpmNumber')
}, {
    getHeader: (context) => <th scope="col" key="title-name">{context.messages['title-language-availability.title-name']}</th>,
    getValue: tla => {
        if (tla.get('seriesSeasonEpisodeName')) {
            return `${tla.get('seriesSeasonEpisodeName')} - ${tla.get('titleName')}`;
        }

        return tla.get('titleName');
    }
}, {
    getHeader: (context) => <th scope="col" key="dub">{context.messages['title-language-availability.dub']}</th>,
    getValue: tla => textWithTooltips(tla.get('dub'))
}, {
    getHeader: (context) => <th scope="col" key="subtitle">{context.messages['title-language-availability.subtitle']}</th>,
    getValue: tla => textWithTooltips(tla.get('subtitle'))
}, {
    getHeader: (context) => <th scope="col" key="version-code">{context.messages['title-language-availability.version-code']}</th>,
    getValue: tla => tla.get('versionCode')
}, {
    getHeader: (context) => <th scope="col" key="availability-date">{context.messages['title-language-availability.availability-date']}</th>,
    getValue: tla => {
        let d = tla.get('airlineAvailabilityDate');
        if (Moment(d).isValid()) {
            return Moment(d).format('M/D/YY');
        }

        return '-';
    }
}];

const textWithTooltips = text => {
    if (!text) {
        return '-';
    }

    return text.split(
        /\s*,\s*/
    ).map(t => {
        let languageDesc = TitleLanguageAvailabilityStore.getState().get('languages').find(
            l => l.get('languageCode') === t, undefined, Immutable.Map({languageDesc: ''})
        ).get('languageDesc');

        t = t.trim();

        return `<span data-toggle="tooltip" data-placement="top" title="${languageDesc}">${t}</span>`;
    }).join(', ');
};

class TitleLanguageAvailability extends React.Component {
    static calculateState() {
        let state = TitleLanguageAvailabilityStore.getState();
        return {
            languages: state.get('languages'),
            titleLanguageAvailability: state.get('titleLanguageAvailability')
        };
    }

    static getStores() {
        return [TitleLanguageAvailabilityStore];
    }

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        let languages = props.location.search.lang || [];
        if (languages !== undefined && !Array.isArray(languages)) {
            languages = [languages];
        }

        this.state.languagesFilter = languages;
        this.state.titleFilter = props.location.search.title;

        this.handleDebouncedSearch = Debounce(this.handleDebouncedSearch.bind(this), 200);
        this.handleDownloadResults = this.handleDownloadResults.bind(this);
        this.handleLanguagesFilterChange = this.handleLanguagesFilterChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleTitleFilterChange = this.handleTitleFilterChange.bind(this);

        return;
    }

    componentDidMount() {
        let query = QueryString.parse(this.props.location.search);
        TitleLanguageAvailabilityActions.get(
            query.lang,
            query.title,
            query.offset,
            query.size
        );

        TitleLanguageAvailabilityActions.getLanguages();

        return;
    }

    componentDidUpdate(prevProps) {
        let compareQueryParams = (paramNames, currentValues, nextValues) => {
            return paramNames.some(p => currentValues[p] !== nextValues[p]);
        };
        let query = QueryString.parse(this.props.location.search);
        if (compareQueryParams(['lang', 'offset', 'size', 'title'], QueryString.parse(prevProps.location.search), query)) {
            TitleLanguageAvailabilityActions.get(
                query.lang,
                query.title,
                query.offset,
                query.size
            );
        }

        return;
    }

    static contextType = MessagesContext;

    handleDebouncedSearch() {
        let query = Object.assign(
            {},
            QueryString.parse(this.props.location.search)
        );

        if (this.state.titleFilter) {
            query.title = this.state.titleFilter;
        } else {
            delete query.title;
        }

        if (this.state.languagesFilter.length) {
            query.lang = this.state.languagesFilter;
        } else {
            delete query.lang;
        }

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);

        return;
    }

    handleDownloadResults() {
        const query = this.props.location.search;
        let q = Object.assign({
            download: true
        }, query, {
            size: 9999
        });

        if (q.lang) {
            q['language-list'] = q.lang;
            delete q.lang;
        }

        DownloadActions.startDownloadExecution('title/language-availability-summary', q);
    }

    handleLanguagesFilterChange(value) {
        this.setState(() => ({
            languagesFilter: value.map(v => v.languageCode)
        }));

        this.handleDebouncedSearch();

        return;
    }

    handlePageChange(offset) {
        let query = Object.assign({},
            QueryString.parse(this.props.location.search),
            {offset}
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);

        return;
    }

    handleTitleFilterChange(e) {
        let value = e.target.value;
        this.setState(() => ({
            titleFilter: value
        }));

        this.handleDebouncedSearch();

        return;
    }

    render() {
        let selectedLanguages = [];

        selectedLanguages = this.state.languagesFilter.map(
            lDesc => this.state.languages.find(
                l => l.get('languageCode') === lDesc
            )
        ).filter(
            l => !!l
        ).map(
            l => l.toJS()
        );

        return (
            <div className="container-fluid padding-all-20">
                <h2 className="margin-top-10">{this.context.intl.messages['title-language-availability.title']}</h2>
                <hr />
                <div className="row">
                    <div className="col-md-6">
                        <h3>{this.context.intl.messages['title-language-availability.search']}</h3>
                        <input
                            className="form-control"
                            placeholder={this.context.intl.messages['title-language-availability.search-by-title']}
                            onChange={this.handleTitleFilterChange}
                            ref="titleInput"
                            value={this.state.titleFilter || ''}
                            type="text"
                        />
                    </div>
                    <div className="col-md-3">
                        <h3>{this.context.intl.messages['title-language-availability.language']}</h3>
                        <Select
                            getOptionLabel={l => l.languageDesc}
                            getOptionValue={l => l.languageCode}
                            isMulti
                            options={this.state.languages.toJS()}
                            onChange={this.handleLanguagesFilterChange}
                            value={selectedLanguages}
                        />
                    </div>
                    <div className="col-md-3">
                        <h3>{this.context.intl.messages['title-language-availability.export']}</h3>
                        <Button
                            className="btn btn-primary btn-md"
                            onClick={this.handleDownloadResults}
                        >
                            <span className="glyphicon glyphicon-file"></span>&nbsp;{this.context.intl.messages['title-language-availability.export-button']}
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Table
                            columns={COLUMNS}
                            items={this.state.titleLanguageAvailability.get('results')}
                        />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <Pagination
                            className="margin-top-5"
                            offset={parseInt(this.props.location.search.offset, 10) || 0}
                            onChange={this.handlePageChange}
                            size={parseInt(this.props.location.search.size, 10) || 15}
                            total={this.state.titleLanguageAvailability.get('totalCount')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Container.create(TitleLanguageAvailability);
export {TitleLanguageAvailability};
