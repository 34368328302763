/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {OrderActions} from './order-actions';
import {OrderConstants} from './order-constants';
import {MessagesContext} from '../messages/messages-context';
import SessionStore from '../session/session-store';

export default class StatusChangeModal extends React.Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func.isRequired,
            orderId: PropTypes.number.isRequired,
            show: PropTypes.bool.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            newStatus: -1,
        };

        this.doChangeStatus = this.doChangeStatus.bind(this);
        this.doClose = this.doClose.bind(this);
        this.handleNewStatusChange = this.handleNewStatusChange.bind(this);
        this.handleReprocessOrder = this.handleReprocessOrder.bind(this);
    }

    static contextType = MessagesContext;

    doChangeStatus() {
        OrderActions.statusChange(this.props.orderId, this.state.newStatus, this.props.onClose);
    }

    doClose() {
        this.props.onClose(false);
    }

    handleNewStatusChange(event) {
        let newStatus = parseInt(event.target.value);
        this.setState({newStatus: newStatus});
    }

    handleReprocessOrder() {
        OrderActions.reprocessOrder(this.props.orderId);

        // onClose called with true should reload the caller's order data table
        this.props.onClose(true);
    }

    render() {
        let reprocessOrderButton;
        if (SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.REPROCESS)) {
            reprocessOrderButton = <Button className="btn-primary" onClick={this.handleReprocessOrder}>{this.context.intl.messages['orders.reprocess-order']}</Button>;
        }

        return (
            <Modal show={!!this.props.show} onHide={this.doClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center">{this.context.intl.messages['orders.status.change.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{this.context.intl.messages['orders.status.change.modal.description']}
                        <select className="form-control margin-right-5 show-per-page" value={this.state.newStatus} onChange={this.handleNewStatusChange} >
                            <option value="-1">Please Select one</option>
                            <option value={OrderConstants.ORDER.STATUS_BY_NAME.REQUESTED}>{OrderConstants.ORDER.STATUS[OrderConstants.ORDER.STATUS_BY_NAME.REQUESTED]}</option>
                            <option value={OrderConstants.ORDER.STATUS_BY_NAME.APPROVED}>{OrderConstants.ORDER.STATUS[OrderConstants.ORDER.STATUS_BY_NAME.APPROVED]}</option>
                            <option value={OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED}>{OrderConstants.ORDER.STATUS[OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED]}</option>
                        </select>
                    </p>
                </Modal.Body>
                <Modal.Footer className=" padding-all-20">
                    {reprocessOrderButton}
                    <Button className="btn-primary pull-right" disabled={this.state.newStatus < 0} onClick={this.doChangeStatus}>{this.context.intl.messages['common.ok']}</Button>
                    <Button className="btn-default btn-secondary margin-right-5 pull-left" onClick={this.doClose}>{this.context.intl.messages['common.close']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
