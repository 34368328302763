/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Header from './header';
import Info from './info';
import Nav from './nav';
import {TalentActions} from './talent-actions';
import TalentStore from './talent-store';
import WithPermissions from '../decorators/with-permissions';
import SessionStore from '../session/session-store';

const InfoTab = WithPermissions(Info);

class TalentLayout extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            aka: TalentStore.getState().get('aka'),
            canEditBrainiac: SessionStore.canUser(SessionStore.PERMISSIONS.EDIT_IN_BRAINIAC),
            talent: TalentStore.getState().get('talent')
        };
    }

    static getStores() {
        return [SessionStore, TalentStore];
    }

    constructor(props) {
        super(props);
        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            TalentActions.clear();
            this.init(nextProps);
        }
        return;
    }

    componentWillUnmount() {
        TalentActions.clear();
    }

    init(props) {
        let id = props.match.params.id;
        TalentActions.get(id);
        TalentActions.getTitles(id);
        return;
    }

    render() {
        return (
            <div>
                <Header
                    aka={this.state.aka}
                    canEditBrainiac={this.state.canEditBrainiac}
                    location={this.props.location}
                    talent={this.state.talent}
                />

                <Nav
                    location={this.props.location}
                    talentId={this.props.match.params.id}
                />

                <Switch>
                    <Route component={InfoTab}/>
                </Switch>
            </div>
        );
    }
}

export default Container.create(TalentLayout);
export {TalentLayout};
