/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Grid} from 'react-bootstrap';
import {Route, Switch} from 'react-router-dom';

import Header from '../../layout/header';
import SessionStore from '../../session/session-store';
import SignUp from '../create/create';
import SignUpStepTwo from '../create/step-two';
import Thanks from '../thanks'; // Sign-up thank-you message

import {RouterActions} from '~/src/router/router-actions';

export default class Layout extends React.Component {
    static get propTypes() {
        return {
            match: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            defaultCopyright: SessionStore.getState().get('defaultCopyright')
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        const partner = SessionStore.getPartner();
        if (partner.preventSignup) {
            RouterActions.redirect('/');
        }
    }

    render() {
        return (
            <div>
                <Header />
                <main className="page-content padding-top-50 padding-bottom-30">
                    <Grid className="padding-all-20">
                        <Switch>
                            <Route exact path={this.props.match.url} component={SignUp}/>
                            <Route exact path={`${this.props.match.url}/step-two`} component={SignUpStepTwo}/>
                            <Route exact path={`${this.props.match.url}/thank-you`} component={Thanks}/>
                        </Switch>
                    </Grid>
                    <footer className="copyright">
                        <Grid>{this.state.defaultCopyright}</Grid>
                    </footer>
                </main>
            </div>
        );
    }
}
