/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './family-holiday.less';

export default class FamilyHoliday extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.family-holiday">
                <div className="container op_container_family_holiday">

                    <div id="op_header" className="row">
                        <div id="op-header-img">
                            <div id="header-btns">
                                <a className="btn-trailer btn-header reel" href="/titles/1842276/videos/1903330" data-title="Sizzle Reel" rel="noopener noreferrer" target="_blank"><img src="https://www.wbtvd.com/page/family-holiday/banner_btn_sizzlereel.png" alt=""/></a>
                                <a className="btn-header presentation" href="https://www.wbtvd.com/page/family-holiday/FamilyHoliday2016.pdf" rel="noopener noreferrer" target="_blank"><img src="https://www.wbtvd.com/page/family-holiday/banner_btn_presentation.png" alt=""/></a>
                            </div>
                        </div>
                    </div>

                    <div id="op_footer" className="row">
                        For more information or to order the Family &amp; Holiday Programming Package, please email <a href="mailto:Mike.Shackleton@warnerbros.com?subject=Re%3A%20Family%20Holiday%20Movies%20Programming%20Package">Mike.Shackleton@warnerbros.com</a>
                    </div>

                    <div id="op_content" className="row clearfix">


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/731335" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/A_Charlie_Brown_Christmas_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/731335/videos/1785007" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/46828" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/A_Christmas_Story_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/46828/videos/1250877" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/1280116" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/A_Christmas_Story_2_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/1280116/videos/1784997" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/227612" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/A_Cinderella_Story_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/227612/videos/1562316" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/1005911" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/A_Cinderella_Story_Song_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/1005911/videos/1214625" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/550453" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/A_Dennis_Menace_Christmas_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/550453/videos/1441740" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/227697" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Charlie_Chocolate_Factory_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/227697/videos/1176533" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/53203" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Christmas_In_Connecticut_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/53203/videos/1785009" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/983466" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Dolphin_Tale_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/983466/videos/1281007" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/171386" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Elf_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/171386/videos/1302997" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/47104" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Gremlins_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/47104/videos/1292832" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/227745" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Happy_Feet_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/227745/videos/1774274" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/879685" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Happy_Feet_2_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/879685/videos/1280996" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/51860" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/How_The_Grinch_227x304.jpg)'}}></a>
                            <div className="btns"><a className="btn btn-trailer vid_class disabled" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/51864" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Jack_Frost_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/51864/videos/1784996" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/989984" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Joyful_Noise_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/989984/videos/1576478" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/47644" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/King_Of_Kings_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/47644/videos/1785011" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/47559" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Christmas_Vacation_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/47559/videos/1553217" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/969600" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/New_Years_Eve_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/969600/videos/1557632" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/51892" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Pinocchios_Christmas_x227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/51892/videos/1785018" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/49074" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Richie_Rich_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/49074/videos/1263115" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/231757" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Samantha_American_Girl_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/231757/videos/1785020" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/51840" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/First_Christmas_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/51840/videos/1785004" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/535514" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/The_Nativity_Story.jpg)'}}></a>
                            <div className="btns"><a href="/titles/535514/videos/1784995" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/227646" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Polar_Express_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/227646/videos/1187404" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/49453" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/The_Wizard_Of_Oz_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/49453/videos/1177392" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/541983" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/The_Year_Without_Santa_227x304.jpg)'}}></a>
                            <div className="btns"><a className="btn btn-trailer vid_class disabled" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/51914" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Twas_The_Night_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/51914/videos/1777661" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/343364" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Unaccompanied_Minors_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/343364/videos/1178685" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/50306" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/family-holiday/Willy_Wonka_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/50306/videos/1488040" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                    </div>
                </div>
                <p id="demo"></p>
            </DocumentTitle>

        );
    }
}
