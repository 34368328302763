/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './formats-showcase.less';

export default class FormatsShowcase extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.formats-showcase">
                <div id="op-page" className="container op_container_formats_showcase">

                    <div id="op_header" className="row">
                        <div id="op-header-img">
                            <a id="header-btn" className="btn-header sizzlereel" href="https://www.wbtvd.com/titles/1852388/videos/2042797" target="_blank"><img src="https://www.wbtvd.com/page/formats-showcase/banner_btn_sizzlereel.png" alt="Sizzle Reel" /></a>
                        </div>
                    </div>

                    <div id="op_content" className="row clearfix">
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1387816" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/First_Dates.jpg)'}} title="First Dates (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1387816" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1387816/videos/2042904" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1852468" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/First_Dates_Cruise.jpg)'}} title="First Dates Cruise (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1852468" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1852468/videos/2042792" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1843240" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/First_Dates_Hotel.jpg)'}} title="First Dates Hotel (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1843240" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1843240/videos/2042796" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1852165" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/The_Opposites.jpg)'}} title="The Opposites (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1852165" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1852165/videos/2042907" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1614600" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Little_Big_Shots.jpg)'}} title="Little Big Shots (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1614600" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1614600/videos/2042800" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1843800" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Game_Of_Games.jpg)'}} title="Game of Games (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1843800" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1843800/videos/2042798" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1852504" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Greatest_Night_Of_Giveaways.jpg)'}} title="Greatest Night Of Giveaways (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1852504" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1852504/videos/2042799" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1852166" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/What_Do_You_Think_I_Do.jpg)'}} title="What Do You Think I Do? (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1852166" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1852166/videos/2042815" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1843228" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Cash_Or_Trash.jpg)'}} title="Cash or Trash (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1843228" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1843228/videos/2042905" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1852164" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Bachelor_Listen_To_Your_Heart.jpg)'}} title="Bachelor Presents: Listen To Your Heart (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1852164" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1852164/videos/2042787" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/257037" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/The_Bachelorette.jpg)'}} title="The Bachelorette (Format)"></a>
                            <div className="btns">
                                <a href="/titles/257037" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/257037/videos/2042785" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1217972" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Who_Do_You_Think_You_Are.jpg)'}} title="Who Do You Think You Are? (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1217972" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1217972/videos/2042909" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1852187" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Love_Me.jpg)'}} title="Love Me (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1852187" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1852187/videos/2042801" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1852188" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/The_Man_In_Room_301.jpg)'}} title="The Man In Room 301 (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1852188" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1852188/videos/2042803" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1846677" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Mental_Samurai.jpg)'}} title="Mental Samurai (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1846677" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1846677/videos/2042804" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1852535" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/What_Where_When.jpg)'}} title="What, Where, When? (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1852535" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1852535/videos/2042816" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1828579" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/The_Repair_Shop.jpg)'}} title="The Repair Shop (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1828579" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1828579/videos/1973558" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1217937" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Supernanny.jpg)'}} title="Supernanny (Format)"></a>
                            <div className="btns">
                                <a href="/titles/1217937" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1217937/videos/2042808" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a className="poster" href="/titles/1852388" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/formats-showcase/Formats_Showcase_2020.jpg)'}} title="Formats Showcase 2020"></a>
                            <div className="btns">
                                <a href="/titles/1852388" className="btn btn_info showinfo_class" target="_blank"><span>&#8227; Info</span></a><a href="/titles/1852388/videos/2042797" className="btn btn-trailer vid_class" target="_blank"><span>&#8227; Trailer</span></a>
                            </div>
                        </div>
                    </div>
                </div>

            </DocumentTitle>

        );
    }
}
