/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import ImageLoader, {ImageLoaderPlaceholders} from './image-loader';
import Analytics from '../../../analytics';
import {AssetTypeConstants} from '../../../asset-types/asset-type-constants';
import {MessagesContext} from '../../../messages/messages-context';
import {OrderActions} from '../../../orders/order-actions';
import OrderStore from '../../../orders/order-store';
import Preloader from '../../../preloader';
import {IsNotTriggerKey} from '../../../utils/utils';
import {AssetTitleActions, AssetTitleConstants} from '../asset-title-actions';


import '../../../styles/bootstrap/mixins/text-overflow.less';

const noop = () => void 0;

export default class GalleryThumbnail extends React.Component {
    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool,
            canRemove: PropTypes.bool.isRequired,
            image: PropTypes.instanceOf(Immutable.Map).isRequired,
            // FIXME:
            // imageStatus: PropTypes.oneOf(Object.values(OrderActions.ITEM_STATUS).map(is => is.id))
            // Where OrderActions.ITEM_STATUS is a list of this API enum:
            // https://github.com/warnerbrostv/Project-Brainiac-Java/blob/develop/src/main/java/com/wbtv/brainiac/api/order/ItemStatusType.java#L22
            imageStatus: PropTypes.string.isRequired,
            pathname: PropTypes.string.isRequired,
            onRemove: PropTypes.func,
            onViewImageClick: PropTypes.func,
            orderId: PropTypes.number.isRequired,
            orderItemId: PropTypes.number.isRequired,
            orderStatus: PropTypes.number,
            showCaptions: PropTypes.bool,
            showPlaceholder: PropTypes.bool,
            titleId: PropTypes.number.isRequired
        };
    }

    static get defaultProps() {
        return {
            canAddToCart: false,
            canRemove: false,
            onRemove: noop,
            onViewImageClick: noop,
            orderStatus: undefined,
            showCaptions: false,
            showPlaceholder: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            openMenu: false
        };

        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleOpenMenu = this.handleOpenMenu.bind(this);
        this.handleViewImageClick = this.handleViewImageClick.bind(this);

        return;
    }

    componentDidMount() {
        if (
            this.props.image &&
            this.props.image.get('thumbnailUrl') === undefined
        ) {
            AssetTitleActions.requestUrl(this.props.image);
        }

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            !this.props.image ||
            !nextProps.image ||
            this.props.canAddToCart !== nextProps.canAddToCart ||
            this.props.canRemove !== nextProps.canRemove ||
            this.props.imageStatus !== nextProps.imageStatus ||
            // Using equals here because the image changes in each run.
            !this.props.image.equals(nextProps.image) ||
            this.props.showCaptions !== nextProps.showCaptions ||
            this.props.titleId !== nextProps.titleId
        ) {
            return true;
        }

        if (this.state.openMenu !== nextState.openMenu) {
            return true;
        }

        return false;
    }

    componentDidUpdate() {
        if (
            this.props.image &&
            this.props.image.get('thumbnailUrl') === undefined
        ) {
            AssetTitleActions.requestUrl(this.props.image);
        }

        return;
    }

    static contextType = MessagesContext;

    handleAddToCart(event) {
        if (event && IsNotTriggerKey(event)) {
            return;
        }

        event.preventDefault();
        OrderActions.add(AssetTypeConstants.ASSET_TYPE.IMAGE, this.props.titleId, [this.props.image]);
        this.setState({openMenu: false});
    }

    handleDownload(renditionType, assetId, event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        let id = this.props.image.get('assetId');
        if (!id) {
            id = this.props.image.get('id');
        }
        Analytics.imageDownloadEvent(id, renditionType, this.props.titleId, this.props.orderId, this.props.orderItemId);

        if (renditionType === 'Source') {
            AssetTitleActions.downloadAsset(assetId);

            return;
        }

        AssetTitleActions.downloadImageRendition(assetId, renditionType);

        return;
    }

    handleOpenMenu(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        this.setState(prevState => ({
            openMenu: !prevState.openMenu
        }));
        return;
    }

    handleRemove(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        this.props.onRemove();
    }

    handleViewImageClick(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        this.setState({openMenu: false});
        this.props.onViewImageClick();
        return;
    }

    render() {
        if (!this.props.image && !this.props.showPlaceholder) {return null;}
        let addToCart;
        let download;
        let remove;
        let restricted;
        let videoOverlay;
        if (this.props.canAddToCart) {
            addToCart = (
                <tr>
                    <td
                        onClick={this.handleAddToCart}
                        onKeyUp={this.handleAddToCart}
                        role="button"
                        tabIndex="0"
                    >
                        <span className="glyphicon glyphicon-shopping-cart"></span>{' '+this.context.intl.messages['asset.image.gallery.add-to-cart']}
                    </td>
                </tr>);
        }

        if (this.props.canRemove) {
            remove = (
                <tr>
                    <td
                        onClick={this.handleRemove.bind(this)}
                        onKeyUp={this.handleRemove.bind(this)}
                        role="button"
                        tabIndex="0"
                    >
                        <span className="glyphicon glyphicon-trash delete-item"></span>{' '+this.context.intl.messages['asset.image.gallery.remove']}
                    </td>
                </tr>
            );
        }

        if (this.props.image) {
            if (this.props.image.get('deliveryType') === AssetTypeConstants.DELIVERY_TYPES.NEEDS_APPROVAL) {
                restricted = <div className="thumbnail-image needs-approval"><span></span>{' '+this.context.intl.messages['asset.image.gallery.needs-approval']}</div>;
            } else if (this.props.image.get('deliveryType') === AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE) {
                restricted = <div className="thumbnail-image restricted"><span className="glyphicon glyphicon-ban-circle"></span>{' '+this.context.intl.messages['asset.image.gallery.restricted']}</div>;
            }
        }

        const canDownloadImage = OrderStore.canDownloadImage(this.props.image, this.props.imageStatus, this.props.orderStatus);

        if (Object.values(canDownloadImage).some(v => v === true)) {
            const assetId = this.props.image.get('imageId', this.props.image.get('id'));
            let downloadFull, downloadLoRes, downloadSource = '';
            if (canDownloadImage.canDownloadLoRes) {
                const lowResFileType = this.props.image.get('previewResolutionFileType') || '';
                downloadLoRes = (
                    <a
                        onClick={this.handleDownload.bind(this, 'LoRes', assetId)}
                        onKeyUp={this.handleDownload.bind(this, 'LoRes', assetId)}
                        role="button"
                        tabIndex="0"
                    >
                        {this.context.intl.messages['asset.image.gallery.renditions.low-res']} {lowResFileType}
                    </a>
                );
            }
            if (canDownloadImage.canDownloadFull) {
                const hiResFileType = this.props.image.get('fullResolutionFileType') || '';
                downloadFull = (
                    <a
                        onClick={this.handleDownload.bind(this, 'HiRes', assetId)}
                        onKeyUp={this.handleDownload.bind(this, 'HiRes', assetId)}
                        role="button"
                        tabIndex="0"
                    >
                        {this.context.intl.messages['asset.image.gallery.renditions.full-res']} {hiResFileType}
                    </a>
                );
            }
            if (canDownloadImage.canDownloadSource) {
                const sourceFileType = this.props.image.get('mediaFile') || '';
                downloadSource = (
                    <a
                        onClick={this.handleDownload.bind(this, 'Source', assetId)}
                        onKeyUp={this.handleDownload.bind(this, 'Source', assetId)}
                        role="button"
                        tabIndex="0"
                    >
                        {this.context.intl.messages['asset.image.gallery.renditions.source']} {sourceFileType}
                    </a>
                );
            }

            let openMenu = {left: '25%', display: 'none'};
            if (this.state.openMenu) {
                openMenu.display = 'block';
            }

            download = (
                <tr className="thumbnail-tooltip" >
                    <td
                        onClick={this.handleOpenMenu}
                        onKeyUp={this.handleOpenMenu}
                        role="button"
                        tabIndex="0"
                    >
                        <span className="glyphicon glyphicon-download-alt"></span>{' '+this.context.intl.messages['asset.image.gallery.download']}
                        <div className="tooltip fade bottom in" role="tooltip" style={openMenu}>
                            <div className="tooltip-arrow gallery-tooltip-arrow" style={{left: '50%'}}></div>
                            <div className="tooltip-inner gallery-tooltip-inner ">
                                {downloadLoRes}
                                {downloadFull}
                                {downloadSource}
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }

        let captions;
        if (this.props.image && this.props.showCaptions) {
            let caption = this.props.image.get('assetComment') || this.props.image.get('name') || '';

            let ellipsis = '';
            if (caption.length > 120) {
                ellipsis = '...';
            }

            captions = <div>
                <div className="thumbnail-caption-long show text-overflow-wrap" dangerouslySetInnerHTML={{__html: caption.slice(0, 120) + ellipsis}}></div>
            </div>;
        }

        let image = undefined;
        if (this.props.image) {
            image = this.props.image.get('thumbnailUrl');
        }
        if (this.props.showPlaceholder) {
            image = ImageLoaderPlaceholders.VERTICAL;
        }

        if (
            /^video\//.test(this.props.image.get('mimeType')) ||
            this.props.image.get('contentType') === parseInt(AssetTitleConstants.CONTENT_TYPES.IMAGE.SOCIAL_MEDIA.TYPES.SOCIAL_MEDIA_CLIP)
        ) {
            videoOverlay = (<span className="img-video-still"></span>);
        }

        const thumbnailContainerWidth = ClassNames(
            {
                'col-sm-4': !this.props.pathname,
                'col-sm-12': this.props.pathname
            }
        );

        return (
            <div className= {`${thumbnailContainerWidth} thumbnail-container`}>
                <Preloader
                    bsSize="sm"
                    fixed
                    show={image === undefined}
                >
                    <div className="responsive-container">
                        <div className="vertical-spacer">
                        </div>
                        <div className="img-container">
                            <ImageLoader
                                alt={`${this.props.image.get('assetName')} Thumbnail`}
                                src={image}
                            />
                            {videoOverlay}
                        </div>
                        <div className="thumbnail-hover" >
                            <table className="thumbnail-menu">
                                <tbody>
                                    <tr className="image-overlay-link">
                                        <td
                                            onClick={this.handleViewImageClick}
                                            onKeyUp={this.handleViewImageClick}
                                            role="button"
                                            tabIndex="0"
                                        >
                                            <span className="glyphicon glyphicon-eye-open"></span>{' '+this.context.intl.messages['asset.image.gallery.view-image']}
                                        </td>
                                    </tr>
                                    {addToCart}
                                    {download}
                                    {remove}
                                </tbody>
                            </table>
                        </div>
                        {restricted}
                    </div>
                    {captions}
                </Preloader>
            </div>
        );
    }
}
