/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './index.less';

export default class HarryPotter extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.harry-potter">
                <div className="op_container_harry_potter">
                    <div
                        className="header-background"
                        style={{
                            backgroundColor: '#1b1e22',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            color: '#FFF'}}>
                        <div className="container padding-all-20">
                            <div className="row margin-bottom-20">
                                <div className="col-md-4"></div>
                                <div className="col-md-4 padding-all-20">
                                    <img
                                        src={require('./images/logo-harry-potter.png')}
                                        className="img-responsive"
                                    />
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div
                                    className="col-md-8 text-center"
                                    style={{
                                        fontSize: '150%',
                                        paddingBottom: '40px'}}>
                                    <p>
                                        For the Harry Potter Design Package, the goal was to create an
                                        identity that feels unique to the Harry Potter franchise but
                                        lives effortlessly at home on any platform.
                                    </p>
                                    <div className="text-center">
                                        <a
                                            href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/DESIGN+PACKAGES+AND+OVERVIEW/WW_TV_Toolkit_Design_Package_LoRes.pdf"
                                            className="btn btn-lg btn-gray">
                                            <i className="glyphicon glyphicon-download-alt"></i> Design Package
                                            Overview &amp; Toolkit
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                    <div className="container padding-all-20">
                        <div className="row">
                            <div className="col-xs-12 col-lg-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="/harry-potter/promo-packages"
                                            className="hover hover-promo-packages">
                                            <img
                                                src={require('./temp-images/ww-title-promo-packages.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a className="thumbnail-title" href="/harry-potter/promo-packages">
                                            Promo Packages
                                        </a>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="/harry-potter/editorial-packages"
                                            className="hover hover-editorial-packages">
                                            <img
                                                src={require('./temp-images/ww-title-editorial-packages.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a
                                            className="thumbnail-title"
                                            href="/harry-potter/editorial-packages">
                                            Editorial Packages
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="/harry-potter/on-air-navigation"
                                            className="hover hover-on-air-navigation">
                                            <img
                                                src={require('./temp-images/ww-title-on-air-navigation.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a
                                            className="thumbnail-title"
                                            href="/harry-potter/on-air-navigation">
                                            On-Air Navigation
                                        </a>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="/harry-potter/core-elements"
                                            className="hover hover-core-elements">
                                            <img
                                                src={require('./temp-images/ww-title-core-elements.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a className="thumbnail-title" href="/harry-potter/core-elements">
                                            Core Elements
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </DocumentTitle>

        );
    }
}
