/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Grid, Glyphicon, Row} from 'react-bootstrap';

import {SmallThumbnail, Thumbnail} from './thumbnails';
import {MessagesContext} from '../../../messages/messages-context';

/**
 * Product Horizontal List
 */
export default (numberOfElements = 4) => {
    return class List extends React.Component {

        static get propTypes() {
            return {
                images: PropTypes.instanceOf(Immutable.Map).isRequired,
                publishingList: PropTypes.object.isRequired,
                titles: PropTypes.instanceOf(Immutable.Map).isRequired,
                videos: PropTypes.instanceOf(Immutable.Map).isRequired
            };
        }

        static contextType = MessagesContext;

        render() {
            let list = this.props.publishingList;
            let items = list.get('items');

            let viewAll;
            if (this.props.publishingList.get('summaryLinkURL')) {
                let caption = this.props.publishingList.get('summaryLinkName', this.context.intl.messages['home.section.list.view-all']);
                viewAll = (
                    <a
                        aria-label={`${caption} ${list.get('displayName')}`}
                        className="btn btn-sm btn-primary"
                        href={this.props.publishingList.get('summaryLinkURL')}
                    >
                        {caption} <Glyphicon glyph="chevron-right" />
                    </a>
                );
            }

            // Only display section title and thumbnails if there are items
            let gridContents;
            if (items.size) {
                let thumbnails = (
                    <Row>
                        <Col md={12} lg={6}>
                            <Row>
                                <Thumbnail
                                    image={this.props.images.get(items.getIn([0, 'assetId']))}
                                    item={items.get(0)}
                                    title={this.props.titles.get(items.getIn([0, 'assetId']))}
                                    video={this.props.videos.get(items.getIn([0, 'assetId']))}
                                />
                                <Thumbnail
                                    image={this.props.images.get(items.getIn([1, 'assetId']))}
                                    item={items.get(1)}
                                    title={this.props.titles.get(items.getIn([1, 'assetId']))}
                                    video={this.props.videos.get(items.getIn([1, 'assetId']))}
                                />
                            </Row>
                        </Col>
                        <Col md={12} lg={6}>
                            <Row>
                                <Thumbnail
                                    image={this.props.images.get(items.getIn([2, 'assetId']))}
                                    item={items.get(2)}
                                    title={this.props.titles.get(items.getIn([2, 'assetId']))}
                                    video={this.props.videos.get(items.getIn([2, 'assetId']))}
                                />
                                <Thumbnail
                                    image={this.props.images.get(items.getIn([3, 'assetId']))}
                                    item={items.get(3)}
                                    title={this.props.titles.get(items.getIn([3, 'assetId']))}
                                    video={this.props.videos.get(items.getIn([3, 'assetId']))}
                                />
                            </Row>
                        </Col>
                    </Row>
                );

                if (numberOfElements === 8) {
                    thumbnails = (
                        <Row>
                            <Col md={12} lg={6}>
                                <Row>
                                    <SmallThumbnail
                                        image={this.props.images.get(items.getIn([0, 'assetId']))}
                                        item={items.get(0)}
                                        title={this.props.titles.get(items.getIn([0, 'assetId']))}
                                        video={this.props.videos.get(items.getIn([0, 'assetId']))}
                                    />
                                    <SmallThumbnail
                                        image={this.props.images.get(items.getIn([1, 'assetId']))}
                                        item={items.get(1)}
                                        title={this.props.titles.get(items.getIn([1, 'assetId']))}
                                        video={this.props.videos.get(items.getIn([1, 'assetId']))}
                                    />
                                    <SmallThumbnail
                                        image={this.props.images.get(items.getIn([2, 'assetId']))}
                                        item={items.get(2)}
                                        title={this.props.titles.get(items.getIn([2, 'assetId']))}
                                        video={this.props.videos.get(items.getIn([2, 'assetId']))}
                                    />
                                    <SmallThumbnail
                                        image={this.props.images.get(items.getIn([3, 'assetId']))}
                                        item={items.get(3)}
                                        title={this.props.titles.get(items.getIn([3, 'assetId']))}
                                        video={this.props.videos.get(items.getIn([3, 'assetId']))}
                                    />
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <SmallThumbnail
                                        image={this.props.images.get(items.getIn([4, 'assetId']))}
                                        item={items.get(4)}
                                        title={this.props.titles.get(items.getIn([4, 'assetId']))}
                                        video={this.props.videos.get(items.getIn([4, 'assetId']))}
                                    />
                                    <SmallThumbnail
                                        image={this.props.images.get(items.getIn([5, 'assetId']))}
                                        item={items.get(5)}
                                        title={this.props.titles.get(items.getIn([5, 'assetId']))}
                                        video={this.props.videos.get(items.getIn([5, 'assetId']))}
                                    />
                                    <SmallThumbnail
                                        image={this.props.images.get(items.getIn([6, 'assetId']))}
                                        item={items.get(6)}
                                        title={this.props.titles.get(items.getIn([6, 'assetId']))}
                                        video={this.props.videos.get(items.getIn([6, 'assetId']))}
                                    />
                                    <SmallThumbnail
                                        image={this.props.images.get(items.getIn([7, 'assetId']))}
                                        item={items.get(7)}
                                        title={this.props.titles.get(items.getIn([7, 'assetId']))}
                                        video={this.props.videos.get(items.getIn([7, 'assetId']))}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    );
                }

                gridContents = (
                    <section className="padding-x-15 row col-xs-12 grid-container">
                        <h2 dangerouslySetInnerHTML={{__html: list.get('displayName')}} className="h3" />
                        {viewAll}
                        {thumbnails}
                    </section>
                );
            }

            return (
                <Grid className="list">
                    {gridContents}
                </Grid>
            );
        }
    };
};
