/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

import ResultContent from './result-content';
import ResultSkeleton from './result-skeleton';
import ImageLoader from '../../titles/asset-title/images/image-loader';

export default function LoadingCard({className, ratio}) {
    const descriptionSlot = (
        <a href="#" className="thumbnail-title Wb(bw)">Loading...</a>
    );
    return (
        <ResultSkeleton isImageLoading={true} descriptionSlot={descriptionSlot} className={className}>
            <ResultContent ratio={ratio}>
                <ImageLoader alt="" src="" className="img-responsive" />
            </ResultContent>
        </ResultSkeleton>
    );
}

LoadingCard.propTypes = {
    className: PropTypes.string,
    ratio: PropTypes.oneOf(['1x1', '4x3', '16x9']),
};

LoadingCard.defaultProps = {
    className: '',
    ratio: '1x1',
};
