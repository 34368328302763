/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';

import {MessagesContext} from '../../messages/messages-context';
import {RouterActions} from '../../router/router-actions';


export default class SeasonSummaryItem extends React.PureComponent {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            assetTypePath: PropTypes.string.isRequired,
            redirectParams: PropTypes.object.isRequired,
            title: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            redirectParams: {},
        };
    }

    constructor(props) {
        super(props);
        this.redirect = this.redirect.bind(this);
    }

    static contextType = MessagesContext;

    redirect() {
        const {asset, assetTypePath, redirectParams} = this.props;
        const params = QueryString.stringify(redirectParams);
        const query = params ? `?${params}` : '';
        const path = `/titles/${asset.get('seasonTitleId')}/${assetTypePath}${query}`;
        RouterActions.redirect(path);
    }

    render() {
        const {asset, title} = this.props;
        return (
            <div className="col-sm-6 col-lg-3">
                <div className="row">
                    <div className="h3">Season {asset.get('order')} <small>({asset.get('assets')} {title})</small></div>
                </div>
                <div className="row">
                    <Button onClick={this.redirect} bsSize="sm" bsStyle="primary">
                        {this.context.intl.messages['common.view-all']} <Glyphicon glyph="chevron-right" />
                    </Button>
                </div>
            </div>
        );
    }
}
