/**
 * @param {string} stringWithHtml
 * @return {string}
 */
const stripHtml = (stringWithHtml) => {
    const dom = new DOMParser().parseFromString(stringWithHtml, 'text/html');
    return dom.body.textContent;
};

export default stripHtml;
