/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';

import {DownloadActions} from '../../common/download/download-actions';
import {MessagesContext} from '../../messages/messages-context';
import Pagination from '../../pagination';
import {IsNotTriggerKey} from '../../utils/utils';
import {WorkOrderActions, WorkOrderConstants} from '../../work-orders/work-order-actions';
import {OrderActions} from '../order-actions';
import {OrderConstants} from '../order-constants';
import OrderStore from '../order-store';

import 'datatables.net-responsive-bs/css/responsive.bootstrap.css';
import '../../styles/app/dataTables.brainiac.css';
import '../../styles/app/responsive.brainiac.css';
import 'datatables.net-responsive-bs';

const createHeaders = function(headerName, showName, className) {
    let ariaLabel = headerName;
    if (OrderConstants.ORDER.SORT_TYPE[headerName]) {
        ariaLabel = `${OrderConstants.ORDER.SORT_TYPE[headerName].name}: click to change sort direction`;
    }
    return function(component) {
        let titleText;
        /* istanbul ignore if */
        if (headerName === 'unsubscribeClient' && component.props.selectedClients.size) {
            titleText = component.context.intl.messages['document-titles.browse-orders.standing-orders.unsbuscribe-clients'];
        }
        return (
            <th
                aria-label={ariaLabel}
                onClick={component.handleHeaderClick.bind(component, headerName)}
                className={ClassNames(component.getSortFieldNameClass(headerName, className))}
                key={headerName}
                scope="col"
                title={titleText}>
                {showName}
            </th>
        );
    };
};

/**
 * Column definitions.
 */
const noValueLabel = '-';

/* istanbul ignore next */
function getClientRepGroupName(user) {
    if (user === undefined) {
        return noValueLabel;
    }
    return user.getIn(['clientRepGroups', 0, 'name'], noValueLabel);
}
/* istanbul ignore next */
function getUserFullName(user) {
    if (user === undefined) {
        return noValueLabel;
    }
    return user.get('fullName', '');
}

const COLUMNS = {
    client: {
        get: function(order) {
            return order.get('clientFullName', noValueLabel);
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.CLIENT_NAME.id, 'Client')
    },
    clipsCount: {
        get: function(order) {
            return order.get('videoUserClipCount', noValueLabel);
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.VIDEO_USER_CLIP_COUNT.id, <span className="fa fa-cut text-default-color" />, 'icon-column')
    },
    completeDate: {
        get: function(order, nextProps, context) {
            let date = Moment(order.get('completedDate'));
            if (date.isValid()) {
                return date.format(context.messages['date-format']);
            }
            return noValueLabel;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.COMPLETE_DATE.id, 'Complete Date')
    },
    download: {
        get: function(order, nextProps) {
            if (nextProps.location.pathname.match('/metadata')) {
                let downloadJSON = '';
                let downloadSalesDeck = '';
                let downloadWorkOrder = '';
                let viewDigitalSalesDeck = '';
                /* istanbul ignore else */
                if (parseInt(order.get('status'), 10) === WorkOrderConstants.STATUS_BY_NAME.COMPLETE &&
                    order.get('exportSpecification')) {
                    downloadWorkOrder = `<a class="text-default-color auth-link" data-toggle="tooltip" data-placement="top" title="Download Package"><span class="far fa-file-excel download-work-order" data-order-id=${order.get('id', noValueLabel)} data-order-status=${order.get('status')} data-order-export-type=${WorkOrderConstants.EXPORT_TYPES.EXCEL}></span></a>`;
                    /* istanbul ignore else */
                    if (parseInt(order.get('exportSpecification'), 10) !== WorkOrderConstants.EXPORT_SPECIFICATIONS_BY_NAME.MASS_UPDATE_TEMPLATE) {
                        downloadJSON = `<a class="text-default-color auth-link" data-toggle="tooltip" data-placement="top" title="Download as JSON"><span class="far fa-brackets-curly download-as-json" data-order-id=${order.get('id', noValueLabel)} data-order-status=${order.get('status')} data-order-export-type=${WorkOrderConstants.EXPORT_TYPES.JSON}></span></a>`;
                    }
                }
                /* istanbul ignore next */
                if (nextProps.canDownloadSalesDeck) {
                    downloadSalesDeck = `<a class="text-default-color auth-link" data-toggle="tooltip" data-placement="top" title="Download Sales Deck"><span class="far fa-file-pdf download-sales-deck" data-order-id=${order.get('id', noValueLabel)}></span></a>`;
                }
                /* istanbul ignore next */
                if (nextProps.canViewDigitalSalesDeck) {
                    const guid = order.get('guid', noValueLabel);
                    viewDigitalSalesDeck = `<a class="text-default-color auth-link" data-toggle="tooltip" data-placement="top" title="View Digital Sales Deck"><span class="far fa-object-group view-digital-sales-deck" data-order-guid=${guid}></span></a>`;
                }
                return `${downloadWorkOrder} ${downloadJSON} ${downloadSalesDeck} ${viewDigitalSalesDeck}`;
            }
            const downloadApproved = OrderStore.canDownloadOrder(order);
            /* istanbul ignore else */
            if (!downloadApproved) { //hide if I wont be able to download
                return noValueLabel;
            }
            if (nextProps.location.pathname.match('/my-orders') || nextProps.location.pathname.match('/my-saved-carts')) {
                let downloadRenditionType = order.get('downloadRenditionType') || OrderConstants.DOWNLOAD_RENDITION_TYPE.HiRes.type;
                return `<a class="text-default-color"><span class="${ClassNames('glyphicon glyphicon-download-alt', {'download-approved': downloadApproved})}" data-order-id=${order.get('id', noValueLabel)} data-order-renditionType=${downloadRenditionType}></span></a>`;
            }
        },
        header: createHeaders('download', <span className="glyphicon glyphicon-download-alt" />, 'icon-column no-sort')
    },
    dueDate: {
        get: function(order, nextProps, context) {
            let date = Moment(order.get('dueDate'));
            if (date.isValid()) {
                return date.format(context.messages['date-format']);
            }
            return noValueLabel;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.DUE_DATE.id, 'Due Date')
    },
    fieldOffice: {
        get: function(order, nextProps) {
            /* istanbul ignore else */
            if (order.get('userId')) {
                return getClientRepGroupName(nextProps.users.get(order.get('userId')));
            }
            return noValueLabel;
        },
        header: createHeaders('fieldOffice', 'Field Office', 'no-sort')
    },
    imageCount: {
        get: function(order) {
            return order.get('imageCount', noValueLabel);
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.IMAGE_COUNT.id, <span className="glyphicon glyphicon-camera text-default-color" />, 'icon-column')
    },
    order: {
        get: function(order) {
            return `<a href="/orders/${order.get('id', noValueLabel)}">${order.get('id', noValueLabel)}</a>`;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.ORDER_NUMBER.id, 'Order #')
    },
    orderName: {
        get: function(order) {
            const orderName = order.get('name');
            let orderShowName;
            /* istanbul ignore next */
            if (orderName === null || orderName === undefined) {
                return noValueLabel;
            }
            orderShowName = orderName;
            let orderLabel = '';
            const imageCount = order.get('imageCount');
            const videoCount = order.get('videoCount');
            const clipCount = order.get('videoUserClipCount');
            if (imageCount && !videoCount && !clipCount) {
                orderLabel = 'IMAGES';
            } else if (!imageCount && videoCount && !clipCount) {
                orderLabel = 'VIDEOS';
            } else if (!imageCount && !videoCount && clipCount) {
                orderLabel = 'MOMENTS';
            }
            orderShowName = `<div><span class='badge badge-secondary'>${orderLabel}</span> ${orderName}</div>`;
            return orderShowName;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.ORDER_NAME.id, 'Order Name')
    },
    packageName: {
        get: function(order) {
            let orderName = order.get('name');
            if (orderName === null || orderName === undefined) {
                return noValueLabel;
            }
            return orderName;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.ORDER_NAME.id, 'Package Name')
    },
    productTitle: {
        get: function(order) {
            return order.get('titleName', noValueLabel);
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.TITLE.id, 'Product Title')
    },
    reloadToCart: {
        get: function(order) {
            return `<a class=""> <span class="glyphicon glyphicon-repeat reload-to-cart" data-order-id=${order.get('id', noValueLabel)}></span></a>`;
        },
        header: createHeaders('reloadToCart', <span className="glyphicon glyphicon-repeat" />, 'icon-column no-sort')
    },
    requestDate: {
        get: function(order, nextProps, context) {
            let date = Moment(order.get('requestDate'));
            if (date.isValid()) {
                return date.format(context.messages['datetime-middleDash-short-format']);
            }
            return noValueLabel;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.REQUEST_DATE.id, 'Request Date')
    },
    requester: {
        get: function(order, nextProps) {
            /* istanbul ignore else */
            if (order.get('requesterUserId')) {
                if (order.get('requesterUserId') === order.get('clientUserId')) {
                    return order.get('clientFullName', noValueLabel);
                }
                return getUserFullName(nextProps.users.get(order.get('requesterUserId')));

            }
            return noValueLabel;
        },
        header: createHeaders('requester', 'Requester', 'no-sort')
    },
    status: {
        get: function(order) {
            /* istanbul ignore if */
            if (order.get('status') === null || order.get('status') === undefined) {
                return noValueLabel;
            }
            return `<div order-status="${order.get('status')}">${OrderConstants.ORDER.STATUS[order.get('status')]}</div>`;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.ORDER_STATUS.id, 'Status')
    },
    statusChange: {
        get: function(order) {
            switch (order.get('status')) {
            case OrderConstants.ORDER.STATUS_BY_NAME.REQUESTED:
            case OrderConstants.ORDER.STATUS_BY_NAME.APPROVED:
            case OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED:
            case OrderConstants.ORDER.STATUS_BY_NAME.PARTIALLY_FULFILMENT:
            case OrderConstants.ORDER.STATUS_BY_NAME.FULFILMENT:
            case OrderConstants.ORDER.STATUS_BY_NAME.DENIED:
                return `<a><span class="glyphicon glyphicon-cog status-change" data-order-id=${order.get('id')}></span></a>`;
            }
            return '';
        },
        header: createHeaders('statusChange', <span className="glyphicon glyphicon-cog" />, 'icon-column no-sort')
    },
    subscriptionDate: {
        get: function(order, nextProps, context) {
            let date = Moment(order.get('subscriptionDate'));
            if (date.isValid()) {
                return date.format(context.messages['date-format']);
            }
            return noValueLabel;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.SUBSCRIPTION_DATE.id, 'Subscription Date')
    },
    type: {
        get: function(order) {
            if (order.get('subscriptionContentType') === null || order.get('subscriptionContentType') === undefined) {
                return noValueLabel;
            }
            return OrderConstants.SUBSCRIPTION_CONTENT_TYPES[order.get('subscriptionContentType')];
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.SUBSCRIPTION_TYPE.id, 'Type')
    },
    unsubscribeClient: {
        get: function(order) {
            if (
                order.get('userId') === null || order.get('userId') === undefined ||
                order.get('titleId') === null || order.get('titleId') === undefined ||
                order.get('subscriptionContentType') === null || order.get('subscriptionContentType') === undefined
            ) {
                return noValueLabel;
            }
            return `<a class=""> <span class="glyphicon glyphicon-trash unsubscribe-client" userId=${order.get('userId')} titleId=${order.get('titleId')} contentType=${order.get('subscriptionContentType')}></span></a>`;
        },
        header: createHeaders('unsubscribeClient', <a className="text-default-color item-count-link"> <span className="glyphicon glyphicon-trash" /></a>, 'icon-column no-sort')
    },
    videoCount: {
        get: function(order) {
            return order.get('videoCount', noValueLabel);
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.VIDEO_COUNT.id, <span className="glyphicon glyphicon-facetime-video text-default-color" />, 'icon-column')
    },
    workOrder: {
        get: function(order) {
            if (order.get('id') === null || order.get('id') === undefined) {
                return noValueLabel;
            }
            return `<a href="/packages/${order.get('id')}">${order.get('id')}</a>`;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.PACKAGE_NUMBER.id, 'Package #')
    },
    workOrderStatus: {
        get: function(order) {
            if (order.get('status') === null || order.get('status') === undefined) {
                return noValueLabel;
            }
            return `<div order-status="${order.get('status')}">${WorkOrderConstants.STATUS[order.get('status')]}</div>`;
        },
        header: createHeaders(OrderConstants.ORDER.SORT_TYPE.ORDER_STATUS.id, 'Status')
    },
    workOrderStatusChange: {
        get: function(order) {
            switch (order.get('status')) {
            case WorkOrderConstants.STATUS_BY_NAME.ACTIVE:
            case WorkOrderConstants.STATUS_BY_NAME.COMPLETE:
                return `<a class="text-default-color action-link"><span class="glyphicon glyphicon-cog status-change" data-order-id=${order.get('id')} data-order-status=${order.get('status')}></span></a>`;
            }
            return '';
        },
        header: createHeaders('statusChange', <span className="glyphicon glyphicon-cog" />, 'icon-column no-sort')
    },
};

class ListOrders extends Component {

    static get propTypes() {
        return {
            canDownloadSalesDeck: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
            canViewDigitalSalesDeck: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
            children: PropTypes.object,
            columns: PropTypes.array,
            displayLinks: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
            location: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
            offset: PropTypes.number,
            onPageChange: PropTypes.func,
            onSelect: PropTypes.func,
            onSelectAll: PropTypes.func,
            onSortChange: PropTypes.func,
            orders: PropTypes.instanceOf(Immutable.List).isRequired,
            reloadToCart: PropTypes.number,
            selectAll: PropTypes.bool,
            selectedClients: PropTypes.instanceOf(Immutable.List),
            showSelect: PropTypes.object,
            size: PropTypes.number,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            titles: PropTypes.instanceOf(Immutable.Map), // eslint-disable-line react/no-unused-prop-types
            total: PropTypes.number,
            users: PropTypes.instanceOf(Immutable.Map) // eslint-disable-line react/no-unused-prop-types
        };
    }

    static get defaultProps() {
        return {
            children: undefined,
            columns: [
                'order', 'orderName', 'status', 'requester', 'client',
                'dueDate', 'requestDate', 'actions', 'request'
            ],
            displayLinks: false,
            offset: undefined,
            onPageChange: undefined,
            onSelect: undefined,
            onSelectAll: undefined,
            onSortChange: undefined,
            orders: Immutable.List(),
            reloadToCart: undefined,
            selectAll: false,
            selectedClients: undefined,
            showSelect: undefined,
            size: undefined,
            titles: Immutable.Map(),
            total: 0,
            users: Immutable.Map()
        };
    }

    constructor(props) {
        super(props);

        this.getSortFieldNameClass = this.getSortFieldNameClass.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.handleReloadToCart = this.handleReloadToCart.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleRowTrigger = this.handleRowTrigger.bind(this);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#list-orders-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'actions',
                targets: 'actions'
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false,
            createdRow: function(row) {
                const fulfilledOrder = jQuery(row).find(`[order-status='${OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED}']`);
                const deniedOrder = jQuery(row).find(`[order-status='${OrderConstants.ORDER.STATUS_BY_NAME.DENIED}']`);
                if (fulfilledOrder.length || deniedOrder.length) {
                    jQuery('td', row).css('opacity', '0.45');
                }
            }
        });

        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.handleResize();
        return;
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.$tableAPI.clear();
        // Add data to the jQuery datatable.

        nextProps.orders.forEach((order, index) => {
            let row = this.props.columns.map(c => {
                let column = COLUMNS[c];
                /* istanbul ignore if */
                if (c.name) {
                    column = COLUMNS[c.name];
                }
                // Override the getter if it was provided. This way
                // the module that includes the table can define custom
                // functions to read the values.
                /* istanbul ignore if */
                if (c.get) {
                    column.get = c.get;
                }
                return column.get(order, nextProps, this.context.intl);
            });

            // If requested, show the select in the first column.
            /* istanbul ignore if */
            if (nextProps.onSelectAll && nextProps.onSelect) {
                let checkedValue = '';
                if (order.get('__selected')) {
                    checkedValue = 'checked';
                }
                row.unshift(`<input type="checkbox" class="select-row" data-index="${index}" ${checkedValue}/>`);
            }
            // Add a last empty column for the datatable-responsive plugin.
            row.push('');

            this.$tableAPI.row.add(row);
            return;
        });

        this.$tableAPI.draw(false);

        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();

        return;
    }

    componentWillUnmount() {
        /* istanbul ignore else */
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    static contextType = MessagesContext;

    getSortFieldNameClass(headerName, className) {
        if (className && className.indexOf('no-sort') !== -1) {
            return;
        }
        let r = 'sorting';
        /* istanbul ignore if */
        if (OrderConstants.ORDER.SORT_TYPE[headerName].name === this.props.sortFieldName) {
            r = `sorting_${this.props.sortDirection}`;
        }
        return r;
    }

    /* istanbul ignore next */
    handleHeaderClick(headerName) {
        if (headerName === 'unsubscribeClient') {
            if (this.props.selectedClients.size) {
                OrderActions.unsubscribeClientsShow();
            }
            return;
        }
        let newSortDirection = 'asc';
        if (this.props.sortFieldName === OrderConstants.ORDER.SORT_TYPE[headerName].name &&
            this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }
        if (this.props.onSortChange) {
            this.props.onSortChange(OrderConstants.ORDER.SORT_TYPE[headerName].name, newSortDirection);
        }
        return;
    }

    handleReloadToCart() {
        OrderActions.doReloadToCart(this.props.reloadToCart);
        return;
    }

    /**
     * Update the datatable columns size.
     */
    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowTrigger(event) {
        /* istanbul ignore if */
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        let orderId;
        switch (true) {
        // Handle click on a select input.
        case !!~event.target.className.indexOf('select-row'):
            // Prevent the default anchor click event.
            event.preventDefault();
            const index = parseInt(event.target.getAttribute('data-index'), 10);
            this.props.onSelect(index, event.target.checked);
            break;
        case !!~event.target.className.indexOf('reload-to-cart'):
            // Prevent the default anchor click event.
            event.preventDefault();
            orderId = parseInt(event.target.getAttribute('data-order-id'), 10);
            OrderActions.reloadToCart(orderId);
            break;
        // Handle click on a unsubscribe client icon.
        case !!~event.target.className.indexOf('unsubscribe-client'):
            // Prevent the default anchor click event.
            event.preventDefault();
            let contentType = parseInt(event.target.getAttribute('contentType'), 10);
            let title = parseInt(event.target.getAttribute('titleId'), 10);
            let user = parseInt(event.target.getAttribute('userId'), 10);
            OrderActions.unsubscribeClient(contentType, title, user);
            break;
        // Handle click on a status-change icon.
        case !!~event.target.className.indexOf('status-change'):
            // Prevent the default anchor click event.
            event.preventDefault();
            orderId = parseInt(event.target.getAttribute('data-order-id'), 10);

            OrderActions.statusChangeShow(orderId);
            break;
        //handle click expand row
        /* istanbul ignore next */
        case !!~event.target.className.indexOf(' control'):
            let cellsData = event.target.parentElement;
            let fulfilledOrder = jQuery(cellsData).find(`[order-status='${OrderConstants.ORDER.STATUS_BY_NAME.FULFILLED}']`);
            if (fulfilledOrder.length) {
                let orderDataId = jQuery(cellsData).find('[data-order-id]').attr('data-order-id');
                let rowChild = jQuery(cellsData.parentElement).find(`[data-order-id='${orderDataId}']`);
                if (rowChild.length > 1) {
                    rowChild[1].closest('ul').style.opacity = 0.45;
                }
            }
            break;
        // Handle click on download in metadata tab
        case !!~event.target.className.indexOf('download-as-json'):
        case !!~event.target.className.indexOf('download-work-order'):
            orderId = parseInt(event.target.getAttribute('data-order-id'), 10);
            DownloadActions.startDownloadExecution(`work-order/web/${orderId}/export-master-meta-data`, {
                'export-type': event.target.getAttribute('data-order-export-type')
            });
            break;

        // Handle click on download-sales-deck in metadata tab
        case !!~event.target.className.indexOf('download-sales-deck'):
            orderId = parseInt(event.target.getAttribute('data-order-id'), 10);
            WorkOrderActions.startDownload(orderId);
            break;

        // Handle click on view-digital-sales-deck in metadata tab
        case !!~event.target.className.indexOf('view-digital-sales-deck'):
            const guid = event.target.getAttribute('data-order-guid');
            WorkOrderActions.viewDigitalSalesDeck(guid);
            break;

        // Handle click on download in my-orders and my-saved-carts tabs
        case !!~event.target.className.indexOf('download-approved'):
            let renditionType = event.target.getAttribute('data-order-renditionType');
            orderId = parseInt(event.target.getAttribute('data-order-id'), 10);
            OrderActions.startDownload(
                false,
                orderId,
                renditionType,
                null,
                `orders/${orderId}/download`
            );
            break;
        }
        return;
    }

    handleSelectAllClick(event) {
        this.props.onSelectAll(event.target.checked);
        return;
    }

    render() {
        let pagination;
        if (this.props.onPageChange) {
            pagination = (
                <Row>
                    <Col md={12} className="text-center">
                        <Pagination
                            className="margin-top-10"
                            offset={this.props.offset}
                            onChange={this.props.onPageChange}
                            size={this.props.size}
                            total={this.props.total}
                        />
                    </Col>
                </Row>
            );
        }

        let showSelect;
        if (this.props.showSelect && this.props.onSelect) {
            showSelect = <th className="no-sort" scope="col"><input type="checkbox" onChange={this.handleSelectAllClick} checked={this.props.selectAll}/></th>;
        }

        return (
            <div>
                <Modal show={!!this.props.reloadToCart} onHide={OrderActions.cancelReloadToCart}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="modal-title text-center">{this.context.intl.messages['orders.tab.my-saved-carts.confirm-reload-to-cart.dialog.title']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.context.intl.messages['orders.tab.my-saved-carts.confirm-reload-to-cart.dialog.description']}</p>
                    </Modal.Body>
                    <Modal.Footer className=" padding-all-20">
                        <Button className="btn-primary pull-right" onClick={this.handleReloadToCart}>{this.context.intl.messages['common.ok']}</Button>
                        <Button className="btn-default btn-secondary margin-right-5 pull-left" onClick={OrderActions.cancelReloadToCart}>{this.context.intl.messages['common.close']}</Button>
                    </Modal.Footer>
                </Modal>
                {this.props.children}
                <table id="list-orders-table"
                    className="table table-striped datatable-responsive dataTable no-footer dtr-column collapsed">
                    <thead>
                        <tr>
                            {showSelect}
                            {this.props.columns.map(c => {
                                let column = COLUMNS[c];
                                /* istanbul ignore if */
                                if (c.name) {
                                    column = COLUMNS[c.name];
                                }
                                return column.header(this);
                            })}
                            <th scope="col" className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowTrigger} onKeyUp={this.handleRowTrigger} />
                </table>
                {pagination}
            </div>
        );
    }

}

export default ListOrders;
