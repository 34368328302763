/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import ImageLoader from './image-loader';

export default class FifoImageLoader extends React.PureComponent {
    static get propTypes() {
        return {
            alt: PropTypes.string,
            placeholder: PropTypes.string,
            sources: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get defaultProps() {
        return {
            alt: '',
            placeholder: '',
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            index: 0
        };

        this.handleErrorEvent = this.handleErrorEvent.bind(this);
    }

    handleErrorEvent() {
        this.setState(state => ({
            index: state.index + 1
        }));
    }

    render() {
        const {index} = this.state;
        const {sources, ...props} = this.props;

        if (this.state.index < this.props.sources.size - 1) {
            props.onError= this.handleErrorEvent;
        }

        return <ImageLoader {...props} src={sources.get(index)} />;
    }
}
