/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {AssetTypeConstants} from '../../../asset-types/asset-type-constants';
import ClipGridItem from '../../../common/clips/clip-grid-item';
import {MessagesContext} from '../../../messages/messages-context';
import {IsNotTriggerKey} from '../../../utils/utils';
import {ClipActions} from '../../clip/clip-actions';

export default class ClipThumbnail extends React.PureComponent {
    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool,
            clip: PropTypes.instanceOf(Immutable.Map),
            titleId: PropTypes.number.isRequired,
        };
    }

    static get defaultProps() {
        return {
            canAddToCart: false,
            clip: null,
        };
    }

    constructor(props) {
        super(props);

        this.handlePlay = this.handlePlay.bind(this);
    }

    static contextType = MessagesContext;

    handlePlay(ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }
        ClipActions.playAutoClip(this.props.clip, this.props.titleId);
    }

    render() {
        const {canAddToCart, clip, titleId} = this.props;
        if (!clip) {
            return null;
        }
        const {contentType} = clip.toJS();

        return (
            <ClipGridItem
                clip={clip}
                titleId={titleId}
                onPlay={this.handlePlay}
                canAddToCart={canAddToCart}
                thumbnailsUrl={clip.get('thumbnailsUrl')}
                ariaLabel={
                    this.context.intl.messages['title.player.watch-moment']
                }
                clipTitle={
                    <span data-testid="video-content-type">
                        {
                            (
                                AssetTypeConstants.VIDEO_CONTENT_TYPE[
                                    contentType
                                ] || {description: ''}
                            ).description
                        }
                    </span>
                }
            />
        );
    }
}
