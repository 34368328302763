/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {FormattedMessage} from 'react-intl';

import Brands from './brands';
import UserPartnersList from './user-partners-list';
import {LogIn, Key} from '../icons/all-icons';

import '../index.css';

export default function MultiBrandHub() {
    return (<div className="general-container h-full w-full bg-black-background-back p-10">
        <div className="flex flex-row h-28 justify-center items-center">
            <div className="flex flex-row h-24 w-24 mt-10 justify-center items-center rounded-full bg-black-background-solid">
                <LogIn classes="h-14 w-14 fill-gray-stroke" />
            </div>
        </div>

        <div className="flex flex-row h-10 mt-5 justify-center items-center">
            <h1 className="text-white text-3xl font-default">
                <FormattedMessage
                    defaultMessage="Continue To Brand"
                    description="Header of select brand section."
                    id="multi.brand.select.brand.header"
                />
            </h1>
        </div>

        <div className="flex flex-row h-10 justify-center items-center">
            <h4 className="text-white text-sm font-default">
                <FormattedMessage
                    defaultMessage="Select a brand portal to continue:"
                    description="Description of header of select brand section."
                    id="multi.brand.select.brand.description"
                />
            </h4>
        </div>

        <div className="flex flex-row h-30 justify-center items-center">
            <UserPartnersList/>
        </div>

        <div className="flex flex-row justify-center items-center">
            <div className="flex basis-11/12 flex-row justify-center items-center border-t border-white" />
        </div>
        <br/>

        <div className="flex flex-row h-28 justify-center items-center">
            <div className="flex flex-row h-24 w-24 mt-10 justify-center items-center rounded-full bg-black-background-solid">
                <Key classes="h-14 w-14 fill-gray-stroke" />
            </div>
        </div>

        <div className="flex flex-row h-10 mt-5 justify-center items-center">
            <h1 className="text-white text-3xl font-default">
                <FormattedMessage
                    defaultMessage="Request Access"
                    description="Header of request access section."
                    id="multi.brand.request.access.header"
                />
            </h1>
        </div>

        <div className="flex flex-row h-10 justify-center items-center">
            <h4 className="text-white text-sm font-default">
                <FormattedMessage
                    defaultMessage="Not seeing the brand you need above? Request access by selecting a brand below:"
                    description="Description of header of request access section."
                    id="multi.brand.request.access.description"
                />
            </h4>
        </div>

        <div className="flex flex-row h-30 justify-center items-center">
            <Brands />
        </div>
    </div>
    );
}

