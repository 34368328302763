/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';

import {MessagesContext} from '../../../messages/messages-context';
import {TitleConstants} from '../../../titles/title-actions';
import {FormatReleaseDate} from '../../../utils/utils';
import {TalentConstants} from '../../talent-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/app/dataTables.brainiac.css');
require('../../../styles/app/responsive.brainiac.css');

require('datatables.net-responsive-bs');

class TitleTable extends React.Component {
    static get propTypes() {
        return {
            roleName: PropTypes.string.isRequired,
            tableId: PropTypes.number.isRequired,
            titles: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.initDT = this.initDT.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.initDT(this.props);
        return;
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.titles !== this.props.titles) {
            return true;
        }
        return false;
    }

    componentDidUpdate() {
        this.initDT(this.props);
        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    static contextType = MessagesContext;

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    initDT(props) {
        if (!props.titles || props.titles.size === 0 ) {
            return;
        }
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        this.$table = jQuery('#title-table-'+props.tableId);
        let dataTableConfig = {
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }],
            info: false,
            ordering: true,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        };
        this.$tableAPI = this.$table.DataTable(dataTableConfig);
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);
        this.loadData(props);
        // And trigger the resize handler once so that the datatable
        // knows its initial dimensions.
        this.handleResize();
    }

    formatDate(title) {
        if (!title) {
            return '-';
        }
        return FormatReleaseDate(title.get('titleReleaseDate'), title.get('titleReleaseDateType'));
    }

    formatFullEpisodeName(title) {
        let displayName = title.get('name');
        switch (title.get('categoryGroupMap')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            displayName = title.get('name') + ' ' + (title.get('fullRunningOrder') || 'Season');
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            displayName='';
            if (title.get('parentTitleDisplayName')) {
                displayName = title.get('parentTitleDisplayName')+ ' ';
            }
            if (title.get('fullRunningOrder')) {
                displayName += title.get('fullRunningOrder') + ' - ';
            }
            displayName += title.get('name') || '';
            break;
        }
        return displayName;
    }

    loadData(props) {
        this.$tableAPI.clear();
        let titles = props.titles.map (t => {
            t = t.set('displayName', this.formatFullEpisodeName(t));
            return t;
        });

        titles.forEach(title => {
            let row = [
                `<a href="/titles/${title.get('titleId')}">${title.get('displayName')}</a>`,
                title.get('characterNameInTitle'),
                this.formatDate(title),
                ''
            ];
            this.$tableAPI.row.add(row);
        });
        this.$tableAPI.draw(false);
    }

    render() {
        if (!this.props.titles || this.props.titles.size === 0) {
            return null;
        }
        let roleTitle = this.context.intl.messages['talent.credits.role.character-name-comment'];
        if (this.props.tableId === TalentConstants.ROLE.ACTOR) {
            roleTitle = this.context.intl.messages['talent.credits.role.character-name'];
        }
        return (
            <tr>
                <td className="cart-parent-title">
                    <strong>{this.props.roleName}</strong>
                </td>
                <td className="cart-parent-assets">
                    <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                        <table className="table table-striped datatable-responsive dataTable no-footer dtr-column collapsed" id={`title-table-${this.props.tableId}`}>
                            <thead>
                                <tr role="row">
                                    <th scope="col" rowSpan="1" colSpan="1">{this.context.intl.messages['talent.credits.role.title']}</th>
                                    <th scope="col" rowSpan="1" colSpan="1" width="250px">{roleTitle}</th>
                                    <th scope="col" rowSpan="1" colSpan="1" width="130px">{this.context.intl.messages['talent.credits.role.release-date']}</th>
                                    <th scope="col" className="no-sort control icon-column" rowSpan="1" colSpan="1" ></th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        );
    }
}

export default class Credits extends React.Component {
    static get propTypes() {
        return {
            titles: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static contextType = MessagesContext;

    render() {
        let categorized = this.props.titles.reduce( (c, t) => {
            let role = t.get('roleInTitle');
            let list = c.get(role) || Immutable.List();
            return c.set(role, list.push(t));
        }, Immutable.Map());
        return (
            <div className="container padding-x-10">
                <hr/>
                <h3>{this.context.intl.messages['talent.credits.title']}</h3>
                <table className="table cart-parent-table">
                    <thead>
                        <tr>
                            <th scope="col" className="cart-title-column">{this.context.intl.messages['talent.credits.role']}</th>
                            <th scope="col">{this.context.intl.messages['talent.credits.info']}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TitleTable tableId={TalentConstants.ROLE.ACTOR} titles={categorized.get(TalentConstants.ROLE.ACTOR)} roleName={this.context.intl.messages['talent.credits.role.actor']} />
                        <TitleTable tableId={TalentConstants.ROLE.DIRECTOR} titles={categorized.get(TalentConstants.ROLE.DIRECTOR)} roleName={this.context.intl.messages['talent.credits.role.director']} />
                        <TitleTable tableId={TalentConstants.ROLE.PRODUCER} titles={categorized.get(TalentConstants.ROLE.PRODUCER)} roleName={this.context.intl.messages['talent.credits.role.producer']} />
                        <TitleTable tableId={TalentConstants.ROLE.VOICE_TALENT} titles={categorized.get(TalentConstants.ROLE.VOICE_TALENT)} roleName={this.context.intl.messages['talent.credits.role.voice-talent']} />
                        <TitleTable tableId={TalentConstants.ROLE.HOST} titles={categorized.get(TalentConstants.ROLE.HOST)} roleName={this.context.intl.messages['talent.credits.role.host']} />
                        <TitleTable tableId={TalentConstants.ROLE.WRITER} titles={categorized.get(TalentConstants.ROLE.WRITER)} roleName={this.context.intl.messages['talent.credits.role.writer']} />
                        <TitleTable tableId={TalentConstants.ROLE.MUSICIAN} titles={categorized.get(TalentConstants.ROLE.MUSICIAN)} roleName={this.context.intl.messages['talent.credits.role.musician']} />
                        <TitleTable tableId={TalentConstants.ROLE.CHOREOGRAPHER} titles={categorized.get(TalentConstants.ROLE.CHOREOGRAPHER)} roleName={this.context.intl.messages['talent.credits.role.choreographer']} />
                        <TitleTable tableId={TalentConstants.ROLE.EXECUTIVE_PRODUCER} titles={categorized.get(TalentConstants.ROLE.EXECUTIVE_PRODUCER)} roleName={this.context.intl.messages['talent.credits.role.executive-producer']} />
                        <TitleTable tableId={TalentConstants.ROLE.GUEST_STAR} titles={categorized.get(TalentConstants.ROLE.GUEST_STAR)} roleName={this.context.intl.messages['talent.credits.role.guest-star']} />
                        <TitleTable tableId={TalentConstants.ROLE.AUTHOR} titles={categorized.get(TalentConstants.ROLE.AUTHOR)} roleName={this.context.intl.messages['talent.credits.role.author']} />
                        <TitleTable tableId={TalentConstants.ROLE.ANIMATED_CHARACTER} titles={categorized.get(TalentConstants.ROLE.ANIMATED_CHARACTER)} roleName={this.context.intl.messages['talent.credits.role.animated-character']} />
                        <TitleTable tableId={TalentConstants.ROLE.MISC_CREW} titles={categorized.get(TalentConstants.ROLE.MISC_CREW)} roleName={this.context.intl.messages['talent.credits.role.misc-crew']} />
                        <TitleTable tableId={TalentConstants.ROLE.CREATOR} titles={categorized.get(TalentConstants.ROLE.CREATOR)} roleName={this.context.intl.messages['talent.credits.role.creator']} />
                    </tbody>
                </table>
            </div>
        );
    }
}

export {Credits, TitleTable};
