/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';

import {SearchFacetsActions} from './facets/search-facets-actions';
import {SearchActions, SearchConstants} from './search-actions';
import SearchFilters from './search-filters';
import SearchSection from './search-section';
import SearchStore from './search-store';
import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import DocumentTitle from '../common/document-title';
import Config from '../config/config';
import WithPermissions from '../decorators/with-permissions';
import Billboard from '../home/sections/billboard';
import HeaderStore from '../layout/header/header-store';
import {MessagesContext} from '../messages/messages-context';
import Pagination from '../pagination';
import PlayerStore from '../player/player-store';
import VideoOverlay from '../player/video-overlay';
import {RouterActions} from '../router/router-actions';
import SessionStore from '../session/session-store';
import {AssetTitleActions} from '../titles/asset-title/asset-title-actions';
import AssetTitleStore from '../titles/asset-title/asset-title-store';
import ClipStore from '../titles/clip/clip-store';
import Share from '../titles/share';
import {Debounce, IsNotTriggerKey} from '../utils/utils';

const CONSTANTS = {
    DOCUMENT_TITLE_MAP: {
        'animation': 'document-titles.search.hubs.animation',
        'doc-films': 'document-titles.search.hubs.doc-films',
        'documentary': 'document-titles.search.hubs.documentary',
        'entertainment': 'document-titles.search.hubs.entertainment',
        'factual': 'document-titles.search.hubs.factual',
        'film': 'document-titles.search.hubs.film',
        'kids': 'document-titles.search.hubs.kids',
        'limited-series': 'document-titles.search.hubs.limited-series',
        'movies': 'document-titles.search.hubs.movies',
        'reality': 'document-titles.search.hubs.reality',
        'scripted': 'document-titles.search.hubs.scripted',
        'search': 'document-titles.search',
        'tv': 'document-titles.search.hubs.tv',
    },
    // Section configuration
    SECTIONS: [
        {type: 'titles', request: SearchConstants.SEARCH.TITLES, permissions: () => true},
        {type: 'moments', request: SearchConstants.SEARCH.CLIPS, permissions: (perms) => perms.canAddToCart && perms.canCreateClip && !SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle()},
        {type: 'images', request: SearchConstants.SEARCH.IMAGES, permissions: () => !SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle()},
        {type: 'talent', request: SearchConstants.SEARCH.TALENT, permissions: () => true},
        {type: 'videos', request: SearchConstants.SEARCH.VIDEOS, permissions: () => true},
        {type: 'episodes', request: SearchConstants.SEARCH.EPISODES, permissions: () => true},
        {type: 'documents', request: SearchConstants.SEARCH.DOCUMENTS, permissions: () => !SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle()},
    ]
};

export const SearchPageConstants = CONSTANTS;
export class SearchPage extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            pageType: PropTypes.string.isRequired,
            pageTypePredicate: PropTypes.func.isRequired,
            searchType: PropTypes.string.isRequired,
        };
    }

    static getPermissions() {
        return {
            canAddToCart: SessionStore.canUser(SessionStore.PERMISSIONS.CART.VIDEOS.ADD),
            canCreateClip: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.CLIPS.CREATE),
            canDownloadAsSpreadsheet: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.DOWNLOAD_AS_SPREADSHEET),
            canIncludeTitleApiUrl: SessionStore.canUser(SessionStore.PERMISSIONS.SEARCH.INCLUDE_API_URL)
        };
    }

    static calculateState() {
        return {
            activePageLabel: SearchStore.getState().get('activePageLabel'),
            currentClip: ClipStore.getState().get('currentClip'),
            displayMode: SearchStore.getState().get('displayMode'),
            documents: SearchStore.getState().get('documents'),
            episodes: SearchStore.getState().get('episodes'),
            headers: HeaderStore.getState().get('navigationItems'),
            images: SearchStore.getState().get('images'),
            isCreateClipModalVisible: ClipStore.getState().get('isModalVisible'),
            moments: SearchStore.getState().get('clips'),
            partner: SessionStore.getState().get('partner'),
            publishingList: SearchStore.getState().get('publishingList'),
            showOverlay: PlayerStore.getState().get('showOverlay'),
            showShare: AssetTitleStore.getState().get('showShare'),
            talent: SearchStore.getState().get('talent'),
            thumbnails: SearchStore.getState().get('thumbnails'),
            titles: SearchStore.getState().get('titles'),
            video: PlayerStore.getState().get('video'),
            videos: SearchStore.getState().get('videos'),
            watchlist: PlayerStore.getState().get('watchlist')
        };
    }

    static getStores() {
        return [AssetTitleStore, PlayerStore, SearchStore, ClipStore, HeaderStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleCloseShare = this.handleCloseShare.bind(this);
        this.getDisplayModeButtons = this.getDisplayModeButtons.bind(this);
        this.getFiltersString = this.getFiltersString.bind(this);
        this.getPaginationBottom = this.getPaginationBottom.bind(this);
        this.getPaginationTop = this.getPaginationTop.bind(this);
        this.handleDisplayModeChange = this.handleDisplayModeChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.search = Debounce(this.search.bind(this), 400);
        this.getFacets = this.getFacets.bind(this);

        return;
    }

    componentDidMount() {
        SearchActions.clear();

        const sections = this.getFilteredSections('request');
        if (!sections.length) {
            // it should bw only if user doesn't have permissions for working with current page
            RouterActions.redirect('/search');
            return;
        }
        const query = QueryString.parse(this.props.location.search);
        this.search(query);
        SearchActions.getHubPublishList(this.props.pageType);
        return;
    }

    componentWillReceiveProps(nextProps) {
        // Fix inconsistent location.pathnames
        let propsPath = this.props.location.pathname;
        let re = new RegExp('^/');
        if (!re.test(propsPath)) {propsPath = '/' + propsPath;}
        let nextPath = nextProps.location.pathname;
        if (!re.test(nextPath)) {nextPath = '/' + nextPath;}

        if (propsPath !== nextPath) {
            SearchActions.clear();

            // Preserve the displayMode when paging through View All, but not when navigating back to /search
            SearchActions.setDisplayMode(SearchConstants.DISPLAY_MODES.GRID);

            SearchActions.getHubPublishList(this.props.pageType);
        }

        // FIXME: better compare!
        const nextQuery = QueryString.parse(nextProps.location.search);
        if (this.props.location.search !== nextProps.location.search) {
            this.search(nextQuery);
        }

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Fix inconsistent location.pathnames
        let propsPath = this.props.location.pathname;
        let re = new RegExp('^/');
        if (!re.test(propsPath)) {propsPath = '/' + propsPath;}
        let nextPath = nextProps.location.pathname;
        if (!re.test(nextPath)) {nextPath = '/' + nextPath;}

        if (
            propsPath !== nextPath ||
            this.props.location.search !== nextProps.location.search
        ) {
            return true;
        }

        if (
            this.state.activePageLabel !== nextState.activePageLabel ||
            this.state.moments !== nextState.moments ||
            this.state.documents !== nextState.documents ||
            this.state.episodes !== nextState.episodes ||
            this.state.images !== nextState.images ||
            this.state.publishingList !== nextState.publishingList ||
            this.state.talent !== nextState.talent ||
            this.state.thumbnails !== nextState.thumbnails ||
            this.state.titles !== nextState.titles ||
            this.state.videos !== nextState.videos ||
            this.state.displayMode !== nextState.displayMode ||

            this.state.showOverlay !== nextState.showOverlay ||
            this.state.showShare !== nextState.showShare ||
            this.state.video !== nextState.video
        ) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    getFacets(query) {
        SearchFacetsActions.getFacets(query?.q ?? '');
    }

    handleCloseShare() {
        AssetTitleActions.hideShare();
    }

    /**
    * Build the display mode toggle buttons (grid view, list view)
    */
    getDisplayModeButtons() {
        let displayModeButtons;

        let buttonClasses = 'display-type-switch glyphicon glyphicon';
        let buttonTypes = [
            {mode: SearchConstants.DISPLAY_MODES.GRID, classes: 'glyphicon-th margin-x-5', ariaLabel: 'common.grid-view'},
            {mode: SearchConstants.DISPLAY_MODES.LIST, classes: 'glyphicon-align-justify', ariaLabel: 'common.list-view'},
        ];

        displayModeButtons = (
            <span>
                {this.context.intl.messages['hub.search.display.title']}:
                {buttonTypes.map( (buttonType, i) => {
                    let spanClass = '';
                    if (this.state.displayMode === buttonType.mode) {
                        spanClass = 'active';
                    }

                    return (
                        <span
                            aria-label={this.context.intl.messages[buttonType.ariaLabel]}
                            className={`${buttonClasses} ${buttonType.classes} ${spanClass}`}
                            key={i}
                            onClick={()=>this.handleDisplayModeChange(buttonType.mode)}
                            onKeyUp={()=>this.handleDisplayModeChange(buttonType.mode)}
                            role="button"
                            tabIndex="0"
                        />
                    );
                })}

                <hr className="visible-xs-block"/>
                <span className="vertical-divider hidden-xs"/>
            </span>);

        return displayModeButtons;
    }

    /**
     * get section that should be rendered
     * @param prop {'type' | 'request'} which type of info we should to get
     * @return {*[]}
     */
    getFilteredSections(prop) {
        return CONSTANTS.SECTIONS.filter((section) => {
            const hasPermissions = section.permissions(this.props.permissions);
            return hasPermissions && this.props.pageTypePredicate(section);
        }).map((section) => section[prop]); // it should work faster than `reduce`, and looks better
    }

    // The filters string summarizes all the applied filters.
    // The following code translates the query string into a human readable text.
    getFiltersString(query) {
        const queryKeys = Object.keys(query);

        if (queryKeys.some(f => ['talent', 'character', 'talent-character'].includes(f)) &&
            !queryKeys.includes('talent-character')
        ) {
            queryKeys.push('talent-character');
        }

        return queryKeys.filter(
            // First, filter all the query params we do not want to show.
            f => [
                'content-super-type',
                'dialogue-operator',
                'genre-operator',
                'keep-scroll',
                'offset',
                'q',
                'setting-operator',
                'size',
                'sort-direction',
                'sort-field-name',
                'title-api-url',
                'talent',
                'character',
            ].indexOf(f) === -1
        ).map(f => {
            // Second, for each filter create an object with the filter name,
            // the label and the filter value.
            let commonName = f.replace(/^(end|start)-/, '');
            let label = this.context.intl.messages[`search.filters.navbar.titles.${commonName}`];

            const searchQuery = QueryString.parse(this.props.location.search);
            let value = searchQuery[f];
            if (f === 'talent-character') {
                if (value && !Array.isArray(value)) {
                    value = [value];
                }
                if (searchQuery['talent-character']) {
                    value = value.map(item => item.split('|').map((part, index) => {
                        if (index === 0) {
                            return [part];
                        }

                        return `<i>${part}</i>`;
                    }).join('|'));
                }
                value = ['talent', 'character'].reduce((acc, filterName) => {
                    let queryValue = searchQuery[filterName];
                    if (!queryValue) {
                        return acc;
                    }
                    if (!Array.isArray(queryValue)) {
                        queryValue = [queryValue];
                    }

                    if (filterName === 'character') {
                        queryValue = queryValue.map(item => `<i>${item}</i>`);
                    }

                    return ([
                        ...acc,
                        ...queryValue,
                    ]);
                }, value || []);
            }
            if (Array.isArray(value)) {
                // If value is an array (this happens with multi-selects), just join
                // all the values.
                value = value.join(', ');
            }
            // If value is the string "true", then the filter is a checkbox.
            if (value === 'true') {
                // Instead of true, use the i18n file to get the right label.
                label = this.context.intl.messages[`search.filters.navbar.titles.${commonName}-checkbox`];
                value = this.context.intl.messages[`search.filters.navbar.titles.${commonName}.value`];
            }

            if (f === 'category') {
                // as category can be an array it will be a comma splited sequence
                // join it transform it and split it again (pure keynesianism)
                value = value.split(', ').map( v => this.context.intl.messages[`search.filters.category.${v}`]).join(', ');
            }

            if (f === 'time-frame') {
                value = this.context.intl.messages[`search.filters.time-frame.${value}`];
            }

            return {
                filterName: f,
                label,
                // Creating an array here is important for the next step.
                value: [value]
            };
        }).reduce((r, f) => {
            // Now group all filters by label. Here we group together all range filters
            // like created date, release date and box office.

            // Start by looking for the filter in the grouped array.
            let index;
            r.some((f2, i) => {
                if (f2.label === f.label) {
                    index = i;
                    return true;
                }

                return false;
            });

            // If found...
            if (index !== undefined) {
                // ...use the filter name to know if the value must be preprended or
                // appended.
                if (f.filterName.match('start-')) {
                    r[index].value.unshift(...f.value);
                } else {
                    r[index].value.push(...f.value);
                }
            } else {
                r.push(f);
            }

            return r;
        }, []).map(
            // Finally, transform all filters to strings.
            f => `<b>${f.label}</b>: ${f.value.join(' - ')}`
        ).join(' | ');
    }

    getPaginationBottom(type, offset, size) {
        return (
            <div>
                <hr />
                <div className="text-center margin-bottom-40">
                    <Pagination
                        offset={offset}
                        onChange={this.handlePageChange}
                        size={size}
                        total={this.state[type].get('totalCount')}
                    />
                </div>
            </div>
        );
    }

    getPaginationTop(type, offset, size) {
        let displayModeButtons = this.getDisplayModeButtons();

        return (
            <div className="pull-right-sm-up margin-top-10 grid-options">
                {displayModeButtons}
                <Pagination
                    className="pull-right-sm-up margin-top-5"
                    offset={offset}
                    onChange={this.handlePageChange}
                    size={size}
                    total={this.state[type].get('totalCount')}
                />
            </div>
        );
    }

    /**
    * Change the display format (grid|list)
    */
    handleDisplayModeChange(mode) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        SearchActions.setDisplayMode(mode);
    }

    handlePageChange(offset) {
        const q = QueryString.parse(this.props.location.search);
        let size = q.size || 40;

        let query = Object.assign(
            {},
            q,
            {
                size,
                offset
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    search(criteria) {
        const sections = this.getFilteredSections('request');

        SearchActions.search(sections, criteria, this.props.pageType, null, this.state.partner.get('id'));

        if (this.props.searchType === 'moments') {
            this.getFacets(criteria);
        }
        return;
    }

    render() {
        let billboard;
        let paginationBottom;
        let paginationTop;
        let searchTerm;

        const query = QueryString.parse(this.props.location.search);
        let offset = query.offset || 0;
        let size = parseInt(query.size) || 40;
        let total;
        // Default search page shows all sections.
        let sections = this.getFilteredSections('type');
        if (this.props.pageType !== 'search') {
            billboard = <Billboard publishingList={this.state.publishingList}/>;
        } else {
            searchTerm = <strong>&nbsp;{query.q}</strong>;
            // Important! This works because path is one of: titles, images, videos, talent,
            // episodes or documents.
            // By using the same names in both the route path and the sections, we allow the View All
            // links to work right away.
            if (this.props.searchType) {
                if (this.state[this.props.searchType].get('totalCount') === 0) {
                    offset = 0;
                }
                const totalCount = this.state[this.props.searchType].get('totalCount');
                const messageId = `hub.search.${this.props.searchType}.count.label`;
                total = (
                    <small>
                        &nbsp;
                        <FormattedMessage id={messageId} values={{totalCount}} />
                    </small>
                );
            }
        }

        if (sections.length === 1) {
            const [section] = sections;
            paginationBottom = this.getPaginationBottom(section, offset, size);
            paginationTop = this.getPaginationTop(section, offset, size);
        }

        let filters = this.getFiltersString(query);
        if (!filters) {
            // Just an empty space so that the layout doesn't move when no filters are applied.
            filters = '<span>&nbsp;</span>';
        }

        let searchFilters;
        let isPressPartner = false;

        switch (this.state.partner.get('id')) {
        case Config.Partners.ADULTSWIM_PRESS.id:
            isPressPartner = true;
            break;
        }

        if (!isPressPartner) {
            searchFilters = (
                <SearchFilters
                    hub={this.props.pageType}
                    location={this.props.location}
                    section={this.props.searchType}
                    partner={this.state.partner.get('id')}
                    permissions={this.props.permissions}
                />);
        }

        let videoOverlayVideo = undefined;
        if (this.state.video.size) {
            videoOverlayVideo = this.state.video;
        }

        let pageType = this.props.pageType;
        if (this.state.partner.get('id') === Config.Partners.HBO_MAX_PORTAL.id) {
            switch (this.props.pageType) {
            case 'documentary':
                pageType = 'doc-films';
                break;
            case 'animation':
                pageType = 'kids';
                break;
            default:
                break;
            }
        }

        let resultsText = `hub.${pageType}.title`;
        if (pageType === 'search' && this.props.searchType) {
            resultsText = 'hub.search.view-all.title';
        }

        let headerContent = <div>
            <b>{this.context.intl.messages[`hub.search.${this.props.searchType}.prefix`]}</b>
            {this.context.intl.messages[resultsText]}{searchTerm}{total}
        </div>;

        let headerFilters = <div dangerouslySetInnerHTML={{__html: filters}}></div>;

        const hubItem = this.state.headers.find((val) => {
            return val.get('targetUrl') === this.props.location.pathname + this.props.location.search;
        });

        if (hubItem && this.props.location.pathname.startsWith('/search')) {
            const hubItemDisplayName = hubItem.get('displayName');
            headerContent = <b>{hubItemDisplayName}</b>;
            headerFilters = null;
        }

        const headerWrapper = <div>
            <div className="clearfix">
                <h2 className="pull-left visible-block-sm-up margin-top-10 h1">
                    <span className="search-term"/>
                    {headerContent}
                </h2>
                {paginationTop}
            </div>

            {headerFilters}
        </div>;

        return (
            <DocumentTitle
                message={CONSTANTS.DOCUMENT_TITLE_MAP[pageType]}
            >
                <div className="page-content">
                    {billboard}
                    {searchFilters}
                    <div className="container padding-top-20">
                        <div className="padding-x-15">
                            {headerWrapper}
                            <hr/>

                            {sections.map((s) => {
                                let searchQuery = this.state[s].get('downloadUrlQuery');

                                if (searchQuery !== undefined) {
                                    searchQuery = searchQuery.toJS();
                                    searchQuery = Object.keys(searchQuery).reduce((q, qName) => {
                                        let qValue = searchQuery[qName];
                                        if (qValue !== undefined) {
                                            q[qName] = qValue;
                                        }
                                        return q;
                                    }, {});
                                }

                                let downloadSearchUrl = `${this.state[s].get('downloadUrlBase')}?${QueryString.stringify(searchQuery)}`;
                                return (
                                    <SearchSection
                                        assets={this.state[s]}
                                        canAddClipToCart={this.props.permissions.canAddToCart && this.props.permissions.canCreateClip}
                                        canDownloadAsSpreadsheet = {this.props.permissions.canDownloadAsSpreadsheet}
                                        currentClip={this.state.currentClip}
                                        downloadSearchUrl={downloadSearchUrl}
                                        hub={this.props.pageType}
                                        isCreateClipModalVisible={this.state.isCreateClipModalVisible && !this.state.showOverlay}
                                        key={s}
                                        location={this.props.location}
                                        showTitle={sections.length > 1}
                                        thumbnails={this.state.thumbnails}
                                        title={s}
                                        displayMode={this.state.displayMode}
                                    />
                                );
                            })}

                            {paginationBottom}
                        </div>
                    </div>

                    <Share
                        asset={this.state.video}
                        assetType={AssetTypeConstants.ASSET_TYPE.VIDEO}
                        close={this.handleCloseShare}
                        show={this.state.showShare}
                        titleId={this.state.video.getIn(['browseTitleList', 0, 'id'])}
                    />

                    <VideoOverlay
                        clipping
                        titleId={this.state.video.getIn(['browseTitleList', 0, 'id'])}
                        video={videoOverlayVideo}
                        visible={this.state.showOverlay}
                        watchlist={this.state.watchlist}
                    />
                </div>
            </DocumentTitle>
        );
    }
}

export default (pageType, searchType = null) => {
    const ComposedComponent = WithPermissions(Container.create(SearchPage));

    const pageTypePredicate = (section) => {
        let type = searchType;

        if (pageType !== 'search') {
            type = 'titles';
        }

        if (type) {
            return section.type === type;
        }

        return true;
    };

    return function WithPageType(props) {
        return (<ComposedComponent {...props} pageType={pageType} pageTypePredicate={pageTypePredicate} searchType={searchType} />);
    };
};
