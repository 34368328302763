/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {ListDocumentsActions, ListDocumentsConstants} from './list-documents-actions';
import ListDocumentsStore from './list-documents-store';
import {MessagesContext} from '../messages/messages-context';
import {AssetTitleActions} from '../titles/asset-title/asset-title-actions';
import ImageLoader, {ImageLoaderPlaceholders} from '../titles/asset-title/images/image-loader';
import {BreakIntoGroups} from '../utils/utils';


class Item extends React.Component {
    static get propTypes() {
        return {
            item: PropTypes.object
        };
    }

    static get defaultProps() {
        return {
            item: null
        };
    }

    handleDownload(assetId) {
        AssetTitleActions.downloadImageRendition(assetId, 'Source');
    }

    render() {
        if (!this.props.item) {
            return null;
        }

        let itemImg = this.props.item.get('imageUrl');

        return (
            <div className="col-sm-6 padding-all-10">
                <a
                    onClick={this.handleDownload.bind(this, this.props.item.get('assetId'))}
                >
                    <ImageLoader
                        alt={this.props.item.get('description')}
                        className="img-responsive"
                        height="200"
                        placeholder={ImageLoaderPlaceholders.HORIZONTAL}
                        src={itemImg}
                        width="200"
                    />
                </a>
            </div>
        );
    }
}

class CountryOverviews extends React.Component {
    static calculateState() {
        return {
            pageData: ListDocumentsStore.getState().getIn(['pageData', ListDocumentsConstants.PUBLISHING_LIST.COUNTRY_OVERVIEWS], Immutable.Map())
        };
    }

    static getStores() {
        return [ListDocumentsStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidMount() {
        ListDocumentsActions.get(ListDocumentsConstants.PUBLISHING_LIST.COUNTRY_OVERVIEWS);
        return;
    }

    componentWillUnmount() {
        ListDocumentsActions.clear();
        return;
    }

    static contextType = MessagesContext;

    render() {
        // Group items by description. The description is the section label.
        let sections = this.state.pageData.get('items', Immutable.List()).reduce(/* istanbul ignore next */(r, i) => {
            let groupByLabel = i.get('description');

            return r.update(/* istanbul ignore next */groupByLabel, Immutable.List(), group => /* istanbul ignore next */group.push(i));
        }, Immutable.Map());

        return (
            <div>
                <div className="Ov(h)" style={{background: '#111111', color: '#ffffff'}}>
                    <div className="container Pos(rel) P(0)" style={{boxShadow: '0 0 30px #000000'}}>
                        <img className="img-responsive" src={this.state.pageData.get('summaryLinkURL')}/>
                    </div>
                </div>

                <div className="container padding-all-10">
                    {sections.keySeq().map(/* istanbul ignore next */k => {
                        return (
                            <div key={k}>
                                <h3>{k.replace(/<\/?div>/g, '') /* Don't do this! */}</h3>
                                {BreakIntoGroups(sections.get(k), 4).map(
                                    /* istanbul ignore next */(g, i) => /* istanbul ignore next */(
                                        <div className="row" key={i}>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <Item item={g.get(0)}/>
                                                    <Item item={g.get(1)}/>
                                                </div>
                                            </div>
                                            <div className="clearfix visible-xs"></div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <Item item={g.get(2)}/>
                                                    <Item item={g.get(3)}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        );
                    }).toJS()}
                </div>
            </div>
        );
    }
}

export default Container.create(CountryOverviews);

export {CountryOverviews, Item};
