/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import {Container} from 'flux/utils';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {VamActions} from './vam-actions';
import VamStore from './vam-store';
import {MessagesContext} from '../../messages/messages-context';
import Preloader from '../../preloader';

import 'datatables.net-responsive-bs/css/responsive.bootstrap.css';
import '../../styles/app/dataTables.brainiac.css';

// Load jQuery and register the datatables plugin.
let jQuery = require('jquery');

class Vam extends React.Component {
    static get propTypes() {
        return {
            match: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            loading: VamStore.getState().get('loading'),
            vamList: VamStore.getState().get('vamList')
        };
    }

    static getStores() {
        return [VamStore];
    }

    constructor(props) {
        super(props);
        this.state = this.constructor.calculateState();
        return;
    }

    componentDidMount() {
        /* istanbul ignore else */
        if (this.props.match.params.id) {
            VamActions.getVamPublishedList(this.props.match.params.id);
        }

        this.$table = jQuery('#vam-status-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This column will
                // contain the button to show the responsive data.
                className: 'control',
                targets: -1,
                width: 20
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        this.updateRows(this.state);
        this.handleResize();
        return;
    }

    componentDidUpdate() {
        this.updateRows(this.state);
        return;
    }

    componentWillUnmount() {
        /* istanbul ignore else */
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    static contextType = MessagesContext;

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    /* istanbul ignore next */
    getConvertedDate(date) {
        let d = Moment(date);

        if (!d.isValid()) {
            if (date !== null && date !== '' && date !== undefined) {
                return date;
            }

            return '-';
        }

        return d.format(this.context.intl.messages['date-format']);
    }

    updateRows(state) {
        if (!state) {return;}

        this.$tableAPI.clear();

        if (state.vamList) {
            state.vamList.forEach(vamItem => {
                let vamName = `<h3>${vamItem.get('name')}</h3>`;

                this.$tableAPI.row.add([vamName, '', '', '', '', '', '']);

                let vamTitleList = vamItem.get('vamTitleList');

                vamTitleList.forEach(vamTitleItem => {
                    let titleName;
                    if (vamTitleItem.get('titleId')) {
                        titleName = `<a href="/titles/${vamTitleItem.get('titleId')}">${vamTitleItem.get('name')}</a>`;
                    } else {
                        titleName = `${vamTitleItem.get('name')}`;
                    }

                    this.$tableAPI.row.add([
                        titleName,
                        vamTitleItem.get('fullRunningOrder'),
                        vamTitleItem.get('mpmNumber'),
                        this.getConvertedDate(vamTitleItem.get('airDate')),
                        this.getConvertedDate(vamTitleItem.get('dueDate')),
                        this.getConvertedDate(vamTitleItem.get('vamOutDate')),
                        ''
                    ]);
                });
            });
        }
        this.$tableAPI.draw(false);
        this.handleResize();
        return;
    }

    render() {
        return (
            <div>
                <Preloader fixed className="text-primary" show={this.state.loading}/>
                <div className="container padding-y-10 padding-x-20" id="hide-this">
                    <div className="row">
                        <h3 className="padding-y-10">
                            {this.context.intl.messages['footer.navmenu.dropdown.internal.item.vam']}
                        </h3>
                        <table id="vam-status-table" className="table table-striped responsive">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {this.context.intl.messages['footer.navmenu.dropdown.internal.item.vam.episode-title']}
                                    </th>
                                    <th scope="col">
                                        {this.context.intl.messages['footer.navmenu.dropdown.internal.item.vam.order']}
                                    </th>
                                    <th scope="col">
                                        {this.context.intl.messages['common.mpm']}
                                    </th>
                                    <th scope="col">
                                        {this.context.intl.messages['footer.navmenu.dropdown.internal.item.vam.air-date']}
                                    </th>
                                    <th scope="col">
                                        {this.context.intl.messages['footer.navmenu.dropdown.internal.item.vam.due-date']}
                                    </th>
                                    <th scope="col">
                                        {this.context.intl.messages['footer.navmenu.dropdown.internal.item.vam.vam-out-date']}
                                    </th>
                                    <th className="no-sort" scope="col"/>
                                </tr>
                            </thead>
                            <tbody/>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Container.create(Vam);
export {Vam};
