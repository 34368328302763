/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel} from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

import {UserCompanyActions} from './user-company-actions';
import {MessagesContext} from '../../messages/messages-context';
import {Debounce} from '../../utils/utils';

class UserCompanySelect extends Component {

    static get propTypes() {
        return {
            allowCreate: PropTypes.bool,
            attr: PropTypes.string.isRequired,
            clearSuggestions: PropTypes.bool,
            isClearable: PropTypes.bool,
            isLoading: PropTypes.bool,
            label: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
            labelKey: PropTypes.string,
            model: PropTypes.object.isRequired,
            options: PropTypes.instanceOf(Immutable.List).isRequired,
            placeholder: PropTypes.string,
            required: PropTypes.bool,
            selected: PropTypes.object,
            setter: PropTypes.func.isRequired,
            store: PropTypes.instanceOf(ReduceStore).isRequired,
        };
    }

    static get defaultProps() {
        return {
            allowCreate: false,
            clearSuggestions: false,
            isClearable: false,
            isLoading: false,
            label: undefined,
            labelKey: 'name',
            placeholder: undefined,
            required: false,
            selected: undefined,
            valueKey: 'id'
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.model.get(this.props.attr)
        };
        this.handleInputChange = Debounce(this.handleInputChange.bind(this), 200);
        this.handleChange = this.handleChange.bind(this);
        return;
    }

    componentDidMount() {
        if (!this.props.clearSuggestions) {
            UserCompanyActions.get(undefined, 0, 20);
        }
        return;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({value: nextProps.model.get(this.props.attr)});
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isLoading !== this.props.isLoading ||
            nextProps.selected !== this.props.selected ||
            nextProps.options !== this.props.options) {
            return true;
        }
        if (nextState.value !== this.state.value) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    handleInputChange(value) {
        if (value !== '') {
            UserCompanyActions.get(value, 0, 20);
            return;
        }
    }

    handleChange(company) {
        if (Array.isArray(company) && !company.length) {
            company = undefined;
        }
        this.props.setter(this.props.attr, Immutable.fromJS(company));
    }

    render() {
        let label = this.props.label;
        if (this.props.required) {
            label = <span>{this.props.label}&nbsp;<span className="text-red">*</span></span>;
        }
        label = <ControlLabel>{label}</ControlLabel>;

        let placeholder = this.context.intl.messages['common.form.select.placeholder'];
        if (this.props.placeholder) {
            placeholder = this.props.placeholder;
        }

        let value = '';
        let modelValue = this.state.value;
        if (modelValue !== null && modelValue !== undefined) {
            if (modelValue.toJS) {
                value = modelValue.toJS();
            } else {
                value = modelValue;
            }
        }

        let options = [];
        if (this.props.options) {
            options = this.props.options.toJS();
        }

        let select = (
            <CreatableSelect
                {...this.props}
                isValidNewOption={() => true}
                isClearable={this.props.isClearable}
                isLoading={this.props.isLoading}
                isMulti={false}
                name="companies"
                onInputChange={this.handleInputChange}
                options={options}
                placeholder={placeholder}
                value={value}
                valueKey="id"
                onChange={this.handleChange}
                getNewOptionData={(inputValue) => {
                    let newItem = {isNew:true, id:inputValue};
                    if (this.props.labelKey) {
                        newItem[this.props.labelKey] = inputValue;
                    }
                    return newItem;
                }}
            />
        );

        if (this.props.label) {
            return (
                <div style={{marginBottom: '20px'}}>
                    {label}
                    {select}
                </div>
            );
        }
        return select;
    }
}

export default UserCompanySelect;
