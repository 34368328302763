/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {WorkOrderActions, WorkOrderConstants} from './work-order-actions';
import {MessagesContext} from '../messages/messages-context';

export default class StatusChangeModal extends React.Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func.isRequired,
            orderId: PropTypes.number.isRequired,
            orders: PropTypes.instanceOf(Immutable.List).isRequired,
            show: PropTypes.bool.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            newStatus: -1,
        };

        this.doChangeStatus = this.doChangeStatus.bind(this);
        this.doClose = this.doClose.bind(this);
        this.handleNewStatusChange = this.handleNewStatusChange.bind(this);
    }

    static contextType = MessagesContext;

    doChangeStatus() {
        WorkOrderActions.statusChange(
            this.props.orders.find(wo => wo.get('id') === this.props.orderId),
            this.state.newStatus,
            this.props.onClose
        );
        this.setState({newStatus: -1});
    }

    doClose() {
        this.setState({newStatus: -1});
        this.props.onClose(false);
    }

    handleNewStatusChange(event) {
        let newStatus = parseInt(event.target.value);
        this.setState({newStatus: newStatus});
    }

    render() {
        let options = [];
        if (this.props.orderId !== undefined) {
            let order = this.props.orders.find(wo => wo.get('id') === this.props.orderId);
            switch (order.get('status')) {
            case WorkOrderConstants.STATUS_BY_NAME.ACTIVE:
                options.push(<option value={WorkOrderConstants.STATUS_BY_NAME.CANCELED}>{WorkOrderConstants.STATUS[WorkOrderConstants.STATUS_BY_NAME.CANCELED]}</option>);
                options.push(<option value={WorkOrderConstants.STATUS_BY_NAME.ONHOLD}>{WorkOrderConstants.STATUS[WorkOrderConstants.STATUS_BY_NAME.ONHOLD]}</option>);
                break;
            case WorkOrderConstants.STATUS_BY_NAME.COMPLETE:
                options.push(<option value={WorkOrderConstants.STATUS_BY_NAME.ARCHIVED}>{WorkOrderConstants.STATUS[WorkOrderConstants.STATUS_BY_NAME.ARCHIVED]}</option>);
                break;
            }
        }
        return (
            <Modal show={!!this.props.show} onHide={this.doClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center">{this.context.intl.messages['work-orders.status.change.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{this.context.intl.messages['work-orders.status.change.modal.description']}
                        <select className="form-control margin-right-5 show-per-page" value={this.state.newStatus} onChange={this.handleNewStatusChange} >
                            <option value="-1">Please Select one</option>
                            {options}
                        </select>
                    </p>
                </Modal.Body>
                <Modal.Footer className=" padding-all-20">
                    <Button className="btn-primary pull-right" disabled={this.state.newStatus < 0} onClick={this.doChangeStatus}>{this.context.intl.messages['common.ok']}</Button>
                    <Button className="btn-secondary pull-left" onClick={this.doClose}>{this.context.intl.messages['common.close']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
