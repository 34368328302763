/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal, Panel} from 'react-bootstrap';
import ReactSelect from 'react-select';
import {TransitionGroup} from 'react-transition-group';
import url from 'url';

import {ShareAssetActions} from './share-asset-actions';
import ShareAssetStore, {ShareAssetValidations} from './share-asset-store';
import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import {GetAttr} from '../common/utils/utils';
import {FormItem, FormRow} from '../form/form';
import {MessagesContext} from '../messages/messages-context';
import {Alert} from '../notification/alert';
import {AlertsWarnings} from '../notification/alert-warning';
import {OrderActions} from '../orders/order-actions';
import OrderStore from '../orders/order-store';
import SessionStore from '../session/session-store';
import {Debounce, IsNotTriggerKey} from '../utils/utils';

class Share extends React.Component {
    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            assetType: PropTypes.number.isRequired,
            close: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            titleId: PropTypes.string.isRequired
        };
    }

    static calculateState() {
        return {
            usersList: OrderStore.getState().get('usersList'),
            preAuthenticatedShare: ShareAssetStore.getState().get('preAuthenticatedShare'),
            share: ShareAssetStore.getState().get('share'),
        };
    }

    static getStores() {
        return [OrderStore, ShareAssetStore];
    }

    constructor(props) {
        super(props);
        this.canShare = this.canShare.bind(this);
        this.getAuthShareLink = this.getAuthShareLink.bind(this);
        this.getPreAuthShareLink = this.getPreAuthShareLink.bind(this);
        this.handleGenerateLink = this.handleGenerateLink.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onShare = this.onShare.bind(this);
        this.searchClients = Debounce(this.searchClients.bind(this), 200);
        this.selectClient = this.selectClient.bind(this);
        this.state = this.constructor.calculateState();
        this.updateShareProfile = this.updateShareProfile.bind(this);
        return;
    }

    shouldComponentUpdate(nextProps, nextState/*, nextContext*/) {
        if (this.props.show !== nextProps.show ||
            this.props.asset !== nextProps.asset ||
            this.state.share !== nextState.share ||
            this.state.preAuthenticatedShare !== nextState.preAuthenticatedShare ||
            this.state.usersList !== nextState.usersList ||
            this.state.isFormSubmited !== nextState.isFormSubmited) {
            return true;
        }
        return false;
    }

    static contextType = MessagesContext;

    canShare(asset, canShare) {
        let validDeliveryType = (
            asset.get('deliveryType') === AssetTypeConstants.DELIVERY_TYPES.ON_DEMAND ||
            asset.get('deliveryType') === AssetTypeConstants.DELIVERY_TYPES.NEEDS_APPROVAL
        );
        let mfaRequired = asset.get('mfaRequired');
        let forensicUrlRequired = asset.get('forensicUrlRequired');
        let hasDashLicense = asset.get('drmAmsCencLicenseUrl');
        let hasHlsLicense = asset.get('drmAmsHlsFairplayLicenseUrl');

        return canShare && validDeliveryType && !mfaRequired && !forensicUrlRequired && !hasDashLicense && !hasHlsLicense;
    }

    handleGenerateLink(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        ShareAssetActions.generatePreauthShareLink(
            this.props.asset,
            this.props.assetType,
            this.props.titleId,
            this.state.share.get('email'),
            this.state.share.get('daysActive'),
            this.state.share.get('views')
        );
    }

    onClose(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        this.props.close();
        ShareAssetActions.clear();
    }

    onShare(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        let validations = ShareAssetStore.getValidations();
        if (validations.length) {
            this.setState({isFormSubmited: true});
        } else {
            this.setState({isFormSubmited: false});
            let emailAddress = this.state.share.getIn(['selectedClient', 'email']) || this.state.share.get('email');
            ShareAssetActions.share(
                this.props.asset,
                this.props.assetType,
                this.props.titleId,
                emailAddress,
                this.state.share.get('daysActive'),
                this.state.share.get('views'),
                this.state.share.get('preAuthenticated')
            );
            this.onClose();
        }
        return;
    }

    updateShareProfile(attr, value) {
        if (attr === 'views') {value = parseInt(value);}
        ShareAssetActions.updateShareProfile(attr, value);
        return;
    }

    getAuthShareLink() {
        let assetType = 'images';
        if (this.props.assetType === AssetTypeConstants.ASSET_TYPE.VIDEO) {
            assetType = 'videos';
        }
        let base = url.parse(window.location.href);
        base.pathname = '';
        let videoId = '';
        if (this.props.asset && this.props.asset.get('assetId')) {
            if (this.props.asset.get('parentStackAssetId')) {
                videoId = `${this.props.asset.get('parentStackAssetId')}/`;
            }
            videoId += `${this.props.asset.get('assetId')}`;
        }
        let titleId = this.props.asset.get('titleId') || this.props.titleId;
        return url.resolve(base, `/titles/${titleId}/${assetType}/${videoId}`);
    }

    getPreAuthShareLink() {
        if (this.state.preAuthenticatedShare.size) {
            let base = url.parse(window.location.href);
            base.pathname = '';
            return url.resolve(base, `/pre/${this.state.preAuthenticatedShare.first().get('shareToken')}`);
        }
        return '';
    }

    searchClients(term) {
        OrderActions.getUsersList(term);
        return;
    }

    selectClient(value) {
        ShareAssetActions.updateShareProfile('selectedClient', Immutable.fromJS(value));
    }

    render() {
        let validations = ShareAssetStore.getValidations();
        let warning;
        /* istanbul ignore next */
        if (this.state.isFormSubmited) {
            warning = (<AlertsWarnings
                title={this.context.intl.messages['signup.form.error.message']}
                validations={validations} />);
        }
        let preAuthCheckbox;
        /* istanbul ignore else */
        if (this.canShare(this.props.asset, SessionStore.canUser(SessionStore.PERMISSIONS.SHARE.PRE_AUTH))) {
            preAuthCheckbox = (
                <FormRow>
                    <FormItem attr="preAuthenticated"
                        label={this.context.intl.messages['title.modal.share.do-not-require-login']}
                        model={this.state.share}
                        setter={this.updateShareProfile}
                        type="checkbox"
                    />
                </FormRow>);
        }
        let copyUrlSection;
        let preAuthSection;
        let sendToEmailSection;
        /* istanbul ignore else */
        if (!this.state.share.get('preAuthenticated')) {
            let selectedClient;
            /* istanbul ignore next */
            if (this.state.share.getIn(['selectedClient', 'id'])) {
                selectedClient = this.state.share.get('selectedClient').toJS();
            }

            sendToEmailSection = (
                <FormRow>
                    <div className="form-group">
                        <label>{this.context.intl.messages['title.modal.share.send-email']}:</label>
                        <ReactSelect
                            getOptionLabel={GetAttr('email')}
                            getOptionKey={GetAttr('id')}
                            getOptionValue={GetAttr('id')}
                            onInputChange={this.searchClients}
                            onChange={this.selectClient}
                            options={this.state.usersList.toJS()}
                            value={selectedClient}
                        />
                    </div>
                </FormRow>);

            copyUrlSection = (<FormRow>
                <div className="form-group">
                    <label>{this.context.intl.messages['title.modal.share.copy-url']}:</label>
                    <input type="text" className="form-control" value={this.getAuthShareLink()} readOnly />
                </div>
            </FormRow>);
        } else {
            // Free form email input, expiration date, allowed views
            sendToEmailSection = (
                <FormRow>
                    <FormItem attr="email"
                        label={`${this.context.intl.messages['title.modal.share.send-email']}:`}
                        model={this.state.share}
                        setter={this.updateShareProfile}
                        type="email"
                        validations={ShareAssetValidations.email.validations}
                    />
                </FormRow>);
            let generateLinkWarning;
            if (validations.length) {
                generateLinkWarning = (<small className="text-muted"><em>{this.context.intl.messages['tital.modal.generate-link.warning']}</em></small>);
            }
            preAuthSection = (<div id="preauthenticated-options">
                <FormRow>
                    <FormItem attr="daysActive"
                        label={this.context.intl.messages['title.modal.share.days-active']}
                        model={this.state.share}
                        setter={this.updateShareProfile}
                        type="number"
                        validations={ShareAssetValidations.daysActive.validations}
                    />
                    <FormItem attr="views"
                        label={this.context.intl.messages['title.modal.share.allowed-views']}
                        model={this.state.share}
                        setter={this.updateShareProfile}
                        type="number"
                        min={1}
                        max={1000}
                        validations={ShareAssetValidations.views.validations}
                    />
                </FormRow>
                <Panel>
                    <div className="form-group">
                        <label>{this.context.intl.messages['title.modal.share.pre-authenticated-link']}:</label>
                        <input type="text" className="form-control" value={this.getPreAuthShareLink()} readOnly />
                    </div>
                    <div id="preauthenticated-notify">
                        {generateLinkWarning}
                    </div>
                    <Button className="btn btn-secondary pull-right" disabled={!!validations.length} onClick={this.handleGenerateLink} onKeyUp={this.handleGenerateLink}>{this.context.intl.messages['title.modal.share.generate-link']}</Button>
                </Panel>
            </div>);
        }

        return (
            <Modal show={this.props.show} onHide={this.onClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center text-uppercase">{this.context.intl.messages['title.modal.share.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form>
                            <FormRow>
                                <TransitionGroup
                                    transitionName="alert"
                                    transitionEnterTimeout={300}
                                    transitionLeaveTimeout={300}>
                                    <Alert />
                                    {warning}
                                </TransitionGroup>
                            </FormRow>

                            {sendToEmailSection}
                            {copyUrlSection}
                            {preAuthCheckbox}
                            {preAuthSection}
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer className=" padding-all-20">
                    <Button bsStyle="default" className="pull-left btn-secondary" onClick={this.onClose} onKeyUp={this.onClose}>{this.context.intl.messages['common.close']}</Button>
                    <Button bsStyle="primary" className="pull-right" disabled={!!validations.length} onClick={this.onShare} onKeyUp={this.onShare}><span className="glyphicon glyphicon-transfer"></span> {this.context.intl.messages['title.modal.share.share']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(Share);
export {Share};
