/* istanbul ignore file */
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {ListDocumentsActions} from './list-documents-actions';
import ListDocumentsStore from './list-documents-store';
import ListDocumentTable from './list-documents-table';
import DocumentTitle from '../common/document-title';
import {MessagesContext} from '../messages/messages-context';


export default function(publishingListId, documentType) {

    class ListDocuments extends Component {
        static get propTypes() {
            return {
                location: PropTypes.object.isRequired
            };
        }

        static calculateState() {
            return {
                pageData: ListDocumentsStore.getState().get('pageData')
            };
        }

        static getStores() {
            return [ListDocumentsStore];
        }

        constructor(props) {
            super(props);

            this.state = this.constructor.calculateState();
        }

        componentDidMount() {
            if (documentType) {
                ListDocumentsActions.getByDocumentType(documentType);
            } else {
                ListDocumentsActions.get(publishingListId);
            }
        }

        componentWillUnmount() {
            ListDocumentsActions.clear();
        }

        static contextType = MessagesContext;

        render() {
            let plId = publishingListId || documentType;
            let publishingList = this.state.pageData.get(plId, Immutable.Map({items: Immutable.List()}));
            let documentMessage = `document-titles.footer.${this.props.location.pathname.substring(1)}`;
            let label = publishingList.get('displayName');

            if (documentType) {
                documentMessage = `list-documents.table.document-types.${documentType}`;
                label = this.context.intl.messages[documentMessage];
            }

            return (
                <DocumentTitle
                    message = {documentMessage}
                >
                    <ListDocumentTable
                        documents={publishingList.get('items')}
                        label={label}
                    />
                </DocumentTitle>
            );
        }
    }

    return Container.create(ListDocuments);
}
