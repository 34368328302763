/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import Config from '../../config/config';
import {MessagesContext} from '../../messages/messages-context';

export default class Header extends React.Component {
    static get propTypes() {
        return {
            aka: PropTypes.instanceOf(Immutable.List).isRequired,
            canEditBrainiac: PropTypes.bool.isRequired,
            talent: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    constructor(props) {
        super(props);

        return;
    }

    static contextType = MessagesContext;

    render() {
        let akaText;
        if (this.props.aka.size > 1) {
            // First aka is always the talent's fullName so we remove it from the aka list
            const aka = this.props.aka.shift();
            akaText = <p className="padding-bottom-0">{this.context.intl.messages['common.aka']}: <strong>{aka.toArray().join(', ')}</strong></p>;
        }
        let editInBrainiac;
        if (this.props.canEditBrainiac) {
            editInBrainiac = <a target="_blank" href={`${Config.BrainiacBaseUrl}/talent/${this.props.talent.get('id')}`}>
                <span className="far fa-edit" />
            </a>;
        }
        return (<div className="title-header">
            <div className="container">
                <div className="title-header-text">
                    <h1>
                        <strong>{this.props.talent.get('fullName') || '' }</strong>
                        {editInBrainiac}
                    </h1>
                    {akaText}
                </div>
            </div>
        </div>);
    }
}
