/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';

import List from './list';
import {MultipleTitlesActions} from './multiple-titles-actions';
import MultipleTitlesStore from './multiple-titles-store';
import DocumentTitle from '../../common/document-title';
import withSetSearchFilters from '../../common/search-filters/hocs/with-set-search-filters';
import {MessagesContext} from '../../messages/messages-context';
import Pagination from '../../pagination';
import {RouterActions} from '../../router/router-actions';
import SessionStore from '../../session/session-store';

class MultipleTitles extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            setFilter: PropTypes.func.isRequired,
        };
    }

    static getStores() {
        return [MultipleTitlesStore];
    }

    static calculateState() {
        return {
            titles: MultipleTitlesStore.getState().get('titles'),
            total: MultipleTitlesStore.getState().get('total'),
            offset: MultipleTitlesStore.getState().get('offset'),
            size: MultipleTitlesStore.getState().get('size'),
        };
    }

    constructor(props) {
        super(props);

        this.handleLoadPage = this.handleLoadPage.bind(this);
    }

    componentDidMount() {
        MultipleTitlesActions.get(QueryString.parse(this.props.location.search));
    }

    componentDidUpdate(prevProps) {
        /* istanbul ignore else */
        if (this.props.location !== prevProps.location) {
            MultipleTitlesActions.get(QueryString.parse(this.props.location.search));
        }
    }

    static contextType = MessagesContext;

    handleLoadPage(offset) {
        const q = QueryString.parse(this.props.location.search);
        let size = q.size || 20;
        let query = Object.assign(
            {},
            q,
            {
                size,
                offset
            }
        );
        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    render() {
        let lastLogin = SessionStore.getState().get('lastLoginTimestamp');
        const lastLoginDate = Moment(lastLogin);
        /* istanbul ignore if */
        if (lastLoginDate.isValid()) {
            lastLogin = lastLoginDate.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(this.context.intl.messages['datetime-full-ampm-tz']);
        }
        const userName = SessionStore.getState().getIn(['authUser', 'name']);
        const query = QueryString.parse(this.props.location.search);
        return (
            <DocumentTitle message="document-titles.home">
                <div className="padding-x-30">
                    <div className="container padding-top-20">
                        <h1 className="margin-top-10 margin-bottom-0">{this.context.intl.messages['homepage-titles.welcome']},&nbsp;<strong>{userName}</strong></h1>
                        <h4 className="margin-top-0"><small>{this.context.intl.messages['homepage-titles.last-login']}:&nbsp;{lastLogin}</small></h4>
                    </div>
                </div>
                <hr/>
                <div className="padding-x-30">
                    <div className="clearfix">
                        <div className="pull-left-sm-up">
                            <h3>{this.context.intl.messages['homepage-titles.titles-to-explore']}&nbsp;
                                <small>{this.state?.total}&nbsp;{this.context.intl.messages['homepage-titles.titles']}</small>
                            </h3>
                        </div>
                        <div className="pull-right-sm-up margin-top-10 grid-options">
                            <div className="visible-xs-block visible-inline-block-sm-up text-center">
                                <div className="text-center inline-block">
                                    {this.context.intl.messages['homepage-titles.sort-by']}
                                    <select
                                        className="form-control margin-left-5 show-per-page"
                                        onChange={this.props.setFilter.bind(this, 'sort-field-name')}
                                        value={query['sort-field-name']}
                                    >
                                        <option value="relevance">{this.context.intl.messages['search.filters.navbar.titles.sort.relevance']}</option>
                                        <option value="alphabetical">{this.context.intl.messages['search.filters.navbar.titles.sort.alphabetical']}</option>
                                        <option value="createdDate">{this.context.intl.messages['search.filters.navbar.titles.sort.date-added']}</option>
                                        <option value="releaseDate">{this.context.intl.messages['search.filters.navbar.titles.sort.date-release']}</option>
                                    </select>
                                    <select
                                        className="form-control margin-left-5 show-per-page"
                                        onChange={this.props.setFilter.bind(this, 'sort-direction')}
                                        value={query['sort-direction']}
                                    >
                                        <option value="desc">{this.context.intl.messages['search.filters.navbar.titles.sort.descending']}</option>
                                        <option value="asc">{this.context.intl.messages['search.filters.navbar.titles.sort.ascending']}</option>
                                    </select>
                                </div>
                            </div>
                            <hr className="visible-xs-block"/>
                            <span className="vertical-divider hidden-xs"></span>
                            <Pagination
                                offset={this.state?.offset}
                                onChange={this.handleLoadPage}
                                size={this.state?.size}
                                total={this.state?.total}
                            />
                            <hr className="visible-xs-block"/>
                        </div>
                    </div>
                    <hr/>
                    <List
                        titles={this.state?.titles}
                        offset={this.state?.offset}
                        totalPages={this.state?.total}
                        size={this.state?.size}
                        onPageChange={this.handleLoadPage}
                    />
                </div>
            </DocumentTitle>
        );
    }
}

export default withSetSearchFilters(Container.create(MultipleTitles));
export {MultipleTitles};
