/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './tv-event.less';

export default class TVEvent extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.tv-event">
                <div>
                    <div className="container op_container_tv_event">
                        <div id="op_header" className="row">
                            <div id="op-header-img">
                                <div id="header-btns">
                                    <a
                                        className="btn-trailer btn-header reel"
                                        href="/titles/1842275/videos/1783771"
                                        data-title="Sizzle Reel"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <img
                                            src="https://www.wbtvd.com/page/tv-event/banner_btn_sizzlereel.png"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        className="btn-header presentation"
                                        href="https://www.wbtvd.com/page/tv-event/TV_EventSeries2016.pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <img
                                            src="https://www.wbtvd.com/page/tv-event/banner_btn_presentation.png"
                                            alt=""
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="op_content" className="row clearfix"></div>

                        <div id="op_footer" className="row">
                            For more information or to order the TV Event Series Programming Package,
                            please email&nbsp;
                            <a href="mailto:Mike.Shackleton@warnerbros.com?subject=Re%3A%20TV%20Event%20Programming%20Package">
                                Mike.Shackleton@warnerbros.com
                            </a>
                        </div>

                        <div id="op_content" className="row clearfix">
                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51651"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/Roots_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51652"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/Roots_Next_Generation_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51588"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/Alex_Haleys_Queen_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51644"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/North_And_South_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51645"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/North_And_South_II_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51622"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/Heaven_And_Hell_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51660"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/The_Thorn_Birds_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51661"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/The_Thorn_Birds_Missing_Years_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/54094"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/Mists_Of_Avalon_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51589"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/Andersonville_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51616"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/Gettysburg_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51668"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/Two_Mrs_Grenvilles_227x304.jpg)'}}></a>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/51632"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/tv-event/Napoleon_And_Josephine_227x304.jpg)'}}></a>
                            </div>
                        </div>
                    </div>

                    <p id="demo"></p>
                </div>

            </DocumentTitle>

        );
    }
}
