/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {MessagesContext} from '../messages/messages-context';

const CONSTANTS = {
    RIGHTS: {
        CONTACT_SERVICING:0,
        CLEARED: 1,
        SEE_NOTES:2
    },
};

let CalculateRights = function(asset) {
    if (!asset) {
        return CONSTANTS.RIGHTS.SEE_NOTES;
    }
    let rights = asset.get('videoRightsType');
    let territory = asset.get('videoRightsTerritoryType');
    let media = asset.get('videoRightsMediaType');
    let term = asset.get('videoRightsTermType');
    // sorry no end date
    let notes = asset.get('rightsNotes');

    if (rights === null &&
        territory === null &&
        media === null &&
        term === null &&
        notes === null) {
        return CONSTANTS.RIGHTS.CONTACT_SERVICING;
    }

    if (rights === undefined &&
        territory === undefined &&
        media === undefined &&
        term === undefined &&
        notes === undefined) {
        return CONSTANTS.RIGHTS.CONTACT_SERVICING;
    }
    if (rights === 'Picture and Music' &&
        (territory === 'Worldwide' || territory === 'International') &&
        media === 'All Media' &&
        term === 'Perp' &&
        !notes) {
        return CONSTANTS.RIGHTS.CLEARED;
    }
    return CONSTANTS.RIGHTS.SEE_NOTES;
};

let CalculateRightsText = function(asset, messages) {
    switch (CalculateRights(asset)) {
    case CONSTANTS.RIGHTS.CLEARED:
        return messages['cart.view.table.rights.cleared'];
    case CONSTANTS.RIGHTS.SEE_NOTES:
        return messages['cart.view.table.rights.see-notes'];
    }
    return messages['cart.view.table.rights.contact-servicing'];
};

let RightsTooltip = function(asset, messages) {
    if (!asset) {
        return messages['cart.view.table.rights.contact-servicing'];
    }
    let tooltip = [
        asset.get('videoRightsType'),
        asset.get('videoRightsTerritoryType'),
        asset.get('videoRightsMediaType'),
        asset.get('videoRightsTermType')
        // sorry no end date
    ];
    let notes = asset.get('rightsNotes');

    tooltip = tooltip.filter( t => {
        return t !== null && t !== undefined && t !== 'See Notes';
    });
    let text = tooltip.join(', ');
    if (notes) {
        text = [text, notes].join('. ');
    }
    if (text.length === 0) {
        // all null case
        text = messages['cart.view.table.rights.contact-servicing'];
    }
    return text;
};

export default class AssetRights extends React.Component {
    static get propTypes() {
        return {
            video: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (this.props.video !== nextProps.video) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    render() {
        let iconClassName = CalculateRights(this.props.video) === CONSTANTS.RIGHTS.CLEARED ? 'glyphicon-globe' : 'glyphicon-warning-sign';

        return (
            <span
                aria-label={RightsTooltip(this.props.video, this.context.intl.messages)}
                className={ClassNames('glyphicon', iconClassName, 'video-thumbnail-tooltip')}
                role="presentation"
                title={RightsTooltip(this.props.video, this.context.intl.messages)}
            />
        );
    }
}

export {
    CalculateRights,
    CalculateRightsText,
    CONSTANTS as RightsConstants,
    RightsTooltip,
};
