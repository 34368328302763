/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {createApi} from '@reduxjs/toolkit/query/react';
import {Partner} from '@wbdt-sie/brainiac-web-common';

import {BaseQueryWithAuth} from '../redux/rtk-query';

type PartnerType = typeof Partner;
interface PostObject {
    id: string,
    token: string | undefined
}

interface PostRes {
    secureLoginToken: string
}

export default createApi({
    reducerPath: 'multiBrandAPI',
    baseQuery: BaseQueryWithAuth,
    endpoints: (builder) => ({
        getAllPartners: builder.query<PartnerType[], void>({
            query: () => ({url: 'user/session/partner'}),
            providesTags: () => {
                return [{type: 'Partners'}];
            }
        }),
        newPartnerTokenPost: builder.mutation<PostRes, PostObject>({
            query: ({id, token}: PostObject) => ({
                url: `user/partner/${id}/login-token`,
                method: 'POST',
                body: `Authorization ${token}`
            }),
            invalidatesTags: [{type: 'Partners'}],
        }),
        putSecurityRequest: builder.mutation<void, string>({
            query: (requestAdditionalSecurity: string) => ({
                url: 'user/request-additional-security',
                method: 'PUT',
                body: {requestAdditionalSecurity: requestAdditionalSecurity}
            }),
            invalidatesTags: [{type: 'Brands'}], //Funny, so if here i put Partners, when this call is completed
            // the user/session/partner request is automatically fired refresh content, very helpfull, not in this case
        }),
    }),
    tagTypes: ['Partners', 'Brands']
});
