/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';

import {SubscriptionActions} from './subscription-actions';
import {MessagesContext} from '../../../messages/messages-context';
import Pagination from '../../../pagination';
import {IsNotTriggerKey} from '../../../utils/utils';

const COLUMNS = {
    SUBSCRIPTION_DATE: 'SUBSCRIPTION_DATE',
    SUGGESTED_BY: 'SUGGESTED_BY',
    TITLE_NAME: 'TITLE_NAME'
};

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/app/dataTables.brainiac.css');

// Load jQuery and register the datatables plugin.
// eslint-disable-next-line @typescript-eslint/no-var-requires
let jQuery = require('jquery');
require('datatables.net-responsive-bs');

class ListSubscriptions extends Component {

    static get propTypes() {
        return {
            icon: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            offset: PropTypes.number,
            onPageChange: PropTypes.func,
            onSortChange: PropTypes.func,
            size: PropTypes.number,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            subscriptionContentType: PropTypes.number.isRequired,
            subscriptionTarget: PropTypes.instanceOf(Immutable.Map).isRequired,
            subscriptions: PropTypes.instanceOf(Immutable.List),
            total: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            offset: undefined,
            onPageChange: () => void 0,
            onSortChange: () => void 0,
            size: undefined,
            sortDirection: undefined,
            sortFieldName: undefined,
            subscriptionContentType: 0,
            subscriptionTarget: undefined,
            subscriptions: Immutable.List(),
            total: 0
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            id: Math.ceil(Math.random() * 100000),
            sortFieldName: this.props.sortFieldName,
            sortDirection: this.props.sortDirection
        };

        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleRowTrigger = this.handleRowTrigger.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery(`#title-subscriptions-table-${this.state.id}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                targets: 0,
                width: 'auto'
            }, {
                targets: 1,
                width: 300
            }, {
                targets: 2,
                width: 150
            }, {
                className: 'actions',
                targets: 3,
                width: 20
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        this.updateRows(this.props, this.state);
        return;
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.subscriptions !== this.props.subscriptions ||
            nextProps.size !== this.props.size ||
            nextProps.total !== this.props.total ||
            nextProps.offset !== this.props.offset
        ) {
            // we don't update component upon sort field/direction changes because we must wait until the response comes
            // from the API; otherwise the sorting glyph updates before the data actually arrives, and that is confusing
            // from a UX perspective
            return true;
        }
        return false;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        this.updateRows(nextProps, nextState);
        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    static contextType = MessagesContext;

    getSortByClass(column) {
        let r = 'sorting';
        if (column === this.state.sortFieldName) {
            r = `sorting_${this.state.sortDirection}`;
        }
        return r;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowTrigger(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        switch (true) {
        // Handle click on a remove button.
        case !!~event.target.className.indexOf('unsubscribe-client'):
            // Prevent the default anchor click event.
            event.preventDefault();
            const titleId = parseInt(event.target.getAttribute('titleId'), 10);
            const userId = parseInt(event.target.getAttribute('userId'), 10);
            const subscriptionContentType = parseInt(event.target.getAttribute('subscriptionContentType'), 10);
            const sortFieldName = event.target.getAttribute('sortFieldName');
            const sortDirection = event.target.getAttribute('sortDirection');
            const size = parseInt(event.target.getAttribute('size'), 10);
            const offset = parseInt(event.target.getAttribute('offset'), 10);

            SubscriptionActions.removeSubscription(
                titleId,
                userId,
                subscriptionContentType,
                sortFieldName,
                sortDirection,
                size,
                offset
            );
            break;
        }

        return;
    }

    handleHeaderClick(headerName) {

        let newSortDirection = 'asc';
        if (this.state.sortFieldName.toUpperCase() === headerName.toUpperCase() &&
            this.state.sortDirection.toUpperCase() === newSortDirection.toUpperCase()
        ) {
            newSortDirection = 'desc';
        }

        this.setState(() => ({
            sortFieldName: headerName,
            sortDirection: newSortDirection
        }));

        this.props.onSortChange(
            this.props.subscriptionTarget,
            this.props.subscriptionContentType,
            headerName,
            newSortDirection,
            this.props.size,
            this.props.offset
        );
    }

    updateRows(props, state) {
        this.$tableAPI.clear();
        if (props.subscriptions) {
            let subscriptions = props.subscriptions;

            // Add sorted data to the jQuery datatable.
            subscriptions.forEach(s => {
                const id = s.get('userId');
                const titleId = s.get('titleId');
                const titleName =`<a href="/titles/${titleId}" target="_blank">${s.get('titleName', '')}</a>`;
                const userName = s.get('suggestedByFullName');
                const subscriptionContentType = s.get('subscriptionContentType');
                const sortFieldName = state.sortFieldName;
                const sortDirection = state.sortDirection;
                const size = props.size;
                const offset = props.offset;
                this.$tableAPI.row.add([
                    titleName,
                    userName,
                    Moment(s.get('subscriptionDate')).format(this.context.intl.messages['date-format']) || '',
                    `<a class="" href=""> <span class="glyphicon glyphicon-trash unsubscribe-client" titleId=${titleId} userId=${id} subscriptionContentType=${subscriptionContentType} sortFieldName=${sortFieldName} sortDirection=${sortDirection} size=${size} offset=${offset}></span></a>`,
                    ''
                ]);
                return;
            });
        }
        this.$tableAPI.draw(false);
        return;
    }

    render() {
        let pagination;
        if (this.props.subscriptions.size > 0 && this.props.onPageChange) {
            pagination = (
                <Row>
                    <Col md={12} className="text-center">
                        <Pagination
                            className="margin-top-10"
                            offset={this.props.offset}
                            onChange={(offset) => this.props.onPageChange(
                                this.props.subscriptionTarget,
                                this.props.subscriptionContentType,
                                this.state.sortFieldName,
                                this.state.sortDirection,
                                this.props.size,
                                offset
                            )}
                            size={this.props.size}
                            total={this.props.total}
                        />
                    </Col>
                </Row>
            );
        }

        return (
            <div>
                <h3><span className={this.props.icon}></span>&nbsp;{this.props.label}</h3>
                <table id={`title-subscriptions-table-${this.state.id}`} className="table table-striped responsive">
                    <thead>
                        <tr>
                            <th scope="col" className={this.getSortByClass(COLUMNS.TITLE_NAME)} onClick={this.handleHeaderClick.bind(this, COLUMNS.TITLE_NAME)}>{this.context.intl.messages['account.tab.subscriptions.list.title']}</th>
                            <th scope="col" className={this.getSortByClass(COLUMNS.SUGGESTED_BY)} onClick={this.handleHeaderClick.bind(this, COLUMNS.SUGGESTED_BY)}>{this.context.intl.messages['account.tab.subscriptions.list.suggested-by']}</th>
                            <th scope="col" className={this.getSortByClass(COLUMNS.SUBSCRIPTION_DATE)} onClick={this.handleHeaderClick.bind(this, COLUMNS.SUBSCRIPTION_DATE)}>{this.context.intl.messages['account.tab.subscriptions.list.subscribed-date']}</th>
                            <th scope="col" className="no-sort icon-column sorting_disabled"><span className="glyphicon glyphicon-trash"></span></th>
                            <th scope="col" className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowTrigger} onKeyUp={this.handleRowTrigger} tabIndex="0" />
                </table>
                {pagination}
            </div>
        );
    }

}

export default ListSubscriptions;
