/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {isEpisode, isEpisodeFromMiniseries} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Grid} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import style from './header.module.less';
import Config from '../../config/config';
import {MessagesContext} from '../../messages/messages-context';
import SessionStore from '../../session/session-store';
import ImageLoader from '../../titles/asset-title/images/image-loader';
import {Compare, CompareImmutable, FormatReleaseDate} from '../../utils/utils';
import {TitleConstants} from '../title-actions';

class EpisodeHeader extends Component {

    static get propTypes() {
        return {
            akas: PropTypes.string,
            canViewReleaseDate: PropTypes.bool,
            children: PropTypes.node,
            editInBrainiac: PropTypes.object,
            releaseDate: PropTypes.string,
            releaseDateDisplayName: PropTypes.string,
            releaseDateType: PropTypes.number,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            akas: undefined,
            canViewReleaseDate: false,
            children: undefined,
            editInBrainiac: undefined,
            releaseDate: undefined,
            releaseDateDisplayName: undefined,
            releaseDateType: undefined
        };
    }

    shouldComponentUpdate(nextProps) {
        return !Compare(this.props, nextProps, ['children', 'serieNavigation', 'releaseDate', 'releaseDateType', 'releaseDateDisplayName']) ||
            !CompareImmutable(this.props.title, nextProps.title, ['id']);
    }

    static contextType = MessagesContext;

    render() {
        const where = this.props.serieNavigation.getIn(['serieMap', `${this.props.title.get('id')}`]);
        let releaseDateText = this.context.intl.messages['title.header.release-date'];
        if (TitleConstants.MADE_FOR_TYPES[this.props.title.get('madeForType')] === 'TV') {
            releaseDateText = this.context.intl.messages['title.header.broadcast-premiere'];
        }
        let mpmNumber;
        let serieLink;
        let seasonNumber = '-';
        let episodeNumber = '-';
        let seasonEpisodeText;
        let seasonText;

        if (this.props.serieNavigation.get('serieMap')) {
            serieLink = <Link to={`/titles/${this.props.serieNavigation.get('titleId')}`}>{this.props.serieNavigation.get('titleName')}</Link>;
        }

        if (where) {
            if (isEpisodeFromMiniseries(where)) {
                let episode = this.props.serieNavigation.get('episodes').find(e => e.get('childTitleId') === this.props.title.get('id'));
                episodeNumber = episode.get('orderWithinParent');
                seasonEpisodeText = `E${episodeNumber.toString().padStart(2, '0')}`;
            }
            if (isEpisode(where)) {
                seasonNumber = this.props.serieNavigation.getIn(['seasons', where.get(0), 'seasonNumber']);
                episodeNumber = this.props.serieNavigation.getIn(['seasons', where.get(0), 'episodes', where.get(1), 'episodeNumber']);
                seasonText = <><strong>{this.context.intl.messages['title.header.season']}:&nbsp;{seasonNumber}</strong>&nbsp;|&nbsp;</>;
                seasonEpisodeText = `S${seasonNumber.toString().padStart(2, '0')}E${episodeNumber.toString().padStart(2, '0')}`;
            }
        }

        let title;
        if (this.props.title.get('defaultImageTitleTreatmentId')) {
            title = (
                <>
                    <Link to={`/titles/${this.props.serieNavigation.get('titleId')}`}>
                        <img
                            alt={this.props.title.get('displayName')}
                            src={this.props.title.get('defaultImageTitleTreatmentPreviewUrl')}
                        />
                    </Link>
                    {this.props.editInBrainiac}
                </>
            );
        } else {
            title = (
                <h2 className="h1">
                    <strong>
                        {serieLink} {seasonEpisodeText}
                    </strong> {this.props.title.get('displayName')}
                    {this.props.editInBrainiac}
                </h2>
            );
        }

        if (this.props.title.get('mpmNumber')) {
            mpmNumber = <>&nbsp;|&nbsp;{this.context.intl.messages['common.mpm']}: <strong>{this.props.title.get('mpmNumber')}</strong></>;
        }

        let fullReleaseDateText;
        if (this.props.canViewReleaseDate) {
            let rd = this.props.releaseDateDisplayName || this.props.releaseDate;

            fullReleaseDateText =<>&nbsp;|&nbsp;{releaseDateText}: <strong>{FormatReleaseDate(rd, this.props.releaseDateType)}</strong></>;
        }

        return (
            <div className="title-header-text">
                {title}
                <p className="padding-bottom-0">
                    {seasonText}
                    <strong>{this.context.intl.messages['title.header.episode']}:&nbsp;{episodeNumber}</strong>
                    {fullReleaseDateText}
                    {this.props.akas}
                    {mpmNumber}
                </p>
                {this.props.children}
            </div>
        );
    }

}

class SeasonHeader extends Component {
    static get propTypes() {
        return {
            akas: PropTypes.string,
            canViewReleaseDate: PropTypes.bool,
            children: PropTypes.node,
            editInBrainiac: PropTypes.object,
            releaseDate: PropTypes.string,
            releaseDateType: PropTypes.number,
            releaseDateDisplayName: PropTypes.string,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            akas: undefined,
            canViewReleaseDate: false,
            children: undefined,
            editInBrainiac: undefined,
            releaseDate:undefined,
            releaseDateDisplayName: undefined,
            releaseDateType: undefined,
        };
    }

    shouldComponentUpdate(nextProps) {
        return !Compare(this.props, nextProps, ['children', 'releaseDate', 'releaseDateType', 'releaseDateDisplayName', 'serieNavigation']) ||
            !CompareImmutable(this.props.title, nextProps.title, ['id']);
    }

    static contextType = MessagesContext;

    render() {
        let releaseDateText = this.context.intl.messages['title.header.release-date'];
        if (TitleConstants.MADE_FOR_TYPES[this.props.title.get('madeForType')] === 'TV') {
            releaseDateText = this.context.intl.messages['title.header.broadcast-premiere'];
        }

        let mpmNumber;
        let seasonNumber = this.props.title.get('season', '-');
        let title;

        if (this.props.title.get('defaultImageTitleTreatmentId')) {
            title = (
                <>
                    <Link to={`/titles/${this.props.serieNavigation.get('titleId')}`}>
                        <img
                            alt={`${this.props.title.get('name')} ${this.context.intl.messages['title.header.season']} ${seasonNumber}`}
                            src={this.props.title.get('defaultImageTitleTreatmentPreviewUrl')}
                        />
                    </Link>
                    {this.props.editInBrainiac}
                </>
            );
        } else {
            title = (
                <h2 className="h1">
                    <strong>
                        {this.props.title.get('name')}
                    </strong> {this.context.intl.messages['title.header.season']}&nbsp;{seasonNumber}
                    {this.props.editInBrainiac}
                </h2>
            );
        }

        if (this.props.title.get('mpmNumber')) {
            mpmNumber = <>&nbsp;|&nbsp;{this.context.intl.messages['common.mpm']}: <strong>{this.props.title.get('mpmNumber')}</strong></>;
        }

        let fullReleaseDateText;
        if (this.props.canViewReleaseDate) {
            let rd = this.props.releaseDateDisplayName || this.props.releaseDate;
            fullReleaseDateText =<>&nbsp;|&nbsp;{releaseDateText}: <strong>{FormatReleaseDate(rd, this.props.releaseDateType)}</strong></>;
        }

        return (
            <div className="title-header-text">
                {title}
                <p className="padding-bottom-0">
                    {this.context.intl.messages['title.header.total-episodes']}: <strong>{this.props.title.get('episodeCount')}</strong>
                    {fullReleaseDateText}
                    {this.props.akas}
                    {mpmNumber}
                </p>
                {this.props.children}
            </div>
        );
    }
}

class MiniSerieHeader extends Component {
    static get propTypes() {
        return {
            akas: PropTypes.string,
            canViewReleaseDate: PropTypes.bool,
            children: PropTypes.node,
            editInBrainiac: PropTypes.object,
            releaseDate: PropTypes.string,
            releaseDateType: PropTypes.number,
            releaseDateDisplayName: PropTypes.string,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            akas: undefined,
            canViewReleaseDate: false,
            children: undefined,
            editInBrainiac: undefined,
            releaseDate:undefined,
            releaseDateType: undefined,
            releaseDateDisplayName: undefined,
        };
    }

    shouldComponentUpdate(nextProps) {
        return !Compare(this.props, nextProps, ['children', 'releaseDate', 'releaseDateType', 'releaseDateDisplayName', 'serieNavigation']) ||
            !CompareImmutable(this.props.title, nextProps.title, ['id']);
    }

    static contextType = MessagesContext;

    render() {
        let releaseDateText = this.context.intl.messages['title.header.release-date'];
        if (TitleConstants.MADE_FOR_TYPES[this.props.title.get('madeForType')] === 'TV') {
            releaseDateText = this.context.intl.messages['title.header.broadcast-premiere'];
        }

        let episodeCount;
        let mpmNumber;
        let title;

        if (SessionStore.isSingleTitle()) {
            episodeCount = this.props.title.get('episodeCount', '-');
        } else {
            episodeCount = this.props.serieNavigation.get('episodes', Immutable.List()).size || '-';
        }

        if (this.props.title.get('defaultImageTitleTreatmentId')) {
            title = (
                <>
                    <img
                        alt={this.props.title.get('displayName')}
                        src={this.props.title.get('defaultImageTitleTreatmentPreviewUrl')}
                    />
                    {this.props.editInBrainiac}
                </>
            );
        } else {
            title = (
                <h2 className="h1">
                    <strong>
                        {this.props.title.get('displayName')}
                    </strong>
                    {this.props.editInBrainiac}
                </h2>
            );
        }

        if (this.props.title.get('mpmNumber')) {
            mpmNumber = <>&nbsp;|&nbsp;{this.context.intl.messages['common.mpm']}: <strong>{this.props.title.get('mpmNumber')}</strong></>;
        }

        let fullReleaseDateText;
        if (this.props.canViewReleaseDate) {
            let rd = this.props.releaseDateDisplayName || this.props.releaseDate;
            fullReleaseDateText =<>&nbsp;|&nbsp;{releaseDateText}: <strong>{FormatReleaseDate(rd, this.props.releaseDateType)}</strong></>;
        }

        return (
            <div className="title-header-text">
                {title}
                <p className="padding-bottom-0">
                    <strong>{this.props.title.get('categoryName')}</strong>
                    &nbsp;|&nbsp;
                    {this.context.intl.messages['title.header.total-episodes']}: <strong>{episodeCount}</strong>
                    {fullReleaseDateText}
                    {this.props.akas}
                    {mpmNumber}
                </p>
                {this.props.children}
            </div>
        );
    }
}

class SerieHeader extends Component {
    static get propTypes() {
        return {
            akas: PropTypes.string,
            canViewReleaseDate: PropTypes.bool,
            children: PropTypes.node,
            editInBrainiac: PropTypes.object,
            releaseDate: PropTypes.string,
            releaseDateType: PropTypes.number,
            releaseDateDisplayName: PropTypes.string,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            akas: undefined,
            canViewReleaseDate: false,
            children: undefined,
            editInBrainiac: undefined,
            releaseDate:undefined,
            releaseDateType: undefined,
            releaseDateDisplayName: undefined,
        };
    }

    shouldComponentUpdate(nextProps) {
        return !Compare(this.props, nextProps, ['children', 'releaseDate', 'releaseDateType', 'releaseDateDisplayName', 'serieNavigation']) ||
            !CompareImmutable(this.props.title, nextProps.title, ['id']);
    }

    static contextType = MessagesContext;

    render() {
        let releaseDateText = this.context.intl.messages['title.header.release-date'];
        if (TitleConstants.MADE_FOR_TYPES[this.props.title.get('madeForType')] === 'TV') {
            releaseDateText = this.context.intl.messages['title.header.broadcast-premiere'];
        }

        let episodeCount, seasonCount, totalSeasons;
        if (SessionStore.isSingleTitle()) {
            episodeCount = this.props.title.get('episodeCount', '-');
        } else {
            episodeCount = this.props.serieNavigation.get('episodeCount', '-');
            seasonCount = this.props.serieNavigation.get('seasons', Immutable.List()).size || '-';
            totalSeasons = <>&nbsp;|&nbsp;{this.context.intl.messages['title.header.total-seasons']}: <strong>{seasonCount}</strong></>;
        }

        let mpmNumber;

        // for now
        let title;

        if (this.props.title.get('defaultImageTitleTreatmentId')) {
            title = (
                <h2 className="h1">
                    <img
                        alt={this.props.title.get('displayName')}
                        src={this.props.title.get('defaultImageTitleTreatmentPreviewUrl')}
                    />
                    {this.props.editInBrainiac}
                </h2>
            );
        } else {
            title = (
                <h2 className="h1">
                    <strong>
                        {this.props.title.get('displayName')}
                    </strong>
                    {this.props.editInBrainiac}
                </h2>
            );
        }

        if (this.props.title.get('mpmNumber')) {
            mpmNumber = <>&nbsp;|&nbsp;{this.context.intl.messages['common.mpm']}: <strong>{this.props.title.get('mpmNumber')}</strong></>;
        }

        let fullReleaseDateText;
        if (this.props.canViewReleaseDate) {
            let rd = this.props.releaseDateDisplayName || this.props.releaseDate;
            fullReleaseDateText =<>&nbsp;|&nbsp;{releaseDateText}: <strong>{FormatReleaseDate(rd, this.props.releaseDateType)}</strong></>;
        }

        return (
            <div className="title-header-text">
                {title}
                <p className="padding-bottom-0">
                    <strong>{this.props.title.get('categoryName')}</strong>
                    {totalSeasons}
                    &nbsp;|&nbsp;
                    {this.context.intl.messages['title.header.total-episodes']}: <strong>{episodeCount}</strong>
                    {fullReleaseDateText}
                    {this.props.akas}
                    {mpmNumber}
                </p>
                {this.props.children}
            </div>
        );
    }
}

class FormatHeader extends Component {
    // No way to calculate episode count
    // at least not a bullet-proof one
    // nor quick :(
    // so i refuse to add episode count as field here.
    static get propTypes() {
        return {
            akas: PropTypes.string,
            canViewReleaseDate: PropTypes.bool,
            editInBrainiac: PropTypes.object,
            releaseDate: PropTypes.string,
            releaseDateType: PropTypes.number,
            releaseDateDisplayName: PropTypes.string,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            akas: undefined,
            canViewReleaseDate: false,
            editInBrainiac: undefined,
            releaseDate:undefined,
            releaseDateType: undefined,
            releaseDateDisplayName: undefined,
        };
    }

    shouldComponentUpdate(nextProps) {
        return !Compare(this.props, nextProps, ['releaseDate', 'releaseDateType', 'releaseDateDisplayName']) ||
            !CompareImmutable(this.props.title, nextProps.title, ['id']);
    }

    static contextType = MessagesContext;

    render() {
        let mpmNumber;
        let releaseDateText = this.context.intl.messages['title.header.release-date'];
        if (TitleConstants.MADE_FOR_TYPES[this.props.title.get('madeForType')] === 'TV') {
            releaseDateText = this.context.intl.messages['title.header.broadcast-premiere'];
        }

        let title = (
            <h2 className="h1">
                <strong>
                    {this.props.title.get('displayName')}
                </strong>
                {this.props.editInBrainiac}
            </h2>
        );

        if (this.props.title.get('defaultImageTitleTreatmentId')) {
            title = (
                <>
                    <img
                        alt={this.props.title.get('displayName')}
                        src={this.props.title.get('defaultImageTitleTreatmentPreviewUrl')}
                    />
                    {this.props.editInBrainiac}
                </>
            );
        }

        if (this.props.title.get('mpmNumber')) {
            mpmNumber = <>&nbsp;|&nbsp;{this.context.intl.messages['common.mpm']}: <strong>{this.props.title.get('mpmNumber')}</strong></>;
        }

        let fullReleaseDateText;
        if (this.props.canViewReleaseDate) {
            let rd = this.props.releaseDateDisplayName || this.props.releaseDate;
            fullReleaseDateText =<>&nbsp;|&nbsp;{releaseDateText}: <strong>{FormatReleaseDate(rd, this.props.releaseDateType)}</strong></>;
        }

        return (
            <div className="title-header-text">
                {title}
                <p className="padding-bottom-0">
                    <strong>{this.props.title.get('categoryName')}</strong>
                    {fullReleaseDateText}
                    {this.props.akas}
                    {mpmNumber}
                </p>
            </div>
        );
    }
}

class SingleReleaseHeader extends Component {
    static get propTypes() {
        return {
            akas: PropTypes.string,
            canViewReleaseDate: PropTypes.bool,
            editInBrainiac: PropTypes.object,
            releaseDate: PropTypes.string,
            releaseDateType: PropTypes.number,
            releaseDateDisplayName: PropTypes.string,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            akas: undefined,
            canViewReleaseDate: false,
            editInBrainiac: undefined,
            releaseDate:undefined,
            releaseDateType: undefined,
            releaseDateDisplayName: undefined,
        };
    }

    shouldComponentUpdate(nextProps) {
        return !Compare(this.props, nextProps, ['releaseDate', 'releaseDateType', 'releaseDateDisplayName']) ||
            !CompareImmutable(this.props.title, nextProps.title, ['id']);
    }

    static contextType = MessagesContext;

    render() {
        let mpmNumber;
        let releaseDateText = this.context.intl.messages['title.header.release-date'];
        let title;

        switch (true) {
        case TitleConstants.MADE_FOR_TYPES[this.props.title.get('madeForType')] === 'TV':
            releaseDateText = this.context.intl.messages['title.header.broadcast-premiere'];
            break;
        case this.props.title.get('category') === TitleConstants.TITLE_TYPES.EVENT.id:
            releaseDateText = this.context.intl.messages['title.header.date'];
            break;
        }

        if (this.props.title.get('defaultImageTitleTreatmentId')) {
            title = (
                <>
                    <img
                        alt={this.props.title.get('displayName')}
                        src={this.props.title.get('defaultImageTitleTreatmentPreviewUrl')}
                    />
                    {this.props.editInBrainiac}
                </>
            );
        } else {
            title = (
                <h2 className="h1">
                    <strong>
                        {this.props.title.get('displayName')}
                    </strong>
                    {this.props.editInBrainiac}
                </h2>
            );
        }

        if (this.props.title.get('mpmNumber')) {
            mpmNumber = <>&nbsp;|&nbsp;{this.context.intl.messages['common.mpm']}: <strong>{this.props.title.get('mpmNumber')}</strong></>;
        }

        let fullReleaseDateText;
        if (this.props.canViewReleaseDate) {
            let rd = this.props.releaseDateDisplayName || this.props.releaseDate;
            fullReleaseDateText =<>&nbsp;|&nbsp;{releaseDateText}: <strong>{FormatReleaseDate(rd, this.props.releaseDateType)}</strong></>;
        }

        return (
            <div className="title-header-text">
                {title}
                <p className="padding-bottom-0">
                    <strong>{this.props.title.get('categoryName')}</strong>
                    {fullReleaseDateText}
                    {this.props.akas}
                    {mpmNumber}
                </p>
            </div>
        );
    }
}

class Header extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            releaseDate: PropTypes.string,
            releaseDateType: PropTypes.number,
            releaseDateDisplayName: PropTypes.string,
            serieNavigation: PropTypes.instanceOf(Immutable.Map),
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            networks: PropTypes.string,
            permissions: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            networks: '-',
            releaseDate: undefined,
            releaseDateDisplayName: undefined,
            releaseDateType: undefined,
            serieNavigation: undefined
        };
    }

    static calculateState() {
        return {
            canEditBrainiac: SessionStore.canUser(SessionStore.PERMISSIONS.EDIT_IN_BRAINIAC),
            canViewReleaseDate: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_RELEASE_DATE)
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            isVideoMuted: true
        }, this.constructor.calculateState());

        this.toggleMute = this.toggleMute.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            return true;
        }
        return nextState.isVideoMuted !== this.state.isVideoMuted ||
            !Compare(this.props, nextProps, ['serieNavigation', 'location', 'releaseDate', 'releaseDateType', 'releaseDateDisplayName']) ||
            !CompareImmutable(this.props.title, nextProps.title, ['id', 'name', 'defaultImageBannerFullResolutionUrl']);
    }

    static contextType = MessagesContext;

    toggleMute() {
        this.setState(prevState => {
            return {
                isVideoMuted: !prevState.isVideoMuted
            };
        });
    }

    render() {
        let cover;
        let image = this.props.title.get('defaultImageBannerFullResolutionUrl');
        let video = this.props.title.get('defaultVideoBannerURL');
        if (video &&
            (this.props.location.pathname === `/titles/${this.props.title.get('id')}` ||
            (SessionStore.isSingleTitle() && this.props.location.pathname === '/'))
        ) {
            let videoBtns;
            if (this.props.title.get('defaultVideoBannerHasAudio')) {
                videoBtns = (
                    <div className="video-banner-container">
                        <div className="video-banner-btns">
                            <button className={ClassNames('glyphicon', {'glyphicon-volume-up': !this.state.isVideoMuted, 'glyphicon-volume-off': this.state.isVideoMuted})} onClick={this.toggleMute} />
                        </div>
                    </div>
                );
            }
            cover = (
                <div>
                    <video
                        autoPlay
                        className={style.video}
                        loop
                        muted={this.state.isVideoMuted}
                        playsInline
                    >
                        <source
                            src={video}
                            type="video/mp4"
                        />
                    </video>
                    {videoBtns}
                </div>
            );
        } else if (
            image &&
            this.props.location.pathname === `/titles/${this.props.title.get('id')}`
        ) {
            cover = (
                <ImageLoader
                    alt=""
                    className="img-responsive"
                    onLoad={this.onLoadCover}
                    src={image}
                />
            );
        }

        let masteringInfo;
        if (this.props.location.pathname === `/titles/${this.props.title.get('id')}/videos`) {
            masteringInfo = (
                <p className="padding-bottom-0">
                    {this.context.intl.messages['title.header.network']}: <strong>{this.props.networks}</strong>
                    &nbsp;|&nbsp;
                    {this.context.intl.messages['title.header.production-company']}: <strong>{this.props.title.get('presentationCredits')}</strong>
                    &nbsp;|&nbsp;
                    {this.context.intl.messages['title.header.copyright-year']}: <strong>{this.props.title.get('copyrightYear')}</strong>
                    &nbsp;|&nbsp;
                    {this.context.intl.messages['title.header.action-type']}: <strong>{TitleConstants.ACTION_TYPES[this.props.title.get('actionType')]}</strong>
                </p>
            );
        }

        let editInBrainiac;
        if (this.state.canEditBrainiac) {
            editInBrainiac = (
                <a
                    aria-label={this.context.intl.messages['title.cta.edit']}
                    href={`${Config.BrainiacBaseUrl}/titles/${this.props.title.get('id')}`}
                    target="_blank"
                >
                    <span className="far fa-edit" />
                </a>
            );
        }

        let akas = [];
        let akasText;
        if (this.props.title.get('aka1')) {
            akas.push(this.props.title.get('aka1'));
        }
        if (this.props.title.get('aka2')) {
            akas.push(this.props.title.get('aka2'));
        }
        if (this.props.title.get('pka1')) {
            akas.push(this.props.title.get('pka1'));
        }
        if (this.props.title.get('pka2')) {
            akas.push(this.props.title.get('pka2'));
        }
        if (this.props.title.get('ika1')) {
            akas.push(this.props.title.get('ika1'));
        }
        if (this.props.title.get('ika2')) {
            akas.push(this.props.title.get('ika2'));
        }
        if (akas.length) {
            akasText = <>&nbsp;|&nbsp;{this.context.intl.messages['common.aka']}: <strong>{akas.join(', ')}</strong></>;
        }

        let header;
        const headerProps = {
            ...this.props,
            akas: akasText,
            canViewReleaseDate: this.state.canViewReleaseDate,
            editInBrainiac
        };
        switch (this.props.title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
            header = <MiniSerieHeader {...headerProps}>
                {masteringInfo}
            </MiniSerieHeader>;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            header = <SerieHeader {...headerProps}>
                {masteringInfo}
            </SerieHeader>;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            header = <SeasonHeader {...headerProps}>
                {masteringInfo}
            </SeasonHeader>;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            header = <EpisodeHeader {...headerProps}>
                {masteringInfo}
            </EpisodeHeader>;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
            header = <SingleReleaseHeader {...headerProps}/>;
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.FORMAT_RIGHTS:
            header = <FormatHeader {...headerProps}/>;
            break;
        }

        return (
            <div className="title-header">
                <Grid>
                    {cover}
                    {header}
                </Grid>
            </div>
        );
    }
}

export default Header;
