/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {SUBSCRIPTION_DOCUMENT_TYPES} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

import ListSubscriptions from './list-subscriptions';
import {SubscriptionActions, SubscriptionConstants} from './subscription-actions';
import SubscriptionStore from './subscription-store';
import DocumentTitle from '../../../common/document-title';
import WithPermissions from '../../../decorators/with-permissions';
import {MessagesContext} from '../../../messages/messages-context';
import SessionStore from '../../../session/session-store';
import {TitleConstants} from '../../../titles/title-actions';

class Subscriptions extends Component {

    static get propTypes() {
        return {
            match: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            authUser: SessionStore.getState().get('authUser').get('id'),
            defaults: { // same size/offset criteria as Order(s); may be tweaked as needed
                sortFieldName: 'subscription_date',
                sortDirection: 'desc',
                size: 10,
                offset: 0
            },
            subscriptions: SubscriptionStore.getState().get('subscriptions'),
            toUnsubscribeClient: SubscriptionStore.getState().get('toUnsubscribeClient'),
        };
    }

    static getPermissions() {
        return {
            canViewDocumentsList: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.DOCUMENTS.STATION_DOCUMENTS)
        };
    }

    static getStores() {
        return [SubscriptionStore, SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.removeSubscription = this.removeSubscription.bind(this);
    }

    componentDidMount() {
        for (let subscriptionContentType in TitleConstants.SUBSCRIPTION_CONTENT_TYPES) {
            // each content type will have its own unique fetch request
            SubscriptionActions.getSubscriptions(
                this.state.authUser,
                [TitleConstants.SUBSCRIPTION_CONTENT_TYPES[subscriptionContentType]],
                this.state.defaults.sortFieldName,
                this.state.defaults.sortDirection,
                this.state.defaults.size,
                this.state.defaults.offset
            );
        }
        return;
    }

    static contextType = MessagesContext;

    handleLoadPage(user, subscriptionContentTypes, sortFieldName, sortDirection, size, offset) {
        SubscriptionActions.getSubscriptions(
            user,
            subscriptionContentTypes,
            sortFieldName,
            sortDirection,
            size,
            offset
        );
    }

    handleSortChange(user, subscriptionContentTypes, sortFieldName, sortDirection, size, offset) {
        SubscriptionActions.getSubscriptions(
            user,
            subscriptionContentTypes,
            sortFieldName,
            sortDirection,
            size,
            offset
        );
    }

    removeSubscription() {
        SubscriptionActions.doRemoveSubscription(this.state.toUnsubscribeClient);
    }

    render() {
        let subscriptions = this.state.subscriptions;

        let stationDocSections;
        if (this.props.permissions.canViewDocumentsList) {
            stationDocSections = SUBSCRIPTION_DOCUMENT_TYPES.map(ct => {
                const contentTypeId = ct.contentTypeId.toString();
                return <div key={`subscription-document-content-type-${contentTypeId}`}>
                    <ListSubscriptions
                        label={ct.name}
                        icon={'fas fa-file-alt'}
                        subscriptionTarget={Immutable.fromJS(this.state.authUser)}
                        subscriptionContentType={contentTypeId}
                        subscriptions={subscriptions.get(contentTypeId)?.get('elements')}
                        onPageChange={this.handleLoadPage}
                        onSortChange={this.handleSortChange}
                        sortDirection={this.state.defaults.sortDirection}
                        sortFieldName={this.state.defaults.sortFieldName}
                        offset={subscriptions.get(contentTypeId)?.get('offset', this.state.defaults.offset)}
                        size={this.state.defaults.size}
                        total={subscriptions.get(contentTypeId)?.get('totalCount', 0)}
                    />
                    <hr />
                </div>;
            });
        }

        return (
            <DocumentTitle
                message="document-titles.my-account.subscriptions"
            >
                <div id="Subscriptions" className={ClassNames('tab-pane fade', {'in active': this.props.match.path === '/account/subscriptions'})}>
                    <Modal show={!!this.state.toUnsubscribeClient} onHide={SubscriptionActions.cancelRemoveSubscription}>
                        <Modal.Header className="bg-gray" closeButton>
                            <Modal.Title className="modal-title text-center">{this.context.intl.messages['orders.tab.subscriptions.confirm-unsubscribe-client.dialog.title']}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{this.context.intl.messages['orders.tab.subscriptions.confirm-unsubscribe-client.dialog.description']}</p>
                        </Modal.Body>
                        <Modal.Footer className=" padding-all-20">
                            <Button bsStyle="primary" className="pull-right" onClick={this.removeSubscription}>{this.context.intl.messages['common.unsubscribe']}</Button>
                            <Button bsStyle="default" className="btn-secondary margin-right-5 pull-left" onClick={SubscriptionActions.cancelRemoveSubscription}>{this.context.intl.messages['common.close']}</Button>
                        </Modal.Footer>
                    </Modal>

                    <ListSubscriptions
                        label={this.context.intl.messages['account.tab.subscriptions.all-videos']}
                        icon={'far fa-video'}
                        subscriptionTarget={Immutable.fromJS(this.state.authUser)}
                        subscriptionContentType={SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.ALL_VIDEOS.id}
                        subscriptions={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.ALL_VIDEOS.id.toString())?.get('elements')}
                        onPageChange={this.handleLoadPage}
                        onSortChange={this.handleSortChange}
                        sortDirection={this.state.defaults.sortDirection}
                        sortFieldName={this.state.defaults.sortFieldName}
                        offset={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.ALL_VIDEOS.id.toString())?.get('offset', this.state.defaults.offset)}
                        size={this.state.defaults.size}
                        total={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.ALL_VIDEOS.id.toString())?.get('totalCount', 0)}
                    />
                    <ListSubscriptions
                        label={this.context.intl.messages['account.tab.subscriptions.images']}
                        icon={'far fa-image'}
                        subscriptionTarget={Immutable.fromJS(this.state.authUser)}
                        subscriptionContentType={SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.IMAGES.id}
                        subscriptions={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.IMAGES.id.toString())?.get('elements')}
                        onPageChange={this.handleLoadPage}
                        onSortChange={this.handleSortChange}
                        sortDirection={this.state.defaults.sortDirection}
                        sortFieldName={this.state.defaults.sortFieldName}
                        offset={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.IMAGES.id.toString())?.get('offset', this.state.defaults.offset)}
                        size={this.state.defaults.size}
                        total={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.IMAGES.id.toString())?.get('totalCount', 0)}
                    />
                    <hr />
                    <ListSubscriptions
                        label={this.context.intl.messages['account.tab.subscriptions.full-episodes']}
                        icon={'far fa-play-circle'}
                        subscriptionTarget={Immutable.fromJS(this.state.authUser)}
                        subscriptionContentType={SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.FULL_EPISODES.id}
                        subscriptions={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.FULL_EPISODES.id.toString())?.get('elements')}
                        onPageChange={this.handleLoadPage}
                        onSortChange={this.handleSortChange}
                        sortDirection={this.state.defaults.sortDirection}
                        sortFieldName={this.state.defaults.sortFieldName}
                        offset={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.FULL_EPISODES.id.toString())?.get('offset', this.state.defaults.offset)}
                        size={this.state.defaults.size}
                        total={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.FULL_EPISODES.id.toString())?.get('totalCount', 0)}
                    />
                    <hr />
                    <ListSubscriptions
                        label={this.context.intl.messages['account.tab.subscriptions.vam-delivery']}
                        icon={'fas fa-vhs'}
                        subscriptionTarget={Immutable.fromJS(this.state.authUser)}
                        subscriptionContentType={SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.VAM_DELIVERY.id}
                        subscriptions={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.VAM_DELIVERY.id.toString())?.get('elements')}
                        onPageChange={this.handleLoadPage}
                        onSortChange={this.handleSortChange}
                        sortDirection={this.state.defaults.sortDirection}
                        sortFieldName={this.state.defaults.sortFieldName}
                        offset={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.VAM_DELIVERY.id.toString())?.get('offset', this.state.defaults.offset)}
                        size={this.state.defaults.size}
                        total={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.VAM_DELIVERY.id.toString())?.get('totalCount', 0)}
                    />
                    <hr />
                    <ListSubscriptions
                        label={this.context.intl.messages['account.tab.subscriptions.episodic-delivery']}
                        icon={'glyphicon glyphicon-film'}
                        subscriptionTarget={Immutable.fromJS(this.state.authUser)}
                        subscriptionContentType={SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.EPISODIC_DELIVERY.id}
                        subscriptions={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.EPISODIC_DELIVERY.id.toString())?.get('elements')}
                        onPageChange={this.handleLoadPage}
                        onSortChange={this.handleSortChange}
                        sortDirection={this.state.defaults.sortDirection}
                        sortFieldName={this.state.defaults.sortFieldName}
                        offset={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.EPISODIC_DELIVERY.id.toString())?.get('offset', this.state.defaults.offset)}
                        size={this.state.defaults.size}
                        total={subscriptions.get(SubscriptionConstants.SUBSCRIPTION_CONTENT_TYPES.EPISODIC_DELIVERY.id.toString())?.get('totalCount', 0)}
                    />
                    <hr />
                    {stationDocSections}
                </div>
            </DocumentTitle>
        );
    }

}

export default WithPermissions(Container.create(Subscriptions));

export {Subscriptions};
