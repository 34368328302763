import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class DropdownMenu extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.object.isRequired,
            className: PropTypes.string,
            title: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            className: '',
            title: ''
        };
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <li className="dropdown" >
                <a
                    aria-expanded="false"
                    aria-haspopup="true"
                    className={ClassNames('dropdown-toggle', this.props.className)}
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    tabIndex="0"
                >
                    {this.props.title} <span className="caret"/>
                </a>
                <ul className="dropdown-menu">
                    <form>
                        {this.props.children}
                    </form>
                </ul>
            </li>
        );
    }
}
