/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Grid, Panel} from 'react-bootstrap';

import {MessagesContext} from '../../messages/messages-context';
require('./thanks.less');

export default class Thanks extends React.Component {
    static contextType = MessagesContext;

    render() {
        return (
            <Grid>
                <Panel className="thanks-panel panel-clean">
                    <h2>{this.context.intl.messages['signup.thank-you.title']}</h2>
                    <p>{this.context.intl.messages['signup.thank-you.text']}</p>
                </Panel>
            </Grid>
        );
    }
}
