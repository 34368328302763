/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {ProgressBar} from 'react-bootstrap';
import {Prompt} from 'react-router';
import {Link} from 'react-router-dom';

import {DownloadActions, DownloadConstants} from './download-actions';
import {default as DownloadAnimation} from './download-animation.gif';
import DownloadStore from './download-store';
import {MessagesContext} from '../../messages/messages-context';
import {RouterActions} from '../../router/router-actions';
import {TitleActions} from '../../titles/title-actions';
import {IsNotTriggerKey} from '../../utils/utils';
import DocumentTitle from '../document-title';

export class Download extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
            showId: PropTypes.object.isRequired,
            type: PropTypes.string.isRequired,
        };
    }

    static calculateState() {
        return {
            downloadExecution: DownloadStore.getState().get('downloadExecution')
        };
    }

    static getStores() {
        return [DownloadStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleDownload = this.handleDownload.bind(this);
        this.shouldPrompt = this.shouldPrompt.bind(this);
        this.showMessage = this.showMessage.bind(this);

        return;
    }

    componentDidMount() {
        if (!this.state.downloadExecution.get('id')) {
            RouterActions.back();
        }

        return;
    }

    componentDidUpdate(prevProps, prevState) {
        // This means the file is ready! Download it!
        if (
            this.state.downloadExecution.get('status') === DownloadConstants.EXECUTION_STATUS.COMPLETED &&
            prevState.downloadExecution.get('status') === DownloadConstants.EXECUTION_STATUS.RUNNING &&
            this.state.downloadExecution.get('fileName') !== prevState.downloadExecution.get('fileName')
        ) {
            this.handleDownload();
        }

        return;
    }

    componentWillUnmount() {
        DownloadActions.clear();
        TitleActions.cancelDownload();
    }

    static contextType = MessagesContext;

    handleDownload(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        DownloadActions.downloadExecutionZip(this.state.downloadExecution.get('id'));
    }

    shouldPrompt() {
        return (
            this.state.downloadExecution.get('status') === DownloadConstants.EXECUTION_STATUS.RUNNING
        );
    }

    showMessage(location, action) {
        let result = this.context.intl.messages['download.in-progress'];
        if (action === 'REPLACE' && location.pathname === this.props.location.pathname) {
            result = true;
        }
        return result;
    }

    render() {
        let progress;
        let progressDescription;
        let preparingZip;
        const {downloadExecution} = this.state;

        const percent = Math.min(
            Math.round(downloadExecution.get('processed') * 100 / downloadExecution.get('total')),
            100
        );
        progressDescription = <span className="h3" style={{display: 'block'}}>{this.context.intl.messages['download.progress.text']} {percent}%</span>;

        if (this.state.downloadExecution.get('preparingZip')) {
            const progressPadding = ' '.repeat(this.state.downloadExecution.get('statusCounter'));
            const progressDots = '.'.repeat(this.state.downloadExecution.get('statusCounter'));

            let preparingZipText = '';
            if (!this.state.downloadExecution.get('fileName')) {
                preparingZipText = `${progressPadding}${this.context.intl.messages['download.progress.preparing-zip']}${progressDots}`;
            }
            preparingZip = <span className="Whs(pre) h4">{preparingZipText}</span>;

            progressDescription = (
                <>
                    {progressDescription}
                    {preparingZip}
                </>
            );
        }

        if (this.state.downloadExecution.get('total')) {
            progress = (
                <div>
                    <ProgressBar striped bsStyle="success" now={this.state.downloadExecution.get('processed') * 100 / this.state.downloadExecution.get('total', 1)} />
                    {progressDescription}
                </div>
            );
        }

        let alternativeDownload;
        if (this.state.downloadExecution.get('fileName')) {
            alternativeDownload = (
                <p>
                    <em>
                        {this.context.intl.messages['download.alternative-download.text']}
                        <button
                            className="auth-link btn-link pl-0"
                            onClick={this.handleDownload}
                            onKeyUp={this.handleDownload}
                            style={{paddingRight: 0}}
                        >
                            {this.context.intl.messages['download.alternative-download.click']}
                        </button>.
                    </em>
                </p>
            );
        }

        const goBackToHome = (
            <p>{this.context.intl.messages['download.return.home.page.phrase.1']}&nbsp;<Link to="/">{this.context.intl.messages['download.return.home.page.phrase.2']}&nbsp;</Link>{this.context.intl.messages['download.return.home.page.phrase.3']}</p>
        );

        let downloadId;
        if (this.props.showId) {
            downloadId = this.props.match.params.id;
        }

        // FIXME: This snippet was added 'cause just Downloading All Fact Sheets
        //  had a section header. It should be removed once each section has its
        //  title leaving just the h4 tag.
        let typeTitle = (<h2 className="margin-top-0">{this.context.intl.messages['download.preparing-files.title']}</h2>);
        /* istanbul ignore else */
        if (this.context.intl.messages[`download.title.${this.props.type}`] !== undefined) {
            typeTitle = (<h4 className="h2 margin-top-0">{this.context.intl.messages['download.preparing-files.title']}</h4>);

            if (this.props.type === 'package') {
                typeTitle = (<h3 className="h2 margin-top-0">{this.context.intl.messages['download.preparing-files.title']}</h3>);
            }
        }

        // FIXME: This snippet was added 'cause just Downloading Packages should
        //  had a different section header tag. It should be removed once each
        //  section has its title leaving just the h4 tag.
        let sectionTitle = '';
        /* istanbul ignore else */
        if (this.context.intl.messages[`download.title.${this.props.type}`] !== undefined) {
            sectionTitle = (<h3 className="h1"><strong>{this.context.intl.messages[`download.title.${this.props.type}`]}{downloadId}</strong></h3>);

            if (this.props.type === 'package') {
                sectionTitle = (<h2 className="h1"><strong>{this.context.intl.messages[`download.title.${this.props.type}`]}{downloadId}</strong></h2>);
            }
        }

        return (
            <DocumentTitle message="document-titles.common-download">
                {this.state.downloadExecution.get('running') === true &&
                <Prompt
                    when={ this.shouldPrompt }
                    message={ this.showMessage }
                />
                }

                <div>
                    <div className="title-header">
                        <div className="container">
                            <div className="title-header-text text-center">{sectionTitle}</div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3"/>
                            <div className="col-md-6">
                                <div className="text-center">
                                    <img
                                        alt={this.context.intl.messages['download.preparing-files.text']}
                                        className="img-responsive"
                                        src={DownloadAnimation}
                                    />
                                </div>
                                <div className="text-center">
                                    {typeTitle}
                                    <p>{this.context.intl.messages['download.preparing-files.text']}</p>
                                </div>
                                <hr className="margin-y-40"/>
                                <div className="text-center" style={{'minHeight': '96px'}}>
                                    {progress}
                                    {alternativeDownload}
                                    {goBackToHome}
                                </div>
                            </div>
                            <div className="col-md-3"/>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default (type, showId = false) => {
    const ComposedComponent = Container.create(Download);

    return function WithTypeAndShowId(props) {
        return (<ComposedComponent {...props} type={type} showId={showId} />);
    };
};
