/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {createApi} from '@reduxjs/toolkit/query/react';
import {LoginContent} from '@wbdt-sie/brainiac-web-common';

import {BaseQueryWithPartnerKey} from '../../redux/rtk-query';

type LoginContentType = typeof LoginContent;
export default createApi({
    reducerPath: 'landingAPI',
    baseQuery: BaseQueryWithPartnerKey,
    endpoints: (builder) => ({
        getLoginContent: builder.query<LoginContentType[], void>({
            query: () => ({url: 'web/login-content'}),
            providesTags: () => {
                return [{type: 'LoginContent'}];
            }
        }),
    }),
    tagTypes: ['LoginContent']
});
