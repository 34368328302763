/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';

import ListOrders from './list-orders';
import DocumentTitle from '../../common/document-title';
import {MessagesContext} from '../../messages/messages-context';
import {RouterActions} from '../../router/router-actions';
import SessionStore from '../../session/session-store';
import {Compare} from '../../utils/utils';
import StatusChangeModal from '../../work-orders/status-change-modal';
import {WorkOrderConstants} from '../../work-orders/work-order-actions';
import {OrderActions} from '../order-actions';
import OrderStore from '../order-store';

class Metadata extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            myOrderMetadata: OrderStore.getState().get('myOrderMetadata'),
            statusChange: OrderStore.getState().get('statusChange'),
            totalCount: OrderStore.getState().get('totalCount'),
            users: OrderStore.getState().get('users')
        };
    }

    static getStores() {
        return [OrderStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showArchived: false
        }, this.constructor.calculateState());
        this.handleSortChange = this.handleSortChange.bind(this);
        this.hideStatusChange = this.hideStatusChange.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    }

    componentDidMount() {
        const query = QueryString.parse(this.props.location.search);
        OrderActions.clear();
        this.getMetadataOrder(query);
        return;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        const query = QueryString.parse(this.props.location.search);
        const nextQuery = QueryString.parse(nextProps.location.search);

        if (query['sort-field-name'] !== nextQuery['sort-field-name'] ||
            query['sort-direction'] !== nextQuery['sort-direction'] ||
            !Compare(this.props.location, nextProps.location, ['search']) ||
            this.state.showArchived !== nextState.showArchived
        ) {
            this.getMetadataOrder(nextQuery, nextState.showArchived);
        }
        return;
    }

    componentWillUnmount() {
        OrderActions.clear();
        return;
    }

    static contextType = MessagesContext;

    getMetadataOrder(query, showArchived) {
        let status = [];

        if (query.active) {
            status.push(WorkOrderConstants.STATUS_BY_NAME.ACTIVE);
        }
        if (query.complete) {
            status.push(WorkOrderConstants.STATUS_BY_NAME.COMPLETE);
        }
        if (query.onHold) {
            status.push(WorkOrderConstants.STATUS_BY_NAME.ONHOLD);
        }

        // If no filter was applied, fall back to the defaults
        if (status.length === 0) {
            status = [
                WorkOrderConstants.STATUS_BY_NAME.ACTIVE,
                WorkOrderConstants.STATUS_BY_NAME.ONHOLD,
                WorkOrderConstants.STATUS_BY_NAME.COMPLETE,
                showArchived ? WorkOrderConstants.STATUS_BY_NAME.ARCHIVED : ''
            ];
        }

        OrderActions.getMetadataOrder(status.length > 0 ? status : defaultStatus, query['sort-field-name'], query['sort-direction']);
    }

    handleSortChange(sortFieldName, sortDirection) {
        const q = QueryString.parse(this.props.location.search);
        let query = Object.assign(
            {},
            q,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    hideStatusChange(success) {
        OrderActions.statusChangeHide();
        if (success) {
            const query = QueryString.parse(this.props.location.search);
            OrderActions.clear();
            this.getMetadataOrder(query);
        }
    }

    onChangeCheckbox() {
        let value = this.state.showArchived;
        this.setState({
            showArchived: !value
        });
    }

    render() {
        let criteria = Object.assign({
            offset: 0,
            size: 40
        }, QueryString.parse(this.props.location.search));
        let columns = ['workOrder', 'packageName', 'workOrderStatus', 'dueDate', 'completeDate', 'download', 'workOrderStatusChange'];

        return (
            <DocumentTitle
                message="document-titles.browse-orders.metadata"
            >
                <div id="Metadata" className={ClassNames('tab-pane fade', {'in active': this.props.location.pathname.match('/metadata')})}>
                    <StatusChangeModal
                        show={!!this.state.statusChange.get('show')}
                        onClose={this.hideStatusChange}
                        orderId={this.state.statusChange.get('orderId')}
                        orders={this.state.myOrderMetadata}
                    />
                    <h3 className="padding-y-10">
                        <span className="glyphicon glyphicon-copy" /> {this.context.intl.messages['orders.tab.my-metadata.title']}
                        <div className="show-archived-packages pull-right">
                            <input type="checkbox"
                                onChange={this.onChangeCheckbox}
                                checked={this.state.showArchived}
                                value="show-archived"/>
                            <small>{this.context.intl.messages['work-orders.show-archived']}</small>
                        </div>
                    </h3>
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                        <ListOrders
                            canDownloadSalesDeck={SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.WORK_ORDERS.DOWNLOAD)}
                            canViewDigitalSalesDeck={SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.WORK_ORDERS.VIEW_DSD_LINK)}
                            columns={columns}
                            location={this.props.location}
                            onSortChange={this.handleSortChange}
                            orders={this.state.myOrderMetadata}
                            sortDirection={criteria['sort-direction']}
                            sortFieldName={criteria['sort-field-name']}
                            total={this.state.totalCount}
                            users={this.state.users}

                        />
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Metadata);
export {Metadata};
