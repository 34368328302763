/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './classic-movies.less';

export default class ClassicMovies extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.classic-movies">
                <div className="container op_container_classic_movies">

                    <div id="op_header" className="row">

                        <div id="op-header-img">
                            <div id="header-btns">
                                <a className="btn-trailer btn-header reel" href="/titles/1842274/videos/1737092" rel="noopener noreferrer" target="_blank"><img src="https://www.wbtvd.com/page/classic-movies/banner_btn_sizzlereel.png" alt="" /></a>
                                <a className="btn-header presentation" href="https://www.wbtvd.com/page/classic-movies/WB_Classic_Movies.pdf" rel="noopener noreferrer" target="_blank"><img src="https://www.wbtvd.com/page/classic-movies/banner_btn_presentation.png" alt="" /></a>
                            </div>
                        </div>
                    </div>

                    <div id="op_content" className="row clearfix">

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/45869" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/2001_A_Space_Odyssey_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/45869/videos/1736982" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/45869/videos/1178389" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/170846" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/A_Clockwork_Orange_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/170846/videos/1736985" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/170846/videos/1451753" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/46181" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/All_the_Presidents_Men_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/46181/videos/1736998" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/46181/videos/1187257" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/46253" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Anna_Karenina_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/46253/videos/1736999" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/46253/videos/1740656" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/46209" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Bridges_of_Madison_County_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/46209/videos/1737069" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/46209/videos/1176592" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/46690" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Bullitt_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/46690/videos/1737056" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/46690/videos/1199769" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/74040" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Casablanca_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/74040/videos/1737057" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/74040/videos/1186557" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/54108" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Deliverance_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/54108/videos/1737058" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/54108/videos/1261854" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/74104" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Dial_M_for_Murder_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/74104/videos/1737059" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/74104/videos/1261839" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/54104" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Dirty_Harry_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/54104/videos/1737061" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/54104/videos/1242230" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/74826" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/East_of_Eden_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/74826/videos/1737062" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/74826/videos/1614260" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/74110" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Empire_of_the_Sun_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/74110/videos/1737063" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/74110/videos/1193783" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/54145" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Eyes_Wide_Shut_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/54145/videos/1737064" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/54145/videos/1201227" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/74204" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Full_Metal_Jacket_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/74204/videos/1737065" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/74204/videos/1177440" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/47054" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Goodfellas_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/47054/videos/1737066" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/47054/videos/980880" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/47359" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/How_the_West_was_Won_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/47359/videos/1737067" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/47359/videos/1740661" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/47483" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Interview_with_the_Vampire_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/47483/videos/1737068" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/47483/videos/1187450" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48168" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Memphis_Belle_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48168/videos/1737071" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/48168/videos/1177445" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/178493" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Mildred_Pierce_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/178493/videos/1737072" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/178493/videos/1178579" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/179084" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Mutiny_on_the_Bounty_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/179084/videos/1737073" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/179084/videos/1740662" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48520" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/North_by_Northwest_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48520/videos/1737074" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/48520/videos/1521636" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48683" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Pale_Rider_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48683/videos/1737075" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/48683/videos/1187573" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48836" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Presumed_Innocent_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48836/videos/1737076" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/48836/videos/1247419" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48990" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Rebel_Without_a_Cause_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48990/videos/1737078" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/48990/videos/1177401" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/46471" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Bodyguard_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/46471/videos/1737000" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/46471/videos/1480509" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/54106" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Color_Purple_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/54106/videos/1737086" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/54106/videos/1177434" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/74265" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Dirty_Dozen_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/74265/videos/1737060" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/74265/videos/978823" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/73998" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Fugitive_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/73998/videos/1737080" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/73998/videos/1178382" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/46978" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Getaway_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/46978/videos/1737084" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/46978/videos/1740657" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/178044" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Maltese_Falcon_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/178044/videos/1737070" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/178044/videos/1247412" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48144" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Matrix_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48144/videos/1737081" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/48144/videos/1186898" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/255365" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Petrified_Forest_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/255365/videos/1737082" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/255365/videos/1740660" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48877" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Public_Enemy_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48877/videos/1737077" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/48877/videos/1740658" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/49288" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Searchers_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/49288/videos/1737079" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/49288/videos/1398949" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/49401" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Shining_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/49401/videos/1737085" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/49401/videos/1186332" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/238348" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Treasure_of_the_Sierra_Madre_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/238348/videos/1737087" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/238348/videos/1740659" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/50293" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Wild_Bunch_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/50293/videos/1737083" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/50293/videos/1261873" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/49453" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/The_Wizard_of_Oz_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/49453/videos/1737090" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/49453/videos/1177392" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/50051" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/Unforgiven_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/50051/videos/1737088" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/50051/videos/1199771" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/257472" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/classic-movies/White_Heat_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/257472/videos/1737089" className="btn btn-gfx gfx_class" rel="noopener noreferrer" target="_blank"><span>&#8227; GFX</span></a><a href="/titles/257472/videos/1388307" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div id="op_footer" className="row">
                            For more information or to order the Classic Movies Programming Package, please email <a href="mailto:Mike.Shackleton@warnerbros.com?subject=Re%3A%20Classic%20Movies%20Programming%20Package">Mike.Shackleton@warnerbros.com</a>
                        </div>
                    </div>
                </div>
            </DocumentTitle>

        );
    }
}
