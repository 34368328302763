/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import addCombinedTalentCharacterFacet from '../../common/facets/utils/add-combined-talent-character-facet';
import {Dispatcher} from '../../flux-helpers';
import Request from '../../request';

const CONSTANTS = {
    FACETS: {
        SUCCESS: 'search_actions.filters.facets.success',
        START: 'search_actions.filters.facets.start',
        ERROR: 'search_actions.filters.facets.error',
    },
};

class SearchFacetsActions {
    /**
     * @param {string} searchTerm
     */
    getFacets(searchTerm) {
        Dispatcher.dispatch({
            actionType: CONSTANTS.FACETS.START,
        });
        Request.get('asset/video-search/facets').query({
            'is-clippable': true,
            'search-term': searchTerm,
        }).then((response) => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.FACETS.SUCCESS,
                data: Immutable.fromJS(addCombinedTalentCharacterFacet(response.body))
            });
        }).catch(() => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.FACETS.ERROR,
            });
        });
    }
}

const actions = new SearchFacetsActions();

export {
    actions as SearchFacetsActions,
    CONSTANTS as SearchFacetsConstants
};

export default SearchFacetsActions;
