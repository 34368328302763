/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import {MessagesContext} from '../../messages/messages-context';
import ImageLoader from '../../titles/asset-title/images/image-loader';


class ZeroContent extends React.Component {

    static contextType = MessagesContext;

    render() {
        return (
            <div>
                <div className="header-search-container"></div>
                <div className="page-content">
                    <div className="title-header">
                        <div className="container">
                            <div className="title-header-text text-center">
                                <h1><strong>{this.context.intl.messages['document-titles.home.no-content.title']}</strong></h1>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="text-center">
                            <div className="row padding-top-30">
                                <div className="col-sm-3">
                                </div>
                                <div className="col-sm-6">
                                    <ImageLoader
                                        alt="Asset Not Found"
                                        className="img-responsive"
                                        src={require('./zero-content-image.svg')}
                                    />
                                </div>
                                <div className="col-sm-3">
                                </div>
                            </div>
                            <div className="row">
                                <div className="text-center padding-y-10">
                                    <h3>{this.context.intl.messages['document-titles.home.no-content.sub-title']}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ZeroContent;
