/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './index.less';

export default class HarryPotterCoreElements extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.harry-potter/core-elements">
                <div className="op_container_harry_potter">
                    <div style={{backgroundColor: '#1b1e22', color: '#FFF'}}>
                        <div className="container padding-all-20">
                            <div className="row margin-bottom-20">
                                <div className="col-md-5"></div>
                                <div className="col-md-2">
                                    <a href="/harry-potter">
                                        <img
                                            src={require('./images/logo-harry-potter.png')}
                                            className="img-responsive"
                                        />
                                    </a>
                                </div>
                                <div className="col-md-5"></div>
                                <div className="col-md-12 text-center" style={{marginTop: '20px'}}>
                                    <a
                                        href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/DESIGN+PACKAGES+AND+OVERVIEW/WW_TV_Toolkit_Design_Package_LoRes.pdf"
                                        className="btn btn-lg btn-gray">
                                        <i className="glyphicon glyphicon-download-alt"></i> Design Package
                                        Overview &amp; Toolkit
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container padding-all-20">
                        <div className="row">
                            <div className="col-md-4 padding-all-20" style={{borderRight: '1px solid #DDD'}}>
                                <img
                                    src={require('./temp-images/ww-animated-core-elements.gif')}
                                    className="img-responsive thumbnail-border"
                                    alt="..."
                                />
                            </div>
                            <div className="col-md-8 padding-all-20">
                                <h1>Core Elements</h1>
                                <hr />
                                <h3 className="h3">Flagrate Font</h3>
                                <p>
                                    The custom &quot;Flagrate&quot; Font is the primary display font used for title
                                    cards, navigation and editorial headlines. Flagrate is only available
                                    in the Roman alphabet. Tune-ins should retain the brand DNA of the
                                    respective networks and should not be set in Flagrate.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/FLAGRATE+FONT/_FLAGRATE_FONT.zip">
                                            Flagrate Font
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">Custom Icons</h3>
                                <p>
                                    Each film Is paired with at least four bespoke icons, representing
                                    storylines and iconic imagery from their respective films. All of the
                                    icons are fully flexible, so that they can be used interchangeably
                                    throughout the design package. The “additional” icons were created
                                    specifically for this reason.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Sorcerer's+Stone.zip">
                                            Harry Potter And The Sorcerer&apos;s Stone / Harry Potter And The
                                            Philosopher&apos;s Stone
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Chamber+of+Secrets.zip">
                                            Harry Potter And The Chamber of Secrets
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Prisoner+of+Azkaban.zip">
                                            Harry Potter And The Prisoner of Azkaban
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Goblet+of+Fire.zip">
                                            Harry Potter And The Goblet of Fire
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Order+of+the+Phoenix.zip">
                                            Harry Potter And The Order of the Phoenix
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Half+Blood+Prince.zip">
                                            Harry Potter And The Half-Blood Prince
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Deathly+Hallows+Part+1.zip">
                                            Harry Potter And The Deathly Hallows, Pt. 1
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Deathly+Hallows+Part+2.zip">
                                            Harry Potter And The Deathly Hallows, Pt. 2
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Harry+Potter+-+Additional.zip">
                                            Harry Potter: Additional
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/CUSTOM+ICONS/Fantastic+Beasts.zip">
                                            Fantastic Beasts
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">Icon Holding Shapes</h3>
                                <p>
                                    Holding Shapes were created for each film and stunt. The previous
                                    Icons were designed to be plugged into their film or stunt&apos;s
                                    respective Holding Shape, prior to being placed on endplates, in
                                    credit squeezes, etc.
                                </p>
                                <h4>
                                    Individual Films
                                    <small>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/CORE-HOLDINGSHAPES-ALL.zip">
                                            <i className="glyphicon glyphicon-download-alt"></i> Download All
                                        </a>
                                    </small>
                                </h4>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/INDIVIDUAL+FILMS/Sorcerer's+Stone.zip">
                                            Harry Potter And The Sorcerer’s Stone / Harry Potter And The
                                            Philosopher’s Stone
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/INDIVIDUAL+FILMS/Chamber+of+Secrets.zip">
                                            Harry Potter And The Chamber of Secrets
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/INDIVIDUAL+FILMS/Prisoner+of+Azkaban.zip">
                                            Harry Potter And The Prisoner of Azkaban
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/INDIVIDUAL+FILMS/Goblet+of+Fire.zip">
                                            Harry Potter And The Goblet of Fire
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/INDIVIDUAL+FILMS/Order+of+the+Phoenix.zip">
                                            Harry Potter And The Order of the Phoenix
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/INDIVIDUAL+FILMS/Half+Blood+Prince.zip">
                                            Harry Potter And The Half-Blood Prince
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/INDIVIDUAL+FILMS/Deathly+Hallows+Part+1.zip">
                                            Harry Potter And The Deathly Hallows, Pt. 1
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/INDIVIDUAL+FILMS/Deathly+Hallows+Part+2.zip">
                                            Harry Potter And The Deathly Hallows, Pt. 2
                                        </a>
                                    </li>
                                </ul>
                                <h4>
                                    Tentpole &amp; Stunt Programming
                                    <small>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/CORE-HOLDINGSHAPES-ALL.zip">
                                            <i className="glyphicon glyphicon-download-alt"></i> Download All
                                        </a>
                                    </small>
                                </h4>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/TENTPOLE+AND+STUNTS+MARATHON/Wizarding+Weekend.zip">
                                            Harry Potter Wizarding Weekend
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/TENTPOLE+AND+STUNTS+MARATHON/Back+to+Hogwarts.zip">
                                            Back to Hogwarts
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/TENTPOLE+AND+STUNTS+MARATHON/Dark+Arts.zip">
                                            Dark Arts
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/TENTPOLE+AND+STUNTS+MARATHON/Christmas+Marathon.zip">
                                            Christmas
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/CORE+ELEMENTS/HOLDING+SHAPES+FOR+ICONS/TENTPOLE+AND+STUNTS+MARATHON/Wizarding+World+Week.zip">
                                            Harry Potter Week
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </DocumentTitle>

        );
    }
}
