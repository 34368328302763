/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';

import Credits from './credits';
import DocumentTitle from '../../common/document-title';
import {MessagesContext} from '../../messages/messages-context';
import SessionStore from '../../session/session-store';
import {AssetTitleConstants} from '../../titles/asset-title/asset-title-actions';
import AssetTitleStore from '../../titles/asset-title/asset-title-store';
import ImageList from '../../titles/asset-title/images/image-list';
import ImageLoader from '../../titles/asset-title/images/image-loader';
import {Compare} from '../../utils/utils';
import {TalentActions} from '../talent-actions';
import TalentStore from '../talent-store';

class TalentInfo extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
        };
    }

    static getPermissions() {
        return {
            canShareTitle: SessionStore.canUser(SessionStore.PERMISSIONS.SHARE.TITLES)
        };
    }

    static calculateState() {
        return {
            talent: TalentStore.getState().get('talent'),
            titles: TalentStore.getState().get('titles'),
            assets: TalentStore.getState().get('assets'),
            imageEntityId: AssetTitleStore.getState().get('entityId'),
            imageEntityType: AssetTitleStore.getState().get('entityType')
        };
    }

    static getStores() {
        return [TalentStore, AssetTitleStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        const query = QueryString.parse(this.props.location.search);
        let parsedId = parseInt(this.props.match.params.id);
        let userId = SessionStore.getState().getIn(['authUser', 'id']);
        if (this.state.imageEntityId !== parsedId || this.state.imageEntityType !== AssetTitleConstants.ENTITY_TYPE.TALENT) {
            TalentActions.get(
                parsedId,
                userId,
                parseInt(query.offset, 10) || 0,
                parseInt(query.size, 10) || 80,
                query.vct
            );
        }
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.match.params.id !== nextProps.match.params.id ||
                !Compare(this.state, nextState, ['talent', 'titles', 'assets', 'imageEntityId', 'imageEntityType']);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        const query = QueryString.parse(nextProps.location.search);
        if (this.props.match.params.id !== nextProps.match.params.id ||
            (nextState.imageEntityType !== AssetTitleConstants.ENTITY_TYPE.TALENT && this.state.imageEntityType === AssetTitleConstants.ENTITY_TYPE.TITLE)) {
            TalentActions.get(
                parseInt(nextProps.match.params.id),
                SessionStore.getState().getIn(['authUser', 'id']),
                parseInt(query.offset, 10) || 0,
                parseInt(query.size, 10) || 80,
                query.vct
            );
        }
    }

    static contextType = MessagesContext;

    render() {
        let bio = '';
        let image;
        if (this.state.talent) {
            bio = this.state.talent.get('bio');
            if (this.state.talent.get('defaultImagePortraitPreviewUrl') !== null) {
                image = this.state.talent.get('defaultImagePortraitPreviewUrl');
            }
        }
        let imageList;
        if (this.state.assets.get('images').size) {
            imageList= (
                <ImageList
                    categoryGroup={this.state.titles.get('categoryGroup')}
                    entityType={AssetTitleConstants.ENTITY_TYPE.TALENT}
                    id={parseInt(this.props.match.params.id)}
                    images={this.state.assets.get('images')}
                />
            );
        }

        return (
            <DocumentTitle
                entityName={this.state.talent.get('fullName')}
                message="document-titles.talent"
            >
                <div className="container padding-y-10 padding-x-10">
                    <div className="row">
                        <div className="col-sm-2 padding-top-10">
                            <div className="responsive-container">
                                <div className="vertical-spacer"/>
                                <div className="img-container">
                                    <ImageLoader
                                        alt="..."
                                        className="img-responsive"
                                        src={image}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-10 padding-x-10">
                            <h3>{this.context.intl.messages['talent.info.biography']}</h3>
                            <p dangerouslySetInnerHTML={{__html: bio}} />
                        </div>
                    </div>
                </div>
                <Credits titles={this.state.titles}/>
                {imageList}
            </DocumentTitle>
        );
    }

}

export default Container.create(TalentInfo);
export {TalentInfo};
