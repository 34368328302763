/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Glyphicon, Row, Carousel} from 'react-bootstrap';

import {MessagesContext} from '../../../messages/messages-context';
import {PlayerActions} from '../../../player/player-actions';
import {HomeConstants, HomeActions} from '../../home-actions';

import './billboard.less';
import 'bootstrap/js/transition';
import 'bootstrap/js/carousel';

/**
 * Position indicator
 */
const Indicators = ({items, currentImageIndex}) => (
    <ol className="carousel-indicators">
        {items.map((item, i) => {
            return (
                <li className={ClassNames({active: i === currentImageIndex})}
                    data-target="#carousel-home-billboard"
                    data-slide-to={i}
                    key={i}
                />
            );
        })}
    </ol>
);

Indicators.propTypes = {
    currentImageIndex: PropTypes.number.isRequired,
    items: PropTypes.instanceOf(Immutable.List).isRequired
};

/**
 * Background image
 */
const Background = ({items, currentImageIndex}) => {
    let item = items.get(currentImageIndex);

    let backgroundImage;
    switch (item.get('targetType')) {
    case HomeConstants.TARGET_TYPE.ASSET.id:
        switch (item.get('displayAssetTypeName')) {
        case 'Image':
            backgroundImage = item.getIn(['thumbnails', 0, 'previewUrl']);
            break;
        case 'Video':
            backgroundImage = item.getIn(['thumbnails', 0, 'thumbnailUrl']);
            break;
        }
        break;
    }

    if (item.get('imageUrl')) {
        backgroundImage = item.get('imageUrl');
    }

    return (
        <div className="billboard-background">
            <div id="billboard-background-image" style={{backgroundImage: `url(${backgroundImage})`}} />
        </div>
    );
};

Background.propTypes = {
    currentImageIndex: PropTypes.number.isRequired,
    items: PropTypes.instanceOf(Immutable.List).isRequired
};

class Item extends React.Component {
    static get propTypes() {
        return {
            active: PropTypes.bool.isRequired,
            buttonIcon: PropTypes.string.isRequired,
            buttonText: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            handleMuteChange: PropTypes.func.isRequired,
            handleVideoEnded: PropTypes.func.isRequired,
            image: PropTypes.string.isRequired,
            internal: PropTypes.bool,
            onClick: PropTypes.func,
            targetUrl: PropTypes.string,
            video: PropTypes.string,
            videoHasAudio: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            internal: false,
            onClick: /*istanbul ignore next */() => void 0,
            targetUrl: '',
            video: '',
            videoHasAudio: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isVideoMuted: true
        };

        this.toggleMute = this.toggleMute.bind(this);
        this.videoEnded = this.videoEnded.bind(this);
        return;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.active) {
            this.setState(/* istanbul ignore next */() => {
                return {
                    isVideoMuted: true
                };
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.image !== nextProps.image ||
            this.props.targetUrl !== nextProps.targetUrl ||
            this.props.video !== nextProps.video ||
            this.state.isVideoMuted !== nextState.isVideoMuted ||
            this.props.active !== nextProps.active
        ) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    toggleMute() {
        this.setState(prevState => {
            this.props.handleMuteChange(!prevState.isVideoMuted);
            return {
                isVideoMuted: !prevState.isVideoMuted
            };
        });
    }

    videoEnded() {
        this.setState(() => {
            return {
                isVideoMuted: true
            };
        });
        this.props.handleVideoEnded();
    }

    render() {
        let button;
        let description = <div className="caption-description-alone" dangerouslySetInnerHTML={{__html: this.props.description}} />;
        let target = '_blank';

        if (this.props.internal) {
            target = '';
        }

        button = (
            <Button bsStyle="primary"
                bsSize="large"
                className="cta"
                onClick={this.props.onClick}>
                <Glyphicon glyph={this.props.buttonIcon} />&nbsp;{this.context.intl.messages[this.props.buttonText]}
            </Button>
        );

        if (this.props.targetUrl) {
            button = (
                <a className="cta btn btn-lg btn-primary"
                    href={this.props.targetUrl}
                    target={target}>
                    <Glyphicon glyph={this.props.buttonIcon} />&nbsp;{this.context.intl.messages[this.props.buttonText]}
                </a>
            );
        }

        let content = (
            <img
                alt=""
                className="carousel-image"
                role="presentation"
                src={this.props.image}
            />
        );
        if (this.props.video) {
            let videoBtns;
            if (this.props.videoHasAudio) {
                videoBtns = (
                    <div className="video-banner-container">
                        <div className="video-banner-btns">
                            <button className={ClassNames('glyphicon', {'glyphicon-volume-up': !this.state.isVideoMuted, 'glyphicon-volume-off': this.state.isVideoMuted})} onClick={this.toggleMute} />
                        </div>
                    </div>
                );
            }
            // Note: all background videos are mp4 by default.
            // We user preload="auto" to automatically download the video.
            content = (
                <div className="homepage-billboard">
                    <video
                        className="carousel-video"
                        autoPlay={true}
                        loop={true}
                        muted={this.state.isVideoMuted}
                        onEnded={this.videoEnded}
                        playsInline
                        poster={this.props.image}
                    >
                        <source
                            src={this.props.video}
                            type="video/mp4"
                        />
                    </video>
                    {videoBtns}
                </div>
            );
        }

        return (
            <div className={ClassNames('item', {active: this.props.active})}>
                <div className="billboard-overlay">
                    <div className="billboard-overlay-container">
                        <div className="text-left billboard-overlay-cell">
                            {description}
                            {button}
                        </div>
                    </div>
                </div>
                {content}
            </div>
        );
    }
}

const List = ({items, currentImageIndex, handleMuteChange, handleVideoEnded}) => {

    const handleClick = (item, event) => {
        event.preventDefault();
        if (
            item.get('targetType') === HomeConstants.TARGET_TYPE.ASSET.id &&
            item.get('displayAssetTypeName') === 'Video'
        ) {
            PlayerActions.showPlayer(item);
        } else {
            HomeActions.gotoItem(item);
        }
    };

    let itemsToShow = items.map((item, i) => {
        let itemImg;
        let buttonIcon = 'info-sign';
        let buttonText = 'billboard.item.button.other.text';
        let targetUrl;
        switch (item.get('targetType')) {
        case HomeConstants.TARGET_TYPE.ASSET.id:
            switch (item.get('displayAssetTypeName')) {
            case 'Image':
                itemImg = item.getIn(['thumbnails', 0, 'previewUrl']);
                break;
            case 'Video':
                buttonText = 'billboard.item.button.video.text';
                buttonIcon = 'play-circle';
                itemImg = item.getIn(['thumbnails', 0, 'thumbnailUrl']);
                break;
            }
            break;
        case HomeConstants.TARGET_TYPE.EXTERNAL_WEB_PAGE.id:
        case HomeConstants.TARGET_TYPE.APP_SECTION.id:
        case HomeConstants.TARGET_TYPE.INTERNAL_PAGE.id:
            targetUrl = item.get('targetUrl');
            break;
        }

        let itemVideo;
        if (item.get('videoUrl')) {
            itemVideo = item.get('videoUrl');
        }

        let image = itemImg;
        if (item.get('imageUrl')) {
            image = item.get('imageUrl');
        }

        return (
            <Item
                active={currentImageIndex === i}
                buttonIcon={buttonIcon}
                buttonText={buttonText}
                description={item.get('description')}
                image={image}
                internal={item.get('targetType') === HomeConstants.TARGET_TYPE.INTERNAL_PAGE.id}
                key={i}
                onClick={handleClick.bind(this, item)}
                handleMuteChange={handleMuteChange}
                handleVideoEnded={handleVideoEnded}
                targetUrl={targetUrl}
                video={itemVideo}
                videoHasAudio={item.get('hasAudio')}
            />
        );
    });

    return (
        <div className="carousel-inner" role="listbox">
            {itemsToShow}
        </div>
    );
};

List.propTypes = {
    currentImageIndex: PropTypes.number.isRequired,
    handleMuteChange: PropTypes.func.isRequired,
    handleVideoEnded: PropTypes.func.isRequired,
    items: PropTypes.instanceOf(Immutable.List).isRequired
};

export default class Billboard extends React.Component {
    static get propTypes() {
        return {
            publishingList: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            currentImageIndex: 0,
            id: Math.round(Math.random() * 1e5)
        };
        this.clearSliderTime = this.clearSliderTime.bind(this);
        this.handleMuteChange = this.handleMuteChange.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handlePrevClick = this.handlePrevClick.bind(this);
        this.handleSlideChange = this.handleSlideChange.bind(this);
        this.handleVideoEnded = this.handleVideoEnded.bind(this);
        this.startCycling = this.startCycling.bind(this);

        return;
    }

    componentDidMount() {
        this.startCycling();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Restart the index if items change.
        if (this.props.publishingList.get('items') !== nextProps.publishingList.get('items')) {
            this.setState({
                currentImageIndex: 0
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.publishingList !== nextProps.publishingList) {
            return true;
        }

        if (this.state.currentImageIndex !== nextState.currentImageIndex) {
            return true;
        }
        return false;
    }

    clearSliderTime() {
        clearInterval(this.sliderInterval);
        delete this.sliderInterval;
    }

    // Simulate carousel auto slide
    startCycling() {
        this.sliderInterval = setInterval(() => {
            this.handleSlideChange('left');
        }, 6000);
    }

    // Updates the .carousel-background image based on this.state.currentImageIndex.
    handleSlideChange(direction) {
        this.setState(prevState => {
            let currentIndex = prevState.currentImageIndex;

            if (direction === 'left') {
                if (currentIndex === this.props.publishingList.get('items').size - 1) {
                    currentIndex = 0;
                } else {
                    currentIndex++;
                }
            } else if (currentIndex === 0) {
                // direction === 'right'
                currentIndex = this.props.publishingList.get('items').size - 1;
            } else {
                currentIndex--;
            }

            return {
                currentImageIndex: currentIndex
            };
        });
    }

    handleMuteChange(isVideoMuted) {
        if (isVideoMuted) {
            if (this.sliderInterval === undefined) {
                setTimeout(() => {
                    this.handleSlideChange('left');
                }, 1000);
            }
        } else {
            this.clearSliderTime();
        }
    }

    handleNextClick() {
        this.clearSliderTime();
        this.handleSlideChange('left');
    }

    handlePrevClick() {
        this.clearSliderTime();
        this.handleSlideChange('right');
    }

    handleVideoEnded() {
        setTimeout(() => {
            this.setState((prevState) => {
                let next = prevState.currentImageIndex+1;
                if (next >= this.props.publishingList.get('items').size) {
                    next = 0;
                }

                return {
                    currentImageIndex: next
                };
            });
        }, 1000);
    }

    render() {
        let items = this.props.publishingList.get('items');

        if (!items.size) {return null;}

        let indicators = <Indicators items={items} currentImageIndex={this.state.currentImageIndex}/>;
        let leftBtn = (
            <button
                aria-label="Previous Slide"
                className="left carousel-control"
                data-target={`#billboard-${this.state.id}`}
                onClick={this.handlePrevClick}
                onKeyPress={this.handlePrevClick}
                tabIndex="0"
            >
                <Glyphicon glyph="chevron-left" />
            </button>
        );
        let rightBtn = (
            <button
                aria-label="Next Slide"
                className="right carousel-control"
                data-target={`#billboard-${this.state.id}`}
                onClick={this.handleNextClick}
                onKeyPress={this.handleNextClick}
                tabIndex="0"
            >
                <Glyphicon glyph="chevron-right" />
            </button>
        );

        if (items.size === 1) {
            indicators = '';
            leftBtn = '';
            rightBtn = '';
        }

        return (
            <>
                <div className="billboard-container">
                    <div>
                        <Row>
                            <div className="billboard-foreground">
                                <Carousel id={`billboard-${this.state.id}`}
                                    indicators={false}
                                    controls={false}
                                >
                                    {indicators}
                                    <List
                                        currentImageIndex={this.state.currentImageIndex}
                                        items={items}
                                        handleMuteChange={this.handleMuteChange}
                                        handleVideoEnded={this.handleVideoEnded}
                                    />
                                    {leftBtn}
                                    {rightBtn}
                                </Carousel>
                            </div>
                            <Background items={items} currentImageIndex={this.state.currentImageIndex} />
                        </Row>
                    </div>
                </div>
                <div className="padding-top-50 hidden-md-down"></div>
            </>
        );
    }
}

export {
    Indicators,
    Item,
    List,
    Background
};
