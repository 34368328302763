/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

let AttributeFilter = function(items, attributes, term) {
    if (!term) {
        return items;
    }
    term = term.toLowerCase();
    return items.filter(
        item => attributes.map(
            attr => item.get(attr) ? item.get(attr).toString().toLowerCase() : ''
        ).join(' ').indexOf(term) !== -1
    );
};

/**
* [SortRows] sort rows by type of order in state.sortDir
*
* @param {Object} list      [Immutable List]
* @param {String} sortBy    [Immutable List]
* @param {String} sortDir   [Immutable List]
*/
let Sort = function(list, sortBy, sortDir) {
    sortBy = sortBy || 'name';
    sortDir = sortDir || 'desc';

    function sortFunc(value1, value2) {
        // FIXME: find a better way to do this!
        if (typeof value1 === 'number' && typeof value2 === 'number') {
            return value2 - value1;
        }

        if (value1 === null || value1 === undefined) {value1 = '';}
        if (value2 === null || value2 === undefined) {value2 = '';}

        return value2.localeCompare(value1);
    }

    return list.sort((item1, item2) => {
        let value1 = item1.get(sortBy);
        let value2 = item2.get(sortBy);

        if (sortDir === 'desc') {
            return sortFunc(value1, value2);
        }
        return sortFunc(value2, value1);
    });
};

export {
    AttributeFilter,
    Sort
};
