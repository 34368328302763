/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import {NotificationActions} from '../../notification/notification-actions';
import Request from '../../request';
import {TitleConstants} from '../../titles/title-actions';

const CONSTANTS = {
    GET: {
        SUCCESS: 'multiple_titles_actions.get.success',
    },
    TITLE: {
        SET: 'multiple_titles_actions.title.set'
    }
};

class MultipleTitlesActions {
    get(queryParams = {}) {
        queryParams.offset = queryParams.offset || 0 ;
        queryParams.size = queryParams.size || 20;
        queryParams['sort-direction'] = queryParams['sort-direction'] || 'desc';

        let sortFieldName;
        switch (queryParams['sort-field-name']) {
        case 'releaseDate':
        case 'relevance':
            sortFieldName = 'titleReleaseDate';
            break;
        case 'alphabetical':
            sortFieldName = 'name';
            break;
        case 'createdDate':
            sortFieldName = 'createdDate';
            break;
        default:
            sortFieldName = 'titleReleaseDate';
        }
        queryParams['sort-field-name'] = sortFieldName;

        // filters titles that are not season, episode or segment
        queryParams.category = [
            TitleConstants.TITLE_TYPES.THEATRICAL_FEATURES.id,
            TitleConstants.TITLE_TYPES.ANIMATED_FEATURES.id,
            TitleConstants.TITLE_TYPES.MADE_FOR_VIDEO_FEATURES.id,
            TitleConstants.TITLE_TYPES.NETWORK.id,
            TitleConstants.TITLE_TYPES.CABLE.id,
            TitleConstants.TITLE_TYPES.PAY_TV.id,
            TitleConstants.TITLE_TYPES.SPECIALS.id,
            TitleConstants.TITLE_TYPES.ANIMATED_SPECIALS.id,
            TitleConstants.TITLE_TYPES.COMEDY_SPECIALS.id,
            TitleConstants.TITLE_TYPES.MUSIC_SPECIALS.id,
            TitleConstants.TITLE_TYPES.SPORTS.id,
            TitleConstants.TITLE_TYPES.DOCUMENTARIES.id,
            TitleConstants.TITLE_TYPES.MAKING_OF.id,
            TitleConstants.TITLE_TYPES.FORMAT_RIGHTS.id,
            TitleConstants.TITLE_TYPES.DIRECT_TO_VIDEO.id,
            TitleConstants.TITLE_TYPES.ANIMATION.id,
            TitleConstants.TITLE_TYPES.OTHER_PRODUCTS.id,
            TitleConstants.TITLE_TYPES.HBO_FILMS.id,
            TitleConstants.TITLE_TYPES.DOCUMENTARY_FILMS.id,
            TitleConstants.TITLE_TYPES.WB_HBO_MAX_RELEASE.id,
            TitleConstants.TITLE_TYPES.SHORT_PROGRAMS.id,
            TitleConstants.TITLE_TYPES.SERIES_HALF_HOUR.id,
            TitleConstants.TITLE_TYPES.SERIES_ONE_HOUR.id,
            TitleConstants.TITLE_TYPES.CARTOONS.id,
            TitleConstants.TITLE_TYPES.MINI_SERIES.id,
            TitleConstants.TITLE_TYPES.TALK_SHOW.id,
            TitleConstants.TITLE_TYPES.LIMITED_SERIES.id,
            TitleConstants.TITLE_TYPES.GAME_SHOW.id,
            TitleConstants.TITLE_TYPES.DOCUSERIES.id,
            TitleConstants.TITLE_TYPES.ANIMATED_SERIES.id,
            TitleConstants.TITLE_TYPES.REALITY.id,
        ];

        Request.get('title/web/title').query(queryParams).then(res => {
            const titles = res.body.results;
            Dispatcher.dispatch({
                actionType: CONSTANTS.GET.SUCCESS,
                offset: res.body.offset,
                size: res.body.size,
                titles: Immutable.fromJS(titles),
                total: res.body.totalCount
            });
            return Promise.all(titles.map(title => Request.get(`title/${title.id}`).then(response => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.TITLE.SET,
                    title: Immutable.fromJS(response.body)
                });
                return;
            })));
        }).catch(err => {
            NotificationActions.showAlertDanger('homepage-titles.titles.get.error');
            throw err;
        });
    }
}

const actions = new MultipleTitlesActions();

export {
    actions as MultipleTitlesActions,
    CONSTANTS as MultipleTitlesConstants,
};
