/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import Request from '../../request';

const DIALOGUE_FACET_ACTION_TYPES = {
    SUCCESS: 'search.dialogue-facet.success',
    START: 'search.dialogue-facet.start',
    ERROR: 'search.dialogue-facet.error',
};

class DialogueFacetActions {
    /**
     * @param {string} searchTerm
     * @param {string} value
     */
    getDialogueFacet(searchTerm, value = '') {
        Dispatcher.dispatch({
            actionType: DIALOGUE_FACET_ACTION_TYPES.START,
        });
        Request.get('asset/video-search/facets/dialogue').query({
            'is-clippable': true,
            'search-term': searchTerm,
            dialogue: value,
        }).then((response) => {
            Dispatcher.dispatch({
                actionType: DIALOGUE_FACET_ACTION_TYPES.SUCCESS,
                data: Immutable.fromJS(response.body?.map((dialogue) => ({key: dialogue})) ?? [])
            });
        }).catch(() => {
            Dispatcher.dispatch({
                actionType: DIALOGUE_FACET_ACTION_TYPES.ERROR,
            });
        });
    }
}

const actions = new DialogueFacetActions();

export {
    actions as DialogueFacetActions,
    DIALOGUE_FACET_ACTION_TYPES as DialogueFacetsConstants
};

export default DialogueFacetActions;
