/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_TYPES, DOCUMENT_CT_NAME_BY_ID} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import Analytics from '../../analytics';
import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import Table from '../../common/table';
import {MessagesContext} from '../../messages/messages-context';
import {AssetTitleActions} from '../../titles/asset-title/asset-title-actions';
import AssetTitleStore from '../asset-title/asset-title-store';
import {TitleActions, TitleConstants} from '../title-actions';

import 'bootstrap/js/collapse';
import 'bootstrap/js/transition';

const AnalyticsDownloadFunctions = {
    AUDIO: Analytics.audioDownloadEvent,
    DOCUMENT: Analytics.documentDownloadEvent,
    SCRIPT: Analytics.scriptDownloadEvent
};

const createHeaders = function(headerName, showName, className) {
    return (
        <th className={className}
            key={headerName}
            scope="col"
        >
            {showName}
        </th>
    );
};

/**
 * Trims the text and add ellipsis if is greater than 20 characters
 * @param  {string} text
 * @param  {int} max characters | 20
 * @return {string}
 */
const trim = function(text, max) {
    max = max || 20;
    if (text.length >= max) {
        text = text.slice(0, max) + '...';
    }
    return text.trim();
};

/**
 * if it's not null or undefined, then return the text, else return label
 * @param  {string, int}  value [description]
 * @return {string} DOM element as a string
 */
const hasValue = function(value) {
    // Not available
    if (!value) {
        return '-';
    }
    return value;
};

/**
 * Column definitions.
 */
const COLUMNS = {
    contentType: {
        getValue: function(asset, index, intl) {
            let icon = '';
            let contentType = asset.get('contentType');

            if (asset.get('assetType') === 'DOCUMENT') {
                return DOCUMENT_CT_NAME_BY_ID[contentType] || '';
            }

            if (asset.get('assetType') === 'SCRIPT') {
                return AssetTypeConstants.SCRIPT[contentType] || '';
            }

            let icons = {
                [AssetTypeConstants.AUDIO.CONTENT_TYPES.EPISODIC_RADIO_SPOT]: '<span class="icons8-radio"/>&nbsp;',
                [AssetTypeConstants.AUDIO.CONTENT_TYPES.GENERIC_RADIO_SPOT]: '<span class="icons8-radio-tower"/>&nbsp;',
                [AssetTypeConstants.AUDIO.CONTENT_TYPES.SPOT_REEL]: '<span class="icons8-film-reel"/>&nbsp;',
                [AssetTypeConstants.AUDIO.CONTENT_TYPES.THEME_SONG]: '<span class="icons8-music-video"/>&nbsp;',
                [AssetTypeConstants.AUDIO.CONTENT_TYPES.SONG]: '<span class="icons8-music"/>&nbsp;',
                [AssetTypeConstants.AUDIO.CONTENT_TYPES.INTERVIEW]: '<span class="icons8-talk-male"/>&nbsp;',
                [AssetTypeConstants.AUDIO.CONTENT_TYPES.CLIP]: '<span class="icons8-cut"/>&nbsp;',
                [AssetTypeConstants.AUDIO.CONTENT_TYPES.AUDIO_STEM]: '<span class="icons8-tree-structure"/>&nbsp;'
            };

            icon = icons[contentType];
            return `${icon}${intl.messages['asset.audio.content-types.' + contentType] || ''}`;
        },
        getHeader: function(context) {
            return createHeaders('contentType', context.messages['list-assets.contentType.title']);
        }
    },
    runDate: {
        getValue: function(asset, index, context) {
            let date = Moment(asset.get('formatRunDate'));
            if (date.isValid()) {
                return date.format(context.messages['date-format']);
            }
            return '-';
        },
        getHeader: function(context) {
            return createHeaders('createdDate', context.messages['list-assets.run-date.title']);
        }
    },
    description: {
        getValue: function(asset) {
            if (!asset.get('assetComment')) {
                return '-';
            }

            let description = asset.get('assetComment')
                .replace('<br />', '')
                .replace('<br>', '')
                .replace('<br></br>', '');
            return trim(description, 50);
        },
        getHeader: function(context) {
            return createHeaders('description', context.messages['list-assets.description.title']);
        }
    },
    documentName: {
        getValue: function(asset) {
            return `<div class="list-overflow"><a aria-label="View ${asset.get('name')}" class="Cur(p) asset-view-link asset-download-link" data-asset-id="${asset.get('assetId')}" role="button">${asset.get('assetComment') || asset.get('name')}</a></div>`;
        },
        getHeader: function(context) {
            return createHeaders('documentName', context.messages['list-assets.documentName.title'], 'all document-name');
        }
    },
    documentType: {
        getValue: function(asset) {
            return DOCUMENT_TYPES[asset.get('contentType')] || '';
        },
        getHeader: function(context) {
            return createHeaders('documentType', context.messages['list-assets.documentType.title']);
        }
    },
    download: {
        getValue: function(asset) {
            return `<a aria-label="Download ${asset.get('name')}" class="Cur(p) asset-download-link" data-asset-id="${asset.get('assetId')}" role="button"><span class="glyphicon glyphicon-download-alt" /></a>`;
        },
        getHeader: function() {
            return createHeaders('download', <span className="glyphicon glyphicon-download-alt" />, 'no-sort icon-column');
        }
    },
    // Episode Number
    episode: {
        getValue: function(asset) {
            if (asset.getIn(['relatedTitle', 'relationshipType']) === TitleConstants.TITLE_RELATIONSHIP_TYPE.SEASON) {
                return '-';
            }

            return asset.getIn(['relatedTitle', 'orderWithinParent']) || '-';
        },
        getHeader: function(context) {
            return createHeaders('episode', context.messages['list-assets.episode.title']);
        }
    },
    formatSheetRun: {
        getValue: function(asset) {
            return asset.get('formatRunDate');
        },
        getHeader: function(context) {
            return createHeaders('formatSheetRun', context.messages['list-assets.format-sheet-run.title']);
        }
    },
    formatSheetWeek: {
        getValue: function(asset, index, context, items) {
            const runDate = Moment(asset.get('formatRunDate'), 'YYYY-MM-DD');
            if (parseInt(runDate.format('d'), 10) === 1) { // to check if it's Monday
                if (index === 0 || items.get(index-1).get('formatRunDate') !== asset.get('formatRunDate')) {
                    return asset.get('formatRunWeek');
                }
            }
            return '';
        },
        getHeader: function(context) {
            return createHeaders('formatSheetWeek', context.messages['list-assets.format-sheet-week.title']);
        }
    },
    isNew: {
        getValue: function(asset, index, context) {
            let assetDate = Moment(asset.get('createdDate'));
            let aWeekAgo = Moment().subtract(7, 'd');
            if (assetDate.isValid() && assetDate.isAfter(aWeekAgo)) {
                let text = context.messages['list-assets.column.value.new'];
                return `<span class="label label-primary text-uppercase"><em>${text}</em></span>`;
            }
            return '';
        },
        getHeader: function(context) {
            return createHeaders('isNew', context.messages['list-assets.isNew.title']);
        }
    },
    name: {
        getValue: function(asset) {
            return `<a aria-label="Download ${asset.get('name')}" class="Cur(p) asset-download-link" data-asset-id="${asset.get('assetId')}" role="button">${asset.get('name')}</a>`;
        },
        getHeader: function(context) {
            return createHeaders('name', context.messages['list-assets.name.title']);
        }
    },
    order: {
        getValue: function(asset, index) {
            return index + 1;
        },
        getHeader: function(context) {
            return createHeaders('order', context.messages['list-assets.order.title']);
        }
    },
    /**
     * TODO: Detect if the asset file it's not .ZIP and if that's the case, show the play icon
     */
    play: {
        getValue: function(asset) {
            return `<a class="audio-play" data-asset-id="${asset.get('assetId')}"><span class="glyphicon glyphicon-play-circle" /></a>`;
        },
        getHeader: function() {
            return createHeaders('play', <span className="glyphicon glyphicon-play-circle" />, 'no-sort');
        }
    },
    runtime: {
        getValue: function(asset) {
            return hasValue(asset.get('runtime'));
        },
        getHeader: function() {
            return createHeaders('runtime', 'Runtime');
        }
    },
    scriptName: {
        getValue: function(asset) {
            return `<div class="list-overflow"><a aria-label="Download ${asset.get('name')}" class="Cur(p) asset-download-link" data-asset-id="${asset.get('assetId')}" role="button">${asset.get('name') || '-'}</a></div>`;
        },
        getHeader: function(context) {
            return createHeaders('scrpitName', context.messages['list-assets.scriptName.title'], 'all document-name');
        }
    },
};

class ListAsset extends React.Component {
    static get propTypes() {
        return {
            accordion: PropTypes.bool.isRequired,
            assets: PropTypes.instanceOf(Immutable.List).isRequired,
            columns: PropTypes.array.isRequired,
            match: PropTypes.object.isRequired,
            name: PropTypes.string.isRequired,
            nameOverride: PropTypes.string,
        };
    }

    static get defaultProps() {
        return {
            nameOverride: undefined,
        };
    }

    static getStores() {
        return [AssetTitleStore];
    }

    constructor(props) {
        super(props);

        this.handleRowClick = this.handleRowClick.bind(this);
        return;
    }

    componentDidMount() {
        if (this.props.match.params?.documentId) {
            AssetTitleActions.downloadImageRendition(this.props.match.params.documentId, 'Source', true);
        }
    }

    static contextType = MessagesContext;

    handleRowClick(event) {
        let target = event.target;
        if (~target.className.indexOf('glyphicon')) {
            target = target.parentElement;
        }
        let assetId;
        let asset;

        switch (true) {
        case !!~target.className.indexOf('asset-download-link'):
            assetId = parseInt(target.getAttribute('data-asset-id'), 10);
            asset = this.props.assets.find(a => a.get('assetId') === assetId);

            // Register download.
            AnalyticsDownloadFunctions[asset.get('assetType')](assetId, asset.get('titleId'));

            AssetTitleActions.downloadImageRendition(assetId, 'Source', !!~target.className.indexOf('asset-view-link'));
            break;

        case !!~target.className.indexOf('audio-play'):
            assetId = parseInt(target.getAttribute('data-asset-id'), 10);
            asset = this.props.assets.find(a => a.get('assetId') === assetId);
            TitleActions.showAudioPreview(asset);
            break;
        }

        return;
    }

    render() {
        const sortedAssets = this.props.assets.sort((a, b) => new Date(b.get('formatRunDate')) - new Date(a.get('formatRunDate')));
        let tableId = this.props.name;
        let title = this.context.intl.messages[`list-assets.${this.props.name}.title`];

        if (this.props.nameOverride) {
            title = this.props.nameOverride;
        }

        let header = <h4 className="h3">{title}</h4>;
        if (this.props.accordion) {
            header = (
                <h3>
                    <a
                        className="text-default-color accordion-header"
                        href={`#list-assets-${tableId}-accordion`}
                        data-toggle="collapse"
                    >
                        {title}&nbsp;
                        <small>
                            <span className="glyphicon glyphicon-chevron-down" />
                        </small>
                    </a>
                </h3>
            );
        }


        return (
            <div>
                {header}
                <div id={`list-assets-${tableId}-accordion`} className={ClassNames('accordion', {collapse: this.props.accordion})}>
                    <Table
                        columns={this.props.columns}
                        items={sortedAssets}
                        handleRowClick={this.handleRowClick}
                    />
                </div>
            </div>
        );
    }
}

export default ListAsset;
export {
    COLUMNS as AssetListColumns
};
