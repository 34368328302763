/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import QueryString from 'query-string';
import {useCallback} from 'react';
import {useLocation} from 'react-router';

import {RouterActions} from '../../../router/router-actions';


const useSetSearchFilters = () => {
    const location = useLocation();

    const setFilter = useCallback(/* istanbul ignore next */(...args) => {
        /* istanbul ignore next */
        let [filterName, label, isSelect = false, event] = args;
        /* istanbul ignore next */
        if (!event) {
            event = args[args.length - 1];
        }

        let value;

        /* istanbul ignore next */
        switch (true) {
        // Handle React Select
        case isSelect:
            if (event.action === 'clear' || event.action === 'remove-value') {
                value = null;
                break;
            }
            if (!Array.isArray(event)) {
                event = [event];
            }
            value = Immutable.fromJS(event.map(v => v[label])).toSet().toJS();
            if (!value.length) {
                value = null;
            }
            break;

            // Handle Moment objects
        case Moment.isMoment(event):
            value = event;
            if (value.isValid()) {
                value = value.format('YYYY-MM-DD');
            } else {
                value = '';
            }
            break;

        case !event:
            value = '';
            break;

        // Handle checkboxes
        case event.target.type === 'checkbox':
            value = event.target.checked;
            break;

        default:
            value = event.target.value;
            break;
        }

        // Reset offset for Pagination
        /* istanbul ignore next */
        const currentQuery = QueryString.parse(location.search);
        /* istanbul ignore next */
        let offset = 0;

        /* istanbul ignore next */
        let query = Object.assign(
            {'keep-scroll': true},
            currentQuery,
            {
                offset
            },
            {[filterName]: value}
        );
        /* istanbul ignore next */
        if ([false, null, undefined, ''].indexOf(value) !== -1) {
            delete query[filterName];
        }
        /* istanbul ignore next */
        const search = `?${QueryString.stringify(query)}`;
        /* istanbul ignore next */
        if (location.search !== search) {
            RouterActions.redirect(`${location.pathname}${search}`, true);
        }
    }, [location]);

    return {
        setFilter,
    };
};

export default useSetSearchFilters;
