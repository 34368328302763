/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import PropTypes from 'prop-types';
import React from 'react';
import {TransitionGroup} from 'react-transition-group';

import {ImageLoaderPlaceholders} from './image-loader';
import style from './photoswipe.module.less';
import Analytics from '../../../analytics';
import {AssetTypeConstants} from '../../../asset-types/asset-type-constants';
import Config from '../../../config/config';
import {MessagesContext} from '../../../messages/messages-context';
import {Alert} from '../../../notification/alert';
import {OrderActions} from '../../../orders/order-actions';
import OrderStore from '../../../orders/order-store';
import SessionStore from '../../../session/session-store';
import {Debounce, IsNotTriggerKey} from '../../../utils/utils';
import Share from '../../share';
import {AssetTitleActions, AssetTitleConstants} from '../asset-title-actions';

class Photoswipe extends React.Component {
    static get propTypes() {
        return {
            addToCart: PropTypes.bool,
            canEditBrainiac: PropTypes.bool,
            canRemove: PropTypes.bool.isRequired,
            currentImage: PropTypes.instanceOf(Immutable.Map),
            images: PropTypes.instanceOf(Immutable.List).isRequired,
            hasRestrictedAuth: PropTypes.bool.isRequired,
            isModalOpen: PropTypes.bool,
            onClose: PropTypes.func,
            onRemove: PropTypes.func,
            share: PropTypes.bool,
            titleId: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            addToCart: true,
            canEditBrainiac: false,
            canRemove: false,
            hasRestrictedAuth: false,
            isModalOpen: false,
            currentImage: null,
            onClose: () => void 0,
            onRemove: () => void 0,
            share: true,
            titleId: -1
        };
    }

    static getStores() {
        return [SessionStore];
    }

    static calculateState() {
        return {
            partner: SessionStore.getState().get('partner')
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            isFullScreen: false,
            showShare: false,
            showDownload: false,
        }, this.constructor.calculateState());

        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleFullScreenChange = this.handleFullScreenChange.bind(this);
        this.handleEventRemove = Debounce(this.handleEventRemove.bind(this), 200);
        this.handleRemove = this.handleRemove.bind(this);
        this.newItem = this.newItem.bind(this);
        this.openPhotoSwipe = this.openPhotoSwipe.bind(this);
        this.registerPSWP = this.registerPSWP.bind(this);
        this.toggleFullScreen = this.toggleFullScreen.bind(this);
        this.toggleViewShare = this.toggleViewShare.bind(this);

        return;
    }

    componentDidMount() {
        this.openPhotoSwipe();

        document.addEventListener('webkitfullscreenchange', this.handleFullScreenChange);
        document.addEventListener('fullscreenchange', this.handleFullScreenChange);
        document.addEventListener('msfullscreenchange', this.handleFullScreenChange);
        document.addEventListener('keydown', this.handleEventRemove);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.currentImage !== nextProps.currentImage ||
            this.props.images !== nextProps.images ||
            this.props.titleId !== nextProps.titleId) {
            return true;
        }

        if (this.state.showDownload !== nextState.showDownload ||
            this.state.showShare !== nextState.showShare ||
            this.state.isFullScreen !== nextState.isFullScreen) {
            return true;
        }

        return false;
    }

    componentDidUpdate(oldProps) {

        this.openPhotoSwipe();

        if (!this.props.currentImage) {
            // PhotoSwipe is closed.
            return;
        }
        if (this.props.images === oldProps.images) {
            // image has not changed nothing to do here
            return;
        }
        // Check for changes, replace items as needed
        oldProps.images.forEach(prevImage => {
            const image = this.props.images.find(t => {
                return t.get('assetId') === prevImage.get('assetId');
            });
            if (image && !prevImage.equals(image)) {
                // Replace the image in internal array
                let imgIndex = -1;
                for (let i = 0; i < this.gallery.items.length; i++) {
                    if (this.gallery.items[i].assetId === image.get('assetId')) {
                        imgIndex = i;
                        break;
                    }
                }

                // Photoswipe documenation: http://photoswipe.com/documentation/getting-started.html

                // Update internal array's image details
                this.gallery.items.splice(imgIndex, 1, this.newItem(image));

                // If the currentImage is updated, update the current item in
                // photoswipe (bug workaround).
                if (imgIndex === this.state.currentImageIndex) {
                    this.gallery.invalidateCurrItems();
                    this.gallery.updateSize(true);
                    this.gallery.ui.update();
                }
                return;
            }

            if (prevImage && !image) {

                let prevImgIndex = -1;

                for (let p = 0; p < this.gallery.items.length; p++) {
                    if (this.gallery.items[p].assetId === prevImage.get('assetId')) {
                        prevImgIndex = p;
                        break;
                    }
                }

                if (prevImgIndex === (this.gallery.items.length-1)) {
                    this.gallery.goTo(0);
                }

                this.gallery.items.splice(prevImgIndex, 1);

                this.gallery.invalidateCurrItems();
                this.gallery.updateSize(true);
                this.gallery.ui.update();

                if (!this.gallery.items.length) {
                    this.gallery.close();
                }
            }
        });

        this.gallery.options.escKey = !this.props.isModalOpen;
        this.gallery.options.arrowKeys = !this.props.isModalOpen;

        return;
    }

    componentWillUnmount() {
        if (this.gallery) {
            this.gallery.close();

            delete this.gallery;
        }

        document.removeEventListener('webkitfullscreenchange', this.handleFullScreenChange);
        document.removeEventListener('fullscreenchange', this.handleFullScreenChange);
        document.removeEventListener('msfullscreenchange', this.handleFullScreenChange);
        document.removeEventListener('keydown', this.handleEventRemove);

        return;
    }

    static contextType = MessagesContext;

    handleAddToCart(event) {
        event.preventDefault();
        OrderActions.add(AssetTypeConstants.ASSET_TYPE.IMAGE, this.props.titleId, [this.gallery.currItem.asset]);
    }

    handleClose() {
        this.setState({
            isFullScreen: false
        });
    }

    handleDownload(event) {
        // There's no real need to check, but if we ever want to,
        // here's the way to know the user clicked on one of the
        // download links:
        // event.target.classList.contains('image-download-link');

        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        const assetId = this.gallery.currItem.asset.get('assetId');
        const renditionType = event.target.getAttribute('data-rendition-type');

        Analytics.imageDownloadEvent(assetId, renditionType, this.props.titleId);

        if (renditionType === 'Source') {
            AssetTitleActions.downloadAsset(assetId);

            return;
        }

        AssetTitleActions.downloadImageRendition(assetId, renditionType);
        return;
    }

    handleFullScreenChange() {
        if (document.webkitIsFullScreen === false || document.mozFullScreen === false || document.msFullscreenElement === false) {
            this.handleClose();
        }
    }

    handleEventRemove(event) {
        if ((event.key === 'Delete' || event.key === 'Backspace') && !this.state.isFullScreen) {
            this.handleRemove();
        }
    }

    handleRemove() {
        if (this.gallery) {
            this.props.onRemove(this.gallery.currItem.asset.get('assetId'));
        }
    }

    newItem(image) {
        let imageDimensions = '';
        let imageHeight = image.get('previewHeight');
        let imageWidth = image.get('previewWidth');
        let videoOverlay;

        if (imageHeight || imageWidth) {
            imageDimensions = `${imageWidth || '-' } x ${imageHeight || '-'} pixels`;
        }

        let restricted = '';
        const isRestricted = image.get('deliveryType') === AssetTypeConstants.DELIVERY_TYPES.NEEDS_APPROVAL;
        if (isRestricted) {
            restricted = `<p class="image-overlay-permission needs-approval active">${this.context.intl.messages['asset.image.gallery.needs-approval']}</p>`;
        } else if (image.get('deliveryType') === AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE) {
            restricted = `<p class="image-overlay-permission restricted active"><span class="glyphicon glyphicon-ban-circle"></span>${this.context.intl.messages['asset.image.gallery.restricted']}</p>`;
        }

        let comment = image.get('caption') || image.get('assetComment') || image.get('assetName') || 'N/A';
        let imgSrc = image.get('previewUrl') || ImageLoaderPlaceholders.SQUARE;

        if ((/^video\//.test(image.get('mimeType'))) || (image.get('contentType') === parseInt(AssetTitleConstants.CONTENT_TYPES.IMAGE.SOCIAL_MEDIA.TYPES.SOCIAL_MEDIA_CLIP))) {
            videoOverlay = `<span class="img-video-still ${style.still}"></span>`;
        }

        return {
            assetId: image.get('assetId'),
            asset: image,
            isRestricted: isRestricted,
            downloadLinks: '',
            html: `<div class="image-overlay">
                <div class="image-overlay-container">
                    <div class="${style.stillContainer}">
                        ${videoOverlay || ''}
                        <img oncontextmenu="return false" src="${imgSrc}"/>
                        <span class="image-overlay-description">${restricted}
                            <p>${comment}</p>
                            ${imageDimensions && '<p>' + imageDimensions + '</p>'}
                        </span>
                    </div>
                </div>
            </div>`,
            // for easy testing
            comment: comment,
            imgSrc: imgSrc,
            imageDimensions: imageDimensions,
        };
    }

    openPhotoSwipe() {
        // If no current image or if PhotoSwipe is already open, then exit.
        if (!this.props.currentImage || this.gallery) {return;}
        const canDownloadImage = OrderStore.canDownloadImage(this.props.currentImage);
        let imageIndex = this.state.currentImageIndex;
        let items = this.props.images.map((i, index) => {
            if (parseInt(i.get('assetId')) === parseInt(this.props.currentImage.get('assetId'))) {
                this.setState({currentImageIndex: index});
                imageIndex = index;
            }

            return this.newItem(i);
        }).toJS();

        // Define PhotoSwipe options
        let slideOptions = {
            index: imageIndex,
            closeOnScroll: false,
            closeOnVerticalDrag: false,
            zoomEl: false,
            history: false,
            captionAndToolbarAutoHideDelay:0,
            bgOpacity: 0.9,
            // Parse output of share links
            parseShareButtonOut: (shareButtonData, shareButtonOut) => {
                // If image is restricted and user doesn't have special permissions, then
                // get out of here!
                if (this.gallery.currItem.isRestricted && !this.props.hasRestrictedAuth) {
                    return 'Restricted';
                }
                shareButtonOut = '';
                if (canDownloadImage.canDownloadLoRes) {
                    const lowResFileType = this.gallery.currItem.asset.get('previewResolutionFileType') || '';
                    shareButtonOut = `<a class="Cur(p) image-download-link ${style.imageDownloadLink}" data-rendition-type="LoRes">${this.context.intl.messages['asset.image.gallery.renditions.low-res']} ${lowResFileType}</a>`;
                }
                if (canDownloadImage.canDownloadFull) {
                    const hiResFileType = this.gallery.currItem.asset.get('fullResolutionFileType') || '';
                    shareButtonOut += `<a class="Cur(p) image-download-link ${style.imageDownloadLink}" data-rendition-type="HiRes">${this.context.intl.messages['asset.image.gallery.renditions.full-res']} ${hiResFileType}</a>`;
                }
                if (canDownloadImage.canDownloadSource) {
                    const sourceFileType = this.gallery.currItem.asset.get('mediaFile') || '';
                    shareButtonOut += `<a class="Cur(p) image-download-link ${style.imageDownloadLink}" data-rendition-type="Source">${this.context.intl.messages['asset.image.gallery.renditions.source']} ${sourceFileType}</a>`;
                }

                return shareButtonOut;
            }
        };

        this.gallery = new PhotoSwipe(this.pswp, PhotoSwipeUIDefault, items, slideOptions);

        this.gallery.listen('destroy', () => {
            this.props.onClose();
            delete this.gallery;
            return;
        });

        this.gallery.listen('beforeChange', () => {
            Analytics.imageViewEvent(this.gallery.currItem.asset.get('assetId'), this.props.titleId);
            return;
        });

        this.gallery.init();

        if (this.gallery && this.gallery.currItem && this.gallery.currItem.asset) {

            this.setState(() => ({
                showDownload: canDownloadImage.canDownloadLoRes || canDownloadImage.canDownloadFull || canDownloadImage.canDownloadSource
            }));
        }

        return;
    }

    registerPSWP(elem) {
        this.pswp = elem;
        return;
    }

    toggleFullScreen() {
        this.setState(prevState => ({
            isFullScreen: !prevState.isFullScreen
        }));
    }

    toggleViewShare() {
        this.setState(prevState => ({
            showShare: !prevState.showShare
        }));
    }

    render() {
        let titleId = this.props.titleId;
        let assetShare = Immutable.Map();
        let letDownloadAsset;

        if (this.gallery) {
            assetShare = this.gallery.currItem.asset;
        }

        if (this.state.showDownload) {
            letDownloadAsset = <button className="pswp__button pswp__button--share" title="Download"/>;
        }
        let letShareAsset;
        if (this.state.partner.get('id') !== Config.Partners.PRESS_SITE.id && !this.state.isFullScreen) {
            letShareAsset = this.props.share && <button className="pswp__button glyphicon glyphicon-transfer" onClick={this.toggleViewShare} title={this.context.intl.messages['asset.image.gallery.share']}/>;
        }

        let letRemoveAsset;
        if (this.props.canRemove && !this.state.isFullScreen) {
            letRemoveAsset = <button className="pswp__button glyphicon glyphicon-trash" onClick={this.handleRemove} title={this.context.intl.messages['asset.image.gallery.remove']}/>;
        }

        let editBrainiac;
        if (this.props.canEditBrainiac && titleId) {
            editBrainiac = <a
                className="pswp__button text-align-center"
                href={`${Config.BrainiacBaseUrl}/assets/image/${assetShare.get('assetId')}`}
                rel="noopener noreferrer"
                target="_blank"
                title={this.context.intl.messages['asset.image.gallery.edit-brainiac']}>
                <i className="glyphicon glyphicon-edit"></i>
            </a>;
        }

        return (
            <div
                aria-hidden="true"
                className="pswp"
                ref={this.registerPSWP}
                role="dialog"
                tabIndex="-1"
            >

                <Share
                    asset={assetShare}
                    assetType={AssetTypeConstants.ASSET_TYPE.IMAGE}
                    close={this.toggleViewShare}
                    show={this.state.showShare}
                    titleId={titleId}
                />


                {/**
                  * Background of PhotoSwipe.
                  * It's a separate element as animating opacity is faster than rgba().
                  */}
                <div className="pswp__bg"></div>

                {/**
                  * Slides wrapper with overflow:hidden.
                  */}
                <div className="pswp__scroll-wrap">

                    {/**
                      * Container that holds slides.
                      * PhotoSwipe keeps only 3 of them in the DOM to save memory.
                      * Don't modify these 3 pswp__item elements, data is added later on.
                      */}
                    <div className="pswp__container">
                        <div className="pswp__item"></div>
                        <div className="pswp__item"></div>
                        <div className="pswp__item"></div>
                    </div>

                    <div className="media-overlay-alert">
                        <TransitionGroup
                            transitionName="alert"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}>
                            <Alert />
                        </TransitionGroup>
                    </div>

                    {/**
                      * Default (PhotoSwipeUI_Default) interface on top of sliding area.
                      * Can be changed.
                      */}
                    <div className="pswp__ui pswp__ui--hidden">

                        <div className="pswp__top-bar">

                            <div className="pswp__counter"></div>

                            <button className="pswp__button pswp__button--close" title={this.context.intl.messages['asset.image.gallery.close']} onClick={this.handleClose}/>

                            {letDownloadAsset}

                            {letShareAsset}

                            {letRemoveAsset}

                            {this.props.addToCart && <button onClick={this.handleAddToCart} className="pswp__button pswp__button--cart" title={this.context.intl.messages['asset.image.gallery.add-to-cart']}/>}

                            <button onClick={this.toggleFullScreen} className="pswp__button pswp__button--fs" title={this.context.intl.messages['asset.image.gallery.fullscreen']}/>

                            <button className="pswp__button pswp__button--zoom" title={this.context.intl.messages['asset.image.gallery.zoom']}/>

                            {editBrainiac}

                            <div className="pswp__preloader">
                                <div className="pswp__preloader__icn">
                                    <div className="pswp__preloader__cut">
                                        <div className="pswp__preloader__donut"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={ClassNames('pswp__share-modal pswp__share-modal--hidden pswp__single-tap', style.shareModal)}>
                            <div
                                className="pswp__share-tooltip"
                                onClick={this.handleDownload}
                                onKeyUp={this.handleDownload}
                                role="button"
                                tabIndex="0"
                            ></div>
                        </div>

                        <button className="pswp__button pswp__button--arrow--left" title={this.context.intl.messages['asset.image.gallery.arrow.previous']}>
                        </button>

                        <button className="pswp__button pswp__button--arrow--right" title={this.context.intl.messages['asset.image.gallery.arrow.next']}>
                        </button>

                        <div className="pswp__caption">
                            <div className="pswp__caption__center"></div>
                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default Container.create(Photoswipe);
export {Photoswipe};
