/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './pitch-blue.less';

export default class PitchBlue extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.pitch-blue">
                <div className="container padding-y-10 padding-x-20" id="hide-this">
                    <div id="content" className="clearfix">
                        <div id="mipcom-heading" style={{marginBottom: '40px'}}>
                            <h2>PitchBlue&trade;</h2>
                        </div>

                        <div className="pitchblue-heading">
                            <h4>Information</h4>
                        </div>

                        <div className="pitchblue-paragraph">
                            <p>
                                <p>
                                    PitchBlue&trade; automatically delivers high-quality HD and SD
                                    programming to your station using the same satellite from which you
                                    receive programming today. Your station has been provided a Vigor
                                    Systems MagnuBox&trade; transport stream recorder as part of the
                                    deployment of this system.
                                </p>
                            </p>
                        </div>

                        <div className="pitchblue-paragraph">
                            <p>
                                The MagnuBox&trade; automatically records programming from linear
                                satellite feeds in real time based upon electronic schedules maintained
                                by Warner Bros. Feed schedule updates are automatically delivered to the
                                MagnuBox&trade; via satellite and Internet, ensuring no feeds are
                                missed. The feeds are non-proprietary digital linear transmissions that
                                may also be received using an off-the-shelf IRD, therefore use of the
                                MagnuBox&trade; is not mandatory and alternate workflows may be
                                implemented by stations, such as using an IRD to receive live
                                programming, or record directly to a broadcast server.
                            </p>
                        </div>

                        <div className="pitchblue-paragraph">
                            <p>
                                The satellite feeds are transmitted using advanced MPEG-4 encoding and
                                DVB S2 modulation, with the capacity to deliver up to eight hours of
                                programming in one hour. The MagnuBox&trade; records the subscribed
                                feeds, storing them as files. Three terabytes of disk space can store
                                over 300 hours of HD programming.
                            </p>
                        </div>

                        <div className="pitchblue-paragraph">
                            <p>
                                The stored files can be played back as HD/SD-SDI video/audio under VDCP
                                automation control, downconverted, cross converted and exported via FTP.
                                Timing sheets are available via web browser and via FTP as XML.
                            </p>
                        </div>

                        <div className="pitchblue-heading">
                            <h4>Content Options </h4>
                        </div>

                        <div className="pitchblue-paragraph">
                            <p>
                                There are several methods for the broadcaster to receive and prepare the
                                content delivered via PitchBlue&trade;.
                            </p>
                        </div>
                        <div className="pitchblue-paragraph">
                            <p>
                                1. HD or SD playout of the program via SDI to an external device for
                                ingest. Essentially the same as recording from an IRD, but with the
                                added benefit of playback control, either manually or using Industry
                                standard Video Disk Control Protocol (VDCP) to cue and play frame
                                accurately.
                            </p>
                        </div>
                        <div className="pitchblue-paragraph">
                            <p>
                                2. Transfer standard definition MPEG-2 files. The MagnuBox can generate
                                MPEG-2 files for backwards compatibility with your existing MPEG-2 file
                                workflow. Files are transferred using FTP.
                            </p>
                        </div>
                        <div className="pitchblue-paragraph">
                            <p>
                                3. Transfer HD and SD MPEG-4 files. Files are transferred using FTP.
                                Recommended for external transcoding systems and supporting video
                                servers.
                            </p>
                        </div>
                        <div className="pitchblue-paragraph">
                            <p>
                                4. Dub to tape. Simple playback of the program via SDI can be initiated
                                using the web interface, suitable for dubbing to tape. Contact
                                Information
                            </p>
                        </div>

                        <div className="pitchblue-heading">
                            <h4>Contact Information </h4>
                        </div>

                        <div className="pitchblue-paragraph">
                            <p>
                                There are several methods for the broadcaster to receive and prepare the
                                content delivered via PitchBlue&trade;.
                            </p>
                        </div>
                        <div className="pitchblue-paragraph">
                            <p>
                                1. Warner Bros. Domestic TV Distribution Services: Can assist with
                                missing content.
                            </p>
                            <div className="pitchblue-contact">
                                <p>&bull; Alex Rudinger (818)977-5981</p>
                            </div>
                            <div className="pitchblue-contact">
                                <p>&bull; Ann Roen (818)977-5983</p>
                            </div>
                        </div>
                        <div className="pitchblue-paragraph">
                            <p>
                                2. GDMX &ndash; PitchBlue Rollout Center: Can assist with
                                troubleshooting technical problems
                            </p>
                            <div className="pitchblue-contact">
                                <p>&bull; Rollout Center (818)872-0847</p>
                            </div>
                        </div>

                        <div className="pitchblue_end"></div>
                    </div>
                </div>

            </DocumentTitle>

        );
    }
}
