/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Sort} from '../common/local-search/filter';
import {MessagesContext} from '../messages/messages-context';
import {AssetTitleActions} from '../titles/asset-title/asset-title-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../styles/app/dataTables.brainiac.css');

// Load jQuery and register the datatables plugin.
let jQuery = require('jquery');
require('datatables.net-responsive-bs');

class ListDocumentsTable extends Component {

    static get propTypes() {
        return {
            documents: PropTypes.instanceOf(Immutable.List),
            label: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            documents: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            id: Math.ceil(Math.random() * 100000),
            sortBy: 'name',
            sortDir: 'asc'
        };

        this.getSortByClass = this.getSortByClass.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery(`#list-documents-table-${this.state.id}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: 'actions',
                targets: 2,
                width: 20
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        this.updateRows(this.props, this.state);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.documents !== this.props.documents ||
            nextState.sortBy !== this.state.sortBy ||
            nextState.sortDir !== this.state.sortDir) {
            return true;
        }
        return false;
    }

    componentDidUpdate() {
        this.updateRows(this.props, this.state);
        return;
    }

    componentWillUnmount() {
        /* istanbul ignore else */
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    static contextType = MessagesContext;

    getSortByClass(column) {
        let r = 'sorting';
        /* istanbul ignore if */
        if (column === this.state.sortBy) {
            r = `sorting_${this.state.sortDir}`;
        }
        return r;
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        let target = event.target;
        if (event.target.classList.contains('glyphicon')) {
            target = target.parentElement;
        }

        switch (true) {
        case !!~target.className.indexOf('download-image'):
            // Prevent the default anchor click event.
            event.preventDefault();
            let documentId = target.getAttribute('data-document-id');
            AssetTitleActions.downloadImageRendition(documentId, 'Source');
            break;
        }

        return;
    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.state.sortBy === column && this.state.sortDir === 'asc') {
            newSortDir = 'desc';
        }

        this.setState({
            sortBy: column,
            sortDir: newSortDir
        });
    }

    updateRows(props, state) {
        this.$tableAPI.clear();
        if (props.documents) {
            let documents = props.documents;

            documents = Sort(documents, state.sortBy, state.sortDir);

            // Add sorted data to the jQuery datatable.
            documents.forEach(s => {
                //let id = s.get('id');
                let assetId = s.get('assetId');
                let docName = s.getIn(['asset', 'name']);
                let dateCreated = Moment(s.getIn(['asset', 'createdDate'])).format(this.context.intl.messages['date-format']);
                this.$tableAPI.row.add([
                    `<a class="Cur(p) download-image" data-document-id=${assetId}> <span class="glyphicon glyphicon-download-alt"></span></a>`,
                    `<a class="Cur(p) download-image" data-document-id=${assetId}>${docName}</a>`,
                    dateCreated,
                    ''
                ]);
                return;
            });
        }
        this.$tableAPI.draw(false);
        return;
    }

    render() {
        return (
            <div className="container padding-y-10 padding-x-20" id="hide-this">
                <h1>&nbsp;{this.props.label}</h1>
                <table id={`list-documents-table-${this.state.id}`} className="table table-striped responsive">
                    <thead>
                        <tr>
                            <th scope="col" className="no-sort icon-column sorting_disabled" aria-label="Download Asset"><span className="glyphicon glyphicon-download-alt"></span></th>
                            <th scope="col" className={this.getSortByClass('description')} onClick={this.handleSort.bind(this, 'description')}>{this.context.intl.messages['list-documents.table.name']}</th>
                            <th scope="col" className={this.getSortByClass('createdDate')} onClick={this.handleSort.bind(this, 'createdDate')}>{this.context.intl.messages['list-documents.table.date-created']}</th>
                            <th scope="col" className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}></tbody>
                </table>
            </div>
        );
    }

}

export default ListDocumentsTable;

export {ListDocumentsTable};
