/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {MessagesContext} from '../../messages/messages-context';

export default class SaveSearchModal extends React.Component {

    static get propTypes() {
        return {
            onClose: PropTypes.func.isRequired,
            onDone: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            searchName: ''
        };

        this.clearSearchName = this.clearSearchName.bind(this);
        this.handleSearchNameChange = this.handleSearchNameChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);

        return;
    }

    static contextType = MessagesContext;

    clearSearchName() {
        this.setState(() => ({
            searchName: ''
        }));
    }

    handleSearchNameChange(event) {
        let value = event.target.value;
        this.setState(() => ({
            searchName: value
        }));
    }

    handleClose() {
        this.props.onClose();
        this.clearSearchName();
    }

    handleSave() {
        this.props.onDone(this.state.searchName, window.location.href);
        this.clearSearchName();
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center text-uppercase">{this.context.intl.messages['search.save-search.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>{this.context.intl.messages['search.save-search.saved-search-name']}:</label>
                        <input type="text" className="form-control" value={this.state.searchName} onChange={this.handleSearchNameChange}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className=" padding-all-20">
                    <Button bsStyle="default" className="pull-left btn-secondary" onClick={this.handleClose}>{this.context.intl.messages['common.close']}</Button>
                    <Button bsStyle="primary" className="pull-right" onClick={this.handleSave}><i className="glyphicon glyphicon-floppy-disk"></i>&nbsp;{this.context.intl.messages['common.save']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
