/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {FormControl, Glyphicon, InputGroup} from 'react-bootstrap';

import {MessagesContext} from '../messages/messages-context';

export default class Pagination extends React.Component {
    static get propTypes() {
        return {
            className: PropTypes.string,
            offset: PropTypes.number.isRequired,
            onChange: PropTypes.func.isRequired,
            size: PropTypes.number.isRequired,
            total: PropTypes.number.isRequired
        };
    }

    static get defaultProps() {
        return {
            className: ''
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            // Why we use props as state:
            // we need the page number in the component state because the
            // user is allowed to type in here. If we get the page number
            // in render we wouldn't be able to allow the user to edit the value.
            page: this.getPage(this.props.offset, this.props.size),
        };

        this.handleNextPageClick = this.handleNextPageClick.bind(this);
        this.handlePageInputChange = this.handlePageInputChange.bind(this);
        this.handlePageInputKeyPress = this.handlePageInputKeyPress.bind(this);
        this.handlePreviousPageClick = this.handlePreviousPageClick.bind(this);
        this.moveToPage = this.moveToPage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.offset !== nextProps.offset ||
            this.props.size !== nextProps.size) {
            // Props as state... (see above).
            this.setState({
                page: this.getPage(nextProps.offset, nextProps.size)
            });
        }
    }

    static contextType = MessagesContext;

    getOffset(page, size) {
        page = parseInt(page, 10);
        if (isNaN(page)) {
            throw new Error(`${page} is not a valid page number.`);
        }

        return (page - 1) * size;
    }

    getPage(offset, size) {
        // Always add 1, because humans count from 1 ;)
        return Math.floor(offset / size) + 1;
    }

    handleNextPageClick() {
        this.moveToPage(1);
        return;
    }

    handlePageInputChange(event) {
        const value = event.target.value.trim();
        if (!/^\d+$/.test(value) && value) {
            return;
        }

        this.setState({
            page: value
        });
    }

    handlePageInputKeyPress(event) {
        if (event.which !== 13) {
            return;
        }
        const {offset, size, total} = this.props;
        let page = parseInt(this.state.page, 10);
        const lastPage = this.getPage(total, size);
        if (isNaN(page)) {
            page = 1;
            this.setState(() => ({page}));
        } else if (page > lastPage) {
            this.setState(() => ({
                page: lastPage
            }));
        }
        const newOffset = this.getOffset(Math.min(page, lastPage), size);
        if (offset !== newOffset) {
            this.props.onChange(newOffset);
        }
    }

    handlePreviousPageClick() {
        this.moveToPage(-1);
        return;
    }

    moveToPage(add) {
        if (!this.props.total) {
            return;
        }
        // Allways use the page number from props so that we avoid issues when
        // the user writes something in the box but never presses "enter".
        let pageFromProps = this.getPage(this.props.offset, this.props.size);
        let newOffset = this.getOffset(pageFromProps + add, this.props.size);

        // Do nothing, user is trying to invoke Cthulhu with negative results.
        if (newOffset < 0) {
            return;
        }

        // Do nothing, user is trying to view more results that the available.
        if (newOffset > this.props.total) {
            return;
        }
        this.props.onChange(newOffset, pageFromProps);
    }

    render() {
        return (
            <div
                className={ClassNames('visible-xs-block visible-inline-block-sm-up text-center', this.props.className)}
            >
                {this.context.intl.messages['orders.pagination.page']}
                <div className="grid-pagination">
                    <InputGroup bsSize="sm" className="margin-x-5 grid-pagination-container">
                        <InputGroup.Addon onClick={this.handlePreviousPageClick}>
                            <Glyphicon glyph="triangle-left" />
                        </InputGroup.Addon>
                        <FormControl
                            aria-label={this.context.intl.messages['common.page-number']}
                            className="text-center"
                            onChange={this.handlePageInputChange}
                            onKeyPress={this.handlePageInputKeyPress}
                            type="text"
                            value={this.state.page}
                        />
                        <InputGroup.Addon onClick={this.handleNextPageClick}>
                            <Glyphicon glyph="triangle-right" />
                        </InputGroup.Addon>
                    </InputGroup>
                </div>
                {this.context.intl.messages['orders.pagination.page-of']} {Math.ceil(this.props.total / this.props.size) || 1}
            </div>
        );
    }
}
