/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {DownloadActions} from '../../../common/download/download-actions';
import {MessagesContext} from '../../../messages/messages-context';
import {Compare, FormatReleaseDate, IsNotTriggerKey} from '../../../utils/utils';
import {TitleActions, TitleConstants} from '../../title-actions';
import TitleStore from '../../title-store';

class EpisodeRow extends React.Component {
    static get propTypes() {
        return {
            episode: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static contextType = MessagesContext;

    render() {
        let title = this.props.episode.get('name');
        if (title.length > 30) {
            title = `${this.props.episode.get('name').slice(0, 30)}...`;
        }

        let airDate = Moment(this.props.episode.get('titleReleaseDate'));

        if (this.props.episode.get('titleReleaseDateType') === TitleConstants.RELEASE_DATE_TYPES.DISPLAY_NAME.id) {
            airDate = FormatReleaseDate(this.props.episode.get('titleReleaseDateDisplayName'), this.props.episode.get('titleReleaseDateType'));
        } else if (airDate.isValid() && this.props.episode.get('titleReleaseDate') !== undefined) {
            airDate = FormatReleaseDate(airDate, this.props.episode.get('titleReleaseDateType'));
        } else {
            airDate = '-';
        }

        return (
            <tr role="row">
                <td>{this.props.episode.get('orderWithinParent')}</td>
                <td><Link to={`/titles/${this.props.episode.get('id')}`}>{title}</Link></td>
                <td>{airDate}</td>
                <td>{this.props.episode.get('mpmNumber')}</td>
                <td>{this.props.episode.get('log60Lines')}</td>
                <td
                    className="control"
                    style={{display: 'none'}}
                />
            </tr>
        );
    }
}

class EpisodeTable extends React.Component {
    static get propTypes() {
        return {
            episodes: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.props.episodes !== nextProps.episodes;
    }

    static contextType = MessagesContext;

    render() {
        return (
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                <table className="table table-striped datatable-responsive dataTable no-footer dtr-column collapsed"
                    id="DataTables_Table_0"
                    role="grid"
                >
                    <colgroup>
                        <col span="3" />
                        <col span="1" className="inline-block" />
                        <col span="3" />
                    </colgroup>
                    <thead>
                        <tr role="row">
                            <th className="sorting_disabled" rowSpan="1" colSpan="1" style={{width: '36px'}} scope="col">{this.context.intl.messages['title.episodes-list.table.ro']}</th>
                            <th className="sorting_disabled" rowSpan="1" colSpan="1" style={{width: '356px'}} scope="col">{this.context.intl.messages['title.episodes-list.table.title']}</th>
                            <th className="sorting_disabled" rowSpan="1" colSpan="1" style={{width: '128px'}} scope="col">{this.context.intl.messages['title.episodes-list.table.air-date']}</th>
                            <th className="sorting_disabled" rowSpan="1" colSpan="1" style={{width: '92px'}} scope="col">{this.context.intl.messages['common.mpm']}</th>
                            <th className="sorting_disabled" rowSpan="1" colSpan="1" style={{width: '606px'}} scope="col">{this.context.intl.messages['title.episodes-list.table.logline']}</th>
                            <th className="icon-column sorting_disabled control" rowSpan="1" colSpan="1" style={{width: '20px', display: 'none'}} scope="col" />
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.episodes.map(
                            (episode, i) => (<EpisodeRow episode={episode} key={i}/>)
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

class EpisodeList extends React.Component {
    static get propTypes() {
        return {
            episodes: PropTypes.instanceOf(Immutable.List).isRequired,
            titleId: PropTypes.number.isRequired
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.props.episodes !== nextProps.episodes;
    }

    static contextType = MessagesContext;

    handleDownloadEpisodeList() {
        DownloadActions.startDownloadExecution(`title/${this.props.titleId}/episode-list-excel`);
    }

    handleDownloadSynopsis() {
        DownloadActions.startDownloadExecution(`title/${this.props.titleId}/synopsis`, {}, 'application/vnd.wbtv-v1+json');
    }

    render() {
        if (!this.props.episodes.size) {
            return null;
        }

        return (
            <div className="container padding-all-10" id="episodeListContainer">
                <hr />
                <h3>
                    {this.context.intl.messages['title.episodes-list.title']}&nbsp;
                    <Button className="btn-secondary" onClick={this.handleDownloadEpisodeList.bind(this)}><Glyphicon glyph="download-alt" /><span>{this.context.intl.messages['title.episodes-list.button.download']}</span></Button>
                    <Button className="btn-secondary" onClick={this.handleDownloadSynopsis.bind(this)}><Glyphicon glyph="download-alt" /><span>{this.context.intl.messages['title.episodes-list.button.download-synopsis']}</span></Button>
                </h3>
                <EpisodeTable episodes={this.props.episodes} />
            </div>
        );
    }
}

class SeasonEpisodeList extends React.Component {
    static get propTypes() {
        return {
            episodes: PropTypes.instanceOf(Immutable.List).isRequired,
            seasonId: PropTypes.number.isRequired
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.props.episodes !== nextProps.episodes;
    }

    static contextType = MessagesContext;

    handleDownloadEpisodeList() {
        DownloadActions.startDownloadExecution(`title/${this.props.seasonId}/episode-list-excel`);
    }

    handleDownloadSynopsis() {
        DownloadActions.startDownloadExecution(`title/${this.props.seasonId}/synopsis`, {}, 'application/vnd.wbtv-v1+json');
    }

    render() {
        let buttons;
        if (this.props.episodes.size) {
            buttons = [
                <Button className="btn-secondary" onClick={this.handleDownloadEpisodeList.bind(this)}><Glyphicon glyph="download-alt" /><span>{this.context.intl.messages['title.episodes-list.button.download']}</span></Button>,
                <Button className="btn-secondary" onClick={this.handleDownloadSynopsis.bind(this)}><Glyphicon glyph="download-alt" /><span>{this.context.intl.messages['title.episodes-list.button.download-synopsis']}</span></Button>
            ];
        }

        return (
            <div>
                <h4>
                    {this.context.intl.messages['title.episodes-list.title']}&nbsp;
                    {buttons}
                </h4>
                <EpisodeTable episodes={this.props.episodes} />
            </div>
        );
    }
}

class SeasonPanel extends React.Component {
    static get propTypes() {
        return {
            season: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static getStores() {
        return [TitleStore];
    }

    static calculateState() {
        return {
            episodesForSeason: TitleStore.getState().get('episodesForSeason'),
            titles: TitleStore.getState().get('titles')
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            open: false,
        }, this.constructor.calculateState());

        this.completeRelatedTitles = this.completeRelatedTitles.bind(this);
        this.toogleOpen = this.toogleOpen.bind(this);

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !Compare(this.props, nextProps, ['season']) ||
            !Compare(this.state, nextState, ['episodesForSeason', 'titles', 'open']);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.open &&
            !prevState.open &&
            (
                !this.state.episodesForSeason.get(this.props.season.get('orderWithinParent')) ||
                this.props.season !== prevProps.season
            )
        ) {
            TitleActions.getEpisodesFromSeason(this.props.season.get('orderWithinParent'), this.props.season.get('childTitleId'));
        }
    }

    static contextType = MessagesContext;

    toogleOpen() {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        this.setState((prevState) => ({
            open: !prevState.open
        }));
    }

    completeRelatedTitles(titles) {
        if (!titles) {
            return Immutable.List();
        }
        return titles.map(t => {
            let title = this.state.titles.get(t.get('childTitleId').toString());
            if (!title) {
                return;
            }

            // Extend the episode object by adding the
            // relationship info. This is used to get
            // the running order.
            return title.merge(t);
        }).filter(
            t => !!t
        );
    }

    render() {
        let episodeList;
        let episodes = this.completeRelatedTitles(this.state.episodesForSeason.get(this.props.season.get('orderWithinParent')));
        let glyph = 'chevron-down';
        if (this.state.open) {
            episodeList = (
                <SeasonEpisodeList
                    episodes={episodes}
                    seasonId={this.props.season.get('childTitleId')}
                />
            );

            glyph = 'chevron-up';
        }

        return (
            <div
                className="container padding-all-10"
                id="seasonListContainer"
            >
                <h3>
                    <a
                        className="text-default-color accordion-header"
                        onClick={this.toogleOpen}
                        onKeyUp={this.toogleOpen}
                        role="button"
                        tabIndex="0"
                    >
                        {this.context.intl.messages['title.seasons.panel.title'].replace('%%1', this.props.season.get('seasonNumber'))}
                        &nbsp;
                        <small>
                            <Glyphicon glyph={glyph}/>
                        </small>
                    </a>
                </h3>
                {episodeList}
            </div>
        );
    }
}

export default EpisodeList;
const SeasonPanelContainer = Container.create(SeasonPanel);

export {
    EpisodeRow,
    SeasonPanelContainer as SeasonPanel
};

