/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param {'1x1' | '4x3' | '16x9'} ratio
 */
const getRatioClassName = (ratio) => {
    switch (ratio) {
    case '4x3':
        return 'vertical-spacer-4x3';
    case '16x9':
        return 'vertical-spacer-16x9';
    default:
        return 'vertical-spacer';
    }
};

export default function ResultContent({children, ratio}) {
    return (
        <div className="responsive-container">
            <div className={getRatioClassName(ratio)}/>
            <div className="img-container">
                {children}
            </div>
        </div>
    );
}

ResultContent.propTypes = {
    children: PropTypes.node,
    ratio: PropTypes.oneOf(['1x1', '4x3', '16x9']),
};

ResultContent.defaultProps = {
    children: null,
    ratio: '1x1',
};
