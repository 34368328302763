/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

import TitleAssetTable from './asset';
import AssetTitle from './asset-title';
import ClipAssetsPage from './asset-title/clip-assets-page';
import Header from './header';
import TitleInfo from './info';
import TitleMerchandise from './merchandise';
import Nav from './nav';
import SerieNavigationStore from './serie-navigation/serie-navigation-store';
import {TitleActions, TitleConstants} from './title-actions';
import TitleStore from './title-store';
import Download from '../common/download';
import WithPermissions from '../decorators/with-permissions';
import NotFound from '../errors/not-found';
import {CompanyActions} from '../lookup/company/company-actions';
import {TerritoryActions} from '../lookup/territory/territory-actions';
import Preloader from '../preloader';
import SessionStore from '../session/session-store';
import {AssetTitleActions, AssetTitleConstants} from '../titles/asset-title/asset-title-actions';

const AssetTitleClip = WithPermissions(ClipAssetsPage);
const AssetTitleImage = WithPermissions(AssetTitle('IMAGE'));
const AssetTitleVideo = WithPermissions(AssetTitle('VIDEO'));
const TitleFactSheetDownload = Download('title-factsheet');
const TitleAssetPackageDownload = Download('asset-package');
const TitleInfoPage = WithPermissions(TitleInfo);
const TitleAssetTableAudios = TitleAssetTable('audio');
const TitleAssetTableDocuments = TitleAssetTable('documents');
const TitleAssetTableStationDocuments = TitleAssetTable('stations', true);
const TitleAssetTableScripts = TitleAssetTable('scripts');

class TitleLayout extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            assetsLoaded: TitleStore.getState().get('assetsLoaded'),
            videos: TitleStore.getState().getIn(['assets', 'videos']),
            serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
            episodes: TitleStore.getState().get('episodes'),
            genres: TitleStore.getState().get('genres'),
            networks: TitleStore.getState().get('networks'),
            related: TitleStore.getState().get('related'),
            thumbnails: TitleStore.getState().get('thumbnails'),
            title: TitleStore.getState().get('title')
        };
    }

    static getStores() {
        return [SerieNavigationStore, TitleStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidMount() {
        this.init(this.props);
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname === nextProps.location.pathname) {
            return;
        }

        if (this.props.match.params.id !== nextProps.match.params.id) {
            setTimeout(() => TitleActions.clear());
            this.init(nextProps);
            return;
        }

        // check if images and has changed
        let match = nextProps.location.pathname.match(/\/titles\/(\d+)\/images\/(\d+)/);
        // pathname looks like /titles/606177/images/699540
        // if we came from a non image selected state or other page from
        // same title to an image selected one image may not be
        // loaded so we need to refresh :)
        if (match) {
            AssetTitleActions.loadSharedImage(
                AssetTitleConstants.ENTITY_TYPE.TITLE,
                parseInt(match[1], 10),
                parseInt(match[2], 10)
            );
        }

        return;
    }

    componentWillUnmount() {
        if (!SessionStore.isSingleTitle()) {
            // When we have a single title we don't need to clear. This fixes an endless preloader on home logo click.
            setTimeout(() => TitleActions.clear());
        }
    }

    init(props) {
        let id = props.match.params.id;
        let userId = SessionStore.getState().getIn(['authUser', 'id']);

        const query = QueryString.parse(this.props.location.search);
        TitleActions.get(
            id,
            userId,
            SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_LANGUAGE_AVAILABILITY),
            parseInt(query.offset, 10) || 0,
            parseInt(query.size, 10) || 80,
            query.vct
        );
        // Prevent double dispatch error
        setTimeout(() => {
            TerritoryActions.get(0, 500);
            CompanyActions.get(0, 9999);
        });
    }

    getNames(List) {
        return List.map(item => item.get('name')).join(', ');
    }

    render() {
        if (!this.state.title.get('id')) {
            return (<Preloader fixed className="text-primary"/>);
        }

        let generalTabs = [], header;
        let releaseDate = this.state.title.get('titleReleaseDate');
        let releaseDateType = this.state.title.get('titleReleaseDateType');
        const releaseDateDisplayName = this.state.title.get('titleReleaseDateDisplayName');

        if ((this.props.match.isExact || !SessionStore.isMultipleTitles() || !SessionStore.isSingleTitle() ||
            (this.props.location.pathname.includes('video') && !this.state.videos.isEmpty())) && !this.props.location.pathname.includes('download-asset-package')) {
            header = (
                <>
                    <Header
                        genres={this.state.genres}
                        location={this.props.location}
                        networks={this.getNames(this.state.networks)}
                        releaseDate={releaseDate}
                        releaseDateType={releaseDateType}
                        releaseDateDisplayName={releaseDateDisplayName}
                        serieNavigation={this.state.serieNavigation}
                        title={this.state.title}
                    />
                    <Nav
                        location={this.props.location}
                        title={this.state.title}
                    />
                </>
            );
        }

        if (!SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle()) {
            generalTabs = [
                <Route
                    component={AssetTitleImage}
                    exact
                    key={`${this.props.match.path}/images`}
                    path={`${this.props.match.path}/images`}
                />,
                <Route
                    component={AssetTitleImage}
                    exact
                    key={`${this.props.match.path}/images/:imageId`}
                    path={`${this.props.match.path}/images/:imageId`}
                />,
                <Route
                    component={TitleAssetTableAudios}
                    exact
                    key={`${this.props.match.path}/audio`}
                    path={`${this.props.match.path}/audio`}
                />,
                <Route
                    component={TitleAssetTableDocuments}
                    exact
                    key={`${this.props.match.path}/documents`}
                    path={`${this.props.match.path}/documents`}
                />,
                <Route
                    component={TitleAssetTableDocuments}
                    exact
                    key={`${this.props.match.path}/documents/:documentId`}
                    path={`${this.props.match.path}/documents/:documentId`}
                />,
                <Route
                    component={TitleFactSheetDownload}
                    exact
                    key={`${this.props.match.path}/download-all-fact-sheet`}
                    path={`${this.props.match.path}/download-all-fact-sheet`}
                />,
                <Route
                    component={TitleAssetPackageDownload}
                    exact
                    key={`${this.props.match.path}/download-asset-package`}
                    path={`${this.props.match.path}/download-asset-package`}
                />,
                <Route
                    component={TitleAssetTableScripts}
                    exact
                    key={`${this.props.match.path}/scripts`}
                    path={`${this.props.match.path}/scripts`}
                />,
                <Route
                    component={TitleMerchandise}
                    exact
                    key={`${this.props.match.path}/merchandise`}
                    path={`${this.props.match.path}/merchandise`}
                />,
                <Route
                    component={AssetTitleClip}
                    exact
                    key={`${this.props.match.path}/moments`}
                    path={`${this.props.match.path}/moments`}
                />
            ];
        }

        // STUDIO-7711 add station documents routes for any homepage contentype as long as the user has permissions
        if (SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.DOCUMENTS.STATION_DOCUMENTS)) {
            generalTabs.push(<Route
                component={TitleAssetTableStationDocuments}
                exact
                key={`${this.props.match.path}/stations`}
                path={`${this.props.match.path}/stations`}
            />);
            generalTabs.push(<Route
                component={TitleAssetTableStationDocuments}
                exact
                key={`${this.props.match.path}/stations/:documentId`}
                path={`${this.props.match.path}/stations/:documentId`}
            />);
        }

        let videosRoutes;
        if (!SessionStore.isMultipleTitles() || !SessionStore.isSingleTitle() || (this.state.assetsLoaded && !this.state.videos.isEmpty())) {
            videosRoutes = [
                <Route
                    component={AssetTitleVideo}
                    exact
                    key={`${this.props.match.path}/videos`}
                    path={`${this.props.match.path}/videos`}
                />,
                <Route
                    component={AssetTitleVideo}
                    exact
                    key={`${this.props.match.path}/videos/:videoId`}
                    path={`${this.props.match.path}/videos/:videoId`}
                />,
                <Route
                    component={AssetTitleVideo}
                    exact
                    key={`${this.props.match.path}/videos/:videoId/:childVideoId`}
                    path={`${this.props.match.path}/videos/:videoId/:childVideoId`}
                />
            ];
        }

        return (
            <div className={
                ClassNames({
                    'title-beetlejuice': this.state.title.get('id') === TitleConstants.BEETLEJUICE,
                    'title-elf': this.state.title.get('id') === TitleConstants.ELF
                })
            }>
                {header}
                <Switch>
                    <Route
                        component={TitleInfoPage}
                        exact
                        path={`${this.props.match.path}/`}
                    />
                    {videosRoutes}

                    {generalTabs}

                    <Route component={NotFound}/>
                </Switch>
            </div>
        );
    }

}

export default Container.create(TitleLayout);
