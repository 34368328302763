/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
/* istanbul ignore file */
import {STATIONS_SUB_TYPES} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {filter} from 'rxjs/operators';

import AssetSection from './asset-section';
import AudioModal from './audio-modal';
import PDFPreviewModal from './pdf-preview-modal';
import SessionStore from '../../session/session-store';
import AssetFilter from '../asset-title/filter';
import {EpisodeNavigation} from '../serie-navigation';
import SerieNavigationStore from '../serie-navigation/serie-navigation-store';
import {TitleActions, TitleConstants} from '../title-actions';
import TitleStore from '../title-store';

import DocumentTitle from '~/src/common/document-title';
import {MessagesContext} from '~/src/messages/messages-context';
import {NotificationActions} from '~/src/notification/notification-actions';
import Preloader from '~/src/preloader';
import {ObservableRoute, RouterActions} from '~/src/router/router-actions';
import {AssetTitleActions, AssetTitleConstants} from '~/src/titles/asset-title/asset-title-actions';
import AssetTitleStore from '~/src/titles/asset-title/asset-title-store';

export default (assetType, showFilters = false) => {
    class AssetIndex extends Component {

        static get propTypes() {
            return {
                match: PropTypes.object.isRequired,
                location: PropTypes.object.isRequired,
            };
        }

        static calculateState() {
            return {
                assetFacets: AssetTitleStore.getState().get('assetFacets'),
                assetFacetCounts: AssetTitleStore.getState().get('assetFacetCounts'),
                assets: TitleStore.getState().get('assets'),
                assetsLoaded: TitleStore.getState().get('assetsLoaded'),
                audioPreview: TitleStore.getState().get('audioPreview'),
                audioSeasonCounts: TitleStore.getState().get('audioSeasonCounts'),
                documentPdfUrl: AssetTitleStore.getState().get('documentPdfUrl'),
                documentId: AssetTitleStore.getState().get('documentId'),
                documentsSeasonCounts: TitleStore.getState().get('documentsSeasonCounts'),
                related: TitleStore.getState().get('related'),
                scriptsSeasonCounts: TitleStore.getState().get('scriptsSeasonCounts'),
                seasonEpisodeDocuments: AssetTitleStore.getState().getIn(['documents', 'seasonEpisodeDocuments']),
                seasonsMap: TitleStore.getState().get('seasonsMap'),
                serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
                stationDocuments: AssetTitleStore.getState().getIn(['documents', 'stationDocuments']),
                title: TitleStore.getState().get('title'),
            };
        }

        static getStores() {
            return [TitleStore, SerieNavigationStore, AssetTitleStore];
        }

        constructor(props) {
            super(props);

            this.state = this.constructor.calculateState();

            this.getAssetsList = this.getAssetsList.bind(this);
            this.onClosePdfPreview = this.onClosePdfPreview.bind(this);
            this.redirectIfEmpty = this.redirectIfEmpty.bind(this);
        }

        componentDidMount() {
            if (assetType === 'stations') {
                const query = QueryString.parse(this.props.location.search);
                // Allows the asset list component to show filtering component for station docs
                AssetTitleActions.getTitleAssetFacetCounts(this.props.match.params.id, 'DOCUMENT', query['format-run-week']);

                let canceler = ObservableRoute.pipe(filter(x => {
                    let cancel = x.pathname.indexOf(`titles/${this.props.match.params.id}`) === -1;
                    return cancel;
                }));

                AssetTitleActions.getTitleAssetsDocuments(
                    AssetTitleConstants.ENTITY_TYPE.TITLE,
                    this.props.match.params.id,
                    SessionStore.getState().getIn(['authUser', 'id']),
                    query.vct,
                    parseInt(query.offset, 10) || 0,
                    parseInt(query.size, 10) || 9999,
                    canceler,
                    query['sort-field-name'],
                    query['sort-direction'],
                    query['format-run-week']
                );
            }
        }

        shouldComponentUpdate(nextProps, nextState) {
            const query = QueryString.parse(this.props.location.search);
            const nextQuery = QueryString.parse(nextProps.location.search);
            if (this.state.assets !== nextState.assets ||
                this.state.stationDocuments !== nextState.stationDocuments ||
                this.state.documentPdfUrl !== nextState.documentPdfUrl ||
                this.state.assetFacets !== nextState.assetFacets ||
                this.state.assetFacetCounts !== nextState.assetFacetCounts ||
                query['format-run-week'] !== nextQuery['format-run-week']) {
                return true;
            }

            return false;
        }

        UNSAFE_componentWillUpdate(nextProps) {
            if (assetType === 'stations') {
                const getVCT = props => {
                    const query = QueryString.parse(props.location.search);
                    let result = [];
                    if (query.vct) {
                        if (Array.isArray(query.vct)) {
                            result = query.vct;
                        } else {
                            result = [query.vct];
                        }
                    }
                    return Immutable.fromJS(result);
                };

                let vct = getVCT(this.props);
                let nextVCT = getVCT(nextProps);
                const query = QueryString.parse(this.props.location.search);
                const nextQuery = QueryString.parse(nextProps.location.search);
                if (
                    query.offset !== nextQuery.offset ||
                    query.size !== nextQuery.size ||
                    query['sort-field-name'] !== nextQuery['sort-field-name'] ||
                    query['sort-direction'] !== nextQuery['sort-direction'] ||
                    this.props.match.params.id !== nextProps.match.params.id ||
                    query['format-run-week'] !== nextQuery['format-run-week'] ||
                    !vct.equals(nextVCT)
                ) {
                    AssetTitleActions.loading();

                    AssetTitleActions.getTitleAssetFacetCounts(parseInt(this.props.match.params.id, 10), 'DOCUMENT', query['format-run-week']);

                    let canceler = ObservableRoute.pipe(filter(x => {
                        let cancel = x.pathname.indexOf(`titles/${nextProps.match.params.id}`) === -1;
                        return cancel;
                    }));

                    AssetTitleActions.getTitleAssetsDocuments(
                        AssetTitleConstants.ENTITY_TYPE.TITLE,
                        nextProps.match.params.id,
                        SessionStore.getState().getIn(['authUser', 'id']),
                        nextQuery.vct,
                        parseInt(nextQuery.offset, 10) || 0,
                        parseInt(nextQuery.size, 10) || 9999,
                        canceler,
                        nextQuery['sort-field-name'],
                        nextQuery['sort-direction'],
                        nextQuery['format-run-week'],
                    );
                }
            }
        }

        componentDidUpdate() {
            this.redirectIfEmpty(this.state.assets.get(this.getAssetType()), this.state.assetsLoaded);
        }

        static contextType = MessagesContext;

        getAssetsList() {
            let assetsList = this.state.assets.get(this.getAssetType());

            // BRAIN-4678 Prevent station documents from showing on documents tab
            if (assetType === 'documents') {
                assetsList = assetsList.filter(asset => !STATIONS_SUB_TYPES.includes(asset.get('contentType')));
            } else if (assetType === 'stations') {
                assetsList = this.state.stationDocuments;
            }
            return assetsList;
        }

        getAssetType() {
            if (assetType === 'stations') {
                return 'documents';
            }
            return assetType;
        }

        redirectIfEmpty(assetsList, assetsLoaded) {
            if (!assetsList.size && assetsLoaded) {
                setTimeout(() => {
                    RouterActions.redirect(`/titles/${this.props.match.params.id}`);
                    NotificationActions.showAlertWarning(`title.error.no-available-asset.${this.getAssetType()}`);
                }, 0);
                return;
            }
            return;
        }

        onClosePdfPreview() {
            AssetTitleActions.closePdfPreview();
            RouterActions.redirect(`/titles/${this.props.match.params.id}/${assetType}`);
        }

        render() {
            const assetsList = this.getAssetsList();
            let wrapperClass;
            if (!this.state.assetsLoaded) {
                return (<Preloader fixed className="text-primary"/>);
            }

            let sections = [];
            let contentTypeFilters = Immutable.Set();
            if (assetType === 'stations') {
                wrapperClass = 'col-md-2';
                sections = ['stations'];
                const query = QueryString.parse(this.props.location.search);
                if (query.vct) {
                    if (Array.isArray(query.vct)) {
                        contentTypeFilters = Immutable.Set.of(...query.vct);
                    } else {
                        contentTypeFilters = Immutable.Set.of(query.vct);
                    }
                } else {
                    contentTypeFilters = Immutable.Set.of(
                        ...AssetTitleConstants.CONTENT_TYPES.DOCUMENT.DOCUMENT_TYPE.ALL,
                    );
                }
            } else {
                switch (this.state.title.get('categoryGroup')) {
                case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
                    sections = ['miniSeries', 'episodes'];
                    break;
                case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
                    sections = ['series', 'seasons'];
                    break;

                case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                    sections = ['season', 'episodes'];
                    break;

                case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                    sections = ['episode'];
                    break;
                default:
                    sections = ['singleRelease'];
                    break;
                }
            }

            let assetFilter;
            let assetFacetCounts = this.state.assetFacetCounts.get(this.props.match.params.id);
            if (showFilters && assetFacetCounts) {
                assetFilter = <AssetFilter
                    assetFacetCounts={assetFacetCounts}
                    assetFacets={this.state.assetFacets}
                    assetType = {assetType}
                    contentTypeFilters={contentTypeFilters}
                    location={this.props.location}
                />;
            }

            return (
                <DocumentTitle
                    message={`document-titles.title.title-asset.${assetType}`}
                >
                    <div className="container padding-all-20">
                        <PDFPreviewModal className="document-modal" documentId={this.state.documentId} match={this.props.match} onHide={this.onClosePdfPreview} pdfUrl={this.state.documentPdfUrl} />
                        <AudioModal audio={this.state.audioPreview.get('audio')} close={TitleActions.hideAudioPreview} show={this.state.audioPreview.get('show')} />
                        <div className="row margin-top-10">
                            <div className="container">
                                <div className={wrapperClass}>
                                    {assetFilter}
                                </div>

                                <h3 className="margin-top-0 margin-left-5 margin-bottom-0 h2 title-and-episodes-navigator">
                                    {this.context.intl.messages[`asset.${assetType}`]}
                                    <EpisodeNavigation serieNavigation={this.state.serieNavigation} titleId={this.state.title.get('id')} location={this.props.location}/>
                                </h3>

                                <div className="col-md-10">
                                    {sections.map((section, i) => {
                                        return (
                                            <div key={i}>
                                                <hr />
                                                <AssetSection
                                                    assets={assetsList}
                                                    assetType={assetType}
                                                    audioSeasonCounts={this.state.audioSeasonCounts}
                                                    documentsSeasonCounts={this.state.documentsSeasonCounts}
                                                    location={this.props.location}
                                                    match={this.props.match}
                                                    scriptsSeasonCounts={this.state.scriptsSeasonCounts}
                                                    seasonEpisodeDocuments={this.state.seasonEpisodeDocuments}
                                                    seasonsMap={this.state.seasonsMap}
                                                    serieNavigation={this.state.serieNavigation}
                                                    title={this.state.title}
                                                    type={section}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </DocumentTitle>
            );
        }

    }

    return Container.create(AssetIndex);
};
