/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {v4 as UUID} from 'uuid';

import AssetsNavigationItem from './assets-navigation-item';
import {MessagesContext} from '../../messages/messages-context';
import Pagination from '../../pagination';
import {IsNotTriggerKey} from '../../utils/utils';

export default class AssetsNavigation extends React.PureComponent {
    static get propTypes() {
        return {
            children: PropTypes.node,
            handlePageChange: PropTypes.func.isRequired,
            handlePageSizeChange: PropTypes.func.isRequired,
            handleViewChange: PropTypes.func.isRequired,
            isGridView: PropTypes.bool.isRequired,
            offset: PropTypes.number.isRequired,
            pageSize: PropTypes.number.isRequired,
            pageSizes: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
            totalCount: PropTypes.number.isRequired,
        };
    }

    static get defaultProps() {
        return {
            children: null,
        };
    }

    constructor(props) {
        super(props);

        this.perPageChosenId = UUID();

        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.toGridViewMode = this.toGridViewMode.bind(this);
        this.toListViewMode = this.toListViewMode.bind(this);
    }

    static contextType = MessagesContext;

    handlePageSizeChange(event) {
        const size = parseInt(event.target.value);
        this.props.handlePageSizeChange(size);
    }

    toGridViewMode(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        this.props.handleViewChange(true);
    }

    toListViewMode(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        this.props.handleViewChange(false);
    }

    render() {
        const {
            children, handlePageChange, isGridView, offset,
            pageSize, pageSizes, totalCount
        } = this.props;
        const options = pageSizes.map(size => <option value={`${size}`} key={size}>{size}</option>);
        return (
            <div className="pull-right-sm-up grid-options padding-y-10 md:W(100%)">

                {children}

                <AssetsNavigationItem>
                    <span>{this.context.intl.messages['titles.videos.display']}:</span>
                    <span
                        aria-label={this.context.intl.messages['common.grid-view']}
                        className={ClassNames('display-type-switch', 'glyphicon', 'glyphicon-th', 'margin-x-5', {active: isGridView})}
                        onClick={this.toGridViewMode}
                        onKeyUp={this.toGridViewMode}
                        role="button"
                        tabIndex={0}
                        title={this.context.intl.messages['common.grid-view']}
                    />
                    <span
                        aria-label={this.context.intl.messages['common.list-view']}
                        className={ClassNames('display-type-switch', 'glyphicon', 'glyphicon-align-justify', {active: !isGridView})}
                        onClick={this.toListViewMode}
                        onKeyUp={this.toListViewMode}
                        role="button"
                        tabIndex={0}
                        title={this.context.intl.messages['common.list-view']}
                    />
                </AssetsNavigationItem>

                <AssetsNavigationItem>
                    <select
                        aria-labelledby={this.perPageChosenId}
                        className="form-control margin-right-5 show-per-page"
                        onChange={this.handlePageSizeChange}
                        value={pageSize}
                    >
                        {options}
                    </select>
                    <span id={this.perPageChosenId}>{this.context.intl.messages['common.per-page']}</span>
                </AssetsNavigationItem>

                <AssetsNavigationItem showDivider={false}>
                    <Pagination
                        offset={offset}
                        onChange={handlePageChange}
                        size={pageSize}
                        total={totalCount}
                    />
                </AssetsNavigationItem>
            </div>
        );
    }
}
