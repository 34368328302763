/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './digital-series.less';

export default class DigitalSeries extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.digital-series">
                <div className="container op_container_digital_series">

                    <div className="row">
                        <div className="col-md-12 carousel_correction">
                            <img src="https://www.wbtvd.com/page/digital-series/header.jpg" alt="Title" className="banner" />
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-3">

                            <a href="/titles/1084468" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/aimhigh_season1_227x290.jpg" alt="" className="left_poster" />
                                <h4>Aim High</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="/titles/825420" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/BloodCell.jpg" alt="" className="center_poster" />
                                <h4>Blood Cell</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="/titles/624295" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/chadamposterpic.jpg" alt="" className="right_poster" />
                                <h4>Chadam</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="/titles/710271" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/ChildrensHospital_DisplayArt_vertical_Netflix.jpg" alt="" className="extreme_right_poster" />
                                <h4>Childrens Hospital</h4>
                            </a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">

                            <a href="/titles/624398" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/Downers_Grove_0031rkasia_c.jpg" alt="" className="left_poster"/>
                                <h4>Downers Grove</h4>
                            </a>
                        </div>

                        <div className="col-md-3">

                            <a href="/titles/624335" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/exposedtitle.jpg" alt="" className="center_poster"/>
                                <h4>Exposed</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="https://www.youtube.com/user/HplusDigitalSeries" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/h__227x290.jpg" alt="" className="right_poster"/>
                                <h4>H+</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="/titles/624305" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/HighDrama_Keyart.jpg" alt="" className="extreme_right_poster"/>
                                <h4>High Drama</h4>
                            </a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">

                            <a href="/titles/828721" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/THE_LAKE_POSTER_20x30_title.jpg" alt="" className="left_poster"/>
                                <h4>The Lake</h4>
                            </a>
                        </div>

                        <div className="col-md-3">

                            <a href="/titles/1281865" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/love_15.jpg" alt="" className="center_poster"/>
                                <h4>Love 15</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="/titles/1208586" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/mk_legacy_season1_227x290.jpg" alt="" className="right_poster"/>
                                <h4>Mortal Kombat Legacy</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="/titles/1214751" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/PTMM_Logo.jpg" alt="" className="extreme_right_poster"/>
                                <h4>Paul the Male Matchmaker</h4>
                            </a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <a href="/titles/933048" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/Pushed_title.jpg" alt="" className="left_poster"/>
                                <h4>Pushed</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="/titles/624327" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/RichGirlPoorGirl.jpg" alt="" className="center_poster"/>
                                <h4>Rich Girl Poor Girl</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="/titles/1362712" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/robin_banks.jpg" alt="" className="right_poster"/>
                                <h4>Robin Banks and the Bank Roberts</h4>
                            </a>
                        </div>
                        <div className="col-md-3">

                            <a href="/titles/741480" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/WBITV_ROCKVILLE_MIC_WHITE_.jpg" alt="" className="extreme_right_poster"/>
                                <h4>Rockville CA</h4>
                            </a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">

                            <a href="/titles/620667" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/digital-series/SG_mb_6.jpg" alt="" className="left_poster"/>
                                <h4>Sorority Forever</h4>
                            </a>
                        </div>
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-3">
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}
