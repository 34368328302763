/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './products.less';

export default class Products extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.products">
                <div className="op_container_products">
                    <div
                        className="products-header"
                        style={{backgroundColor: '#1b1e22',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            color: '#FFF',
                            backgroundPosition: 'center center'}}>
                        <div className="container padding-all-20">
                            <div className="row margin-bottom-20">
                                <div className="col-md-4"></div>
                                <div className="col-md-4 padding-all-20">
                                    <img src={require('./products/products-logo.png')} className="img-responsive" alt=""/>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div
                                    className="col-md-8"
                                    style={{
                                        fontSize: '150%',
                                        paddingBottom: '40px',
                                        textAlign: 'center'}}>
                                    <p>View or download information about our products below.</p>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                        </div>
                    </div>

                    <div className="container padding-all-20">
                        <div className="row">
                            <div className="col-xs-12 col-lg-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="https://www.wbtvd.com/page/products/WBTVD.COM_OneSheet.pdf"
                                            target="_blank">
                                            <img
                                                src={require('./products/product6.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a
                                            className="thumbnail-title"
                                            href="https://www.wbtvd.com/page/products/WBTVD.COM_OneSheet.pdf"
                                            target="_blank">
                                            <i className="glyphicon glyphicon-download-alt"></i> WBTVD.com
                                        </a>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="https://www.wbtvd.com/page/products/Brainiac-One-Sheet.pdf"
                                            target="_blank">
                                            <img
                                                src={require('./products/product4.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a
                                            className="thumbnail-title"
                                            href="https://www.wbtvd.com/page/products/Brainiac-One-Sheet.pdf"
                                            target="_blank">
                                            <i className="glyphicon glyphicon-download-alt"></i> Brainiac
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="https://www.wbtvd.com/page/products/WMScreenersApp_OneSheet.pdf"
                                            target="_blank">
                                            <img
                                                src={require('./products/product2.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a
                                            className="thumbnail-title"
                                            href="https://www.wbtvd.com/page/products/WMScreenersApp_OneSheet.pdf"
                                            target="_blank">
                                            <i className="glyphicon glyphicon-download-alt"></i> WM Screeners App
                                        </a>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="https://www.wbtvd.com/page/products/WMScreenersApp-FeatureList.pdf"
                                            target="_blank">
                                            <img
                                                src={require('./products/product5.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a
                                            className="thumbnail-title"
                                            href="https://www.wbtvd.com/page/products/WMScreenersApp-FeatureList.pdf"
                                            target="_blank">
                                            <i className="glyphicon glyphicon-download-alt"></i> WM Screeners App
                                            Features Grid
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-lg-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="https://www.wbtvd.com/page/products/FYC-One-Sheet.pdf"
                                            target="_blank">
                                            <img
                                                src={require('./products/product1.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a
                                            className="thumbnail-title"
                                            href="https://www.wbtvd.com/page/products/FYC-One-Sheet.pdf"
                                            target="_blank">
                                            <i className="glyphicon glyphicon-download-alt"></i> FYC APP
                                        </a>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="https://www.wbtvd.com//page/products/Clip-Library-One-Sheet.pdf"
                                            target="_blank">
                                            <img
                                                src={require('./products/product3.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a
                                            className="thumbnail-title"
                                            href="https://www.wbtvd.com//page/products/Clip-Library-One-Sheet.pdf"
                                            target="_blank">
                                            <i className="glyphicon glyphicon-download-alt"></i> Downloadable Clip
                                            Library
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-lg-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 thumbnail-container padding-all-10">
                                        <a
                                            href="https://www.wbtvd.com//page/products/WBTV_MarketingOperationsDigitalProducts_OneSheet.pdf"
                                            target="_blank">
                                            <img
                                                src={require('./products/product7.jpg')}
                                                className="img-responsive thumbnail-border"
                                                alt="..."
                                            />
                                        </a>
                                        <a
                                            className="thumbnail-title"
                                            href="https://www.wbtvd.com/page/products/WBTV_MarketingOperationsDigitalProducts_OneSheet.pdf"
                                            target="_blank">
                                            <i className="glyphicon glyphicon-download-alt"></i> Products Summary
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </DocumentTitle>

        );
    }
}
