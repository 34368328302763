/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_CT_TYPES, DOCUMENT_CT_NAME_BY_ID, displayOrder, STATIONS_FORMAT_SHEETS} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import ListAsset, {AssetListColumns} from './list-asset';
import {AssetTitleActions} from '../../titles/asset-title/asset-title-actions';
import SeasonsSummary from '../../titles/asset-title/seasons-summary';

const COLUMNS = {
    audio: [
        AssetListColumns.download, AssetListColumns.order, AssetListColumns.runtime,
        AssetListColumns.play, AssetListColumns.name, AssetListColumns.isNew, AssetListColumns.description,
        AssetListColumns.contentType
    ],
    documents: [
        AssetListColumns.download, AssetListColumns.order, AssetListColumns.documentName,
        AssetListColumns.isNew, AssetListColumns.documentType, AssetListColumns.contentType
    ],
    scripts: [
        AssetListColumns.download, AssetListColumns.order, AssetListColumns.scriptName,
        AssetListColumns.isNew, AssetListColumns.contentType
    ],
    stationDocuments: [
        AssetListColumns.download, AssetListColumns.documentName
    ],
    stationDocumentsAirSchedule: [
        AssetListColumns.download, AssetListColumns.documentName, AssetListColumns.runDate
    ],
    stationDocumentsFormatSheets: [
        AssetListColumns.download, AssetListColumns.formatSheetWeek, AssetListColumns.documentName, AssetListColumns.runDate
    ],
};

export default class AssetSection extends React.Component {
    static get propTypes() {
        return {
            assets: PropTypes.instanceOf(Immutable.List).isRequired,
            assetType: PropTypes.string.isRequired,
            audioSeasonCounts: PropTypes.instanceOf(Immutable.List).isRequired,
            documentsSeasonCounts: PropTypes.instanceOf(Immutable.List).isRequired,
            match: PropTypes.object.isRequired,
            scriptsSeasonCounts: PropTypes.instanceOf(Immutable.List).isRequired,
            seasonEpisodeDocuments: PropTypes.instanceOf(Immutable.List),
            seasonsMap: PropTypes.instanceOf(Immutable.Map).isRequired,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            type: PropTypes.oneOf(['singleRelease', 'series', 'season', 'seasons', 'episode', 'episodes']).isRequired
        };
    }

    componentDidMount() {
        if (this.props.assetType === 'documents' && this.props.type === 'episodes') {
            const where = this.props.serieNavigation.getIn(['serieMap', `${this.props.title.get('id')}`]);
            if (!where) {
                return;
            }
            let episodeIds;
            if (!this.props.serieNavigation.get('seasons')) {
                const episodes = this.props.serieNavigation.get('episodes');
                episodeIds = episodes.map(e => {
                    return e.get('childTitleId');
                });
            } else {
                const episodes = this.props.serieNavigation.getIn(['seasons', where.get(0)]).get('episodes');
                episodeIds = episodes.map(e => {
                    return e.get('titleId');
                });
            }
            AssetTitleActions.getAllSeasonEpisodesDocuments(episodeIds);
        }
    }

    filterAssets(id, checkEquals) {
        return this.props.assets.filter(asset => {
            if (checkEquals) {
                return asset.get('titleId') === id;
            }
            return asset.get('titleId') !== id;
        });
    }

    render() {
        let columns = COLUMNS[this.props.assetType];
        let titleId = this.props.title.get('id');
        let view = null;

        if (this.props.assetType === 'stations') {
            // Group documents by type and create a ListAsset for each group
            const documents = this.filterAssets(titleId, true);
            const groupedByContentType = documents.groupBy(doc => doc.get('contentType'));
            const views = displayOrder.map((contentTypeId) => {
                const groupAssets = groupedByContentType.get(parseInt(contentTypeId, 10));

                if (!groupAssets?.size) {
                    return <span key={`station-documents-group-${contentTypeId}`} />;
                }

                columns = COLUMNS.stationDocuments;
                if (contentTypeId === DOCUMENT_CT_TYPES.CONTENT_TYPES.STATION_DOCUMENT.subType.STA_AIR_SCHEDULES.id) { // Air Schedules
                    columns = COLUMNS.stationDocumentsAirSchedule;
                }
                if (STATIONS_FORMAT_SHEETS.includes(parseInt(contentTypeId, 10))) { // Format Sheets
                    columns = COLUMNS.stationDocumentsFormatSheets;
                }

                return <div key={`station-documents-group-${contentTypeId}`}>
                    <ListAsset
                        accordion={false}
                        assets={groupAssets}
                        assetType={this.props.assetType}
                        columns={columns}
                        match={this.props.match}
                        name={`station-documents-group-${contentTypeId}`}
                        nameOverride={DOCUMENT_CT_NAME_BY_ID[contentTypeId]}
                    /><br />
                </div>;
            });

            view = (
                <div>
                    {views}
                </div>
            );
        } else {

            let assets = this.filterAssets(titleId, true);
            if (this.props.assetType === 'documents') {
                assets = assets.sort((a, b) => {
                    return (a.get('assetOrder') - b.get('assetOrder'));
                });
            }

            switch (this.props.type) {
            case 'episode':
            case 'season':
            case 'series':
            case 'singleRelease':
            case 'miniSeries':
                view = (
                    <ListAsset
                        accordion={false}
                        assets={assets}
                        assetType={this.props.assetType}
                        columns={columns}
                        match={this.props.match}
                        name={this.props.type}
                    />
                );
                break;

            case 'seasons':
                let assetSeasonCounts;
                let assetType;
                let title;

                if (this.props.assetType === 'documents') {
                    assetSeasonCounts = this.props.documentsSeasonCounts;
                    assetType = 'documents';
                    title = 'Documents';
                }

                if (this.props.assetType === 'audio') {
                    assetSeasonCounts = this.props.audioSeasonCounts;
                    assetType = 'audio';
                    title = 'Audio';
                }

                if (this.props.assetType === 'scripts') {
                    assetSeasonCounts = this.props.scriptsSeasonCounts;
                    assetType = 'scripts';
                    title = 'Scripts';
                }

                view = (
                    <SeasonsSummary
                        assetSeasonCounts={assetSeasonCounts}
                        assetTypePath={assetType}
                        seasonsMap={this.props.seasonsMap}
                        title={title}
                    />
                );
                break;

            case 'episodes':
                if (this.props.assetType === 'documents' && this.props.seasonEpisodeDocuments) {
                    assets = this.props.seasonEpisodeDocuments;
                }
                view = (
                    <ListAsset
                        accordion
                        assets={assets}
                        assetType={this.props.assetType}
                        columns={columns}
                        match={this.props.match}
                        name={this.props.type}
                    />
                );
                break;
            }
        }

        return view;
    }
}
