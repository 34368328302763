/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';

import GetImageUrl from './utils/get-image-url';
import GetIsImageLoading from './utils/get-is-image-loading';
import ClipGridItem from '../../common/clips/clip-grid-item';
import {ClipActions} from '../../titles/clip/clip-actions';

export default class ResultClip extends PureComponent {
    static get propTypes() {
        return {
            clip: PropTypes.instanceOf(Immutable.Map).isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.Map).isRequired,
            canAddToCart: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            imageUrl: '',
            canAddToCart: false,
        };
    }

    constructor(props) {
        super(props);
    }

    handlePlay = (event) => {
        event.preventDefault();
        const {clip} = this.props;
        ClipActions.playAutoClip(
            clip,
            clip.getIn(['browseTitleList', 0, 'id'])
        );
    };

    render() {
        const {clip, thumbnails, canAddToCart} = this.props;
        if (!clip) {
            return null;
        }
        const imageUrl = GetImageUrl(clip, thumbnails, 'moments');
        const isImageLoading = GetIsImageLoading(clip, thumbnails, 'moments');

        let thumbnailsUrl = clip.get('thumbnailsUrl');
        if (imageUrl) {
            thumbnailsUrl = thumbnailsUrl.push(imageUrl);
        }

        let clipTitle = null;

        const parentTitleId = clip.getIn([
            'browseTitleList',
            0,
            'parentTitleId',
        ]);
        const rootTitleName = clip.get('rootTitleDisplayName');

        const rootTitleId = clip.get('rootTitleId');

        if (rootTitleName && rootTitleId) {
            clipTitle = <Link to={`/titles/${rootTitleId}/moments`}>{rootTitleName}</Link>;
        }

        return (
            <ClipGridItem
                onPlay={this.handlePlay}
                titleId={parentTitleId}
                clip={clip}
                thumbnailsUrl={thumbnailsUrl}
                isImageLoading={isImageLoading}
                clipTitle={clipTitle}
                canAddToCart={canAddToCart}
            />
        );
    }
}
