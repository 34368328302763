/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import AssetSection from './asset-section';
import {AssetTitleConstants} from './asset-title-actions';
import {MessagesContext} from '../../messages/messages-context';

const GALLERY_SECTIONS = [
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.SOCIAL_MEDIA,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.social-media'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.LOGO,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.logo'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.KEY_ART,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.key-art'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.OUTDOOR_ART,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.outdoor-art'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.ONLINE,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.online'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.SVOD,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.svod'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.GALLERY,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.gallery'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.ANIMATION_PRODUCTON_MATERIALS,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.animation-production-materials'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.BTS,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.bts'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.EVENTS,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.events'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.PHOTOGRAPHY_STILLS,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.photography-stills'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.OTHER,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.other'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.HBO_MAX,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.hbo-max'
    },
    {
        imageSuperType: AssetTitleConstants.CONTENT_TYPES.IMAGE.CLIENT_SAMPLES,
        groupKey: AssetTitleConstants.IMAGE.GROUP_KEY.TITLE_IMAGE,
        title: 'title.image-list.title.client-samples'
    }
];
class GridView extends React.Component {
    static get propTypes() {
        return {
            assetFacetCounts: PropTypes.instanceOf(Immutable.Map).isRequired,
            assetType: PropTypes.string.isRequired,
            canAddAllToCart: PropTypes.bool,
            canAddToCart: PropTypes.bool,
            canDownloadApproval: PropTypes.bool,
            canDownloadFull: PropTypes.bool,
            canDownloadLoRes: PropTypes.bool,
            canDownloadSource: PropTypes.bool,
            canInstantOrderVideos: PropTypes.bool,
            contentTypeFilters: PropTypes.instanceOf(Immutable.Set).isRequired,
            onSortChange: PropTypes.func.isRequired,
            location: PropTypes.object.isRequired,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            showAirDate: PropTypes.bool,
            showCaptions: PropTypes.bool.isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            videos: PropTypes.instanceOf(Immutable.Map).isRequired,
            images: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            canAddAllToCart: false,
            canAddToCart: false,
            canDownloadApproval: false,
            canDownloadFull: false,
            canDownloadLoRes: false,
            canDownloadSource: false,
            canInstantOrderVideos: false,
            showAirDate: false,
        };
    }

    static contextType = MessagesContext;

    render() {

        let sections;

        switch (this.props.assetType) {

        case AssetTitleConstants.ASSET_CATEGORY.VIDEO:


            let titleId = parseInt(this.props.title.get('id'), 10);

            // Create all sections if there are any videos for them
            sections = this.props.videos.map((videos, videoSuperType) => {
                if (!videos.get('results').size) {
                    return null;
                }

                return (
                    <AssetSection
                        assetType={this.props.assetType}
                        canAddToCart={this.props.canAddToCart}
                        canDownloadApproval={this.props.canDownloadApproval}
                        canDownloadFull={this.props.canDownloadFull}
                        canDownloadLoRes={this.props.canDownloadLoRes}
                        canDownloadSource={this.props.canDownloadSource}
                        canInstantOrderVideos={this.props.canInstantOrderVideos}
                        key={videoSuperType}
                        location={this.props.location}
                        onSortChange={this.props.onSortChange}
                        serieNavigation={this.props.serieNavigation}
                        showAirDate={this.props.showAirDate}
                        superType={videoSuperType}
                        titleId={titleId}
                        videos={videos}
                    />
                );
            }).toArray().filter(s => !!s);
            break;

        case AssetTitleConstants.ASSET_CATEGORY.IMAGE:

            titleId = this.props.title.get('id');

            // Get a list of all images, but in the right order (by category).
            // so that they show right in the lightbox.
            let groupedImages = Immutable.List();

            // TODO: move it to title part
            // filter based on category (remove season videos from series)
            let images = this.props.images;

            sections = GALLERY_SECTIONS.map((gallerySection) => {
                let filtered = images.getIn([gallerySection.imageSuperType.key, 'results']);
                groupedImages = groupedImages.concat(filtered);


                return (
                    <AssetSection
                        assetFacetCounts={this.props.assetFacetCounts}
                        assetType={this.props.assetType}
                        canAddAllToCart={this.props.canAddAllToCart}
                        canDownloadAllAssets={
                            gallerySection.imageSuperType !== AssetTitleConstants.CONTENT_TYPES.IMAGE.KEY_ART && (
                                this.props.canDownloadSource || this.props.canDownloadFull || this.props.canDownloadLoRes
                            )
                        }
                        canAddToCart={this.props.canAddToCart}
                        canDownloadApproval={this.props.canDownloadApproval}
                        canDownloadFull={this.props.canDownloadFull}
                        canDownloadLoRes={this.props.canDownloadLoRes}
                        canDownloadSource={this.props.canDownloadSource}
                        contentTypeFilters={this.props.contentTypeFilters}
                        images={filtered}
                        location={this.props.location}
                        onSortChange={this.props.onSortChange}
                        superType={gallerySection.imageSuperType}
                        showCaptions={this.props.showCaptions}
                        title={this.context.intl.messages[gallerySection.title]}
                        titleId={titleId}
                    />
                );
            });
            break;
        }
        return (
            <div className="display-results display-results-grid">
                {sections}
            </div>
        );
    }
}

export default GridView;
export {
    GALLERY_SECTIONS as GridViewConstants
};
