/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import GalleryThumbnail from './gallery-thumbnail';
import {BreakIntoGroups} from '../../utils/utils';

class GallerySection extends Component {

    static get propTypes() {
        return {
            merchList: PropTypes.instanceOf(Immutable.List).isRequired,
            showCaptions: PropTypes.bool.isRequired,
            titleId: PropTypes.number.isRequired
        };
    }

    render() {
        if (!this.props.merchList.size) {return null;}

        let merchRows = BreakIntoGroups(this.props.merchList, 4).map((merchRow, i) => {
            return (
                <div className="row" key={i}>
                    {BreakIntoGroups(merchRow, 4).map((merchCol, j) => {
                        return (
                            <div className="col-xs-12" key={j}>
                                <div className="row">
                                    <GalleryThumbnail
                                        merchandise={merchCol.get(0)}
                                        showCaptions={this.props.showCaptions}
                                        titleId={this.props.titleId}/>
                                    <GalleryThumbnail
                                        merchandise={merchCol.get(1)}
                                        showCaptions={this.props.showCaptions}
                                        titleId={this.props.titleId}/>
                                    <div className="clearfix visible-xs"></div>
                                    <GalleryThumbnail
                                        merchandise={merchCol.get(2)}
                                        showCaptions={this.props.showCaptions}
                                        titleId={this.props.titleId}/>
                                    <GalleryThumbnail
                                        merchandise={merchCol.get(3)}
                                        showCaptions={this.props.showCaptions}
                                        titleId={this.props.titleId}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        });

        return (
            <div>
                {merchRows}
            </div>
        );
    }
}
export default GallerySection;
