/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';


export default class HBOMaxContacts extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.hbo-max-contacts">
                <div className="container padding-y-10 padding-x-20">
                    <div className="row">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="row">
                        <table className="table cart-parent-table">
                            <thead>
                                <tr>
                                    <th scope="col" className="no-sort">NAME</th>
                                    <th scope="col" className="no-sort"></th>
                                    <th scope="col" className="no-sort">EMAIL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="2"><a href="mailto:Kalliope.Diakos@warnerbros.com">Diakos, Kalliope</a></td>
                                    <td><a href="mailto:Kalliope.Diakos@warnerbros.com">Kalliope.Diakos@warnerbros.com</a></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><a href="mailto:brandi.wilson@warnerbros.com">Wilson, Brandi</a></td>
                                    <td><a href="mailto:brandi.wilson@warnerbros.com">brandi.wilson@warnerbros.com</a></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><a href="mailto:James.Pettit@warnerbros.com">Pettit, James</a></td>
                                    <td><a href="mailto:James.Pettit@warnerbros.com">James.Pettit@warnerbros.com</a></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><a href="mailto:amy.witry@wbconsultant.com">Witry, Amy (*IC)</a></td>
                                    <td><a href="mailto:amy.witry@wbconsultant.com">amy.witry@wbconsultant.com</a></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><a href="mailto:Jane.Chapman@warnermedia.com">Chapman, Jane</a></td>
                                    <td><a href="mailto:Jane.Chapman@warnermedia.com">Jane.Chapman@warnermedia.com</a></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><a href="mailto:wbtvd@warnerbros.com">ACCOUNT SUPPORT</a></td>
                                    <td><a href="mailto:wbtvd@warnerbros.com">wbtvd@warnerbros.com</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </DocumentTitle>

        );
    }
}
