/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {BreakIntoGroups} from '../../utils/utils';

export default class ClipsGrid extends React.PureComponent {
    static get propTypes() {
        return {
            clips: PropTypes.instanceOf(Immutable.List).isRequired,
            renderClipItem: PropTypes.func.isRequired,
        };
    }

    renderRow = (assets, i) => {
        const {renderClipItem} = this.props;
        return (
            <div className="row clips-grid-row" key={i}>
                <div className="col-md-6">
                    <div className="row">
                        {renderClipItem(assets.get(0))}
                        {renderClipItem(assets.get(1))}
                    </div>
                </div>
                <div className="clearfix visible-xs"/>
                <div className="col-md-6">
                    <div className="row">
                        {renderClipItem(assets.get(2))}
                        {renderClipItem(assets.get(3))}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return BreakIntoGroups(this.props.clips, 4).map(this.renderRow).toJS();
    }
}
