/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import DialogueMatchItem from './dialogue-match-item';
import GenericMatchItem from './generic-match-item';
import style from './matches.module.less';

export default function ListOfMatches({matches, tags}) {
    if (!matches.size) {
        return null;
    }
    return (
        <div className={style.container}>
            {matches.map((tag) => {
                if (tag.get('type') === 'dialogue') {
                    return <DialogueMatchItem
                        key={tag.get('uid')}
                        text={tag.get('text')}
                        type={tag.get('type')}
                        fullDialogueText={tags.get('dialogue')}
                    />;
                }

                return (
                    <GenericMatchItem
                        key={tag.get('uid')}
                        text={tag.get('text')}
                        type={tag.get('type')}
                        title={tag.get('text')}
                    />
                );
            })}
        </div>
    );
}

ListOfMatches.propTypes = {
    matches: PropTypes.instanceOf(Immutable.List).isRequired,
    tags: PropTypes.instanceOf(Immutable.Map).isRequired,
};
