/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import QueryString from 'query-string';
import {useMemo} from 'react';
import {useLocation} from 'react-router';

/**
 * @param {string} queryKey
 * @returns {string[]}
 */
const useQueryParamValues = (queryKey) => {
    const location = useLocation();
    const query = QueryString.parse(location.search);
    const queryValue = query[queryKey];

    return useMemo(() => {
        let value = queryValue;

        if (!value) {
            return [];
        }
        /* istanbul ignore if */
        if (!Array.isArray(value)) {
            value = [value];
        }

        return value;
    }, [queryValue]);
};

export default useQueryParamValues;
