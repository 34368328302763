/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {AssetTitleConstants} from './asset-title-actions';
import ListViewTableImage from './list-view-table-image';
import ListViewTableVideo from './list-view-table-video';


class ListView extends Component {
    static get propTypes() {
        return {
            assetType: PropTypes.string.isRequired,
            canAddToCart: PropTypes.bool,
            canInstantOrderVideos: PropTypes.bool,
            hasRestrictedAuth: PropTypes.bool.isRequired,
            images: PropTypes.instanceOf(Immutable.Map).isRequired,
            imageSuperType: PropTypes.string.isRequired,
            location: PropTypes.object.isRequired,
            onSortChange: PropTypes.func.isRequired,
            sectionImageCount: PropTypes.number.isRequired,
            showAirDate: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            videos: PropTypes.instanceOf(Immutable.Map).isRequired,
            videoSuperType: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            canAddToCart: false,
            canInstantOrderVideos: false,
            showAirDate: false,
        };
    }

    render() {
        if (!this.props.title) {
            return null;
        }

        let assetTable;

        switch (this.props.assetType) {
        case AssetTitleConstants.ASSET_CATEGORY.VIDEO:
            assetTable = (
                <div className="display-results display-results-grid">
                    <ListViewTableVideo
                        canAddToCart={this.props.canAddToCart}
                        canInstantOrderVideos={this.props.canInstantOrderVideos}
                        location={this.props.location}
                        onSortChange={this.props.onSortChange}
                        showAirDate={this.props.showAirDate}
                        title={this.props.title}
                        videos={this.props.videos}
                        videoSuperType={this.props.videoSuperType}
                    />
                </div>
            );
            break;

        case AssetTitleConstants.ASSET_CATEGORY.IMAGE:
            assetTable = (
                <div className="display-results display-results-grid">
                    <ListViewTableImage
                        canAddToCart={this.props.canAddToCart}
                        hasRestrictedAuth={this.props.hasRestrictedAuth}
                        images={this.props.images}
                        imageSuperType={this.props.imageSuperType}
                        location={this.props.location}
                        onSortChange={this.props.onSortChange}
                        sectionImageCount={this.props.sectionImageCount}
                        title={this.props.title}
                    />
                </div>
            );
            break;
        }

        return (
            <div>
                {assetTable}
            </div>
        );
    }

}

export default ListView;
