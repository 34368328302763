import PropTypes from 'prop-types';
import React from 'react';

import GenericMatchItem from './generic-match-item';

// eslint-disable-next-line no-unused-vars
const DialogueMatchItem = ({fullDialogueText, ...restProps}) => {
    return <GenericMatchItem {...restProps} />;
};

export default DialogueMatchItem;

DialogueMatchItem.propTypes = {
    fullDialogueText: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};
