/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {PROPERTIES} from '../constants';

/**
 * @param {Immutable.Map} value
 * @param {Immutable.Map} thumbnails
 * @param {'documents' | 'episodes' | 'images' | 'moments' | 'talent' | 'titles' | 'videos'} type
 * @return {boolean}
 */
export default function getIsImageLoading(value, thumbnails, type) {
    if (!value) {
        return true;
    }
    let imageId = value.get(PROPERTIES[type].image);
    if (!imageId) {
        return false;
    }
    let imageUrl = thumbnails.getIn([imageId.toString(), 'thumbnailUrl']);
    return imageUrl === undefined;
}
