/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Glyphicon} from 'react-bootstrap';

import {DownloadActions} from '../../../common/download/download-actions';
import WithPermissions from '../../../decorators/with-permissions';
import CompanyStore from '../../../lookup/company/company-store';
import TerritoryStore from '../../../lookup/territory/territory-store';
import {MessagesContext} from '../../../messages/messages-context';
import SessionStore from '../../../session/session-store';
import {FormatReleaseDate} from '../../../utils/utils';
import {TitleConstants} from '../../title-actions';

class ReleaseDatesTable extends Component {
    static get propTypes() {
        return {
            collapsed: PropTypes.bool.isRequired,
            onHeaderClick: PropTypes.func.isRequired,
            permissions: PropTypes.object.isRequired,
            releaseDates: PropTypes.instanceOf(Immutable.List).isRequired,
            titleId: PropTypes.string.isRequired,
        };
    }

    static calculateState() {
        return {
            companies: CompanyStore.getState().get('items'),
            territories: TerritoryStore.getState().get('items'),
        };
    }

    static getPermissions() {
        return {
            canExportMultipleReleases: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.METADATA.EXPORT_MULTIPLE_RELEASES)
        };
    }

    static getStores() {
        return [TerritoryStore, CompanyStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.constructor.calculateState(),
            {
                collapsed: true
            }
        );

        this.handleExportMultitpleReleases = this.handleExportMultitpleReleases.bind(this);
        this.handleToggleExpandTable = this.handleToggleExpandTable.bind(this);
    }

    static contextType = MessagesContext;

    handleExportMultitpleReleases() {
        DownloadActions.startDownloadExecution(`title/${this.props.titleId}/release/export`);
    }

    handleToggleExpandTable() {
        this.setState((prevState) => ({
            collapsed: !prevState.collapsed
        }));
    }

    render() {
        let expandBtn = <span className="collapse-button">{this.context.intl.messages['title.release-dates.view.more']} <i className="fa-solid fa-chevron-right"></i></span>;
        if (!this.props.collapsed) {
            expandBtn = <span className="collapse-button">{this.context.intl.messages['title.release-dates.view.less']} <i className="fa-solid fa-chevron-down"></i></span>;
        }

        let exportButton;
        if (this.props.permissions.canExportMultipleReleases) {
            exportButton = (
                <Button
                    className="btn-secondary"
                    onClick={this.handleExportMultitpleReleases}
                >
                    <Glyphicon glyph="download-alt" />
                    <span>{this.context.intl.messages['title.release-dates.export-multiple-releases']}</span>
                </Button>
            );
        }

        return <div id="release-dates" className="container padding-all-10">
            <hr />
            <h3 onClick={() => this.props.onHeaderClick()}>
                {this.context.intl.messages['title.release-dates.caption']}&emsp;&emsp;&emsp;<span className="collapse-button">{expandBtn}</span>
            </h3>
            <div className="row">
                <div className="col-xs-12" style={{display: 'flex', alignItems: 'center'}}>
                    <i>{this.context.intl.messages['title.release-dates.note']}</i>&nbsp;&nbsp;
                    {exportButton}
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <table className={ClassNames('table table-stripped release-dates-table', {'collapsed': this.props.collapsed})}>
                        <thead>
                            <tr>
                                <th scope="col">{this.context.intl.messages['title.release-dates.territory']}</th>
                                <th scope="col">{this.context.intl.messages['title.release-dates.date']}</th>
                                <th scope="col">{this.context.intl.messages['title.release-dates.content-type']}</th>
                                <th scope="col">{this.context.intl.messages['title.release-dates.company']}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.releaseDates.map((rel, key) => {
                                let releaseTerritory = 'N/A';
                                const territory = this.state.territories.find(t => t.get('id') === rel.get('territoryId'));
                                if (territory) {
                                    releaseTerritory = territory.get('name');
                                }

                                let releaseDate = 'N/A';
                                if (rel.get('date')) {
                                    releaseDate = FormatReleaseDate(rel.get('date'), rel.get('dateType'));
                                }

                                let releaseContentType = 'N/A';
                                if (rel.get('releaseContentType')) {
                                    releaseContentType = Object.keys(TitleConstants.RELEASE_CONTENT_TYPES)
                                        .map(k => TitleConstants.RELEASE_CONTENT_TYPES[k])
                                        .filter(obj => rel.get('releaseContentType') === obj.id)[0].name;
                                }

                                let releaseCompany = 'N/A';
                                const company = this.state.companies.find(c => c.get('id') === rel.get('domesticReleaseCompanyId'));
                                if (company) {
                                    releaseCompany = company.get('name');
                                }

                                return <tr key={key}>
                                    <td>{releaseTerritory}</td>
                                    <td>{releaseDate}</td>
                                    <td>{releaseContentType}</td>
                                    <td>{releaseCompany}</td>
                                </tr>;
                            }).toList().toJS()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>;
    }
}

export default WithPermissions(Container.create(ReleaseDatesTable));

export {ReleaseDatesTable};

