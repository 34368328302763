/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {MessagesContext} from '../../../messages/messages-context';
import ImageLoader from '../../../titles/asset-title/images/image-loader';
import {BreakIntoGroups} from '../../../utils/utils';
import {TitleConstants} from '../../title-actions';

class RelatedTitleThumbnail extends React.Component {
    static get propTypes() {
        return {
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    render() {
        if (!this.props.title) {
            return null;
        }

        let titleName = this.props.title.get('displayName');
        let categoryGroup = Object.keys(TitleConstants.TITLE_TYPES).reduce((c, t) => {
            if (TitleConstants.TITLE_TYPES[t].id === this.props.title.get('category')) {
                return TitleConstants.TITLE_TYPES[t].categoryGroup;
            }
            return c;
        }, undefined);
        if (categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SEASON && this.props.title.get('season') !== 0) {
            titleName += ` S${this.props.title.get('season')}`;
        }

        if (titleName.length > 30) {
            titleName = `${titleName.slice(0, 30)}...`;
        }

        let src;

        if (this.props.title.get('defaultImagePortraitThumbnailUrl')) {
            src = this.props.title.get('defaultImagePortraitThumbnailUrl');
        }

        return (
            <Col xs={6} sm={3} className="thumbnail-container">
                <div className="responsive-container">
                    <div className="vertical-spacer"></div>
                    <div className="img-container">
                        <Link to={`/titles/${this.props.title.get('id')}`}>
                            <ImageLoader
                                alt={this.props.title.get('name')}
                                className="img-responsive"
                                src={src}
                            />
                        </Link>
                    </div>
                </div>
                <Link className="thumbnail-title" style={{height: '40px'}} to={`/titles/${this.props.title.get('id')}`}>{titleName}</Link>
            </Col>
        );
    }
}

export default class RelatedTitle extends React.Component {
    static get propTypes() {
        return {
            titles: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static contextType = MessagesContext;

    render() {
        if (!this.props.titles.size) {
            return null;
        }

        return (
            <div className="container padding-all-10">
                <hr />
                <h3>{this.context.intl.messages['title.related-titles.title']}</h3>
                <Row>
                    {BreakIntoGroups(this.props.titles, 4).map((titleRow, i) => {
                        return (
                            <Col xs={12} lg={6} key={i}>
                                <Row>
                                    {titleRow.map((title, j) => {
                                        return (
                                            <RelatedTitleThumbnail
                                                key={j}
                                                title={title}
                                            />
                                        );
                                    })}
                                </Row>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    }
}

export {RelatedTitleThumbnail};
