/* eslint-disable jsx-a11y/no-autofocus */
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {Button, Col, FormControl, FormGroup, Row} from 'react-bootstrap';
import url from 'url';

import LoginRedirectModal from './login-redirect-modal';
import DocumentTitle from '../common/document-title';
import LandingLayout from '../layout/landing';
import LanguageOptions from '../messages/language-options';
import {MessagesContext} from '../messages/messages-context';
import {Alert} from '../notification/alert';
import DomainZeroWithoutAccountModal from '../notification/domain-zero-without-account/domain-zero-without-account';
import MFAEnrollmentEmailModal from '../notification/mfa/mfa-enrollment-email-modal';
import MFAPushNotificationModal from '../notification/mfa/mfa-push-notification-modal';
import {NotificationActions} from '../notification/notification-actions';
import {PlayerActions} from '../player/player-actions';
import RouterHelpers from '../router/router-helpers';
import {SessionActions} from '../session/session-actions';
import SessionStore from '../session/session-store';
import './login-form.less';

class LoginForm extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            login: SessionStore.getState().get('login'),
            isPartnerAuthLogin: SessionStore.getState().get('isPartnerAuthLogin'),
            user: SessionStore.getState().get('authUser')
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.constructor.calculateState(),
            {
                showPassword: false
            }
        );
        this.handleLogin = this.handleLogin.bind(this);
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.setValue = this.setValue.bind(this);

        return;
    }

    componentDidMount() {
        const query = QueryString.parse(this.props.location.search);
        /* istanbul ignore next */
        if (query['wbtvd-auth']) {
            SessionActions.swapToken(
                SessionStore.getPartner(),
                query['wbtvd-auth']
            );
        }
        PlayerActions.checkOSVersionSupport();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.login !== this.state.login;
    }

    static contextType = MessagesContext;

    handleLogin(e) {
        e.preventDefault();
        if (!this.state.login.get('email') || (this.state.login.get('email').includes('@') && !this.state.login.get('password'))) {
            NotificationActions.showAlertDanger('login.error.complete-form');
            return;
        }

        let clearSessionToken, next, forward;
        if (this.props.location && this.props.location.search) {
            const query = QueryString.parse(this.props.location.search);
            // Fixes unit test failures when location/query is unavailable
            next = query.next;
            forward = query.forward;
            clearSessionToken = query['clear-session-token'];
        }

        if (this.state.login.get('email').includes('@')) {
            // Email + password login mode
            SessionActions.login(
                this.state.login.get('email'),
                this.state.login.get('password'),
                next,
                forward,
                SessionStore.getPartner(),
                clearSessionToken
            );
        } else {
            // login code/anonymous login mode
            SessionActions.loginWithCode(this.state.login.get('email'), SessionStore.getPartner(), (next || '/'));
        }
        return;
    }

    handleShowPassword() {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }

    setValue(attr, e) {
        SessionActions.updateAttr('login', attr, e.target.value);
    }

    render() {
        const inProgress = this.state.login.get('inProgress');

        let loginButtonText = this.context.intl.messages['login.login'];
        let loginButtonProgress;
        /* istanbul ignore next */
        if (inProgress) {
            loginButtonText = this.context.intl.messages['login.login.in-progress'];
            loginButtonProgress = (
                <i className="far fa-sync-alt fa-spin" />
            );
        }

        const partner = SessionStore.getPartner();
        const signUp = url.resolve(partner.loginBaseUrl, '/terms-of-use?from=sign-up');
        const forgotPassword = url.resolve(partner.loginBaseUrl, '/password-reset');
        const oneTimeLogin = url.resolve(partner.loginBaseUrl, '/one-time-login');

        let maintenanceWarning;
        const timeAtPST = Moment().tz('America/Los_Angeles');
        const dayOfYear = timeAtPST.dayOfYear();
        const year = timeAtPST.year();
        const hour = timeAtPST.hour();
        // Currently set for Sep 13th 2020 between 0:00 and 7:00 PST.
        /* istanbul ignore next */
        if (year === 2020 && dayOfYear === 257 && hour >= 0 && hour <= 6) {
            maintenanceWarning = (
                <div className="alert alert-warning alert-dismissable" role="alert">
                    <i className="far fa-exclamation-triangle"></i> Our system is scheduled for updates on Sunday, September 13, 2020, 3:00 a.m. – 6:00 a.m. PST. During this process, access to the site may be disrupted temporarily. Thank you for your patience.
                </div>
            );
        }
        let inputType = 'password';
        /* istanbul ignore next */
        if (this.state.showPassword) {
            inputType = 'text';
        }

        let passwordInput;
        /* istanbul ignore next */
        if (this.state.login.get('email', '').includes('@') && !this.state.isPartnerAuthLogin) {
            passwordInput = <FormGroup className="has-feedback">
                <FormControl
                    aria-required="true"
                    autoComplete="current-password"
                    onChange={this.setValue.bind(this, 'password')}
                    placeholder={this.context.intl.messages['login.password']}
                    ref="passwordInput"
                    type={inputType}
                    value={this.state.login.get('password')}
                />
                <span
                    aria-hidden="true"
                    className={ClassNames(
                        'glyphicon',
                        {
                            'glyphicon-eye-open': !this.state.showPassword,
                            'glyphicon-eye-close': this.state.showPassword
                        },
                        'form-control-feedback',
                        'login-password'
                    )}
                    onClick={this.handleShowPassword}
                ></span>
            </FormGroup>;
        }

        let signupLink;
        /* istanbul ignore next */
        if (!partner.preventSignup) {
            signupLink = <a className="login-links" href={signUp}>{this.context.intl.messages['login.sign-up']}</a>;
        }

        return (
            <DocumentTitle
                message="document-titles.login"
            >
                <LandingLayout>
                    <Col className="box" sm={4} smOffset={4}>
                        <Alert/>
                        <LoginRedirectModal/>
                        <MFAEnrollmentEmailModal
                            email={this.state.login.get('email')}
                        />
                        <MFAPushNotificationModal/>
                        <DomainZeroWithoutAccountModal />
                        <Row>
                            <Col sm={12}>
                                {maintenanceWarning}
                                <form onSubmit={this.handleLogin}>
                                    <FormGroup className="has-feedback">
                                        <FormControl
                                            aria-required="true"
                                            autoComplete="email"
                                            autoFocus
                                            disabled={this.state.isPartnerAuthLogin}
                                            onChange={this.setValue.bind(this, 'email')}
                                            placeholder={this.context.intl.messages['login.email-or-login-code']}
                                            type="text"
                                            value={this.state.login.get('email')}
                                        />
                                        <span
                                            aria-hidden="true"
                                            className="glyphicon glyphicon-envelope form-control-feedback login-password"
                                        ></span>
                                    </FormGroup>
                                    {passwordInput}
                                    <Button
                                        block
                                        bsSize="lg"
                                        className="btn btn-wbd-primary"
                                        disabled={inProgress}
                                        type="submit"
                                    >{loginButtonText} {loginButtonProgress}</Button>
                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <LanguageOptions showAs="login" className="btn-sm text-center" variant="secondary btn-sm" />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} className="text-center">{signupLink}</Col>
                            <Col sm={4} className="text-center"><a className="login-links" href={oneTimeLogin}>{this.context.intl.messages['login.one-time-login']}</a></Col>
                            <Col sm={4} className="text-center"><a className="login-links" href={forgotPassword}>{this.context.intl.messages['login.forgot-password']}</a></Col>
                        </Row>
                    </Col>
                </LandingLayout>
            </DocumentTitle>
        );
    }
}

export default RouterHelpers(Container.create(LoginForm));
export {LoginForm};
