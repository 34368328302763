/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {Languages, MessagesContext} from './messages-context';

import 'bootstrap/js/dropdown';

/* istanbul ignore next */
const LanguageOptionsData = ({className, label, updateIntl, ...props}) => {
    return Object.values(Languages).sort(
        (a, b) => a.displayOrder - b.displayOrder
    ).map(lang => {
        return {
            className,
            isActive: (label === lang.label),
            label,
            language: lang,
            onClick: ()=>updateIntl(lang.locale),
            updateIntl,
            ...props
        };
    });
};

/* istanbul ignore next */
const LanguageOption = ({className, label, updateIntl, ...props}) => {
    return LanguageOptionsData({className, label, updateIntl, ...props}).map(l => {
        return (
            <li
                className={ClassNames(className, {active: l.isActive})}
                key={`${className}-lang-select--${l.language.locale}`}
                onClick={l.onClick}
                {...props}
            >
                {l.language.label}
            </li>
        );
    });
};

const LanguageOptions = ({className, showAs, variant}) => {
    const {intl, label, updateIntl} = React.useContext(MessagesContext);

    switch (showAs) {
    case 'login':
        return (
            <div className="btn-group dropdown-lang-select" id="dropdown-lang-select">
                <button type="button" className={ClassNames('btn', 'btn-link', className)} data-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-language"></i>
                    {' '}
                    {intl.messages['common.change-language']}
                </button>
                <div className={ClassNames('dropdown-menu', 'login-dropdown', {[`bg-${variant}`]: (variant!==null)})}>
                    <LanguageOption
                        className={ClassNames('dropdown-item')}
                        label={label}
                        updateIntl={updateIntl}
                    />
                </div>
            </div>
        );
    case 'header':
    default:
        return (
            <div className="btn-group dropdown-lang-select" id="dropdown-lang-select">
                <button type="button" className={ClassNames('btn btn-sm btn-primary', className)} data-toggle="dropdown" aria-expanded="false">
                    {intl.messages['common.select-language']}
                </button>
                <div className={ClassNames('dropdown-menu', {[`bg-${variant}`]: (variant!==null)})}>
                    <LanguageOption
                        className={ClassNames('dropdown-item')}
                        label={label}
                        updateIntl={updateIntl}
                    />
                </div>
            </div>
        );
    }
};
LanguageOptions.propTypes = {
    className: PropTypes.string,
    showAs: PropTypes.string,
    variant: PropTypes.string
};
LanguageOptions.defaultProps = {
    className: null,
    showAs: null,
    variant: null
};

export default LanguageOptions;
export {LanguageOption, LanguageOptionsData};
