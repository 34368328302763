/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassName from 'classnames';
import {Container} from 'flux/utils';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import AutoComplete from 'react-autocomplete';
import {Col, DropdownButton, FormGroup, Grid, InputGroup, MenuItem} from 'react-bootstrap';

import WithPermissions from '../../decorators/with-permissions';
import {MessagesContext} from '../../messages/messages-context';
import {RouterActions} from '../../router/router-actions';
import {SearchActions} from '../../search/search-actions';
import SearchStore from '../../search/search-store';
import SessionStore from '../../session/session-store';
import {Debounce} from '../../utils/utils';
import {PageLayoutActions} from '../page-layout/page-layout-actions';
import PageLayoutStore from '../page-layout/page-layout-store';

import './searchbar.less';

const capitalizeFirstLetter = (s) => {
    if (typeof s !== 'string') {
        return;
    }
    return s.charAt(0).toLocaleUpperCase() + s.slice(1);
};

const truthy = () => true;
const SEARCHBAR_TYPES = [
    {id: 'all', predicate: truthy},
    {id: 'titles', predicate: truthy},
    {id: 'images', predicate: () => !SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle()},
    {id: 'talent', predicate: truthy},
    {id: 'videos', predicate: truthy},
    {id: 'moments', predicate: (perms) => perms.canCreateClip && perms.canAddToCart && !SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle()},
    {id: 'episodes', predicate: truthy},
    {id: 'documents', predicate: () => !SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle()},
];

class Searchbar extends React.Component {
    static get propTypes() {
        return {
            fixed: PropTypes.bool,
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            searchSuggestions: SearchStore.getState().get('searchSuggestions'),
            searchSuggestionsQuery: SearchStore.getState().get('searchSuggestionsQuery'),
        };
    }

    static getStores() {
        return [SearchStore];
    }

    static get defaultProps() {
        return {
            fixed: false
        };
    }

    static getPermissions() {
        return {
            canAddToCart: SessionStore.canUser(SessionStore.PERMISSIONS.CART.VIDEOS.ADD),
            canCreateClip: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.CLIPS.CREATE),
            canShowAutoSuggest: SessionStore.canUser(SessionStore.PERMISSIONS.SEARCH.AUTO_SUGGEST),
        };
    }

    constructor(props) {
        super(props);

        const query = QueryString.parse(props.location.search);
        this.state = Object.assign({
            filter: 'all',
            q: query.q || '',
            scroll: 0,
        }, this.constructor.calculateState());

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRedirection = this.handleRedirection.bind(this);
        this.handleSelectFilter = this.handleSelectFilter.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.renderMenu = this.renderMenu.bind(this);

        this.fetchSuggestions = Debounce(this.fetchSuggestions.bind(this), 200);
    }

    componentDidMount() {
        if (this.state.q) {
            this.fetchSuggestions(this.state.q);
        }
    }

    static contextType = MessagesContext;

    fetchSuggestions(value) {
        SearchActions.getSuggestions(value);
    }

    getItemValue(item) {
        return item;
    }

    handleInputChange(event, value) {
        this.setState({
            q: value,
        });

        if (value) {
            this.fetchSuggestions(value);
        } else {
            SearchActions.clearSuggestions();
        }

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify({q: value})}`, true);

        return;
    }

    handleRedirection(filter) {
        const currentQuery = QueryString.parse(this.props.location.search);
        if (currentQuery.q !== '') {
            let query = {};
            if (currentQuery.q) {
                query = {q: currentQuery.q};
            }

            // Only keep the current query if the user triggers
            // the search from within the search page.
            if (this.props.location.pathname === '/search') {
                query = Object.assign(
                    {},
                    currentQuery,
                    query
                );
            }
            if (filter !== 'all') {
                query.size = 40;
                RouterActions.redirect(`/search/${filter}?${QueryString.stringify(query)}`, true);
            } else {
                RouterActions.redirect(`/search?${QueryString.stringify(query)}`, true);
            }
        }
    }

    handleSelectFilter(eventKey, event) {
        let filter = event.target.getAttribute('data-filter');
        PageLayoutActions.changeSearchBarDropdown(filter);
        this.setState({
            filter: filter
        });
        this.handleRedirection(filter);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.handleRedirection(this.state.filter);
        return;
    }

    onSelect(value) {
        if (this.state.q !== this.state.searchSuggestionsQuery) {
            this.handleRedirection(this.state.filter);
            return;
        }

        let searchObject = {
            q: value.displayName || value.displayValue
        };

        if (value.talentId) {
            RouterActions.redirect(`/talent/${value.talentId}?${QueryString.stringify(searchObject)}`);
        } else if (value.titleId) {
            RouterActions.redirect(`/titles/${value.titleId}?${QueryString.stringify(searchObject)}`);
        }
    }

    renderItem(item, isHighlighted) {
        let css = 'item';
        let value = item.displayName || item.displayValue;

        let itemType = 'talent';
        if (item.titleId) {
            itemType = 'title';
        }

        let ariaLabel = `${capitalizeFirstLetter(itemType)}, ${value}.`;

        if (isHighlighted) {
            css = 'auto-complete-suggestion';
        }

        let term = this.state.q;
        if (term) {
            let index = value.toLowerCase().indexOf(term.toLowerCase());
            if (index !== -1) {
                value = (
                    <span>{value.slice(0, index)}<b>{value.slice(index, index + term.length)}</b>{value.slice(index + term.length)}</span>
                );
            }
        }

        let releaseYear;
        if (item.titleReleaseDate) {
            const year = Moment(item.titleReleaseDate).format('YYYY');
            releaseYear = <small className="gray-text">{year}</small>;
            ariaLabel += ` From ${year}`;
        }

        return (
            <div
                className={css}
                key={item.titleId || item.talentId}
                type={itemType}
                aria-label={ariaLabel}
            >
                {value}&nbsp;{releaseYear}
            </div>
        );
    }

    renderMenu(children) {
        let horizontalRuler;
        let menu;
        let talent = [];
        let titles = [];
        let showTalent;
        let showTitle;

        children.forEach((child) => {
            if (child.props.type === 'title') {
                titles.push(child);
            } else {
                talent.push(child);
            }
        });

        if (titles.length < 10 && talent.length) {
            showTalent = (
                <div>
                    <div className="type-header">
                        <small className="gray-text">{this.context.intl.messages['header.searchbar.results.talent']}</small>
                    </div>
                    {talent}
                </div>
            );
        }

        if (titles.length) {
            showTitle = (
                <div>
                    <div className="type-header">
                        <small className="gray-text">{this.context.intl.messages['header.searchbar.results.title']}</small>
                    </div>
                    {titles}
                </div>
            );
        }

        if (titles.length < 10 && talent.length && titles.length) {
            horizontalRuler = <hr/>;
        }

        if (children.length) {
            menu = (
                <div className="menu">
                    {showTitle}
                    {horizontalRuler}
                    {showTalent}
                </div>
            );
        }

        return (
            <div>
                {menu}
            </div>
        );
    }

    render() {
        let autoCompleteProps = {
            className: 'auto-complete',
            placeholder: 'Search...'
        };

        const items = SEARCHBAR_TYPES.filter(({predicate}) => predicate(this.props.permissions)).map(({id}) => {
            const messageKey = `header.searchbar.dropdown.item.${id}`;
            return <MenuItem key={id} data-filter={id}>{this.context.intl.messages[messageKey]}</MenuItem>;
        });

        const query = QueryString.parse(this.props.location.search);

        let autocompleteProps = {};
        if (!this.props.permissions.canShowAutoSuggest) {
            autocompleteProps.open = false;
        }

        return (
            <aside className="header-search-container">
                <Col xs={12} className={ClassName('header-search no-transition', {'header-search-fixed': this.props.fixed, 'header-search-hidden': !this.props.fixed})}>
                    <Grid>
                        <form
                            onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <InputGroup>
                                    <span className="input-group-addon header-search-addon">
                                        <span className="glyphicon glyphicon-search margin-all-0 h3"/>
                                    </span>
                                    <AutoComplete
                                        value={query.q}
                                        items={this.state.searchSuggestions.toJS()}
                                        getItemValue={this.getItemValue}
                                        onChange={this.handleInputChange}
                                        onSelect={this.onSelect}
                                        renderItem={this.renderItem}
                                        inputProps={autoCompleteProps}
                                        wrapperStyle={{}}
                                        autoHighlight={false}
                                        renderMenu={this.renderMenu}
                                        {...autocompleteProps}
                                    />
                                    <InputGroup.Button>
                                        <DropdownButton
                                            componentClass={InputGroup.Button}
                                            id="input-dropdown-addon"
                                            onSelect={this.handleSelectFilter}
                                            pullRight
                                            title={this.context.intl.messages[`header.searchbar.dropdown.item.${PageLayoutStore.getState().getIn(['searchBar', 'assetFilter'])}`]}
                                        >
                                            {items}
                                        </DropdownButton>
                                    </InputGroup.Button>
                                </InputGroup>
                            </FormGroup>
                        </form>
                    </Grid>
                </Col>
            </aside>
        );
    }
}

export default WithPermissions(Container.create(Searchbar));
export {Searchbar};
