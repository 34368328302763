/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import DocumentTitle from '../../common/document-title';
import LandingLayout from '../../layout/landing';
import {MessagesContext} from '../../messages/messages-context';
import {RouterActions} from '../../router/router-actions';
import './terms-of-use.less';

export default class TermsOfUse extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.agreeTOU = this.agreeTOU.bind(this);

        return;
    }

    static contextType = MessagesContext;

    denyTOU() {
        RouterActions.redirect('/login');
    }

    agreeTOU() {
        const query = QueryString.parse(this.props.location.search);
        let q = {
            token: query.token
        };
        if (this.props.match.url === '/first-time') {
            q.firstTime = true;
        }
        if (query.wb) {
            RouterActions.redirect('/login');
            return;
        }
        if (query.from === 'sign-up') {
            RouterActions.redirect('/sign-up');
            return;
        }
        if (query.from === 'domain-zero-modal') {
            RouterActions.redirect('/sign-up?from=domain-zero-modal');
            return;
        }
        RouterActions.redirect(`/reset-password?${QueryString.stringify(q)}`);
    }

    render() {
        let btnsRow = (
            <Row>
                <Col sm={12}>
                    <Button
                        bsSize="lg"
                        className="btn-gray pull-right"
                        onClick={this.denyTOU}>
                        {this.context.intl.messages['terms-of-use.button.close']}
                    </Button>
                </Col>
            </Row>
        );

        const query = QueryString.parse(this.props.location.search);

        if (query.from === 'sign-up' || this.props.match.url === '/first-time') {
            btnsRow = <Row>
                <Col sm={6}>
                    <Button block
                        bsSize="lg"
                        onClick={this.denyTOU}>
                        {this.context.intl.messages['terms-of-use.button.deny']}
                    </Button>
                </Col>
                <Col sm={6}>
                    <Button block
                        bsSize="lg"
                        bsStyle="primary"
                        onClick={this.agreeTOU}>
                        {this.context.intl.messages['terms-of-use.button.agree']}
                    </Button>
                </Col>
            </Row>;
        }

        return (
            <DocumentTitle
                message="document-titles.tou"
            >
                <LandingLayout>
                    <Col className="box" sm={6} smOffset={3}>
                        <Row>
                            <Col sm={12}>
                                <iframe title="Terms of Use" width="100%" height="400px" src="https://policies.warnerbros.com/terms/en-us/html/terms_en-us_1.3.0.html"></iframe>
                            </Col>
                        </Row>
                        {btnsRow}
                    </Col>
                </LandingLayout>
            </DocumentTitle>
        );
    }
}
