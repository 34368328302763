/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

import Analytics from '../../analytics';
import {MessagesContext} from '../../messages/messages-context';
import SessionStore from '../../session/session-store';

const PARTNER = SessionStore.getPartner();

export default class DocumentTitle extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.oneOfType([
                PropTypes.array,
                PropTypes.element
            ]).isRequired,
            entityName: PropTypes.string,
            message: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            entityName: '',
            message: ''
        };
    }

    componentDidMount() {
        this.setTitle(this.props, this.context.intl);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.entityName !== prevProps.entityName ||
            this.props.message !== prevProps.message
        ) {
            this.setTitle(this.props, this.context.intl);
        }
    }

    static contextType = MessagesContext;

    setTitle(props, intl) {
        let title = intl.messages[props.message];
        // Override with the entity name if available, for example: Superman
        if (props.entityName) {
            title = props.entityName;
        }

        document.title = `${title} | ${intl.messages[`document-titles.page-title-suffix-${PARTNER.id}`]}`;

        Analytics.pageViewEvent();

        return;
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}
