/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import PropTypes from 'prop-types';
import React from 'react';
import {Button, FormControl, FormGroup, InputGroup, Modal} from 'react-bootstrap';
import {pdfjs, Document as PDFDocument, Page} from 'react-pdf';
import * as pdfjsWorker from 'react-pdf/src/pdf.worker.entry.js';
import url from 'url';

import {FormRow} from '../../form/form';
import {MessagesContext} from '../../messages/messages-context';
import {RouterActions} from '../../router/router-actions';
import {AssetTitleActions} from '../../titles/asset-title/asset-title-actions';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import './asset.less';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

class PDFPreviewModal extends React.Component {
    static get propTypes() {
        return {
            className: PropTypes.string,
            documentId: PropTypes.string,
            match: PropTypes.object.isRequired,
            onHide: PropTypes.func.isRequired,
            pdfUrl: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            className: '',
            documentId: '',
            pdfUrl: '',
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            pages: 1,
        };

        this.createUrl = this.createUrl.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
        this.handleCopyDocumentPath = this.handleCopyDocumentPath.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.documentId !== prevProps.documentId) {
            if (this.props.match.path.search(/documents/) > 0) {
                RouterActions.redirect(`/titles/${this.props.match.params.id}/documents/${this.props.documentId}`);
                return;
            }

            RouterActions.redirect(`/titles/${this.props.match.params.id}/stations/${this.props.documentId}`);
        }
    }

    static contextType = MessagesContext;

    onDocumentLoadSuccess(pdfInfo) {
        this.setState({
            pages: pdfInfo.numPages
        });
    }

    handleCopyDocumentPath() {
        navigator.clipboard.writeText(this.createUrl());
    }

    createUrl() {
        let base = url.parse(window.location.href);
        if (base.path.split('/').length === 5) {
            const withoutPreviousId = base.href.substr(0, base.href.lastIndexOf('/'));
            return `${withoutPreviousId}/${this.props.documentId}`;
        }

        return `${base.href}/${this.props.documentId}`;
    }

    downloadDocument() {
        AssetTitleActions.downloadImageRendition(this.props.documentId, 'Source', false);
    }

    render() {
        let show = !!this.props.pdfUrl || false;

        return <Modal bsSize="large" className={this.props.className} onHide={this.props.onHide} show={show}>
            <Modal.Header closeButton>
                <FormRow>
                    <a aria-label="Download document" className="Cur(p) modal-download-link" role="button" onClick={this.downloadDocument}><span className="glyphicon glyphicon-download-alt" /></a>
                    <FormGroup md={12}>
                        <p style={{textAlign: 'left', fontWeight: 'bold'}}>{this.context.intl.messages['document-titles.title.title-asset.pdf-modal.copy-link']}</p>
                        <InputGroup>
                            <FormControl readonly type="text" value={this.createUrl()} />
                            <InputGroup.Addon style={{padding: 0}}>
                                <Button
                                    bsSize="large"
                                    className="btn btn-default-outline"
                                    onClick={this.handleCopyDocumentPath}
                                    style={{padding: '4px 12px'}}
                                ><i className="fa-regular fa-copy" /></Button>
                            </InputGroup.Addon>
                        </InputGroup>
                    </FormGroup>
                </FormRow>
            </Modal.Header>
            <Modal.Body>
                <PDFDocument
                    file={this.props.pdfUrl}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    className={'pdf-document-render'}
                >
                    {Array.from(new Array(this.state.pages), (el, index) => (
                        <div key={`pdf-preview-modal-page-${index + 1}`}>
                            <Page pageNumber={index + 1} />
                        </div>
                    ))}
                </PDFDocument>
            </Modal.Body>
        </Modal>;
    }
}

export default PDFPreviewModal;
