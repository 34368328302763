/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './romance-package.less';

export default class RomancePackage extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.romance-package">
                <div id="op-page" className="container op_container_romance_package">
                    <div id="op_header" className="row">
                        <div id="op-header-img">
                            <div id="header-btns">
                                <a
                                    className="btn-header sizzlereel"
                                    href="https://www.wbtvd.com/titles/1846623/videos/1965326"
                                    target="_blank">
                                    <img
                                        src="https://www.wbtvd.com/page/romance-package/banner_btn_sizzlereel.png"
                                        alt="Sizzle Reel"
                                    />
                                </a>
                                <a
                                    className="btn-header presentation"
                                    href="https://www.wbtvd.com/page/romance-package/WarnerBros_RomancePackage_Presentation.pdf"
                                    target="_blank">
                                    <img
                                        src="https://www.wbtvd.com/page/romance-package/banner_btn_presentation.png"
                                        alt="Presentation"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="op_content" className="row clearfix">
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/586650"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/AnotherCinderellaS_600x880.jpg)'}}
                                title="Another Cinderella Story"></a>
                            <div className="btns">
                                <a
                                    href="/titles/586650"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/586650/videos/972603"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/46412"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/BeforeSunrise_600x880.jpg)'}}
                                title="Before Sunrise"></a>
                            <div className="btns">
                                <a
                                    href="/titles/46412"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/46412/videos/1208411"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/238521"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/BeforeSunset_600x880.jpg)'}}
                                title="Before Sunset"></a>
                            <div className="btns">
                                <a
                                    href="/titles/238521"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/238521/videos/1690551"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/1385892"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/Blended_600x880.jpg)'}}
                                title="Blended"></a>
                            <div className="btns">
                                <a
                                    href="/titles/1385892"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/1385892/videos/1496446"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/46471"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/TheBodyguard_600x880.jpg)'}}
                                title="The Bodyguard"></a>
                            <div className="btns">
                                <a
                                    href="/titles/46471"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/46471/videos/1480509"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/46209"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/TheBridgesOfMadisonCounty_600x880.jpg)'}}
                                title="The Bridges Of Madison County"></a>
                            <div className="btns">
                                <a
                                    href="/titles/46209"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/46209/videos/1176592"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/236311"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/ChasingLiberty_600x880.jpg)'}}
                                title="Chasing Liberty"></a>
                            <div className="btns">
                                <a
                                    href="/titles/236311"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/236311/videos/1452520"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/227612"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/ACinderellaStory_600x880.jpg)'}}
                                title="A Cinderella Story"></a>
                            <div className="btns">
                                <a
                                    href="/titles/227612"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/227612/videos/1562316"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/46843"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/CityofAngels_600x880.jpg)'}}
                                title="City Of Angels"></a>
                            <div className="btns">
                                <a
                                    href="/titles/46843"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/46843/videos/1201011"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/977592"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/CrazyStupidLove_600x880.jpg)'}}
                                title="Crazy, Stupid, Love"></a>
                            <div className="btns">
                                <a
                                    href="/titles/977592"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/977592/videos/1749837"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/580923"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/FoolsGold_600x880.jpg)'}}
                                title="Fool's Gold"></a>
                            <div className="btns">
                                <a
                                    href="/titles/580923"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/580923/videos/1179095"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/869808"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/GoingTheDistance_600x880.jpg)'}}
                                title="Going The Distance"></a>
                            <div className="btns">
                                <a
                                    href="/titles/869808"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/869808/videos/1175458"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/263323"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/MustLoveDogs_600x880.jpg)'}}
                                title="Must Love Dogs"></a>
                            <div className="btns">
                                <a
                                    href="/titles/263323"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/263323/videos/1187498"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/969600"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/NewYearsEve_600x880.jpg)'}}
                                title="New Year's Eve"></a>
                            <div className="btns">
                                <a
                                    href="/titles/969600"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/969600/videos/1557017"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/49459"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/Singles_600x880.jpg)'}}
                                title="Singles"></a>
                            <div className="btns">
                                <a
                                    href="/titles/49459"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/49459/videos/978787"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/49514"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/SomethingToTalkAbout_600x880.jpg)'}}
                                title="Something To Talk About"></a>
                            <div className="btns">
                                <a
                                    href="/titles/49514"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/49514/videos/1751016"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/231776"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/SomethingsGottaGive_600x880.jpg)'}}
                                title="Something's Gotta Give"></a>
                            <div className="btns">
                                <a
                                    href="/titles/231776"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/231776/videos/1178745"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/878795"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/ValentinesDay_600x880.jpg)'}}
                                title="Valentine's Day"></a>
                            <div className="btns">
                                <a
                                    href="/titles/878795"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/878795/videos/981843"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/167661"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/WhataGirlWants_600x880.jpg)'}}
                                title="What A Girl Wants"></a>
                            <div className="btns">
                                <a
                                    href="/titles/167661"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/167661/videos/1724038"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/50433"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/YouveGotMail_600x880.jpg)'}}
                                title="You've Got Mail"></a>
                            <div className="btns">
                                <a
                                    href="/titles/50433"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/50433/videos/1240698"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/413175"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/MusicAndLyrics_600x880.jpg)'}}
                                title="Music And Lyrics"></a>
                            <div className="btns">
                                <a
                                    href="/titles/413175"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/413175/videos/1179490"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">
                            <a
                                className="poster"
                                href="/titles/74772"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/romance-package/TwoWeeksNotice_600x880.jpg)'}}
                                title="Two Weeks Notice"></a>
                            <div className="btns">
                                <a
                                    href="/titles/74772"
                                    className="btn btn-info showinfo_class"
                                    target="_blank">
                                    <span>&#8227; Info</span>
                                </a>
                                <a
                                    href="/titles/74772/videos/1178253"
                                    className="btn btn-trailer vid_class"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>

        );
    }
}
