/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {useCallback, useMemo} from 'react';

import stripHtml from '../../../utils/strip-html';
import useQueryParamValues from '../../search-filters/hooks/use-query-param-values';
import useSetSearchFilters from '../../search-filters/hooks/use-set-search-filters';

/**
 * @typedef {typeof import("../types").Facet} Facet
 */

/**
 * @callback OnChangeFacet
 * @param {Facet[]} selected
 */

/**
 * @param {string} facetName
 * @return {{onChange: OnChangeFacet, isActive: boolean, value: Facet[]}}
 */
const useGenericFacet = (facetName) => {
    const value = useQueryParamValues(facetName);
    const {setFilter} = useSetSearchFilters();

    /**
     * @type {OnChangeFacet}
     */
    const onChange = useCallback((selected) => {
        setFilter(facetName, 'key', true, selected.map(item => ({
            key: stripHtml(item.key),
        })));
    }, [setFilter, facetName]);

    return useMemo(() => ({
        onChange,
        value: value.map(v => ({key: v})),
        isActive: !!value.length,
    }), [value, onChange]);
};

export default useGenericFacet;
