/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Modal, Nav, NavItem} from 'react-bootstrap';
import {Route, Switch} from 'react-router-dom';

import {OrderActions} from './order-actions';
import OrderFilters from './order-filters';
import OrderStore from './order-store';
import OrderTabs from './tabs';
import {MessagesContext} from '../messages/messages-context';
import {RouterActions} from '../router/router-actions';
import RouterHelpers from '../router/router-helpers';
import SessionStore from '../session/session-store';


const OrdersInProgress = RouterHelpers(OrderTabs.OrdersInProgress, true, SessionStore.PERMISSIONS.ORDERS.BROWSE);

class Orders extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            children: undefined
        };
    }

    static getStores() {
        return [OrderStore];
    }

    static calculateState() {
        return {
            toUnsubscribeClient: OrderStore.getState().get('toUnsubscribeClient')
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            links: Immutable.Map({
                ordersInProgress: '/orders',
                myOrders: '/orders/my-orders',
                requestsSentMe: '/orders/sent-to-me',
                mySavedCarts: '/orders/my-saved-carts',
                allOrders: '/orders/all-orders',
                subscriptions: '/orders/subscriptions',
                standingOrders: '/orders/standing-orders',
                metadata: '/orders/metadata'
            })
        }, this.constructor.calculateState());

        this.doUnsubscribeClient = this.doUnsubscribeClient.bind(this);

        return;
    }

    componentWillUnmount() {
        OrderActions.clear();
    }

    static contextType = MessagesContext;

    doUnsubscribeClient() {
        OrderActions.doUnsubscribeClient(this.state.toUnsubscribeClient);
    }

    handleTab(url) {
        return function(event) {
            event.preventDefault();
            RouterActions.redirect(url);
        };
    }

    isActive(linkName) {
        if (this.props.location.pathname === this.state.links.get(linkName)) {
            return true;
        }
    }

    render() {
        return (
            <div className={ClassNames({'container-zero-content': SessionStore.isContentNotAvailable()})}>
                <Modal show={!!this.state.toUnsubscribeClient} onHide={OrderActions.cancelUnsubscribeClient}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="modal-title text-center">{this.context.intl.messages['orders.tab.subscriptions.confirm-unsubscribe-client.dialog.title']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.context.intl.messages['orders.tab.subscriptions.confirm-unsubscribe-client.dialog.description']}</p>
                    </Modal.Body>
                    <Modal.Footer className=" padding-all-20">
                        <Button className="btn-primary pull-right" onClick={this.doUnsubscribeClient}>{this.context.intl.messages['common.unsubscribe']}</Button>
                        <Button className="btn-default btn-secondary margin-right-5 pull-left" onClick={OrderActions.cancelUnsubscribeClient}>{this.context.intl.messages['common.close']}</Button>
                    </Modal.Footer>
                </Modal>

                <OrderFilters location={this.props.location}/>
                <div className="container padding-y-10 padding-x-20" id="hide-this">
                    <div className="row">
                        <h2 className="padding-y-10 h1">{this.context.intl.messages['orders.title']}</h2>
                    </div>
                    <Nav className="nav nav-tabs">
                        <NavItem
                            active={this.isActive('ordersInProgress')}
                            eventKey={1}
                            onClick={this.handleTab(this.state.links.get('ordersInProgress'))}>
                            {this.context.intl.messages['orders.tab.orders-in-progress.title']}
                        </NavItem>
                        <NavItem
                            active={this.isActive('myOrders')}
                            eventKey={2}
                            onClick={this.handleTab(this.state.links.get('myOrders'))}>
                            {this.context.intl.messages['orders.tab.my-orders.title']}
                        </NavItem>
                        <NavItem
                            active={this.isActive('requestsSentMe')}
                            eventKey={3}
                            onClick={this.handleTab(this.state.links.get('requestsSentMe'))}>
                            {this.context.intl.messages['orders.tab.requests-sent-me.title']}
                        </NavItem>
                        <NavItem
                            active={this.isActive('mySavedCarts')}
                            eventKey={4}
                            onClick={this.handleTab(this.state.links.get('mySavedCarts'))}>
                            {this.context.intl.messages['orders.tab.my-saved-carts.title']}
                        </NavItem>
                        <NavItem
                            active={this.isActive('allOrders')}
                            eventKey={5}
                            onClick={this.handleTab(this.state.links.get('allOrders'))}>
                            {this.context.intl.messages['orders.tab.all-orders.title']}
                        </NavItem>
                        <NavItem
                            active={this.isActive('subscriptions')}
                            eventKey={6}
                            onClick={this.handleTab(this.state.links.get('subscriptions'))}>
                            {this.context.intl.messages['orders.tab.subscriptions.title']}
                        </NavItem>
                        <NavItem
                            active={this.isActive('standingOrders')}
                            eventKey={7}
                            onClick={this.handleTab(this.state.links.get('standingOrders'))}>
                            {this.context.intl.messages['orders.tab.standing-orders.title']}
                        </NavItem>
                        <NavItem
                            active={this.isActive('metadata')}
                            eventKey={8}
                            onClick={this.handleTab(this.state.links.get('metadata'))}>
                            {this.context.intl.messages['orders.tab.my-metadata.title']}
                        </NavItem>
                    </Nav>
                    <div className="tab-content">
                        <Switch>
                            <Route component={OrderTabs.MyOrders} exact path={`${this.props.match.path}/my-orders`}/>
                            <Route component={OrderTabs.RequestsSentMe} exact path={`${this.props.match.path}/sent-to-me`}/>
                            <Route component={OrderTabs.MySavedCarts} exact path={`${this.props.match.path}/my-saved-carts`}/>
                            <Route component={OrderTabs.AllOrders} exact path={`${this.props.match.path}/all-orders`}/>
                            <Route component={OrderTabs.Subscriptions} exact path={`${this.props.match.path}/subscriptions`}/>
                            <Route component={OrderTabs.StandingOrders} exact path={`${this.props.match.path}/standing-orders`}/>
                            <Route component={OrderTabs.Metadata} exact path={`${this.props.match.path}/metadata`}/>
                            <Route component={OrdersInProgress}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default Container.create(Orders);
export {Orders};
