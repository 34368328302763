/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import React from 'react';

import {CircleArrows} from '../../icons/all-icons';

interface LoadingOverlayProps {
    show?: boolean;
}

const ViewGridIcon = ({show}: LoadingOverlayProps): JSX.Element | null => {
    if (!show) {
        return null;
    }

    return <div className="flex loading-overlay absolute h-full w-full inset-0 bg-gray-light/50 justify-center items-center">
        <CircleArrows classes="animate-spin w-14 h-14 fill-gray-icon"/>
    </div>;
};

ViewGridIcon.defaultProps = {
    show: true
};

export default ViewGridIcon;
