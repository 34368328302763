/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

import {FACET_NAMES} from '../../constants';
import './option-styles.less';

/**
 * @param {React.StatelessComponent<{data: {group: string}, children: React.Children}>} Component
 * @return {React.StatelessComponent}
 */
const withItalicizedCharacterOption = (Component) => {
    /**
     * @param {{data: {group: string}, children: React.Children}} props
     * @return {JSX.Element}
     * @constructor
     */
    const Wrapper = (props) => {
        let childrenValue = props.children;
        const group = props.data.group;

        if (typeof childrenValue === 'string') {
            if (group === FACET_NAMES.CHARACTER) {
                childrenValue = (
                    <span className="talent-character-option">
                        {childrenValue}
                    </span>
                );
            }
            if (
                group === FACET_NAMES.TALENT_CHARACTER &&
                childrenValue.includes('|')
            ) {
                childrenValue = childrenValue.split('|').map((item, index) => {
                    if (index === 0) {
                        return `${item}|`;
                    }

                    return (
                        <span key={item} className="talent-character-option">
                            {item}
                        </span>
                    );
                });
            }
        }

        return <Component {...props}>{childrenValue}</Component>;
    };

    Wrapper.defaultProps = {
        children: null,
        data: {
            group: '',
        },
    };

    Wrapper.propTypes = {
        children: PropTypes.node.isRequired,
        data: PropTypes.shape({
            group: PropTypes.string,
        }),
    };

    return Wrapper;
};

withItalicizedCharacterOption.defaultProps = {
    children: null,
    data: {
        group: '',
    },
};

withItalicizedCharacterOption.propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.shape({
        group: PropTypes.string,
    }),
};

export default withItalicizedCharacterOption;
