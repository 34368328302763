/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, FormGroup} from 'react-bootstrap';
import {Prompt} from 'react-router';
import Scroll from 'react-scroll';

import {WorkOrderActions, WorkOrderConstants} from './work-order-actions';
import WorkOrderStore from './work-order-store';
import MetadataExportFallbackEnglish from '../account/tabs/delivery/metadata-export-fallback-english';
import MetadataExportLanguage from '../account/tabs/delivery/metadata-export-language';
import MetadataExportSpec from '../account/tabs/delivery/metadata-export-spec';
import DocumentTitle from '../common/document-title';
import Table from '../common/table';
import Validations from '../common/validations/validations';
import WithPermissions from '../decorators/with-permissions';
import {FormRow, FormItem} from '../form/form';
import enUS from '../messages/en-US.json';
import {MessagesContext} from '../messages/messages-context';
import {AlertsWarnings} from '../notification/alert-warning';
import Preloader from '../preloader';
import SessionStore from '../session/session-store';

/* istanbul ignore next */
const COLUMNS = [{
    getHeader: () => <th className="icon-column" key="number" scope="col">#</th>,
    getValue: (tla, index) => {
        return index + 1;
    }
}, {
    getHeader: context => <th key="ro" scope="col">{context.messages['work-orders.titles-table.ro']}</th>,
    getValue: tla => tla.get('fullRunningOrder')
}, {
    getHeader: context => <th key="parent" scope="col">{context.messages['work-orders.titles-table.parent']}</th>,
    getValue: tla => tla.get('parentTitleDisplayName')
}, {
    getHeader: context => <th key="name" scope="col">{context.messages['work-orders.titles-table.title']}</th>,
    getValue: tla => tla.get('titleDisplayName')
}, {
    getHeader: context => <th key="mpm" scope="col">{context.messages['common.mpm']}</th>,
    getValue: tla => tla.get('mpmNumber')
}, {
    getHeader: () => <th className="icon-column" key="actions" scope="col"><span className="glyphicon glyphicon-trash" /></th>,
    getValue: (tla, index) => `<a class="delete-title" data-index=${index} data-work-order-id=${tla.get('titleId')} data-toggle="tooltip" data-placement="top" title="Delete"><span class="glyphicon glyphicon-trash"></span></a>`
}];

const BUTTON_CONSTANTS = {
    SAVE_BUTTON: 'SAVE_BUTTON',
    SAVE_SUBMIT_BUTTON: 'SAVE_SUBMIT_BUTTON'
};

/* istanbul ignore next */
const WorkOrderValidations = {
    allValidations: {
        label: 'work-orders.export-spec',
        validations: [Validations.custom(
            ()=> {
                return enUS['work-orders.export-spec.error'];
            },
            ()=> {
                return (
                    WorkOrderStore.getState().getIn(['workOrder', 'exportSpecification']) !== null &&
                    WorkOrderStore.getState().getIn(['workOrder', 'exportSpecification']) !== undefined
                );
            }
        ),
        Validations.custom(
            ()=> {
                return enUS['work-orders.btn.download.no-package-name'];
            },
            ()=> {
                return (
                    WorkOrderStore.getState().getIn(['workOrder', 'name']) !== '' &&
                    WorkOrderStore.getState().getIn(['workOrder', 'name']) !== undefined
                );
            }
        ),

        ]
    },

    onlyName: {
        label: 'work-orders.export-spec',
        validations: [Validations.custom(
            ()=> {
                return enUS['work-orders.btn.download.no-package-name'];
            },
            ()=> {
                return (
                    WorkOrderStore.getState().getIn(['workOrder', 'name']) !== '' &&
                    WorkOrderStore.getState().getIn(['workOrder', 'name']) !== undefined
                );
            }
        ),

        ]
    },
};

class WorkOrder extends Component {

    static get propTypes() {
        return {
            match: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canCreateWorkOrder: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.WORK_ORDERS.CREATE),
            canDownloadSalesDeck: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.WORK_ORDERS.DOWNLOAD),
            canEditWorkOrder: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.WORK_ORDERS.EDIT),
            canExportWorkOrder: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.WORK_ORDERS.EXPORT),
            canViewDigitalSalesDeck: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.WORK_ORDERS.VIEW_DSD_LINK)
        };
    }

    static calculateState() {
        return {
            isWorkOrderLoading: WorkOrderStore.getState().get('isWorkOrderLoading'),
            originalWorkOrder: WorkOrderStore.getState().get('originalWorkOrder'),
            originalWorkOrderTitles: WorkOrderStore.getState().get('originalWorkOrderTitles'),
            statusTypes: WorkOrderStore.getState().get('statusTypes'),
            userId: SessionStore.getState().getIn(['authUser', 'id']),
            workOrder: WorkOrderStore.getState().get('workOrder'),
            workOrderTitles: WorkOrderStore.getState().get('workOrderTitles')
        };
    }

    static getStores() {
        return [WorkOrderStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            mpmNumbers: '',
            needsArtWork: false,
            showErrors: false,
            buttonPressed: undefined
        }, this.constructor.calculateState());

        this.handleAddToWorkOrder = this.handleAddToWorkOrder.bind(this);
        this.handleArtWorkChange = this.handleArtWorkChange.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleReorder = this.handleReorder.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSaveAndDownload = this.handleSaveAndDownload.bind(this);
        this.handleSaveAndSubmit = this.handleSaveAndSubmit.bind(this);
        this.handleSaveExportSpecAndDownload = this.handleSaveExportSpecAndDownload.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSetMpmNumbers = this.handleSetMpmNumbers.bind(this);
        this.handleViewDigitalSalesDeck = this.handleViewDigitalSalesDeck.bind(this);
        this.showMessage = this.showMessage.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            WorkOrderActions.get(this.props.match.params.id, this.state.workOrderTitles);
        }

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.showErrors !== this.state.showErrors) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showErrors !== this.state.showErrors && this.state.showErrors) {
            Scroll.animateScroll.scrollToTop();
        }
    }

    componentWillUnmount() {
        WorkOrderActions.clear();

        return;
    }

    static contextType = MessagesContext;

    handleAddToWorkOrder() {
        WorkOrderActions.batchMPMs(this.props.match.params.id, this.state.mpmNumbers);
        this.setState(() => ({
            mpmNumbers: ''
        }));
    }

    handleArtWorkChange() {
        WorkOrderActions.updateAttr('needsArtWork', !this.state.workOrder.get('needsArtWork'));
    }

    handleDownload() {
        WorkOrderActions.startDownload(this.props.match.params.id);
    }

    handleReorder(event, diff, edit ) {
        event.preventDefault();
        let fromPos = edit.triggerRow[0][0];
        if (diff.length < 2) {
            return;
        }
        let toPos;
        if (diff[0].oldPosition === fromPos) {
            toPos = diff[0].newPosition;
        } else {
            toPos = diff[diff.length-1].newPosition;
        }
        setTimeout(() => {
            WorkOrderActions.reorder(fromPos, toPos);
            return;
        }, 0);
    }

    handleRowClick(event) {
        let target = event.target;
        if (~target.className.indexOf('glyphicon')) {
            target = target.parentElement;
        }
        switch (true) {
        case !!~target.className.indexOf('delete-title'):
            let index = parseInt(target.getAttribute('data-index'), 10);
            WorkOrderActions.removeTitle(index);
            break;
        }
        return;
    }

    handleSave(status) {
        this.setState({
            buttonPressed: BUTTON_CONSTANTS.SAVE_BUTTON,
            showErrors: false
        });
        const isValidToSave = Validations.validate(this.state.workOrder, {name: WorkOrderValidations.onlyName});
        if (isValidToSave.length) {
            this.setState({
                showErrors: true
            });
        } else {
            this.setState({
                showErrors: false
            });
            WorkOrderActions.updateAttr('status', status);
            WorkOrderActions.save(
                WorkOrderStore.getState().get('workOrder'),
                this.state.workOrderTitles,
                this.state.userId,
                this.state.originalWorkOrder,
                this.state.originalWorkOrderTitles
            );
        }
    }

    handleSaveAndDownload() {
        WorkOrderActions.updateAttr('status', WorkOrderConstants.STATUS_BY_NAME.ACTIVE);
        WorkOrderActions.saveAndDownload(
            WorkOrderStore.getState().get('workOrder'),
            this.state.workOrderTitles,
            this.state.userId
        );
    }

    handleSaveAndSubmit() {
        this.setState({
            buttonPressed: BUTTON_CONSTANTS.SAVE_SUBMIT_BUTTON
        });
        let validateAll = Validations.validate(this.state.workOrder, WorkOrderValidations);
        if (validateAll.length) {
            this.setState({
                showErrors: true
            });
        } else {
            this.setState({
                showErrors: false
            });
            WorkOrderActions.updateAttr('status', WorkOrderConstants.STATUS_BY_NAME.COMPLETE);
            WorkOrderActions.save(
                WorkOrderStore.getState().get('workOrder'),
                this.state.workOrderTitles,
                this.state.userId
            );
        }
    }

    handleSaveExportSpecAndDownload(exportSpec) {
        const workOrder = WorkOrderStore.getState().get('workOrder');
        WorkOrderActions.saveExportSpecAndDownload(
            workOrder,
            this.state.workOrderTitles,
            this.state.userId,
            exportSpec,
            this.state.originalWorkOrder
        );
    }

    handleViewDigitalSalesDeck() {
        const guid = this.state.workOrder.get('guid');
        WorkOrderActions.viewDigitalSalesDeck(guid);
        return;
    }

    handleSelect(attr) {
        return (value) => {
            WorkOrderActions.updateAttr(attr, value?.id);
        };
    }

    handleSelectFallbackEnglish(value) {
        return WorkOrderActions.updateAttr('fallbackEnglish', value.id === 1);
    }

    handleSetMpmNumbers(event) {
        this.setState({
            mpmNumbers: event.target.value
        });
    }

    showMessage(location) {
        let nextLocation = this.context.intl.messages['common.unsaved-changes'];
        if ((/^\?q=/.test(location.search) && /^\/packages/.test(location.pathname)) ||
            (this.state.workOrder.equals(this.state.originalWorkOrder) &&
            this.state.workOrderTitles.equals(this.state.originalWorkOrderTitles) &&
            this.state.mpmNumbers === '')
        ) {
            nextLocation = true;
        }
        return nextLocation;
    }

    render() {
        let warning;
        /* istanbul ignore if */
        if (this.state.showErrors) {
            let validations = Validations.validate(this.state.workOrder, {allValidations: WorkOrderValidations.allValidations});
            if (this.state.buttonPressed === BUTTON_CONSTANTS.SAVE_BUTTON) {
                validations = Validations.validate(this.state.workOrder, {name: WorkOrderValidations.onlyName});
            }
            warning = (<AlertsWarnings validations={validations} />);
        }
        let disabled = false;
        let downloadExcelButton;
        let downloadJsonButton;
        let downloadSalesDeckButton;
        let viewDigitalSalesDeck;
        let hasDueDate = false;
        let mode = 'create';
        if (this.props.match.params.id) {
            mode = 'edit';
        }
        let mpmsRow;
        let saveAndSubmitButton;
        let setter = WorkOrderActions.updateAttr.bind(WorkOrderActions);
        let status;
        let statusSel;
        let workOrder = this.state.workOrder;
        let workOrderId = '';
        if (this.props.match.params.id) {
            workOrderId = <span style={{fontSize: 16}}> #{this.props.match.params.id}</span>;
        }

        if (mode === 'create' && !this.props.permissions.canCreateWorkOrder ||
            mode === 'edit' && !this.props.permissions.canEditWorkOrder) {
            disabled = true;
        }

        if (this.state.workOrder.get('status') !== WorkOrderConstants.STATUS_BY_NAME.COMPLETE &&
            this.props.permissions.canExportWorkOrder) {
            saveAndSubmitButton = <Button
                bsSize="large"
                className="btn-primary pull-right margin-left-10"
                disabled={disabled}
                onClick={this.handleSaveAndSubmit.bind(this, WorkOrderConstants.STATUS_BY_NAME.COMPLETE)}>
                {this.context.intl.messages['work-orders.btn.save-and-submit']}
            </Button>;
        }
        /* istanbul ignore if */
        if (this.state.workOrder.get('status') === WorkOrderConstants.STATUS_BY_NAME.COMPLETE) {
            let downloadBtnTitle = '';
            if (!workOrder.get('exportSpecification')) {
                downloadBtnTitle = this.context.intl.messages['work-orders.btn.download.no-export-spec'];
            }
            downloadExcelButton = <Button
                bsSize="large"
                className="btn-primary pull-right margin-left-10"
                disabled={disabled || !workOrder.get('exportSpecification')}
                onClick={this.handleSaveExportSpecAndDownload.bind(this, WorkOrderConstants.EXPORT_TYPES.EXCEL)}
                title={downloadBtnTitle}>
                <i className="far fa-file-excel"></i>&nbsp;
                {this.context.intl.messages['work-orders.btn.download']}
            </Button>;
            if (parseInt(this.state.workOrder.get('exportSpecification'), 10) !== WorkOrderConstants.EXPORT_SPECIFICATIONS_BY_NAME.MASS_UPDATE_TEMPLATE) {
                let downloadJsonBtnTitle = '';
                if (!workOrder.get('exportSpecification')) {
                    downloadJsonBtnTitle = this.context.intl.messages['work-orders.btn.download.no-export-spec'];
                }
                downloadJsonButton = <Button
                    bsSize="large"
                    className="btn-primary pull-right margin-left-10"
                    disabled={disabled || !workOrder.get('exportSpecification')}
                    onClick={this.handleSaveExportSpecAndDownload.bind(this, WorkOrderConstants.EXPORT_TYPES.JSON)}
                    title={downloadJsonBtnTitle}>
                    <i className="far fa-brackets-curly"></i>&nbsp;
                    {this.context.intl.messages['work-orders.btn.download-json']}
                </Button>;
            }
        }

        if (this.props.permissions.canDownloadSalesDeck) {
            switch (mode) {
            case 'create':
                downloadSalesDeckButton = <Button
                    bsSize="large"
                    className="btn-primary pull-right margin-left-10"
                    disabled={disabled}
                    onClick={this.handleSaveAndDownload}>
                    {this.context.intl.messages['work-orders.btn.save-and-download-sales-deck']}
                </Button>;
                break;
            case 'edit':
                downloadSalesDeckButton = <Button
                    bsSize="large"
                    className="btn-primary pull-right margin-left-10"
                    disabled={disabled}
                    onClick={this.handleDownload}>
                    <i className="far fa-file-pdf"></i>&nbsp;
                    {this.context.intl.messages['work-orders.btn.download-sales-deck']}
                </Button>;
                break;
            }
        }

        if (mode === 'edit') {
            if (this.props.permissions.canViewDigitalSalesDeck) {
                viewDigitalSalesDeck = <Button
                    bsSize="large"
                    className="btn-primary pull-right margin-left-10"
                    disabled={disabled}
                    onClick={this.handleViewDigitalSalesDeck}>
                    <i className="far fa-object-group"></i>&nbsp;
                    {this.context.intl.messages['work-orders.btn.view-digital-sales-deck']}
                </Button>;
            }
            mpmsRow = (<div>
                <div className="row">
                    <h3>
                        <span className="glyphicon glyphicon-list"/>&nbsp;
                        {this.context.intl.messages['work-orders.add-titles-by-mpm.title']}
                    </h3>
                </div>
                <div className="row form-wrapper">
                    <div className="col-md-12 form-group">
                        <FormRow>
                            <FormGroup>
                                <label>
                                    {this.context.intl.messages['common.mpm']}
                                    <em className="lighter">{this.context.intl.messages['work-orders.mpm-numbers.text']}</em>
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    disabled={disabled}
                                    onChange={this.handleSetMpmNumbers}
                                    value={this.state.mpmNumbers}
                                />
                            </FormGroup>
                        </FormRow>
                        <Button
                            bsSize="small"
                            className="btn-primary pull-right"
                            disabled={disabled}
                            onClick={this.handleAddToWorkOrder}>
                            {this.context.intl.messages['work-orders.btn.add-to-work-order']}
                        </Button>
                    </div>
                </div>
            </div>);
            let selectedStatus = WorkOrderConstants.STATUS_TYPES.find((item) => {
                return item.id === workOrder.get('status');
            });
            statusSel = '';
            /* istanbul ignore if */
            if (selectedStatus !== undefined) {
                statusSel = selectedStatus.name;
            }
            status = <div className="col-md-4">
                <label>
                    {this.context.intl.messages['work-orders.status']}
                </label><br/>
                {statusSel}
            </div>;
        }
        /* istanbul ignore if */
        if (workOrder.get('dueDate') !== undefined && workOrder.get('dueDate') !== null) {
            hasDueDate = true;
        }
        let packageDetailFields;
        if (this.props.permissions.canExportWorkOrder) {
            packageDetailFields = <div>
                <div className={ClassNames('col-md-12', {'form-group': !hasDueDate})}>
                    <div className="col-md-4">
                        <FormItem attr="client"
                            label={this.context.intl.messages['work-orders.client']}
                            md={12}
                            model={workOrder}
                            setter={setter}
                            type="text"
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-4">
                        <FormItem attr="dueDate"
                            label={this.context.intl.messages['work-orders.due-date']}
                            md={4}
                            model={workOrder}
                            setter={setter}
                            type="date"
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-md-4 form-group">
                        <label>
                            {this.context.intl.messages['work-orders.export-spec']}
                        </label><br/>
                        <MetadataExportSpec
                            disabled={disabled}
                            onChange={this.handleSelect('exportSpecification')}
                            value={workOrder.get('exportSpecification')}
                        />
                    </div>
                </div>
                <div className="col-md-12 form-group">
                    <div className="col-md-4 form-group">
                        <label>
                            {this.context.intl.messages['work-orders.language']}
                            <em className="lighter"> {this.context.intl.messages['work-orders.language.text']}</em>
                        </label><br/>
                        <MetadataExportLanguage
                            disabled={disabled}
                            onChange={this.handleSelect('languageCode')}
                            value={workOrder.get('languageCode')}
                        />
                    </div>
                    <div className="col-md-4 form-group">
                        <label>
                            {this.context.intl.messages['work-orders.fallback-english']}
                        </label><br/>
                        <MetadataExportFallbackEnglish
                            disabled={disabled}
                            onChange={this.handleSelectFallbackEnglish}
                            workOrder={workOrder}
                        />
                    </div>
                    <div className="col-md-4 form-group">
                        <label>
                            {this.context.intl.messages['work-orders.artwork']}
                        </label>
                        <div className="checkbox">
                            <label>
                                <input type="checkbox"
                                    onChange={this.handleArtWorkChange}
                                    checked={workOrder.get('needsArtWork')}
                                    value="needsArtWork"/>
                                <span>{this.context.intl.messages['work-orders.artwork.text']}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12">
                    <div className="col-md-12 form-group">
                        <FormRow>
                            <FormItem attr="description"
                                label={this.context.intl.messages['work-orders.notes']}
                                md={12}
                                model={workOrder}
                                setter={setter}
                                type="textarea"
                                disabled={disabled}
                            />
                        </FormRow>
                    </div>
                </div>
            </div>;
        }

        return (
            <DocumentTitle message="document-titles.work-order">
                <Prompt
                    message={this.showMessage}
                />
                <Preloader fixed className="text-primary" show={this.state.isWorkOrderLoading}>
                    <div className="container padding-y-10 padding-x-20 Pos(rel)">
                        <div className="row">
                            <h3>
                                <span className="glyphicon glyphicon-copy"/>&nbsp;
                                {this.context.intl.messages[`work-orders.${mode}.title`]}{workOrderId}
                            </h3>
                        </div>
                        <div className="row form-wrapper">
                            {warning}
                            <div className="col-xs-12">
                                <div className="col-md-8 form-group">
                                    <FormItem attr="name"
                                        label={this.context.intl.messages['work-orders.order-name']}
                                        md={8}
                                        model={workOrder}
                                        setter={setter}
                                        type="text"
                                        disabled={disabled}
                                        validations={[Validations.required]}
                                    />
                                </div>
                                {status}
                            </div>
                            {packageDetailFields}
                        </div>
                        {mpmsRow}
                        <div className="row">
                            <h3>
                                <span className="glyphicon glyphicon-th"/>&nbsp;
                                {this.context.intl.messages['work-orders.titles-in-order.title']}
                            </h3>
                        </div>
                        <div className="row form-wrapper">
                            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                <Table
                                    className="table table-stripped"
                                    columns={COLUMNS}
                                    enabledReorder={true}
                                    handleReorder={this.handleReorder}
                                    handleRowClick={this.handleRowClick}
                                    items={this.state.workOrderTitles}
                                />
                            </div>
                        </div>
                        <div className="row padding-y-20">
                            <Button
                                bsSize="large"
                                className="btn-primary pull-right margin-left-10"
                                disabled={disabled}
                                onClick={this.handleSave.bind(this, WorkOrderConstants.STATUS_BY_NAME.ACTIVE)}>
                                {this.context.intl.messages['work-orders.btn.save']}
                            </Button>
                            {saveAndSubmitButton}
                            {downloadExcelButton}
                            {downloadJsonButton}
                            {downloadSalesDeckButton}
                            {viewDigitalSalesDeck}
                        </div>
                    </div>
                </Preloader>
            </DocumentTitle>
        );
    }

}

export default WithPermissions(Container.create(WorkOrder));
export {WorkOrder};
