/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import ExternalLinkButton from './external-link-button';
import ExternalLinks, {PermissionsPropType} from './external-links';

export default class ExternalLinksSection extends React.PureComponent {
    static get propTypes() {
        return {
            permissions: PermissionsPropType.isRequired,
            links: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    render() {
        return (
            <ExternalLinks permissions={this.props.permissions} links={this.props.links} render={({links}) => {
                if (!links.size) {
                    // render nothing
                    return null;
                }

                return (
                    <div className="container padding-top-10 padding-x-10">
                        <h3 className="margin-top-10"><FormattedMessage id="title.links.title" /></h3>
                        {links.map(link => <ExternalLinkButton link={link} />)}
                        <hr />
                    </div>
                );
            }} />
        );
    }
}
