/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {OrderActions} from './order-actions';
import {MessagesContext} from '../messages/messages-context';


export default class UnsubscribeClientsModal extends React.Component {
    static get propTypes() {
        return {
            onClose: PropTypes.func.isRequired,
            selectedClients: PropTypes.instanceOf(Immutable.List).isRequired,
            show: PropTypes.bool.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.doUnsubscribeClients = this.doUnsubscribeClients.bind(this);
        this.doClose = this.doClose.bind(this);
    }

    static contextType = MessagesContext;

    doUnsubscribeClients() {
        OrderActions.unsubscribeSelectedClients(this.props.selectedClients);
        this.props.onClose(true);
    }

    doClose() {
        this.props.onClose(false);
    }

    render() {
        return (
            <Modal show={!!this.props.show} onHide={this.doClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center">{this.context.intl.messages['orders.tab.subscriptions.confirm-unsubscribe-client.dialog.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{this.context.intl.messages['orders.tab.subscriptions.confirm-unsubscribe-selected-clients.dialog.description']}
                    </p>
                </Modal.Body>
                <Modal.Footer className=" padding-all-20">
                    <Button className="btn-primary pull-right" onClick={this.doUnsubscribeClients}>{this.context.intl.messages['common.unsubscribe']}</Button>
                    <Button className="btn-default btn-secondary margin-right-5 pull-left" onClick={this.doClose}>{this.context.intl.messages['common.close']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
