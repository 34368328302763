/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Checkbox, ControlLabel, Grid, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {TransitionGroup} from 'react-transition-group';

import {FormRow, FormItem} from '../../form/form';
import TerritorySelect from '../../lookup/territory/territory-select';
import {MessagesContext} from '../../messages/messages-context';
import {Alert} from '../../notification/alert';
import {AlertsWarnings} from '../../notification/alert-warning';
import {SessionConstants} from '../../session/session-actions';
import SessionStore from '../../session/session-store';
import {SignUpActions} from '../signup-actions';
import SignUpStore, {FYCValidations} from '../signup-store';

import './signup-fyc.less';

class FYCSignUp extends React.Component {
    static get propTypes() {
        return {
            isLoading: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            isLoading: true,
        };
    }

    static calculateState() {
        return {
            signup: SignUpStore.getState().get('signup'),
            defaultCopyright: SessionStore.getState().get('defaultCopyright'),
        };
    }

    static getStores() {
        return [SignUpStore, SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            alertVisible: false,
            isCheckboxEnabled: true,
            isFormSubmited: false,
            isTerritoryDirty: false,
            partner: SessionStore.getPartner()
        }, this.constructor.calculateState());

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static contextType = MessagesContext;

    handleCheckboxChange(attr, event) {
        SignUpActions.updateAttr(attr, event.target.checked);
        this.setState(prevState => ({
            isCheckboxEnabled: !prevState.isCheckboxEnabled
        }));
    }

    handleSubmit(e) {
        e.preventDefault();
        let validations = SignUpStore.getFYCValidations();
        if (validations.length) {
            this.setState({isFormSubmited: true});
        } else {
            SignUpActions.signUpFyc(this.state.signup);
        }
    }

    render() {
        let checked;
        let setter = SignUpActions.updateAttr.bind(SignUpActions);
        let validations = SignUpStore.getFYCValidations();
        let warning;

        /* istanbul ignore if */
        if (this.state.value) {
            checked = 'checked';
        }

        // if form is not submited
        /* istanbul ignore if */
        if (this.state.isFormSubmited) {
            warning = (
                <AlertsWarnings
                    title={this.context.intl.messages['signup.form.error.message']}
                    validations={validations}
                />
            );
        }
        return (
            <div className="fyc-body">
                <FormRow onScroll={this.handleScrollHeader} ref="header">
                    <div className="fyc-header-inner-bg">
                        <div className="fyc-logo fyc-logo-container">
                            <span className="hb-dark-logo hb-visible-logo hb-logo-wrap">
                                <img src="/page/fyc/fyc-logo.png" height="44" className="default fyc-logo-img" alt="FYC"/>
                            </span>
                        </div>
                    </div>
                </FormRow>
                <FormRow>
                    <div className="fyc-form-container">
                        <Row className="fyc-form-label">
                            <h1>{this.context.intl.messages['signup.form.title.fyc']}</h1>
                            <TransitionGroup
                                transitionName="alert"
                                transitionEnterTimeout={300}
                                transitionLeaveTimeout={300}>
                                <Alert />
                                {warning}
                            </TransitionGroup>
                        </Row>
                        <Row className="fyc-main-form">
                            <form autoComplete="off">
                                <FormRow className="fyc-control-label">
                                    <FormItem attr="firstName"
                                        className="fyc-form-input"
                                        label={this.context.intl.messages['signup.form.label.name']}
                                        model={this.state.signup}
                                        setter={SignUpActions.updateAttr.bind(SignUpActions)}
                                        validations={FYCValidations.firstName.validations}
                                    />
                                    <FormItem attr="lastName"
                                        label={this.context.intl.messages['signup.form.label.lastName']}
                                        model={this.state.signup}
                                        setter={setter}
                                        validations={FYCValidations.lastName.validations}
                                    />
                                </FormRow>
                                <FormRow>
                                    <FormItem attr="email"
                                        label={this.context.intl.messages['signup.form.label.email']}
                                        model={this.state.signup}
                                        setter={SignUpActions.updateAttr.bind(SignUpActions)}
                                        validations={FYCValidations.email.validations}
                                    />
                                </FormRow>
                                <FormRow>
                                    <div className="territory-select">
                                        <TerritorySelect
                                            attr="country"
                                            isLoading={this.props.isLoading}
                                            label={this.context.intl.messages['signup.form.label.country']}
                                            model={this.state.signup}
                                            name="country"
                                            placeholder={this.context.intl.messages['signup.form.label.country.placeholder']}
                                            required
                                            setter={SignUpActions.updateAttr.bind(SignUpActions)}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <ControlLabel><span>{this.context.intl.messages['signup.form.label.legal']}&nbsp;<span className="text-red">*</span></span></ControlLabel>
                                </FormRow>
                                <FormRow additionalClass="agree-terms-checkbox">
                                    <Checkbox name checked={checked} onChange={this.handleCheckboxChange.bind(this, 'agreeTerms')}>
                                        {this.context.intl.messages['signup.form.label.term-of-use-fyc.text']}<Link to={SessionConstants.EXTERNAL_URLS.TERMS_OF_USE} target="_blank">{this.context.intl.messages['signup.form.label.term-of-use']}</Link>{this.context.intl.messages['signup.form.label.privacy-policy-fyc.text']}<Link to={SessionConstants.EXTERNAL_URLS.PRIVACY_POLICY} target="_blank">{this.context.intl.messages['signup.form.label.privacy-policy']}</Link>
                                    </Checkbox>
                                </FormRow>
                                <div className="col-md-12 fyc-submit">
                                    <Button bsSize="large"
                                        bsStyle="primary"
                                        className="pull-left"
                                        disabled={this.state.isCheckboxEnabled}
                                        onClick={this.handleSubmit}>
                                        {this.context.intl.messages['signup.form.label.submit.fyc']}
                                    </Button>
                                </div>
                            </form>
                        </Row>
                    </div>
                </FormRow>
                <FormRow>
                    <footer className="copyright fyc-copyright">
                        <Grid>{this.state.defaultCopyright}</Grid>
                        <Grid>
                            <Link to={SessionConstants.EXTERNAL_URLS.PRIVACY_POLICY} target="_blank">
                                {this.context.intl.messages['signup.form.label.privacy-policy']}
                            </Link> | <Link to={SessionConstants.EXTERNAL_URLS.TERMS_OF_USE} target="_blank">
                                {this.context.intl.messages['signup.form.label.term-of-use']}
                            </Link>
                        </Grid>
                    </footer>
                </FormRow>
            </div>
        );
    }
}

export default Container.create(FYCSignUp);
export {FYCSignUp};
