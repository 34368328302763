/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {detect} from 'detect-browser';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {HomeActions} from './home-actions';
import HomeStore from './home-store';
import MultipleTitles from './multiple-titles';
import {PageTypes} from './page-types';
import SingleTitle from './single-title';
import ZeroContent from './zero-content';
import {WBTVDAnalyticsConstants, WBTVDAnalyticsExtraInfo} from '../analytics/wbtvd-analytics';
import DocumentTitle from '../common/document-title';
import {EventActions} from '../events/event-actions';
import EventStore from '../events/event-store';
import {NotificationActions} from '../notification/notification-actions';
import PlayerStore from '../player/player-store';
import VideoOverlay from '../player/video-overlay';
import Preloader from '../preloader';
import SessionStore from '../session/session-store';
import {CompareImmutable} from '../utils/utils';

import './home.less';

class Home extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [EventStore, HomeStore, PlayerStore, SessionStore];
    }

    static calculateState() {
        return {
            eventIds: SessionStore.getState().get('eventIds'),
            eventTitles: EventStore.getState().get('eventTitles'),
            homepage: HomeStore.getState().get('homepage'),
            images: HomeStore.getState().get('images'),
            partner: SessionStore.getState().get('partner'),
            sessionHomepage: SessionStore.getState().get('homepage'),
            showOneTimeWelcomeToast: SessionStore.getState().get('showOneTimeWelcomeToast'),
            showOverlay: PlayerStore.getState().get('showOverlay'),
            titles: HomeStore.getState().get('titles'),
            video: PlayerStore.getState().get('video'),
            videos: HomeStore.getState().get('videos'),
            watchlist: PlayerStore.getState().get('watchlist'),
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidMount() {
        HomeActions.getHomepage();
        HomeActions.getNewThisWeek();
        if (this.state.eventIds) {
            EventActions.get(this.state.eventIds);
        }
        if (this.state.showOneTimeWelcomeToast && SessionStore.canUser(SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.INFO.ACCOUNT_INFO)) {
            setTimeout(() => {
                NotificationActions.showAlertInfo('homepage.first.time.login');
            }, 10);
        }

        if (!this.isBrowserSupported()) {
            setTimeout(() => {
                NotificationActions.showAlertDanger('login.browser.not.supported');
            }, 10);
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        const immutableOldState = Immutable.Map(this.state);
        const immutableNextState = Immutable.Map(nextState);
        return !CompareImmutable(immutableOldState, immutableNextState, ['images', 'homepage', 'showOverlay', 'titles', 'video', 'videos']);
    }

    isBrowserSupported() {
        if (typeof navigator.brave !== 'undefined') {
            return false;
        }

        const browser = detect();

        if (!browser) {
            return false;
        }

        const browserName = browser.name.toUpperCase();

        switch (browser && browserName) {
        case WBTVDAnalyticsConstants.BROWSERS.CHROME:
        case WBTVDAnalyticsConstants.BROWSERS.FIREFOX:
        case WBTVDAnalyticsConstants.BROWSERS.EDGE:
        case WBTVDAnalyticsConstants.BROWSERS.EDGE_CHROMIUM:
        case WBTVDAnalyticsConstants.BROWSERS.SAFARI:
            return parseInt(browser.version, 10) >= WBTVDAnalyticsExtraInfo.getMinimumSupportedVersion(browserName);
        default:
            return false;
        }
    }

    render() {
        if (SessionStore.isMultipleTitles()) {
            return <MultipleTitles location={this.props.location}/>;
        }

        if (SessionStore.isSingleTitle()) {
            return <SingleTitle location={this.props.location}/>;
        }

        if (SessionStore.isContentNotAvailable()) {
            return <ZeroContent />;
        }

        if (!this.state.homepage.get('pageType')) {
            return (
                <DocumentTitle
                    message="document-titles.home"
                >
                    <Preloader fixed className="text-primary" />
                </DocumentTitle>
            );
        }

        /**
         * Get the page type from the homepage object.
         */
        let PAGE_TYPE = Object.values(PageTypes).filter(
            /* istanbul ignore next */
            pt => pt.ID === this.state.homepage.get('pageType')
        )[0];


        let videoValue;
        if (this.state.video.size !== 0) {
            videoValue = this.state.video;
        }

        let addedSections = [];

        return (
            <DocumentTitle
                message="document-titles.home"
            >
                <>
                    {PAGE_TYPE.SECTIONS.map((SECTION, i) => {
                        let publishingList = null;
                        if (SECTION.ID !== null) {
                            let section = this.state.homepage.get('sections').find(s => {
                                return s.get('sectionType') === SECTION.ID && !addedSections.includes(s);
                            });
                            if (!section) {
                                return <div />;
                            }
                            addedSections.push(section);
                            publishingList = section.get('publishingList');
                        }
                        return (
                            <SECTION.COMPONENT
                                images={this.state.images}
                                key={i}
                                partnerId={this.state.partner.get('id')}
                                publishingList={publishingList}
                                titles={this.state.titles}
                                videos={this.state.videos}
                            />
                        );
                    })}
                    <VideoOverlay
                        clipping
                        noShare
                        titleId={this.state.video.getIn(['browseTitleList', 0, 'id'])}
                        video={videoValue}
                        visible={this.state.showOverlay}
                        watchlist={this.state.watchlist}
                    />
                </>
            </DocumentTitle>
        );
    }
}

export default Container.create(Home);

export {Home};
