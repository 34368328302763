/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel} from 'react-bootstrap';
import Select from 'react-select';

import {ContactSelectActions} from './contact-select-actions';
import ContactSelectStore from './contact-select-store';
import {MessagesContext} from '../../messages/messages-context';

class ContactSelect extends Component {
    static get propTypes() {
        return {
            attr: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            label: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
            model: PropTypes.object.isRequired,
            placeholder: PropTypes.string,
            required: PropTypes.bool,
            setter: PropTypes.func.isRequired,
            showNotListed: PropTypes.bool,
            valueKey: PropTypes.string,
        };
    }

    static calculateState() {
        return {
            isLoading: ContactSelectStore.getState().get('isLoading'),
            options: ContactSelectStore.getState().get('options'),
        };
    }

    static getStores() {
        return [ContactSelectStore];
    }

    static get defaultProps() {
        return {
            disabled: false,
            label: '',
            placeholder: undefined,
            required: false,
            showNotListed: false,
            valueKey: 'id'
        };
    }

    constructor(props) {
        super(props);
        this.state = Object.assign({
            value: this.props.model.get(this.props.attr),
        }, this.constructor.calculateState());

        this.getOptionValue = this.getOptionValue.bind(this);
        this.filterOptions = this.filterOptions.bind(this);
        this.onChange = this.onChange.bind(this);
        this.optionRenderer = this.optionRenderer.bind(this);
    }

    componentDidMount() {
        ContactSelectActions.get(0, 9999);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({value: nextProps.model.get(nextProps.attr)});
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.isLoading !== nextState.isLoading ||
            this.state.options !== nextState.options ||
            this.state.value !== nextState.value) {
            return true;
        }
        return false;
    }

    static contextType = MessagesContext;

    getOptionValue(option) {
        if (!option) {return '';}

        return `${option.firstName.trim()} ${option.lastName.trim()}`;
    }

    filterOptions(options, filter, currentValues) {
        let filtered = [];
        let curr = [];

        if (currentValues) {
            curr = currentValues.map((current) => {
                return this.getOptionValue(current).toLowerCase();
            });
        }

        let hasFilter = filter && filter.length > 0;
        options.forEach(option => {
            let optionValue = this.getOptionValue(option).toLowerCase();
            if (curr.indexOf(optionValue) !== -1) {
                return;
            }

            if (hasFilter && !optionValue.match(filter.toLowerCase())) {
                return;
            }

            filtered.push(option);
            return;
        });

        return filtered;
    }

    onChange(newValue) {
        if (Array.isArray(newValue) && !newValue.length) {
            newValue = undefined;
        }
        this.props.setter(this.props.attr, Immutable.fromJS(newValue));
    }

    optionRenderer(option) {
        let middleName = '';
        if (option.middleName) {
            middleName = `${option.middleName} `;
        }
        return `${option.firstName} ${middleName}${option.lastName}`;
    }

    render() {

        let label = this.props.label;
        if (this.props.required) {
            label = (
                <span>
                    {this.props.label}&nbsp;<span className="text-red">*</span>
                </span>
            );
        }
        label = <ControlLabel>{label}</ControlLabel>;

        // This is done here because this.context isn't available during
        // getDefaultProps().
        let placeholder = this.context.intl.messages['common.form.select.placeholder'];
        if (this.props.placeholder) {
            placeholder = this.props.placeholder;
        }

        let value = '';
        let modelValue = this.state.value;
        if (modelValue !== null && modelValue !== undefined) {
            value = modelValue.toJS();
        }

        let options = this.state.options.toJS();
        if (this.props.showNotListed) {
            const notListed = {
                company: '',
                firstName: 'Not',
                lastName: 'Listed',
                middleName: '',
                id: '-1',
            };
            options.unshift(notListed);
        }

        return (
            <div style={{marginBottom: '20px'}}>
                {label}
                <Select
                    disabled={this.props.disabled}
                    filterOptions={this.filterOptions}
                    getOptionLabel={/*istanbul ignore next */data => this.optionRenderer(data)}
                    getOptionValue={/*istanbul ignore next */data => data.id}
                    isLoading={this.state.isLoading}
                    onChange={this.onChange}
                    options={options}
                    placeholder={placeholder}
                    value={value}
                    valueKey={this.props.valueKey}
                    valueRenderer={this.optionRenderer}
                />
            </div>
        );
    }
}

export default Container.create(ContactSelect);
export {ContactSelect};
