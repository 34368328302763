/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Row} from 'react-bootstrap';

import {PreauthenticatedActions} from './preauthenticated-actions';
import PreauthenticatedStore from './preauthenticated-store';
import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import Footer from '../layout/footer';
import Header from '../layout/header';
import {Alert} from '../notification/alert';
import Player from '../player';
import Preloader from '../preloader';

class Preauthenticated extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [PreauthenticatedStore];
    }

    static calculateState() {
        return {
            shared: PreauthenticatedStore.getState().get('shared')
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidMount() {
        PreauthenticatedActions.load(this.props.match.params.token);
        document.querySelector('body').classList.add('dark-bg');
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('dark-bg');
    }

    render() {
        if (!this.state.shared) {
            return (<Preloader fixed className="text-primary" />);
        }
        let username = this.state.shared.get('userName') || this.state.shared.get('emailAddress') || this.state.shared.get('createdBy');
        let content;
        if (this.state.shared.get('assetType') === AssetTypeConstants.ASSET_TYPE.VIDEO) {
            // video
            content = <div className="col-sm-12 share-player-wrapper">
                <Player
                    playerOptions={{
                        autostart: true
                    }}
                    preauthenticated
                    preauthenticatedUser={username}
                    titleId={this.state.shared.get('titleId')}
                    video={this.state.shared.get('sharedVideo')}
                />
                <div className="homepage-video-description">
                    <h1>
                        <strong id="homepage-video-title">{this.state.shared.getIn(['sharedVideo', 'assetComment'])}</strong>&nbsp;
                        <span id="homepage-video-name">{this.state.shared.getIn(['sharedVideo', 'assetName'])}</span>&nbsp;
                        <small id="homepage-video-runtime">{this.state.shared.getIn(['sharedVideo', 'runtime'])}</small>
                    </h1>
                </div>
            </div>;

        } else {
            // image
            content = <div className="col-sm-12 share-player-wrapper">
                <div className="text-center">
                    <div className="inline-block text-left">
                        <img src={this.state.shared.getIn(['sharedImage', 'previewUrl'])} className="share-image"/>
                        <div className="image-share-text">
                            <span className="image-share-description">
                                <h3 className="margin-all-0 padding-all-15 pull-right">
                                    <a href={this.state.shared.getIn(['sharedImage', 'sourceUrl'])} download=""><span className="glyphicon glyphicon-download-alt"></span></a>
                                </h3>
                                <p>{this.state.shared.getIn(['sharedImage', 'caption']) || this.state.shared.getIn(['sharedImage', 'name']) || ''}</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return (
            <div className="single-footer">
                <Alert fixed className="col-xs-11 col-sm-4" transitionName="fadeDown" />
                <Header location={this.props.location} searchIconHidden preauthenticated userName={username} />
                <div style={{height:'70px'}}>&nbsp;</div>
                <div className="page-content">
                    <div className="share-overlay">
                        <div className="share-overlay-bg">
                            <div className={ClassNames('share-overlay-wrapper', {'share-video-wrapper': this.state.shared.get('assetType') === AssetTypeConstants.ASSET_TYPE.VIDEO})}>
                                <div className="share-overlay-container padding-top-80">
                                    <Row>
                                        {content}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer copyrightOnly/>
            </div>
        );
    }
}

export default Container.create(Preauthenticated);
