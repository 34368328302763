/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Moment from 'moment';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {SavedSearchesActions} from './saved-searches-actions';
import SavedSearchesStore from './saved-searches-store';
import CommonShareModal from '../../../common/modal-share';
import {MessagesContext} from '../../../messages/messages-context';
import Preloader from '../../../preloader';
import SessionStore from '../../../session/session-store';
import {IsNotTriggerKey} from '../../../utils/utils';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/app/dataTables.brainiac.css');

// Load jQuery and register the datatables plugin.
let jQuery = require('jquery');
require('datatables.net-responsive-bs');

class SavedSearches extends React.Component {
    static get propTypes() {
        return {};
    }

    static calculateState() {
        return {
            currentSavedShareItem: {},
            loading: SavedSearchesStore.getState().get('loading'),
            showDeleteSavedShareModal: false,
            searches: SavedSearchesStore.getState().get('searches'),
            showShareSearchModal: false,
            userId: SessionStore.getState().getIn(['authUser', 'id'])
        };
    }

    static getStores() {
        return [SavedSearchesStore];
    }

    constructor(props) {
        super(props);
        this.state = this.constructor.calculateState();
        this.closeModal = this.closeModal.bind(this);
        this.handleRowTrigger = this.handleRowTrigger.bind(this);
        this.deleteSavedSearch = this.deleteSavedSearch.bind(this);
        this.doneShareSearchModal = this.doneShareSearchModal.bind(this);
        return;
    }

    componentDidMount() {
        SavedSearchesActions.getSavedSearches(this.state.userId);

        this.$table = jQuery('#saved-searches-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This column will
                // contain the button to show the responsive data.
                className: 'control',
                targets: -1,
                width: 20
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        this.updateRows(this.state);
        this.handleResize();
        return;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        this.updateRows(nextState);
        return;
    }

    componentWillUnmount() {
        /* istanbul ignore else */
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    static contextType = MessagesContext;

    closeModal(modalName, event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        if (event) {
            event.preventDefault();
        }
        this.setState(() => ({[`show${modalName}Modal`]: false}));
    }

    doneShareSearchModal(email, searchUrl, searchId) {
        SavedSearchesActions.shareSearch(email, this.state.userId, searchId);
        this.setState(() => ({showShareSearchModal: false}));
    }

    deleteSavedSearch(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        SavedSearchesActions.deleteSavedSearch(this.state.userId, this.state.currentSavedShareItem);
        this.setState(() => ({showDeleteSavedShareModal: false}));
    }

    findSavedSearch(savedSearchId) {
        savedSearchId = parseInt(savedSearchId);
        let searchList = this.state.searches;
        if (searchList) {
            return searchList.find((savedSearch) => {
                return savedSearch.get('id') === savedSearchId;
            });
        }
        return {};
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
        return;
    }

    handleRowTrigger(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        if (event.target.className === 'search-url') {return;}

        event.preventDefault();
        let savedSearchId = event.target.id;
        switch (event.target.className) {
        case 'glyphicon glyphicon-transfer':
            this.setState(() => ({
                currentSavedShareItem: {
                    shareId: savedSearchId,
                    shareUrl: this.findSavedSearch(savedSearchId).get('searchUrl')
                },
                showShareSearchModal: true
            }));
            break;
        case 'glyphicon glyphicon-trash':
            this.setState(() => ({
                currentSavedShareItem: {
                    shareId: savedSearchId,
                    searchName: this.findSavedSearch(savedSearchId).get('searchName')
                },
                showDeleteSavedShareModal: true
            }));
            break;
        }
    }

    updateRows(state) {
        if (!state) {return;}

        this.$tableAPI.clear();

        if (state.searches) {
            // Add sorted data to the jQuery datatable.
            state.searches.forEach(data => {

                // Compose buttons
                let shareButton = `<a href="#" class="text-default-color" role="button"><span class="glyphicon glyphicon-transfer" id="${data.get('id')}"></span></a>`;
                let removeButton = `<a href="#" class="text-default-color" role="button"><span class="glyphicon glyphicon-trash" id="${data.get('id')}"></span></a>`;

                let savedDate = Moment(data.get('createdDate'));
                savedDate = savedDate.isValid() ? savedDate.format(this.context.intl.messages['date-format']) : '-';

                let searchName = `<a href="${data.get('searchUrl')}" class="search-url">${data.get('searchName')}</a>`;
                this.$tableAPI.row.add([
                    searchName,
                    savedDate,
                    shareButton,
                    removeButton,
                    ''
                ]);
                return;
            });
        }
        this.$tableAPI.draw(false);
        this.handleResize();
        return;
    }

    render() {
        return (
            <div>
                <Preloader fixed className="text-primary" show={this.state.loading}/>
                <div className="container padding-y-10 padding-x-20" id="hide-this">
                    <div className="row">
                        <h3 className="padding-y-10">
                            <span className="glyphicon glyphicon-search"/>&nbsp;{this.context.intl.messages['account.tab.my-saved-searches.title']}
                        </h3>
                        <table id="saved-searches-table" className="table table-striped responsive">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        {this.context.intl.messages['search.save-search.saved-search-name']}
                                    </th>
                                    <th scope="col">
                                        {this.context.intl.messages['account.tab.my-saved-searches.date-saved']}
                                    </th>
                                    <th className="no-sort icon-column sorting_disabled" rowSpan="1" colSpan="1" scope="col">
                                        <span className="glyphicon glyphicon-transfer"/>
                                    </th>
                                    <th className="no-sort icon-column sorting_disabled" rowSpan="1" colSpan="1" scope="col">
                                        <span className="glyphicon glyphicon-trash"/>
                                    </th>
                                    <th className="no-sort" scope="col"/>
                                </tr>
                            </thead>
                            <tbody onClick={this.handleRowTrigger} onKeyUp={this.handleRowTrigger} tabIndex="0" />
                        </table>
                    </div>
                </div>
                <CommonShareModal
                    close={this.closeModal.bind(this, 'ShareSearch')}
                    done={this.doneShareSearchModal}
                    show={this.state.showShareSearchModal}
                    data={this.state.currentSavedShareItem}
                />
                <Modal show={this.state.showDeleteSavedShareModal} onHide={this.closeModal.bind(this, 'DeleteSavedShare')}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="modal-title text-center">{this.context.intl.messages['cart.view.confirm-remove.dialog.title']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{this.context.intl.messages['account.tab.my-saved-searches.confirm-delete-saved-search.dialog.description']}</p>
                        <strong>{this.state.currentSavedShareItem.searchName}</strong>
                    </Modal.Body>
                    <Modal.Footer className=" padding-all-20">
                        <Button bsStyle="primary" className="pull-right" onClick={this.deleteSavedSearch} onKeyUp={this.deleteSavedSearch}>{this.context.intl.messages['common.delete']}</Button>
                        <Button bsStyle="default" className="btn-secondary margin-right-5 pull-left" onClick={this.closeModal.bind(this, 'DeleteSavedShare')} onKeyUp={this.closeModal.bind(this, 'DeleteSavedShare')}>{this.context.intl.messages['common.close']}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Container.create(SavedSearches);
export {SavedSearches};
