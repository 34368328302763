/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {MessagesContext} from '../../../messages/messages-context';
import {Compare} from '../../../utils/utils';
import {TitleConstants} from '../../title-actions';
import TitleStore from '../../title-store';

class LanguageAvailabilityTable extends React.Component {
    static get propTypes() {
        return {
            languageAvailability: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static contextType = MessagesContext;

    render() {
        if (!this.props.languageAvailability.size) {
            return null;
        }

        // Group by availability date and version code.
        let groupedLA = this.props.languageAvailability.sortBy(
            la => la.get('availableDate')
        ).groupBy(
            la => `${la.get('versionCode')}`
        );

        let textWithTooltips = las => {
            if (!las.size) {
                return '-';
            }

            return las.map((la, i) => (
                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    key={i}
                    title={la.get('languageDesc')}
                >{la.get('languageCode')}</span>
            )).interpose(', ');
        };

        return (
            <div className="row">
                <div className="col-xs-12">
                    <table className="table table-striped" ref={tableElem => {this.$tableElem = jQuery(tableElem);}}>
                        <thead>
                            <tr>
                                <th scope="col">{this.context.intl.messages['title.additional-info.languages.dub']}</th>
                                <th scope="col">{this.context.intl.messages['title.additional-info.languages.subtitle']}</th>
                                <th scope="col">{this.context.intl.messages['title.additional-info.languages.version-code']}</th>
                                <th scope="col" style={{width: '20px'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedLA.map((las, key) => (
                                <tr key={key}>
                                    <td>{textWithTooltips(
                                        las.filter(
                                            la => la.get('elementType') === TitleConstants.TITLE.LANGUAGE_AVAILABILITY.ELEMENT_TYPE.AUDIO
                                        ).sort(/* istanbul ignore next */(a, b) => a.get('languageCode').localeCompare(b.get('languageCode')))
                                    )}</td>
                                    <td>{textWithTooltips(
                                        las.filter(
                                            la => la.get('elementType') === TitleConstants.TITLE.LANGUAGE_AVAILABILITY.ELEMENT_TYPE.SUBTITLE
                                        ).sort(/* istanbul ignore next */(a, b) => a.get('languageCode').localeCompare(b.get('languageCode')))
                                    )}</td>
                                    <td>{las.getIn([0, 'versionCode'])}</td>
                                    <td></td>
                                </tr>
                            )).toList().toJS()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

class LanguageAvailability extends React.Component {
    static get propTypes() {
        return {
            languageAvailability: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static contextType = MessagesContext;

    render() {
        // Hide the complete section if...
        if (!this.props.languageAvailability.size) {
            return null;
        }

        return (
            <div>
                <h3>{this.context.intl.messages['title.additional-info.languages']}</h3>
                <LanguageAvailabilityTable
                    languageAvailability={this.props.languageAvailability}
                />
            </div>
        );
    }
}

export default class AdditionalInfo extends React.Component {
    static get propTypes() {
        return {
            actionType: PropTypes.string.isRequired,
            aspectRatio: PropTypes.instanceOf(Immutable.List).isRequired,
            audios: PropTypes.instanceOf(Immutable.List).isRequired,
            canViewLanguageAvailability: PropTypes.bool,
            canViewLegalLines: PropTypes.bool,
            canViewMasteringInfo: PropTypes.bool,
            languageAvailability: PropTypes.instanceOf(Immutable.List).isRequired,
            languages: PropTypes.instanceOf(Immutable.List).isRequired,
            madeForType: PropTypes.string.isRequired,
            movieColor: PropTypes.string.isRequired,
            networks: PropTypes.instanceOf(Immutable.List).isRequired,
            originalSources: PropTypes.instanceOf(Immutable.List).isRequired,
            season: PropTypes.number,
            sourceMaster: PropTypes.instanceOf(Immutable.List).isRequired,
            standards: PropTypes.instanceOf(Immutable.List).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            canViewLanguageAvailability: false,
            canViewLegalLines: false,
            canViewMasteringInfo: false,
            season: undefined
        };
    }

    shouldComponentUpdate(nextProps) {
        // TODO, check for title attribute used and not everything
        return !Compare(
            this.props,
            nextProps,
            [
                'actionType', 'aspectRatio', 'audios', 'canViewMasteringInfo',
                'title', 'languages', 'madeForType', 'movieColor', 'networks',
                'originalSources', 'season',
                'standards', 'sourceMaster'
            ]
        );
    }

    static contextType = MessagesContext;

    /**
     * Get multiple names from an Immutable.List()
     * @param  {[Immutable List Object]} List [Value list]
     * @return {[type]}      [description]
     */
    getNames(List) {
        return List.map(item => item.get('name')).join(', ');
    }

    getNetwork() {
        let map = new Map();
        this.props.networks.forEach(item => {map.set(item.get('name'), '');});
        return Array.from(map.keys()).join(', ');
    }

    getReason(ratingReasons) {
        let ratingConcat = [];
        ratingConcat = ratingConcat.concat(ratingReasons);
        ratingConcat = ratingConcat.join(', ').replace(/, ([^,]*)$/, ' and $1');
        return ratingConcat;
    }

    render() {
        let title = this.props.title;

        //column 1
        let languages, mpmNumber, presentationCredits, networks, season;
        if (this.props.languages.size) {
            languages = <span><strong>{this.context.intl.messages['title.additional-info.title-info.language']}:</strong> {this.getNames(this.props.languages)}<br /></span>;
        }
        if (title.get('mpmNumber')) {
            mpmNumber = <span><strong>{this.context.intl.messages['common.mpm']}:</strong> {title.get('mpmNumber')}<br /></span>;
        }
        if (title.get('presentationCredits')) {
            presentationCredits = <span><strong>{this.context.intl.messages['title.additional-info.title-info.presentation-credits']}:</strong> {title.get('presentationCredits')}<br /></span>;
        }
        const networksCount = this.props.networks.size;
        if (networksCount) {
            networks = <span><strong><FormattedMessage id="title.additional-info.title-info.network" values={{networksCount}} /></strong>{this.getNetwork()}<br /></span>;
        }
        const seasonNumber = this.props.season;
        if (seasonNumber) {
            season = <span><strong>{this.context.intl.messages['title.additional-info.title-info.seasons']}:</strong> {seasonNumber}<br /></span>;
        }
        const column1 = <div className="col-sm-4">
            <h4>{this.context.intl.messages['title.additional-info.title-info']}</h4>
            <hr />
            <p>
                {mpmNumber}
                {season}
                {languages}
                {networks}
                {presentationCredits}
            </p>
        </div>;

        //column 2
        let actionType, copyrightYear, madeFor, movieColor, programLegalline, promotionalLegalline;
        if (this.props.canViewLegalLines && title.get('programLegalline')) {
            programLegalline = <span><strong>{this.context.intl.messages['title.additional-info.title-info.program-legal']}:</strong><span style={{display: 'block'}} dangerouslySetInnerHTML={{__html: title.get('programLegalline')}}></span></span>;
        }
        if (this.props.canViewLegalLines && title.get('promotionalLegalline')) {
            promotionalLegalline = <span><strong>{this.context.intl.messages['title.additional-info.title-info.promotional-legal']}:</strong><span style={{display: 'block'}} dangerouslySetInnerHTML={{__html: title.get('promotionalLegalline')}}></span></span>;
        }

        let ratingSection = [];
        const mpaaRating = title.get('mpaaRatings') || this.context.intl.messages['title.additional-info.title-info.mpaa-ratings.no-rating'];
        const ratingReason = this.getReason(title.get('ratingReasons'));
        const parentalRating = TitleStore.getParentalRatingType(title.get('parentalRatingId', 0));
        ratingSection.push(<span key="mpaa-ratings"><strong>{this.context.intl.messages['title.additional-info.title-info.mpaa-ratings']}:</strong> {mpaaRating}<br /></span>);
        ratingSection.push(<span key="parental-ratings"><strong>{this.context.intl.messages['title.additional-info.title-info.parental-rating']}:</strong> {parentalRating.get('name')}<br /></span>);
        if (ratingReason) {
            ratingSection.push(<span key="rating-reasons"><strong>{this.context.intl.messages['title.additional-info.title-info.rating-reason']}:</strong> {ratingReason}<br /></span>);
        }

        /* istanbul ignore else */
        if (this.props.actionType) {
            actionType = <span><strong>{this.context.intl.messages['title.additional-info.title-info.action-type']}:</strong> {this.props.actionType}<br /></span>;
        }
        if (title.get('copyrightYear')) {
            copyrightYear = <span><strong>{this.context.intl.messages['title.additional-info.title-info.copyright-year']}:</strong> {title.get('copyrightYear')}<br /></span>;
        }
        /* istanbul ignore else */
        if (this.props.madeForType) {
            madeFor = <span><strong>{this.context.intl.messages['title.additional-info.title-info.made-for']}:</strong> {this.props.madeForType}<br /></span>;
        }
        /* istanbul ignore else */
        if (this.props.movieColor) {
            movieColor = <span><strong>{this.context.intl.messages['title.additional-info.title-info.movie-color']}:</strong> {this.props.movieColor}<br /></span>;
        }
        const column2 = <div className="col-sm-4">
            <h4 className="hidden-xs" aria-hidden="true">&nbsp;</h4>
            <hr className="hidden-xs" />
            <p>
                {madeFor}
                {actionType}
                {copyrightYear}
                {movieColor}
                {programLegalline}
                {promotionalLegalline}
                {ratingSection}
            </p>
        </div>;

        //column 3
        let column3;
        if (this.props.canViewMasteringInfo) {
            let aspectRatio = null;
            if (this.props.aspectRatio.size) {
                aspectRatio = <span><strong>{this.context.intl.messages['title.additional-info.mastering-info.aspect-ratio']}:</strong> {this.props.aspectRatio.getIn([0, 'name'])}<br /></span>;
            }
            let audios = null;
            if (this.props.audios.size) {
                audios = <span><strong>{this.context.intl.messages['title.additional-info.mastering-info.audios']}:</strong> {this.getNames(this.props.audios)}<br /></span>;
            }
            let sourceMedia = null;
            if (this.props.originalSources.size) {
                sourceMedia = <span><strong>{this.context.intl.messages['title.additional-info.mastering-info.original-source-media']}:</strong> {this.getNames(this.props.originalSources)}<br /></span>;
            }
            let standards = null;
            if (this.props.standards.size) {
                standards = <span><strong>{this.context.intl.messages['title.additional-info.mastering-info.standard']}:</strong> {this.getNames(this.props.standards)}<br /></span>;
            }
            let notes = null;
            if (this.props.sourceMaster.size) {
                notes = <span><strong>{this.context.intl.messages['title.additional-info.mastering-info.notes']}:</strong> {this.getNames(this.props.sourceMaster)}<br /></span>;
            }
            column3 = <div className="col-sm-4">
                <h4>{this.context.intl.messages['title.additional-info.mastering-info']}</h4>
                <hr />
                <p>
                    {aspectRatio}
                    {audios}
                    {standards}
                    {sourceMedia}
                    {notes}
                </p>
            </div>;
        }

        return (
            <div className="container padding-all-10">
                <hr />
                <h3>{this.context.intl.messages['title.additional-info.title']}</h3>
                <div className="row">
                    {column1}
                    {column2}
                    {column3}
                </div>
                {this.props.canViewLanguageAvailability &&
                    <LanguageAvailability
                        languageAvailability={this.props.languageAvailability}
                    />
                }
            </div>
        );
    }
}

export {AdditionalInfo, LanguageAvailability, LanguageAvailabilityTable};
