/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Dropdown, Glyphicon, Modal} from 'react-bootstrap';
import RootCloseWrapper from 'react-overlays/RootCloseWrapper';
import {Link} from 'react-router-dom';
import Scroll from 'react-scroll';

import {default as CartItemsLoader} from './cart-items-loader.gif';
import HeaderStore from './header-store';
import Config from '../../config/config';
import {HomeActions} from '../../home/home-actions';
import LanguageOptions from '../../messages/language-options';
import {MessagesContext} from '../../messages/messages-context';
import {OrderActions} from '../../orders/order-actions.js';
import OrderStore from '../../orders/order-store';
import {SessionActions, SessionConstants} from '../../session/session-actions';
import SessionStore from '../../session/session-store';
import SerieNavigationStore from '../../titles/serie-navigation/serie-navigation-store';
import TitleStore from '../../titles/title-store';
import {IsNotTriggerKey} from '../../utils/utils';
import CustomDropdown from '../dropdown/';
import HappyFoxModal from '../happy-fox-modal';
import {PageLayoutActions} from '../page-layout/page-layout-actions';
import PageLayoutStore from '../page-layout/page-layout-store';

import './header.less';

const noop = () => void 0;

/**
 * Navigation items
 * @return {JSX Component} Nav items
 */
class HeaderNavItems extends React.Component {
    static get propTypes() {
        return {
            isMenuOpen: PropTypes.bool,
            location: PropTypes.object.isRequired,
            onMenuOpen: PropTypes.func,
            preauthenticated: PropTypes.bool,
            searchIconHidden: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            isMenuOpen: false,
            preauthenticated: false,
            onMenuOpen: noop,
            searchIconHidden: false
        };
    }

    static calculateState() {
        return {
            itemsInCart: OrderStore.getState().get('itemsInCart'),
            navigationItems: HeaderStore.getState().get('navigationItems'),
            parentUser: SessionStore.getState().get('parentUser'),
            searchBar: PageLayoutStore.getState().get('searchBar'),
            serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
            title: TitleStore.getState().get('title'),
            user: SessionStore.getState().get('authUser')
        };
    }

    static getStores() {
        return [SessionStore, PageLayoutStore, OrderStore, SerieNavigationStore, TitleStore, HeaderStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            isLanguageModalOpen: false,
            isMenuOpen: false,
            showAssetRequestsModal: false,
        }, this.constructor.calculateState());

        this.closeMenu = this.closeMenu.bind(this);
        this.handleLogoutKeyUp = this.handleLogoutKeyUp.bind(this);
        this.handleNavMenuClicks = this.handleNavMenuClicks.bind(this);
        this.handleToggleLanguageModal = this.handleToggleLanguageModal.bind(this);
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.isActive = this.isActive.bind(this);
        this.toggleAssetRequestsModal = this.toggleAssetRequestsModal.bind(this);
        this.toggleSearchBar = this.toggleSearchBar.bind(this);
        this.toggleSearchBarKeyUp = this.toggleSearchBarKeyUp.bind(this);
    }

    componentDidMount() {
        if (!this.props.preauthenticated && SessionStore.isSessionValid()) {

            if (!this.state.navigationItems.size) {
                // Prevents empty nav if we are not on the home page and the user refreshes
                HomeActions.getHomepage();
            }

            OrderActions.getCartItemsAmount();
        }
    }

    static contextType = MessagesContext;

    closeMenu(isUserMenuLink) {
        if (isUserMenuLink === true) {
            this.props.onMenuOpen(false);
        }
        this.setState({
            isMenuOpen: false
        });
    }

    handleNavMenuClicks() {
        if (this.props.isMenuOpen) {
            this.props.onMenuOpen(this.props.isMenuOpen);
        }
    }

    handleToggleLanguageModal() {
        this.setState(prevState => ({
            isLanguageModalOpen: !prevState.isLanguageModalOpen
        }));
        return;
    }

    handleToggleMenu(isOpen) {
        this.setState({
            isMenuOpen: isOpen
        });
    }

    isActive(path) {
        if (this.props.location.pathname === path) {
            return 'active';
        }
    }

    toggleSearchBar() {
        if (this.state.searchBar.get('fixed')) {
            PageLayoutActions.hideSearchBar();
        } else {
            PageLayoutActions.showSearchBar();
        }
    }

    toggleSearchBarKeyUp(ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        this.toggleSearchBar();
    }

    handleLogoutKeyUp(ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        SessionActions.logout();
    }

    toggleAssetRequestsModal(show) {
        this.handleNavMenuClicks();
        this.setState(() => ({
            showAssetRequestsModal: show
        }));
    }

    toggleAssetRequestsModalKeyUp(show, ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        this.toggleAssetRequestsModal(show);
    }

    hasBrowseOrdersPermission() {
        return SessionStore.canUser(SessionStore.PERMISSIONS.ORDERS.BROWSE);
    }

    hasHappyFoxPermission() {
        return SessionStore.canUser(SessionStore.PERMISSIONS.HAPPYFOX.REQUESTS.SUBMIT);
    }

    hasViewCartPermission() {
        return SessionStore.canUser(SessionStore.PERMISSIONS.VIEW.CART);
    }

    render() {
        if (!SessionStore.isSessionValid() || SessionStore.canUser(SessionStore.PERMISSIONS.RESTRICTED.HOMEPAGEONLY)) {
            return null;
        }

        let itemCount = this.state.itemsInCart;
        if (itemCount === undefined) {
            itemCount = 'loading...';
        }
        let cart;
        if (this.hasViewCartPermission()) {
            cart = (
                <li>
                    <Link
                        onClick={this.closeMenu.bind(this, true)}
                        to="/cart"
                    >{this.context.intl.messages['header.navbar.dropdown.item.cart']}&nbsp;({itemCount})</Link>
                </li>
            );
            separator = <li role="separator" className="divider"/>;
        }
        let order;
        let separator;
        if (this.hasBrowseOrdersPermission()) {
            order = (
                <li>
                    <Link
                        onClick={this.closeMenu.bind(this, true)}
                        to="/orders"
                    >{this.context.intl.messages['header.navbar.dropdown.item.orders']}</Link>
                </li>
            );
            separator = <li role="separator" className="divider"/>;
        }

        // HappyFox "Asset Requests" modal
        let happyFoxRequest;
        if (this.hasHappyFoxPermission() && !SessionStore.isContentNotAvailable()) {
            happyFoxRequest = (
                <li className="header-nav-link">
                    <a href="#" className="request-button" onClick={this.toggleAssetRequestsModal.bind(this, true)} onKeyUp={this.toggleAssetRequestsModalKeyUp.bind(this, true)}>
                        <span className="header-nav-requests">{this.context.intl.messages['header.navbar.item.requests']}</span>
                    </a>
                </li>
            );
        }

        // Partner Links
        let navLinks;
        if (!SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle() && !SessionStore.isContentNotAvailable()) {
            // Include this.state.links in conditional to prevent blank page after login when partner not configured above in PARTNER_LINKS
            if (this.state.navigationItems.size) {
                navLinks = this.state.navigationItems.map((li, i) => {
                    return (
                        <li key={i} className="header-nav-link">
                            <Link
                                className={this.isActive(li.get('targetUrl'))}
                                onClick={this.handleNavMenuClicks}
                                to={li.get('targetUrl').trim()}
                            >{li.get('displayName')}</Link>
                        </li>
                    );
                });
            }
        }

        let userMenuLabel = `User Menu: ${this.state.user.get('fullName', '')} logged in. ${itemCount} items in the cart.`;
        let userFullName = this.state.user.get('fullName', '');
        if (this.state.user.get('accountType') === SessionConstants.USER_TYPES.SECONDARY.id) {
            userFullName = `${this.state.parentUser.get('name')} ${this.state.parentUser.get('lastName')}`;
        }

        let cartItemsLabel = <span className="label user-count">
            <img className="cart-items-loader" alt="loading..." role="presentation" src={CartItemsLoader}/>
        </span>;
        if (this.state.itemsInCart !== undefined) {
            cartItemsLabel = <span className="label bg-wb-blue user-count">{itemCount}</span>;
        }

        return (
            <div className={ClassNames('header-item header-actions', {'hide-navbar': !this.props.isMenuOpen})}>
                <nav id="navbar" className="navbar-collapse collapse">
                    <ul className="nav navbar-nav navbar-right">
                        <li className={ClassNames('header-nav-link header-search-icon', {'icon-hidden': this.props.searchIconHidden})}>
                            <Glyphicon glyph="search" className="search-icon" onClick={this.toggleSearchBar} onKeyUp={this.toggleSearchBarKeyUp}/>
                        </li>
                        <li className="divider"/>
                        {navLinks}
                        {happyFoxRequest}
                        <li className="divider"/>

                        <RootCloseWrapper onRootClose={this.closeMenu}>
                            <Dropdown id="nav-menu" componentClass="li" pullRight onToggle={this.handleToggleMenu} open={this.state.isMenuOpen} aria-label={userMenuLabel}>
                                <CustomDropdown.Toggle bsRole="toggle" onClick={noop}>
                                    {userFullName}&nbsp;
                                    {cartItemsLabel}
                                    <span className="caret"/>
                                </CustomDropdown.Toggle>
                                <CustomDropdown.Menu bsRole="menu">
                                    {cart}
                                    {separator}
                                    {order}
                                    {separator}
                                    {SessionStore.canUser(SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.INFO.SUBSCRIPTIONS) && <li>
                                        <Link
                                            className="nav-menu-link"
                                            onClick={this.closeMenu.bind(this, true)}
                                            to="/account/subscriptions"
                                        >{this.context.intl.messages['header.navbar.dropdown.item.subscriptions']}</Link>
                                    </li>}
                                    {SessionStore.canUser(SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.INFO.SUBSCRIPTIONS) && <li role="separator" className="divider"/>}
                                    {SessionStore.canUser(SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.INFO.ACCOUNT_INFO) && <li>
                                        <Link
                                            className="nav-menu-link"
                                            onClick={this.closeMenu.bind(this, true)}
                                            to="/account"
                                        >{this.context.intl.messages['header.navbar.dropdown.item.account']}</Link>
                                    </li>}
                                    {SessionStore.canUser(SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.INFO.ACCOUNT_INFO) && <li role="separator" className="divider"/>}
                                    <li>
                                        <a href="#" onClick={this.handleToggleLanguageModal}>{this.context.intl.messages['common.change-language']}</a>
                                    </li>
                                    <li role="separator" className="divider"/>
                                    <li>
                                        <a href="#" onClick={SessionActions.logout} onKeyUp={this.handleLogoutKeyUp}>{this.context.intl.messages['header.navbar.dropdown.item.logout']}</a>
                                    </li>
                                </CustomDropdown.Menu>
                            </Dropdown>
                            <Modal show={this.state.isLanguageModalOpen} onHide={this.handleToggleLanguageModal} bsSize="small" className="change-language-modal">
                                <Modal.Header className="bg-gray" closeButton>
                                    <Modal.Title className="modal-title text-center">{this.context.intl.messages['common.change-language']}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="text-center">
                                    <LanguageOptions showAs="header" className="btn-sm text-center" variant="secondary btn-sm" />
                                </Modal.Body>
                            </Modal>
                        </RootCloseWrapper>
                    </ul>
                </nav>
                <HappyFoxModal
                    showAssetRequestsModal={this.state.showAssetRequestsModal}
                    onToggleModal={this.toggleAssetRequestsModal}
                />
            </div>
        );
    }
}

class PreauthenticatedHeaderNavItems extends React.Component {
    static get propTypes() {
        return {
            userName: PropTypes.string.isRequired
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };

        this.closeMenu = this.closeMenu.bind(this);
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
    }

    static contextType = MessagesContext;

    closeMenu() {
        this.setState({
            isMenuOpen: false
        });
    }

    handleToggleMenu(isOpen) {
        this.setState({
            isMenuOpen: isOpen
        });
    }

    render() {
        let menuLink = (
            <li>
                <Link to="/login">{this.context.intl.messages['header.navbar.dropdown.item.login']}</Link>
            </li>
        );

        if (SessionStore.isSessionValid()) {
            menuLink = (
                <li>
                    <Link to="/">{this.context.intl.messages['header.navbar.dropdown.item.home']}</Link>
                </li>
            );
        }

        return (
            <div id="navbar" className="navbar-collapse collapse">
                <ul className="nav navbar-nav navbar-right">
                    <RootCloseWrapper onRootClose={this.closeMenu}>
                        <Dropdown id="nav-menu" componentClass="li" pullRight onToggle={this.handleToggleMenu} open={this.state.isMenuOpen}>
                            <CustomDropdown.Toggle bsRole="toggle">
                                {this.props.userName}&nbsp;
                                <span className="caret"/>
                            </CustomDropdown.Toggle>
                            <CustomDropdown.Menu bsRole="menu">
                                {menuLink}
                            </CustomDropdown.Menu>
                        </Dropdown>
                    </RootCloseWrapper>
                </ul>
            </div>
        );
    }
}

/**
 * Header Component
 * @return {JSX Component} Page header with navigation
 */
export default class Header extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            partnerId: PropTypes.string.isRequired,
            preauthenticated: PropTypes.bool,
            searchIconHidden: PropTypes.bool.isRequired,
            userName: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            preauthenticated: false,
            userName: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isBrandsMenuOpen: false,
            isMenuOpen: false,
        };

        this.handleCloseBrandsMenu = this.handleCloseBrandsMenu.bind(this);
        this.handleLogoutKeyUp = this.handleLogoutKeyUp.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleToggleBrandsMenu = this.handleToggleBrandsMenu.bind(this);
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.redirectToMultiBrandHub = this.redirectToMultiBrandHub.bind(this);
    }

    static contextType = MessagesContext;

    handleLogoutKeyUp(ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        SessionActions.logout();
    }

    handleCloseBrandsMenu() {
        this.setState(() => ({
            isBrandsMenuOpen: false
        }));
    }

    handleNavigateToPartner(partner) {
        const auth = SessionStore.getAuthorization();
        if (partner.isBrand) {
            const currentBrand = SessionStore.getCurrentBrand();
            if (currentBrand && currentBrand.get('id') === partner.id) {
                SessionActions.deleteUserSessionBrand(auth);
            } else {
                SessionActions.updateUserSessionBrand(partner, auth);
            }
        } else if (this.props.partnerId === partner.id) {
            SessionActions.deleteUserSessionBrand(auth);
        } else {
            SessionActions.navigateToPartner(partner, auth);
        }
    }

    handleNavigateToPartnerKeyUp(partner, ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        this.handleNavigateToPartner(partner);
    }

    handleSearchClick() {
        this.handleToggleMenu(false);
        Scroll.animateScroll.scrollToTop();
    }

    handleToggleBrandsMenu(isOpen) {
        this.setState(() => ({
            isBrandsMenuOpen: isOpen
        }));
    }

    handleToggleBrandsMenuKeyUp(isOpen, ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        this.handleToggleBrandsMenu(isOpen);
    }

    handleToggleMenu(isOpen) {
        this.setState(() => ({
            isMenuOpen: isOpen
        }));
    }

    redirectToMultiBrandHub() {
        window.location.replace('/ts/brands-hub');
    }

    render() {
        let header;
        const PARTNER = SessionStore.getPartner();
        let partnerLogo = `/${PARTNER.logo}`;
        if (PARTNER.isBrandActive || SessionStore.isBrandsUser()) {
            partnerLogo = `/${PARTNER.brandLogo}`;
        }
        if (SessionStore.isACSUser() && PARTNER.acsLogo) {
            partnerLogo = `/${PARTNER.acsLogo}`;
        }
        // BRAIN-4625: Display airlinesLogo
        if (PARTNER.id === Config.Partners.HBO.id) {
            partnerLogo = `/${PARTNER.airlinesLogo}`;
        }
        if (PARTNER.id === Config.Partners.HBO_MAX_PORTAL.id) {
            partnerLogo = `/${PARTNER.airlinesLogo}`;
        }

        let searchIconHidden = this.props.searchIconHidden;

        switch (true) {
        case Config.Partners.PRESS_SITE.id === this.props.partnerId:
        case SessionStore.isContentNotAvailable():
            searchIconHidden = true;
            break;
        }

        if (!SessionStore.canUser(SessionStore.PERMISSIONS.RESTRICTED.HOMEPAGEONLY)) {
            const userPartners = SessionStore.getUserPartners();
            let filteredPartners = [];
            if (userPartners) {
                filteredPartners = userPartners.map(p => {
                    const clientId = p.get('clientId');
                    return Config.Partners[clientId];
                }).toJS();
            }

            const userBrands = Immutable.List(SessionStore.getUserBrands());
            userBrands.forEach(brand => {
                filteredPartners.push({
                    baseUrl: '', // Note: This will prevent any userBrand from appearing in the waffle menu, the baseUrl is not used for userBrands
                    description: brand.get('description'),
                    id: brand.get('id'),
                    key: '',
                    loginBaseUrl: '',
                    logo: brand.get('logoSourceUrl'),
                    isBrand: true,
                });
            });

            // BRAIN-4101: prevent partners without baseUrl or logo from being displayed
            filteredPartners = filteredPartners.filter(partner => partner?.baseUrl && partner?.logo);

            let brandsMenu;
            if (SessionStore.canUser(SessionStore.PERMISSIONS.HEADER.BRANDS_MENU) && filteredPartners.length > 1) {
                brandsMenu = (
                    <RootCloseWrapper onRootClose={this.handleCloseBrandsMenu}>
                        <Dropdown
                            id="brands-nav-menu"
                            className="brands-nav-menu header-item"
                            componentClass="div"
                            onToggle={this.handleToggleBrandsMenu}
                            open={this.state.isBrandsMenuOpen}
                        >
                            <CustomDropdown.Toggle bsRole="toggle" className="brands-nav-menu-toggle">
                                <span className="brand-nav-icon" aria-label={this.context.intl.messages['header.brands.toggle.nav']}>
                                    <i className="glyphicon glyphicon-th"/>
                                </span>
                            </CustomDropdown.Toggle>
                            <CustomDropdown.Menu bsRole="menu">
                                <h4>{this.context.intl.messages['header.brands.dropdown.select']}&nbsp;
                                    <small className="pull-right">
                                        <a
                                            role="button"
                                            tabIndex="0"
                                            onClick={this.handleToggleBrandsMenu.bind(this, false)}
                                            onKeyUp={this.handleToggleBrandsMenuKeyUp.bind(this, false)}
                                        >
                                            {this.context.intl.messages['common.close'].toUpperCase()}
                                        </a>
                                    </small>
                                </h4>
                                {Object.keys(filteredPartners).filter(k => filteredPartners[k] && !!filteredPartners[k].logo).map((k, i) => {
                                    const logo = filteredPartners[k].logo;
                                    let logoUrl = `url(/${logo})`;
                                    let active = false;
                                    if (filteredPartners[k].isBrand) {
                                        if (PARTNER.isBrandActive) {
                                            active = PARTNER.brandId === filteredPartners[k].id;
                                        }
                                    } else {
                                        if (!PARTNER.isBrandActive) {
                                            active = PARTNER.id === filteredPartners[k].id;
                                        }
                                    }

                                    return (
                                        <li className="brand-logo" key={`partner-${i}`}>
                                            <span
                                                role="button"
                                                className={ClassNames('brand-image-container', {active})}
                                                onClick={this.handleNavigateToPartner.bind(this, filteredPartners[k])}
                                                onKeyUp={this.handleNavigateToPartnerKeyUp.bind(this, filteredPartners[k])}
                                                tabIndex="0"
                                            >
                                                <div style={{backgroundImage: logoUrl}}/>
                                            </span>
                                        </li>
                                    );
                                })}
                                <li className="brand-logo" style={{verticalAlign: 'Top', color: 'White', fontSize: '20px'}}>
                                    <div
                                        role="button"
                                        className={'brand-image-container'}
                                        style={{display: 'flex', alignItems: 'center'}}
                                        onClick={this.redirectToMultiBrandHub}
                                        onKeyUp={this.redirectToMultiBrandHub}
                                        tabIndex="0"
                                    >
                                        <i className="fas fa-plus fa-1x"></i>&nbsp;{this.context.intl.messages['home.section.list.view-all']}
                                    </div>
                                </li>
                            </CustomDropdown.Menu>
                        </Dropdown>
                    </RootCloseWrapper>
                );
            }
            let headerItems = (
                <HeaderNavItemsContainer
                    isMenuOpen={this.state.isMenuOpen}
                    location={this.props.location}
                    onMenuOpen={this.handleToggleMenu.bind(this, false)}
                    partnerId={this.props.partnerId}
                    searchIconHidden={searchIconHidden}
                />
            );

            if (this.props.preauthenticated) {
                headerItems = <PreauthenticatedHeaderNavItems location={this.props.location} userName={this.props.userName} />;
            }
            let collapsedSearch;
            if (!SessionStore.isContentNotAvailable()) {
                collapsedSearch = (
                    <button type="button" className="collapsed-search" onClick={this.handleSearchClick}>
                        <Glyphicon glyph="search" className="search-icon"/>
                    </button>
                );
            }

            header = (
                <>
                    {brandsMenu}
                    <h1>
                        <Link className="header-item brand-logo" to="/" onClick={() => PageLayoutActions.changeSearchBarDropdown('all')}>
                            <img
                                alt={`${this.context.intl.messages['login.brand.name']} - ${this.context.intl.messages['header.logo']}`}
                                src={partnerLogo}
                            />
                        </Link>
                    </h1>
                    <Button
                        aria-controls="navbar"
                        aria-expanded="false"
                        className="header-item navbar-toggle pull-right collapsed"
                        data-target="#navbar"
                        data-toggle="collapse"
                        id="navbar-header-button"
                        onClick={this.handleToggleMenu.bind(this, !this.state.isMenuOpen)}
                        type="button"
                    >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar bg-white"/>
                        <span className="icon-bar bg-white"/>
                        <span className="icon-bar bg-white"/>
                    </Button>
                    {collapsedSearch}
                    {headerItems}
                </>
            );
        } else {
            let user = SessionStore.getState().getIn(['authUser', 'name'], 'User');
            header = (
                <div className="pull-right-sm-up text-center padding-x-10">
                    Welcome, {user}!
                    <a role="button" tabIndex="0" onClick={SessionActions.logout} onKeyUp={this.handleLogoutKeyUp} className="btn btn-sm btn-secondary margin-left-10">Log Out <Glyphicon glyph="log-out"/></a>
                </div>
            );
        }
        let partnerClassName;
        if (this.props.partnerId) {
            partnerClassName = `${this.props.partnerId.toLowerCase()}-header`;
        }
        return (
            <header id="layout-header" className={ClassNames('header-scroll', partnerClassName)}>
                {header}
            </header>
        );
    }
}

const HeaderNavItemsContainer = Container.create(HeaderNavItems);
export {
    HeaderNavItemsContainer as HeaderNavItems,
    HeaderNavItems as HeaderNavItemsRaw
};
