/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col, Grid, Glyphicon, ResponsiveEmbed, Row} from 'react-bootstrap';

import {MessagesContext} from '../../../messages/messages-context';
import {PlayerActions} from '../../../player/player-actions';
import ImageLoader, {ImageLoaderPlaceholders} from '../../../titles/asset-title/images/image-loader';
import {GetThumbnail} from '../../../utils/utils';
import {HomeConstants, HomeActions} from '../../home-actions';


import './small-billboard.less';

export default class SmallBillboard extends Component {

    static get propTypes() {
        return {
            publishingList: PropTypes.object.isRequired,
            videos: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static contextType = MessagesContext;

    handleAction(item, event) {
        event.preventDefault();
        if (item.get('targetType') === HomeConstants.TARGET_TYPE.ASSET.id && item.get('displayAssetTypeName') === 'Video') {
            const video = this.props.videos.get(item.get('assetId'));
            PlayerActions.showPlayer(video);
        } else {
            HomeActions.gotoItem(item);
        }
    }

    render() {
        let items = this.props.publishingList.get('items').slice(0, 3);

        if (items.size === 0) {
            return null;
        }

        let viewAll;
        if (this.props.publishingList.get('summaryLinkURL')) {
            let caption = this.props.publishingList.get('summaryLinkName', this.context.intl.messages['home.section.list.view-all']);
            viewAll = (
                <a href={this.props.publishingList.get('summaryLinkURL')} className="btn btn-sm btn-primary">
                    {caption} <Glyphicon glyph="chevron-right" />
                </a>);
        }

        return (
            <Grid className="small-billboard padding-all-20">
                <h2 className="padding-all-15 margin-all-0 h3">
                    {this.props.publishingList.get('displayName')}
                    {viewAll}
                </h2>
                <div className="row video-list col-xs-12">
                    <Row>
                        {items.map((item, i) => {
                            const video = this.props.videos.get(item.get('assetId'), Immutable.Map());
                            let src;
                            // 900px because it is max size that thumbnails will have in home view/videos section
                            let thumbnail = GetThumbnail(video.get('thumbnails'), 900);
                            if (thumbnail) {
                                src = thumbnail.get('thumbnailUrl');
                            }

                            let href;
                            let handler;
                            if (item.get('targetUrl')) {
                                href=item.get('targetUrl');
                            } else {
                                handler=this.handleAction.bind(this, item);
                            }

                            return (
                                <Col md={4} className="video-box" key={i}>
                                    <div className="padding-all-10 margin-bottom-10">
                                        <a
                                            className="video-thumbnail"
                                            href={href}
                                            onClick={handler}
                                            onKeyPress={handler}
                                            rel="noopener noreferrer"
                                            role="button"
                                            tabIndex="0"
                                            target="_blank"
                                        >
                                            <Glyphicon glyph="play-circle" className="thumbnail-play-icon" />
                                            <ResponsiveEmbed a16by9>
                                                <ImageLoader
                                                    alt={item.get('displayName')}
                                                    className="img-responsive"
                                                    placeholder={ImageLoaderPlaceholders.HORIZONTAL}
                                                    src={src}
                                                />
                                            </ResponsiveEmbed>
                                        </a>
                                        <p className="h4">
                                            <span dangerouslySetInnerHTML={{__html: item.get('description')}} />
                                        </p>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </Grid>
        );
    }
}
