/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';

export default function ExternalLinkButton({link}) {
    const linkTargetURL = link.get('linkTargetURL');
    const label = link.get('name') || linkTargetURL;
    return (
        <Button
            bsSize="lg"
            className="btn-secondary margin-right-10 margin-bottom-10"
            href={linkTargetURL}
            rel="noopener noreferrer"
            target="_blank"
        >
            {label}&nbsp;<Glyphicon glyph="new-window"/>
        </Button>
    );
}

ExternalLinkButton.propTypes = {
    link: PropTypes.instanceOf(Immutable.Map).isRequired,
};

