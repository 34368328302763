/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';

import DropdownOption from './dropdown-option';
import SelectedOption from './selected-option';
import omit from '../../utils/omit';
import FacetSelect from '../facet-select';

const TalentCharacterFacetSelect = (props) => {
    const selectComponents = useMemo(() => ({
        Option: DropdownOption,
        MultiValueLabel: SelectedOption,
    }), []);

    return <FacetSelect
        components={selectComponents}
        name="combined-talent-character"
        {...props}
    />;
};

TalentCharacterFacetSelect.propTypes = {
    ...FacetSelect.propTypes,
    options: PropTypes.instanceOf(Immutable.List).isRequired,
};

TalentCharacterFacetSelect.defaultProps = omit(FacetSelect.defaultProps, ['options']);

export default memo(TalentCharacterFacetSelect);
