/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Link} from 'react-router-dom';

import {MessagesContext} from '../messages/messages-context';
import ImageLoader from '../titles/asset-title/images/image-loader';


export default class NotFound extends React.Component {

    static contextType = MessagesContext;

    render() {
        return (
            <div>
                <div className="row title-header">
                    <div className="title-header-text text-center">
                        <h1><strong>{this.context.intl.messages['errors.not-found.title']}</strong></h1>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                            <div className="padding-y-20">
                                <ImageLoader
                                    alt="..."
                                    className="img-responsive"
                                    src={require('./404.svg')}
                                />
                            </div>
                            <div className="text-center padding-y-10">
                                <p>{this.context.intl.messages['errors.not-found.body']}<br/>
                                    {this.context.intl.messages['errors.not-found.return-to']} <Link to="/">{this.context.intl.messages['errors.not-found.homepage']}</Link>.</p>
                            </div>
                        </div>
                        <div className="col-sm-4"></div>
                    </div>
                </div>
            </div>
        );
    }
}
