/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';

import style from './matches.module.less';

const TYPE_2_CLASS_NAME = {
    tag: 'far fa-tag',
    setting: 'far fa-map-marker-alt',
    character: 'far fa-user',
    dialogue: 'far fa-comment-alt',
};

const GenericMatchItem = ({text, type, title, matchItemRef}) => {
    return (
        <span className={style.label} title={title} ref={matchItemRef}>
            <i className={TYPE_2_CLASS_NAME[type]} /> {text}
        </span>
    );
};

GenericMatchItem.propTypes = {
    matchItemRef: PropTypes.shape({current: PropTypes.elementType}),
    text: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
};

GenericMatchItem.defaultProps = {
    matchItemRef: {current: null},
    title: null,
};

export default GenericMatchItem;
