/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';

import ListOrders from './list-orders';
import DocumentTitle from '../../common/document-title';
import {MessagesContext} from '../../messages/messages-context';
import {RouterActions} from '../../router/router-actions';
import {Compare, Debounce} from '../../utils/utils';
import {OrderActions} from '../order-actions';
import {OrderConstants} from '../order-constants';
import OrderStore from '../order-store';
import StatusChangeModal from '../status-change-modal';
import UnsubscribeClientsModal from '../unsubscribe-clients-modal';

class Subscriptions extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            orders: OrderStore.getState().get('orders'),
            selectedClients: OrderStore.getState().get('selectedClients'),
            statusChange: OrderStore.getState().get('statusChange'),
            titles: OrderStore.getState().get('titles'),
            totalCount: OrderStore.getState().get('totalCount'),
            unsubscribeClients: OrderStore.getState().get('unsubscribeClients'),
            users: OrderStore.getState().get('users')
        };
    }

    static getStores() {
        return [OrderStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.hideStatusChange = this.hideStatusChange.bind(this);
        this.hideUnsubscribeClients = this.hideUnsubscribeClients.bind(this);
        this.search = Debounce(this.search.bind(this), 400);
    }

    componentDidMount() {
        const q = QueryString.parse(this.props.location.search);
        setTimeout(() => {
            OrderActions.get(
                OrderConstants.ORDER.TAB.SUBSCRIPTIONS.url,
                q,
                'user_id',
                q['sort-direction']
            );
        }, 1);
        return;
    }

    componentWillReceiveProps(nextProps) {
        if (!Compare(this.props.location, nextProps.location, ['search'])) {
            this.search(QueryString.parse(nextProps.location.search));
        }
    }

    componentWillUnmount() {
        OrderActions.clear();
        return;
    }

    static contextType = MessagesContext;

    /**
     * Handle clicks on pagination controls.
     * @param  {Number} pageNumber page to load.
     */
    handleLoadPage(offset) {
        let size = QueryString.parse(this.props.location.search).size || 40;

        let query = Object.assign(
            {},
            QueryString.parse(this.props.location.search),
            {
                size,
                offset
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        const q = QueryString.parse(this.props.location.search);
        let query = Object.assign(
            {},
            q,
            {
                'sort-field-name': sortFieldName,
                'sort-direction': sortDirection
            }
        );

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        return;
    }

    hideStatusChange(success) {
        OrderActions.statusChangeHide();
        const q = QueryString.parse(this.props.location.search);
        if (success) {
            OrderActions.get(
                OrderConstants.ORDER.TAB.SUBSCRIPTIONS.url,
                q,
                q['sort-field-name'],
                q['sort-direction']
            );
        }
    }

    hideUnsubscribeClients() {
        OrderActions.unsubscribeClientsHide();
    }

    search(criteria) {
        OrderActions.get(
            OrderConstants.ORDER.TAB.SUBSCRIPTIONS.url,
            criteria,
            criteria['sort-field-name'],
            criteria['sort-direction']
        );

        return;
    }

    render() {
        let criteria = Object.assign({
            offset: 0,
            size: 40
        }, QueryString.parse(this.props.location.search));

        let columns = ['productTitle', 'client', 'fieldOffice', 'type', 'subscriptionDate', 'unsubscribeClient'];

        return (
            <DocumentTitle
                message="document-titles.browse-orders.subscriptions"
            >
                <div id="Subscriptions" className={ClassNames('tab-pane fade', {
                    'in active': this.props.location.pathname.match('/subscriptions')
                })}>
                    <StatusChangeModal show={!!this.state.statusChange.get('show')} onClose={this.hideStatusChange} orderId={this.state.statusChange.get('orderId')} />
                    <UnsubscribeClientsModal show={!!this.state.unsubscribeClients.get('show')} onClose={this.hideUnsubscribeClients} selectedClients={this.state.selectedClients} />
                    <h3 className="padding-y-10">
                        <span className="glyphicon glyphicon-check" /> {this.context.intl.messages['orders.tab.subscriptions.title']}
                    </h3>
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                        <ListOrders
                            columns={columns}
                            location={this.props.location}
                            offset={criteria.offset}
                            onPageChange={this.handleLoadPage}
                            onSortChange={this.handleSortChange}
                            orders={this.state.orders}
                            selectedClients={this.state.selectedClients}
                            showSelect={true}
                            size={criteria.size}
                            sortDirection={criteria['sort-direction']}
                            sortFieldName={criteria['sort-field-name']}
                            titles={this.state.titles}
                            total={this.state.totalCount}
                            users={this.state.users}
                        />
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Subscriptions);
export {Subscriptions};
