/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {Button, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {TransitionGroup} from 'react-transition-group';

import {FormRow, FormItem} from '../../form/form';
import TerritorySelect from '../../lookup/territory/territory-select';
import {MessagesContext} from '../../messages/messages-context';
import {Alert} from '../../notification/alert';
import {AlertsWarnings} from '../../notification/alert-warning';
import SessionStore from '../../session/session-store';
import {SignUpActions} from '../signup-actions';
import SignUpStore, {StepOneValidations} from '../signup-store';

class SignUpForm extends React.Component {
    static get propTypes() {
        return {
            isLoading: PropTypes.bool,
            onSubmit: PropTypes.func.isRequired,
            setter: PropTypes.func.isRequired,
            signup: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            isLoading: false
        };
    }

    static contextType = MessagesContext;

    render() {
        return (
            <Row className="form-wrapper">
                <form autoComplete="off">
                    <FormRow>
                        <FormItem attr="name"
                            label={this.context.intl.messages['signup.form.label.name']}
                            model={this.props.signup}
                            setter={this.props.setter}
                            validations={StepOneValidations.name.validations}
                        />
                        <FormItem attr="middleName"
                            label={this.context.intl.messages['signup.form.label.middleName']}
                            model={this.props.signup}
                            setter={this.props.setter}
                            validations={StepOneValidations.middleName.validations}
                        />
                        <FormItem attr="lastName"
                            label={this.context.intl.messages['signup.form.label.lastName']}
                            model={this.props.signup}
                            setter={this.props.setter}
                            validations={StepOneValidations.lastName.validations}
                        />
                    </FormRow>
                    <FormRow>
                        <FormItem attr="email"
                            label={this.context.intl.messages['signup.form.label.email']}
                            model={this.props.signup}
                            setter={this.props.setter}
                            validations={StepOneValidations.email.validations}
                        />
                        <TerritorySelect
                            attr="country"
                            isLoading={this.props.isLoading}
                            label={this.context.intl.messages['signup.form.label.country']}
                            model={this.props.signup}
                            name="country"
                            placeholder={this.context.intl.messages['signup.form.label.country.placeholder']}
                            required
                            setter={this.props.setter}
                        />
                    </FormRow>
                    <div className="col-md-12">
                        <p><em>{this.context.intl.messages['signup.form.footer.text.block1']} <Link to="/terms-of-use?from=sign-up">{this.context.intl.messages['signup.form.footer.text.button']}</Link> {this.context.intl.messages['signup.form.footer.text.block2']}</em></p>
                        <Button bsSize="large"
                            className="pull-right"
                            bsStyle="primary"
                            onClick={this.props.onSubmit}>
                            {this.context.intl.messages['signup.form.label.button.apply']}
                        </Button>
                    </div>
                </form>
            </Row>
        );
    }
}

class Create extends React.Component {
    static get propTypes() {
        return {
            isLoading: PropTypes.bool,
            location: PropTypes.object.isRequired,
        };
    }

    static get defaultProps() {
        return {
            isLoading: true,
        };
    }

    static calculateState() {
        return {
            additionalAccess: SignUpStore.getState().get('additionalAccess'),
            partner: SessionStore.getPartner(),
            signup: SignUpStore.getState().get('signup'),
        };
    }

    static getStores() {
        return [SignUpStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            alertVisible: false,
            isFormSubmited: false,
            isTerritoryDirty: false
        }, this.constructor.calculateState());

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const query = QueryString.parse(this.props.location.search);
        if (query.mode === 'edit' || query.from === 'domain-zero-modal') {
            return;
        }
        return;
    }

    static contextType = MessagesContext;

    handleSubmit(e) {
        e.preventDefault();
        const validations = SignUpStore.getStepOneValidations();

        if (validations.length) {
            this.setState({isFormSubmited: true});
        } else {
            SignUpActions.signUpStepOne(this.state.signup, this.state.partner);
        }
    }

    render() {
        let partnerId = this.state.partner.id;
        let setter = SignUpActions.updateAttr.bind(SignUpActions);
        let validations = SignUpStore.getStepOneValidations();
        let warning;

        let signUpForm = <SignUpForm
            isLoading ={this.props.isLoading}
            onSubmit={this.handleSubmit}
            partner={this.state.partner}
            setter={setter}
            signup={this.state.signup}
        />;

        // if form is not submited
        /* istanbul ignore next */
        if (this.state.isFormSubmited) {
            warning = (
                <AlertsWarnings
                    title={this.context.intl.messages['signup.form.error.message']}
                    validations={validations}
                />
            );
        }

        return (
            <div>
                <div className="col-md-2">
                </div>
                <div className="col-md-8">
                    <Row>
                        <h2>{this.context.intl.messages[`signup.form.title.${partnerId.toLowerCase()}`]}</h2>
                        <p>Please complete the form below. <span className="text-red">*</span> indicates required field.</p>
                        <TransitionGroup
                            transitionName="alert"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}>
                            <Alert />
                            {warning}
                        </TransitionGroup>
                    </Row>
                    {signUpForm}
                </div>
                <div className="col-md-2">
                </div>
            </div>
        );
    }
}

export default Container.create(Create);
export {SignUpForm};
export {Create};
