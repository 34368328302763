/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import './2015-slate.less';

import DocumentTitle from '~/src/common/document-title';

export default class TwentyFifteenSlate extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.2015-slate">
                <div className="container_2015 op_container_2015">
                    <div className="myrow">

                        <a href="https://www.wbtvd.com/page/slate-2015/WB_Theatrical_Slate_2015.pdf" rel="noopener noreferrer" target="_blank">
                            <img src="https://www.wbtvd.com/page/slate-2015/2015_Slate_billboard.jpg" alt="Title" className="banner"/>
                        </a>

                    </div>

                    {/* <!-- Row 1 Done --> */}
                    <div className="myrow">
                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_FOCUS.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Focus_cover.jpg" alt="" className="left_poster " />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_FOCUS.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>
                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1490513/videos/1559714" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>

                        </div>


                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_AMER_SNIPER.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/American_Sniper_cover.jpg" alt="" className="left_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_AMER_SNIPER.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1529370/videos/1559707" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_HEART_OF_THE_SEA.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Heart_of_the_Sea_cover.jpg" alt="" className="right_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_right">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_HEART_OF_THE_SEA.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_right" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_right">
                                <a href="/titles/1490519/videos/1695772" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Row 2 Done --> */}
                    <div className="myrow">

                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_INHERENT_VICE.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Inherent_Vice_cover.jpg" alt="" className="left_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_INHERENT_VICE.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>
                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1488473/videos/1536037" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>

                        </div>


                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_MAGIC_MIKE_XXL.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Magic_Mike_XXL_cover.jpg" alt="" className="left_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_MAGIC_MIKE_XXL.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1504885/videos/1600879" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_MAN_FROM_UNCLE.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/The_Man_from_UNCLE_cover.jpg" alt="" className="right_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_right">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_MAN_FROM_UNCLE.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_right" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_right">
                                <a href="/titles/1490530/videos/1608721" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Row 3 Done--> */}
                    <div className="myrow">

                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_POINT_BREAK.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Point_Break_cover.jpg" alt="" className="left_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_POINT_BREAK.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1506627/videos/1696204" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_VACATION.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Vacation_cover.jpg" alt="" className="left_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_VACATION.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1504992/videos/1605671" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_MIDNIGHT_SPECIAL.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Midnight_Special_cover.jpg" alt="" className="right_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_right">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_MIDNIGHT_SPECIAL.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_right" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_right">
                                <a href="/titles/1504982/videos/1721897" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>
                    </div>


                    {/* <!-- Row 4 --> */}

                    <div className="myrow">

                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_INTERN.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/The_Intern_cover.jpg" alt="" className="left_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_INTERN.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1490522/videos/1626084" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_GALLOWS.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Gallows_cover.jpg" alt="" className="left_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_GALLOWS.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1555460/videos/1603642" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_WHITEY_BULGER.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Untitled_Whitey_Bulger_cover.jpg" alt="" className="right_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_right">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_WHITEY_BULGER.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_right" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_right">
                                <a href="/titles/1519095/videos/1623092" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Row 5 --> */}

                    <div className="myrow">

                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_RUN_ALL_NIGHT.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Run_All_Night.jpg" alt="" className="left_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_RUN_ALL_NIGHT.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1490536/videos/1573468" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <a href="https://www.wbtvd.com/page/slate-2015/WB_MAD_MAX.pdf" rel="noopener noreferrer" target="_blank">
                                <img src="https://www.wbtvd.com/page/slate-2015/Mad_max_fury_road_cover.jpg" alt="" className="left_poster" />
                            </a>

                            <div className="col-sm-6 download_pdf_left">
                                <a href="https://www.wbtvd.com/page/slate-2015/WB_MAD_MAX.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_download.jpg" alt="" className="download_pdf_left" />
                                </a>
                            </div>

                            <div className="col-sm-6 watch_trailer watch_trailer_left">
                                <a href="/titles/1433548/videos/1602436" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/slate-2015/slate_btn_watch.jpg" alt="" className="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}
