/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {isEpisode, isEpisodeFromMiniseries} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';

import Config from '../../config/config';
import {MessagesContext} from '../../messages/messages-context';
import OrderStore from '../../orders/order-store';
import SessionStore from '../../session/session-store';
import SerieNavigationStore from '../../titles/serie-navigation/serie-navigation-store';
import {TitleConstants} from '../../titles/title-actions';
import TitleStore from '../../titles/title-store';
import {IsNotTriggerKey} from '../../utils/utils';
import HeaderStore from '../header/header-store';
import PageLayoutStore from '../page-layout/page-layout-store';

import '../header/header.less';

const noop = /* istanbul ignore next */() => /* istanbul ignore next */void 0;

class HappyFoxModal extends React.Component {
    static get propTypes() {
        return {
            showAssetRequestsModal: PropTypes.bool,
            onToggleModal: PropTypes.func,
        };
    }

    static get defaultProps() {
        return {
            showAssetRequestsModal: false,
            onToggleModal: noop,
        };
    }

    static calculateState() {
        return {
            serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
            title: TitleStore.getState().get('title'),
            user: SessionStore.getState().get('authUser')
        };
    }

    static getStores() {
        return [SessionStore, PageLayoutStore, OrderStore, SerieNavigationStore, TitleStore, HeaderStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            closeBtnStyles: { }
        }, this.constructor.calculateState());

        this.composeHappyFoxIframeUrl = this.composeHappyFoxIframeUrl.bind(this);
        this.showHappyFoxCloseBtn = this.showHappyFoxCloseBtn.bind(this);
        this.toggleAssetRequestsModal = this.toggleAssetRequestsModal.bind(this);
    }

    static contextType = MessagesContext;

    composeHappyFoxIframeUrl() {
        let productTitle = this.state.title.get('name');
        let seasonNumber;
        const where = this.state.serieNavigation.getIn(['serieMap', `${this.state.title.get('id')}`]);

        switch (this.state.title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            if (isEpisode(where)) {
                // is an episode
                seasonNumber = this.state.serieNavigation.getIn(['seasons', where.get(0), 'seasonNumber']);
                productTitle = `${this.state.serieNavigation.get('titleName')}: Season ${seasonNumber}: ${this.state.title.get('name')}`;
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            seasonNumber = this.state.title.get('season');
            if (isEpisodeFromMiniseries(where)) {
                productTitle = `${this.state.serieNavigation.get('titleName')}: Season ${seasonNumber}`;
            }
            break;
        }

        const formatPhone = phone => {
            if (
                !phone ||
                phone === '-'
            ) {
                return '';
            }

            return phone;
        };

        const params = {
            name: this.state.user.get('fullName', ''),
            email: this.state.user.get('email', ''),
            phone: formatPhone(this.state.user.get('dayPhone')),
            product_title: productTitle,
            subject: productTitle,
            product_mpm_number: this.state.title.get('mpmNumber', ''),
            season: seasonNumber,
        };
        return `${Config.HappyFoxWidgetUrl}?${QueryString.stringify(params)}`;
    }

    toggleAssetRequestsModal(show) {
        this.props.onToggleModal(show);
    }

    toggleAssetRequestsModalKeyUp(show, ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        this.toggleAssetRequestsModal(show);
    }

    showHappyFoxCloseBtn() {
        this.setState({
            closeBtnStyles: {
                display: 'inline-block'
            }
        });
    }

    render() {
        if (!this.props.showAssetRequestsModal) {
            return null;
        }

        // HappyFox "Asset Requests" modal
        return (
            <div className="happyfox-iframe-container" onClick={this.toggleAssetRequestsModal.bind(this, false)} onKeyUp={this.toggleAssetRequestsModalKeyUp.bind(this, false)} role="button" tabIndex="0">
                <button className="closeBtn" onClick={this.toggleAssetRequestsModal.bind(this, false)} onKeyUp={this.toggleAssetRequestsModalKeyUp.bind(this, false)} style={this.state.closeBtnStyles}>
                    <span className="glyphicon glyphicon-remove"/>
                </button>
                <iframe
                    className="happyfox-iframe"
                    frameBorder="0"
                    onLoad={this.showHappyFoxCloseBtn}
                    ref="happyFoxIframe"
                    src={this.composeHappyFoxIframeUrl()}
                    width="100%"
                    title={this.context.intl.messages['header.navbar.item.requests.happyfox.title']}
                />
            </div>
        );
    }
}

export default Container.create(HappyFoxModal);
