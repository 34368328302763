/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {STATIONS_SUB_TYPES} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import {AssetTitleConstants} from './asset-title-actions';

import {MessagesContext} from '~/src/messages/messages-context';
import {RouterActions} from '~/src/router/router-actions';

class Category extends React.Component {
    static get propTypes() {
        return {
            areAllFiltersSelected: PropTypes.bool.isRequired,
            assetFacetCounts: PropTypes.instanceOf(Immutable.Map).isRequired,
            assetFacets: PropTypes.instanceOf(Immutable.List),
            assetType: PropTypes.string.isRequired,
            contentType: PropTypes.object.isRequired,
            contentTypeFilters: PropTypes.instanceOf(Immutable.Set).isRequired,
            onChange: PropTypes.func.isRequired,
        };
    }

    static get defaultProps() {
        return {
            assetFacets: Immutable.List()
        };
    }

    constructor(props) {
        super(props);

        this.handleAllChange = this.handleAllChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);

        return;
    }

    static contextType = MessagesContext;

    handleAllChange(e) {
        this.props.onChange(
            Immutable.Set.of(...this.props.contentType.ALL),
            e.target.checked,
            this.props.areAllFiltersSelected,
            this.props.contentType
        );

        return;
    }

    handleChange(e) {
        let contentType = e.target.value;
        let selected = e.target.checked;

        this.props.onChange(
            Immutable.Set.of(contentType),
            selected,
            this.props.areAllFiltersSelected,
            this.props.contentType
        );

        return;
    }

    // The "All" checkbox is checked if all content types for the category
    // are included in the array.
    isAllChecked() {
        // Special case, that's why the if.
        if (this.props.areAllFiltersSelected) {
            return false;
        }

        return this.props.contentType.ALL.filter(
            t => this.props.assetFacetCounts.get(t, 0) > 0
        ).every(
            t => this.props.contentTypeFilters.includes(t)
        );
    }

    // Filter is checked if included in the array of filters or if empty.
    isChecked(t) {
        // Special case, that's why the if.
        if (this.props.areAllFiltersSelected) {
            return false;
        }

        return this.props.contentTypeFilters.includes(t);
    }

    render() {
        let assetType;
        switch (this.props.assetType) {
        case AssetTitleConstants.ASSET_CATEGORY.VIDEO:
            assetType = 'videos';
            break;
        case AssetTitleConstants.ASSET_CATEGORY.IMAGE:
            assetType = 'images';
            break;
        default:
            assetType = this.props.assetType;
            break;
        }

        let categoryLabel = this.context.intl.messages[`title.${assetType}.content-types.${this.props.contentType.key}`];
        if (assetType === 'stations') {
            categoryLabel = this.context.intl.messages['title.stations.content-types.document-type'];
        }

        if (!this.props.assetFacetCounts?.size) {
            return null;
        }
        let total = this.props.assetFacetCounts.reduce((r, totalByContentType) => {
            return r += totalByContentType;
        }, 0);

        let items = Object.values(this.props.contentType.TYPES).filter(
            // Only keep content types with assets.
            t => !!this.props.assetFacetCounts.get(t)
        ).map(
            t => {
                let typeLabel = this.context.intl.messages[`title.${assetType}.content-types.${t}`];
                if (this.props.assetFacets.size && this.props.assetType !== 'stations') {
                    // Station document filters should not show the facet secondaryGroup label
                    const facet = this.props.assetFacets.find(f => f.get('contentType') === parseInt(t, 10));
                    if (facet) {
                        typeLabel = facet.get('secondaryGroup');
                    }
                }

                return (
                    <li key={t}>
                        <label className="Fw(n)">
                            <input checked={this.isChecked(t)} onChange={this.handleChange} type="checkbox" value={t}/>
                            &nbsp;{typeLabel}&nbsp;<small>({this.props.assetFacetCounts.get(t)})</small>
                        </label>
                    </li>
                );
            }
        );

        return (
            <div>
                <div className="h5"><b>{categoryLabel}</b></div>
                <label className="Fw(n)"><input checked={this.isAllChecked()} onChange={this.handleAllChange} type="checkbox" value="all"/>&nbsp;All&nbsp;<small>({total})</small></label>
                <ul className="List(n)">
                    {items}
                </ul>
            </div>
        );
    }
}

export default class AssetFilter extends React.Component {
    static get propTypes() {
        return {
            assetFacetCounts: PropTypes.instanceOf(Immutable.Map).isRequired,
            assetFacets: PropTypes.instanceOf(Immutable.List),
            assetType: PropTypes.string.isRequired,
            contentTypeFilters: PropTypes.instanceOf(Immutable.Set).isRequired,
            location: PropTypes.object.isRequired,
        };
    }

    static get defaultProps() {
        return {
            assetFacets: Immutable.List()
        };
    }

    constructor(props) {
        super(props);

        let weekDate;
        const query = QueryString.parse(this.props.location.search);
        if (query['format-run-week']) {
            const dateOnlyString = query['format-run-week'].split(': ')[1];
            const dateObject = Moment(dateOnlyString, 'MM/DD/YY').toDate();
            weekDate = dateObject;
        }

        this.state = {
            weekDate: weekDate
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleOnDateChange = this.handleOnDateChange.bind(this);

        return;
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.contentTypeFilters !== nextProps.contentTypeFilters ||
            this.props.assetFacetCounts !== nextProps.assetFacetCounts ||
            this.props.assetType !== nextProps.assetFacetCounts
        );
    }

    static contextType = MessagesContext;

    handleFilterChange(contentTypes, selected, areAllFiltersSelected, changedContentType) {

        let contentTypeFilters = this.props.contentTypeFilters;

        if (selected) {
            if (areAllFiltersSelected) {
                // In this case, contentTypes is the only set we want to keep selected.
                contentTypeFilters = contentTypes;
            } else {
                // In this case, just add one set of filters with the other.
                contentTypeFilters = contentTypeFilters.union(contentTypes);
                // But don't forget to remove all other content types with a different
                // super type that may be selected.
                let assetType = this.props.assetType;
                if (assetType === 'stations') {
                    assetType = 'DOCUMENT';
                }
                Object.values(AssetTitleConstants.CONTENT_TYPES[assetType]).forEach(ct => {
                    // Skip currently selected content type.
                    if (changedContentType.id === ct.id) {
                        return;
                    }
                    contentTypeFilters = contentTypeFilters.subtract(ct.ALL);
                    return;
                });
            }
        } else {
            // Remove the filter from the list of currently selected.
            contentTypeFilters = contentTypeFilters.subtract(contentTypes);
        }

        let newQuery = Object.assign({}, QueryString.parse(this.props.location.search));
        newQuery.offset = 0;

        if (contentTypeFilters.size) {
            let assetFacetCounts = this.props.assetFacetCounts.flatten(1);
            newQuery.vct = contentTypeFilters.toJS().filter(vct => assetFacetCounts.get(vct, 0) > 0);
        } else {
            newQuery.view = 'grid';
            delete newQuery.vct;
        }

        newQuery['keep-scroll'] = true;

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(newQuery)}`);
    }

    handleOnDateChange(e) {
        let query = Object.assign({}, QueryString.parse(this.props.location.search));

        if (e) {
            const stringDate = Moment(e).format('[Week: ]MM/DD/YY');
            this.setState({weekDate:e});
            query['format-run-week'] = stringDate;
            RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
            return;
        }

        delete query['format-run-week'];
        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);
        this.setState({weekDate:e});
    }

    render() {
        let query = Object.assign({}, QueryString.parse(this.props.location.search));
        // videoFacetCounts is a complex object with the form:
        // {
        //     fullEpisodeOrFeature: {
        //         101: 3,
        //         ...
        //         103: 4
        //     },
        //     ...
        //     promo: {
        //         121: 2,
        //         ...
        //         129: 39
        //     }
        // }
        // By flattening one level, getting the keySeq and converting to a set,
        // we are able to get a Set[] of all the content types with at least 1 video:
        // allAvailableFilters = [101, ..., 103, ..., 121, ..., 129]
        let allAvailableFilters = this.props.assetFacetCounts.flatten(1).keySeq().toSet();
        // In order to know if all the available filters are selected, the difference between
        // the two sets: the available minus the currently selected ones must be 0.
        let areAllAvailableFiltersSelected = !allAvailableFilters.subtract(this.props.contentTypeFilters).size;

        let filterMenu;
        switch (this.props.assetType) {
        case 'stations':
            allAvailableFilters = Immutable.fromJS(STATIONS_SUB_TYPES.map(k => k.toString())).toSet();
            areAllAvailableFiltersSelected = !allAvailableFilters.subtract(this.props.contentTypeFilters).size;
            filterMenu = (<div>
                <div className="form-group">
                    <label>{this.context.intl.messages['title.stations.filter.run-week']}</label>
                    <ReactDatePicker
                        dateFormat="'Week: 'MM/dd/yy"
                        dropdownMode={'select'}
                        filterDate={(date => date.getDay() === 1)} // only allow selection on mondays
                        isClearable={true}
                        onChange={(value) => this.handleOnDateChange(value)}
                        placeholderText={this.context.intl.messages['title.stations.filter.run-week.placeholder']}
                        scrollableYearDropdown={true}
                        selected={this.state.weekDate}
                        showYearDropdown={true}
                    />
                </div>
                <Category
                    assetFacets={this.props.assetFacets.filter(asset => asset.get('secondaryGroup') === query['format-run-week'])}
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.DOCUMENT.DOCUMENT_TYPE}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.DOCUMENT.DOCUMENT_TYPE.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.DOCUMENT.DOCUMENT_TYPE.key)?.size})}/>
            </div>);
            break;
        case AssetTitleConstants.ASSET_CATEGORY.VIDEO:
            filterMenu = (<div>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.PROMO.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.VIDEO.PROMO}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.PROMO.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.ELEMENT.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.VIDEO.ELEMENT}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.ELEMENT.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.BEHIND_THE_SCENES.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.VIDEO.BEHIND_THE_SCENES}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.BEHIND_THE_SCENES.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.SALES.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.VIDEO.SALES}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.SALES.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.SAMPLE.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.VIDEO.SAMPLE}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.VIDEO.SAMPLE.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
            </div>);
            break;
        case AssetTitleConstants.ASSET_CATEGORY.IMAGE:
            filterMenu = (<div>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.SOCIAL_MEDIA}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.SOCIAL_MEDIA.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.LOGO.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.LOGO}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.LOGO.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.KEY_ART.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.KEY_ART}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.KEY_ART.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.OUTDOOR_ART.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.OUTDOOR_ART}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.OUTDOOR_ART.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.ONLINE.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.ONLINE}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.ONLINE.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.SVOD.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.SVOD}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.SVOD.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.GALLERY.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.GALLERY}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.GALLERY.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.ANIMATION_PRODUCTON_MATERIALS.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.ANIMATION_PRODUCTON_MATERIALS}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.ANIMATION_PRODUCTON_MATERIALS.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.BTS.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.BTS}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.BTS.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.EVENTS.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.EVENTS}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.EVENTS.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.PHOTOGRAPHY_STILLS.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.PHOTOGRAPHY_STILLS}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.PHOTOGRAPHY_STILLS.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.CLIENT_SAMPLES.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.CLIENT_SAMPLES}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.CLIENT_SAMPLES.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.OTHER.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.OTHER}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.OTHER.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
                <hr className={ClassNames({hidden: !this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.HBO_MAX.key).size})}/>
                <Category
                    areAllFiltersSelected={areAllAvailableFiltersSelected}
                    contentTypeFilters={this.props.contentTypeFilters}
                    contentType={AssetTitleConstants.CONTENT_TYPES.IMAGE.HBO_MAX}
                    assetFacetCounts={this.props.assetFacetCounts.get(AssetTitleConstants.CONTENT_TYPES.IMAGE.HBO_MAX.key)}
                    onChange={this.handleFilterChange}
                    assetType={this.props.assetType}
                />
            </div>);
            break;
        }

        return (
            <div>
                <div className="h4"><b>Filters</b></div>
                <br/>
                {filterMenu}
            </div>
        );
    }
}
