/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ClipsFacetsConstants} from './clips-facets-actions';
import {Dispatcher} from '../../../flux-helpers';

class ClipsFacetsStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            data: Immutable.Map({
                videoDialogueFacets: Immutable.List(),
                videoSettingFacets: Immutable.List(),
                videoTagFacets: Immutable.List(),
                videoCombinedTalentCharacterFacets: Immutable.List(),
            }),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case ClipsFacetsConstants.FACETS.SUCCESS:
            state = state.mergeIn(['data'], action.data).set('loading', false);
            break;
        }

        return state;
    }
}

export default new ClipsFacetsStore(Dispatcher);
