/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import ListShows from './list-shows';
import DocumentTitle from '../common/document-title';
import Config from '../config/config';
import {MessagesContext} from '../messages/messages-context';
import {CheckPartners} from '../router/router-helpers';
import {SearchActions, SearchConstants} from '../search/search-actions';
import SearchStore from '../search/search-store';
import SessionStore from '../session/session-store';

class ShowsPage extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            displayShowSize: SearchStore.getState().get('displayShowSize'),
            partnerId: SessionStore.getState().getIn(['partner', 'id']),
            thumbnails: SearchStore.getState().get('thumbnails'),
            titles: SearchStore.getState().get('titles')
        };
    }

    static getStores() {
        return [SearchStore];
    }

    constructor(props) {
        super(props);
        this.state = this.constructor.calculateState();
        return;
    }

    componentDidMount() {
        let query = Object.assign(
            {},
            this.props.location.search,
            {
                size: this.state.displayShowSize,
                'sort-direction': 'asc',
                'sort-field-name': 'titleReleaseDate'
            }
        );

        // in case when user loads current page directly he will see blank page
        setTimeout(() => {
            SearchActions.search([SearchConstants.SEARCH.TITLES], query, 'search', 'shows', this.state.partnerId);
        });
    }

    static contextType = MessagesContext;

    render() {
        return (
            <DocumentTitle
                message="document-titles.press-site.shows-title"
            >
                <div className="page-content">
                    <div className="container padding-top-20">
                        <div className="padding-x-15">
                            <div className="clearfix">
                                <h1 className="pull-left visible-block-sm-up margin-top-10">
                                    {this.context.intl.messages['document-titles.press-site.shows-title']}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="container padding-top-20">
                        <ListShows
                            shows={this.state.titles.get('results')}
                            thumbnails={this.state.thumbnails}
                        />
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default CheckPartners(
    Container.create(ShowsPage),
    [Config.Partners.PRESS_SITE.id, Config.Partners.ADULTSWIM_PRESS.id, Config.Partners.CARTOON_NETWORK_PRESS.id, Config.Partners.CARTOONITO_PRESS.id]
);
