import {FACET_NAMES} from '../constants';

/**
 * @param {import("../types").FacetsResponse} facets
 * @returns {import("../types").Facets}
 */
const addCombinedTalentCharacterFacet = (facets) => {
    const videoCombinedTalentCharacterFacets = [
        ...facets.videoTalentFacets.map(option => ({...option, group: FACET_NAMES.TALENT})),
        ...facets.videoTalentCharacterFacets.map(option => ({...option, group: FACET_NAMES.TALENT_CHARACTER})),
        ...facets.videoCharacterFacets.map(option => ({...option, group: FACET_NAMES.CHARACTER})),
    ].sort((firstEl, secondEl) =>
        firstEl.key.localeCompare(secondEl.key));

    return {
        ...facets,
        videoCombinedTalentCharacterFacets,
    };
};

export default addCombinedTalentCharacterFacet;
