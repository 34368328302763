/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col, Glyphicon, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {MessagesContext} from '../../../../messages/messages-context';
import {BreakIntoGroups} from '../../../../utils/utils';
import {AssetTitleConstants} from '../../asset-title-actions';
import VideoThumbnail from '../video-thumbnail';

/**
 * Video List
 */

class VideoList extends Component {

    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool,
            canInstantOrderVideos: PropTypes.bool,
            entityType: PropTypes.oneOf([AssetTitleConstants.ENTITY_TYPE.TITLE, AssetTitleConstants.ENTITY_TYPE.TALENT]).isRequired,
            id: PropTypes.number,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.string,
            videos: PropTypes.instanceOf(Immutable.List).isRequired
        };
    }

    static get defaultProps() {
        return {
            canAddToCart: false,
            canInstantOrderVideos: false,
            title: 'title.video-list.title',
            id: undefined
        };
    }

    static contextType = MessagesContext;

    render() {
        let entity = this.props.entityType;
        if (this.props.entityType === AssetTitleConstants.ENTITY_TYPE.TITLE) {
            entity = 'titles';
        }

        let videoListTitle = this.context.intl.messages[this.props.title];
        let videos = this.props.videos.toList().slice(0, 4);
        let videoList = [];
        if (videos.size) {
            videoList = BreakIntoGroups(videos, 2).map((videoRow, i) => {
                return (
                    <Col md={6} key={i} className="Mb(1em) col-inline">
                        <Row>
                            {videoRow.map((videoCol, j) => {
                                return (
                                    <VideoThumbnail
                                        canAddToCart={this.props.canAddToCart}
                                        canInstantOrderVideos={this.props.canInstantOrderVideos}
                                        entityType={this.props.entityType}
                                        serieNavigation={this.props.serieNavigation}
                                        titleId={this.props.id}
                                        key={j}
                                        video={videoCol}
                                    />
                                );
                            })}
                        </Row>
                    </Col>
                );
            });
        }

        return (
            <div className="container padding-y-10 padding-x-10">
                <hr />
                <h3>
                    {videoListTitle}
                    <Link to={`/${entity}/${this.props.id}/videos`} aria-label={`${videoListTitle} ${this.context.intl.messages['common.view-all']}`}>
                        <span className="btn btn-sm btn-primary">
                            {this.context.intl.messages['common.view-all']} <Glyphicon glyph="chevron-right" />
                        </span>
                    </Link>
                </h3>
                <div className="row">
                    {videoList}
                </div>
            </div>
        );
    }
}

export default VideoList;
