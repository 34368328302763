/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {createSlice} from '@reduxjs/toolkit';
import {Partner} from '@wbdt-sie/brainiac-web-common';

type PartnerType = typeof Partner;
interface SessionState {
    token?: string,
    partner: PartnerType
}

const initialState: SessionState = {
    token: localStorage.getItem('__token') || '',
    partner: JSON.parse(localStorage.getItem('__partner') || '{}')
};

export const SessionSlice = createSlice({
    initialState,
    name: 'session',
    reducers: {}
});
