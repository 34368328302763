/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Nav, NavItem} from 'react-bootstrap';
import {Route, Switch} from 'react-router-dom';

import {AccountActions, AccountConstants} from './account-actions';
import AccountTabs from './tabs';
import Config from '../config/config';
import WithPermissions from '../decorators/with-permissions';
import {MessagesContext} from '../messages/messages-context';
import {NotificationActions} from '../notification/notification-actions';
import {RouterActions} from '../router/router-actions';
import RouterHelpers from '../router/router-helpers';
import {SessionActions} from '../session/session-actions';
import SessionStore from '../session/session-store';

const SavedSearches = RouterHelpers(AccountTabs.SavedSearches, true, SessionStore.PERMISSIONS.SEARCH.SAVE);
const MyOrders = RouterHelpers(AccountTabs.MyOrders, true, SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.MY_ORDERS);
const MySavedCarts = RouterHelpers(AccountTabs.MySavedCarts, true, SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.MY_SAVED_CARTS);
const TitlePackages = RouterHelpers(AccountTabs.Metadata, true, SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.TITLE_PACKAGES);

class Account extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canSaveSearch: SessionStore.canUser(SessionStore.PERMISSIONS.SEARCH.SAVE),
            canSeeMyOrders: SessionStore.canUser(SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.MY_ORDERS),
            canSeeMySavedCarts: SessionStore.canUser(SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.MY_SAVED_CARTS),
            canSeeTitlePackages: SessionStore.canUser(SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.TITLE_PACKAGES)
        };
    }

    static calculateState() {
        return {
            partner: SessionStore.getState().get('partner'),
            showOneTimeWelcomeToast: SessionStore.getState().get('showOneTimeWelcomeToast'),
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.constructor.calculateState(),
            {
                links: Immutable.Map({
                    info: '/account',
                    subscriptions: '/account/subscriptions',
                    subscribedContent: '/account/subscribed-content',
                    onboarding: '/account/onboarding',
                    myOrders: '/account/my-orders',
                    mySavedCarts: '/account/my-saved-carts',
                    mySavedSearches: '/account/my-saved-searches',
                    downloadHistory: '/account/download-history',
                    metadata: '/account/metadata'
                })
            }
        );

        this.isActive = this.isActive.bind(this);
        this.getNavTab = this.getNavTab.bind(this);

        return;
    }

    componentDidMount() {
        if (this.state.showOneTimeWelcomeToast) {
            setTimeout(() => {
                NotificationActions.showAlertInfo('account.first.time.login', false, SessionActions.welcomeToastShown());
            }, 10);
        }
    }

    componentWillUnmount() {
        // Prevent double dispatch error when directly navigating to /accounts page
        setTimeout(() => {
            AccountActions.clear();
        }, 0);
    }

    static contextType = MessagesContext;

    handleTab(url) {
        return function(event) {
            event.preventDefault();
            RouterActions.redirect(url);
        };
    }

    isActive(linkName) {
        return this.props.location.pathname === this.state.links.get(linkName);
    }

    getNavTab(tabPermission, tabName, tabEvent, tabTitle) {
        if (!tabPermission) {
            return;
        }

        return (
            <NavItem
                active={this.isActive(tabName)}
                eventKey={tabEvent}
                onClick={this.handleTab(this.state.links.get(tabName))}
            >
                {this.context.intl.messages[`account.tab.${tabTitle}.title`]}
            </NavItem>
        );
    }

    render() {
        let isPressPartner = false;
        let partnerId = this.state.partner.get('id');
        let savedSearches;

        const {
            canSeeMyOrders,
            canSeeMySavedCarts,
            canSeeTitlePackages
        } = this.props.permissions;

        const {
            MY_ORDERS,
            MY_SAVED_CARTS,
            METADATA
        } = AccountConstants.ACCOUNT.TAB;

        switch (partnerId) {
        case Config.Partners.ADULTSWIM_PRESS.id:
        case Config.Partners.PRESS_SITE.id:
            isPressPartner = true;
            break;
        }

        if (this.props.permissions.canSaveSearch && !isPressPartner) {
            savedSearches = (
                <NavItem
                    active={this.isActive('mySavedSearches')}
                    eventKey={AccountConstants.ACCOUNT.TAB.MY_SAVED_SEARCHES}
                    onClick={this.handleTab(this.state.links.get('mySavedSearches'))}>
                    {this.context.intl.messages['account.tab.my-saved-searches.title']}
                </NavItem>
            );
        }

        return (
            <>
                <div className={ClassNames('container padding-y-10 padding-x-20', {'container-zero-content': SessionStore.isContentNotAvailable()})} id="hide-this">
                    <div className="row">
                        <h2 className="padding-y-10 h1">{this.context.intl.messages['account.view.title']}</h2>
                    </div>
                    <Nav className="nav nav-tabs">
                        <NavItem
                            active={this.isActive('info')}
                            eventKey={AccountConstants.ACCOUNT.TAB.INFO}
                            onClick={this.handleTab(this.state.links.get('info'))}>
                            {this.context.intl.messages['account.tab.info.title']}
                        </NavItem>
                        <NavItem
                            active={this.isActive('subscriptions')}
                            eventKey={AccountConstants.ACCOUNT.TAB.SUBSCRIPTION}
                            onClick={this.handleTab(this.state.links.get('subscriptions'))}>
                            {this.context.intl.messages['account.tab.subscriptions.title']}
                        </NavItem>
                        <NavItem
                            active={this.isActive('subscribedContent')}
                            eventKey={AccountConstants.ACCOUNT.TAB.SUBSCRIBED_CONTENT}
                            onClick={this.handleTab(this.state.links.get('subscribedContent'))}>
                            {this.context.intl.messages['account.tab.subscribed-content.title']}
                        </NavItem>

                        {this.getNavTab(canSeeMyOrders, 'myOrders', MY_ORDERS, 'my-orders')}

                        {this.getNavTab(canSeeMySavedCarts, 'mySavedCarts', MY_SAVED_CARTS, 'my-saved-carts')}

                        {savedSearches}

                        <NavItem
                            active={this.isActive('downloadHistory')}
                            eventKey={AccountConstants.ACCOUNT.TAB.DOWNLOAD_HISTORY}
                            onClick={this.handleTab(this.state.links.get('downloadHistory'))}>
                            {this.context.intl.messages['account.tab.download-history.title']}
                        </NavItem>

                        {this.getNavTab(canSeeTitlePackages, 'metadata', METADATA, 'my-metadata')}
                    </Nav>
                    <div className="tab-content">
                        <Switch>
                            <Route
                                component={AccountTabs.DownloadHistory}
                                exact
                                path={`${this.props.match.path}/download-history`}
                            />
                            <Route
                                component={MyOrders}
                                exact
                                path={`${this.props.match.path}/my-orders`}
                            />
                            <Route
                                component={MySavedCarts}
                                exact
                                path={`${this.props.match.path}/my-saved-carts`}
                            />
                            <Route
                                component={SavedSearches}
                                exact
                                path={`${this.props.match.path}/my-saved-searches`}
                            />
                            <Route
                                component={AccountTabs.SubscribedContent}
                                exact
                                path={`${this.props.match.path}/subscribed-content`}
                            />
                            <Route
                                component={AccountTabs.Subscriptions}
                                exact
                                path={`${this.props.match.path}/subscriptions`}
                            /><Route
                                component={TitlePackages}
                                exact
                                path={`${this.props.match.path}/metadata`}
                            />
                            <Route
                                component={AccountTabs.AccountInfo}
                            />
                        </Switch>
                    </div>
                </div>
            </>
        );
    }
}

export default WithPermissions(Account);
export {Account};
