/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import DocumentTitle from '../../common/document-title';
import LandingLayout from '../../layout/landing';
import {MessagesContext} from '../../messages/messages-context';

class Confirm extends Component {
    static contextType = MessagesContext;

    render() {
        return (
            <DocumentTitle
                message="document-titles.forgot-password-confirm"
            >
                <LandingLayout>
                    <Col className="box" sm={6} smOffset={3}>
                        <Row>
                            <Col sm={12}>
                                <div className="padding-bottom-20">
                                    {this.context.intl.messages['forgot-password.confirm']}
                                    <p className="text-center padding-top-20">
                                        <Link to="/login">{this.context.intl.messages['forgot-password.return.login']}</Link>
                                    </p>
                                </div>
                                <p>{this.context.intl.messages['forgot-password.contact']}</p>
                            </Col>
                        </Row>
                    </Col>
                </LandingLayout>
            </DocumentTitle>
        );
    }
}

export default Confirm;
