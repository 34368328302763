/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */


import PropTypes from 'prop-types';
import React, {memo} from 'react';
import ReactSelect from 'react-select';

import withOptionsFromImmutableList from './hocs/with-options-from-immutable-list';

const FacetSelect = (props) => {
    return <ReactSelect
        getOptionLabel={/* istanbul ignore next */option => /* istanbul ignore next */option.key}
        getOptionValue={/* istanbul ignore next */option => /* istanbul ignore next */option.key}
        isClearable
        isMulti
        openMenuOnClick={false}
        {...props}
    />;
};

FacetSelect.propTypes = {
    className: PropTypes.string,
    components: PropTypes.object,
    filterOption: PropTypes.func,
    isActive: PropTypes.bool,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    openMenuOnClick: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({key: PropTypes.string})),
    styles: PropTypes.object,
    value: PropTypes.arrayOf(PropTypes.shape({key: PropTypes.string})),
};

FacetSelect.defaultProps = {
    className: '',
    components: {},
    filterOption: undefined,
    isLoading: false,
    isActive: false,
    name: '',
    onChange: undefined,
    onInputChange: undefined,
    openMenuOnClick: true,
    options: [],
    styles: {},
    value: [],
};

export default withOptionsFromImmutableList(memo(FacetSelect));
