/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

// import {ResponsiveEmbed} from 'react-bootstrap';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
require('./preloader.less');

export default class Preloader extends React.Component {
    static get propTypes() {
        return {
            background: PropTypes.bool,
            bsSize: PropTypes.string,
            fixed: PropTypes.bool,
            messages: PropTypes.array,
            show: PropTypes.bool,
            children: PropTypes.node
        };
    }

    static get defaultProps() {
        return {
            background: true,
            bsSize: '',
            children: undefined,
            fixed: false,
            show: true,
            messages: []
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            currentMessage: ''
        };

        this.startMessagesInterval = this.startMessagesInterval.bind(this);
    }

    componentDidMount() {
        if (this.props.messages.length) {
            this.startMessagesInterval();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.show !== nextProps.show ||
            this.props.fixed !== nextProps.fixed ||
            this.props.messages !== nextProps.messages ||
            this.props.children !== nextProps.children) {
            return true;
        }

        if (this.state.currentMessage !== nextState.currentMessage) {
            return true;
        }

        return false;
    }

    componentWillUnmount() {
        clearInterval(this.changeMessageInterval);
    }

    startMessagesInterval() {
        let i = 0;
        this.changeMessageInterval = setInterval(() => {
            i = (i < (this.props.messages.length - 1)) ? i+1 : 0;
            this.setState({currentMessage: this.props.messages[i]});
        }, 4000);
    }

    render() {
        let rotatingMessage = null;
        if (this.props.messages.length) {
            rotatingMessage = <p className="message">{this.state.currentMessage}</p>;
        }

        let style = {};
        if (this.props.children) {
            style.position = 'relative';
        }

        let loader = (
            <div className={ClassNames(
                `preloader preloader-${this.props.bsSize}`,
                {
                    'preloader-background': this.props.background,
                    fixed: this.props.fixed,
                    hidden: !this.props.show
                }
            )}>
                <i className="spinner far fa-sync-alt fa-spin" />
                {rotatingMessage}
            </div>
        );

        return (
            <div style={style}>
                {loader}
                {this.props.children}
            </div>
        );
    }
}
