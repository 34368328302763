/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import CastList from './cast-list';
import style from './index.module.less';
import {MessagesContext} from '../../../../messages/messages-context';
import {TalentConstants} from '../../../../talent/talent-actions';
import ImageLoader from '../../../../titles/asset-title/images/image-loader';
import {Compare} from '../../../../utils/utils';
import {TitleConstants} from '../../../title-actions';

const BEETLEJUICE_TALENT = {
    '6580': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-michael-keaton.gif'),
    '1778': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-alec-baldwin.gif'),
    '7127': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-geena-davis.gif'),
    '9390': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-jeffrey-jones.gif'),
    '13023': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-catherine-ohara.gif'),
    '16448': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-winona-ryder.gif'),
    '2229': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-sylvia-sidney.gif'),
    '2333': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-robert-goulet.gif'),
    '16664': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-dick-cavett.gif'),
    '34907': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-glenn-shadix.gif'),
    '4507': require('../../../special-themes/beetlejuice/temp-images/beetlejuice-annie-mcenroe.gif')
};

const ELF_TALENT = {
    '13698': require('../../../special-themes/elf/temp-images/elf-title-talent-1.gif'),
    '14948': require('../../../special-themes/elf/temp-images/elf-title-talent-2.gif'),
    '21457': require('../../../special-themes/elf/temp-images/elf-title-talent-3.gif'),
    '17157': require('../../../special-themes/elf/temp-images/elf-title-talent-4.gif'),
    '13223': require('../../../special-themes/elf/temp-images/elf-title-talent-5.gif'),
    '14104': require('../../../special-themes/elf/temp-images/elf-title-talent-6.gif'),
    '231540': require('../../../special-themes/elf/temp-images/elf-title-talent-7.gif'),
    '16922': require('../../../special-themes/elf/temp-images/elf-title-talent-8.gif'),
    '45402': require('../../../special-themes/elf/temp-images/elf-title-talent-9.gif'),
    '231550': require('../../../special-themes/elf/temp-images/elf-title-talent-10.gif'),
    '6587': require('../../../special-themes/elf/temp-images/elf-title-talent-11.gif'),
    '179248': require('../../../special-themes/elf/temp-images/elf-title-talent-12.gif')
};

class Talent extends Component {

    static get propTypes() {
        return {
            item: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.props.item !== nextProps.item;
    }

    render() {
        return (
            <Col xs={4} className={ClassNames('padding-all-10', style.thumbnailActor)}>
                <p className="margin-bottom-0">
                    <Link to={`/talent/${this.props.item.get('talentId')}`}>{this.props.item.get('displayAKAFullName')}</Link>
                    <small>{this.props.item.get('characterNameInTitle')}</small>
                </p>
            </Col>
        );
    }

}

class TalentThumbnail extends Component {

    static get propTypes() {
        return {
            titleTalent: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.props.titleTalent !== nextProps.titleTalent;
    }

    render() {
        const talentId = this.props.titleTalent.get('talentId');
        const isBeetlejuice = this.props.titleTalent.get('titleId') === TitleConstants.BEETLEJUICE;
        const isElf = this.props.titleTalent.get('titleId') === TitleConstants.ELF;
        let src;
        if (this.props.titleTalent.get('defaultImagePortraitPreviewUrl') !== null) {
            src = this.props.titleTalent.get('defaultImagePortraitPreviewUrl');
        }

        if (BEETLEJUICE_TALENT[talentId.toString()] && isBeetlejuice) {
            src = BEETLEJUICE_TALENT[talentId.toString()];
        }

        if (ELF_TALENT[talentId.toString()] && isElf) {
            src = ELF_TALENT[talentId.toString()];
        }

        let url = '';
        // FIXME: this doesn't make any sense, because titleTalent is required!.
        if (this.props.titleTalent) {
            url = `/talent/${talentId}`;
        }

        return (
            <Col xs={4} className={ClassNames('padding-all-10', style.thumbnailActor)}>
                <Link to={url}>
                    <div className={ClassNames({'responsive-container': !isBeetlejuice} || {'responsive-container': !isElf})}>
                        <div className={ClassNames({'vertical-spacer': !isBeetlejuice} || {'vertical-spacer': !isElf})}></div>
                        <div className={ClassNames({'img-container': !isBeetlejuice} || {'img-container': !isElf})}>
                            <ImageLoader
                                alt={this.props.titleTalent.get('displayAKAFullName')}
                                className="img-responsive"
                                height="200"
                                src={src}
                                width="200"
                            />
                        </div>
                    </div>
                </Link>
                <p className="padding-top-10 margin-bottom-0">
                    <Link to={url}>{this.props.titleTalent.get('displayAKAFullName')}</Link>
                    <small>{this.props.titleTalent.get('characterNameInTitle')}</small>
                </p>
            </Col>
        );
    }
}

class CastAndCredits extends Component {
    static get propTypes() {
        return {
            talentByRole: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    shouldComponentUpdate(nextProps) {
        return !Compare(this.props, nextProps, ['titleTalent']);
    }

    static contextType = MessagesContext;

    render() {

        let talentsWithThumbnails = [
            {
                title: 'title.cast.actors',
                roleType: TalentConstants.ROLE.ACTOR
            },
            {
                title: 'title.cast.hosts',
                roleType: TalentConstants.ROLE.HOST
            }
        ].map( elem => {
            let items = this.props.talentByRole.get(elem.roleType, Immutable.List()).sortBy(
                t => t.get('orderInTitle')
            ).map(
                (t, i) => <TalentThumbnail titleTalent={t} key={i} s/>
            );
            if (items.size === 0) {
                return null;
            }
            return (
                <div key={elem.roleType}>
                    <h4>{this.context.intl.messages[elem.title]}</h4>
                    <Row>
                        <CastList items={items} maxSize={999} />
                    </Row>
                </div>
            );
        });

        let othersList = [
            {
                title: 'title.cast.directors',
                roleType: TalentConstants.ROLE.DIRECTOR
            },
            {
                title: 'title.cast.producers',
                roleType: TalentConstants.ROLE.PRODUCER
            },
            {
                title: 'title.cast.voice-talent',
                roleType: TalentConstants.ROLE.VOICE_TALENT
            },
            {
                title: 'title.cast.writers',
                roleType: TalentConstants.ROLE.WRITER
            },
            {
                title: 'title.cast.musicians',
                roleType: TalentConstants.ROLE.MUSICIAN
            },
            {
                title: 'title.cast.choreographers',
                roleType: TalentConstants.ROLE.CHOREOGRAPHER
            },
            {
                title: 'title.cast.executive-producers',
                roleType: TalentConstants.ROLE.EXECUTIVE_PRODUCER
            },
            {
                title: 'title.cast.guest-star',
                roleType: TalentConstants.ROLE.GUEST_STAR
            },
            {
                title: 'title.cast.authors',
                roleType: TalentConstants.ROLE.AUTHOR
            },
            {
                title: 'title.cast.animated-characters',
                roleType: TalentConstants.ROLE.ANIMATED_CHARACTER
            },
            {
                title: 'title.cast.misc-crew',
                roleType: TalentConstants.ROLE.MISC_CREW
            },
            {
                title: 'title.cast.creator',
                roleType: TalentConstants.ROLE.CREATOR
            }
        ].map( elem => {
            let items = this.props.talentByRole.get(elem.roleType, Immutable.List()).sortBy(
                t => t.get('orderInTitle')
            ).map(
                (t, i) => <Talent item={t} key={i} />
            );
            if (items.size === 0) {
                return null;
            }
            return (
                <div key={elem.roleType}>
                    <h4>{this.context.intl.messages[elem.title]}</h4>
                    <Row>
                        <CastList items={items} maxSize={999} />
                    </Row>
                </div>
            );
        });

        return (
            <Col
                className={ClassNames({'title-beetlejuice-talent': this.props.title.get('id') === TitleConstants.BEETLEJUICE}, {'title-elf-talent': this.props.title.get('id') === TitleConstants.ELF})}
                componentClass="aside"
                md={6}
            >
                <h3>{this.context.intl.messages['title.cast.title']}</h3>

                {talentsWithThumbnails}

                {othersList}
            </Col>
        );
    }
}

export default CastAndCredits;
export {TalentThumbnail};
