/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/* istanbul ignore file */
import AllOrders from './all-orders';
import Metadata from './metadata';
import MyOrders from './my-orders';
import MySavedCarts from './my-saved-carts';
import OrdersInProgress from './orders-in-progress';
import RequestsSentMe from './sent-to-me';
import StandingOrders from './standing-orders';
import Subscriptions from './subscriptions';

export default {
    AllOrders: AllOrders,
    MyOrders: MyOrders,
    MySavedCarts: MySavedCarts,
    OrdersInProgress: OrdersInProgress,
    RequestsSentMe: RequestsSentMe,
    Subscriptions: Subscriptions,
    StandingOrders: StandingOrders,
    Metadata: Metadata
};
