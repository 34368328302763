/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {MessagesContext} from '../../messages/messages-context';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('datatables.net-rowreorder-bs/css/rowReorder.bootstrap.css');
require('../../styles/app/dataTables.brainiac.css');
require('../../styles/app/responsive.brainiac.css');

// Load jQuery and register the datatables plugin.
/* eslint-disable-next-line */
let jQuery = require('jquery');
require('datatables.net-rowreorder');
require('datatables.net-responsive-bs');

let noop = /* istanbul ignore next */() => void 0;

export default class Table extends React.Component {
    static get propTypes() {
        return {
            columns: PropTypes.arrayOf(
                PropTypes.shape({
                    getHeader: PropTypes.func.isRequired,
                    getValue: PropTypes.func.isRequired
                })
            ).isRequired,
            enabledReorder: PropTypes.bool,
            items: PropTypes.instanceOf(Immutable.List).isRequired,
            handleReorder: PropTypes.func,
            handleRowClick: PropTypes.func
        };
    }

    static get defaultProps() {
        return {
            handleReorder: noop,
            handleRowClick: noop,
            enabledReorder: false,
        };
    }

    constructor(props) {
        super(props);

        this.initTable = this.initTable.bind(this);
        this.loadItems = this.loadItems.bind(this);
        this.onHandleReorder = this.onHandleReorder.bind(this);
        this.onHandleRowClick = this.onHandleRowClick.bind(this);
    }

    componentDidMount() {
        this.loadItems(this.props.items, this.props.columns);
    }

    componentDidUpdate(prevProps) {
        // Exit if nothing changed.
        if (
            this.props.columns === prevProps.columns &&
            this.props.items === prevProps.items
        ) {
            return;
        }

        this.$tableAPI.clear();

        /* istanbul ignore else */
        if (this.props.columns !== prevProps.columns) {
            // Destroy and recreate the table.
            this.initTable(null);
            this.initTable(this.$table.get(0));
        }

        this.loadItems(this.props.items, this.props.columns);

        return;
    }

    componentWillUnmount() {
        /* istanbul ignore if */
        if (this.$table) {
            this.$table.off('row-reorder');
        }
        /* istanbul ignore else */
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    static contextType = MessagesContext;

    initTable(table) {
        /* istanbul ignore else */
        if (!table) {
            this.$tableAPI.destroy();
            return;
        }

        let rowReorderHandleClassName = '';
        /* istanbul ignore if */
        if (this.props.enabledReorder) {
            rowReorderHandleClassName = 'row-reorder-handle';
        }

        this.$table = jQuery(table);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            rowReorder: this.props.enabledReorder,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }, {
                className: rowReorderHandleClassName,
                targets: 0
            }, {
                className: 'actions',
                targets: 'actions'
            }, {
                targets: 'no-sort',
                orderable: false
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        /* istanbul ignore if */
        if (this.props.enabledReorder) {
            this.$tableAPI.rowReorder = {
                selector: '.row-reorder-handle'
            };
            this.$tableAPI.on('row-reorder', this.onHandleReorder);
        }

        return;
    }

    loadItems(items, columns) {
        // Add data to the jQuery datatable.
        /* istanbul ignore next */
        items.forEach((item, itemIndex) => {
            let row = columns.map(c => {
                let v = c.getValue(item, itemIndex, this.context.intl, items);
                if (v === undefined || v === null) {
                    return '-';
                }

                return v;
            });

            // Add a last empty column for the datatable-responsive plugin.
            row.push('');

            this.$tableAPI.row.add(row);

            return;
        });

        this.$tableAPI.draw(false);

        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.$tableAPI.responsive.recalc();

        return;
    }

    onHandleReorder(event, diff, edit) {
        this.props.handleReorder(event, diff, edit);
    }

    onHandleRowClick(event) {
        this.props.handleRowClick(event);
    }

    render() {
        return (
            <table
                className="table table-striped"
                ref={this.initTable}
            >
                <thead>
                    <tr>
                        {this.props.columns.map(/* istanbul ignore next */c => /* istanbul ignore next */c.getHeader(this.context.intl))}
                        <th className="no-sort" scope="col"></th>
                    </tr>
                </thead>
                <tbody onClick={this.onHandleRowClick}>
                </tbody>
            </table>
        );
    }
}
