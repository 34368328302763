/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './summer-of-animation.less';

export default class SummerOfAnimation extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.summer-of-animation">
                <div id="op-page" className="container op_container_summer_of_animation">
                    <div id="op_header" className="row">
                        <div id="op-header-img">
                            <div id="header-btns">
                                <a
                                    className="btn-header catalog"
                                    href="https://www.wbtvd.com/page/summer-of-animation/WarnerBros_SummerOfAnimation_Catalog.pdf"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <img
                                        src="https://www.wbtvd.com/page/summer-of-animation/banner_btn_catalog.png"
                                        alt="Catalog"
                                    />
                                </a>
                                <a
                                    className="btn-header avails"
                                    href="https://www.wbtvd.com/page/summer-of-animation/WarnerBros_SummerOfAnimation.xlsx"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <img
                                        src="https://www.wbtvd.com/page/summer-of-animation/banner_btn_avails.png"
                                        alt="Avails"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="op_content" className="row clearfix">
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/1409707"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/MikeTysonMysteries_600x880_.jpg)'}}
                                title="Mike Tyson Mysteries"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/1789535"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/Unikitty_600x880_.jpg)'}}
                                title="Unikitty"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/1544270"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/ElfBuddysMusicalChristmas_600x880_.jpg)'}}
                                title="Buddy the Elf (Christmas Special)"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/1819646"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/LEGODCComicsSuperHeroesTheFlash_600x880_.jpg)'}}
                                title="Lego DC Comics Super Heroes: The Flash"></a>
                            <div className="btns">
                                <a
                                    href="/titles/1819646/videos/1926310 "
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/1819641"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/SuicideSquadHelltoPay_600x880_.jpg)'}}
                                title="Suicide Squad: Hell to Pay"></a>
                            <div className="btns">
                                <a
                                    href="/titles/1819641/videos/1935541 "
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/1614606"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/Vixen_DigitalSeries_600x880_.jpg)'}}
                                title="Vixen"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/1842838"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/FreedomFighters_TheRay_600x880_.jpg)'}}
                                title="Freedom Fighters: The Ray"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/1843167"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/ConstantineCityOfDemons_600x880_.jpg)'}}
                                title="Constantine: City of Demons"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/1720653"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/TheMayflowerVoyagers_600x880_.jpg)'}}
                                title="This is America, Charlie Brown: Mayflower Voyagers, The"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/731505"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/ItsChristmastimeAgainCharlieBrown_600x880_.jpg)'}}
                                title="It's Christmastime Again, Charlie Brown"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/731328"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/BeMyValentineCharlieBrown_600x880_.jpg)'}}
                                title="Be My Valentine, Charlie Brown"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/731363"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/HappyNewYearCharlieBrown_600x880_.jpg)'}}
                                title="Happy New Year, Charlie Brown"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/731441"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/ItsArborDayCharlieBrown_600x880_.jpg)'}}
                                title="It's Arbor Day, Charlie Brown"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/731397"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/IWantaDogforChristmasCharlieBrown_600x880_.jpg)'}}
                                title="I Want a Dog for Christmas, Charlie Brown"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/731324"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/ACharlieBrownValentine_600x880_.jpg)'}}
                                title="Charlie Brown Valentine, A"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/51854"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/TheHalloweenTree_600x880_.jpg)'}}
                                title="Halloween Tree, The"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/52122"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/MrT_600x880_.jpg)'}}
                                title="Mr. T"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/52054"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/TheAmazingChanAndTheChanClan_600x880_.jpg)'}}
                                title="Amazing Chan and The Chan Clan"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/52354"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/GooberAndTheGhostChasers_600x880_.jpg)'}}
                                title="Goober and the Ghost Chasers"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/52448"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/ClueClub_600x880_.jpg)'}}
                                title="Clue Club"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/52186"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/FunkyPhantom_600x880_.jpg)'}}
                                title="Funky Phantom"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/52504"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/Jabberjaw_600x880_.jpg)'}}
                                title="Jabberjaw"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/52058"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/InchHighPrivateEye_600x880_.jpg)'}}
                                title="Inch High Private Eye"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/51912"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/TheThanksgivingThatAlmostWasnt_600x880_.jpg)'}}
                                title="Thanksgiving That Almost Wasn't, The"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/613087"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/AMiserBrothersChristmas_600x880_.jpg)'}}
                                title="Miser Brothers' Christmas, A"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/549133"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/BahHumduckALooneyChristmas_600x880_.jpg)'}}
                                title="Bah Humduck! A Looney Tunes Christmas"></a>
                            <div className="btns">
                                <a
                                    href="/titles/549133/videos/1747465 "
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6">

                            <a
                                className="poster"
                                href="/titles/51909"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://www.wbtvd.com/page/summer-of-animation/TheStingiestManInTown_600x880_.jpg)'}}
                                title="Stingiest Man in Town, The"></a>
                            <div className="btns">
                                <a
                                    href=""
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </DocumentTitle>

        );
    }
}
