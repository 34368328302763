/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './animation-package.less';

export default class AnimationPackage extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.animation-package">
                <div className="container op_container_animation_package">

                    <div id="op_header" className="row">
                        <div id="op-header-img">
                            <div id="header-btns">
                                <a className="btn-header catalog" href="https://www.wbtvd.com/page/animation-package/WarnerBros_SuperAnimation_Catalog.pdf" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/animation-package/banner_btn_catalog.png" alt="Catalog" />
                                </a>
                                <a className="btn-header avails" href="https://www.wbtvd.com/page/animation-package/WarnerBros_SuperAnimation.xlsx" rel="noopener noreferrer" target="_blank">
                                    <img src="https://www.wbtvd.com/page/animation-package/banner_btn_avails.png" alt="Avails" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.row --> */}

                    <div id="op_content" className="row clearfix">
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1774609" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/BatmanandHarleyQuinn_600x880.jpg)'}} title="Batman and Harley Quinn"></a>
                            <div className="btns"><a href="/titles/1774609/videos/1904047" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1526886" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/BatmanUnlimitedAnimalInstinct_600x880_217810dd.jpg)'}} title="Batman Unlimited: Animal Instinct"></a>
                            <div className="btns"><a href="/titles/1526886/videos/1580263" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1674236" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/BatmanUnlimitedMechsvsMutants_600x880_4dc0ee22.jpg)'}} title="Batman Unlimited: Mechs vs. Mutants"></a>
                            <div className="btns"><a href="/titles/1674236/videos/1770645" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1526889" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/BatmanUnlimitedMonsterMayhem_600x880_660091ef.jpg)'}} title="Batman Unlimited: Monster Mayhem"></a>
                            <div className="btns"><a href="/titles/1526889/videos/1632467" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1704146" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/BatmanvsTwoFace_600x880.jpg)'}} title="Batman vs. Two-Face"></a>
                            <div className="btns"><a href="/titles/1704146/videos/1922735" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1566642" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/BatmanBadBlood_600x880_39417f22.jpg)'}} title="Batman: Bad Blood"></a>
                            <div className="btns"><a href="/titles/1566642/videos/1723549" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1819622" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/BatmanGothambyGaslight_600x880_DClogo_5e4b9527.jpg)'}} title="Batman: Gotham by Gaslight"></a>
                            <div className="btns"><a href="/titles/1819622/videos/1923571" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1769528" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/BatmanReturnoftheCapedCrusaders_600x880_9641ff14.jpg)'}} title="Batman: Return of the Caped Crusaders"></a>
                            <div className="btns"><a href="/titles/1769528/videos/1927232" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1674239" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/DCSuperheroGirlsHerooftheYear_600x880_7f4a14b5.jpg)'}} title="DC Superhero Girls: Hero of the Year"></a>
                            <div className="btns"><a href="/titles/1674239/videos/1770741" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1774612" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/DCSuperheroGirlsIntergalacticGames_600x880_7dacb70b.jpg)'}} title="DC Superhero Girls: Intergalactic Games"></a>
                            <div className="btns"><a href="/titles/1774612/videos/1904407" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1527131" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/FlintstonesWWEStoneAgeSmackdown_600x880_33847210.jpg)'}} title="Flintstones & WWE: Stone Age Smackdown"></a>
                            <div className="btns"><a href="/titles/1527131/videos/1927114" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1774615" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/JetsonsWWERoboWrestlemania_600x880_dc992b1f.jpg)'}} title="Jetsons & WWE: Robo-Wrestlemania"></a>
                            <div className="btns"><a href="/titles/1774615/videos/1922741" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1604927" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/JusticeLeaugeAction_600x880_851b7031.jpg)'}} title="Justice League Action "></a>
                            <div className="btns"><a href="/titles/1604927/videos/1927231" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1734307" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/JusticeLeagueDark_600x880.jpg)'}} title="Justice League Dark"></a>
                            <div className="btns"><a href="/titles/1734307/videos/1908698" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1300262" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/LegoBatmanDCSuperHeroesUnite_600x880_b4bc8b09.jpg)'}} title="Lego Batman: DC Super Heroes Unite!"></a>
                            <div className="btns"><a href="/titles/1300262/videos/1596085" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1527148" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/LegoDCHJLLegionDoom_600x880.jpg)'}} title="Lego DC Comics Super Heroes: Justice League - Attack of the Legion of Doom!"></a>
                            <div className="btns"><a href="/titles/1527148/videos/1913723" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1527143" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/LegoDCCSHJLvsBizarroL_600x880.jpg)'}} title="Lego DC Comics Super Heroes: Justice League vs. Bizarro League"></a>
                            <div className="btns"><a href="/titles/1527143/videos/1913725" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1742104" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/LegoDCSuperHeroGirlsBrainDrain_600x880_447a3b01.jpg)'}} title="Lego DC Super Hero Girls: Brain Drain"></a>
                            <div className="btns"><a href="/titles/1742104/videos/1904410" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1841572" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/LegoDCHGSuperVillainHigh_600x880.jpg)'}} title="Lego DC Superhero Girls: Super-villain High"></a>
                            <div className="btns"><a href="/titles/1841572/videos/" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1774618" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/LegoScoobyDooBlowoutBeachBash_600x880_6e1ce6b6.jpg)'}} title="Lego Scooby Doo: Blowout Beach Bash"></a>
                            <div className="btns"><a href="/titles/1774618/videos/1904604" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1608686" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/LegoScoobyDooHauntedHollywood_600x880_60b4babd.jpg)'}} title="Lego Scooby Doo: Haunted Hollywood"></a>
                            <div className="btns"><a href="/titles/1608686/videos/1904613" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1527133" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/RabbitsRun_73a403ad.jpg)'}} title="Looney Tunes: Rabbits Run"></a>
                            <div className="btns"><a href="/titles/1527133/videos/1596088" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/52293" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/PaddingtonBear_600x880_7eccdc26.jpg)'}} title="Paddington Bear"></a>
                            <div className="btns"><a href="/titles/52293/videos/" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1609625" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/ScoobyDooWWECurseoftheSpeedDemon_600x880_87352194.jpg)'}} title="Scooby-Doo & WWE: Curse of the Speed Demon"></a>
                            <div className="btns"><a href="/titles/1609625/videos/1770793" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1734310" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/ScoobyDooShaggysShowdown_600x880_9780fb80.jpg)'}} title="Scooby-Doo: Shaggy's Showdown"></a>
                            <div className="btns"><a href="/titles/1734310/videos/1922737" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1742101" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/JudasContract_Tout_d9563d75.jpg)'}} title="Teen Titans: The Judas Contract"></a>
                            <div className="btns"><a href="/titles/1742101/videos/1807558" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1674242" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/TomJerryBacktoOz_600x880_21fb1101.jpg)'}} title="Tom & Jerry: Back to Oz"></a>
                            <div className="btns"><a href="/titles/1674242/videos/1770798" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                        <div className="content_container col-lg-3 col-md-3 col-sm-3 col-xs-4 col-xxs-6"> <a className="poster" href="/titles/1774621" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://www.wbtvd.com/page/animation-package/TomJerryWillyWonkaandtheChocolateFactory_600x880_4abba697.jpg)'}} title="Tom & Jerry: Willy Wonka and the Chocolate Factory"></a>
                            <div className="btns"><a href="/titles/1774621/videos/1927230" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                    </div>
                    {/* <!-- /#op_content.row --> */}


                </div>
            </DocumentTitle>
        );
    }
}
