/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './horror-package.less';

export default class HorrorPackage extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.horror-package">
                <div className="container op_container_horror_package">
                    <div id="op_header" className="row">
                        <div id="op-header-img">
                            <div id="header-btns">
                                <a
                                    className="btn-trailer btn-header reel"
                                    href="/titles/1843186/videos/1915467"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <img
                                        src="https://wbtvd.com/page/horror-package/banner_btn_sizzlereel.png"
                                        alt=""
                                    />
                                </a>
                                <a
                                    className="btn-header presentation"
                                    href="https://wbtvd.com/page/horror-package/WB_Horror_Movies.pdf"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <img
                                        src="https://wbtvd.com/page/horror-package/banner_btn_presentation.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="op_content" className="row clearfix">
                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/1506134"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Annabelle_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/1506134"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Feature</span>
                                </a>
                                <a
                                    href="/titles/1506134/videos/1527438"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/46529"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Blade_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/46529"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/46529/videos/1185933"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/1324930"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Conjuring_The_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/1324930"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/1324930/videos/1399559"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/1490178"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Conjuring2_The_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/1490178"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/1490178/videos/1709873"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/167740"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Dreamcatcher_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/167740"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/167740/videos/1706270"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/74112"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Exorcist_The_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/74112"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/74112/videos/1525193"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/75194"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Exorcist_II_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/75194"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/75194/videos/1790825"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/73983"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Exorcist_Never_Seen_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/73983"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/73983/videos/1279370"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/73946"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Final_Destination_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/73946"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/73946/videos/1171435"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/75243"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Final_Destination2_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/75243"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/75243/videos/1175622"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/293027"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Final_Destination3_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/293027"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/293027/videos/1563474"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/969588"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Final_Destination5_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/969588/"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/969588/videos/1575080"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/74435"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Ghost_Ship_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/74435"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/74435/videos/1187258"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/235170"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/House_Of_Wax_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/235170"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/235170/videos/1184371"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/47483"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Interview_With_The_Vampire_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/47483"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/47483/videos/1187450"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/74403"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Queen_Of_The_Damned_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/74403"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/74403/videos/1203175"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/75451"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Friday_The_13th_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/75451"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/75451/videos/1177409"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/47536"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Jason_Goes_To_Hell_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/47536"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/47536/videos/1917953"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/75245"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Jason_X_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/75245"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/75245/videos/1270148"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/47799"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Leatherface_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/47799"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/47799/videos/979569"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/1707246"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Lights_Out_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/1707246"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/1707246/videos/1766682"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/47561"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Lost_Boys_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/47561"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/47561/videos/1909597"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/48495"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Nightmare_On_Elm_Street_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/48495"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/48495/videos/1303331"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/47811"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Nightmare_On_Elm_Street2_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/47811"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/47811/videos/1773477"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/48492"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Nightmare_On_Elm_Street3_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/48492"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/48492/videos/1650723"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/48493"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Nightmare_On_Elm_Street4_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/48493"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/48493/videos/966340"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/48494"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Nightmare_On_Elm_Street5_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/48494"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/48494/videos/1690550"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/229473"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Freddys_Dead_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/229473"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/229473/videos/1747855"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/765538"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Nightmare_On_Elm_Street_2010_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/765538"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/765538/videos/1176607"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/48808"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Poltergeist_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/48808"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/48808/videos/1177394"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/292797"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Reaping_The_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/292797"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/292797/videos/1269825"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/969582"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Red_Riding_Hood_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/969582"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/969582/videos/1687971"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/969098"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Rite_The_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/969098"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/969098/videos/1448023"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/49401"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Shining_The_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/49401"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/49401/videos/1186332"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a
                                className="poster"
                                href="/titles/51665"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{backgroundImage: 'url(https://wbtvd.com/page/horror-package/Stephen_Kings_It_227x304.jpg)'}}></a>
                            <div className="btns">
                                <a
                                    href="/titles/51665"
                                    className="btn btn-showinfo showinfo_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; GFX</span>
                                </a>
                                <a
                                    href="/titles/51665/videos/1900481"
                                    className="btn btn-trailer vid_class"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <span>&#8227; Trailer</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="op_footer" className="row">
                        For more information or to order the WB Horror Programming Package, please
                        email <a href="mailto:Mike.Shackleton@warnerbros.com?subject=Horror%20Programming%20Package">Mike.Shackleton@warnerbros.com</a>
                    </div>
                </div>
            </DocumentTitle>

        );
    }
}
