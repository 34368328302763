/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, ControlLabel, Row, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';
import {TransitionGroup} from 'react-transition-group';

import {DeliveryActions} from './delivery-actions';
import DeliveryStore from './delivery-store';
import MetadataExportLanguage from './metadata-export-language';
import MetadataExportSpec from './metadata-export-spec';
import Notification from './notification';
import {GetAttr} from '../../../common/utils/utils';
import WithPermissions from '../../../decorators/with-permissions';
import {FormRow, FormSection} from '../../../form/form';
import {MessagesContext} from '../../../messages/messages-context';
import {AlertsWarnings} from '../../../notification/alert-warning';
import SessionStore from '../../../session/session-store';


class Delivery extends Component {

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            permissions: PropTypes.object.isRequired,
            onShowAdditionalAccess: PropTypes.func.isRequired
        };
    }

    static calculateState() {
        return {
            fileFormat: DeliveryStore.getState().get('fileFormat'),
            notifications: DeliveryStore.getState().get('notifications'),
            profile: DeliveryStore.getState().get('profile'),
            userId: SessionStore.getState().get('authUser').get('id')
        };
    }

    static getPermissions() {
        return {
            canRequestAdditionalAccess: SessionStore.canUser(SessionStore.PERMISSIONS.MY_ACCOUNT.TABS.INFO.REQUEST_ADDITIONAL_ACCESS)
        };
    }

    static getStores() {
        return [DeliveryStore];
    }

    static get defaultProps() {
        return {
            disabled: false
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            alertVisible: false,
            isFormSubmited: false
        }, this.constructor.calculateState());

        this.handleAdd = this.handleAdd.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSelectFileFormat = this.handleSelectFileFormat.bind(this);
        this.handleSelectImageDeliveryFormat = this.handleSelectImageDeliveryFormat.bind(this);
    }

    componentDidMount() {
        DeliveryActions.clear();
        DeliveryActions.getNotificationsById(this.state.userId);
        DeliveryActions.getProfileById(this.state.userId);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled) {
            return true;
        }

        if (nextState.notifications !== this.state.notifications ||
            nextState.profile !== this.state.profile ||
            nextState.isFormSubmited !== this.state.isFormSubmited) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    handleAdd() {
        DeliveryActions.addNotification();
        return;
    }

    handleSave() {
        let validations = DeliveryStore.getValidations();
        if (validations.length) {
            this.setState({isFormSubmited: true});
        } else {
            this.setState({isFormSubmited: false});
            DeliveryActions.saveProfile(this.state.userId, this.state.profile, this.state.notifications);
        }
        return;
    }

    handleSelectFileFormat(option) {
        if (!option) {
            DeliveryActions.updateProfile('fileFormat', null);
            return;
        }
        DeliveryActions.updateProfile('fileFormat', option.id);
        return;
    }

    handleSelectImageDeliveryFormat(option) {
        if (!option) {
            DeliveryActions.updateProfile('downloadRenditionType', null);
            return;
        }
        DeliveryActions.updateProfile('downloadRenditionType', option.id);
        return;
    }

    getHandleSelect(attr) {
        return (value) => {
            let valueId;
            if (value) {
                valueId = value.id;
            }
            DeliveryActions.updateProfile(attr, valueId);
        };
    }

    render() {
        let validations = DeliveryStore.getValidations();
        let warning;
        // if form is not submited
        if (this.state.isFormSubmited) {
            // Show all rules except the required ones
            //validations = validations.filter(value => value.type !== 'required');
            warning = (<AlertsWarnings
                title={this.context.intl.messages['signup.form.error.message']}
                validations={validations} />);
        }
        let notifications = this.state.notifications.map((n, i) => {
            return <Notification disabled={this.props.disabled} index={i} key={i} notification={n}/>;
        });

        let selectedFileFormat = DeliveryStore.getFileFormatType(this.state.profile.get('fileFormat'));
        if (selectedFileFormat && selectedFileFormat.toJS) {
            selectedFileFormat = selectedFileFormat.toJS();
        }
        let selectedImageDeliveryFormat = DeliveryStore.getImageDeliveryType(this.state.profile.get('downloadRenditionType'));
        if (selectedImageDeliveryFormat && selectedImageDeliveryFormat.toJS) {
            selectedImageDeliveryFormat = selectedImageDeliveryFormat.toJS();
        }

        let requestAdditionalAccess;
        if (this.props.permissions.canRequestAdditionalAccess) {
            requestAdditionalAccess = (
                <>
                    <h3 className="padding-y-10">
                        <span className="fas fa-key" /> {this.context.intl.messages['account.tab.info.account-access']}
                    </h3>
                    <FormSection className="row form-wrapper">
                        <FormRow>
                            <p>{this.context.intl.messages['account.tab.info.request-additional-access.description']}</p>
                        </FormRow>
                        <FormRow>
                            <FormGroup>
                                <Button bsStyle="primary"
                                    className="bg-wb-blue"
                                    onClick={this.props.onShowAdditionalAccess}>
                                    {this.context.intl.messages['account.tab.info.request-additional-access']}
                                </Button>
                            </FormGroup>
                        </FormRow>
                    </FormSection>
                </>
            );
        }

        return (
            <>
                <h3 className="padding-y-10"><span className="fas fa-ruler-combined"/> {this.context.intl.messages['account.tab.delivery.delivery.title']}</h3>
                <div className="form-wrapper">
                    <form>
                        <FormRow>
                            <TransitionGroup
                                transitionName="alert"
                                transitionEnterTimeout={300}
                                transitionLeaveTimeout={300}>
                                {warning}
                            </TransitionGroup>
                        </FormRow>
                        <FormRow>
                            <FormGroup md={4}>
                                <ControlLabel>
                                    {this.context.intl.messages['account.tab.delivery.video-format']}
                                </ControlLabel>
                                <ReactSelect
                                    disabled={this.props.disabled}
                                    getOptionLabel={GetAttr('name')}
                                    getOptionValue={GetAttr('id')}
                                    isClearable={true}
                                    isMulti={false}
                                    onChange={this.handleSelectFileFormat}
                                    options={DeliveryStore.getState().get('fileFormats').toJS()}
                                    value={selectedFileFormat}
                                />
                            </FormGroup>
                            <FormGroup md={4}>
                                <ControlLabel>
                                    {this.context.intl.messages['account.tab.delivery.image-format']}
                                </ControlLabel>
                                <ReactSelect
                                    disabled={this.props.disabled}
                                    getOptionLabel={GetAttr('name')}
                                    getOptionValue={GetAttr('id')}
                                    isClearable={true}
                                    isMulti={false}
                                    onChange={this.handleSelectImageDeliveryFormat}
                                    options={DeliveryStore.getState().get('imageDeliveryFormats').toJS()}
                                    value={selectedImageDeliveryFormat}
                                />
                            </FormGroup>
                        </FormRow>
                        <FormRow>
                            <Button bsStyle="primary"
                                className="bg-wb-blue"
                                onClick={this.handleSave}
                            >
                                {this.context.intl.messages['account.tab.delivery.save']}
                            </Button>
                        </FormRow>
                    </form>
                </div>

                <h3><span className="glyphicon glyphicon-envelope"/> {this.context.intl.messages['account.tab.delivery.notifications.title']}</h3>
                <p>{this.context.intl.messages['account.tab.delivery.notifications.description']}</p>
                <div className="form-wrapper">
                    <form>
                        {notifications}
                        <Row>
                            <Col md={12}>
                                <Button bsStyle="primary" className="add-notification" onClick={this.handleAdd}>
                                    <i className="fa fa-plus"/>&nbsp;{this.context.intl.messages['account.tab.delivery.notifications.add']}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </div>

                {requestAdditionalAccess}

                <h3><i className="fa fa-file" aria-hidden="true"></i> {this.context.intl.messages['accounts.tab.delivery.metadata.exportSpec.title']}</h3>
                <div className="form-wrapper">
                    <form>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <ControlLabel>{this.context.intl.messages['accounts.tab.delivery.metadata.exportSpec.title']}</ControlLabel>
                                    <MetadataExportSpec
                                        disabled={this.props.disabled}
                                        onChange={this.getHandleSelect('metadataExportSpec')}
                                        value={this.state.profile.get('metadataExportSpec')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <ControlLabel>{this.context.intl.messages['accounts.edit.delivery.metadata.language']}</ControlLabel>
                                    <MetadataExportLanguage
                                        disabled={this.props.disabled}
                                        onChange={this.getHandleSelect('metadataExportPreferredLanguage')}
                                        value={this.state.profile.get('metadataExportPreferredLanguage')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Button bsStyle="primary"
                                className="bg-wb-blue"
                                onClick={this.handleSave}
                            >
                                {this.context.intl.messages['account.tab.metadata.save']}
                            </Button>
                        </Row>
                    </form>
                </div>
            </>
        );
    }

}

export default WithPermissions(Container.create(Delivery));
export {Delivery};
