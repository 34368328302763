/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Children, isValidElement, cloneElement} from 'react';
import {FormattedMessage} from 'react-intl';

import SeasonsSummary from './seasons-summary';
import DocumentTitle from '../../common/document-title';
import {MessagesContext} from '../../messages/messages-context';
import Pagination from '../../pagination';
import Preloader from '../../preloader';
import {RouterActions} from '../../router/router-actions';
import AssetsNavigation from '../asset/assets-navigation';
import {TitleConstants} from '../title-actions';

export default class AssetListPageSkeleton extends React.PureComponent {
    static get propTypes() {
        return {
            asideFiltersSlot: PropTypes.node,
            assetSeasonCounts: PropTypes.instanceOf(Immutable.List).isRequired,
            assetsSearchSlot: PropTypes.node,
            assetTypePath: PropTypes.string.isRequired,
            children: PropTypes.node,
            documentMessage: PropTypes.string.isRequired,
            getSelectedFacets: PropTypes.func.isRequired,
            handleQueryChanged: PropTypes.func.isRequired,
            hasFacetsSlot: PropTypes.bool,
            location: PropTypes.object.isRequired,
            modalsSlot: PropTypes.node,
            pageSizes: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
            seasonsMap: PropTypes.instanceOf(Immutable.Map).isRequired,
            showNavigationBlock: PropTypes.bool,
            showPreloader: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleId: PropTypes.number.isRequired,
            titleMessage: PropTypes.string.isRequired,
            totalCount: PropTypes.number.isRequired,
            upperFiltersSlot: PropTypes.node,
        };
    }

    static get defaultProps() {
        return {
            assetsSearchSlot: null,
            asideFiltersSlot: null,
            children: null,
            hasFacetsSlot: true,
            modalsSlot: null,
            showAsideFacetsBlock: true,
            showNavigationBlock: true,
            showPreloader: false,
            upperFiltersSlot: null,
        };
    }

    constructor(props) {
        super(props);

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleViewChanged = this.handleViewChanged.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {getSelectedFacets, handleQueryChanged, location, titleId} = this.props;

        const query = QueryString.parse(location.search);
        const prevQuery = QueryString.parse(prevProps.location.search);

        const facets = getSelectedFacets(query);
        const prevFacets = getSelectedFacets(prevQuery);

        if (
            query.offset !== prevQuery.offset ||
            query.size !== prevQuery.size ||
            query['sort-field-name'] !== prevQuery['sort-field-name'] ||
            query['sort-direction'] !== prevQuery['sort-direction'] ||
            titleId !== prevProps.titleId ||
            !facets.equals(prevFacets)
        ) {
            handleQueryChanged();
        }
    }

    static contextType = MessagesContext;

    handlePageChange(offset) {
        this.appendToQuery({offset});
    }

    handlePageSizeChange(size) {
        this.appendToQuery({
            offset: 0,
            size
        });
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.appendToQuery({
            'sort-field-name': sortFieldName,
            'sort-direction': sortDirection,
            'keep-scroll': true
        });
    }

    handleViewChanged(isGridView) {
        const view = isGridView ? 'grid' : 'list';
        this.appendToQuery({view});
    }

    appendToQuery(delta) {
        const {location} = this.props;
        const q = QueryString.parse(location.search);
        const query = {...q, ...delta};
        RouterActions.redirect(`${location.pathname}?${QueryString.stringify(query)}`);
    }

    renderContent(isGridView) {
        const {asideFiltersSlot, children, hasFacetsSlot} = this.props;
        const resultCN = isGridView ? 'display-results-grid' : 'display-results-table';

        const content = (
            <div className={ClassNames('display-results', resultCN)}>
                {Children.map(children, child => {
                    if (isValidElement(child)) {
                        return cloneElement(child, {onSortChange : this.handleSortChange});
                    }
                }) }
            </div>
        );

        if (hasFacetsSlot) {
            return (
                <>
                    <div className="col-md-3">{asideFiltersSlot}</div>
                    <div className="col-md-9">{content}</div>
                </>
            );
        }

        return <div className="col-md-12">{content}</div>;
    }

    render() {
        let assetsNavigationSlot, lowerPaginatorSlot, seasonSummarySlot;

        const {
            assetsSearchSlot, assetSeasonCounts, assetTypePath,
            documentMessage, location, modalsSlot, totalCount, title, titleMessage, pageSizes,
            seasonsMap, showNavigationBlock, showPreloader, upperFiltersSlot,
        } = this.props;

        const query = QueryString.parse(location.search);
        const offset = parseInt(query.offset, 10) || 0;
        const pageSize = parseInt(query.size, 10) || 80;
        const isGridView = query.view === 'grid';

        const contentSlot = this.renderContent(isGridView);

        if (totalCount > 0 && title.get('categoryGroup') === TitleConstants.TITLE_CATEGORY_GROUPS.SERIES) {
            seasonSummarySlot = (
                <div>
                    <div className="container" key="divider"><hr/></div>
                    <SeasonsSummary
                        assetTypePath={assetTypePath}
                        assetSeasonCounts={assetSeasonCounts}
                        key="seasonsSummary"
                        redirectParams={{cqs: query.cqs}}
                        seasonsMap={seasonsMap}
                        title={assetTypePath}
                    />
                </div>
            );
        }

        if (showNavigationBlock) {
            assetsNavigationSlot = (
                <AssetsNavigation
                    handlePageChange={this.handlePageChange}
                    handlePageSizeChange={this.handlePageSizeChange}
                    handleViewChange={this.handleViewChanged}
                    isGridView={isGridView}
                    offset={offset}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    pageSizes={pageSizes}>

                    {isGridView ? upperFiltersSlot : null}
                </AssetsNavigation>
            );
        }

        if (totalCount) {
            lowerPaginatorSlot = (
                <Pagination
                    className="margin-top-10 container text-center margin-bottom-10"
                    offset={offset}
                    onChange={this.handlePageChange}
                    size={pageSize}
                    total={totalCount}
                />
            );
        }

        return (
            <DocumentTitle message={documentMessage}>
                <div>
                    {modalsSlot}
                    <Preloader show={showPreloader} fixed className="text-primary">
                        <div className="container padding-all-20">
                            <div className="container">
                                <div className="D(f) Jc(sb) Flxw(w)">
                                    <h3 className="pull-left-sm-up margin-top-10 h2">
                                        <FormattedMessage id={titleMessage} />
                                    </h3>
                                    {assetsSearchSlot}
                                    <div className="hidden-md hidden-lg padding-y-10 W(100%)"/>
                                    {assetsNavigationSlot}
                                </div>
                                <hr/>
                                <div className="row">
                                    {contentSlot}
                                </div>
                            </div>
                            {lowerPaginatorSlot}
                            {seasonSummarySlot}
                        </div>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}
