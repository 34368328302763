/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {createSlice} from '@reduxjs/toolkit';
import {Brand} from '@wbdt-sie/brainiac-web-common';

import AdultSwimLogo from './adult-swim-logo.png';
import CartoonNetworkLogo from './cartoon-network-logo.svg';
import CartoonitoLogo from './cartoonito-logo.png';
import FormatsPortalLogo from './formats-logo.svg';
import HBOLogo from './hbo-logo.svg';
import HBOMaxLogo from './hbo-max-logo.svg';
import WBTVDPressLogo from './wb-press-logo.png';
import WBTVDLogo from './wbtvd-logo.png';

type BrandType = typeof Brand;
interface MultiBrandState {
    brands: BrandType[]
}

const initialState: MultiBrandState = {
    brands: [
        {
            clientId: 'FORMATS_PORTAL',
            defaultSiteUrl: 'https://formats.wbtvd.com/',
            description: 'Formats Portal to access https://formats.wbtvd.com/',
            displayName: 'Formats Portal',
            id: 11165,
            logoSourceS3Path: FormatsPortalLogo,
            name: 'Formats Portal',
            platformType: 'WEB',
            usageType: 'APPLICATION'
        }, {
            clientId: 'HBO_MAX_PORTAL',
            defaultSiteUrl: 'https://hbomax.wbtvd.com/',
            description: 'HBO Max Partner to access https://hbomax.wbtvd.com/',
            displayName: 'HBO Max',
            id: 11164,
            logoSourceS3Path: HBOMaxLogo,
            name: 'HBO Max',
            platformType: 'WEB',
            usageType: 'APPLICATION'
        }, {
            clientId: 'CARTOONITO_PRESS',
            defaultSiteUrl: 'https://cartoonito.wbtvd.com',
            description: 'Press Site for Cartoonito. https://cartoonito.wbtvd.com',
            displayName: 'Cartoonito Press Site',
            id: 11148,
            logoSourceS3Path: CartoonitoLogo,
            name: 'Cartoonito Press Site',
            platformType: 'WEB',
            usageType: 'APPLICATION'
        }, {
            clientId: 'CARTOON_NETWORK_PRESS',
            defaultSiteUrl: 'https://cartoonnetwork.wbtvd.com',
            description: 'Press Site for Cartoon Network. https://cartoonnetwork.wbtvd.com',
            displayName: 'Cartoon Network Press',
            id: 11139,
            logoSourceS3Path: CartoonNetworkLogo,
            name: 'Cartoon Network Press',
            platformType: 'WEB',
            usageType: 'APPLICATION'
        }, {
            clientId: 'ADULTSWIM_PRESS',
            defaultSiteUrl: 'https://adultswim.wbtvd.com',
            description: 'Press site for Adult Swim. https://adultswim.wbtvd.com',
            displayName: 'Adult Swim Press',
            id: 11035,
            logoSourceS3Path: AdultSwimLogo,
            name: 'Adult Swim Press',
            platformType: 'WEB',
            usageType: 'APPLICATION'
        }, {
            clientId: 'HBO',
            defaultSiteUrl: 'https://hbo.wbtvd.com/',
            description: 'HBO partner, access to https://hbo.wbtvd.com/\n\nContact: Chris Bell - as of 9/29/2020',
            displayName: 'HBO Press',
            id: 11032,
            logoSourceS3Path: HBOLogo,
            name: 'HBO',
            platformType: 'WEB',
            usageType: 'APPLICATION'
        }, {
            clientId: 'WBTVD_PRESS',
            defaultSiteUrl: 'https://press.wbtvd.com',
            description: 'Press site at press.wbtvd.com',
            displayName: 'WBTVD Press',
            id: 11026,
            logoSourceS3Path: WBTVDPressLogo,
            name: 'WBTVD Press site',
            platformType: 'WEB',
            usageType: 'APPLICATION'
        }, {
            clientId: 'WBTVD',
            defaultSiteUrl: null,
            description: 'Public website for WBTVD.\nNOTE: NEVER set catalogs for this partner.',
            displayName: 'WBTVD',
            id: 11007,
            logoSourceS3Path: WBTVDLogo,
            name: 'WBTVD',
            platformType: 'WEB',
            usageType: 'APPLICATION'
        }
    ]
};

export const MultiBrandSlice = createSlice({
    initialState,
    name: 'multiBrand',
    reducers: {}
});
