/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {isEpisode, isEpisodeFromMiniseries, GetDeliverableFormatData, GetVideoDisplayName} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import VideoToolbar from './video-toolbar';
import {AssetTypeConstants} from '../../../asset-types/asset-type-constants';
import {MessagesContext} from '../../../messages/messages-context';
import {NotificationActions} from '../../../notification/notification-actions';
import {PlayerActions} from '../../../player/player-actions';
import Preloader from '../../../preloader';
import SessionStore from '../../../session/session-store';
import {GetThumbnail, IsNotTriggerKey, FormatReleaseDate} from '../../../utils/utils';
import SerieNavigationStore from '../../serie-navigation/serie-navigation-store';
import {TitleConstants} from '../../title-actions';
import {AssetTitleConstants} from '../asset-title-actions';
import ImageLoader, {ImageLoaderPlaceholders} from '../images/image-loader';


export class VideoAssetFormatLabel extends React.Component {
    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    static contextType = MessagesContext;

    render() {
        let data = GetDeliverableFormatData(this.props.asset);

        return <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip>{this.context.intl.messages[data.tooltip]}</Tooltip>
            }>
            <span className={data.className}>{this.context.intl.messages[data.text]}</span>
        </OverlayTrigger>;
    }
}

export default class VideoThumbnail extends React.Component {
    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool,
            canInstantOrderVideos: PropTypes.bool,
            showAirDate: PropTypes.bool,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map),
            titleId: PropTypes.number.isRequired,
            video: PropTypes.instanceOf(Immutable.Map).isRequired,
        };
    }

    static get defaultProps() {
        return {
            canAddToCart: false,
            canInstantOrderVideos: false,
            showAirDate: false,
            title: Immutable.Map()
        };
    }

    constructor(props) {
        super(props);

        this.handlePlay = this.handlePlay.bind(this);
    }

    static contextType = MessagesContext;

    handlePlay(event) {
        event.preventDefault();

        if (IsNotTriggerKey(event)) {
            return;
        }

        NotificationActions.clearQueue();
        PlayerActions.showPlayer(this.props.video, undefined, this.props.titleId);
    }

    render() {
        if (!this.props.video) {return null;}

        let thumbnail = GetThumbnail(this.props.video.get('thumbnails'), 400);
        let image;
        if (thumbnail) {
            image = thumbnail.get('thumbnailUrl');
        }

        let runtime;
        if (this.props.video.get('runtime')) {
            runtime = `(${this.props.video.get('runtime')})`;
        }

        let cornerCount;
        let stack = (<div className="video-stack-container"></div>);

        if (this.props.video.get('childStackAssetCount') > 0 ) {
            cornerCount = (
                <div className="video-stack-count">
                    {this.props.video.get('childStackAssetCount') + 1}&nbsp;{this.context.intl.messages['title.video-list.associated-videos']}
                </div>
            );
            stack = (
                <div className="video-stack-container">
                    <div className="video-stack video-stack-top"></div>
                    <div className="video-stack video-stack-bottom"></div>
                </div>
            );
        }

        let episodeNumber;
        const where = this.props.serieNavigation.getIn(['serieMap', `${this.props.video.get('titleId').toString()}`]);

        if (where) {
            if (isEpisodeFromMiniseries(where) && this.props.serieNavigation.get('episodes')) {
                let episode = this.props.serieNavigation.get('episodes').find(e => e.get('childTitleId') === this.props.video.get('titleId')) || Immutable.Map();
                episodeNumber = episode.get('orderWithinParent');
            } else if (isEpisode(where)) {
                episodeNumber = this.props.serieNavigation.getIn(['seasons', where.get(0), 'episodes', where.get(1), 'episodeNumber']);
            } else {
                episodeNumber = this.props.serieNavigation.getIn([
                    'serieMap',
                    this.props.video.get('titleId').toString(),
                    1
                ]);
                if (episodeNumber !== undefined) {
                    episodeNumber += 1;
                }
            }
        }

        const assetDisplayName = GetVideoDisplayName(this.props.video);

        let episodeNumberElement;
        if (episodeNumber !== undefined && this.props.titleId !== this.props.video.get('titleId')) {
            let assetDisplayNameMargin = ((assetDisplayName.length / 25) * 18) + 55; //add margin bottom based on assetDisplayName length
            episodeNumberElement = (
                <h4 className="pull-left margin-right-20 margin-bottom-40" style={{marginBottom: `${assetDisplayNameMargin}px`}}>{episodeNumber}</h4>
            );
        }

        let isNew;
        let createdDate = this.props.video.get('createdDate');
        if (createdDate) {
            createdDate = new Date(createdDate);
            let twoWeeksDuration = 1000 * 60 * 60 * 24 * 14;
            let twoWeeksBeforeNow = new Date(new Date() - twoWeeksDuration);
            if (createdDate > twoWeeksBeforeNow) {
                isNew = (
                    <div className="video-new-label"></div>
                );
            }
        }

        let seasonNumber = 0;
        if (this.props.video.get('contentType').toString() === AssetTitleConstants.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.TYPES.FULL_EPISODE) {
            const seasons = this.props.serieNavigation.get('seasons');
            const sanData = SerieNavigationStore.detectSeasonAndEpisodeNumbers(seasons, this.props.video.get('titleId'));
            seasonNumber = sanData.seasonNumber;
        }

        let episodeDisplay;
        if (episodeNumber && seasonNumber) {
            episodeDisplay = (
                <div>
                    <em>{this.context.intl.messages['list-assets.episode.title']} <span className="gray-text">{`S${seasonNumber}E${episodeNumber.toString().padStart(2, '0')}`}</span></em>
                </div>
            );
        }

        let mfaRequired;
        if (this.props.video.get('mfaRequired')) {
            mfaRequired = <><i className="fas fa-lock-alt"></i>&nbsp;</>;
        }

        let childStackAssetCount = 'video-play-single';
        if (this.props.video.get('childStackAssetCount') > 0) {
            childStackAssetCount = 'video-play-list';
        }

        let airDateFormatted;

        if (this.props.showAirDate && !!this.props.video.get('releaseDate')) {
            let airDate;
            if (this.props.title.get('titleReleaseDateType') === TitleConstants.RELEASE_DATE_TYPES.DISPLAY_NAME.id) {
                airDate = FormatReleaseDate(this.props.title.get('titleReleaseDateDisplayName'), this.props.title.get('titleReleaseDateType'), '');
            } else {
                airDate = FormatReleaseDate(Moment(this.props.video.get('releaseDate').split('T')[0]), this.props.title.get('titleReleaseDateType'));
            }


            airDateFormatted = (
                <div>{airDate}</div>
            );
        }

        /* Determine playback restriction banners. Note: the order here is important, as it sets prioritization for banner message shown per BRAIN-4580 */
        const assetRestrictions = this.props.video.get('assetRestrictions').toJS();
        let videoPlaybackLabel, playIsDisabled = false;

        // Plays remaining / Play limit exceeded
        if (assetRestrictions.maxNumberOfView > 0) {
            switch (true) {
            case (assetRestrictions.currentUserNumberOfView >= assetRestrictions.maxNumberOfView):
                videoPlaybackLabel = (
                    <div className="video-playback-number red">
                        <i className="fas fa-video-slash"></i>&nbsp;{this.context.intl.messages['title.video-list.play-limit-exceeded']}
                    </div>
                );
                playIsDisabled = true;
                break;
            case (assetRestrictions.currentUserNumberOfView < assetRestrictions.maxNumberOfView):
                videoPlaybackLabel = (
                    <div className="video-playback-number">
                        <i className="fas fa-video"></i>&nbsp;{assetRestrictions.maxNumberOfView - assetRestrictions.currentUserNumberOfView}&nbsp;{this.context.intl.messages['title.video-list.plays-remaining']}
                    </div>
                );
                break;
            }
        }

        // IP address restriction
        if (assetRestrictions.isRestrictByIp && assetRestrictions.currentUserAllowedNumberOfIp > 0 && (assetRestrictions.currentUserNumberOfIpUsed >= assetRestrictions.currentUserAllowedNumberOfIp)) {
            playIsDisabled = false; // The player needs to decide if we can play when we get the stream, due to case of a 1 ip limit when 1 ip used.
            videoPlaybackLabel = (
                <div className="video-playback-number red">
                    <i className="fas fa-video-slash"></i>&nbsp;{this.context.intl.messages['title.video-list.ip-address-restricted']}
                </div>
            );
        }

        // Unavailable on this platform
        const partner = SessionStore.getPartner();
        if (assetRestrictions.partnerIds.length && !assetRestrictions.partnerIds.includes(partner.id)) {
            playIsDisabled = true;
            videoPlaybackLabel = (
                <div className="video-playback-number red">
                    <i className="fas fa-video-slash"></i>&nbsp;{this.context.intl.messages['title.video-list.unavailable-on-this-platform']}
                </div>
            );
        }

        let videoToolbar;
        if (!playIsDisabled) {
            videoToolbar = (
                <>
                    <VideoToolbar
                        canAddToCart={this.props.canAddToCart}
                        canInstantOrderVideos={this.props.canInstantOrderVideos}
                        video={this.props.video}
                        titleId={this.props.titleId}
                    />

                    <div
                        className={ClassNames('video-play', childStackAssetCount)}
                        onClick={this.handlePlay}
                        onKeyUp={this.handlePlay}
                        role="button"
                        tabIndex="0">
                        <span className="glyphicon glyphicon-play-circle hover-play-icon" aria-label="Play Video"/>
                    </div>
                    <div className="video-thumbnail-overlay" />
                </>
            );
        }

        return (
            <div className="col-sm-6 padding-all-10">
                {stack}
                <div className="video-thumbnail" ref={this.logSize}>
                    {isNew}
                    {cornerCount}
                    <Preloader
                        bsSize="sm"
                        fixed
                        show={this.props.video.get('thumbnails') === undefined}
                    >
                        {videoToolbar}
                        <ImageLoader
                            alt={assetDisplayName}
                            className="img-responsive"
                            placeholder={ImageLoaderPlaceholders.HORIZONTAL}
                            src={image}
                        />
                    </Preloader>
                    {videoPlaybackLabel}
                </div>
                <p className="video-thumbnail-info"></p>
                {episodeNumberElement}
                <a
                    className="video-play-single Cur(p)"
                    onClick={this.handlePlay}
                    onKeyUp={this.handlePlay}
                    role="button"
                    tabIndex="0"
                    style={{wordBreak:'break-word'}}
                >
                    {mfaRequired}{assetDisplayName}
                </a> {runtime}
                <br/>
                <em>{AssetTypeConstants.VIDEO_CONTENT_TYPE[this.props.video.get('contentType')].description}&nbsp;</em>
                <VideoAssetFormatLabel asset={this.props.video} />
                {episodeDisplay}
                {airDateFormatted}
            </div>
        );
    }
}
