/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';

import SingleTitleStore from './single-title-store';
import WithPermissions from '../../decorators/with-permissions';
import Preloader from '../../preloader';
import SessionStore from '../../session/session-store';
import Header from '../../titles/header';
import TitleInfo from '../../titles/info';
import Nav from '../../titles/nav';
import {TitleActions, TitleConstants} from '../../titles/title-actions';
import TitleStore from '../../titles/title-store';

const TitleInfoPage = WithPermissions(TitleInfo);

class SingleTitle extends React.Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            genres: TitleStore.getState().get('genres'),
            networks: TitleStore.getState().get('networks'),
            thumbnails: TitleStore.getState().get('thumbnails'),
            title: TitleStore.getState().get('title'),
            titleId: SingleTitleStore.getState().get('titleId')
        };
    }

    static getStores() {
        return [SingleTitleStore, TitleStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.titleId === undefined) {
            let userId = SessionStore.getState().getIn(['authUser', 'id']);
            TitleActions.get(
                this.state.titleId,
                userId,
                SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_LANGUAGE_AVAILABILITY)
            );
        }
        return;
    }

    componentWillUnmount() {
        if (!SessionStore.isSingleTitle()) {
            // When we have a single title we don't need to clear. This fixes an endless preloader on home logo click.
            setTimeout(() => TitleActions.clear());
        }
    }

    getNames(List) {
        return List.map(item => item.get('name')).join(', ');
    }

    render() {
        if (!this.state.title.get('id')) {
            return (<Preloader fixed className="text-primary"/>);
        }

        let title = this.state.title;
        let releaseDate = title.get('titleReleaseDate');
        let releaseDateType = title.get('titleReleaseDateType');

        return (
            <div className={
                ClassNames({
                    'title-beetlejuice': title.get('id') === TitleConstants.BEETLEJUICE,
                    'title-elf': title.get('id') === TitleConstants.ELF
                })
            }>
                <Header
                    genres={this.state.genres}
                    location={this.props.location}
                    networks={this.getNames(this.state.networks)}
                    releaseDate={releaseDate}
                    releaseDateType={releaseDateType}
                    serieNavigation={this.state.serieNavigation}
                    title={title}
                />
                <Nav
                    location={this.props.location}
                    title={title}
                />
                <Switch>
                    <Route
                        component={TitleInfoPage}
                    />
                </Switch>
            </div>
        );
    }

}

export default Container.create(SingleTitle);
export {SingleTitle};
