/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Grid, Row} from 'react-bootstrap';

import Thumbnail from './thumbnail';
import Pagination from '../../pagination';
import {BreakIntoGroups} from '../../utils/utils';

const List = ({titles, onPageChange, offset, size, totalPages}) => {
    const thumbnails = BreakIntoGroups(titles, 4).map((group, i) => {
        return (
            <Row key={i}>
                <Col md={12} lg={6}>
                    <Row>
                        <Thumbnail
                            item={group.get(0)}
                        />
                        <Thumbnail
                            item={group.get(1)}
                        />
                    </Row>
                </Col>
                <Col md={12} lg={6}>
                    <Row>
                        <Thumbnail
                            item={group.get(2)}
                        />
                        <Thumbnail
                            item={group.get(3)}
                        />
                    </Row>
                </Col>
            </Row>
        );
    });

    return (
        <div>
            <Grid className="list">
                {thumbnails}
            </Grid>
            <hr className="margin-top-0"/>
            <div className="text-center margin-bottom-40">
                <Pagination
                    offset={offset}
                    onChange={onPageChange}
                    size={size}
                    total={totalPages}
                />
            </div>
        </div>
    );
};

List.propTypes = {
    offset: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
    titles: PropTypes.instanceOf(Immutable.List).isRequired,
    totalPages: PropTypes.number.isRequired,
};

export default List;
