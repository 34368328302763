/* istanbul ignore file */
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

/* eslint-disable import/order */
import {DatadogClient} from '@wbdt-sie/brainiac-web-common'; // Analytics and diagnostics.
import React, {Suspense, useEffect} from 'react';
import {name as packageName, version as packageVersion} from '../package.json';
import Config from './config/config';

// Polyfills and global utils.
import './utils/polyfills';

// React dependencies.
import {render} from 'react-dom';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import Scroll from 'react-scroll';

/* TODO: check if this is still needed since Datadog is supposed to provide Analytics and diagnostics as well */
import Analytics from './analytics'; // Analytics and diagnostics.

import {IntlProvider} from 'react-intl';
import {IntlProviderWrapper} from './messages/intl-provider-wrapper';

import QueryString from 'query-string';

// Router helpers
import {BrowserHistory} from './router/router-actions';
import RouterHelpers from './router/router-helpers';

// WBTVD Client components.
import ForgotPassword from './login/forgot-password';
import ForgotPasswordConfirm from './login/forgot-password/confirm';
import ResetPassword from './login/reset-password';
import OneTimeLogin from './login/one-time-login';
import LoginLink from './login/one-time-login/login-link';
import SignUpLayout from './signup/layout';
import SignUpFyc from './signup/signup-fyc';
import TermsOfUse from './login/terms-of-use';

// Declare ALL stores here so that they are included in the main bundle.
// https://github.com/reduxjs/redux/issues/37
// -- Currently Redux forces you to declare all Stores at the root. This is sensible
// -- because if you register them lazily as components subscribe to them, some Stores
// -- might miss some actions. This is totally not obvious and fragile.
// This happened with WorkOrders, the Add to Work Order Modal dispatched an action,
// but the store wasn't there so it missed the action object.
/* eslint-disable no-unused-vars */
import SearchStore from './search/search-store.js';
import SessionStore from './session/session-store.js';
import ContactSelectShow from './signup/contact-select/contact-select-store.js';
import AccountStore from './account/account-store.js';
import SupportStore from './account/support/support-store.js';
import DeliveryStore from './account/tabs/delivery/delivery-store.js';
import NotificationStore from './notification/notification-store.js';
import OrderStore from './orders/order-store.js';
import PartnerStore from './partner/partner-store.js';
import PlayerStore from './player/player-store.js';
import Preauthenticated from './preauthenticated/preauthenticated';
import PreauthenticatedStore from './preauthenticated/preauthenticated-store.js';
import SignupStore from './signup/signup-store.js';
import SwaggerStore from './swagger/swagger-store.js';
import TalentStore from './talent/talent-store.js';
import TitlelanguageAvailabilityStore from './title-language-availability/title-language-availability-store.js';
import AssetTitleStore from './titles/asset-title/asset-title-store.js';
import ClipStore from './titles/clip/clip-store.js';
import CompanyStore from './lookup/company/company-store.js';
import CurrencyStore from './lookup/currency/currency-store.js';
import DownloadHistoryStore from './account/tabs/download-history/download-history-store.js';
import DownloadStore from './common/download/download-store.js';
import FooterStore from './layout/footer/footer-store.js';
import GenreStore from './lookup/genre/genre-store.js';
import HelpStore from './help/help-store.js';
import HomeStore from './home/home-store.js';
import LandingStore from './layout/landing/landing-store.js';
import LanguageStore from './lookup/language/language-store.js';
import ListDocumentsStore from './list-documents/list-documents-store.js';
import LookupStore from './lookup/lookup-store.js';
import MerchandiseStore from './titles/merchandise/merchandise-store.js';
import PageLayoutStore from './layout/page-layout/page-layout-store.js';
import SavedSearchesStore from './account/tabs/saved-searches/saved-searches-store.js';
import SerieNavigationStore from './titles/serie-navigation/serie-navigation-store.js';
import ShareAssetStore from './titles/share-asset-store.js';
import SubscribedContentStore from './account/tabs/subscribed-content/subscribed-content-store.js';
import SubscriptionStore from './account/tabs/subscriptions/subscription-store.js';
import TerritoryStore from './lookup/territory/territory-store.js';
import ThemeStore from './lookup/theme/theme-store.js';
import TitleCatalogStore from './lookup/title-catalog/title-catalog-store.js';
import TitleStore from './titles/title-store.js';
import UserCompanyStore from './lookup/user-company/user-company-store.js';
import VamStore from './list-documents/vam/vam-store.js';
import WorkOrderStore from './work-orders/work-order-store';
import DigitalSalesDecksStore from './dsd/dsd-store.js';
import Store from './ts/redux/store';
/* eslint-enable  no-unused-vars */

// Require third party styles and fonts.
// font awesome
import './styles/app/fontawesome/fontawesome.css';
import './styles/app/fontawesome/regular.css';
import './styles/app/fontawesome/solid.css';

// Include favicons in build output.
import '../static/wbd-favicon-16.png';
import '../static/wbd-favicon-32.png';
import '../static/wbd-favicon-128.png';
import '../static/wbd-favicon-180.png';
import '../static/wbd-favicon-192.png';

// Require for Proper Timezone Display
import 'moment-timezone';

// Require bootstrap styles
import './styles/bootstrap/bootstrap.less';
import './styles/app/main.less';

// Start all analytics platforms.
Analytics.init();
DatadogClient.init(
    {name: packageName, version: packageVersion},
    Config
);

import PageLayoutWithRouter from './layout/page-layout';
const TermsOfUseWithRouter = RouterHelpers(TermsOfUse);
const ForgotPasswordWithRouter = RouterHelpers(ForgotPassword);
const ForgotPasswordConfirmWithRouter = RouterHelpers(ForgotPasswordConfirm);
import Login from './login/login-form';
const ResetPasswordWithRouter = RouterHelpers(ResetPassword);
const OneTimeLoginWithRouter = RouterHelpers(OneTimeLogin);
const LoginLinkWithRouter = RouterHelpers(LoginLink);
const SignUpLayoutWithRouter = RouterHelpers(SignUpLayout);
const SignUpFycWithRouter = RouterHelpers(SignUpFyc);

// Relocated entry points
import DigitalSalesDecks from './dsd/dsd.js';
import MultiBrand from './ts/multi-brand';
import {Provider} from 'react-redux';

const WBTVDApp = function() {
    useEffect(() => {
        const query = QueryString.parse(location.search);
        if (query['keep-scroll'] !== undefined) {
            return;
        }

        Scroll.animateScroll.scrollToTop();

        return;
    });

    return (
        <Suspense fallback={<div></div>}>
            <Provider store={Store}>
                <IntlProviderWrapper>
                    <Switch>
                        <Route path="/dsd/:id/:titleId" component={DigitalSalesDecks} />
                        <Route path="/dsd/:id" component={DigitalSalesDecks} />
                        <Route path="/ts/brands-hub" render={() => (<MultiBrand/>)}/>

                        <Route exact path="/activate-device" render={() => {
                            // This is for Apple devices. Not using <Redirect/>
                            // because we don't want React to handle this route.
                            window.location.href = `${window.location.origin}/page/app/index.html`;
                        }}/>
                        <Route path="/first-time" component={TermsOfUseWithRouter} />

                        <Route path="/password-reset" component={ForgotPasswordWithRouter} />
                        <Route exact path="/forgot-password">
                            <Redirect to="/password-reset" />
                        </Route>

                        <Route path="/password-reset-confirm" component={ForgotPasswordConfirmWithRouter} />
                        <Route exact path="/forgot-password-confirm">
                            <Redirect to="/password-reset-confirm" />
                        </Route>


                        <Route path="/login" component={Login} />

                        <Route path="/pre/:token" component={Preauthenticated}/>;
                        <Route path="/reset-password" component={ResetPasswordWithRouter} />
                        <Route path="/one-time-login" component={OneTimeLoginWithRouter} />
                        <Route path="/login-link" component={LoginLinkWithRouter} />
                        <Route path="/terms-of-use" component={TermsOfUseWithRouter} />
                        <Route path="/sign-up" component={SignUpLayoutWithRouter} />
                        <Route path="/signup-fyc" component={SignUpFycWithRouter} />

                        {
                            // The following is routing all private routes,
                            // partly because they all share the same layout.
                        }
                        <Route path="/" component={PageLayoutWithRouter}/>
                    </Switch>
                </IntlProviderWrapper>
            </Provider>
        </Suspense>
    );
};

// Render our router to start.
render(
    <IntlProvider locale="en">
        <Router history={BrowserHistory}>
            <Route path="/" component={WBTVDApp}/>
        </Router>
    </IntlProvider>,
    document.querySelector('#wbtvd-app')
);
