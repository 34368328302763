/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Glyphicon} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {PROPERTIES} from './constants';
import LoadingCard from './loading-card';
import ResultContent from './result-content';
import ResultSkeleton from './result-skeleton';
import GetImageUrl from './utils/get-image-url';
import GetIsImageLoading from './utils/get-is-image-loading';
import {PlayerActions} from '../../player/player-actions';
import ImageLoader from '../../titles/asset-title/images/image-loader';
import {TitleConstants} from '../../titles/title-actions';

export default class Index extends React.Component {
    static get propTypes() {
        return {
            thumbnails: PropTypes.instanceOf(Immutable.Map).isRequired,
            type: PropTypes.oneOf(['documents', 'episodes', 'images', 'moments', 'loading', 'talent', 'titles', 'videos']).isRequired,
            value: PropTypes.instanceOf(Immutable.Map),
        };
    }

    static get defaultProps() {
        return {
            value: null,
        };
    }

    constructor(props) {
        super(props);

        this.handlePlay = this.handlePlay.bind(this);
    }

    shouldComponentUpdate(nextProps) {
        // Get the current image url and compare it with the next one.
        let imageUrl = GetImageUrl(this.props.value, this.props.thumbnails, this.props.type);
        let nextImageUrl = GetImageUrl(nextProps.value, nextProps.thumbnails, nextProps.type);

        if (imageUrl !== nextImageUrl ||
            this.props.value !== nextProps.value ||
            GetIsImageLoading(
                nextProps.value,
                nextProps.thumbnails,
                nextProps.type,
            ) !== GetIsImageLoading(
                nextProps.value,
                nextProps.thumbnails,
                nextProps.type,
            )) {
            return true;
        }

        return false;
    }

    getFormattedName(title, resultName) {
        if (!title) {
            return resultName;
        }

        let categoryGroup = Object.keys(TitleConstants.TITLE_TYPES).reduce((c, t) => {
            if (TitleConstants.TITLE_TYPES[t].id === title.get('category')) {
                return TitleConstants.TITLE_TYPES[t].categoryGroup;
            }
            return c;
        }, undefined);

        switch (categoryGroup) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            let seasonLabel;
            if (title.get('season')) {
                seasonLabel = <> - <i>{`Season ${title.get('season')}`}</i></>;
            }
            resultName = <>{resultName}{seasonLabel}</>;
            break;

        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            let parentLabel;
            if (title.get('parentTitleDisplayName') || title.get('fullRunningOrder')) {
                parentLabel = <> - <i>{title.get('parentTitleDisplayName') || ''} {title.get('fullRunningOrder') || ''}</i></>;
            }
            resultName = <>{resultName}{parentLabel}</>;
            break;

        case TitleConstants.TITLE_CATEGORY_GROUPS.SINGLE_RELEASE:
            if (title.get('category') === TitleConstants.TITLE_TYPES.THEATRICAL_FEATURES.id || title.get('category') === TitleConstants.TITLE_TYPES.ANIMATED_SPECIALS.id) {
                resultName = <i>{resultName}</i>;
            }
            break;
        }

        return resultName;
    }

    detectResultName() {
        const {type, value: asset} = this.props;
        let resultName = asset.get(PROPERTIES[type].name);

        if (type === 'titles' || type === 'episodes') {
            resultName = this.getFormattedName(asset, resultName);
        }
        return resultName;
    }

    // handlePlay is called only for videos and clips.
    handlePlay(event) {
        event.preventDefault();
        // The PlayerActions.showPlayer method is expecting an object with assetId.
        // Set it up so that it doesn't break.
        let video = this.props.value.set('assetId', this.props.value.get('id'));
        // Also, add the thumbnails so that we don't fetch them again.
        let imageId = this.props.value.get(PROPERTIES[this.props.type].image);
        video = video.set('thumbnails', Immutable.List.of(this.props.thumbnails.get(imageId.toString())));
        PlayerActions.showPlayer(video, undefined, this.props.value.getIn(['browseTitleList', 0, 'id']));
    }

    render() {
        if (this.props.type === 'loading') {
            return <LoadingCard className="col-xs-6 col-sm-3 thumbnail-container" />;
        }

        if (!this.props.value) {
            return null;
        }

        const imageUrl = GetImageUrl(this.props.value, this.props.thumbnails, this.props.type);

        let uri = `/titles/${this.props.value.get('id')}`;
        let icon;
        const titleId = this.props.value.getIn(['browseTitleList', 0, 'id']);
        switch (this.props.type) {
        case 'talent':
            uri = `/talent/${this.props.value.get('talentId')}`;
            break;

        case 'videos':
            icon = <Glyphicon glyph="play-circle" className="thumbnail-play-icon" onClick={this.handlePlay}/>;
            // Fallthrough!
        case 'images':
            uri = `/titles/${titleId}/${this.props.type}/${this.props.value.get('id')}`;
            break;

        case 'documents':
            uri = `/titles/${titleId}/documents`;
            break;
        }

        const resultName = this.detectResultName();

        return (
            <ResultSkeleton
                className="col-xs-6 col-sm-3 thumbnail-container Cur(p)"
                isImageLoading={GetIsImageLoading(
                    this.props.value,
                    this.props.thumbnails,
                    this.props.type,
                )}
            >
                <Link to={uri}>
                    <ResultContent>
                        <ImageLoader
                            alt=""
                            className="img-responsive"
                            src={imageUrl}
                        />
                        {icon}
                    </ResultContent>
                    <div className="thumbnail-title Wb(bw)">{resultName}</div>
                </Link>
            </ResultSkeleton>
        );
    }
}
