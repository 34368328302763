/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import ClipThumbnail from './clip-thumbnail';
import ClipsGrid from '../../../common/clips/clips-grid';
import ClipsList from '../../../common/clips/clips-list';
import {enrichClipsViaSerieNavigation} from '../../../common/clips/converters';
import SerieNavigationStore from '../../serie-navigation/serie-navigation-store';
import {TitleConstants} from '../../title-actions';

export default class ClipsResult extends React.PureComponent {
    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool.isRequired,
            clips: PropTypes.instanceOf(Immutable.List).isRequired,
            isGridView: PropTypes.bool.isRequired,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleId: PropTypes.number.isRequired,
        };
    }

    render() {
        const {canAddToCart, isGridView, serieNavigation, title, titleId} = this.props;
        const seasons = serieNavigation.get('seasons', Immutable.List());
        const episodes = serieNavigation.get('episodes', Immutable.List());
        const clips = enrichClipsViaSerieNavigation(this.props.clips, seasons);
        let blockTitle = null, count = 0, content;
        const categoryGroup = title.get('categoryGroup');
        if (categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SEASON) {
            blockTitle = <FormattedMessage id="list-assets.moments.season" values={{season: title.get('season')}} />;
        } else if (categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE) {
            if (seasons.size) {
                const sat = SerieNavigationStore.detectSeasonAndEpisodeNumbers(seasons, title.get('id'));
                blockTitle = (
                    <>
                        <FormattedMessage id="list-assets.moments.season" values={{season: sat.seasonNumber || ''}} />
                        &nbsp;
                        <FormattedMessage id="list-assets.moments.episode" values={{episode: sat.episodeNumber || ''}} />
                    </>
                );
            } else {
                const episode = episodes.find((e) => e.get('childTitleId') === titleId);
                if (episode) {
                    blockTitle = (
                        <FormattedMessage id="list-assets.moments.episode" values={{episode: episode.get('orderWithinParent')}} />
                    );
                }
            }
        } else if (categoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SERIES && seasons.size) {
            blockTitle = <FormattedMessage id="list-assets.series.title" />;
            count = seasons.size;
        }

        let countBlock = null;
        if (count) {
            countBlock = (
                <small>
                    (<FormattedMessage id="list-assets.moments.seasons" values={{count}} />)
                </small>
            );
        }

        if (isGridView) {
            content = (
                <ClipsGrid
                    clips={clips}
                    renderClipItem={(clip) => <ClipThumbnail titleId={titleId} canAddToCart={canAddToCart} clip={clip} />}
                />);
        } else {
            content = (<ClipsList canAddToCart={canAddToCart} clips={clips} titleId={titleId} />);
        }

        return (
            <>
                <h3>{blockTitle} {countBlock}</h3>
                {content}
            </>
        );
    }
}
