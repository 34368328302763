/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

export default class PressSiteContacts extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.press-site-contacts">
                <div className="container padding-y-10 padding-x-20">
                    <div className="row">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="row">
                        <table className="table cart-parent-table">
                            <thead>
                                <tr>
                                    <th scope="col" className="no-sort">
                                        NAME
                                    </th>
                                    <th scope="col" className="no-sort">
                                        TITLE
                                    </th>
                                    <th scope="col" className="no-sort">
                                        PHONE
                                    </th>
                                    <th scope="col" className="no-sort">
                                        EMAIL
                                    </th>
                                    <th scope="col" className="no-sort">
                                        SHOWS
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <a href="mailto:jeff.tobler@warnerbros.com">Jeff Tobler</a>
                                    </td>
                                    <td>SVP, Television Publicity & Communications</td>
                                    <td >818-954-5062</td>
                                    <td>
                                        <a href="mailto:jeff.tobler@warnerbros.com">
                                            Jeff.Tobler@warnerbros.com
                                        </a>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href="mailto:winson.seto@warnerbros.com">Winson Seto</a>
                                    </td>
                                    <td>Senior Publicist</td>
                                    <td >818-954-7674</td>
                                    <td>
                                        <a href="mailto:winson.seto@warnerbros.com">
                                            Winson.Seto@warnerbros.com
                                        </a>
                                    </td>
                                    <td>
                                        Titans
                                        <br />
                                        Young Justice: Outsiders
                                        <br />
                                        Doom Patrol
                                        <br />
                                        Swamp Thing
                                        <br />
                                        Harley Quinn
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href="mailto:remi.martin@warnerbros.com">Remi Martin</a>
                                    </td>
                                    <td>Manager, WW TV Publicity</td>
                                    <td >818-954-7361</td>
                                    <td>
                                        <a href="mailto:remi.martin@warnerbros.com">
                                            Remi.Martin@warnerbros.com
                                        </a>
                                    </td>
                                    <td>Stargirl</td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        <a href="mailto:wbtvd@warnerbros.com">ACCOUNT SUPPORT</a>
                                    </td>
                                    <td>
                                        <a href="mailto:wbtvd@warnerbros.com">wbtvd@warnerbros.com</a>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <h1>DC Publicity</h1>
                    </div>
                    <div className="row">
                        <table className="table cart-parent-table">
                            <thead>
                                <tr>
                                    <th scope="col" className="no-sort">
                                        NAME
                                    </th>
                                    <th scope="col" className="no-sort">
                                        PHONE
                                    </th>
                                    <th scope="col" className="no-sort">
                                        EMAIL
                                    </th>
                                    <th scope="col" className="no-sort"></th>
                                    <th scope="col" className="no-sort control icon-column"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <a href="mailto:carrie.williams@dcentertainment.com">
                                            Carrie Williams
                                        </a>
                                    </td>
                                    <td >818-977-1314</td>
                                    <td>
                                        <a href="mailto:carrie.williams@dcentertainment.com">
                                            Carrie.Williams@dcentertainment.com
                                        </a>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href="mailto:kelley.popham@dcentertainment.com">Kelley Popham</a>
                                    </td>
                                    <td >818-977-7527</td>
                                    <td>
                                        <a href="mailto:kelley.popham@dcentertainment.com">
                                            kelley.popham@dcentertainment.com
                                        </a>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </DocumentTitle>

        );
    }
}
