/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import PropTypes from 'prop-types';
import React from 'react';

export default function AssetNavigationItem({children, showDivider}) {
    return (
        <>
            <hr className="visible-xs-block" />
            <div className="visible-xs-block visible-inline-block-sm-up text-center D(f) Ai(c)">
                {children}
            </div>
            {showDivider && <span className="vertical-divider hidden-xs"/>}
        </>
    );
}

AssetNavigationItem.propTypes = {
    children: PropTypes.node.isRequired,
    showDivider: PropTypes.bool,
};

AssetNavigationItem.defaultProps = {
    showDivider: true,
};
