const selectStyles = {
    control: (provided) => ({
        ...provided,
        width: '100%',
        borderRadius: 0,
        minHeight: 30,
        cursor: 'text',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        display: 'none',
    }),
    placeholder: () => ({
        display: 'none',
    }),
    input: (provided) => ({
        ...provided,
        padding: 0,
    }),
    option: (provided) => ({
        ...provided,
        cursor: 'pointer',
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: 0,
        border: '1px solid #ccd5e3',
        backgroundColor: '#eff2f7',
        'div:nth-child(1)': {
            padding: '1px 1px 1px 4px',
        },
        'div:nth-child(2)': {
            cursor: 'pointer',
            color: '#a6b4ce',
            '&:hover': {
                background: 'none',
                color: '#A6B4D1',
            },
        },
    }),
};

export default selectStyles;
