/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import AssetListPageSkeleton from './asset-list-page-skeleton';
import {AssetTitleActions} from './asset-title-actions';
import AssetTitleStore from './asset-title-store';
import ClipsFacetsBlock from './clips/clips-facets-block';
import ClipsResult from './clips/clips-results';
import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import SearchField from '../../common/search-field';
import {MessagesContext} from '../../messages/messages-context';
import {PlayerActions} from '../../player/player-actions';
import PlayerStore from '../../player/player-store';
import VideoOverlay from '../../player/video-overlay';
import {RouterActions} from '../../router/router-actions';
import SessionStore from '../../session/session-store';
import pick from '../../utils/pick';
import ClipStore from '../clip/clip-store';
import CreateClipModal from '../clip/create-clip-modal';
import ExternalLinks from '../info/sections/external-links';
import SerieNavigationStore from '../serie-navigation/serie-navigation-store';
import Share from '../share';
import {TitleActions} from '../title-actions';
import TitleStore from '../title-store';

const PAGE_SIZES = [20, 40, 60, 80];

const QUERY_FACETS = [
    'talent',
    'character',
    'talent-character',
    'setting',
    'dialogue',
    'tag',
    'type',
];

/**
 * @param {Record<string, unknown>} obj
 * @param {string[]} keys
 * @returns {Record<string, unknown>}
 */
const pickFromObject = (obj, keys) => {
    return Object.keys(obj)?.reduce((acc, key) => {
        if (keys.includes(key) && obj[key]) {
            return {
                ...acc,
                [key]: obj[key],
            };
        }
        return acc;
    }, {}) ?? [];
};

class ClipAssetsPage extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            assetSeasonCounts: TitleStore.getState().get('clipsSeasonCounts'),
            clips: AssetTitleStore.getState().get('clips'),
            currentClip: ClipStore.getState().get('currentClip'),
            isClipAssetsLoaded: AssetTitleStore.getState().get('assetsLoaded'),
            isClipsAvailable: TitleStore.getState().getIn(['assets', 'isClipsAvailable']),
            isTitleAssetsLoaded: TitleStore.getState().get('assetsLoaded'),
            isModalVisible: ClipStore.getState().get('isModalVisible'),
            seasonsMap: TitleStore.getState().get('seasonsMap'),
            selectedVideo: AssetTitleStore.getState().get('selectedVideo'),
            serieNavigation: SerieNavigationStore.getState().get('serieNavigation'),
            showOverlay: PlayerStore.getState().get('showOverlay'),
            showShare: AssetTitleStore.getState().get('showShare'),
            title: TitleStore.getState().get('title'),
            titleLinks: TitleStore.getState().get('links'),
            video: PlayerStore.getState().get('video'),
            watchlist: PlayerStore.getState().get('watchlist'),
        };
    }

    static getPermissions() {
        return {
            canAddToCart: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.CLIPS.CREATE),
            canViewClipLibrary: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.VIEW_CLIP_LIBRARY),
        };
    }

    static getStores() {
        return [AssetTitleStore, ClipStore, PlayerStore, SerieNavigationStore, TitleStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleSearchStringChanged = this.handleSearchStringChanged.bind(this);
        this.loadAutoClips = this.loadAutoClips.bind(this);

        // this is just a flag no need to use state lifecycle here, or call rerender
        this.isSearchTermChanged = true;
    }

    componentDidMount() {
        const {permissions, match} = this.props;
        if (permissions.canAddToCart) {
            setTimeout(() => this.loadAutoClips(), 0);
            this.loadExternalLinks();
        } else {
            RouterActions.redirect(`/titles/${match.params.id}`);
        }
    }

    static contextType = MessagesContext;

    handleSearchStringChanged(cqs) {
        if (!this.state.isClipsAvailable) {
            return;
        }
        this.isSearchTermChanged = true;
        const {location} = this.props;
        const query = {...QueryString.parse(location.search), cqs, offset: 0};
        RouterActions.redirect(`${location.pathname}?${QueryString.stringify(query)}`);
    }

    loadExternalLinks() {
        TitleActions.getExternalLinks(this.props.match.params.id);
    }

    loadAutoClips() {
        const {location, match} = this.props;
        const titleId = parseInt(match.params.id, 10);
        const query = QueryString.parse(location.search);
        const searchTerm = query.cqs || '';
        const offset = parseInt(query.offset, 10) || 0;
        const size = parseInt(query.size, 10) || 80;
        const sortFieldName = query['sort-field-name'] || '';
        const sortDirection = query['sort-direction'] || '';

        const facets = pickFromObject(query, QUERY_FACETS);

        AssetTitleActions.loading();
        AssetTitleActions.getTitleAssetsClips(titleId, {offset, searchTerm, size, sortFieldName, sortDirection, ...facets}, this.isSearchTermChanged);
        this.isSearchTermChanged = false;
    }

    renderMessageWithScissors(key) {
        return (
            this.renderMessage(
                <>
                    {this.context.intl.messages[`titles.moments.${key}-1`]}
                    <span className="fa fa-cut"/>
                    {this.context.intl.messages[`titles.moments.${key}-2`]}
                </>
            )
        );
    }

    renderMessage(content) {
        return (
            <div className="h3"><small>{content}</small></div>
        );
    }

    renderClipLibraryLinkIfAvailable(message) {
        const {titleLinks} = this.state;
        const permissions = pick(this.props.permissions, ['canViewClipLibrary']);

        return (
            <ExternalLinks permissions={permissions} links={titleLinks} render={({links}) => {
                if (!links.size) {
                    return null;
                }

                return (
                    <h3 className="Mt(20px)">
                        <br/>
                        <small className="D(f) Ai(c)">
                            <FormattedMessage id={message} />
                            {links.map((link) => <a
                                className="text-uppercase Ml(5px)"
                                href={link.get('linkTargetURL')}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {link.get('name')}
                            </a>)}
                        </small>
                    </h3>
                );
            }}/>
        );

    }

    render() {
        const {location, permissions, match} = this.props;
        const {
            assetSeasonCounts, clips, currentClip, isClipAssetsLoaded, isClipsAvailable, isModalVisible,
            isTitleAssetsLoaded, seasonsMap, selectedVideo, serieNavigation, showOverlay, showShare,
            title, video, watchlist,
        } = this.state;

        const {messages} = this.context.intl;

        let asideFiltersSlot, content, clipModal;

        const query = QueryString.parse(location.search);
        const titleId = parseInt(match.params.id, 10);
        const q = query.cqs || '';
        const isQueryStringFilled = !!q.trim();
        const showPreload = !isClipAssetsLoaded || !isTitleAssetsLoaded;
        const totalCount = isTitleAssetsLoaded ? clips.get('totalCount') : 0;
        const isGridView = query.view === 'grid';
        const clipsSize = clips.get('results').size;

        const searchSlot = (
            <SearchField className="D(f) Ai(c) Flxg(1) margin-x-30" value={q} handleValueChanged={this.handleSearchStringChanged}
                placeholder={messages['titles.moments.search.placeholder']} autofocus />
        );

        if (!isTitleAssetsLoaded) {
            content = null;
        } else if (!isClipsAvailable) {
            content = (
                <>
                    <h4 className="h3">{messages['titles.moments.no-clips.title']}</h4>
                    {this.renderMessageWithScissors('no-moments')}
                </>
            );
        } else if (isQueryStringFilled) {
            asideFiltersSlot = (
                <ClipsFacetsBlock location={location} titleId={titleId} />
            );
            if (clipsSize) {
                content = (
                    <ClipsResult
                        canAddToCart={permissions.canAddToCart}
                        clips={clips.get('results')}
                        isGridView={isGridView}
                        serieNavigation={serieNavigation}
                        title={title}
                        titleId={titleId}
                    />
                );
                clipModal = (
                    <CreateClipModal clip={currentClip} searchTerm={q} show={isModalVisible && !showOverlay} />
                );
            } else {
                content = (
                    <>
                        {this.renderMessageWithScissors('no-results')}
                        {this.renderClipLibraryLinkIfAvailable('titles.moments.no-results.link')}
                    </>
                );
            }
        } else {
            content = (
                <>
                    <h4 className="h3">{messages['titles.moments.empty-query.title']}</h4>
                    {this.renderMessageWithScissors('empty-query')}
                    {this.renderClipLibraryLinkIfAvailable('titles.moments.empty-query.link')}
                </>
            );
        }

        const modalSlot = (
            <>
                {clipModal}
                <Share
                    asset={selectedVideo}
                    assetType={AssetTypeConstants.ASSET_TYPE.VIDEO}
                    close={AssetTitleActions.hideShare}
                    show={showShare}
                    titleId={titleId}
                />
                <VideoOverlay
                    clipping
                    onClose={PlayerActions.hidePlayer}
                    titleId={titleId}
                    searchTerm={q}
                    video={video.size === 0 ? undefined : video}
                    visible={showOverlay}
                    watchlist={watchlist}
                />
            </>
        );

        return (
            <AssetListPageSkeleton
                asideFiltersSlot={asideFiltersSlot}
                assetSeasonCounts={assetSeasonCounts}
                assetsSearchSlot={searchSlot}
                assetTypePath="moments"
                documentMessage="document-titles.title.moments"
                getSelectedFacets={getSelectedFacets}
                handleQueryChanged={this.loadAutoClips}
                hasFacetsSlot={true}
                location={location}
                modalsSlot={modalSlot}
                pageSizes={PAGE_SIZES}
                seasonsMap={seasonsMap}
                showAsideFacetsBlock={false}
                showNavigationBlock={isClipsAvailable && isQueryStringFilled}
                showPreloader={showPreload}
                title={title}
                titleId={titleId}
                titleMessage="titles.moments.title"
                totalCount={totalCount}
            >
                {content}
            </AssetListPageSkeleton>
        );
    }
}

export default Container.create(ClipAssetsPage);

export function getSelectedFacets(query) {
    const searchTerm = query.cqs || '';

    const facets = pickFromObject(query, QUERY_FACETS);

    return Immutable.fromJS({facets, searchTerm});
}
