/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import Result from './result';
import {BreakIntoGroups} from '../utils/utils';

export default class ResultsRow extends React.Component {
    static get propTypes() {
        return {
            results: PropTypes.instanceOf(Immutable.List).isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.Map).isRequired,
            type: PropTypes.oneOf(['documents', 'episodes', 'images', 'moments', 'talent', 'titles', 'videos']).isRequired
        };
    }

    render() {
        return (
            <div className="row">
                {BreakIntoGroups(this.props.results.slice(0, 8), 4).map((resGroup, i) => {
                    return (
                        <div className="col-xs-12 col-lg-6" key={i}>
                            <div className="row">
                                <Result thumbnails={this.props.thumbnails} type={this.props.type} value={resGroup.get(0)}/>
                                <Result thumbnails={this.props.thumbnails} type={this.props.type} value={resGroup.get(1)}/>
                                <div className="clearfix visible-xs"/>
                                <Result thumbnails={this.props.thumbnails} type={this.props.type} value={resGroup.get(2)}/>
                                <Result thumbnails={this.props.thumbnails} type={this.props.type} value={resGroup.get(3)}/>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
