/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {createApi} from '@reduxjs/toolkit/query/react';
import {Title} from '@wbdt-sie/brainiac-web-common';

import {BaseQueryWithAuth} from '../redux/rtk-query';

type TitleType = typeof Title;
export default createApi({
    reducerPath: 'titleAPI',
    baseQuery: BaseQueryWithAuth,
    endpoints: (builder) => ({
        getTitleById: builder.query<TitleType, string>({
            query: (id) => ({url: `title/${id}`}),
            providesTags: (_result, _error, arg) => {
                return [{type: 'Title', id: arg}];
            }
        }),
    }),
    tagTypes: ['Title']
});
