/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import Preloader from '../preloader';
import ImageLoader from '../titles/asset-title/images/image-loader';
import {BreakIntoGroups} from '../utils/utils';

export class Show extends Component {

    static get propTypes() {
        return {
            show: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    constructor(props) {
        super(props);
        this.getImageUrl = this.getImageUrl.bind(this);
        this.imageIsLoading = this.imageIsLoading.bind(this);
        return;
    }

    shouldComponentUpdate(nextProps) {
        // Get the current image url and compare it with the next one.
        let imageUrl = this.getImageUrl(this.props);
        let nextImageUrl = this.getImageUrl(nextProps);
        if (imageUrl !== nextImageUrl ||
            this.props.show !== nextProps.show ||
            this.imageIsLoading(this.props) !== this.imageIsLoading(nextProps)) {
            return true;
        }

        return false;
    }

    getImageUrl(props) {
        let imageId = '';
        if (props.show.get('defaultImagePortraitId') !== null) {
            imageId = props.show.get('defaultImagePortraitId');
        }

        return props.thumbnails.getIn([imageId.toString(), 'previewUrl']);
    }

    imageIsLoading(props) {
        let imageId = props.show.get('defaultImagePortraitId');
        if (!imageId) {
            return false;
        }
        let imageUrl = props.thumbnails.getIn([imageId.toString(), 'previewUrl']);
        return imageUrl === undefined;
    }

    render() {
        if (!this.props.show) {return null;}
        let icon;
        let imageUrl = this.getImageUrl(this.props);
        let uri = `/titles/${this.props.show.get('id')}`;

        return (
            <div
                className="col-sm-4 thumbnail-container Cur(p)"
            >
                <Preloader
                    bsSize="sm"
                    fixed
                    show={this.imageIsLoading(this.props)}
                >
                    <Link to={uri}>
                        <div className="responsive-container">
                            <div className="vertical-spacer"></div>
                            <div className="img-container">
                                <ImageLoader
                                    alt=""
                                    className="img-responsive"
                                    src={imageUrl}
                                />
                                {icon}
                            </div>
                        </div>
                    </Link>
                </Preloader>
            </div>
        );
    }
}

export default class ListShows extends Component {
    static get propTypes() {
        return {
            shows: PropTypes.instanceOf(Immutable.List).isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    constructor(props) {
        super(props);
        return;
    }

    render() {
        return (
            <div className="col-xs-12">
                {BreakIntoGroups(this.props.shows, 3).map((showRow, i) => {
                    return (
                        <Row key={i}>
                            {showRow.map((show, j) => {
                                return (
                                    <Show
                                        key={j}
                                        show={show}
                                        thumbnails={this.props.thumbnails}
                                    />
                                );
                            })}
                        </Row>
                    );
                })}
            </div>
        );
    }
}
