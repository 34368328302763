/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import React from 'react';
/*
INFO: big setback about this: if we would like another prop dunno, fillColor, IT HAS TO BE on ALL  components if its on the IconsProps, possible solution, have different types
IconProps and IconPropsWithFill and only that one should posses the fillColor prop, otherwise it will fail. Just a comment.
*/
interface IconsProps {
    classes?: string,
    fillColor?: string,
    hoverColor?: string,
    strokeColor?: string,
}

const ViewGridIcon = ({classes, strokeColor, fillColor, hoverColor}: IconsProps) => {
    //Just an FYI: h-5 w-5 are overriten when you send on the prop clases h-10 w-10
    const allClassNames = ClassNames(
        'h-5 w-5',
        classes,
        `stroke-${strokeColor}`,
        `fill-${fillColor}`,
        `hover:stroke-${hoverColor}`
    );
    return <svg xmlns="http://www.w3.org/2000/svg" className={allClassNames} viewBox="0 0 20 20">
        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
    </svg>;
};
ViewGridIcon.defaultProps = {
    classes: '',
    fillColor: 'transparent',
    hoverColor: '',
    strokeColor: 'currentColor',
};

const DownloadIcon = ({classes, strokeColor, fillColor, hoverColor}: IconsProps) => {
    const allClassNames = ClassNames(
        'h-5 w-5',
        classes,
        `stroke-${strokeColor}`,
        `fill-${fillColor}`,
        `hover:stroke-${hoverColor}`
    );
    return <svg xmlns="http://www.w3.org/2000/svg" className={allClassNames} viewBox="0 0 20 20">
        <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" />
    </svg>;
};
DownloadIcon.defaultProps = {
    classes: '',
    fillColor: 'none',
    hoverColor: '',
    strokeColor: 'currentColor',
};

const ChevronDown = ({classes, strokeColor, fillColor, hoverColor}: IconsProps) => {
    const allClassNames = ClassNames(
        'h-5 w-5',
        classes,
        `stroke-${strokeColor}`,
        `fill-${fillColor}`,
        `hover:stroke-${hoverColor}`
    );
    return <svg xmlns="http://www.w3.org/2000/svg" className={allClassNames} viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
    </svg>;
};
ChevronDown.defaultProps = {
    classes: '',
    fillColor: 'none',
    hoverColor: '',
    strokeColor: 'currentColor',
};

const MagGlass = ({classes, strokeColor, fillColor, hoverColor}: IconsProps) => {
    const allClassNames = ClassNames(
        'h-5 w-5',
        classes,
        `stroke-${strokeColor}`,
        `fill-${fillColor}`,
        `hover:stroke-${hoverColor}`
    );
    return <svg xmlns="http://www.w3.org/2000/svg" className={allClassNames} viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>;
};
MagGlass.defaultProps = {
    classes: '',
    fillColor: 'transparent',
    hoverColor: '',
    strokeColor: 'currentColor',
};

const Edit = ({classes, strokeColor, fillColor, hoverColor}: IconsProps) => {
    const allClassNames = ClassNames(
        'h-5 w-5',
        classes,
        `stroke-${strokeColor}`,
        `fill-${fillColor}`,
        `hover:stroke-${hoverColor}`
    );
    return <svg xmlns="http://www.w3.org/2000/svg" className={allClassNames} viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
    </svg>;
};
Edit.defaultProps = {
    classes: '',
    fillColor: 'transparent',
    hoverColor: '',
    strokeColor: 'currentColor',
};

const LogIn = ({classes, strokeColor, fillColor, hoverColor}: IconsProps) => {
    const allClassNames = ClassNames(
        'h-5 w-5',
        classes,
        `stroke-${strokeColor}`,
        `fill-${fillColor}`,
        `hover:stroke-${hoverColor}`
    );
    return <svg xmlns="http://www.w3.org/2000/svg" className={allClassNames} viewBox="0 0 512 512">
        <path d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z"/>
    </svg>;
};
LogIn.defaultProps = {
    classes: '',
    fillColor: 'none',
    hoverColor: '',
    strokeColor: 'currentColor',
};

const CircleArrows = ({classes, strokeColor, fillColor, hoverColor}: IconsProps) => {
    const allClassNames = ClassNames(
        'h-5 w-5',
        classes,
        `stroke-${strokeColor}`,
        `fill-${fillColor}`,
        `hover:stroke-${hoverColor}`
    );
    return <svg xmlns="http://www.w3.org/2000/svg" className={allClassNames} viewBox="0 0 512 512">
        <path d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"/>
    </svg>;
};
CircleArrows.defaultProps = {
    classes: '',
    fillColor: 'none',
    hoverColor: '',
    strokeColor: 'currentColor',
};

const Key = ({classes, strokeColor, fillColor, hoverColor}: IconsProps) => {
    const allClassNames = ClassNames(
        'h-5 w-5',
        classes,
        `stroke-${strokeColor}`,
        `fill-${fillColor}`,
        `hover:stroke-${hoverColor}`
    );
    return <svg xmlns="http://www.w3.org/2000/svg" className={allClassNames} viewBox="0 0 512 512">
        <path d="M282.3 343.7L248.1 376.1C244.5 381.5 238.4 384 232 384H192V424C192 437.3 181.3 448 168 448H128V488C128 501.3 117.3 512 104 512H24C10.75 512 0 501.3 0 488V408C0 401.6 2.529 395.5 7.029 391L168.3 229.7C162.9 212.8 160 194.7 160 176C160 78.8 238.8 0 336 0C433.2 0 512 78.8 512 176C512 273.2 433.2 352 336 352C317.3 352 299.2 349.1 282.3 343.7zM376 176C398.1 176 416 158.1 416 136C416 113.9 398.1 96 376 96C353.9 96 336 113.9 336 136C336 158.1 353.9 176 376 176z"/>
    </svg>;
};
Key.defaultProps = {
    classes: '',
    fillColor: 'none',
    hoverColor: '',
    strokeColor: 'currentColor',
};

export {ViewGridIcon, DownloadIcon, ChevronDown, MagGlass, Edit, LogIn, CircleArrows, Key};
