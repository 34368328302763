/* istanbul ignore file */
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

const withOptionsFromImmutableList = (Component) => {
    const Wrapper = ({options: derivedOptions, ...rest}) => {
        let options = derivedOptions;
        if (derivedOptions?.toJS) {
            options = derivedOptions.toJS() ?? [];
        }
        return <Component options={options} {...rest} />;
    };

    Wrapper.propTypes = {
        options: PropTypes.instanceOf(Immutable.List).isRequired,
    };

    return Wrapper;
};

export default withOptionsFromImmutableList;

withOptionsFromImmutableList.propTypes = {
    Component: PropTypes.element,
};
