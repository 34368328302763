/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Glyphicon} from 'react-bootstrap';

import SynopsisDetail, {Description} from './synopsis-detail';
import {DownloadActions} from '../../../../common/download/download-actions';
import WithPermissions from '../../../../decorators/with-permissions';
import {MessagesContext} from '../../../../messages/messages-context';
import SessionStore from '../../../../session/session-store';
import {Compare, CompareImmutable} from '../../../../utils/utils';
import {TitleActions, TitleConstants} from '../../../title-actions';
import TitleModal from '../../modal';
import CastAndCredits from '../cast-and-credits';

class Synopsis extends Component {

    static get propTypes() {
        return {
            canAddToWorkOrder: PropTypes.bool,
            canDownloadSalesDeck: PropTypes.bool,
            canSelectDocuments: PropTypes.bool,
            canShareTitle: PropTypes.bool,
            canSubscribeClient: PropTypes.bool,
            canViewFrontendComment: PropTypes.bool,
            canViewFullEpisode: PropTypes.bool,
            canViewLanguageAvailability: PropTypes.bool,
            canViewReleaseDate: PropTypes.bool,
            canViewMultipleReleaseDates: PropTypes.bool,
            releaseDatesTableCollapsed: PropTypes.bool,
            handleToggleExpandReleaseDatesTable: PropTypes.func,
            episodes: PropTypes.instanceOf(Immutable.List),
            genres: PropTypes.instanceOf(Immutable.List).isRequired,
            permissions: PropTypes.object.isRequired,
            related: PropTypes.instanceOf(Immutable.List),
            releaseDate: PropTypes.string,
            releaseDateType: PropTypes.number,
            releaseTerritory: PropTypes.string,
            seasons: PropTypes.instanceOf(Immutable.List),
            serieNavigation: PropTypes.instanceOf(Immutable.Map),
            synopses: PropTypes.instanceOf(Immutable.List),
            synopsisUsageType: PropTypes.string,
            themes: PropTypes.instanceOf(Immutable.List).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            // TODO: remove talent and request them on CastAndCredits component to avoid request
            // talent in pages that are not using them (like all pages that are not info)
            titleTalent: PropTypes.instanceOf(Immutable.List).isRequired,
            videos: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get defaultProps() {
        return {
            canAddToWorkOrder: false,
            canDownloadSalesDeck: false,
            canSelectDocuments: false,
            canShareTitle: false,
            canSubscribeClient: false,
            canViewFrontendComment: false,
            canViewFullEpisode: false,
            canViewLanguageAvailability: false,
            canViewReleaseDate: false,
            canViewMultipleReleaseDates: false,
            releaseDatesTableCollapsed: true,
            handleToggleExpandReleaseDatesTable: /* istanbul ignore next */() => void 0,
            episodes: undefined,
            related: undefined,
            releaseDate: '',
            releaseDateType: undefined,
            releaseTerritory: '',
            seasons: '',
            serieNavigation: '',
            synopses: Immutable.List(),
            synopsisUsageType: undefined,
        };
    }

    static getPermissions() {
        return {
            canDownloadFactSheet: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.FACT_SHEETS.DOWNLOAD),
            canDownloadAllFactSheets: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.FACT_SHEETS.DOWNLOAD_ALL),
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showSynopsesModal: false,
            showWorkOrderModal: false,
        });

        this.closeModal = this.closeModal.bind(this);
        this.handleDownloadFactSheet = this.handleDownloadFactSheet.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !Compare(
            this.props, nextProps, [
                'canAddToWorkOrder',
                'canDownloadSalesDeck',
                'canShareTitle',
                'canSubscribeClient',
                'canViewFullEpisode',
                'genres',
                'videos',
                'related',
                'releaseDate',
                'releaseDateType',
                'releaseDatesTableCollapsed',
                'releaseTerritory',
                'serieNavigation',
                'themes',
                'titleTalent'
            ]) ||
            !CompareImmutable(this.props.title, nextProps.title, ['id', 'defaultImagePreviewUrl']) ||
            !Compare(this.state, nextState, ['showWorkOrderModal', 'showSynopsesModal']);
    }

    static contextType = MessagesContext;

    closeModal(modalName, event) {
        if (event) {
            event.preventDefault();
        }

        this.setState({['show' + modalName + 'Modal']: false});
    }

    handleDownloadAllFactSheet(titleId) {
        TitleActions.downloadAllFactSheet(titleId);
    }

    handleDownloadFactSheet() {
        DownloadActions.startDownloadExecution(`title/${this.props.title.get('id')}/fact-sheet`);
    }

    openModal(modalName, event) {
        if (event) {
            event.preventDefault();
        }
        this.setState({['show' + modalName + 'Modal']: true});
    }

    render() {
        let synopsisText = this.props.title.get('synopsis') || this.context.intl.messages['title.synopsis.not-available'];
        //check if an event has set a usage type
        if (this.props.synopsisUsageType !== undefined) {
            const altSynopsisText = this.props.synopses.find(s => s.get('usageType') === this.props.synopsisUsageType)?.get('synopsis');
            //check if the synopsis set for the event has text
            if (altSynopsisText !== undefined && altSynopsisText !== '' && altSynopsisText !== null) {
                synopsisText = altSynopsisText;
            }
        }
        let hr;
        let description;
        let rightPanel = <Description className="col-md-6 padding-y-20" synopsis={synopsisText} />;

        let awards, awardsOnRight;
        if (this.props.title.get('awards')) {
            awardsOnRight = (
                <div>
                    <h3>Awards</h3>
                    <p dangerouslySetInnerHTML={{__html: this.props.title.get('awards')}} />
                </div>
            );
        }

        let talentByRole = this.props.titleTalent.reduce( (tr, t) => {
            let role = t.get('roleInTitle');
            let talent = tr.get( role, Immutable.List());
            return tr.set(role, talent.push(t));
        }, Immutable.Map());
        if (this.props.titleTalent.size !== 0) {
            hr = <hr/>;
            description = <Description synopsis={synopsisText} />;
            rightPanel = <CastAndCredits talentByRole={talentByRole} title={this.props.title}/>;
            // Display awards on left side below the synopsis.
            awards = awardsOnRight;
            awardsOnRight = undefined;
        }

        let fsDownload;
        if (this.props.permissions.canDownloadFactSheet && this.props.title.get('category') !== TitleConstants.TITLE_TYPES.EVENT.id) {
            fsDownload = (
                <Button className="btn-secondary" onClick={this.handleDownloadFactSheet}>
                    <Glyphicon glyph="download-alt" /><span>{this.context.intl.messages['title.synopsis.download.fact-sheet']}</span>
                </Button>
            );
        }

        let fsDownloadAll;
        let addToWorkOrderBtn;
        let addToWorkOrderModal;

        if (this.props.canAddToWorkOrder || this.props.canDownloadSalesDeck) {
            addToWorkOrderModal = (
                <TitleModal.WorkOrder
                    close={this.closeModal.bind(this, 'WorkOrder')}
                    serieNavigation={this.props.serieNavigation}
                    show={this.state.showWorkOrderModal}
                    title={this.props.title}

                />
            );
            addToWorkOrderBtn = <Button className="btn-secondary" onClick={this.openModal.bind(this, 'WorkOrder')}><Glyphicon glyph="copy" /><span>{this.context.intl.messages['title.synopsis.work-order.add']}</span></Button>;
        }

        if (this.props.permissions.canDownloadAllFactSheets &&
            [
                TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES,
                TitleConstants.TITLE_CATEGORY_GROUPS.SERIES,
                TitleConstants.TITLE_CATEGORY_GROUPS.SEASON
            ].indexOf(
                this.props.title.get('categoryGroup')
            ) !== -1
        ) {
            fsDownloadAll = (
                <Button
                    className="btn-secondary"
                    onClick={this.handleDownloadAllFactSheet.bind(this, this.props.title.get('id'))}
                >
                    <Glyphicon glyph="download-alt" />
                    <span>{this.context.intl.messages['title.synopsis.download.fact-sheet-all']}</span>
                </Button>
            );
        }
        //Additional Synopses
        let additionalSynopsesModal = (
            <TitleModal.Synopses
                close={this.closeModal.bind(this, 'Synopses')}
                show={this.state.showSynopsesModal}
                synopses={this.props.synopses}
                title={this.props.title}

            />
        );
        let additionalSynopsesLink = <p>
            <a
                onClick={this.openModal.bind(this, 'Synopses')}
                role="button"
                tabIndex="0">
                {this.context.intl.messages['title.synopsis.view-additional-synopses']}
                <Glyphicon glyph="chevron-right" />
            </a>
        </p>;

        return (
            <div className="container padding-y-10 padding-x-10">
                <div className="row">
                    {additionalSynopsesModal}
                    {addToWorkOrderModal}
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12 padding-x-10 padding-y-10">
                                <SynopsisDetail
                                    canSelectDocuments={this.props.canSelectDocuments}
                                    canShareTitle={this.props.canShareTitle}
                                    canSubscribeClient={this.props.canSubscribeClient}
                                    canViewFrontendComment={this.props.canViewFrontendComment}
                                    canViewFullEpisode={this.props.canViewFullEpisode}
                                    canViewLanguageAvailability={this.props.canViewLanguageAvailability}
                                    canViewReleaseDate={this.props.canViewReleaseDate}
                                    canViewMultipleReleaseDates={this.props.canViewMultipleReleaseDates}
                                    releaseDatesTableCollapsed={this.props.releaseDatesTableCollapsed}
                                    handleToggleExpandReleaseDatesTable={this.props.handleToggleExpandReleaseDatesTable}
                                    episodeListId="episodeListContainer"
                                    episodes={this.props.episodes}
                                    genres={this.props.genres}
                                    related={this.props.related}
                                    releaseDate={this.props.releaseDate}
                                    releaseDateType={this.props.releaseDateType}
                                    releaseTerritory={this.props.releaseTerritory}
                                    seasonListId="seasonListContainer"
                                    seasons={this.props.seasons}
                                    serieNavigation={this.props.serieNavigation}
                                    themes={this.props.themes}
                                    title={this.props.title}
                                    videos={this.props.videos}
                                />
                                {hr}
                                {description}
                                {additionalSynopsesLink}
                                {fsDownload}
                                {fsDownload && <span>&nbsp;</span>}
                                {fsDownloadAll}
                                {fsDownloadAll && <span>&nbsp;</span>}
                                {addToWorkOrderBtn}
                                {awards}
                            </div>
                        </div>
                    </div>
                    {rightPanel}
                    {awardsOnRight}
                </div>
            </div>
        );
    }

}

export default WithPermissions(Synopsis);
export {Synopsis};
