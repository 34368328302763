/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Billboard, List, NewThisWeek, PlaylistBillboard, SmallBillboard, VideoList} from './sections';

const SECTIONS = {
    BILLBOARD: {
        COMPONENT: Billboard,
        ID: 1
    },
    PLAYLIST_BILLBOARD: {
        COMPONENT: PlaylistBillboard,
        ID: 1
    },
    SMALL_BILLBOARD: {
        COMPONENT: SmallBillboard,
        ID: 2
    },
    DC_SMALL_BILLBOARD: {
        COMPONENT: List(8),
        ID: 2
    },
    LIST_ONE: {
        COMPONENT: List(4),
        ID: 3
    },
    VIDEO_LIST: {
        COMPONENT: VideoList,
        ID: 3
    },
    LIST_TWO: {
        COMPONENT: List(4),
        ID: 4
    },
    LIST_THREE: {
        COMPONENT: List(4),
        ID: 5
    },
    DYNAMIC_LISTS: {
        COMPONENT: List(4),
        ID: 6
    },
    NEW_THIS_WEEK: {
        COMPONENT: NewThisWeek,
        ID: null
    },
};

/**
 * Constant representing all available Page Types.
* Important: the sections are in the order they must be shown!
*/
const PAGE_TYPE = {
    LEGACY: {
        ID: 1,
        SECTIONS: [
            SECTIONS.BILLBOARD,
            SECTIONS.LIST_ONE,
            SECTIONS.LIST_TWO,
            SECTIONS.LIST_THREE,
            SECTIONS.NEW_THIS_WEEK,
            SECTIONS.SMALL_BILLBOARD
        ]
    },
    PLAYLIST: {
        ID: 2,
        SECTIONS: [
            SECTIONS.PLAYLIST_BILLBOARD,
            SECTIONS.VIDEO_LIST
        ]
    },
    UPFRONTS: {
        ID: 3,
        SECTIONS: [
            SECTIONS.BILLBOARD,
            SECTIONS.LIST_ONE,
            SECTIONS.LIST_TWO
        ]
    },
    PRESS_RELEASE: {
        ID: 4,
        SECTIONS: [
            SECTIONS.BILLBOARD,
            SECTIONS.VIDEO_LIST,
            SECTIONS.DYNAMIC_LISTS,
            SECTIONS.DYNAMIC_LISTS,
            SECTIONS.DYNAMIC_LISTS
        ]
    },
};

export {
    SECTIONS as HomeSections,
    PAGE_TYPE as PageTypes,
};
