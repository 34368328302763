/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import ClipTRT from './clip-trt';
import Preloader from '../../preloader';
import ClipThumbnailToolbar from '../../titles/asset-title/clips/clip-thumbnail-toolbar';
import FifoImageLoader from '../../titles/asset-title/images/fifo-image-loader';
import {ImageLoaderPlaceholders} from '../../titles/asset-title/images/image-loader';
import ListOfMatches from '../matches/list-of-matches';

const ClipGridItem = (props) => {
    const {
        clip,
        titleId,
        canAddToCart,
        onPlay,
        ariaLabel,
        clipTitle,
        thumbnailsUrl,
        className,
        isImageLoading,
    } = props;
    const {displayName, id, name: clipName} = clip.toJS();

    return (
        <div className={ClassNames('col-sm-6 padding-all-10', className)}>
            <Preloader bsSize="sm" fixed show={isImageLoading}>
                <div className="video-thumbnail">
                    <ClipTRT clip={clip} />
                    <ClipThumbnailToolbar
                        clip={clip}
                        titleId={titleId}
                        canAddToCart={canAddToCart}
                    />
                    <div className="video-play video-play-single">
                        <span
                            aria-label={ariaLabel}
                            className="glyphicon glyphicon-play-circle hover-play-icon"
                            data-testid="play-button"
                            onClick={onPlay}
                            onKeyUp={onPlay}
                            role="button"
                            tabIndex={0}
                        />
                    </div>
                    <div className="video-thumbnail-overlay" />
                    <FifoImageLoader
                        alt={displayName}
                        className="img-responsive"
                        key={id}
                        placeholder={ImageLoaderPlaceholders.HORIZONTAL}
                        sources={thumbnailsUrl}
                    />
                </div>
            </Preloader>
            <p className="Mb(20px)" />
            <ListOfMatches
                matches={clip.get('matches')}
                tags={clip.get('tags')}
            />
            <button
                className="video-play-single Cur(p) btn-link P(0)"
                data-testid="clip-name"
                onClick={onPlay}
                onKeyUp={onPlay}
                type="button"
                tabIndex={0}
            >
                {displayName}
            </button>
            {clipName && /* istanbul ignore next */<div dangerouslySetInnerHTML={{__html: clipName}} />}
            {clipTitle && /* istanbul ignore next */<><br />{clipTitle}</>}
        </div>
    );
};

ClipGridItem.propTypes = {
    ariaLabel: PropTypes.string,
    canAddToCart: PropTypes.bool,
    className: PropTypes.string,
    clip: PropTypes.instanceOf(Immutable.Map).isRequired,
    clipTitle: PropTypes.node,
    isImageLoading: PropTypes.bool,
    onPlay: PropTypes.func.isRequired,
    thumbnailsUrl: PropTypes.instanceOf(Immutable.List),
    titleId: PropTypes.number.isRequired,
};

ClipGridItem.defaultProps = {
    ariaLabel: '',
    className: '',
    canAddToCart: false,
    clipTitle: '',
    thumbnailsUrl: Immutable.List,
    isImageLoading: false,
};

export default ClipGridItem;
