/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Glyphicon, Modal} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {MessagesContext} from '../../../messages/messages-context';
import {OrderActions} from '../../../orders/order-actions';
import OrderStore from '../../../orders/order-store';
import {WorkOrderActions, WorkOrderConstants} from '../../../work-orders/work-order-actions';
import {TitleConstants} from '../../title-actions';

class WorkOrder extends Component {
    static get propTypes() {
        return {
            close: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static calculateState() {
        return {
            episode: false,
            season: false,
            serie: false,
            workOrders: OrderStore.getState().get('myOrderMetadata')
        };
    }

    static getStores() {
        return [OrderStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.checkboxFrom = this.checkboxFrom.bind(this);
        this.checkValidation = this.checkValidation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleWorkOrderChange = this.handleWorkOrderChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDone = this.onDone.bind(this);
    }

    componentDidMount() {
        OrderActions.getMetadataOrder([
            WorkOrderConstants.STATUS_BY_NAME.ACTIVE,
            WorkOrderConstants.STATUS_BY_NAME.ONHOLD
        ]);
        return;
    }

    static contextType = MessagesContext;

    addTitles(isNewWorkOrder) {
        let titles = [this.props.title.get('id')];
        let sn = this.props.serieNavigation.getIn(['serieMap', this.props.title.get('id').toString()]);

        switch (this.props.title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            if (this.state.season) {
                titles.push(this.props.serieNavigation.getIn(['seasons', sn.get(0), 'titleId']));
            }
            if (this.state.serie) {
                titles.push(this.props.serieNavigation.get('titleId'));
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            if (this.state.serie) {
                titles.push(this.props.serieNavigation.get('titleId'));
            }
            if (this.state.episode) {
                titles = titles.concat(this.props.serieNavigation.getIn(['seasons', sn.get(0), 'episodes']).map( e => e.get('titleId')).toJS());
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
            if (this.state.episodes) {
                titles = titles.concat(this.props.serieNavigation.get('episodes').map( s => s.get('titleId')).toJS());
            }
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            if (this.state.season) {
                titles = titles.concat(this.props.serieNavigation.get('seasons').map( s => s.get('titleId')).toJS());
            }
            if (this.state.episode) {
                titles = titles.concat(this.props.serieNavigation.get('seasons').reduce( (a, s) => {
                    return a.concat(s.get('episodes').map( e => e.get('titleId')).toJS());
                }, []));
            }
            break;
        }
        let workOrderId;
        if (!isNewWorkOrder) {
            workOrderId = this.state.selectedWorkOrder.id;
        }

        WorkOrderActions.addTitles(titles, workOrderId);
        return;
    }

    checkboxFrom(title, titleType, disabled) {
        return (<div key={titleType}>
            <label>
                <input type="checkbox" checked={disabled || this.state[titleType]} onChange={this.handleChange.bind(this, titleType)} disabled={disabled}/>
                &nbsp;<strong>{title}</strong>
            </label>
        </div>);
    }

    checkValidation() {
        let isValid = false;

        if (this.state.selectedWorkOrder !== undefined) {
            isValid = true;
        }

        return isValid;
    }

    handleChange(titleType) {
        this.setState(state => {
            return {
                [titleType]: !state[titleType]
            };
        });
    }

    handleWorkOrderChange(value) {
        if (value) {
            this.setState({selectedWorkOrder: value});
        } else {
            this.setState({selectedWorkOrder: undefined});
        }
    }

    onClose() {
        this.setState({
            selectedWorkOrder: undefined
        });
        this.props.close();
    }

    onDone(isNewWorkOrder) {
        this.addTitles(isNewWorkOrder);
        this.onClose();
    }

    render() {
        let checkboxes = [];
        let checkboxesContainer;
        let episodeNumber;
        let modalContent;
        let modalTitle = 'title.modal.work-order.title';
        let seasonNumber;
        let sn = this.props.title.get('id') ? this.props.serieNavigation.getIn(['serieMap', this.props.title.get('id').toString()]) : undefined;
        let titleName = this.props.title.get('displayName');
        let workOrders = this.state.workOrders !== undefined ? this.state.workOrders.toJS() : [];

        switch (this.props.title.get('categoryGroup')) {
        case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
            seasonNumber = sn ? this.props.serieNavigation.getIn(['seasons', sn.get(0), 'seasonNumber']) : 'X';
            episodeNumber = sn ? this.props.serieNavigation.getIn(['seasons', sn.get(0), 'episodes', sn.get(1), 'episodeNumber']) : 'X';
            titleName = `${this.props.title.get('name')} (${this.props.serieNavigation.get('titleName')} - S${seasonNumber}E${episodeNumber})`;
            checkboxes = [this.checkboxFrom(titleName, 'episode', true),
                this.checkboxFrom(`${this.props.serieNavigation.get('titleName')} - Series`, 'serie', false),
                this.checkboxFrom(`${this.props.serieNavigation.get('titleName')} - Season ${seasonNumber}`, 'season', false)];
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
            seasonNumber = sn ? this.props.serieNavigation.getIn(['seasons', sn.get(0), 'seasonNumber']) : 'X';
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')} - Season ${seasonNumber}`, 'season', true),
                this.checkboxFrom(`${this.props.title.get('name')} - Series`, 'serie', false),
                this.checkboxFrom(`All episodes of ${this.props.title.get('name')} Season ${seasonNumber}`, 'episode', false)];
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.MINI_SERIES:
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')}`, 'serie', true),
                this.checkboxFrom(`All episodes of ${this.props.title.get('name')}`, 'episode', false)];
            break;
        case TitleConstants.TITLE_CATEGORY_GROUPS.SERIES:
            checkboxes = [this.checkboxFrom(`${this.props.title.get('name')}`, 'serie', true),
                this.checkboxFrom(`All Seasons of ${this.props.title.get('name')}`, 'season', false),
                this.checkboxFrom(`All episodes of ${this.props.title.get('name')}`, 'episode', false)];
            break;
        }

        if (checkboxes.length > 0) {
            checkboxesContainer = <div className="margin-bottom-20">
                {checkboxes}
            </div>;
        }

        modalContent = (
            <div>
                <p>{this.context.intl.messages['title.modal.work-order.suggest-line.one']} &quot;{titleName}&quot;. {this.context.intl.messages['title.modal.work-order.suggest-line.two']}</p>
                {checkboxesContainer}
                <label>{this.context.intl.messages['title.modal.work-order.subtitle']}</label>
                <ReactSelect
                    label={(<strong>{this.context.intl.messages['title.modal.work-order.subtitle']}:</strong>)}
                    getOptionLabel={u => u.name}
                    getOptionValue={u => u.id}
                    name="name"
                    onChange={this.handleWorkOrderChange}
                    options={workOrders}
                    placeholder={this.context.intl.messages['title.modal.work-order.to-add.placeholder']}
                    value={this.state.selectedWorkOrder}
                />
                <hr/>
                <p className="text-center">{this.context.intl.messages['title.modal.work-order.or']}<br/>
                    <Button
                        bsStyle="default"
                        onClick={this.onDone.bind(this, true)}>{this.context.intl.messages['title.modal.work-order.create']}
                    </Button>
                </p>
            </div>
        );

        return (
            <Modal show={this.props.show} onHide={this.onClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center text-uppercase">{this.context.intl.messages[modalTitle]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent}
                </Modal.Body>
                <Modal.Footer className="padding-all-20">
                    <Button
                        bsStyle="default"
                        className="pull-left btn-secondary"
                        onClick={this.onClose}>{this.context.intl.messages['common.close']}
                    </Button>
                    <Button
                        bsStyle="primary"
                        className="pull-right"
                        disabled={!this.checkValidation()}
                        onClick={this.onDone.bind(this, false)}>
                        <Glyphicon glyph="copy" /><span>&nbsp;{this.context.intl.messages['common.add']}</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(WorkOrder);
