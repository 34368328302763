/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-bootstrap';

export default class CastList extends React.Component {
    static get propTypes() {
        return {
            items: PropTypes.object.isRequired, // array of JSX Objects
            maxCol: PropTypes.number,
            maxSize: PropTypes.number,
        };
    }

    static get defaultProps() {
        return {
            maxCol: 3,
            maxSize: 6
        };
    }

    shouldComponentUpdate(nextProps) {
        return this.props.items !== nextProps.items || this.props.maxCol !== nextProps.maxCol;
    }

    render() {
        let list = [];
        let counter = 0;
        for (let i = 0; i < this.props.items.size; i+=this.props.maxCol) {
            counter = counter+1;
            let begin = i;
            let end = i + this.props.maxCol;
            end = (end >= this.props.maxSize) ? this.props.maxSize : end;
            end = (end > this.props.items.size) ? this.props.items.size : end;
            if (end <= this.props.maxSize) {
                list.push(
                    <Col sm={6} key={i} className="Mb(1em) col-inline">
                        <Row>
                            {this.props.items.slice(begin, end).map(component => {
                                return component;
                            })}
                        </Row>
                    </Col>
                );
            }
        }
        return (
            <div>
                {list}
            </div>
        );
    }
}
