/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './index.less';

export default class HarryPotterEditorialPackages extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.harry-potter/editorial-packages">
                <div className="op_container_harry_potter">
                    <div style={{backgroundColor: '#1b1e22', color: '#FFF'}}>
                        <div className="container padding-all-20">
                            <div className="row margin-bottom-20">
                                <div className="col-md-5"></div>
                                <div className="col-md-2">
                                    <a href="/harry-potter">
                                        <img
                                            src={require('./images/logo-harry-potter.png')}
                                            className="img-responsive"
                                        />
                                    </a>
                                </div>
                                <div className="col-md-5"></div>
                                <div className="col-md-12 text-center" style={{marginTop: '20px'}}>
                                    <a
                                        href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/DESIGN+PACKAGES+AND+OVERVIEW/WW_TV_Toolkit_Design_Package_LoRes.pdf"
                                        className="btn btn-lg btn-gray">
                                        <i className="glyphicon glyphicon-download-alt"></i> Design Package
                                        Overview &amp; Toolkit
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container padding-all-20">
                        <div className="row">
                            <div className="col-md-4 padding-all-20" style={{borderRight: '1px solid #DDD'}}>
                                <img
                                    src={require('./temp-images/ww-animated-editorial-packages.gif')}
                                    className="img-responsive thumbnail-border"
                                    alt="..."
                                />
                            </div>
                            <div className="col-md-8 padding-all-20">
                                <h1>Editorial Packages</h1>
                                <hr />
                                <h3 className="h3">Spellbook Bumpers</h3>
                                <p>
                                    We have created two master looks for text-based bumpers: “Spellbook”
                                    and “Newspaper.” Spellbook Bumpers have the most flexibility, as they
                                    can reference both in-canon and real world milestones, as well as
                                    general editorial and interstitial content, such as fan-inspired
                                    statements, fun facts, etc. To allow for visual variety, this package
                                    is available in a color palette inspired by the Hogwarts House colors.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/EDITORIAL+PACKAGES/EP-SpellbookBumpers.zip">
                                            Spellbook Bumpers
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">Newspaper Bumpers</h3>
                                <p>
                                    Newspaper Bumpers were inspired by The Daily Prophet from the Harry
                                    Potter films. Backgrounds use headlines and copy as borders and are
                                    paired with a range of icons to evoke the look of the Daily Prophet
                                    from the film franchise. Please do not recreate The Daily Prophet
                                    headlines or articles. Only designs in the toolkit can be used.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/EDITORIAL+PACKAGES/EP-NewspaperBumpers.zip">
                                            Newspaper Bumpers
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">Text-Over-Footage Treatment</h3>
                                <p>
                                    These are examples of how title cards can be featured in conjunction
                                    with footage.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/EDITORIAL+PACKAGES/EP-TalentIDs.zip">
                                            Text-Over-Footage Treatment
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">House Mortise Collection</h3>
                                <p>
                                    Mortises in the four Hogwarts House colors can be used flexibly, to
                                    frame a variety of content.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/EDITORIAL+PACKAGES/EP-HouseMortiseCollection.zip">
                                            House Mortise Collection
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">Talent IDs</h3>
                                <p>
                                    Graphic Treatment to be used over behind-the-scenes footage,
                                    interviews, etc.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/EDITORIAL+PACKAGES/EP-TalentIDs.zip">
                                            Talent IDs
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            </DocumentTitle>

        );
    }
}
