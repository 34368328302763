/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {IntlProvider, createIntl, createIntlCache} from 'react-intl';

import {Languages, MessagesContext} from './messages-context';

// Grabs the preferred locales from the browser
// then checks against the list of languages available
// and returns the first locale to match
// Returns null when no languages available
// or when no locales match.
const getPreferredBrowserLocale = () => {
    let navigatorLanguages = navigator.languages || [navigator.language];
    if (!navigatorLanguages || !navigatorLanguages[0]) {
        return null;
    }
    let browserLocales = Array.from(
        new Set(
            navigatorLanguages.flatMap(l => [l, l.split(/-|_/)[0]])
        )
    );
    let preferredLocales = browserLocales.filter(l => (l in Languages));
    if (preferredLocales.length === 0) {
        return null;
    }
    return preferredLocales[0];
};


class IntlProviderWrapper extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.object
        };
    }
    static get defaultProps() {
        return {
            children: null
        };
    }

    constructor(...args) {
        super(...args);

        this.cache = createIntlCache();

        // determine which language to used based on
        // saved localStorage value or browser preferences
        let locale = localStorage.getItem('__locale');
        if (!Languages[locale]) {
            let preferredLocale = getPreferredBrowserLocale();
            locale = 'en';
            if (preferredLocale) {
                locale = preferredLocale;
            }
        }

        let {label, messages} = Languages[locale];
        let intl = createIntl({label, locale, messages}, this.cache);
        this.state = {
            intl,
            label,
            locale,
            messages,
            updateIntl: this.updateIntl, // eslint-disable-line react/no-unused-state
        };
    }

    static contextType = MessagesContext;

    updateIntl = (locale) => {
        if (!Languages[locale]) {
            // Prevents a bug when an invalid locale is passed
            locale = 'en';
        }

        const {label, messages} = Languages[locale];
        localStorage.setItem('__locale', locale);

        return this.setState({
            intl: createIntl({label, locale, messages}, this.cache),
            locale,
            label,
            messages
        });
    };

    render() {
        const {children} = this.props;
        const {intl, label, locale, messages} = this.state;
        return (
            <MessagesContext.Provider value={this.state}>
                <IntlProvider
                    intl={intl}
                    key={locale}
                    label={label}
                    locale={locale}
                    messages={messages}>
                    {children}
                </IntlProvider>
            </MessagesContext.Provider>
        );
    }
}

export {getPreferredBrowserLocale, IntlProviderWrapper};
