/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React, {memo, useCallback, useEffect, useState} from 'react';
import {Checkbox, ControlLabel, FormGroup} from 'react-bootstrap';
import {useIntl} from 'react-intl';

import style from './moment-type.module.less';
import {FACET_NAMES} from '../../../../common/facets/constants';
import useQueryParamValues from '../../../../common/search-filters/hooks/use-query-param-values';
import useSetSearchFilters from '../../../../common/search-filters/hooks/use-set-search-filters';

const MomentType = () => {
    const value = useQueryParamValues(FACET_NAMES.MOMENT_TYPE);
    const {setFilter} = useSetSearchFilters();
    const [highlightType, setHighlightType] = useState(value.includes('highlight'));
    const [momentType, setMomentType] = useState(value.includes('moment'));
    const intl = useIntl();

    useEffect(() => {
        const highlightEvent = {
            key: 'highlight',
        };
        const momentEvent = {
            key: 'moment',
        };

        if (!highlightType) {
            highlightEvent.action = 'remove-value';
        }
        if (!momentType) {
            momentEvent.action = 'remove-value';
        }

        const filterEvent = [];

        if (highlightType) {
            filterEvent.push(highlightEvent);
        }
        if (momentType) {
            filterEvent.push(momentEvent);
        }

        setFilter(FACET_NAMES.MOMENT_TYPE, 'key', true, filterEvent);
    }, [highlightType, momentType]);

    const handleHighlightChange = useCallback((event) => {
        const isChecked = event.target.checked;
        setHighlightType(isChecked);
    }, []);

    const handleMomentChange = useCallback((event) => {
        const isChecked = event.target.checked;
        setMomentType(isChecked);
    }, []);

    const handleAllChange = useCallback((event) => {
        handleHighlightChange(event);
        handleMomentChange(event);
    }, [handleHighlightChange, handleMomentChange]);

    return (
        <div>
            <FormGroup md={4}>
                <ControlLabel>{intl.messages['titles.moments.facets.type.moment-type']}</ControlLabel>
                <Checkbox checked={highlightType && momentType} onChange={handleAllChange}>
                    {intl.messages['titles.moments.facets.type.all']}
                </Checkbox>
                <div className={style.singleCheckboxes}>
                    <Checkbox checked={momentType} onChange={handleMomentChange}>
                        {intl.messages['titles.moments.facets.type.auto-moment']}
                    </Checkbox>
                    <Checkbox checked={highlightType} onChange={handleHighlightChange}>
                        {intl.messages['titles.moments.facets.type.highlight-reel']}
                    </Checkbox>
                </div>
            </FormGroup>
        </div>
    );
};

export default memo(MomentType);
