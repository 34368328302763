/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {SUBSCRIPTION_DOCUMENT_TYPES} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Col, Glyphicon, Modal, Row} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {FormItem} from '../../../form/form';
import {MessagesContext} from '../../../messages/messages-context';
import {Debounce} from '../../../utils/utils';
import {TitleActions, TitleConstants} from '../../title-actions';
import TitleStore from '../../title-store';

import {SubscriptionActions} from '~/src/account/tabs/subscriptions/subscription-actions';
import SubscriptionStore from '~/src/account/tabs/subscriptions/subscription-store';
import SessionStore from '~/src/session/session-store';
import {Compare} from '~/src/utils/utils';

const StationDocumentSubscriptionContentTypeIds = Object.keys(SUBSCRIPTION_DOCUMENT_TYPES)
    .map(k => SUBSCRIPTION_DOCUMENT_TYPES[k])
    .map(sct => sct.contentTypeId);

class Subscribe extends Component {
    static get propTypes() {
        return {
            canSelectDocuments: PropTypes.bool,
            close: PropTypes.func.isRequired,
            done: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            subscriptionType: PropTypes.oneOf([
                TitleConstants.SUBSCRIPTION_TYPE.NORMAL,
                TitleConstants.SUBSCRIPTION_TYPE.CLIENT
            ]),
            titleId: PropTypes.number.isRequired
        };
    }

    static get defaultProps() {
        return {
            canSelectDocuments: false,
            subscriptionType: TitleConstants.SUBSCRIPTION_TYPE.NORMAL
        };
    }

    static calculateState() {
        return {
            suggestedUsers: TitleStore.getState().getIn(['title', 'suggestedUsers']),
            titleCategoryGroup: TitleStore.getState().getIn(['title', 'categoryGroup']),
            subscriptions: SubscriptionStore.getState().get('subscriptions'),
        };
    }

    static getStores() {
        return [TitleStore, SubscriptionStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign ({
            isSelectedDocumentSubscriptionsDirty: false,
            selectedDocumentSubscriptions: [],
            selectedClientUser: undefined

        }, this.constructor.calculateState());

        this.checkSubscriptionTypes = this.hasSubscribedContent.bind(this);
        this.checkValidation = this.checkValidation.bind(this);
        this.getSubscriptionTypes = this.getSubscriptionTypes.bind(this);
        this.handleChangeDocument = this.handleChangeDocument.bind(this);
        this.handleClientUserChange = this.handleClientUserChange.bind(this);
        this.onChangeSubscriptionType = this.onChangeSubscriptionType.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDone = this.onDone.bind(this);
        this.searchUsers = Debounce(this.searchUsers.bind(this), 200);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !Compare(this.props, nextProps, [
            'canSelectDocuments',
            'close',
            'done',
            'show',
            'subscriptionType',
        ]) ||
        !Compare(this.state, nextState, [
            'subscriptions',
            'selectedDocumentSubscriptions',
            'suggestedUsers',
            'selectedClientUser']);
    }

    static contextType = MessagesContext;

    hasSubscribedContent() {
        // In client subscription mode, ensure that we have at least one subscription
        return !!Object.keys(this.state.subscriptions.toJS()).length;
    }

    checkValidation() {
        let isValid = false;

        switch (this.props.subscriptionType) {
        case TitleConstants.SUBSCRIPTION_TYPE.NORMAL:
            isValid = true;
            break;
        case TitleConstants.SUBSCRIPTION_TYPE.CLIENT:
            isValid = !!(this.hasSubscribedContent() && this.state.selectedClientUser);
            break;
        }

        return isValid;
    }

    getSubscriptionTypes() {
        let hasDocuments = false;
        StationDocumentSubscriptionContentTypeIds.forEach(ct => {
            if (this.state.subscriptions.getIn([ct.toString(), 'elements'])?.size > 0) {
                hasDocuments = true;
            }
        });

        return Immutable.fromJS({
            allVideos: this.state.subscriptions.getIn([TitleConstants.SUBSCRIPTION_CONTENT_TYPES.allVideos.toString(), 'elements'])?.size > 0,
            documents: hasDocuments,
            episodicDelivery: this.state.subscriptions.getIn([TitleConstants.SUBSCRIPTION_CONTENT_TYPES.episodicDelivery.toString(), 'elements'])?.size > 0,
            fullEpisodes: this.state.subscriptions.getIn([TitleConstants.SUBSCRIPTION_CONTENT_TYPES.fullEpisodes.toString(), 'elements'])?.size > 0,
            images: this.state.subscriptions.getIn([TitleConstants.SUBSCRIPTION_CONTENT_TYPES.images.toString(), 'elements'])?.size > 0,
            metadata: this.state.subscriptions.getIn([TitleConstants.SUBSCRIPTION_CONTENT_TYPES.metadata.toString(), 'elements'])?.size > 0,
            vamDelivery: this.state.subscriptions.getIn([TitleConstants.SUBSCRIPTION_CONTENT_TYPES.vamDelivery.toString(), 'elements'])?.size > 0,
        });
    }

    handleClientUserChange(value) {
        let clientUser = undefined;
        if (value) {
            clientUser = value;
        }

        this.setState(() => ({selectedClientUser: clientUser}));
        SubscriptionActions.updateUser(clientUser?.id, SessionStore.getState().getIn(['authUser', 'id']));
    }

    /**
     * Update the subscription type state
     * @param  {string} attr  SubscriptionType property
     * @param  {string} value new value
     * @return {void}
     */
    onChangeSubscriptionType(attr, value) {
        // Action to add/remove subscription type (only for checkbox items)

        let subscriptionContentTypeId;
        switch (attr) {
        case 'allVideos':
            subscriptionContentTypeId = TitleConstants.SUBSCRIPTION_CONTENT_TYPES.allVideos;
            break;
        case 'fullEpisodes':
            subscriptionContentTypeId = TitleConstants.SUBSCRIPTION_CONTENT_TYPES.fullEpisodes;
            break;
        case 'vamDelivery':
            subscriptionContentTypeId = TitleConstants.SUBSCRIPTION_CONTENT_TYPES.vamDelivery;
            break;
        case 'episodicDelivery':
            subscriptionContentTypeId = TitleConstants.SUBSCRIPTION_CONTENT_TYPES.episodicDelivery;
            break;
        case 'images':
            subscriptionContentTypeId = TitleConstants.SUBSCRIPTION_CONTENT_TYPES.images;
            break;
        case 'metadata':
            subscriptionContentTypeId = TitleConstants.SUBSCRIPTION_CONTENT_TYPES.metadata;
            break;
        default:
            subscriptionContentTypeId = value;
            break;
        }

        let targetUserId = SessionStore.getState().getIn(['authUser', 'id']);
        if (this.state.selectedClientUser) {
            targetUserId = this.state.selectedClientUser.id;
        }

        SubscriptionActions.update(this.props.titleId, targetUserId, SessionStore.getState().getIn(['authUser', 'id']), subscriptionContentTypeId, value);
    }

    /**
     * Reset the state & Close the modal
     * @return {void}
     */
    onClose() {
        this.setState({
            isSelectedDocumentSubscriptionsDirty: false,
            selectedDocumentSubscriptions: [],
            selectedClientUser: undefined
        });
        this.props.close();
    }

    onDone() {
        /**
         * Get all the properties there are true
         */


        this.props.done(this.state.subscriptions);
        this.onClose();
    }

    searchUsers(term) {
        TitleActions.getSuggestedUsers(term);
        return;
    }

    handleChangeDocument(currentValue) {
        const currentSelectedDocumentContentTypeIds = currentValue.map(c => c.contentTypeId.toString());
        let currentStateDocumentContentTypeIds = [];
        const cur = Object.keys(this.state.subscriptions.toJS()).filter(c => StationDocumentSubscriptionContentTypeIds.includes(parseInt(c, 10)));
        cur.forEach(c => {
            if (this.state.subscriptions.getIn([c.toString(), 'elements'])?.size > 0) {
                currentStateDocumentContentTypeIds.push(c);
            }
        });

        // Determine which content type ids were added or removed (user can clear the select box and remove all)
        let ids;
        let value;
        if (currentStateDocumentContentTypeIds.length < currentSelectedDocumentContentTypeIds.length) {
            // add subscription for content type id
            ids = currentSelectedDocumentContentTypeIds.filter(c => !currentStateDocumentContentTypeIds.includes(c));
            value = true;
        } else {
            // remove subscription for content type id
            ids = currentStateDocumentContentTypeIds.filter(c => !currentSelectedDocumentContentTypeIds.includes(c));
            value = false;
        }

        let targetUserId = SessionStore.getState().getIn(['authUser', 'id']);
        if (this.state.selectedClientUser) {
            targetUserId = this.state.selectedClientUser.id;
        }
        ids.forEach(id => {
            SubscriptionActions.update(this.props.titleId, targetUserId, SessionStore.getState().getIn(['authUser', 'id']), id, value);
        });

        this.setState(() => ({
            selectedDocumentSubscriptions: currentValue,
            isSelectedDocumentSubscriptionsDirty: true,
        }));
    }

    render() {
        let allDocuments;

        let modalFields;
        let modalTitle = 'title.modal.subscribe.title';
        let subtitle = this.context.intl.messages['title.modal.subscribe.subtitle'];
        let modalContent;
        let deliveryContent;
        let metadataSection;

        const model = this.getSubscriptionTypes();

        let documentsCaption;
        if (this.props.canSelectDocuments) {
            let existingDocumentSubscriptions = [];
            if (this.props.subscriptionType === TitleConstants.SUBSCRIPTION_TYPE.NORMAL) {
                // We can only preload existing subscriptions for the current user (will not work for client mode)
                existingDocumentSubscriptions = StationDocumentSubscriptionContentTypeIds.filter(docId => this.state.subscriptions.getIn([docId.toString(), 'elements'], Immutable.List()).filter(c => c.get('titleId') === this.props.titleId).size);
            }

            let selectedValues = [].concat(this.state.selectedDocumentSubscriptions);
            if (!this.state.isSelectedDocumentSubscriptionsDirty) {
                // Add our existing subscriptions to the selected values
                selectedValues = selectedValues.concat(SUBSCRIPTION_DOCUMENT_TYPES.filter(c => existingDocumentSubscriptions.includes(c.contentTypeId) && !selectedValues.filter(d => d.contentTypeId === c.contentTypeId).length));
            }

            documentsCaption = <Col xs={6} md={4}>
                <div className="checkbox">
                    <div><strong>{this.context.intl.messages['title.modal.subscribe-client.documents']}</strong></div>
                </div>
            </Col>;
            allDocuments = (
                <ReactSelect
                    getOptionLabel={data => data.name}
                    getOptionValue={data => data.id}
                    isMulti={true}
                    isClearable={true}
                    options={SUBSCRIPTION_DOCUMENT_TYPES}
                    placeholder={this.context.intl.messages['title.modal.subscribe-client.documents.placeholder']}
                    onChange={this.handleChangeDocument}
                    value={selectedValues}
                />
            );
        }

        /**
         * If SubscriptionClient
         *
         */
        if (this.props.subscriptionType === TitleConstants.SUBSCRIPTION_TYPE.CLIENT) {
            modalTitle = 'title.modal.subscribe-client.title';
            subtitle = this.context.intl.messages['title.modal.subscribe.subtitle'];
            /**
             * If Season Title
             *
             */
            if (this.state.titleCategoryGroup === TitleConstants.TITLE_CATEGORY_GROUPS.SEASON) {
                modalFields = (
                    <Row>
                        <Col xs={6} md={4}>
                            <div className="checkbox">
                                <FormItem
                                    model={model}
                                    type="checkbox"
                                    attr="vamDelivery"
                                    setter={this.onChangeSubscriptionType}
                                    label={(<div><strong>{this.context.intl.messages['title.modal.subscribe-client.vam-delivery']}</strong></div>)}
                                />
                            </div>
                        </Col>
                        <Col xs={6} md={4}>
                            <div className="checkbox">
                                <FormItem
                                    model={model}
                                    type="checkbox"
                                    attr="episodicDelivery"
                                    setter={this.onChangeSubscriptionType}
                                    label={(<div><strong>{this.context.intl.messages['title.modal.subscribe-client.episodic-promos']}</strong></div>)}
                                />
                            </div>
                        </Col>
                    </Row>
                );

                deliveryContent = (<div>
                    <h1 className="h3"><Glyphicon glyph="send" /> {this.context.intl.messages['title.synopsis.delivery']}</h1>
                    <p><em>{this.context.intl.messages['title.synopsis.delivery.subtitle']}</em></p>
                </div>);
            }

            let suggestedUsers = [];
            if (this.state.suggestedUsers !== undefined) {
                suggestedUsers = this.state.suggestedUsers.toJS();
            }
            modalContent = (
                <div>
                    <label>{this.context.intl.messages['title.modal.subscribe-client.to-subscribe.label']}</label>
                    <ReactSelect
                        label={(<strong>{this.context.intl.messages['title.modal.subscribe-client.to-subscribe']}:</strong>)}
                        getOptionLabel={u => u.email}
                        getOptionValue={u => u.id}
                        name="email"
                        onChange={this.handleClientUserChange}
                        onInputChange={this.searchUsers}
                        options={suggestedUsers}
                        placeholder={this.context.intl.messages['title.modal.subscribe-client.to-subscribe.placeholder']}
                        value={this.state.selectedClientUser}
                    />
                </div>
            );

            metadataSection = (<div>
                <h1 className="h3"><Glyphicon glyph="cloud-download" /> {this.context.intl.messages['title.synopsis.metadata']}</h1>
                <p><em>{this.context.intl.messages['title.synopsis.metadata.subtitle']}</em></p>
                <Row>
                    <Col xs={6} md={4}>
                        <div className="checkbox">
                            <FormItem
                                model={model}
                                type="checkbox"
                                attr="metadata"
                                setter={this.onChangeSubscriptionType}
                                label={(<div><strong>{this.context.intl.messages['title.modal.subscribe-client.metadata']}</strong></div>)}
                            />
                        </div>
                    </Col>
                </Row>
            </div>);
        }

        return (
            <Modal show={this.props.show} onHide={this.onClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center text-uppercase">{this.context.intl.messages[modalTitle]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h1 className="h3"><Glyphicon glyph="bell" /> {this.context.intl.messages['title.synopsis.notifications']}</h1>
                        <p><em>{subtitle}</em></p>
                    </div>
                    <Row>
                        <Col xs={6} md={4}>
                            <div className="checkbox">
                                <FormItem
                                    model={model}
                                    type="checkbox"
                                    attr="allVideos"
                                    setter={this.onChangeSubscriptionType}
                                    label={(<div><strong>{this.context.intl.messages['title.modal.subscribe-client.all-video']}</strong></div>)}
                                />
                            </div>
                        </Col>
                        <Col xs={6} md={4}>
                            <div className="checkbox">
                                <FormItem
                                    model={model}
                                    type="checkbox"
                                    attr="fullEpisodes"
                                    setter={this.onChangeSubscriptionType}
                                    label={(<div><strong>{this.context.intl.messages['title.modal.subscribe-client.full-episodes']}</strong></div>)}
                                />
                            </div>
                        </Col>
                        <Col xs={6} md={4}>
                            <div className="checkbox">
                                <FormItem
                                    model={model}
                                    type="checkbox"
                                    attr="images"
                                    setter={this.onChangeSubscriptionType}
                                    label={(<div><strong>{this.context.intl.messages['title.modal.subscribe-client.images']}</strong></div>)}
                                />
                            </div>
                        </Col>
                        {documentsCaption}
                    </Row>
                    {allDocuments}
                    {deliveryContent}
                    {modalFields}
                    {metadataSection}
                    {modalContent && (<hr/>)}
                    {modalContent}
                </Modal.Body>
                <Modal.Footer className="padding-all-20">
                    <Button
                        bsStyle="default"
                        className="pull-left btn-secondary"
                        onClick={this.onClose}>{this.context.intl.messages['common.close']}</Button>
                    <Button
                        bsStyle="primary"
                        className="pull-right"
                        disabled={!this.checkValidation()}
                        onClick={this.onDone}>{this.context.intl.messages['common.save']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

// TODO: update this to a pure component
export default Container.create(Subscribe, {pure: false});

export {Subscribe};
