/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained here./thumbnailsn is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Glyphicon, ResponsiveEmbed} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {PlayerActions} from '../../../player/player-actions';
import Preloader from '../../../preloader';
import ImageLoader, {ImageLoaderPlaceholders} from '../../../titles/asset-title/images/image-loader';
import {GetThumbnail} from '../../../utils/utils';
import {HomeConstants} from '../../home-actions';


class SmallThumbnail extends React.Component {
    static get propTypes() {
        return {
            image: PropTypes.instanceOf(Immutable.Map),
            item: PropTypes.instanceOf(Immutable.Map),
            title: PropTypes.instanceOf(Immutable.Map),
            video: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get defaultProps() {
        return {
            image: undefined,
            item: undefined,
            title: undefined,
            video: undefined
        };
    }

    render() {
        if (!this.props.item) {
            return null;
        }

        let loading = false;
        if (
            !this.props.image &&
            !this.props.title &&
            !this.props.video
        ) {
            loading = true;
        }

        let displayName, href, src;
        switch (true) {
        case !!this.props.image:
            displayName = this.props.image.get('caption');
            href = `titles/${this.props.image.getIn(['titles', 0, 'titleId'])}/images/${this.props.item.get('assetId')}`;
            src = this.props.image.get('largeThumbnailUrl');
            break;
        case !!this.props.video:
            displayName = this.props.video.get('assetComment');
            href = `titles/${this.props.video.getIn(['titles', 0, 'titleId'])}/videos/${this.props.item.get('assetId')}`;
            const thumbnail = GetThumbnail(this.props.video.get('thumbnails'), 400);
            if (thumbnail) {
                src = thumbnail.get('thumbnailUrl');
            }
            break;
        }
        return (
            <div
                className="col-xs-6 col-sm-3 thumbnail-container Cur(p)"
            >
                <Preloader
                    bsSize="sm"
                    fixed
                    show={loading}
                >
                    <Link to={href}>
                        <div className="responsive-container">
                            <div className="vertical-spacer"></div>
                            <div className="img-container">
                                <ImageLoader
                                    alt={displayName}
                                    className="img-responsive"
                                    src={src}
                                />
                            </div>
                        </div>
                    </Link>
                </Preloader>
                <Link className="thumbnail-title Wb(bw)" to={href}><span dangerouslySetInnerHTML={{__html: displayName}}></span></Link>
            </div>
        );
    }
}

class VideoThumbnailItem extends React.Component {
    static get propTypes() {
        return {
            item: PropTypes.object.isRequired,
            video: PropTypes.object.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleVideoClick = this.handleVideoClick.bind(this);
    }

    handleVideoClick(event) {
        event.preventDefault();
        PlayerActions.showPlayer(this.props.item);
    }

    render() {
        const thumbnail = GetThumbnail(this.props.video, 400);
        let src;
        if (thumbnail) {
            src = thumbnail.get('thumbnailUrl');
        }

        return (
            <div className="thumbnail-link video-thumbnail" onClick={this.handleVideoClick}>
                <Glyphicon className="thumbnail-play-icon" glyph="play-circle"/>
                <ImageItem src={src}/>
            </div>
        );
    }
}

class VideoItem extends React.Component {
    static get propTypes() {
        return {
            src: PropTypes.string.isRequired,
        };
    }

    render() {
        return (
            <ResponsiveEmbed a16by9>
                <video
                    autoPlay={true}
                    className="img-responsive"
                    loop={true}
                    muted={true}
                    poster={ImageLoaderPlaceholders.HORIZONTAL}
                >
                    <source
                        src={this.props.src}
                        type="video/mp4"
                    />
                </video>
            </ResponsiveEmbed>
        );
    }
}

class ImageItem extends React.Component {
    static get propTypes() {
        return {
            src: PropTypes.string.isRequired,
        };
    }

    render() {
        return (
            <ResponsiveEmbed a16by9>
                <ImageLoader
                    alt=""
                    className="img-responsive"
                    placeholder={ImageLoaderPlaceholders.HORIZONTAL}
                    src={this.props.src}
                />
            </ResponsiveEmbed>
        );
    }
}

class TitleItem extends React.Component {
    static get propTypes() {
        return {
            item: PropTypes.object.isRequired,
        };
    }

    render() {
        let src = this.props.item.get('imageUrl');
        if (this.props.item.get('imageHorizontalPreviewUrl')) {
            src = this.props.item.get('imageHorizontalPreviewUrl');
        }

        return (
            <Link
                aria-label={this.props.item.get('displayName')}
                className="thumbnail-link video-thumbnail"
                to={`/titles/${this.props.item.get('assetId')}`}
            >
                <ImageItem src={src} />
            </Link>
        );
    }
}

class Thumbnail extends React.Component {
    static get propTypes() {
        return {
            image: PropTypes.instanceOf(Immutable.Map),
            item: PropTypes.instanceOf(Immutable.Map),
            title: PropTypes.instanceOf(Immutable.Map),
            video: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get defaultProps() {
        return {
            image: undefined,
            item: undefined,
            title: undefined,
            video: undefined
        };
    }

    shouldComponentUpdate(nextProps) {
        if (
            this.props.image !== nextProps.image ||
            this.props.item !== nextProps.item ||
            this.props.title !== nextProps.title ||
            this.props.video !== nextProps.video
        ) {
            return true;
        }

        return false;
    }

    render() {
        if (!this.props.item) {
            return null;
        }

        // Only render valid (non empty map) items
        let item;
        if (!Immutable.is(Immutable.Map(), this.props.item)) {
            switch (this.props.item.get('targetType')) {
            case HomeConstants.TARGET_TYPE.TITLE.id:
                item = <TitleItem item={this.props.item} />;
                break;
            case HomeConstants.TARGET_TYPE.ASSET.id:
                switch (this.props.item.get('displayAssetTypeName')) {
                case 'Image':
                    let src;
                    if (this.props.image) {
                        src = this.props.image.get('previewUrl');
                    }
                    item = <ImageItem src={src} />;
                    break;
                case 'Video':
                    let video;
                    if (this.props.video) {
                        video = this.props.video.get('thumbnails');
                    }
                    item = <VideoThumbnailItem item={this.props.item} video={video} />;
                    break;
                }
                break;
            case HomeConstants.TARGET_TYPE.EXTERNAL_WEB_PAGE.id:
            case HomeConstants.TARGET_TYPE.INTERNAL_PAGE.id:
                let content;
                if (this.props.item.get('videoUrl')) {
                    content = <VideoItem src={this.props.item.get('videoUrl')} />;
                } else {
                    content = <ImageItem src={this.props.item.get('imageUrl')} />;
                }

                item = (
                    <a
                        aria-label={this.props.item.get('displayName')}
                        className="thumbnail-link video-thumbnail"
                        href={this.props.item.get('targetUrl')}
                    >
                        {content}
                    </a>
                );
                break;
            }
        }

        return (
            <Col
                className="thumbnail-container"
                sm={6}
                xs={12}
            >
                {item}
            </Col>
        );
    }
}

export {
    ImageItem,
    SmallThumbnail,
    Thumbnail,
    TitleItem,
    VideoItem,
    VideoThumbnailItem,
};
