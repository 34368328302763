/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import React from 'react';
import {FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import Config from '../../config/config';
import {FormSection, FormRow, FormItem} from '../../form/form';
import {MessagesContext} from '../../messages/messages-context';
import {SignUpActions, SignUpConstants} from '../signup-actions';
import SignUpStore from '../signup-store';

export class AdditionalAccessContent extends React.Component {
    static calculateState() {
        return {
            accessType: SignUpStore.getState().get('accessType'),
            accessTypeSelected: SignUpStore.getState().get('accessTypeSelected'),
            additionalAccess: SignUpStore.getState().get('additionalAccess'),
        };
    }

    static getStores() {
        return [SignUpStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleSelectAccessType = this.handleSelectAccessType.bind(this);
        this.handleSelectPartners = this.handleSelectPartners.bind(this);

        return;
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.additionalAccess !== prevState.additionalAccess) {
            this.requestAdditionalAccess(this.state.additionalAccess);
        }
        return;
    }

    static contextType = MessagesContext;

    handleSelectAccessType(accessTypeSelected) {
        if (!accessTypeSelected) {
            accessTypeSelected = [];
        }
        let additionalAccess = this.state.additionalAccess.toJS();
        this.state.accessType.forEach(type => {
            if (accessTypeSelected.findIndex(selected => selected.id === type.get('id')) === -1) {
                delete additionalAccess[type.get('id')];
            }
        });
        SignUpActions.update('additionalAccess', Immutable.fromJS(additionalAccess));

        if (accessTypeSelected.findIndex(type => type.id === SignUpConstants.ACCESS_TYPE.VIDEOS_PLACE_ORDERS.id) !== -1) {
            SignUpActions.update(`additionalAccess.${SignUpConstants.ACCESS_TYPE.VIDEOS_PLACE_ORDERS.id}`, true);
        }
        if (accessTypeSelected.findIndex(type => type.id === SignUpConstants.ACCESS_TYPE.PHOTOS_TO_DOWNLOAD.id) !== -1) {
            SignUpActions.update(`additionalAccess.${SignUpConstants.ACCESS_TYPE.PHOTOS_TO_DOWNLOAD.id}`, true);
        }
        if (accessTypeSelected.findIndex(type => type.id === SignUpConstants.ACCESS_TYPE.VIDEOS_TO_VIEW.id) !== -1) {
            SignUpActions.update(`additionalAccess.${SignUpConstants.ACCESS_TYPE.VIDEOS_TO_VIEW.id}`, true);
        }
        if (accessTypeSelected.findIndex(type => type.id === SignUpConstants.ACCESS_TYPE.PHOTOS_TO_VIEW.id) !== -1) {
            SignUpActions.update(`additionalAccess.${SignUpConstants.ACCESS_TYPE.PHOTOS_TO_VIEW.id}`, true);
        }
        if (accessTypeSelected.findIndex(type => type.id === SignUpConstants.ACCESS_TYPE.TITLE_METADATA.id) !== -1) {
            SignUpActions.update(`additionalAccess.${SignUpConstants.ACCESS_TYPE.TITLE_METADATA.id}`, true);
        }
        if (accessTypeSelected.findIndex(type => type.id === SignUpConstants.ACCESS_TYPE.ADDITIONAL_PARTNERS.id) !== -1) {
            this.updateList(`additionalAccess.${SignUpConstants.ACCESS_TYPE.ADDITIONAL_PARTNERS.id}`, additionalAccess[SignUpConstants.ACCESS_TYPE.ADDITIONAL_PARTNERS.id]);
        }

        this.updateList('accessTypeSelected', accessTypeSelected);
    }

    handleSelectPartners(partnersList) {
        this.updateList(`additionalAccess.${SignUpConstants.ACCESS_TYPE.ADDITIONAL_PARTNERS.id}`, partnersList);
    }

    handleSetAdditionalAccessData(type, value) {
        SignUpActions.update(`additionalAccess.${type}`, value);
    }

    requestAdditionalAccess(additionalAccess) {
        additionalAccess = additionalAccess.toJS();

        let additionalAccessData = '';
        Object.keys(additionalAccess).forEach(key => {
            if (key === SignUpConstants.ACCESS_TYPE.VIDEOS_PLACE_ORDERS.id ||
                key === SignUpConstants.ACCESS_TYPE.VIDEOS_TO_VIEW.id ||
                key === SignUpConstants.ACCESS_TYPE.PHOTOS_TO_DOWNLOAD.id ||
                key === SignUpConstants.ACCESS_TYPE.PHOTOS_TO_VIEW.id ||
                key === SignUpConstants.ACCESS_TYPE.TITLE_METADATA.id) {
                additionalAccessData = `${additionalAccessData}${SignUpConstants.ACCESS_TYPE[key].name}, `;
            } else {
                if (key === SignUpConstants.ACCESS_TYPE.ADDITIONAL_PARTNERS.id) {
                    additionalAccess[key] = additionalAccess[key].map(value => value.name).join(', ');
                }
                additionalAccessData = `${additionalAccessData}${SignUpConstants.ACCESS_TYPE[key].name}  ${additionalAccess[key]}, `;
            }

        });

        if (additionalAccessData.charAt(additionalAccessData.length - 2) === ',') {
            additionalAccessData = additionalAccessData.slice(0, -2);
        }

        setTimeout(() => {
            SignUpActions.updateInSignUp('requestAdditionalSecurity', additionalAccessData);
        }, 5);

    }

    updateList(property, list) {
        if (!list || list.length === 0) {
            SignUpActions.update(property, Immutable.List());
            return;
        }

        SignUpActions.update(property, Immutable.fromJS(list));

        return;
    }

    render() {
        const partnersList = Object.values(Config.Partners).filter(partner => partner.showInAdditionalPartners);
        const {accessTypeSelected, additionalAccess, accessType} = this.state;
        const accessTypeConstants = SignUpConstants.ACCESS_TYPE;

        let partnerSites, other, businessJustification, stationsCallsigns;
        /* istanbul ignore if */
        if (!accessTypeSelected.isEmpty()) {
            if (accessTypeSelected.findIndex(type => type.get('id') === accessTypeConstants.ADDITIONAL_PARTNERS.id) !== -1) {
                partnerSites = (
                    <FormGroup>
                        <h2 className="h4">{this.context.intl.messages['account.tab.info.request.partner-sites']}&nbsp;<span className="text-red">*</span></h2>
                        <p><em>{this.context.intl.messages['account.tab.info.request.partner-sites.description']}</em></p>
                        <ReactSelect
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={true}
                            name="partners"
                            onChange={this.handleSelectPartners}
                            options={partnersList}
                            value={this.state.additionalAccess.toJS().ADDITIONAL_PARTNERS}
                        />
                    </FormGroup>
                );
            }

            if (accessTypeSelected.findIndex(type => type.get('id') === accessTypeConstants.OTHER.id) !== -1) {
                other = (
                    <FormRow>
                        <FormGroup>
                            <h2 className="h4">{this.context.intl.messages['account.tab.info.request.other']}&nbsp;<span className="text-red">*</span></h2>
                            <p><em>{this.context.intl.messages['account.tab.info.request.other.description']}</em></p>
                            <FormItem
                                attr={`${accessTypeConstants.OTHER.id}`}
                                model={additionalAccess}
                                md={6}
                                placeholder={this.context.intl.messages['account.tab.info.request.other.placeholder']}
                                required
                                setter={this.handleSetAdditionalAccessData}
                                type="text"
                            />
                        </FormGroup>
                    </FormRow>
                );
            }

            if (accessTypeSelected.findIndex(type => type.get('id') !== accessTypeConstants.VIDEOS_TO_VIEW.id) !== -1 &&
                accessTypeSelected.findIndex(type => type.get('id') !== accessTypeConstants.STATION_DOCUMENTS.id) !== -1) {
                businessJustification = (
                    <FormRow>
                        <FormGroup>
                            <h2 className="h4">{this.context.intl.messages['signup.additional-access-content.business-justification']}&nbsp;<span className="text-red">*</span></h2>
                            <FormItem
                                attr={`${accessTypeConstants.BUSINESS_JUSTIFICATION.id}`}
                                model={additionalAccess}
                                md={6}
                                placeholder={this.context.intl.messages['signup.additional-access-content.business-justification.placeholder']}
                                required
                                setter={this.handleSetAdditionalAccessData}
                                type="text"
                            />
                        </FormGroup>
                    </FormRow>
                );
            }

            if (accessTypeSelected.findIndex(type => type.get('id') === accessTypeConstants.STATION_DOCUMENTS.id) !== -1) {
                stationsCallsigns = (
                    <FormRow>
                        <FormGroup>
                            <h2 className="h4">{this.context.intl.messages['signup.additional-access-content.station-documents']}&nbsp;<span className="text-red">*</span></h2>
                            <FormItem
                                attr={`${accessTypeConstants.STATION_DOCUMENTS.id}`}
                                model={additionalAccess}
                                md={6}
                                placeholder={this.context.intl.messages['signup.additional-access-content.station-documents.placeholder']}
                                required
                                setter={this.handleSetAdditionalAccessData}
                                type="text"
                            />
                        </FormGroup>
                    </FormRow>
                );
            }
        }

        return (<div>
            <FormSection>
                <label>{this.context.intl.messages['account.tab.info.request.access-type-question']}&nbsp;<span className="text-red">*</span></label>
                <FormRow>
                    <FormGroup>
                        <p><em>{this.context.intl.messages['account.tab.info.request.select-access-type']}</em></p>
                        <ReactSelect
                            getOptionLabel={data => data.name}
                            getOptionValue={data => data.id}
                            isClearable={true}
                            isMulti={true}
                            onChange={this.handleSelectAccessType}
                            options={accessType.toJS().filter(accessTypeOption => !accessTypeOption.hideFromDropdown)}
                            value={accessTypeSelected.toJS()}
                        />
                    </FormGroup>
                </FormRow>
                {partnerSites}
                {other}
                {businessJustification}
                {stationsCallsigns}
            </FormSection>
        </div>
        );
    }
}

export default Container.create(AdditionalAccessContent);
