/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Glyphicon, Modal} from 'react-bootstrap';

import style from './synopses.module.less';
import {DownloadActions} from '../../../common/download/download-actions';
import {MessagesContext} from '../../../messages/messages-context';
import {TitleConstants} from '../../title-actions';

class Synopses extends Component {
    static get propTypes() {
        return {
            close: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            synopses: PropTypes.instanceOf(Immutable.List),
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            synopses: Immutable.List()
        };
    }

    constructor(props) {
        super(props);

        this.handleExportMultipleSynopsis = this.handleExportMultipleSynopsis.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onDone = this.onDone.bind(this);
    }

    static contextType = MessagesContext;

    handleExportMultipleSynopsis() {
        DownloadActions.startDownloadExecution(`title/${this.props.title.get('id')}/synopsis/export-rtf`);
    }

    onClose() {
        this.props.close();
    }

    onDone() {
        this.onClose();
    }

    render() {
        // we need to take the default synopsis from the title since it's still being saved with the old endpoint
        let synopsesArray = [];
        let defaultSynopsisObj = {
            log180Lines: this.props.title.get('log180Lines'),
            log60Lines: this.props.title.get('log60Lines'),
            shortSynopsis: this.props.title.get('shortSynopsis'),
            synopsis: this.props.title.get('synopsis'),
            usageType: TitleConstants.SYNOPSIS_TYPES.DEFAULT.id
        };
        synopsesArray.push(defaultSynopsisObj);

        this.props.synopses.forEach(s => {
            let sObj = {
                log180Lines: s.get('log180Lines'),
                log60Lines: s.get('log60Lines'),
                shortSynopsis: s.get('shortSynopsis'),
                synopsis: s.get('synopsis'),
                usageType: s.get('usageType')
            };
            synopsesArray.push(sObj);
        });

        let bodyContent = synopsesArray.map((s, key) => {
            if (!s.log180Lines && !s.log60Lines && !s.shortSynopsis && !s.synopsis) {
                return;
            }
            let s180, s60, short, synopsis;
            if (s.log180Lines) {
                s180 = <li>
                    <p><u>{this.context.intl.messages['title.modal.synopses.log180']}</u></p>
                    <p dangerouslySetInnerHTML={{__html: s.log180Lines}} />
                </li>;
            }
            if (s.log60Lines) {
                s60 = <li>
                    <p><u>{this.context.intl.messages['title.modal.synopses.log60']}</u></p>
                    <p dangerouslySetInnerHTML={{__html: s.log60Lines}} />
                </li>;
            }
            if (s.shortSynopsis) {
                short = <li>
                    <p><u>{this.context.intl.messages['title.modal.synopses.short']}</u></p>
                    <p dangerouslySetInnerHTML={{__html: s.shortSynopsis}} />
                </li>;
            }
            if (s.synopsis) {
                synopsis = <li>
                    <p dangerouslySetInnerHTML={{__html: s.synopsis}} />
                </li>;
            }

            return (
                <ul className={style.synopses} key={key}>
                    <li>{TitleConstants.SYNOPSIS_TYPES[s.usageType].name}</li>
                    {synopsis}
                    {short}
                    {s180}
                    {s60}
                </ul>
            );
        });

        return (
            <Modal show={this.props.show} onHide={this.onClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center text-uppercase">{this.context.intl.messages['title.modal.synopses.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h1 className="h3">{this.context.intl.messages['title.modal.synopses.subtitle']}</h1>
                    <em>{this.context.intl.messages['title.modal.synopses.text']}</em>
                    <div>
                        {bodyContent}
                    </div>
                </Modal.Body>
                <Modal.Footer className="padding-all-20">
                    <Button
                        bsStyle="default"
                        className="pull-left btn-secondary"
                        onClick={this.onClose}>{this.context.intl.messages['common.close']}
                    </Button>
                    <Button
                        bsStyle="primary"
                        className="pull-right"
                        onClick={this.handleExportMultipleSynopsis}>
                        <Glyphicon glyph="download-alt" /><span>&nbsp;{this.context.intl.messages['common.download']}</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Synopses;
export {Synopses};
