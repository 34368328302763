/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';

import {FACET_NAMES} from '../../common/facets/constants';
import DialogueFacetSelect from '../../common/facets/dialogue-facet-select';
import FacetSelect from '../../common/facets/facet-select';
import useGenericFacet from '../../common/facets/hooks/use-generic-facet';
import omit from '../../common/utils/omit';
import Dropdown from '../../form/dropdown';

const DialogueFacet = (props) => {
    const {isActive, ...facetProps} = useGenericFacet(FACET_NAMES.DIALOGUE);

    const intl = useIntl();
    const title = intl.messages['search.filters.navbar.titles.dialogue'];

    return <Dropdown
        className={{active: isActive}}
        title={title}
    >
        <li className="padding-all-10">
            <DialogueFacetSelect
                className="search-criteria-input"
                {...props}
                {...facetProps}
            />
        </li>
    </Dropdown>;
};

DialogueFacet.propTypes = {
    ...FacetSelect.propTypes,
    onInputChange: PropTypes.func.isRequired,
    options: PropTypes.instanceOf(Immutable.List).isRequired,
};

DialogueFacet.defaultProps = omit(FacetSelect.defaultProps, ['onInputChange', 'options']);

export default memo(DialogueFacet);
