/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../flux-helpers';
import Request from '../request';
import {TitleConstants} from '../titles/title-actions';

const CONSTANTS = {
    GET: {
        SUCCESS: 'event_actions.get.success',
    }
};

class EventActions {
    get(eventIds) {
        if (eventIds instanceof Immutable.List) {
            eventIds = eventIds.toJS();
        } else {
            eventIds = eventIds.split(',').map(Number);
        }
        if (eventIds && eventIds.length > 0) {
            let eventTitles = [];
            Promise.all(eventIds.map(id => Request.get(`event/${id}`).then(eventRes => {
                eventRes.body.titles.map(title => {
                    //only keeping the !default synopsis type
                    if (title.synopsisUsageType !== TitleConstants.SYNOPSIS_TYPES.DEFAULT.id) {
                        eventTitles.push({titleId: title.titleId, synopsisUsageType: title.synopsisUsageType});
                    }
                });
                return;
            }).then(() => {
                Dispatcher.dispatch({
                    actionType: CONSTANTS.GET.SUCCESS,
                    eventTitles: Immutable.fromJS(eventTitles)
                });
            }).catch(err => {
                console.error('Error getting events.', err);
                return {body: []};
            })));
        }
    }
}

const actions = new EventActions();

export {
    actions as EventActions,
    CONSTANTS as EventConstants,
};
