/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
import {v4 as UUID} from 'uuid';

import ClipListThumbnail from './clip-list-thumbnail/index';
import styles from './clips-list.module.less';
import {RightsConstants, CalculateRights, RightsTooltip} from '../../assets/asset-rights';
import {MessagesContext} from '../../messages/messages-context';
import {ClipActions} from '../../titles/clip/clip-actions';
import {countOfHtmlElements} from '../../utils/query-selector-all';
import {IsNotTriggerKey} from '../../utils/utils';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/app/dataTables.brainiac.css');

// eslint-disable-next-line @typescript-eslint/no-var-requires
const jQuery = require('jquery');
require('datatables.net-responsive-bs');

const ACTIONS = [
    {className: 'add-clip-to-cart', action: 'addClipToOrder'},
    {className: 'open-title', action: 'openTitlePage'},
    {className: 'open-parent-title', action: 'openParentTitlePage'},
    {className: 'video-play-single', action: 'playClip'},
];

export default class ClipsList extends React.PureComponent {

    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool.isRequired,
            clips: PropTypes.instanceOf(Immutable.List).isRequired,
            titleId: PropTypes.number,
            thumbnails: PropTypes.instanceOf(Immutable.List),
        };
    }

    static get defaultProps() {
        return {
            canAddToCart: false,
            titleId: null,
            thumbnails: Immutable.List(),
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            redirectToTitleId: null
        };

        this.tableId = `list-clips-table-${UUID()}`;

        this.handleRowClick = this.handleRowClick.bind(this);
        this.playClip = this.playClip.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    componentDidMount() {
        const {thumbnails} = this.props;
        const intl = this.context.intl;

        this.$table = jQuery(`#${this.tableId}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: '20px'
            }, {
                className: 'text-center',
                targets:   -2,
            }, {
                className: 'text-center',
                targets:   -3,
            }, {
                className: 'text-center',
                targets:   -4,
            }, {
                className: 'text-center',
                targets:   -5,
            }, {
                targets: 'no-sort',
                orderable: false
            }, {
                targets: 0,
                createdCell: /* istanbul ignore next */function(td, clip) {
                    ReactDOM.render(<ClipListThumbnail intl={intl} clip={clip} thumbnails={thumbnails} />, td);
                }
            }, {
                targets: 1,
                width: '120px',
            }],
            info: false,
            paging: false,
            ordering: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        this.updateRows();
    }

    componentDidUpdate() {
        this.updateRows();
    }

    static contextType = MessagesContext;

    addClipToOrder(clip) {
        const titleId = this.props.titleId || clip.getIn(['browseTitleList', 0, 'id']);
        ClipActions.createClipFromMoment(clip.set('titleId', titleId));
    }

    findClip(event) {
        const {clips} = this.props;
        const id = event.target.getAttribute('data-asset-id');
        return clips.find(v => v.get('id') === id);
    }

    openParentTitlePage(clip) {
        this.setState(/* istanbul ignore next */() => /* istanbul ignore next */({
            redirectToTitleId: clip.get('rootTitleId'),
        }));
    }

    openTitlePage(clip) {
        this.setState(/* istanbul ignore next */() => /* istanbul ignore next */({
            redirectToTitleId: clip.getIn(['browseTitleList', 0, 'id']),
        }));
    }

    handleRowClick(event) {
        if (event && IsNotTriggerKey(event)) {
            event.preventDefault();

            return;
        }

        const clip = this.findClip(event);
        /* istanbul ignore else */
        if (!clip) {
            return;
        }
        /* istanbul ignore next */
        ACTIONS.some(({className, action}) => {
            const isCurrent = !!~event.target.className.indexOf(className);
            if (isCurrent) {
                event.preventDefault();
                this[action](clip);
            }
            return isCurrent;
        });
    }

    playClip(clip) {
        const titleId = this.props.titleId || clip.getIn(['browseTitleList', 0, 'id']);
        ClipActions.playAutoClip(clip, titleId);
    }

    updateRows() {
        const clipToRowConverter = createConverterClip2HTML(this.context.intl.messages, this.props.canAddToCart);

        this.$tableAPI.clear();
        (this.props.clips || []).forEach(clip => {
            const row = clipToRowConverter(clip);
            this.$tableAPI.row.add(row);
        });
        this.$tableAPI.draw(false);
    }

    render() {
        const {canAddToCart, clips} = this.props;
        const intl = this.context.intl.messages;
        let opacity = 0;
        if (clips.size) {
            opacity = 1;
        }

        if (this.state.redirectToTitleId) {
            return <Redirect to={`/titles/${this.state.redirectToTitleId}/moments`} />;
        }

        return (
            <div id="DataTables_Table_0_wrapper" className={`dataTables_wrapper form-inline dt-bootstrap no-footer ${styles.tableWrap}`} style={{opacity}}>
                <table className="table table-striped datatable-responsive dataTable no-footer dtr-column collapsed vertical-align-middle" id={this.tableId}>
                    <thead>
                        <tr role="row">
                            <th>
                                {intl['titles.moments.column.thumbnail']}
                            </th>
                            <th>
                                {intl['titles.moments.column.moment']}
                            </th>
                            {canAddToCart && <th className="no-sort icon-column"><span className="glyphicon glyphicon-shopping-cart"/></th>}
                            <th className="no-sort icon-column">
                                <span className="glyphicon glyphicon-globe"/>
                            </th>
                            <th style={{width: '60px'}}>
                                {intl['titles.moments.column.trt']}
                            </th>
                            <th>
                                {intl['titles.moments.column.title']}
                            </th>
                            <th>
                                {intl['titles.moments.column.parent-title']}
                            </th>
                            <th>
                                {intl['titles.moments.column.type']}
                            </th>
                            <th style={{width: '30px'}}>
                                {intl['titles.moments.column.dialogue']}
                            </th>
                            <th style={{width: '30px'}}>
                                {intl['titles.moments.column.characters']}
                            </th>
                            <th style={{width: '30px'}}>
                                {intl['titles.moments.column.settings']}
                            </th>
                            <th style={{width: '30px'}}>
                                {intl['titles.moments.column.tags']}
                            </th>
                            <th className="control icon-column no-sort" style={{width: '30px'}}/>
                        </tr>
                    </thead>
                    <tbody
                        onClick={this.handleRowClick}
                        onKeyUp={this.handleRowClick}
                        role="button"
                        tabIndex={0}
                    />
                </table>
            </div>
        );
    }
}

function createConverterClip2HTML(intl, canAddToCart) {
    function descriptionColumn(clip) {
        const {displayName, id} = clip.toJS();
        return (
            `<a href="#">
                <i class="far fa-play-circle video-play-single" data-asset-id="${id}"></i>
                <span class="video-play-single" data-asset-id="${id}">${displayName.replace(/@\s/, '@&nbsp;')}</span>
            </a>`
        );
    }

    function trtColumn(clip) {
        return `<nobr>${clip.get('clipDurationInSec')} sec</nobr>`;
    }

    function withTooltip(classNames, title, children = '') {
        return `<span class="${classNames}" data-toggle="tooltip" data-placement="top" data-html="true" title="${title}">${children}</span>`;
    }

    function rightsColumn(clip) {
        const hasRights = CalculateRights(clip) === RightsConstants.RIGHTS.CLEARED;
        const classNames = ClassNames(
            'glyphicon', 'video-thumbnail-tooltip',
            {
                'glyphicon-globe': hasRights,
                'glyphicon-warning-sign': !hasRights
            }
        );

        return withTooltip(classNames, RightsTooltip(clip, intl));
    }

    function dialogueColumn(clip) {
        const dialogue = clip.getIn(['tags', 'dialogue']);
        const countOfMatches = countOfHtmlElements(dialogue);
        /* istanbul ignore else */
        if (!countOfMatches) {
            return '';
        }
        const body = `<span class="glyphicon glyphicon-comment"></span> ${countOfMatches}`;
        return withTooltip('label label-primary-outline', dialogue.replace(/"/g, '\''), body);
    }

    function commonTagsColumn(tags, classNames) {
        const count = tags.size;
        /* istanbul ignore if */
        if (!count) {
            return '';
        }
        const body = `<span class="${classNames}"></span> ${count}`;
        return withTooltip('label label-primary-outline', tags.join(', '), body);
    }

    function charactersColumn(clip) {
        return commonTagsColumn(clip.getIn(['tags', 'characters']), 'glyphicon glyphicon-user');
    }

    function settingsColumn(clip) {
        return commonTagsColumn(clip.getIn(['tags', 'settings']), 'glyphicon glyphicon-map-marker');
    }


    function tagsColumn(clip) {
        return commonTagsColumn(clip.getIn(['tags', 'tags']), 'glyphicon glyphicon-tag');
    }

    function parentTitleColumn(clip) {
        const rootTitleId = clip.get('rootTitleId');
        const rootTitleDisplayName = clip.get('rootTitleDisplayName');

        const path = `/titles/${rootTitleId}/moments`;

        return (
            `<a href="${path}" class="open-parent-title" data-asset-id="${clip.get('id')}">
                ${rootTitleDisplayName}
            </a>`
        );
    }

    function titleColumn(clip) {
        const path = `/titles/${clip.getIn(['browseTitleList', 0, 'id'])}/moments`;
        return (
            `<a href="${path}" class="open-title"  data-asset-id="${clip.get('id')}">
                ${clip.getIn(['browseTitleList', 0, 'displayName'])}
            </a>`
        );
    }

    function thumbnailColumn(clip) {
        // Send only data to the cell
        // Rendering will be handled by createdCell default
        return clip;
    }

    function typeColumn(clip) {
        const {type} = clip.toJS();

        const badgeConfig = {
            MOMENT: {
                className: styles.badgeReel,
                icon: '<span class="glyphicon glyphicon-flash"></span>',
                label: intl['titles.moments.column.auto'],
                tooltip: intl['titles.moments.column.automatically_suggested_moment']
            },
            HIGHLIGHT: {
                className: styles.badgeAuto,
                icon: '<span class="fa fa-cut"></span>',
                label: intl['titles.moments.column.reel'],
                tooltip: intl['titles.moments.column.highlight_reel']
            },
        };

        const body = `<div class="${styles.typeBadge} ${badgeConfig[type]?.className ?? ''}">
            ${badgeConfig[type]?.icon}
            ${badgeConfig[type]?.label ?? ''}
        </div>`;

        return withTooltip('', badgeConfig[type]?.tooltip, body);
    }

    return function convertClipToHTML(clip) {
        const id = clip.get('id');

        const row = [
            thumbnailColumn(clip),
            descriptionColumn(clip),
            rightsColumn(clip),
            trtColumn(clip),
            titleColumn(clip),
            parentTitleColumn(clip),
            typeColumn(clip),
            dialogueColumn(clip),
            charactersColumn(clip),
            settingsColumn(clip),
            tagsColumn(clip),
            '',
        ];
        /* istanbul ignore else */
        if (canAddToCart) {
            const addToCart = (
                `<a href="#" aria-label="${intl['title.player.add-new-clip']}">
                    <span data-asset-id="${id}" class="glyphicon glyphicon-shopping-cart add-clip-to-cart"></span>
                </a>`
            );
            row.splice(2, 0, addToCart);
        }

        return row;
    };
}

