/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';

import {ClipsFacetsActions} from './clips-facets-actions';
import style from './clips-facets-block.module.less';
import ClipsFacetsStore from './clips-facets-store';
import DialogueFacet from './facets/dialogue-facet';
import {DialogueFacetActions} from './facets/dialogue-facet-actions';
import DialogueFacetStore from './facets/dialogue-facet-store';
import MomentType from './facets/moment-type';
import SettingFacet from './facets/setting-facet';
import TagFacet from './facets/tag-facet';
import TalentCharacterFacet from './facets/talent-character-facet';
import {MessagesContext} from '../../../messages/messages-context';

export class ClipsFacetsBlock extends React.Component {

    static getStores() {
        return [
            ClipsFacetsStore,
            DialogueFacetStore,
        ];
    }

    static calculateState() {
        return {
            combinedTalentCharacterOptions: ClipsFacetsStore.getState().getIn(['data', 'videoCombinedTalentCharacterFacets']),
            settingOptions: ClipsFacetsStore.getState().getIn(['data', 'videoSettingFacets']),
            tagOptions: ClipsFacetsStore.getState().getIn(['data', 'videoTagFacets']),
            dialogueOptions: DialogueFacetStore.getState().get('data'),
            isDialogueLoading: DialogueFacetStore.getState().get('loading'),
        };
    }

    componentDidMount() {
        this.getFacets();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location?.search !== this.props.location?.search) {
            this.getFacets();
        }
    }

    static contextType = MessagesContext;

    getFacets() {
        const query = QueryString.parse(location.search);
        const searchTerm = query.cqs || '';

        if (searchTerm) {
            ClipsFacetsActions.getFacetsByTitle(searchTerm, this.props.titleId);
        }
    }

    /**
     * @param {string} query
     * @param {string} value
     */
    getDialogueFacet(query, value) {
        DialogueFacetActions.getDialogueFacet(query, this.props.titleId, value);
    }

    render() {
        return (
            <div className={style.blockBox}>
                <h4>{this.context.intl.messages['titles.moments.facets.title']}</h4>
                <hr/>
                <MomentType />
                <hr/>
                <TalentCharacterFacet options={this.state?.combinedTalentCharacterOptions} />
                <hr/>
                <SettingFacet options={this.state?.settingOptions} />
                <hr/>
                <DialogueFacet
                    isLoading={this.state?.isDialogueLoading}
                    onInputChange={this.getDialogueFacet.bind(this)}
                    options={this.state?.dialogueOptions}
                />
                <hr/>
                <TagFacet options={this.state?.tagOptions} />
            </div>
        );
    }
}

ClipsFacetsBlock.propTypes = {
    location: PropTypes.object.isRequired,
    titleId: PropTypes.string.isRequired,
};

export default Container.create(ClipsFacetsBlock);
