/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import DocumentTitle from '../../common/document-title';
import Validations from '../../common/validations/validations';
import {FormRow, FormItem} from '../../form/form';
import LandingLayout from '../../layout/landing';
import {MessagesContext} from '../../messages/messages-context';
import {Alert} from '../../notification/alert';
import {AlertsWarnings} from '../../notification/alert-warning';
import {NotificationActions} from '../../notification/notification-actions';
import {RouterActions} from '../../router/router-actions';
import {SessionActions} from '../../session/session-actions';
import SessionStore from '../../session/session-store';

class AdditionalRequirement extends React.Component {
    static get propTypes() {
        return {
            icon: PropTypes.string,
            text: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            icon: 'fa-ban',
        };
    }

    render() {
        return (
            <div>
                <small>
                    <i className={ClassNames('far', this.props.icon)}/>
                    &nbsp;{this.props.text}
                </small>
            </div>
        );
    }
}

class ResetPassword extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            authUser: SessionStore.getState().get('authUser'),
            passwordReset: SessionStore.getState().get('passwordReset')
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showPassword: false,
            showAdditionalRequirements: false
        }, this.constructor.calculateState());
        this.handleShowPassword = this.handleShowPassword.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.handleToggleAdditionalRequirements = this.handleToggleAdditionalRequirements.bind(this);
    }

    componentDidMount() {
        const query = QueryString.parse(this.props.location.search);

        if (!query.token) {
            // if there is no token UUID, it was an invalid reset-password instance; show alert & redirect to login after timeout
            NotificationActions.showAlertDanger('reset-password.set-new.password.token.failed');

            setTimeout(() => {
                NotificationActions.clearQueue();
                RouterActions.redirect('/login', true);
            }, 5000);

            return;
        }
    }

    static contextType = MessagesContext;

    handleShowPassword() {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }

    resetPassword() {
        const query = QueryString.parse(this.props.location.search);

        SessionActions.setNewPassword(query.token, this.state.passwordReset.get('password'));

        return;
    }

    handleToggleAdditionalRequirements() {
        this.setState(prevState => ({
            showAdditionalRequirements: !prevState.showAdditionalRequirements
        }));
    }

    render() {
        const inProgress = this.state.passwordReset.get('inProgress');

        let resetMessage = 'reset-password.message';
        let resetButtonMessage = 'reset-password.button.change';
        const query = QueryString.parse(this.props.location.search);
        /* istanbul ignore next */
        if (query.firstTime) {
            resetMessage = 'reset-password.message.set';
            resetButtonMessage = 'reset-password.button.set';
        }

        let changeButtonProgress;
        /* istanbul ignore next */
        if (inProgress) {
            if (query.firstTime) {
                resetButtonMessage = 'reset-password.button.set.in-progress';
            } else {
                resetButtonMessage = 'reset-password.button.change.in-progress';
            }
            changeButtonProgress = (
                <i className="far fa-sync-alt fa-spin" />
            );
        }

        // Important! The order of password first and confirmPassword second
        // matters. It will display password messages first and the
        // "passwords must match" message last. This is by UX rule.
        const passwordValidations = {
            password: {
                label: 'reset-password.form.password',
                validations: [
                    Validations.required,
                    Validations.passwordHasUppercase,
                    Validations.passwordHasLowercase,
                    Validations.passwordHasNumber,
                    Validations.passwordHasSymbolOrPunctuationMark,
                    Validations.passwordLength,
                    Validations.passwordNotCommon
                ]
            },
            confirmPassword: {
                label: 'reset-password.form.confirmPassword',
                validations: [Validations.required, Validations.stringMatch(
                    () => 'Match the confirm password field',
                    /* istanbul ignore next */() => /* istanbul ignore next */this.state.passwordReset.get('password'),
                    true
                )]
            }
        };

        let validations = Validations.validate(this.state.passwordReset, passwordValidations, true);
        const disableForm = validations.some(v => !v.valid);

        const additionalRequirements = <div className="padding-x-20">
            <hr style={{'border-color': 'rgba(170, 170, 170, 1)'}}/>
            <p>{this.context.intl.messages['reset-password.additional-requirements.note']}</p>
            <p style={{'font-size': '12px'}}>{this.context.intl.messages['reset-password.additional-requirements.text']}</p>
        </div>;

        let passwordIcon = 'eye-open';
        let passwordType = 'password';
        /* istanbul ignore if */
        if (this.state.showPassword) {
            passwordIcon = 'eye-close';
            passwordType = 'text';
        }
        return (
            <DocumentTitle
                message="document-titles.reset-password"
            >
                <LandingLayout>
                    <Col className="box" sm={6} smOffset={3}>
                        <Alert/>
                        <AlertsWarnings
                            colorIndicators={true}
                            title={this.context.intl.messages['reset-password.set-new.password.validation-check.title']}
                            type="password-reset"
                            validations={validations}>
                            {additionalRequirements}
                        </AlertsWarnings>
                        <Row componentClass="header">
                            <Col sm={12}>
                                <p>{this.context.intl.messages[resetMessage]}</p>
                            </Col>
                        </Row>
                        <form>
                            {/* avoiding autocomplete for first input of type password */}
                            <input type="password" className="hide"/>

                            <FormRow>
                                <FormItem
                                    attr="password"
                                    icon={passwordIcon}
                                    onIconClick={this.handleShowPassword}
                                    model={this.state.passwordReset}
                                    placeholder={this.context.intl.messages['reset-password.form.password.placeholder']}
                                    setter={SessionActions.updateAttr.bind(SessionActions, 'passwordReset')}
                                    type={passwordType}
                                    validations={passwordValidations.password.validations}
                                    value={this.state.passwordReset.get('password')}
                                />
                            </FormRow>
                            <FormRow>
                                <FormItem
                                    attr="confirmPassword"
                                    icon={passwordIcon}
                                    onIconClick={this.handleShowPassword}
                                    model={this.state.passwordReset}
                                    placeholder={this.context.intl.messages['reset-password.form.confirmPassword.placeholder']}
                                    setter={SessionActions.updateAttr.bind(SessionActions, 'passwordReset')}
                                    type={passwordType}
                                    value={this.state.passwordReset.get('confirmPassword')}
                                />
                            </FormRow>
                            <Row>
                                <Col sm={12}>
                                    <Button block
                                        bsSize="lg"
                                        bsStyle="primary"
                                        disabled={disableForm || inProgress}
                                        onClick={this.resetPassword}>
                                        {this.context.intl.messages[resetButtonMessage]} {changeButtonProgress}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </LandingLayout>
            </DocumentTitle>
        );
    }
}

export default Container.create(ResetPassword);
export {ResetPassword, AdditionalRequirement};
