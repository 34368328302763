/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Suspense} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Route, Switch} from 'react-router-dom';

import {PageLayoutActions} from './page-layout-actions';
import PageLayoutStore from './page-layout-store';
import AccountSection from '../../account/account';
import Download from '../../common/download';
import Config from '../../config/config';
import CountdownTimer from '../../counter';
import AccessDenied from '../../errors/access-denied';
import AssetNotFound from '../../errors/asset-not-found';
import ComingSoon from '../../errors/coming-soon';
import NotFound from '../../errors/not-found';
import Help from '../../help/help';
import Home from '../../home';
import CountryOverviews from '../../list-documents/country-overviews';
import ListDocuments from '../../list-documents/list-documents';
import {ListDocumentsConstants} from '../../list-documents/list-documents-actions';
import Vam from '../../list-documents/vam';
import {MessagesContext} from '../../messages/messages-context';
import {Alert} from '../../notification/alert';
import MFAEnrollmentEmailModal from '../../notification/mfa/mfa-enrollment-email-modal';
import MFAPushNotificationModal from '../../notification/mfa/mfa-push-notification-modal';
import {NotificationActions} from '../../notification/notification-actions';
import Order from '../../orders/order';
import Orders from '../../orders/orders';
import RouterHelpers, {CheckPartners} from '../../router/router-helpers';
import SearchPage from '../../search/search-page';
import {SessionActions} from '../../session/session-actions';
import SessionStore from '../../session/session-store';
import ShowsPage from '../../shows/shows-page';
import TwentyFifteenSlate from '../../static-pages/2015-slate';
import AdultSwimContacts from '../../static-pages/adult-swim-contacts.js';
import AnimationPackage from '../../static-pages/animation-package.js';
import AppNotes from '../../static-pages/app-notes.js';
import CartoonNetworkPressContacts from '../../static-pages/cartoon-network-press-contacts.js';
import CartoonitoPressContacts from '../../static-pages/cartoonito-press-contacts.js';
import ClassicMovies from '../../static-pages/classic-movies.js';
import ContactUsDomestic from '../../static-pages/contact-us-domestic.js';
import ContactUs from '../../static-pages/contact-us.js';
import CrimePackage from '../../static-pages/crime-package.js';
import DigitalSeries from '../../static-pages/digital-series.js';
import FamilyHoliday from '../../static-pages/family-holiday.js';
import FormatsCatalog from '../../static-pages/formats-catalog.js';
import FormatsPortalContacts from '../../static-pages/formats-portal-contacts.js';
import FormatsShowcase from '../../static-pages/formats-showcase.js';
import FullEpisodes from '../../static-pages/full-episodes.js';
import HarryPotterCoreElements from '../../static-pages/harry-potter/core-elements.js';
import HarryPotterEditorialPackages from '../../static-pages/harry-potter/editorial-packages.js';
import HarryPotterIndex from '../../static-pages/harry-potter/index.js';
import HarryPotterOnAirNavigation from '../../static-pages/harry-potter/on-air-navigation.js';
import HarryPotterPromoPackages from '../../static-pages/harry-potter/promo-packages.js';
import HBOMaxContacts from '../../static-pages/hbo-max-contacts.js';
import HDTitles from '../../static-pages/hd-titles.js';
import HorrorPackage from '../../static-pages/horror-package.js';
import Network45 from '../../static-pages/network-45.js';
import PitchBlue from '../../static-pages/pitch-blue.js';
import PressSiteContacts from '../../static-pages/press-site-contacts.js';
import Products from '../../static-pages/products.js';
import RomancePackage from '../../static-pages/romance-package.js';
import SummerOfAnimation from '../../static-pages/summer-of-animation.js';
import TVEvent from '../../static-pages/tv-event.js';
import StationSummit from '../../station-summit/station-summit';
import Swagger from '../../swagger/swagger';
import TalentLayout from '../../talent/talent-layout';
import TitleLanguageAvailability from '../../title-language-availability/title-language-availability';
import TitleLayout from '../../titles/title-layout';
import WorkOrder from '../../work-orders/work-order';
import Footer from '../footer';
import Header from '../header';
import SearchBar from '../searchbar';
import './page-layout.less';

// Important!
// Decorated components are created here because if this is done inside
// the render method it will return a new class/object on each cycle. Therefore,
// React would unmount the old component and mount the new one resulting
// in lots of unnecessary renders.

// Hubs.
const AnimationSearchPage = CheckPartners(
    SearchPage('animation'),
    [Config.Partners.WBTVD.id, Config.Partners.HBO.id, Config.Partners.ADULTSWIM_PRESS.id, Config.Partners.CARTOON_NETWORK_PRESS.id, Config.Partners.CARTOONITO_PRESS.id]
);
const ScriptedSearchPage = CheckPartners(
    SearchPage('scripted'),
    [Config.Partners.FORMATS_PORTAL.id]
);
const EntertainmentSearchPage = CheckPartners(
    SearchPage('entertainment'),
    [Config.Partners.FORMATS_PORTAL.id]
);
const FactualSearchPage = CheckPartners(
    SearchPage('factual'),
    [Config.Partners.FORMATS_PORTAL.id]
);
const RealitySearchPage = CheckPartners(
    SearchPage('reality'),
    [Config.Partners.FORMATS_PORTAL.id]
);
const FilmSearchPage = CheckPartners(
    SearchPage('film'),
    [Config.Partners.WBTVD.id, Config.Partners.ADULTSWIM_PRESS.id, Config.Partners.CARTOON_NETWORK_PRESS.id, Config.Partners.CARTOONITO_PRESS.id]
);
const DocFilmsSearchPage = CheckPartners(
    SearchPage('documentary'), [Config.Partners.HBO_MAX_PORTAL.id]
);
const DocumentarySearchPage = CheckPartners(
    SearchPage('documentary'), [Config.Partners.HBO.id]
);
const KidsSearchPage = CheckPartners(
    SearchPage('animation'),
    [Config.Partners.HBO_MAX_PORTAL.id]
);
const LimitedSeriesSearchPage = CheckPartners(
    SearchPage('limited-series'),
    [Config.Partners.HBO.id, Config.Partners.HBO_MAX_PORTAL.id]
);
const MoviesSearchPage = CheckPartners(
    SearchPage('movies'),
    [Config.Partners.HBO.id]
);
const TVSearchPage = CheckPartners(
    SearchPage('tv'),
    [Config.Partners.WBTVD.id,
        Config.Partners.HBO.id,
        Config.Partners.HBO_MAX_PORTAL.id,
        Config.Partners.ADULTSWIM_PRESS.id,
        Config.Partners.CARTOON_NETWORK_PRESS.id,
        Config.Partners.CARTOONITO_PRESS.id]
);

// List Documents
const Acquisitions = ListDocuments(ListDocumentsConstants.PUBLISHING_LIST.ACQUISITIONS);
const LanguageAvailability = ListDocuments(null, ListDocumentsConstants.CONTENT_TYPES.LANGUAGE_AVAILABILITY_CHART);
const USAirDates = ListDocuments(ListDocumentsConstants.PUBLISHING_LIST.US_AIR_DATES);
const ProductReports = ListDocuments(ListDocumentsConstants.PUBLISHING_LIST.PRODUCT_REPORTS);
const TheatricalGrids = ListDocuments(ListDocumentsConstants.PUBLISHING_LIST.THEATRICAL_GRIDS);
const AdultSwimPressReleases = CheckPartners(
    ListDocuments(ListDocumentsConstants.PUBLISHING_LIST.ADULTSWIM_PRESS),
    [Config.Partners.ADULTSWIM_PRESS.id]
);
const CartoonNetworkPressReleases = CheckPartners(
    ListDocuments(ListDocumentsConstants.PUBLISHING_LIST.CARTOON_NETWORK_PRESS),
    [Config.Partners.CARTOON_NETWORK_PRESS.id]
);
const CartoonitoPressReleases = CheckPartners(
    ListDocuments(ListDocumentsConstants.PUBLISHING_LIST.CARTOONITO_PRESS),
    [Config.Partners.CARTOONITO_PRESS.id]
);
const PressSiteReleases = CheckPartners(
    ListDocuments(ListDocumentsConstants.PUBLISHING_LIST.PRESS_SITE_RELEASES),
    [Config.Partners.PRESS_SITE.id]
);

// Orders
const CartDownload = Download('cart', true);
const FileDownload = Download('file', false);
const OrderDownload = Download('order', true);

// Search pages.
const GeneralSearchPage = CheckPartners(
    SearchPage('search'),
    [
        Config.Partners.WBTVD.id, Config.Partners.HBO.id, Config.Partners.ADULTSWIM_PRESS.id,
        Config.Partners.CARTOON_NETWORK_PRESS.id, Config.Partners.PRESS_SITE.id,
        Config.Partners.CARTOONITO_PRESS.id, Config.Partners.HBO_MAX_PORTAL.id,
        Config.Partners.FORMATS_PORTAL.id
    ]
);
const DocumentsSearchPage = CheckPartners(
    SearchPage('search', 'documents'),
    [
        Config.Partners.WBTVD.id, Config.Partners.HBO.id,
        Config.Partners.ADULTSWIM_PRESS.id, Config.Partners.CARTOON_NETWORK_PRESS.id,
        Config.Partners.CARTOONITO_PRESS.id, Config.Partners.FORMATS_PORTAL.id,
        Config.Partners.HBO_MAX_PORTAL.id
    ]
);
const EpisodesSearchPage = CheckPartners(
    SearchPage('search', 'episodes'),
    [
        Config.Partners.WBTVD.id, Config.Partners.HBO.id, Config.Partners.ADULTSWIM_PRESS.id,
        Config.Partners.CARTOON_NETWORK_PRESS.id, Config.Partners.CARTOONITO_PRESS.id,
        Config.Partners.HBO_MAX_PORTAL.id, Config.Partners.FORMATS_PORTAL.id
    ]
);
const MomentsSearchPage = CheckPartners(
    SearchPage('search', 'moments'),
    [Config.Partners.WBTVD.id, Config.Partners.FORMATS_PORTAL.id, Config.Partners.HBO_MAX_PORTAL.id]
);
const ImagesSearchPage = CheckPartners(
    SearchPage('search', 'images'),
    [
        Config.Partners.WBTVD.id, Config.Partners.HBO.id,
        Config.Partners.ADULTSWIM_PRESS.id, Config.Partners.CARTOON_NETWORK_PRESS.id,
        Config.Partners.PRESS_SITE.id, Config.Partners.CARTOONITO_PRESS.id,
        Config.Partners.FORMATS_PORTAL.id, Config.Partners.HBO_MAX_PORTAL.id
    ]
);
const TalentSearchPage = CheckPartners(
    SearchPage('search', 'talent'),
    [
        Config.Partners.WBTVD.id, Config.Partners.HBO.id, Config.Partners.ADULTSWIM_PRESS.id,
        Config.Partners.CARTOON_NETWORK_PRESS.id, Config.Partners.CARTOONITO_PRESS.id,
        Config.Partners.HBO_MAX_PORTAL.id, Config.Partners.FORMATS_PORTAL.id
    ]
);
const TitlesSearchPage = CheckPartners(
    SearchPage('search', 'titles'),
    [
        Config.Partners.WBTVD.id, Config.Partners.HBO.id, Config.Partners.ADULTSWIM_PRESS.id,
        Config.Partners.CARTOON_NETWORK_PRESS.id, Config.Partners.PRESS_SITE.id,
        Config.Partners.CARTOONITO_PRESS.id, Config.Partners.HBO_MAX_PORTAL.id,
        Config.Partners.FORMATS_PORTAL.id
    ]
);
const VideosSearchPage = CheckPartners(
    SearchPage('search', 'videos'),
    [
        Config.Partners.WBTVD.id, Config.Partners.HBO.id, Config.Partners.ADULTSWIM_PRESS.id,
        Config.Partners.CARTOON_NETWORK_PRESS.id, Config.Partners.PRESS_SITE.id,
        Config.Partners.CARTOONITO_PRESS.id, Config.Partners.HBO_MAX_PORTAL.id,
        Config.Partners.FORMATS_PORTAL.id
    ]
);

// Static pages
const AdultSwimContactsWrapper = CheckPartners(AdultSwimContacts, [Config.Partners.ADULTSWIM_PRESS.id]);
const CartoonitoPressContactsWrapper = CheckPartners(CartoonitoPressContacts, [Config.Partners.CARTOONITO_PRESS.id]);
const CartoonNetworkPressContactsWrapper = CheckPartners(CartoonNetworkPressContacts, [Config.Partners.CARTOON_NETWORK_PRESS.id]);
const HBOMaxContactsWrapper = CheckPartners(HBOMaxContacts, [Config.Partners.HBO_MAX_PORTAL.id]);
const PressSiteContactsWrapper = CheckPartners(PressSiteContacts, [Config.Partners.PRESS_SITE.id]);
const HarryPotterIndexWrapper = CheckPartners(HarryPotterIndex, [Config.Partners.WBTVD.id]);
const HarryPotterCoreElementsWrapper = CheckPartners(HarryPotterCoreElements, [Config.Partners.WBTVD.id]);
const HarryPotterEditorialPackagesWrapper = CheckPartners(HarryPotterEditorialPackages, [Config.Partners.WBTVD.id]);
const HarryPotterOnAirNavigationWrapper = CheckPartners(HarryPotterOnAirNavigation, [Config.Partners.WBTVD.id]);
const HarryPotterPromoPackagesWrapper = CheckPartners(HarryPotterPromoPackages, [Config.Partners.WBTVD.id]);
const FormatsPortalContactsWrapper = CheckPartners(FormatsPortalContacts, [Config.Partners.FORMATS_PORTAL.id]);

const WorkOrderDownload = Download('package', true);

class Layout extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
            onClose: PropTypes.func.isRequired
        };
    }

    static getStores() {
        return [PageLayoutStore, SessionStore];
    }

    static calculateState() {
        return {
            homepage: SessionStore.getState().get('homepage'),
            partner: SessionStore.getState().get('partner'),
            searchBar: PageLayoutStore.getState().get('searchBar'),
            searchIcon: PageLayoutStore.getState().get('searchIcon'),
            timeout: SessionStore.getState().get('timeout')
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.onScroll = this.onScroll.bind(this);

        return;
    }

    componentDidMount() {
        NotificationActions.clearQueue();
        document.addEventListener('scroll', this.onScroll, false);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll, false);
    }

    static contextType = MessagesContext;

    onScroll() {
        let offset = document.getElementById('layout-header').offsetHeight;

        /**
         * The user has reached the top
         * then unfix the searchBar
         */
        if (window.scrollY <= (offset / 2)) {
            PageLayoutActions.hideSearchBar();
        }
        /**
         * When the user scroll under the offset limit
         * then the .searchIcon appears
         */
        if (window.scrollY >= offset) {
            if (this.state.searchIcon.get('hidden')) {
                PageLayoutActions.showSearchIcon();
            }
        } else if (!this.state.searchIcon.get('hidden')) {
            PageLayoutActions.hideSearchIcon();
        }
    }

    render() {
        let footer;
        let isPressPartner = false;
        let partnerId = this.state.partner.get('id');
        let searchBar;

        switch (partnerId) {
        case Config.Partners.PRESS_SITE.id:
            isPressPartner = true;
            break;
        }

        if (!SessionStore.canUser(SessionStore.PERMISSIONS.RESTRICTED.HOMEPAGEONLY)) {
            footer = <Footer/>;
            if (!isPressPartner && !this.state.homepage.isEmpty() && !SessionStore.isContentNotAvailable()) {
                searchBar = <SearchBar fixed={this.state.searchBar.get('fixed')} location={this.props.location}/>;
            }
        }

        let animationFilmAndTV;
        if (!SessionStore.isMultipleTitles() && !SessionStore.isSingleTitle()) {
            animationFilmAndTV = [
                <Route component={AnimationSearchPage} exact path={`${this.props.match.path}animation`} key={`${this.props.match.path}animation`}/>,
                <Route component={FilmSearchPage} exact path={`${this.props.match.path}film`} key={`${this.props.match.path}film`}/>,
                <Route component={TVSearchPage} exact path={`${this.props.match.path}tv`} key={`${this.props.match.path}tv`}/>,
                <Route component={ScriptedSearchPage} exact path={`${this.props.match.path}scripted`} key={`${this.props.match.path}scripted`}/>,
                <Route component={EntertainmentSearchPage} exact path={`${this.props.match.path}entertainment`} key={`${this.props.match.path}entertainment`}/>,
                <Route component={FactualSearchPage} exact path={`${this.props.match.path}factual`} key={`${this.props.match.path}factual`}/>,
                <Route component={RealitySearchPage} exact path={`${this.props.match.path}reality`} key={`${this.props.match.path}reality`}/>,
            ];
        }

        return (
            <div className="main-style">
                <Alert fixed className="col-xs-11 col-sm-4" transitionName="fadeDown" />
                <MFAEnrollmentEmailModal
                    email={SessionStore.getState().getIn(['authUser', 'email'])}
                />
                <MFAPushNotificationModal/>
                <Modal show={this.state.timeout} onHide={this.props.onClose}>
                    <Modal.Header className="bg-gray">
                        <Modal.Title className="modal-title text-center">{this.context.intl.messages['session.timeoutWarning.title']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {this.context.intl.messages['session.timeoutWarning.message-pre']}
                            <CountdownTimer initialTimeRemaining={SessionStore.getTimeToLogout()} completeCallback={this.onComplete} /> .
                            {this.context.intl.messages['session.timeoutWarning.message-post']}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className=" padding-all-20">
                        <Button bsStyle="primary" className="pull-right" onClick={SessionActions.keepAlive}>{this.context.intl.messages['session.timeoutWarning.keep-alive-button']}</Button>
                    </Modal.Footer>
                </Modal>
                <Header
                    location={this.props.location}
                    partnerId={partnerId}
                    searchIconHidden={this.state.searchIcon.get('hidden')}
                />
                {searchBar}
                <main className={ClassNames('page-content', {'press-site': isPressPartner})}>
                    <Suspense fallback={<div></div>}>
                        <Switch>
                            <Route component={Home} exact path={this.props.match.path}/>

                            <Route component={AccessDenied} exact path={`${this.props.match.path}access-denied`}/>
                            <Route component={ComingSoon} exact path={`${this.props.match.path}coming-soon`}/>

                            <Route component={AccountSection} path={`${this.props.match.path}account`}/>

                            <Route component={AssetNotFound} path={`${this.props.match.path}asset-not-found`}/>

                            <Route component={DocFilmsSearchPage} exact path={`${this.props.match.path}doc-films`}/>
                            <Route component={DocumentarySearchPage} exact path={`${this.props.match.path}documentary`}/>

                            <Route component={KidsSearchPage} exact path={`${this.props.match.path}kids`}/>

                            <Route component={LimitedSeriesSearchPage} exact path={`${this.props.match.path}limited-series`}/>

                            <Route component={MoviesSearchPage} exact path={`${this.props.match.path}movies`}/>

                            <Route component={FileDownload} exact path={`${this.props.match.path}download`}/>
                            <Route component={Order} exact path={`${this.props.match.path}cart`}/>
                            <Route component={CartDownload} exact path={`${this.props.match.path}cart/download`}/>

                            <Route component={Help} exact path={`${this.props.match.path}help`}/>

                            <Route component={TitleLanguageAvailability} exact path={`${this.props.match.path}alternative-content-sales-availability`}/>

                            {
                                // The order of the routes is very important here.
                            }
                            <Route component={OrderDownload} exact path={`${this.props.match.path}orders/:id/download`}/>
                            <Route component={Order} exact path={`${this.props.match.path}orders/:id(\\d+)`}/>
                            <Route component={Orders} path={`${this.props.match.path}orders`}/>

                            <Route component={WorkOrder} exact path={`${this.props.match.path}packages`}/>
                            <Route component={WorkOrder} exact path={`${this.props.match.path}packages/:id`}/>
                            <Route component={WorkOrderDownload} exact path={`${this.props.match.path}packages/:id/download`}/>

                            <Route component={GeneralSearchPage} exact path={`${this.props.match.path}search`}/>
                            <Route component={MomentsSearchPage} exact path={`${this.props.match.path}search/moments`}/>
                            <Route component={DocumentsSearchPage} exact path={`${this.props.match.path}search/documents`}/>
                            <Route component={EpisodesSearchPage} exact path={`${this.props.match.path}search/episodes`}/>
                            <Route component={ImagesSearchPage} exact path={`${this.props.match.path}search/images`}/>
                            <Route component={TalentSearchPage} exact path={`${this.props.match.path}search/talent`}/>
                            <Route component={TitlesSearchPage} exact path={`${this.props.match.path}search/titles`}/>
                            <Route component={VideosSearchPage} exact path={`${this.props.match.path}search/videos`}/>

                            <Route component={ShowsPage} exact path={`${this.props.match.path}shows`}/>

                            <Route component={StationSummit} exact path={`${this.props.match.path}station-summit`}/>

                            <Route component={Swagger} exact path={`${this.props.match.path}swagger`}/>

                            <Route component={TalentLayout} path={`${this.props.match.path}talent/:id`}/>

                            <Route component={TitleLayout} path={`${this.props.match.path}titles/:id`}/>

                            {animationFilmAndTV}

                            {
                                // List Documents section. These are all pages which display the
                                // documents included in a publishing list.
                            }
                            <Route component={Acquisitions} path={`${this.props.match.path}local-acquisitions`}/>
                            <Route component={CountryOverviews} path={`${this.props.match.path}country-overviews`}/>
                            <Route component={LanguageAvailability} path={`${this.props.match.path}alternative-content-sales-updates`}/>
                            <Route component={USAirDates} path={`${this.props.match.path}us-air-dates`}/>
                            <Route component={ProductReports} path={`${this.props.match.path}product-reports`}/>
                            <Route component={TheatricalGrids} path={`${this.props.match.path}theatrical-grids`}/>
                            <Route component={AdultSwimPressReleases} path={`${this.props.match.path}adultswim-press-releases`}/>
                            <Route component={CartoonNetworkPressReleases} path={`${this.props.match.path}cartoon-network-press-releases`}/>
                            <Route component={CartoonitoPressReleases} path={`${this.props.match.path}cartoonito-press-releases`}/>
                            <Route component={PressSiteReleases} path={`${this.props.match.path}press-site-releases`}/>
                            <Route component={Vam} path={`${this.props.match.path}vam/:id`}/>

                            {
                                // Static Pages
                            }
                            <Route component={AppNotes} path={`${this.props.match.path}app-notes`}/>
                            <Route component={AnimationPackage} path={`${this.props.match.path}animation-package`}/>
                            <Route component={ClassicMovies} path={`${this.props.match.path}classic-movies`}/>
                            <Route component={ContactUsDomestic} path={`${this.props.match.path}contact-us-domestic`}/>
                            <Route component={ContactUs} path={`${this.props.match.path}contact-us`}/>
                            <Route component={AdultSwimContactsWrapper} path={`${this.props.match.path}adultswim-contacts`}/>
                            <Route component={CartoonitoPressContactsWrapper} path={`${this.props.match.path}cartoonito-press-contacts`}/>
                            <Route component={CartoonNetworkPressContactsWrapper} path={`${this.props.match.path}cartoon-network-press-contacts`}/>
                            <Route component={HBOMaxContactsWrapper} path={`${this.props.match.path}hbo-max-contacts`}/>
                            <Route component={PressSiteContactsWrapper} path={`${this.props.match.path}press-site-contacts`}/>
                            <Route component={FormatsPortalContactsWrapper} path={`${this.props.match.path}formats-portal-contacts`}/>
                            <Route component={DigitalSeries} path={`${this.props.match.path}digital-series`}/>
                            <Route component={FamilyHoliday} path={`${this.props.match.path}family-holiday`}/>
                            <Route component={FormatsCatalog} path={`${this.props.match.path}formats-catalog`}/>
                            <Route component={FormatsShowcase} path={`${this.props.match.path}formats-showcase`}/>
                            <Route component={FullEpisodes} path={`${this.props.match.path}full-episodes`}/>
                            <Route component={HDTitles} path={`${this.props.match.path}hd-titles`}/>
                            <Route component={Network45} path={`${this.props.match.path}network45`}/>
                            <Route component={Products} path={`${this.props.match.path}products`}/>
                            <Route component={TwentyFifteenSlate} path={`${this.props.match.path}slate`}/>
                            <Route component={SummerOfAnimation} path={`${this.props.match.path}summer-of-animation`}/>
                            <Route component={TVEvent} path={`${this.props.match.path}tv-event`}/>
                            <Route component={PitchBlue} path={`${this.props.match.path}pitch-blue`}/>
                            <Route component={CrimePackage} path={`${this.props.match.path}crime-package`}/>
                            <Route component={HorrorPackage} path={`${this.props.match.path}horror-package`}/>
                            <Route component={RomancePackage} path={`${this.props.match.path}romance-package`}/>
                            <Route component={HarryPotterIndexWrapper} exact path={`${this.props.match.path}harry-potter/`}/>
                            <Route component={HarryPotterCoreElementsWrapper} exact path={`${this.props.match.path}harry-potter/core-elements`}/>
                            <Route component={HarryPotterEditorialPackagesWrapper} exact path={`${this.props.match.path}harry-potter/editorial-packages`}/>
                            <Route component={HarryPotterOnAirNavigationWrapper} exact path={`${this.props.match.path}harry-potter/on-air-navigation`}/>
                            <Route component={HarryPotterPromoPackagesWrapper} exact path={`${this.props.match.path}harry-potter/promo-packages`}/>

                            <Route component={NotFound}/>
                        </Switch>
                    </Suspense>
                </main>
                {footer}
            </div>
        );
    }
}

export default RouterHelpers(Container.create(Layout), true);
export {Layout};
