/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {memo, useMemo, useState} from 'react';
import {useLocation} from 'react-router';

import DropdownOption from './dropdown-option';
import SelectOption from './select-option';
import {Debounce} from '../../../utils/utils';
import omit from '../../utils/omit';
import FacetSelect from '../facet-select';

const DialogueFacetSelect = (props) => {
    const {onInputChange, styles, ...restProps} = props;
    const selectComponents = useMemo(() => ({
        Option: DropdownOption,
        MultiValueLabel: SelectOption,
    }), []);

    const location = useLocation();
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = Debounce((value) => {
        setInputValue(value);
        /* istanbul ignore else */
        if (value.length > 1) {
            const query = QueryString.parse(location.search);
            onInputChange(query?.q ?? '', value);
        }
    }, 400);

    const selectStyles = useMemo(() => ({
        ...(styles || {}),
        indicatorsContainer: (provided) => ({
            ...provided,
            display: 'none',
        }),
        menu: (provided)/* istanbul ignore next */ => {
            /* istanbul ignore next */
            if (inputValue.length > 1) {
                return provided;
            }
            return {...provided, display: 'none'};
        },
    }), [inputValue, styles]);

    return <FacetSelect
        components={selectComponents}
        name="dialogue"
        onInputChange={handleInputChange}
        styles={selectStyles}
        filterOption={/* istanbul ignore next */(option) => /* istanbul ignore next */option}
        {...restProps}
    />;
};

DialogueFacetSelect.propTypes = {
    ...FacetSelect.propTypes,
    onInputChange: PropTypes.func.isRequired,
    options: PropTypes.instanceOf(Immutable.List).isRequired,
};

DialogueFacetSelect.defaultProps = omit(FacetSelect.defaultProps, ['onInputChange', 'options']);

export default memo(DialogueFacetSelect);
