/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';

import {Dispatcher} from '../../flux-helpers';
import Request from '../../request';

const CONSTANTS = {
    CLEAR: 'modal-share-actions.clear',
    USERS_LIST: {
        GET: {
            SUCCESS: 'modal-share-actions.users-list.get.success'
        }
    },
};

class ModalShareActions {

    clear() {
        Dispatcher.dispatch({
            actionType: CONSTANTS.CLEAR
        });
    }

    getUsers(user) {
        let params = {
            email: user,
            name: user,
            operator: 'OR',
            offset: 0,
            size: 10
        };

        return Request.get('user').query(params).then(usersList => {
            Dispatcher.dispatch({
                actionType: CONSTANTS.USERS_LIST.GET.SUCCESS,
                usersList: Immutable.fromJS(usersList.body.results)
            });
        }).catch(/* istanbul ignore next */err => {
            throw err;
        });
    }
}

const actions = new ModalShareActions();

export {
    actions as ModalShareActions,
    CONSTANTS as ModalShareConstants,
};
