/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {ModalShareConstants} from './modal-share-actions';
import {Dispatcher} from '../../flux-helpers';


class ModalShareStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            usersList: Immutable.List(),
        });
    }

    reduce(state, action) {
        switch (action.actionType) {

        case ModalShareConstants.CLEAR:
            state = this.getInitialState();
            break;

        case ModalShareConstants.USERS_LIST.GET.SUCCESS:
            state = state.set('usersList', action.usersList);
            break;
        }
        return state;
    }
}

export default new ModalShareStore(Dispatcher);
