/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col, Grid, ResponsiveEmbed, Row} from 'react-bootstrap';

import {MessagesContext} from '../../../messages/messages-context';
import Player from '../../../player';
import {PlayerActions} from '../../../player/player-actions';
import PlayerStore from '../../../player/player-store';
import ImageLoader, {ImageLoaderPlaceholders} from '../../../titles/asset-title/images/image-loader';
import {BreakIntoGroups, GetThumbnail, HandleWithTriggerKeys} from '../../../utils/utils';
import {HomeActions} from '../../home-actions';
import HomeStore from '../../home-store';
import {PageTypes} from '../../page-types';
import './video-list.less';

class PlayerWrapper extends Component {
    static get propTypes() {
        return {
            item: PropTypes.instanceOf(Immutable.Map).isRequired,
            lastIndex: PropTypes.number.isRequired,
            prevNext: PropTypes.func.isRequired,
            video: PropTypes.instanceOf(Immutable.Map),
            videoIndex: PropTypes.number.isRequired
        };
    }

    static get defaultProps() {
        return {
            video: undefined
        };
    }

    static calculateState() {
        return {
            watchlist: PlayerStore.getState().get('watchlist')
        };
    }

    static getStores() {
        return [PlayerStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.prevNext = this.prevNext.bind(this);

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.item !== nextProps.item ||
            this.props.video !== nextProps.video
        ) {
            return true;
        }

        if (this.state.watchlist !== nextState.watchlist) {
            return true;
        }

        return false;
    }

    prevNext(index) {
        this.props.prevNext(index);
    }

    render() {
        let prevIndex;
        let nextIndex;
        let playerOptions = {
            autostart: false
        };

        if (this.props.videoIndex === this.props.lastIndex) {
            nextIndex = 0;
        } else {
            nextIndex = this.props.videoIndex + 1;
        }

        if (this.props.videoIndex === 0) {
            prevIndex = this.props.lastIndex;
        } else {
            prevIndex = this.props.videoIndex - 1;
        }

        let player;

        if (this.props.video) {
            player = (
                <Player
                    playerOptions={playerOptions}
                    showPoster={!this.props.video.get('streams')}
                    video={this.props.video}
                    watchlist={this.state.watchlist}
                />
            );
        }

        let arrows = (
            <div>
                <span id="prev-playlist" onClick={this.prevNext.bind(this, prevIndex)}
                    className="playlist-control glyphicon glyphicon-chevron-left"
                    aria-hidden="true"/>
                <span id="next-playlist" onClick={this.prevNext.bind(this, nextIndex)}
                    className="playlist-control glyphicon glyphicon-chevron-right"
                    aria-hidden="true"/>
            </div>
        );

        let displayTitle = this.props.item.get('displayName') || this.props.item.get('description');

        return (
            <div className="homepage-video-wrapper">
                <div className="container homepage-video-container">
                    {arrows}
                    {player}
                    <div className="homepage-video-description">
                        <h1>
                            <strong id="homepage-video-title" dangerouslySetInnerHTML={{__html: displayTitle}} />&nbsp;
                            <small dangerouslySetInnerHTML={{__html: this.props.item.getIn(['video', 'runtime'])}} />
                        </h1>
                    </div>
                </div>
            </div>
        );
    }
}
let PlayerWrapperContainer = Container.create(PlayerWrapper);

class Thumbnail extends Component {

    static get propTypes() {
        return {
            currentVideoIndex: PropTypes.number.isRequired,
            onPlay: PropTypes.func.isRequired,
            video: PropTypes.instanceOf(Immutable.Map),
            videoIndex: PropTypes.number.isRequired
        };
    }

    static get defaultProps() {
        return {
            onPlay: undefined,
            video: Immutable.Map()
        };
    }


    constructor(props) {
        super(props);

        this.playVideo = this.playVideo.bind(this);

        return;
    }

    playVideo() {
        // Do nothing because this is the current video.
        if (this.props.videoIndex === this.props.currentVideoIndex) {
            return;
        }

        this.props.onPlay(this.props.videoIndex);
        return;
    }

    render() {
        const {
            assetComment: assetDisplayName = '',
            runtime,
            hasDub,
            hasSub,
            texted,
            textless,
            language,
        } = this.props.video.toJS();

        let thumbnail = GetThumbnail(this.props.video.get('thumbnails'), 600);
        let src;
        if (thumbnail) {
            src = thumbnail.get('thumbnailUrl');
        }

        const parentTitleDisplayName = this.props.video.getIn(['titles', 0, 'parentTitleDisplayName']);

        let languageName = '';
        if (language) {
            languageName = LanguageCodeType.LANGUAGES[language];
        }

        let dubSub = '';
        if (hasSub && hasDub) {
            dubSub = ' Subtitled/Dubbed';
        } else if (hasSub) {
            dubSub = ' Subtitled';
        } else if (hasDub) {
            dubSub = ' Dubbed';
        }

        let textlessTextedText = '';
        if (textless) {
            textlessTextedText = ' Textless';
        }
        if (texted) {
            textlessTextedText = ' Texted';
        }

        let serviceableProperties = `${languageName}${dubSub}${textlessTextedText}`.trim();
        if (serviceableProperties.length !== 0) {
            serviceableProperties = <span>{`(${serviceableProperties})`}&nbsp;</span>;
        }

        return (
            <Col xs={3} className="thumbnail-container">
                <span
                    className={ClassNames(
                        'video-thumbnail',
                        {'active': this.props.videoIndex === this.props.currentVideoIndex}
                    )}
                    role="presentation"
                    onClick={this.playVideo}
                    onKeyUp={HandleWithTriggerKeys(this.playVideo)}
                >
                    <ResponsiveEmbed a16by9>
                        <div>
                            <ImageLoader
                                alt={assetDisplayName}
                                className="img-responsive"
                                placeholder={ImageLoaderPlaceholders.HORIZONTAL}
                                src={src}
                            />
                            <span className="glyphicon glyphicon-play-circle thumbnail-play-icon" />
                        </div>
                    </ResponsiveEmbed>
                </span>
                <p>
                    <strong dangerouslySetInnerHTML={{__html: parentTitleDisplayName}} />&nbsp;
                    {assetDisplayName}&nbsp;
                    {serviceableProperties}
                    <small>{runtime}</small>
                </p>
            </Col>
        );
    }
}

class VideoList extends Component {

    static get propTypes() {
        return {
            publishingList: PropTypes.object.isRequired,
            videos: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static calculateState() {
        return {
            homepage: HomeStore.getState().get('homepage'),
            playlistVideoIndex: HomeStore.getState().get('playlistVideoIndex')
        };
    }

    static getStores() {
        return [HomeStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.setVideo = this.setVideo.bind(this);

        return;
    }

    componentDidMount() {
        if (this.state.homepage.get('pageType') !== PageTypes.PLAYLIST.ID) {
            return;
        }

        const item = this.props.publishingList.getIn(['items', 0]);
        const video = this.props.videos.get(item.get('assetId'));
        if (video && !video.get('streams')) {
            HomeActions.prepareVideo(video, 0);
        }

        return;
    }

    componentDidUpdate(oldProps, oldState) {
        if (this.state.homepage.get('pageType') !== PageTypes.PLAYLIST.ID) {
            return;
        }

        const item = this.props.publishingList.getIn(['items', this.state.playlistVideoIndex]);
        const video = this.props.videos.get(item.get('assetId'));

        const oldItem = oldProps.publishingList.getIn(['items', oldState.playlistVideoIndex]);
        const oldVideo = oldProps.videos.get(oldItem.get('assetId'));
        if (video && !video.equals(oldVideo) && !video.get('streams')) {
            HomeActions.prepareVideo(video, this.state.playlistVideoIndex);
        }

        return;
    }

    static contextType = MessagesContext;

    setVideo(videoIndex) {
        const item = this.props.publishingList.getIn(['items', videoIndex]);
        const video = this.props.videos.get(item.get('assetId'));

        switch (this.state.homepage.get('pageType')) {
        case PageTypes.PLAYLIST.ID:
            HomeActions.prepareVideo(video, videoIndex);
            break;
        case PageTypes.PRESS_RELEASE.ID:
            PlayerActions.showPlayer(this.props.publishingList.getIn(['items', videoIndex]));
            break;
        }

        return;
    }

    render() {
        let items = this.props.publishingList.get('items');
        let itemList =[];
        let player;

        itemList = BreakIntoGroups(items, 4).map((itemRow, i) => {
            return (
                <Row key={i}>
                    {itemRow.map(item => {
                        const originalItemIndex = items.findIndex(ii => ii === item);

                        if (originalItemIndex === this.state.playlistVideoIndex) {
                            player = (
                                <PlayerWrapperContainer
                                    item={item}
                                    prevNext={this.setVideo}
                                    lastIndex={items.size - 1}
                                    video={this.props.videos.get(item.get('assetId'))}
                                    videoIndex={originalItemIndex}
                                />
                            );
                        }

                        let currentVideoIndex = this.state.playlistVideoIndex;
                        /* istanbul ignore next */
                        if (this.state.homepage.get('pageType') === PageTypes.PRESS_RELEASE.ID) {
                            currentVideoIndex = -1;
                            player = undefined;
                        }

                        return (
                            <Thumbnail
                                currentVideoIndex={currentVideoIndex}
                                key={originalItemIndex}
                                onPlay={this.setVideo}
                                video={this.props.videos.get(item.get('assetId'))}
                                videoIndex={originalItemIndex}
                            />
                        );
                    })}
                </Row>
            );
        });

        return (
            <div>
                {player}
                <Grid className="list">
                    <div className="padding-x-15">
                        {itemList}
                    </div>
                </Grid>
            </div>
        );
    }
}

export default Container.create(VideoList);
export {
    PlayerWrapperContainer as PlayerWrapper,
    PlayerWrapper as PlayerWrapperBase,
    Thumbnail,
    VideoList
};
