/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {HeaderConstants} from './header-actions';

import {Dispatcher} from '~/src/flux-helpers';

class HeaderStore extends ReduceStore {
    getInitialState() {
        return Immutable.fromJS({
            navigationItems: []
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case HeaderConstants.HEADER.GET_HOMEPAGE_NAVIGATION_MENU_ITEMS.SUCCESS:
            state = state.set('navigationItems', action.items);
            break;
        }
        return state;
    }
}

const store = new HeaderStore(Dispatcher);
export default store;
