/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import SeasonSummaryItem from './season-summary-item';
import {MessagesContext} from '../../messages/messages-context';

const DEFAULT_PARAMS = {};

class SeasonSummary extends React.PureComponent {
    static get propTypes() {
        return {
            assetSeasonCounts: PropTypes.instanceOf(Immutable.List).isRequired,
            assetTypePath: PropTypes.string.isRequired,
            redirectParams: PropTypes.object,
            seasonsMap: PropTypes.instanceOf(Immutable.Map).isRequired,
            title: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            redirectParams: DEFAULT_PARAMS,
        };
    }

    static contextType = MessagesContext;

    render() {
        const {assetSeasonCounts, assetTypePath, redirectParams, seasonsMap, title} = this.props;
        // do not render until seasons are available
        if (seasonsMap.size === 0) {
            return null;
        }

        let seasons = Immutable.List();
        assetSeasonCounts.forEach(assetCount => {
            let seasonTitleId = assetCount.get('titleId');
            seasons = seasons.push(Immutable.Map({
                seasonTitleId,
                assets: assetCount.get('count'),
                order: seasonsMap.getIn([seasonTitleId.toString(), 'season'], 'all')
            }));
        });

        return (
            <div key="seasons-section">
                <div className="container">
                    <div className="display-results display-results-grid">
                        <div className="row">
                            {seasons.filter(s => s.get('assets') > 0).sortBy(s => s.get('order')).map((item, i) => {
                                return (<SeasonSummaryItem assetTypePath={assetTypePath} asset={item} key={i} redirectParams={redirectParams} title={title} />);
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SeasonSummary;
