/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

export default class FormatsPortalContacts extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.formats-portal-contacts">
                <div className="container padding-y-10 padding-x-20">
                    <div className="row">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="row">
                        <table className="table cart-parent-table">
                            <thead>
                                <tr>
                                    <th scope="col" className="no-sort">NAME</th>
                                    <th scope="col" className="no-sort">TITLE</th>
                                    <th scope="col" className="no-sort">EMAIL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="3"><strong>Format Sales</strong></td>
                                </tr>
                                <tr>
                                    <td>André Renaud</td>
                                    <td>Group Vice President, Format & Finished Sales</td>
                                    <td><a href="mailto:andre.renaud@wbd.com">andre.renaud@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Harry Gamsu</td>
                                    <td>Vice President, Creative Development & Format Sales, English & French speaking Canada</td>
                                    <td><a href="mailto:harry.gamsu@wbd.com">harry.gamsu@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Ilan Astrug</td>
                                    <td>Vice President, Format Acquisition & Format Sales Executive, Italy</td>
                                    <td><a href="mailto:ilan.astrug@wbd.com">ilan.astrug@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Leonora Teale</td>
                                    <td>Executive Director, Sales (Asia, Latin America & Israel)</td>
                                    <td><a href="mailto:leonora.teale@wbd.com">leonora.teale@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Reema Patel</td>
                                    <td>Director, Formats (Eastern Europe & Africa)</td>
                                    <td><a href="mailto:reema.patel@wbd.com">reema.patel@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Liv Janicek</td>
                                    <td>Executive Assistant, Development to Harry Gamsu</td>
                                    <td><a href="mailto:liv.janicek@wbd.com">liv.janicek@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Cheryl Ayton</td>
                                    <td>Personal Assistant to Andre Renaud and Ilan Astrug</td>
                                    <td><a href="mailto:cheryl.ayton@wbd.com">cheryl.ayton@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Finished Sales</strong></td>
                                </tr>
                                <tr>
                                    <td>Andrea Zarzecki</td>
                                    <td>Director, Non-Scripted Tape Sales, UK, Ireland, Nordics, Germany & Benelux</td>
                                    <td><a href="mailto:andrea.zarzecki@wbd.com">andrea.zarzecki@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Chantal Faudone</td>
                                    <td>Director, Non-Scripted Tape Sales - France, Iberia, Italy, MENA, Africa, Latin America & French speaking Europe</td>
                                    <td><a href="mailto:chantal.faudone@wbd.com">chantal.faudone@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Jack Davies</td>
                                    <td>Sales Manager, Non-Scripted Tape Sales – Central & Eastern Europe, CIS, Baltics, Balkans, Middle East & Israel</td>
                                    <td><a href="mailto:jack.davies@wbd.com">jack.davies@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Sales Administrators</strong></td>
                                </tr>
                                <tr>
                                    <td>Levi Malcolm</td>
                                    <td>Sales Administrator Manager</td>
                                    <td><a href="mailto:levi.malcolm@wbd.com">levi.malcolm@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Camilla Markvardsen</td>
                                    <td>Senior Sales Administrator</td>
                                    <td><a href="mailto:camilla.markvardsen@wbd.com">camilla.markvardsen@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Caira Booth-Murray</td>
                                    <td>Senior Sales Administrator</td>
                                    <td><a href="mailto:caira.booth-murray@wbd.com">caira.booth-murray@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Creative</strong></td>
                                </tr>
                                <tr>
                                    <td>Andrew Zein</td>
                                    <td>Executive Vice President, Creative, Format Development & Sales</td>
                                    <td><a href="mailto:andrew.zein@wbd.com">andrew.zein@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Ed Levan</td>
                                    <td>Group Vice President, Creative & Format Development</td>
                                    <td><a href="mailto:ed.levan@wbd.com">ed.levan@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>David Belshaw</td>
                                    <td>Director, International Scripted Production</td>
                                    <td><a href="mailto:david.belshaw@wbd.com">david.belshaw@wbd.com</a></td>
                                </tr>
                                <tr>
                                    <td>Rose Kelly</td>
                                    <td>Director, Marketing & Creative Materials</td>
                                    <td><a href="mailto:rose.kelly@wbd.com">rose.kelly@wbd.com</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}
