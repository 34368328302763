/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

export default class CartoonitoPressContacts extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.cartoonito-press-contacts">
                <div className="container padding-y-10 padding-x-20">
                    <div className="row">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="row">
                        <table className="table cart-parent-table">
                            <thead>
                                <tr>
                                    <th scope="col" className="no-sort">NAME</th>
                                    <th scope="col" className="no-sort">EMAIL</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Samantha Pio</td>
                                    <td><a href="mailto:samantha.pio@warnermedia.com">samantha.pio@warnermedia.com</a></td>
                                </tr>
                                <tr>
                                    <td>Sara Hannah</td>
                                    <td><a href="mailto:Sara.hannah@warnermedia.com">sara.hannah@warnermedia.com</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}
