/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './clip-trt.module.less';
import {secondsToTimeData} from '../timecode/time-code';

export default function ClipTRT({clip}) {
    const clipDurationInSec = clip.get('clipDurationInSec');
    if (!clipDurationInSec) {
        return null;
    }
    const data = secondsToTimeData(clipDurationInSec);
    let key = 'titles.moments.trt.sec';
    if (data.minutes) {
        key = 'titles.moments.trt.min';
    }
    return (
        <span className={styles.trt}>
            <FormattedMessage id={key} values={data} />
        </span>
    );
}

ClipTRT.propTypes = {
    clip: PropTypes.instanceOf(Immutable.Map).isRequired,
};
