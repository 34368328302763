/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container as FluxContainer} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {
    Carousel, CarouselItem, CarouselIndicators, CarouselControl,
    Col, Container, Nav, NavItem, NavLink, Row, Spinner
} from 'reactstrap';

import {DigitalSalesDecksActions, DigitalSalesDecksConstants} from './dsd-actions';
import DigitalSalesDecksStore from './dsd-store';
import DSDTabs from './tabs';
import {MessagesContext} from '../messages/messages-context';
import {Alert} from '../notification/alert';
import {RouterActions} from '../router/router-actions';
import SessionStore from '../session/session-store';
import {TitleConstants} from '../titles/title-actions';
import {FormatReleaseDate} from '../utils/utils';

import './dsd.less';

class DigitalSalesDecks extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            match: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
        };
    }

    static getStores() {
        return [DigitalSalesDecksStore, SessionStore];
    }

    static calculateState() {
        return {
            loading: DigitalSalesDecksStore.getState().get('loading'),
            titleId: DigitalSalesDecksStore.getState().get('titleId'),
            titles: DigitalSalesDecksStore.getState().get('titles'),
            titleVideos: DigitalSalesDecksStore.getState().get('titleVideos')
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            isAnimating: false,
            links: Immutable.Map({
                info: '',
                talent: 'talent',
            })
        }, this.constructor.calculateState());

        this.handleNextClick = this.handleNextClick.bind(this);
        this.handlePrevClick = this.handlePrevClick.bind(this);
        this.handleSetIsAnimating = this.handleSetIsAnimating.bind(this);
        this.handleSlideChange = this.handleSlideChange.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.isActive = this.isActive.bind(this);

        return;
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            DigitalSalesDecksActions.get(this.props.match.params.id);
        }

        return;
    }

    componentDidUpdate() {
        if (!this.props.match.params.titleId && this.state.titleId !== '') {
            const url = `/dsd/${this.props.match.params.id}/${this.state.titleId}`;
            RouterActions.redirect(url);
        }
    }

    static contextType = MessagesContext;

    handleNextClick(currentIndex) {
        if (this.state.isAnimating) {return;}

        let nextIndex = currentIndex + 1;
        if (nextIndex === this.state.titles.size) {
            nextIndex = 0;
        }
        RouterActions.redirect(`/dsd/${this.props.match.params.id}/${this.state.titles.getIn([nextIndex, 'id'])}`);
    }

    handlePrevClick(currentIndex) {
        if (this.state.isAnimating) {return;}

        let prevIndex = currentIndex - 1;
        if (prevIndex === -1) {
            prevIndex = this.state.titles.size - 1;
        }
        RouterActions.redirect(`/dsd/${this.props.match.params.id}/${this.state.titles.getIn([prevIndex, 'id'])}`);
    }

    handleSetIsAnimating(isAnimating) {
        this.setState(() => ({
            isAnimating
        }));
    }

    handleSlideChange(nextIndex) {
        if (this.state.isAnimating) {return;}

        RouterActions.redirect(`/dsd/${this.props.match.params.id}/${this.state.titles.getIn([nextIndex, 'id'])}`);
    }

    handleTab(url) {
        return (event) => {
            event.preventDefault();
            RouterActions.redirect(url);
        };
    }

    isActive(linkName) {
        //if info tab
        if (linkName === 'info' && this.props.location.pathname.split('/').pop() === this.props.match.params.titleId) {
            return true;
        }

        //others tabs
        return this.props.location.pathname.split('/').pop() === this.state.links.get(linkName);
    }

    render() {
        const items = this.state.titles.map((t, index) => {
            let airlineAvailabilityDate = t.get('airlineAvailabilityDate') || this.context.intl.messages['common.not-available'];
            let appBackgroundColor = t.get('appBackgroundColor');
            let appBackgroundImageUrl = t.get('appBackgroundImageUrl');
            let appTextColor = t.get('appTextColor');
            let categoryName = t.get('categoryName');
            let defaultImageTitleTreatmentPreviewUrl = t.get('defaultImageTitleTreatmentPreviewUrl');
            let displayName = <h1><strong>{t.get('displayName')}</strong></h1>;
            let rightExpiration = t.get('rightExpiration');
            let territory = t.get('territory');
            let titleReleaseDate = 'N/A';
            let titleRuntime = t.get('runTime');

            if (t.get('titleReleaseDate')) {
                if (t.get('titleReleaseDateType') === TitleConstants.RELEASE_DATE_TYPES.DISPLAY_NAME.id) {
                    titleReleaseDate = FormatReleaseDate(t.get('titleReleaseDateDisplayName'), t.get('titleReleaseDateType'));
                } else {
                    titleReleaseDate = FormatReleaseDate(Moment(t.get('titleReleaseDate')), t.get('titleReleaseDateType'));
                }
            }

            if (t.get('defaultImageTitleTreatmentId')) {
                displayName = (
                    <img className="img-fluid" alt={t.get('displayName')} src={defaultImageTitleTreatmentPreviewUrl} />
                );
            }

            let dsdStyle = {
                backgroundColor: `#${appBackgroundColor}`,
                backgroundImage: `url(${appBackgroundImageUrl})`,
                color: `#${appTextColor}`
            };

            let rightsAndTerritory;
            if (territory && rightExpiration) {
                rightsAndTerritory = `${territory} | ${rightExpiration}`;
            } else if (territory) {
                rightsAndTerritory = territory;
            } else if (rightExpiration) {
                rightsAndTerritory = rightExpiration;
            }

            const baseRoute = `/dsd/${this.props.match.params.id}`;

            return (
                <CarouselItem
                    key={index}
                    onExiting={this.handleSetIsAnimating.bind(this, true)}
                    onExited={this.handleSetIsAnimating.bind(this, false)}
                >
                    <Container fluid={true} style={dsdStyle}>
                        <Row>
                            <Col lg={6}>
                                <div className="left-column">
                                    <div className="title-info">
                                        <div className="text-center">
                                            {displayName}
                                            <p><small>{rightsAndTerritory}</small></p>
                                        </div>
                                        <p className="text-center">
                                            <strong>Release:</strong> {categoryName}<br />
                                            <strong>Runtime:</strong> {titleRuntime}<br />
                                            <strong>Premiere:</strong> {titleReleaseDate}<br />
                                            <strong>Airline Date:</strong> {airlineAvailabilityDate}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="right-column">
                                    <Nav>
                                        <NavItem
                                            active={this.isActive('info')}
                                            eventKey={DigitalSalesDecksConstants.TAB.INFO}
                                            onClick={this.handleTab(`${baseRoute}/${t.get('id')}`)}>
                                            <NavLink>
                                                {this.context.intl.messages['digital-sales-decks.tab.info.title']}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem
                                            active={this.isActive('talent')}
                                            eventKey={DigitalSalesDecksConstants.TAB.TALENT}
                                            onClick={this.handleTab(`${baseRoute}/${t.get('id')}/${this.state.links.get('talent')}`)}>
                                            <NavLink>
                                                {this.context.intl.messages['digital-sales-decks.tab.talent.title']}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className="title-content padding-top-20">
                                        <Switch>
                                            <Route
                                                component={DSDTabs.Talent}
                                                exact
                                                path={`${this.props.match.path}/talent`}
                                            />
                                            <Route
                                                render={(props) => (<DSDTabs.Info {...props} titleId={this.state.titles.getIn([index, 'id'])} />)}
                                            />
                                        </Switch>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </CarouselItem>
            );
        }).toJS();

        let carousel = <Spinner animation="border" color="light" style={{marginTop: '20%', marginLeft: '50%', width: '10rem', height: '10rem'}} />;
        if (!this.state.loading) {
            const currentTitleId = parseInt(this.props.match.params.titleId, 10) || this.state.titles.getIn([0, 'id']);
            const currentIndex = this.state.titles.findIndex(title => title.get('id') === currentTitleId);
            carousel = <Carousel
                activeIndex={currentIndex}
                interval={false}
                next={this.handleNextClick.bind(this, currentIndex)}
                previous={this.handlePrevClick.bind(this, currentIndex)}
            >
                <CarouselIndicators activeIndex={currentIndex} items={items} onClickHandler={this.handleSlideChange}/>
                {items}
                <CarouselControl direction="next" onClickHandler={this.handleNextClick.bind(this, currentIndex)}/>
                <CarouselControl direction="prev" onClickHandler={this.handlePrevClick.bind(this, currentIndex)}/>
            </Carousel>;
        }

        return (
            <div id="dsd-app">
                <Container className="dsd" fluid={true}>
                    <Alert/>
                    {carousel}
                </Container>
            </div>
        );
    }
}

export default FluxContainer.create(DigitalSalesDecks);
export {DigitalSalesDecks};
