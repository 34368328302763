/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Col, Row} from 'react-bootstrap';

import {DeliveryActions} from './delivery-actions';
import Validations from '../../../common/validations/validations';
import {FormItem} from '../../../form/form';
import {MessagesContext} from '../../../messages/messages-context';

export default class Notification extends React.Component {
    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            index: PropTypes.number.isRequired,
            notification: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    constructor(props) {
        super(props);

        this.handleRemove = this.handleRemove.bind(this);
    }

    shouldComponentUpdate(nextProps/*, nextState*/) {
        if (nextProps.notification !== this.props.notification) {
            return true;
        }
        return false;
    }

    static contextType = MessagesContext;

    handleRemove() {
        DeliveryActions.removeNotification(this.props.index, this.props.notification);
        return;
    }

    render() {
        return (
            <Row>
                <Col md={5}>
                    <FormItem attr="notificationName"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['account.tab.delivery.notifications.name']}
                        model={this.props.notification}
                        setter={DeliveryActions.updateNotifications.bind(this, this.props.index)}
                        type="name"
                        validations={[Validations.noSymbols, Validations.noNumbers, Validations.max(50), Validations.required]}
                    />
                </Col>
                <Col md={5}>
                    <FormItem attr="notificationEmail"
                        disabled={this.props.disabled}
                        label={this.context.intl.messages['account.tab.delivery.notifications.email']}
                        model={this.props.notification}
                        setter={DeliveryActions.updateNotifications.bind(this, this.props.index)}
                        type="email"
                        validations={[Validations.email, Validations.max(100), Validations.required]}
                    />
                </Col>
                <Col md={2}>
                    <Button
                        bsSize="sm"
                        bsStyle="danger"
                        className="remove-notification"
                        onClick={this.handleRemove}
                    ><i className="fa fa-trash"></i>&nbsp;{this.context.intl.messages['common.delete']}</Button>
                </Col>
            </Row>
        );
    }
}
