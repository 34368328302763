/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {DropdownButton, MenuItem} from 'react-bootstrap';

import {AssetTitleActions} from './asset-title-actions';
import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import {RightsConstants, CalculateRights, RightsTooltip} from '../../assets/asset-rights';
import {MessagesContext} from '../../messages/messages-context';
import {OrderActions} from '../../orders/order-actions';
import OrderStore from '../../orders/order-store';
import {PlayerActions} from '../../player/player-actions';
import SessionStore from '../../session/session-store';
import {TitleConstants} from '../../titles/title-actions';
import {HandleWithTriggerKeys, FormatReleaseDate} from '../../utils/utils';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/app/dataTables.brainiac.css');

// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

class ListViewTableVideo extends Component {
    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool,
            canInstantOrderVideos: PropTypes.bool,
            location: PropTypes.object.isRequired,
            onSortChange: PropTypes.func.isRequired,
            showAirDate: PropTypes.bool,
            title: PropTypes.instanceOf(Immutable.Map).isRequired,
            videoSuperType: PropTypes.string.isRequired,
            videos: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            canAddToCart: false,
            canInstantOrderVideos: false,
            showAirDate: false,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            id: Math.ceil(Math.random() * 100000),
            showSort: false
        };

        this.createTable = this.createTable.bind(this);
        this.getVideo = this.getVideo.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSelectSort = this.handleSelectSort.bind(this);
        this.handleToggleSort = this.handleToggleSort.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    componentDidMount() {
        this.createTable(this.props);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.canAddToCart !== this.props.canAddToCart ||
            nextProps.title !== this.props.title ||
            nextProps.videos !== this.props.videos ||
            nextState.showSort !== this.state.showSort;
    }

    UNSAFE_componentWillUpdate() {
        this.$tableAPI.clear();
        this.$tableAPI.destroy();
        return;
    }

    componentDidUpdate(nextProps) {
        this.createTable(nextProps);
        return;
    }

    static contextType = MessagesContext;

    createTable(props) {
        this.$table = jQuery(`#list-documents-table-${this.state.id}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });

        this.updateRows(props);
    }

    handleSelectSort(eventKey) {
        let sortFilter = eventKey.split(':');
        this.props.onSortChange(sortFilter[0], sortFilter[1]);
        return;
    }

    handleToggleSort() {
        this.setState(prevState => ({
            showSort: !prevState.showSort
        }));

        return;
    }

    updateRows(props) {
        if (props.videos) {
            props.videos.get('results').forEach(v => {
                const classNames = ClassNames(
                    'glyphicon video-thumbnail-tooltip',
                    {
                        'glyphicon-globe': CalculateRights(v) === RightsConstants.RIGHTS.CLEARED,
                        'glyphicon-warning-sign': CalculateRights(v) !== RightsConstants.RIGHTS.CLEARED
                    }
                );

                let assetName = v.get('name');
                if (v.get('childStackAssetCount') > 0 ) {
                    assetName += ` (+${v.get('childStackAssetCount')})`;
                }

                let runtime = '';
                if (v.get('runtime')) {
                    runtime = v.get('runtime');
                }

                let row = [
                    `<a videoId="${v.get('assetId')}" href="#"><span class="glyphicon glyphicon-play-circle video-play-single"></span></a>`,
                    `<a videoId="${v.get('assetId')}" href="#"><span class="video-play-single">${assetName}</span></a>`,
                    `<a href="#"><span videoId="${v.get('assetId')}" class="glyphicon glyphicon-transfer share-video"></span></a>`,
                    `<span class="${classNames}" data-toggle="tooltip" data-placement="top" title="${RightsTooltip(v, this.context.intl.messages)}""></span>`,
                    runtime,
                    AssetTypeConstants.VIDEO_CONTENT_TYPE[v.get('contentType')].description,
                    ''
                ];

                if (this.props.showAirDate) {
                    let airDate;
                    if (v.get('releaseDate')) {
                        if (props.title.get('titleReleaseDateType') === TitleConstants.RELEASE_DATE_TYPES.DISPLAY_NAME.id) {
                            airDate = FormatReleaseDate(props.title.get('titleReleaseDateDisplayName'), props.title.get('titleReleaseDateType'), '');
                        } else {
                            airDate = FormatReleaseDate(Moment(v.get('releaseDate').split('T')[0]), props.title.get('titleReleaseDateType'));
                        }
                    } else {
                        airDate = '-';
                    }
                    row.splice(6, 0, airDate);
                }

                const hasChildren = v.get('childStackAssetCount') > 0;
                const canDownload = OrderStore.canDownloadVideo(v);
                const hasDownloadPermission = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.VIDEOS);
                let addToCart = '';
                if (canDownload && !hasChildren) {
                    addToCart = `<a href="#">
                    <span videoId="${v.get('assetId')}" data-toggle="tooltip" data-placement="top" title="${this.context.intl.messages['cart.download.video.mp4']}"" class="download-video glyphicon glyphicon-download-alt"></span>
                    </a>`;
                }

                if (props.canAddToCart && v.get('deliveryType') !== AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE) {
                    const isInstantOrder = OrderStore.isInstantOrderVideo(v, this.props.canInstantOrderVideos);

                    addToCart = `<a href="#"><span videoId="${v.get('assetId')}" class="video-overlay-cart fas fa-shopping-cart"></span></a>`;

                    if (hasChildren || isInstantOrder || canDownload) {
                        let instantOrder = '';
                        let instantOrderAll = '';
                        let downloadVideo = '';
                        if (isInstantOrder) {
                            instantOrder = `<li><a videoId="${v.get('assetId')}" class="tooltip-link video-instant-order"><span class="fas fa-cart-shopping-fast"></span>&nbsp;${this.context.intl.messages['cart.instant.order.video.single']}</a></li>`;

                            if (hasChildren) {
                                instantOrderAll = `<li><a videoId="${v.get('assetId')}" class="tooltip-link video-instant-order-all"><span class="fas fa-cart-shopping-fast"></span>&nbsp;${this.context.intl.messages['cart.instant.order.video.multi']}</a></li>`;
                            }
                        }

                        let addAll = '';
                        if (hasChildren) {
                            addAll = `<li><a videoId="${v.get('assetId')}" class="tooltip-link video-overlay-cart video-overlay-cart-all"><span class="fas fa-cart-circle-plus"></span>&nbsp;${this.context.intl.messages['cart.add.video.multi']}</a></li>`;
                        }

                        if (canDownload && !hasChildren) {
                            downloadVideo = `<li><a videoId="${v.get('assetId')}" class="tooltip-link download-video"><span class="glyphicon glyphicon-download-alt"></span>&nbsp;${this.context.intl.messages['cart.download.video.mp4']}</a></li>`;
                        }

                        const addToCartIcon = ClassNames('fas', {'fa-shopping-cart': !isInstantOrder, 'fa-cart-arrow-down': isInstantOrder});
                        addToCart =`
                            <a href="#" data-toggle="dropdown" class="dropdown-toggle"><span aria-label=${this.context.intl.messages['asset.image.gallery.add-to-cart']} class="${addToCartIcon}"></span></a>
                            <ul class="dropdown-menu dropdown-download-menu">
                                ${instantOrder}
                                ${instantOrderAll}
                                <li><a videoId="${v.get('assetId')}" class="tooltip-link video-overlay-cart"><span class="fas fa-cart-circle-plus"></span>&nbsp;${this.context.intl.messages['cart.add.video.single']}</a></li>
                                ${addAll}
                                ${downloadVideo}
                            </ul>
                        `;
                    }
                }
                if (props.canAddToCart || hasDownloadPermission) {
                    row.splice(2, 0, addToCart);
                }

                this.$tableAPI.row.add(row);
                return;
            });
        }
        this.$tableAPI.draw(false);
        this.$table.find('[data-toggle="tooltip"]').tooltip?.();
        return;
    }

    getVideo(event) {
        let videoId = parseInt(event.target.getAttribute('videoId'), 10);
        return this.props.videos.get('results').find(v => v.get('assetId') === videoId);
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        switch (true) {
        case !!~event.target.className.indexOf('video-play-single'):
            event.preventDefault();
            let videoId = parseInt(event.target.parentNode.getAttribute('videoId'), 10);
            let video = this.props.videos.get('results').find(v => v.get('assetId') === videoId);
            PlayerActions.showPlayer(video, undefined, video.get('titleId'));
            break;

        // video-instant-order-all is first on purpose. Be careful with indexOf()
        // or it may create bugs.
        case !!~event.target.className.indexOf('video-instant-order-all'):
            event.preventDefault();
            OrderActions.instantOrderVideo(this.props.title.get('id'), Immutable.List([this.getVideo(event)]), true);
            break;
        case !!~event.target.className.indexOf('video-instant-order'):
            event.preventDefault();
            OrderActions.instantOrderVideo(this.props.title.get('id'), Immutable.List([this.getVideo(event)]));
            break;

        case !!~event.target.className.indexOf('video-overlay-cart'):
            event.preventDefault();
            OrderActions.add(AssetTypeConstants.ASSET_TYPE.VIDEO, this.props.title.get('id'), [this.getVideo(event)], event.target.className.indexOf('video-overlay-cart-all') !== -1);
            break;
        case !!~event.target.className.indexOf('share-video'):
            event.preventDefault();
            AssetTitleActions.showShare(this.getVideo(event));
            break;
        case !!~event.target.className.indexOf('download-video'):
            event.preventDefault();
            OrderActions.downloadVideo(this.getVideo(event).get('assetId'));
            break;
        }

        return;
    }

    render() {
        const query = QueryString.parse(this.props.location.search);
        let airDateAsc, airDateDesc;
        if (this.props.showAirDate) {
            airDateAsc = <MenuItem eventKey="titlereleasedate:asc" active={(query['sort-field-name'] === 'titlereleasedate' && query['sort-direction'] === 'asc')}>
                {this.context.intl.messages['title.video-list.filter.air-date-asc']}
            </MenuItem>;
            airDateDesc = <MenuItem eventKey="titlereleasedate:desc" active={(query['sort-field-name'] === 'titlereleasedate' && query['sort-direction'] === 'desc')}>
                {this.context.intl.messages['title.video-list.filter.air-date-desc']}
            </MenuItem>;
        }
        let sortDropdown = (
            <DropdownButton
                bsStyle="default"
                className="videos-sort-button"
                onToggle={this.handleToggleSort}
                open={this.state.showSort}
                onSelect={this.handleSelectSort}
                title="Sort"
            >
                <MenuItem eventKey="relevance:asc" active={(query['sort-field-name'] === 'relevance')}>
                    {this.context.intl.messages['title.asset-list.filter.relevance']}
                </MenuItem>
                {airDateAsc}
                {airDateDesc}
                <MenuItem eventKey="createdDate:asc" active={(query['sort-field-name'] === 'createdDate') && (query['sort-direction'] === 'asc')}>
                    {this.context.intl.messages['title.asset-list.filter.date-added-asc']}
                </MenuItem>
                <MenuItem eventKey="createdDate:desc" active={(query['sort-field-name'] === 'createdDate') && (query['sort-direction'] === 'desc')}>
                    {this.context.intl.messages['title.asset-list.filter.date-added-desc']}
                </MenuItem>
            </DropdownButton>
        );

        if (!this.props.title || this.props.videos.get('results').size === 0) {
            return null;
        }

        const hasDownloadPermission = SessionStore.canUser(SessionStore.PERMISSIONS.DOWNLOAD.VIDEOS);

        return (
            <div className="display-results display-results-table">
                <h5>{this.context.intl.messages[`title.videos.content-types.${this.props.videoSuperType}`]}&nbsp;<small>({this.props.videos.get('totalCount')}){sortDropdown}</small></h5>
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                    <table className="table table-striped datatable-responsive dataTable no-footer dtr-column collapsed" id={`list-documents-table-${this.state.id}`}>
                        <thead>
                            <tr role="row">
                                <th scope="col" className="icon-column" rowSpan={1} colSpan={1}/>
                                <th scope="col" rowSpan={1} colSpan={1}>
                                    {this.context.intl.messages['title.video-list.video-name']}
                                </th>
                                {(this.props.canAddToCart || hasDownloadPermission) && <th scope="col" className="icon-column" rowSpan={1} colSpan={1}><span className="glyphicon glyphicon-shopping-cart"></span></th>}
                                <th scope="col" className="icon-column" rowSpan={1} colSpan={1}><span className="glyphicon glyphicon-transfer"></span></th>
                                <th scope="col" className="icon-column" rowSpan={1} colSpan={1}><span className="glyphicon glyphicon-globe"></span></th>
                                <th scope="col" rowSpan={1} colSpan={1}>
                                    {this.context.intl.messages['title.video-list.length']}
                                </th>
                                <th scope="col" rowSpan={1} colSpan={1}>
                                    {this.context.intl.messages['title.video-list.type']}
                                </th>
                                {
                                    this.props.showAirDate &&
                                    <th scope="col" rowSpan={1} colSpan={1}>
                                        {this.context.intl.messages['title.video-list.air-date']}
                                    </th>
                                }
                                <th scope="col" className="control icon-column" rowSpan={1} colSpan={1} />
                            </tr>
                        </thead>
                        <tbody
                            onClick={this.handleRowClick}
                            onKeyUp={HandleWithTriggerKeys(this.handleRowClick)}
                            role="button"
                            tabIndex="0"
                        />
                    </table>
                </div>
            </div>
        );
    }

}

export default ListViewTableVideo;
