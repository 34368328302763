/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import React from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';

import {MessagesContext} from '../../messages/messages-context';
import {RouterActions} from '../../router/router-actions';
import {NotificationActions} from '../notification-actions';
import NotificationStore from '../notification-store';

import './domain-zero-without-account.less';

export class DomainZeroWithoutAccountModal extends React.Component {
    static calculateState() {
        return {
            show: NotificationStore.getState().get('domainZeroWithoutAccountModal')
        };
    }

    static getStores() {
        return [NotificationStore];
    }

    static contextType = MessagesContext;

    handleDismiss() {
        NotificationActions.hideModal('domainZeroWithoutAccountModal');
        RouterActions.redirect('/first-time?from=domain-zero-modal');
        return;
    }

    render() {
        return (
            <Modal show={this.state?.show}>
                <Modal.Header className="bg-wb-blue">
                    <Modal.Title className="text-center">{this.context.intl.messages['login.domain-zero-without-account.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img className="domain-zero-image" src={require('./connect-warner-media_pos.svg')}/>
                    <br />
                    <br />
                    <div>{this.context.intl.messages['login.domain-zero-without-account.message.first-sign-in']}</div>
                    <div>{this.context.intl.messages['login.domain-zero-without-account.message.first-profile']}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col md={4} mdOffset={4}>
                            <Button
                                block
                                bsSize="large"
                                bsStyle="primary"
                                onClick={this.handleDismiss}
                            >
                                {this.context.intl.messages['common.continue']}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(DomainZeroWithoutAccountModal);
