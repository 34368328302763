/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel} from 'react-bootstrap';
import Select from 'react-select';

import {MessagesContext} from '../../messages/messages-context';

class BaseSelect extends Component {
    static get propTypes() {
        return {
            attr: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            filterOptions: PropTypes.func,
            getter: PropTypes.func,
            groupBy: PropTypes.string,
            label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            labelKey: PropTypes.string,
            model: PropTypes.object.isRequired,
            multi: PropTypes.bool,
            name: PropTypes.string.isRequired,
            onInputChange: PropTypes.func,
            optionRenderer: PropTypes.func,
            options: PropTypes.string,
            placeholder: PropTypes.string,
            required: PropTypes.bool,
            setter: PropTypes.func.isRequired,
            store: PropTypes.object.isRequired,
            valueKey: PropTypes.string,
            valueRenderer: PropTypes.func
        };
    }

    static get defaultProps() {
        return {
            disabled: false,
            filterOptions: /* istanbul ignore next */function() {},
            getter: /* istanbul ignore next */function() {},
            groupBy: null,
            label: '',
            labelKey: 'name',
            multi: false,
            onInputChange: undefined,
            optionRenderer: undefined,
            options: 'items',
            placeholder: undefined,
            required: undefined,
            value: undefined,
            valueKey: 'id',
            valueRenderer: undefined
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: this.props.store.getState().get('isLoading'),
            options: this.props.store.getState().getIn(this.props.options.split('.')),
            value: this.props.model.get(this.props.attr)
        };
        this.groupOptions = this.groupOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Register to the store that was passed as prop.
     */
    UNSAFE_componentWillMount() {
        this.storeListeners = [
            this.props.store.addListener(() => {
                /**
                 * Read the options from the options prop. Use the split('.')
                 * to allow reading from nested objects like 'user.groups'.
                 */
                this.setState({
                    isLoading: this.props.store.getState().get('isLoading'),
                    options: this.props.store.getState().getIn(this.props.options.split('.'))
                });
                return;
            })
        ];
        return;
    }

    /**
     * Always call the getter after mount.
     */
    componentDidMount() {
        this.props.getter();
        return;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({value: nextProps.model.get(this.props.attr)});
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.disabled !== this.props.disabled ||
            nextProps.labelKey !== this.props.labelKey ||
            nextProps.multi !== this.props.multi ||
            nextProps.name !== this.props.name ||
            nextProps.options !== this.props.options ||
            nextProps.store !== this.props.store ||
            nextProps.valueKey !== this.props.valueKey) {
            return true;
        }

        if (nextState.isLoading !== this.state.isLoading ||
            nextState.options !== this.state.options ||
            nextState.value !== this.state.value) {
            return true;
        }

        return false;
    }

    componentWillUnmount() {
        this.storeListeners.forEach(listener => listener.remove());
        return;
    }

    static contextType = MessagesContext;

    groupOptions(options) {
        let groupByOptions = [];

        options.forEach(option => {
            if (option[this.props.groupBy]) {
                option.disabled = true;
                groupByOptions.push(option, ...Object.keys(option[this.props.groupBy]).map(k => {
                    let o = option[this.props.groupBy][k];
                    o.name = `---- ${o.name}`;
                    return o;
                }));
            } else {
                groupByOptions.push(option);
            }
        });
        return groupByOptions;
    }

    handleChange(value) {
        if (Array.isArray(value) && !value.length && !this.props.multi) {
            value = undefined;
        }
        this.props.setter(this.props.attr, Immutable.fromJS(value));
    }

    render() {
        let options;
        if (this.state.options) {
            options = this.state.options.toJS();
            if (this.props.groupBy) {
                options = this.groupOptions(options);
            }
        }

        let label = this.props.label;
        if (this.props.required) {
            label = <span>{this.props.label}&nbsp;<span className="text-red">*</span></span>;
        }
        label = <ControlLabel>{label}</ControlLabel>;

        // This is done here because this.context isn't available during
        // getDefaultProps().
        let placeholder = this.context.intl.messages['common.form.select.placeholder'];
        if (this.props.placeholder) {
            placeholder = this.props.placeholder;
        }

        let value = '';
        let modelValue = this.state.value;
        /* istanbul ignore else */
        if (modelValue !== null && modelValue !== undefined) {
            if (modelValue.toJS) {
                value = modelValue.toJS();
            } else {
                let selected = (options && options.length)? options.find(item => item[this.props.labelKey] === modelValue): null;
                value = selected? selected : modelValue;
            }
        }

        let select = (
            <Select
                getOptionLabel={/* istanbul ignore next */data => /* istanbul ignore next */data[this.props.labelKey]}
                getOptionValue={/* istanbul ignore next */data => /* istanbul ignore next */data[this.props.valueKey]}
                disabled={this.props.disabled}
                filterOption={this.props.filterOptions}
                isMulti={this.props.multi}
                isLoading={this.state.isLoading}
                name={this.props.name}
                onChange={this.handleChange}
                onInputChange={this.props.onInputChange}
                options={options}
                optionRenderer={this.props.optionRenderer}
                placeholder={placeholder}
                value={value}
                valueRenderer={this.props.valueRenderer}
            />
        );
        /* istanbul ignore else */
        if (this.props.label) {
            return (
                <div>
                    {label}
                    {select}
                </div>
            );
        }
        return select;
    }
}

export default BaseSelect;
