/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import Config from '../../config/config';

import type {RootState} from './store';

const ACCEPT_HEADER = 'application/vnd.wbtv-v3+json,application/vnd.wbtv-v2+json;q=0.2,application/vnd.wbtv-v1+json;q=0.1';
export const ACCEPT_V1_HEADER = 'application/vnd.wbtv-v1+json';
export const ACCEPT_V2_HEADER = 'application/vnd.wbtv-v2+json';
export const ACCEPT_V3_HEADER = 'application/vnd.wbtv-v3+json';

const baseUrl = Config.ApiBaseUrl;

export const BaseQuery = fetchBaseQuery({baseUrl});

// Unauthed queries with partner key attached (for login page, etc)
export const BaseQueryWithPartnerKey = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
        headers.set('WBTV-Partner-Key', Config.Partners.WBTVD.key);
        headers.set('WBTV-Partner-Id', Config.Partners.WBTVD.id);
        return headers;
    }
});

export const BaseQueryWithAuth = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers: Headers, {getState}) => {
        const token = (getState() as RootState).session.token;
        if (token) {
            headers.set('authorization', token);
        } else {
            window.location.replace('/login');
        }

        if (!headers.get('accept')) {
            headers.set('accept', ACCEPT_HEADER);
        }

        return headers;
    }
});
