/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import {DownloadActions} from '../common/download/download-actions';

export default function SearchListWrapper({children, downloadSearchUrl, title, showDownloadSearchButton, canDownloadAsSpreadsheet}) {
    const downloadSpreadsheetLink = `${downloadSearchUrl}&download=true`;
    return (
        <div>
            {title}
            {canDownloadAsSpreadsheet && showDownloadSearchButton && <div className="text-center padding-bottom-20">
                <Button className="btn-secondary btn btn-default" onClick={() => DownloadActions.startDownloadExecution(downloadSpreadsheetLink)}>
                    <Glyphicon glyph="download-alt" />
                    <span> <FormattedMessage id="search-list.download.spreadsheet" /></span>
                </Button>
            </div>}
            {children}
        </div>
    );
}

SearchListWrapper.propTypes = {
    canDownloadAsSpreadsheet: PropTypes.bool,
    children: PropTypes.node.isRequired,
    downloadSearchUrl: PropTypes.string.isRequired,
    showDownloadSearchButton: PropTypes.bool,
    title: PropTypes.node,
};

SearchListWrapper.defaultProps = {
    canDownloadAsSpreadsheet: false,
    title: null,
    showDownloadSearchButton: false,
};
