/* eslint-disable jsx-a11y/no-autofocus */
/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {InputGroup} from 'react-bootstrap';

import {MessagesContext} from '../../messages/messages-context';

export default class SearchField extends React.PureComponent {
    static get propTypes() {
        return {
            autofocus: PropTypes.bool,
            className: PropTypes.string,
            handleValueChanged: PropTypes.func,
            placeholder: PropTypes.string,
            value: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            autofocus: false,
            className: '',
            handleValueChanged: /* istanbul ingnore next */() => /* istanbul ingnore next */void 0,
            placeholder: '',
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
        };

        this.handleInputEvent = this.handleInputEvent.bind(this);
        this.handleKeyPressEvent = this.handleKeyPressEvent.bind(this);
        this.handleSubmitEvent = this.handleSubmitEvent.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState(/* istanbul ignore next */() => /* istanbul ignore next */({value: nextProps.value}));
        }
    }

    static contextType = MessagesContext;

    emitValue() {
        this.props.handleValueChanged(this.state.value);
    }

    /**
     * Handle input event
     * @param event {React.FormEvent<HTMLInputElement>}
     */
    handleInputEvent(event) {
        const value = event.target.value;
        this.setState(/* istanbul ignore next */() => /* istanbul ignore next */({value}));
    }

    /**
     * Handle keypress event
     * @param event {React.KeyboardEvent<HTMLInputElement>}
     */
    handleKeyPressEvent(event) {
        /* istanbul ignore else */
        if (event.which === 13) {
            this.emitValue();
        }
    }

    /**
     * Handle keypress event
     * @param event {React.FormEvent}
     */
    handleSubmitEvent(event) {
        event.preventDefault();
        this.emitValue();
    }

    render() {
        const label = this.props.placeholder || this.context.intl.messages['document-titles.search'];
        return (
            <form className={ClassNames(this.props.className, 'D(f)')} onSubmit={this.handleSubmitEvent}>
                <InputGroup className="Flxg(1)">
                    <span className="input-group-addon">
                        <span className="h3 glyphicon glyphicon-search margin-all-0" />
                    </span>
                    <input
                        aria-label={label}
                        autoFocus={this.props.autofocus}
                        className="form-control input-lg"
                        onInput={this.handleInputEvent}
                        onKeyPress={this.handleKeyPressEvent}
                        placeholder={this.props.placeholder}
                        type="text"
                        value={this.state.value}
                    />
                </InputGroup>
            </form>
        );
    }
}
