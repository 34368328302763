/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {FormattedMessage} from 'react-intl';

interface ModalProps {
    disableSubmit?: boolean;
    children: JSX.Element;
    onHide: () => void;
    show: boolean;
    onSubmit?: () => void | null;
    title: JSX.Element;
    onSubmitText?: JSX.Element;
}

const ViewGridIcon = ({show, onHide, children, title, onSubmit, disableSubmit, onSubmitText}: ModalProps): JSX.Element | null => {
    if (!show) {
        return null;
    }

    return <div>
        <div tabIndex={-1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center">
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto lg:mt-5">
                <div className="relative bg-white shadow dark:bg-gray-700 ">
                    <div className="flex bg-gray-font justify-center p-3">
                        <div className="flex text-lg font-default font-medium dark:text-white">
                            {title}
                        </div>
                        <button onClick={onHide}type="button" className="absolute top-2 right-2 text-gray-icon hover:text-gray-stroke rounded-lg text-sm p-1 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                    <div className="p-6 space-y-6">
                        {children}
                    </div>

                    <div className="flex justify-between p-4 space-x-2 rounded-b border-t border-gray-light dark:border-gray-600">
                        <button onClick={onHide} type="button" className="text-gray-active bg-gray-light border border-gray-font hover:border-black-background-solid focus:ring-4 focus:outline-none focus:ring-blue-300 font-normal font-default text-sm px-3 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            {<FormattedMessage
                                defaultMessage="Close"
                                description="Default text for modal close button"
                                id="modal.button.close"
                            />}
                        </button>
                        {onSubmit && <button onClick={onSubmit} disabled={disableSubmit} type="button" className="text-white bg-blue-active border border-black-background-black hover:bg-blue-solid focus:ring-4 focus:outline-none focus:ring-blue-300 text-sm font-normal font-default px-3 py-1.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">{onSubmitText}</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

ViewGridIcon.defaultProps = {
    disableSubmit: false,
    onSubmit: null,
    onSubmitText: <FormattedMessage
        defaultMessage="Submit"
        description="Default text for modal approve/submit button"
        id="modal.button.default.submit"
    />
};

export default ViewGridIcon;
