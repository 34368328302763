/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {AssetTypeConstants} from '../../../asset-types/asset-type-constants';
import AssetRightsIcon from '../../../assets/asset-rights';
import {MessagesContext} from '../../../messages/messages-context';
import {OrderActions} from '../../../orders/order-actions';
import OrderStore from '../../../orders/order-store';
import {HandleWithTriggerKeys} from '../../../utils/utils';
import {AssetTitleActions} from '../asset-title-actions';

class VideoToolbar extends Component {

    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool,
            canInstantOrderVideos: PropTypes.bool,
            titleId: PropTypes.number.isRequired,
            video: PropTypes.instanceOf(Immutable.Map),
        };
    }

    static get defaultProps() {
        return {
            canAddToCart: false,
            canInstantOrderVideos: false,
            video: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.handleAddToCartMulti = this.handleAddToCartMulti.bind(this);
        this.handleAddToCartMenu = this.handleAddToCartMenu.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleInstantOrder = this.handleInstantOrder.bind(this);
        this.handleInstantOrderAll = this.handleInstantOrderAll.bind(this);
        this.handleShare = this.handleShare.bind(this);

        return;
    }

    static contextType = MessagesContext;

    handleAddToCart(event) {
        event.preventDefault();

        OrderActions.add(AssetTypeConstants.ASSET_TYPE.VIDEO, this.props.titleId, [this.props.video]);
        this.setState({visible: false});
    }

    handleAddToCartMulti(event) {
        event.preventDefault();

        OrderActions.add(AssetTypeConstants.ASSET_TYPE.VIDEO, this.props.titleId, [this.props.video], true);
        this.setState({visible: false});
    }

    handleAddToCartMenu(event) {
        event.preventDefault();

        this.setState(prevState => ({visible: !prevState.visible}));
    }

    handleDownload(event) {
        event.preventDefault();
        OrderActions.downloadVideo(this.props.video.get('assetId'));
        this.setState({visible: false});
    }

    handleInstantOrder(event) {
        event.preventDefault();

        OrderActions.instantOrderVideo(this.props.titleId, Immutable.List([this.props.video]));
        this.setState({visible: false});
    }

    handleInstantOrderAll(event) {
        event.preventDefault();

        OrderActions.instantOrderVideo(this.props.titleId, Immutable.List([this.props.video]), true);
        this.setState({visible: false});
    }

    handleShare(event) {
        event.preventDefault();

        AssetTitleActions.showShare(this.props.video);
    }

    render() {
        if (!this.props.video) {return null;}

        let addToCart;
        let menu;

        const hasChildren = this.props.video.get('childStackAssetCount') > 0;
        const canDownload = OrderStore.canDownloadVideo(this.props.video);
        if (canDownload && !hasChildren) {
            addToCart = (
                <span
                    className="glyphicon glyphicon-download-alt"
                    onClick={this.handleDownload}
                    onKeyUp={HandleWithTriggerKeys(this.handleDownload)}
                    role="button"
                    tabIndex="0"
                    title={this.context.intl.messages['cart.download.video.mp4']}
                ></span>
            );
        }

        if (
            this.props.canAddToCart &&
            this.props.video.get('deliveryType') !== AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE
        ) {
            addToCart = (
                <span
                    className="fas fa-shopping-cart"
                    onClick={this.handleAddToCart}
                    onKeyUp={HandleWithTriggerKeys(this.handleAddToCart)}
                    role="button"
                    tabIndex="0"
                ></span>
            );

            const isInstantOrder = OrderStore.isInstantOrderVideo(this.props.video, this.props.canInstantOrderVideos);
            if (
                hasChildren ||
                isInstantOrder ||
                canDownload
            ) {
                addToCart = (
                    <span
                        aria-label={this.context.intl.messages['asset.image.gallery.add-to-cart']}
                        className={ClassNames('fas', {'fa-shopping-cart': !isInstantOrder, 'fa-cart-arrow-down': isInstantOrder})}
                        onClick={this.handleAddToCartMenu}
                        onKeyUp={HandleWithTriggerKeys(this.handleAddToCartMenu)}
                        role="button"
                        tabIndex="0"
                    ></span>
                );

                let display = 'none';
                if (this.state.visible) {
                    display = 'block';
                }

                const style = {
                    top: '36px',
                    left: '94px',
                    display
                };

                menu = (
                    <div className="tooltip fade bottom in" role="tooltip" style={style}>
                        <div className="tooltip-arrow" style={{left: '50%'}}></div>
                        <div className="tooltip-inner">
                            <div className="tooltip-menu" aria-hidden="true">
                                {isInstantOrder && (
                                    <button
                                        className="tooltip-link tooltip-add-single"
                                        onClick={this.handleInstantOrder}
                                        onKeyUp={HandleWithTriggerKeys(this.handleInstantOrder)}
                                    ><i className="fas fa-cart-shopping-fast"/>&nbsp;{this.context.intl.messages['cart.instant.order.video.single']}</button>
                                )}

                                {hasChildren && isInstantOrder && (
                                    <button
                                        className="tooltip-link tooltip-add-multiple"
                                        onClick={this.handleInstantOrderAll}
                                        onKeyUp={HandleWithTriggerKeys(this.handleInstantOrderAll)}
                                    ><i className="fas fa-cart-shopping-fast"/>&nbsp;{this.context.intl.messages['cart.instant.order.video.multi']}</button>
                                )}

                                <button
                                    className="tooltip-link tooltip-add-single"
                                    onClick={this.handleAddToCart}
                                    onKeyUp={HandleWithTriggerKeys(this.handleAddToCart)}
                                ><i className="fas fa-cart-circle-plus"/>&nbsp;{this.context.intl.messages['cart.add.video.single']}</button>

                                {hasChildren && (
                                    <button
                                        className="tooltip-link tooltip-add-multiple"
                                        onClick={this.handleAddToCartMulti}
                                        onKeyUp={HandleWithTriggerKeys(this.handleAddToCartMulti)}
                                    ><i className="fas fa-cart-circle-plus"/>&nbsp;{this.context.intl.messages['cart.add.video.multi']}</button>
                                )}

                                {canDownload && !hasChildren && (
                                    <button
                                        className="tooltip-link tooltip-add-single"
                                        onClick={this.handleDownload}
                                        onKeyUp={HandleWithTriggerKeys(this.handleDownload)}
                                    ><i className="glyphicon glyphicon-download-alt"/>&nbsp;{this.context.intl.messages['cart.download.video.mp4']}</button>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }
        }

        // There was previous login here to only hide for the press portal.
        // Since then, we have added many other press sites (CN, AdultSwim, HBO, etc),
        // this logic seemed odd. It must be a permission instead of a partner check.
        const shareAsset = (
            <div className="inline-block">
                <span
                    className="glyphicon glyphicon-transfer video-thumbnail-tooltip"
                    title="Share"
                    onClick={this.handleShare}
                    onKeyUp={HandleWithTriggerKeys(this.handleShare)}
                    role="button"
                    tabIndex="0"
                ></span>
            </div>
        );

        return (
            <div className="video-toolbar">
                {shareAsset}
                <AssetRightsIcon video={this.props.video}/>
                {addToCart}
                {menu}
            </div>
        );
    }

}

export default VideoToolbar;
