/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './hd-titles.less';

export default class HDTitles extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.hd-titles">
                <div className="op_container_hd_titles">

                    <div id="op_header" className="myrow">
                        <h1>Titles In HD</h1>
                        <div className="billboardImgs">
                            <a href="/titles/51651" rel="noopener noreferrer" target="_blank">
                                <img src="https://wbtvd.com/page/hd-titles/hd_billboard_roots.jpg" />
                            </a>
                        </div>
                    </div>

                    <div className="hd-videos-row">
                        <div className="hd-videos-header">

                            <h2>NEWLY AVAILABLE TV TITLES IN HD</h2>

                        </div>
                        <ul id="thetitles">
                            <li className="atitle">
                                <div className="video-single-width">
                                    <a href="/titles/51660" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                        <img alt="" src="https://wbtvd.com/page/hd-titles/poster_the_thorn_birds.jpg"/>
                                    </a>
                                    <a href="/titles/51660" className="hd-video-title" rel="noopener noreferrer" target="_blank">The Thorn Birds</a>
                                </div>
                            </li>
                            <li className="atitle">
                                <div className="video-single-width">
                                    <a href="/titles/52368" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                        <img alt="" src="https://wbtvd.com/page/hd-titles/poster_hangin_with_mr_cooper.jpg"/>
                                    </a>
                                    <a href="/titles/52368" className="hd-video-title" rel="noopener noreferrer" target="_blank">Hangin&apos; With Mr. Cooper</a>
                                </div>
                            </li>
                            <li className="atitle">
                                <div className="video-single-width">
                                    <a href="/titles/52006" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                        <img alt="" src="https://wbtvd.com/page/hd-titles/poster_the_wayans_bros.jpg"/>
                                    </a>
                                    <a href="/titles/52006" className="hd-video-title" rel="noopener noreferrer" target="_blank">The Wayans Bros.</a>
                                </div>
                            </li>
                            <li className="atitle">
                                <div className="video-single-width">
                                    <a href="/titles/52251" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                        <img alt="" src="https://wbtvd.com/page/hd-titles/poster_the_jamie_foxx_show.jpg"/>
                                    </a>
                                    <a href="/titles/52251" className="hd-video-title" rel="noopener noreferrer" target="_blank">The Jamie Foxx Show</a>
                                </div>
                            </li>
                        </ul>

                    </div>
                    <div className="clear">

                        <div className="hd-videos-row">
                            <div className="hd-videos-header">
                                <h2>
                                    THEATRICAL TITLES IN HD
                                </h2>
                                <a href="/film?film=true&size=40&offset=0&hd=true" className="hd-videos-header-link" rel="noopener noreferrer" target="_blank">View All</a>
                            </div>
                            <ul>
                                <li className="atitle">
                                    <div className="video-single-width">
                                        <a href="/titles/1529370" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                            <img alt="" src="https://wbtvd.com/page/hd-titles/poster_american_sniper.jpg"/>
                                        </a>
                                        <a href="/titles/1529370" className="hd-video-title" rel="noopener noreferrer" target="_blank">American Sniper</a>
                                    </div>
                                </li>
                                <li className="atitle">
                                    <div className="video-single-width">
                                        <a href="/titles/227703" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                            <img alt="" src="https://wbtvd.com/page/hd-titles/poster_batman_begins.jpg"/>
                                        </a>
                                        <a href="/titles/227703" className="hd-video-title" rel="noopener noreferrer" target="_blank">Batman Begins</a>
                                    </div>
                                </li>
                                <li className="atitle">
                                    <div className="video-single-width">
                                        <a href="/titles/54115" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                            <img alt="" src="https://wbtvd.com/page/hd-titles/poster_harry_potter_and_the_sorcerers_stone.jpg"/>
                                        </a>
                                        <a href="/titles/54115" className="hd-video-title" rel="noopener noreferrer" target="_blank">Harry Potter and the Sorcerer&apos;s Stone</a>
                                    </div>
                                </li>
                                <li className="atitle">
                                    <div className="video-single-width">
                                        <a href="/titles/1391254" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                            <img alt="" src="https://wbtvd.com/page/hd-titles/poster_vaterfreuden.jpg"/>
                                        </a>
                                        <a href="/titles/1391254" className="hd-video-title" rel="noopener noreferrer" target="_blank">Vaterfreuden</a>
                                    </div>
                                </li>
                            </ul>

                        </div>
                        <div className="clear">

                            <div className="hd-videos-row">
                                <div className="hd-videos-header">

                                    <h2>ANIMATION TITLES IN HD</h2>

                                    <a href="/search/titles?animation=true&hd=true" className="hd-videos-header-link" rel="noopener noreferrer" target="_blank">View All</a>
                                </div>
                                <ul>
                                    <li className="atitle">
                                        <div className="video-single-width">
                                            <a href="/titles/872031" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                                <img alt="" src="https://wbtvd.com/page/hd-titles/poster_the_looney_tunes_show.jpg"/>
                                            </a>
                                            <a href="/titles/872031" className="hd-video-title" rel="noopener noreferrer" target="_blank">The Looney Tunes Show</a>
                                        </div>
                                    </li>
                                    <li className="atitle">
                                        <div className="video-single-width">
                                            <a href="/titles/872037" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                                <img alt="" src="https://wbtvd.com/page/hd-titles/poster_scooby_doo_mystery_incorporated.jpg"/>
                                            </a>
                                            <a href="/titles/872037" className="hd-video-title" rel="noopener noreferrer" target="_blank">Scooby-Doo! Mystery Incorporated</a>
                                        </div>
                                    </li>
                                    <li className="atitle">
                                        <div className="video-single-width">
                                            <a href="/titles/1556911" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                                <img alt="" src="https://wbtvd.com/page/hd-titles/poster_teen_titans_go.jpg"/>
                                            </a>
                                            <a href="/titles/1556911" className="hd-video-title" rel="noopener noreferrer" target="_blank">Teen Titans Go!</a>
                                        </div>
                                    </li>
                                    <li className="atitle">
                                        <div className="video-single-width">
                                            <a href="/titles/984237" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                                <img alt="" src="https://wbtvd.com/page/hd-titles/poster_batman_year_one.jpg"/>
                                            </a>
                                            <a href="/titles/984237" className="hd-video-title" rel="noopener noreferrer" target="_blank">Batman: Year One</a>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="clear">
                            <div className="hd-videos-row">

                                <h2>COMING SOON IN HD</h2>

                                <ul>
                                    <li className="atitle">
                                        <div className="video-single-width">
                                            <a href="/titles/52652" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                                <img alt="" src="https://wbtvd.com/page/hd-titles/poster_roots_the_gift.jpg"/>
                                            </a>
                                            <a href="/titles/52652" className="hd-video-title" rel="noopener noreferrer" target="_blank">Roots: The Gift</a>
                                        </div>
                                    </li>
                                    <li className="atitle">
                                        <div className="video-single-width">
                                            <a href="/titles/51652" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                                <img alt="" src="https://wbtvd.com/page/hd-titles/poster_roots_the_next_generations.jpg"/>
                                            </a>
                                            <a href="/titles/51652" className="hd-video-title" rel="noopener noreferrer" target="_blank">Roots: The Next Generations</a>
                                        </div>
                                    </li>
                                    <li className="atitle">
                                        <div className="video-single-width">
                                            <a href="/titles/53865" className="hd-video-wrapper" rel="noopener noreferrer" target="_blank">
                                                <img alt="" src="https://wbtvd.com/page/hd-titles/poster_roots_one_year_later.jpg"/>
                                            </a>
                                            <a href="/titles/53865" className="hd-video-title" rel="noopener noreferrer" target="_blank">Roots: One Year Later</a>
                                        </div>
                                    </li>
                                    <li className="atitle">

                                    </li>
                                </ul>

                                <div className="clear">
                                </div>
                            </div>

                            <div className="view-all-hd-titles-link-container">
                                <a href="/search/titles?&hd=true&size=40" className="view-all-hd-titles-link" rel="noopener noreferrer" target="_blank">All Titles in HD</a>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>

        );
    }
}
