/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './index.less';

export default class HarryPotterOnAirNavigation extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.harry-potter/on-air-navigation">
                <div className="op_container_harry_potter">
                    <div style={{backgroundColor: '#1b1e22', color: '#FFF'}}>
                        <div className="container padding-all-20">
                            <div className="row margin-bottom-20">
                                <div className="col-md-5"></div>
                                <div className="col-md-2">
                                    <a href="/harry-potter">
                                        <img
                                            src={require('./images/logo-harry-potter.png')}
                                            className="img-responsive"
                                        />
                                    </a>
                                </div>
                                <div className="col-md-5"></div>
                                <div className="col-md-12 text-center" style={{marginTop: '20px'}}>
                                    <a
                                        href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/DESIGN+PACKAGES+AND+OVERVIEW/WW_TV_Toolkit_Design_Package_LoRes.pdf"
                                        className="btn btn-lg btn-gray">
                                        <i className="glyphicon glyphicon-download-alt"></i> Design Package
                                        Overview &amp; Toolkit
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container padding-all-20">
                        <div className="row">
                            <div className="col-md-4 padding-all-20" style={{borderRight: '1px solid #DDD'}}>
                                <img
                                    src={require('./temp-images/ww-animated-on-air-navigation.gif')}
                                    className="img-responsive thumbnail-border"
                                    alt="..."
                                />
                            </div>
                            <div className="col-md-8 padding-all-20">
                                <h1>On Air Navigation</h1>
                                <hr />
                                <h3 className="h3">Lower-Third Snipes</h3>
                                <p>
                                    For Lower-Third Snipes promoting Harry Potter programming during
                                    other network programming, the Harry Potter package and font must
                                    be used with the full film title.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/ON+AIR+NAVIGATION/Lower+3rd+Snipe/ONAIR-Lower3rdSnipe.zip">
                                            Lower-Third Snipe
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">Social/Twitter Snipes</h3>
                                <p>
                                    Social (Twitter) Snipes have a black background for visibility,
                                    accented with gold.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/ON+AIR+NAVIGATION/Social+Twitter+Snipe/ONAIR-SocialTwitterSnipe.zip">
                                            Social/Twitter Snipe
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">Countdown Clock Snipes</h3>
                                <p>
                                    A countdown is used on the credit squeeze, before going into
                                    commercial.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/ON+AIR+NAVIGATION/Countdown+Clock+Snipe/ONAIR-CountdownClockSnipe.zip">
                                            Countdown Clock Snipe
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">Commercial Break Squeezes</h3>
                                <p>Lower 3rd Snipes with Countdown Clock.</p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/ON+AIR+NAVIGATION/ONAIRNAV-COMMBRK_Squeeze/ONAIR-CBM-ALL.zip">
                                            Individual Films (ALL)
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/ON+AIR+NAVIGATION/ONAIRNAV-COMMBRK_Squeeze/ONAIR-CBM-StuntsMarathon.zip">
                                            Stunts & Marathons
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">End Credits Squeezes</h3>
                                <p>
                                    As End Credit Squeezes are an evergreen item, we are using a Leather &
                                    Gold themed color palette that evokes the texture of the package
                                    without feeling too specifically tied to any one film or event.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/ON+AIR+NAVIGATION/ONAIRNAV-ENDCS/ONAIR-ENDCS-ALL.zip">
                                            Individual Films (ALL)
                                        </a>
                                    </li>
                                </ul>
                                <p>
                                    These End Credit Squeezes, however, are tied to the artwork and color
                                    themes for each individual stunt or marathon.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/ON+AIR+NAVIGATION/ONAIRNAV-ENDCS/ONAIR-ENDCS-STUNTSANDMARATHON.zip">
                                            Stunts & Marathons
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </DocumentTitle>

        );
    }
}
