/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {PureComponent} from 'react';

import {TitleConstants} from '../../title-actions';

const PERMISSIONS_FOR_TYPE = {
    [TitleConstants.LINK_TYPES.CLIP_LIBRARY]: 'canViewClipLibrary',
    [TitleConstants.LINK_TYPES.EXTERNAL_LINK]: 'canViewExternalLink',
    [TitleConstants.LINK_TYPES.INTERNAL_LINK]: 'canViewInternalLink',
};

export const PermissionsPropType = PropTypes.shape({
    canViewClipLibrary: PropTypes.bool,
    canViewExternalLink: PropTypes.bool,
    canViewInternalLink: PropTypes.bool,
});

export default class ExternalLinks extends PureComponent {
    static get propTypes() {
        return {
            links: PropTypes.instanceOf(Immutable.List).isRequired,
            permissions: PermissionsPropType.isRequired,
            render: PropTypes.func.isRequired,
        };
    }

    render() {
        const {permissions, links, render} = this.props;

        const buttons = links.filter((link) => {
            const permName = PERMISSIONS_FOR_TYPE[link.get('linkType')];
            let canViewLink = false;
            if (permissions[permName]) {
                canViewLink = true;
            }
            return canViewLink;
        });

        return render({links: buttons});
    }
}
