/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import {MessagesContext} from '../../../messages/messages-context';
import {HomeConstants} from '../../home-actions';

import './playlist-billboard.less';

export default class PlaylistBillboard extends React.Component {
    static get propTypes() {
        return {
            publishingList: PropTypes.object.isRequired,
        };
    }

    shouldComponentUpdate(nextProps) {
        let imagePath = ['items', 0, 'imageUrl'];
        if (this.props.publishingList.getIn(imagePath) !== nextProps.publishingList.getIn(imagePath)) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    render() {

        let item = this.props.publishingList.getIn(['items', 0]);

        if (!item || !item.get('imageUrl')) {
            return null;
        }


        let targetUrl;
        switch (item.get('targetType')) {
        case HomeConstants.TARGET_TYPE.INTERNAL_PAGE.id:
            targetUrl = item.get('targetUrl');
            break;
        case HomeConstants.TARGET_TYPE.TITLE.id:
            targetUrl = `titles/${item.get('assetId')}`;
            break;
        }

        return (
            <div className="container homepage-alt-header">
                <Link to={targetUrl}><img alt="" src={item.get('imageUrl')} /></Link>
            </div>
        );
    }
}
