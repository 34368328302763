/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GetVideoDisplayName} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {PlayerActions} from './player-actions';
import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import AssetRightsIcon from '../assets/asset-rights';
import Config from '../config/config';
import {MessagesContext} from '../messages/messages-context';
import {Alert} from '../notification/alert';
import {OrderActions} from '../orders/order-actions';
import OrderStore from '../orders/order-store';
import PlayerStore from '../player/player-store';
import Preloader from '../preloader';
import SessionStore from '../session/session-store';
import {AssetTitleActions} from '../titles/asset-title/asset-title-actions';
import {ClipActions} from '../titles/clip/clip-actions';
import ClipStore from '../titles/clip/clip-store';
import {HandleWithTriggerKeys} from '../utils/utils';

import Player from './index';


import './video-overlay.less';

class VideoOverlay extends Component {
    static get propTypes() {
        return {
            clipping: PropTypes.bool,
            noAddToCart: PropTypes.bool,
            noShare: PropTypes.bool,
            onClose: PropTypes.func,
            onlyViewingClip: PropTypes.bool,
            playerOptions: PropTypes.object,
            searchTerm: PropTypes.string,
            selectedVideo: PropTypes.instanceOf(Immutable.Map),
            titleId: PropTypes.number.isRequired,
            video: PropTypes.instanceOf(Immutable.Map),
            visible: PropTypes.bool,
            watchlist: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            clipping: false,
            noAddToCart: false,
            noShare: false,
            onClose: undefined,
            onlyViewingClip: false,
            playerOptions: undefined,
            searchTerm: '',
            selectedVideo: undefined,
            video: undefined,
            visible: false,
        };
    }

    static calculateState() {
        return {
            // FIXME: use the same getPermissions as in other elements.
            canAddToCart: SessionStore.canUser(SessionStore.PERMISSIONS.CART.VIDEOS.ADD),
            canInstantOrderVideos: SessionStore.canUser(SessionStore.PERMISSIONS.CART.VIDEOS.INSTANT_ORDER),
            canEditBrainiac: SessionStore.canUser(SessionStore.PERMISSIONS.EDIT_IN_BRAINIAC),
            canCreateClip: SessionStore.canUser(SessionStore.PERMISSIONS.TITLE.CLIPS.CREATE),
            currentClip: ClipStore.getState().get('currentClip'),
            isClippingModeOn: ClipStore.getState().get('isClippingModeOn'),
            isCreateClipModalVisible: ClipStore.getState().get('isModalVisible'),
            partner: SessionStore.getState().get('partner'),
            selectedVideo: PlayerStore.getState().get('selectedVideo'),
            video: PlayerStore.getState().get('video'),
        };
    }

    static getStores() {
        return [SessionStore, ClipStore, PlayerStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            currentTime: 0,
            duration: 0,
            visibleTooltip: false,
        }, this.constructor.calculateState());

        this.changeCurrentTime = this.changeCurrentTime.bind(this);
        this.changeDuration = this.changeDuration.bind(this);
        this.createUIPlaylist = this.createUIPlaylist.bind(this);
        this.createUIPlaylistItem = this.createUIPlaylistItem.bind(this);
        this.getVideoFromProps = this.getVideoFromProps.bind(this);
        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.handleAddToCartMulti = this.handleAddToCartMulti.bind(this);
        this.handleAddToCartMenu = this.handleAddToCartMenu.bind(this);
        this.handleClickVideoOverlay = this.handleClickVideoOverlay.bind(this);
        this.handleCompleteVideo = this.handleCompleteVideo.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleInstantOrder = this.handleInstantOrder.bind(this);
        this.handleInstantOrderAll = this.handleInstantOrderAll.bind(this);
        this.handleShare = this.handleShare.bind(this);
        this.handleClipping = this.handleClipping.bind(this);
        this.handleUIPlaylistClick = this.handleUIPlaylistClick.bind(this);
        this.logKey = this.logKey.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        const wrapper = document.getElementsByClassName('page-content')[0];
        wrapper.addEventListener('keyup', this.logKey);

        if (this.props.visible) {
            document.body.classList.add('overflow-y-hidden');
        }

        return;
    }

    componentDidUpdate() {
        if (this.props.visible) {
            document.body.classList.add('overflow-y-hidden');
        } else {
            document.body.classList.remove('overflow-y-hidden');
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('overflow-y-hidden');
    }

    static contextType = MessagesContext;

    changeDuration(duration) {
        this.setState(() => ({duration}));
    }

    changeCurrentTime(currentTime) {
        this.setState(() => ({currentTime}));
    }

    /**
     * Create the playlist ui widget when there are stacked videos
     */
    createUIPlaylist(currentVideo) {
        // if player is called from video list view children do not exists and will never do.
        // no need to create empty array just check here and everything will still work :)
        if (this.props.video.get('children') && this.props.video.get('children').size) {
            let playlistItems = this.props.video.get(
                'children'
            ).unshift(
                this.props.video
            ).map((item, index) => {
                return this.createUIPlaylistItem(item, index, currentVideo);
            });


            return (
                <div className="col-sm-12 col-md-3 col-lg-2 multi-video-playlist">
                    <ul className="video-overlay-playlist">
                        {playlistItems}
                    </ul>
                </div>
            );
        }

        return;
    }

    /**
     * Create a playlist ui item element
     */
    createUIPlaylistItem(video, index, currentVideo) {
        let checked = '';

        if (video.get('assetId') === currentVideo.get('assetId')) {
            checked = 'checked';
        }

        return (
            <li className="video-playlist" key={`video-playlist-item-${index}`}>
                <span role="button" tabIndex="0" onClick={this.handleUIPlaylistClick.bind(this, index)} onKeyUp={HandleWithTriggerKeys(this.handleUIPlaylistClick.bind(this, index))}>
                    <input type="radio" checked={checked} name="playlist" readOnly="true" />
                    <label>
                        <span className="glyphicon glyphicon-play-circle" /> {video.get('name')}
                    </label>
                </span>
            </li>
        );
    }

    getVideoFromProps() {
        return this.props.selectedVideo || this.props.video;
    }

    handleAddToCart(event) {
        event.preventDefault();
        OrderActions.add(AssetTypeConstants.ASSET_TYPE.VIDEO, this.props.titleId, [this.getVideoFromProps()]);
        this.setState({visibleTooltip: false});
    }

    handleAddToCartMulti(event) {
        event.preventDefault();
        let videos = [this.props.video];
        videos = videos.concat(this.props.video.get('children').toArray()).filter(v => v.get('deliveryType') !== AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE);
        OrderActions.add(AssetTypeConstants.ASSET_TYPE.VIDEO, this.props.titleId, videos);
        this.setState({visibleTooltip: false});
    }

    handleAddToCartMenu(event) {
        event.preventDefault();
        this.setState(prevState => ({
            visibleTooltip: !prevState.visibleTooltip
        }));
    }

    handleCompleteVideo() {
        let children = this.props.video.get('children', Immutable.List());
        if (!children.size) {
            return;
        }
        let video = this.getVideoFromProps();
        let currentChildrenIndex = children.findIndex(c => c.get('mediaKey') === video.get('mediaKey'));
        if ((currentChildrenIndex + 1) === children.size) {
            // User is at the end of the playlist.
            return;
        }
        // Immutable.List.findIndex returned -1 if the item is not found.
        // This is great, because -1 + 1 = 0, so it will play the first children.
        PlayerActions.playVideo(children.get(currentChildrenIndex + 1), this.props.titleId);
        return;
    }

    handleDownload(event) {
        event.preventDefault();
        OrderActions.downloadVideo(this.getVideoFromProps().get('assetId'));
        this.setState({visibleTooltip: false});
    }

    handleInstantOrder(event) {
        event.preventDefault();

        OrderActions.instantOrderVideo(this.props.titleId, Immutable.List([this.getVideoFromProps()]));
        this.setState({visibleTooltip: false});
    }

    handleInstantOrderAll(event) {
        event.preventDefault();

        OrderActions.instantOrderVideo(this.props.titleId, Immutable.List([this.getVideoFromProps()]), true);
        this.setState({visibleTooltip: false});
    }

    handleShare() {
        AssetTitleActions.showShare(this.getVideoFromProps());
    }

    handleClipping() {
        const video = this.getVideoFromProps();
        const frameRate = video.get('frameRate');
        const videoId = video.get('assetId');
        const titleId = this.props.titleId || video.getIn(['titles', 0, 'titleId']);
        const {currentTime, duration} = this.state;
        ClipActions.createNewClip(Immutable.fromJS({currentTime, duration, frameRate, titleId, videoId}));
    }

    /**
     * Playlist UI widget click handler, replace currently playing video
     * with clicked video.
     */
    handleUIPlaylistClick(index) {
        let selectedVideo = this.props.video;
        if (index > 0) {
            selectedVideo = this.props.video.getIn(['children', index - 1]);
        }

        PlayerActions.playVideo(selectedVideo, this.props.titleId);

        return;
    }

    handleClickVideoOverlay(ev) {
        if (ev && (ev.type === 'keyup' && (ev.keyCode !== 27))) {
            return;
        }

        if (this.state.visibleTooltip) {
            this.setState(()=> ({
                visibleTooltip: false
            }));
        }
    }

    handleEditClick(url) {
        window.open(url);
    }

    logKey(e) {
        if (e.code === 'Escape') {
            this.onClose();
        }
    }

    onClose() {
        if (this.props.onClose) {
            return this.props.onClose();
        }
        PlayerActions.hidePlayer();
    }

    toggleCreateModal() {
        ClipActions.toggleCreateModal();
    }

    render() {
        let video = this.getVideoFromProps();

        let currentVideo = this.state.video;

        if (this.state.selectedVideo.size) {
            currentVideo = this.state.selectedVideo;
        }

        if (!this.props.visible) {
            return null;
        }

        if (!this.props.video) {
            return (
                <div className="video-overlay video-overlay-list video-overlay-single" style={{display: 'block'}}>
                    <div className="video-overlay-bg">
                        <Preloader background={false} fixed />
                    </div>
                </div>
            );
        }
        let uiPlaylist = this.createUIPlaylist(currentVideo);
        let addToCart;
        let menu;

        let isServiceable = video.get('deliveryType') !== AssetTypeConstants.DELIVERY_TYPES.NON_SERVICEABLE;
        const isAbleAddVideoToCart = !this.props.noAddToCart && this.state.canAddToCart && isServiceable;

        const hasChildren = this.props.video.get('childStackAssetCount') > 0;
        const canDownload = OrderStore.canDownloadVideo(video);
        if (canDownload) {
            addToCart = (
                <button
                    className="btn white video-overlay-cart"
                    onClick={this.handleDownload}
                    aria-label={this.context.intl.messages['cart.download.video.mp4']}
                    title={this.context.intl.messages['cart.download.video.mp4']}
                >
                    <span className="glyphicon glyphicon-download-alt" />
                </button>
            );
        }

        if (isAbleAddVideoToCart) {
            addToCart = (
                <button
                    className="btn white video-overlay-cart"
                    onClick={this.handleAddToCart}
                    aria-label={this.context.intl.messages['cart.add.video.single']}
                >
                    <span className="glyphicon glyphicon-shopping-cart" />
                </button>
            );

            const isInstantOrder = OrderStore.isInstantOrderVideo(video, this.state.canInstantOrderVideos);
            if (hasChildren || isInstantOrder || canDownload) {
                addToCart = (
                    <button
                        className="btn white video-overlay-cart"
                        onClick={this.handleAddToCartMenu}
                        aria-label={this.context.intl.messages['cart.add.video.single']}
                    >
                        <span aria-label={this.context.intl.messages['asset.image.gallery.add-to-cart']} className={ClassNames('fas', {'fa-shopping-cart': !isInstantOrder, 'fa-cart-arrow-down': isInstantOrder})}/>
                    </button>
                );

                // FIXME: these three if must be CSS.
                let display = 'none';
                if (this.state.visibleTooltip) {
                    display = 'block';
                }
                let left = '70px';
                if (isInstantOrder) {
                    left = '56px';
                }
                let style = {
                    top: '44px',
                    left,
                    display
                };

                menu = (
                    <div className="tooltip fade bottom in" role="tooltip" style={style}>
                        <div className="tooltip-arrow" style={{left: 61 + '%'}} />
                        <div className="tooltip-inner">
                            <div className="tooltip-menu">
                                {isInstantOrder && (
                                    <button
                                        className="tooltip-link tooltip-add-single"
                                        onClick={this.handleInstantOrder}
                                        onKeyUp={HandleWithTriggerKeys(this.handleInstantOrder)}
                                    ><i className="fas fa-cart-shopping-fast"/>&nbsp;{this.context.intl.messages['cart.instant.order.video.single']}</button>
                                )}

                                {hasChildren && isInstantOrder && (
                                    <button
                                        className="tooltip-link tooltip-add-multiple"
                                        onClick={this.handleInstantOrderAll}
                                        onKeyUp={HandleWithTriggerKeys(this.handleInstantOrderAll)}
                                    ><i className="fas fa-cart-shopping-fast"/>&nbsp;{this.context.intl.messages['cart.instant.order.video.multi']}</button>
                                )}

                                <button
                                    className="tooltip-link tooltip-add-single"
                                    onClick={this.handleAddToCart}
                                    onKeyUp={HandleWithTriggerKeys(this.handleAddToCart)}
                                ><i className="fas fa-cart-circle-plus"/>&nbsp;{this.context.intl.messages['cart.add.video.single']}</button>

                                {hasChildren && (
                                    <button
                                        className="tooltip-link tooltip-add-multiple"
                                        onClick={this.handleAddToCartMulti}
                                        onKeyUp={HandleWithTriggerKeys(this.handleAddToCartMulti)}
                                    ><i className="fas fa-cart-circle-plus"/>&nbsp;{this.context.intl.messages['cart.add.video.multi']}</button>
                                )}

                                {canDownload && (
                                    <button
                                        className="tooltip-link tooltip-add-single"
                                        onClick={this.handleDownload}
                                        onKeyUp={HandleWithTriggerKeys(this.handleDownload)}
                                    ><i className="glyphicon glyphicon-download-alt"/>&nbsp;{this.context.intl.messages['cart.download.video.mp4']}</button>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }
        }

        const isEditingExistingClip = !!this.state.currentClip.get('id');
        let clip = null;

        if (this.props.clipping || isEditingExistingClip) {
            clip = this.state.currentClip;
        }

        let clippingButton;
        const isClippableVideo = video.get('isClippable');
        if (isClippableVideo && this.state.canCreateClip && this.props.clipping && !this.props.onlyViewingClip && isAbleAddVideoToCart && clip) {
            const noFrameRate = !clip.get('frameRate');
            let clippingButtonTitle = '';

            if (noFrameRate) {
                clippingButtonTitle = this.context.intl.messages['title.clipping.no-frame-rate'];
            }

            clippingButton = (
                <button
                    aria-label={this.context.intl.messages['cart.player.moments-tool']}
                    className={ClassNames('btn', 'white', 'video-overlay-clipping', {'video-overlay-clipping-on': this.state.isClippingModeOn})}
                    disabled={noFrameRate}
                    onClick={this.handleClipping}
                    title={clippingButtonTitle}
                >
                    <span className="fa fa-cut"/>
                </button>
            );
        }

        let shareButton;
        if (!this.props.noShare && this.state.partner.get('id') !== Config.Partners.PRESS_SITE.id) {
            shareButton = (
                <button
                    aria-label={this.context.intl.messages['cart.player.share']}
                    className="btn white video-overlay-share"
                    onClick={this.handleShare}
                >
                    <span className="glyphicon glyphicon-transfer" />
                </button>
            );
        }

        let editBrainiac;
        if (this.state.canEditBrainiac) {
            editBrainiac = (
                <button
                    aria-label={this.context.intl.messages['cart.player.edit-brainiac']}
                    className="btn white"
                    onClick={() => this.handleEditClick(`${Config.BrainiacBaseUrl}/assets/video/${video.get('assetId')}`)}
                >
                    <span className="glyphicon glyphicon-edit"></span>
                </button>
            );
        }

        const assetDisplayName = GetVideoDisplayName(currentVideo);

        return (
            <div
                className={ClassNames(
                    'video-overlay video-overlay-list',
                    {'video-overlay-single': !this.props.video.get('children', Immutable.List()).size}
                )}
                style={{display: 'block'}}
                onClick={this.handleClickVideoOverlay}
                onKeyUp={HandleWithTriggerKeys(this.handleClickVideoOverlay)}
                role="button"
                tabIndex="0"
            >
                <div className="video-overlay-controls video-toolbar">
                    {clippingButton}
                    {editBrainiac}
                    {shareButton}
                    <AssetRightsIcon video={this.getVideoFromProps()} />
                    {addToCart}
                    {menu}
                    <button
                        aria-label={this.context.intl.messages['cart.player.close-button']}
                        className="btn white video-overlay-close"
                        onClick={this.onClose}
                    >
                        <span className="glyphicon glyphicon-remove" />
                    </button>
                </div>
                <div className="media-overlay-alert no-background">
                    <Alert />
                </div>
                <div className="video-overlay-bg">
                    <div className="video-overlay-wrapper">
                        <div className="video-overlay-container row">
                            <div className="col-sm-12 col-md-9 col-lg-10 video-player-wrapper">
                                <Player
                                    canPreviewVideoPlayer={SessionStore.canUser(SessionStore.PERMISSIONS.PLAYER.VIDEO_PLAYER_PREVIEW)}
                                    clip={clip}
                                    onlyViewingClip={this.props.onlyViewingClip}
                                    duration={this.state.duration}
                                    isClippingModeOn={this.state.isClippingModeOn}
                                    isCreateClipModalVisible={this.state.isCreateClipModalVisible}
                                    onComplete={this.handleCompleteVideo}
                                    onDurationChange={this.changeDuration}
                                    onTimeUpdate={this.changeCurrentTime}
                                    playerOptions={this.props.playerOptions}
                                    searchTerm={this.props.searchTerm}
                                    titleId={this.props.titleId}
                                    toggleCreateModal={this.toggleCreateModal}
                                    video={video}
                                    visible={this.props.visible}
                                    watchlist={this.props.watchlist}
                                />
                                <div className="homepage-video-description">
                                    <h1>
                                        <span id="homepage-video-name">{assetDisplayName}</span>
                                        &nbsp;
                                        <small id="homepage-video-runtime">{video.get('runtime')}</small>
                                    </h1>
                                </div>
                            </div>
                            {uiPlaylist}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Container.create(VideoOverlay);

export {VideoOverlay};
