/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {Button, DropdownButton, MenuItem, Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {AssetTitleActions, AssetTitleConstants} from './asset-title-actions';
import GalleryThumbnail from './images/gallery-thumbnail';
import VideoThumbnail from './videos/video-thumbnail';
import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import {MessagesContext} from '../../messages/messages-context';
import {OrderActions} from '../../orders/order-actions';
import {OrderConstants} from '../../orders/order-constants';
import {BreakIntoGroups} from '../../utils/utils';
import {TitleActions} from '../title-actions';

export default class AssetSection extends React.Component {
    static get propTypes() {
        return {
            assetFacetCounts: PropTypes.instanceOf(Immutable.Map).isRequired,
            assetType: PropTypes.string.isRequired,
            canAddAllToCart: PropTypes.bool,
            canAddToCart: PropTypes.bool,
            canDownloadAllAssets: PropTypes.bool,
            canDownloadFull: PropTypes.bool,
            canDownloadLoRes: PropTypes.bool,
            canDownloadSource: PropTypes.bool,
            canInstantOrderVideos: PropTypes.bool,
            contentTypeFilters: PropTypes.instanceOf(Immutable.Set).isRequired,
            groupKey: PropTypes.string.isRequired,
            images: PropTypes.instanceOf(Immutable.Map).isRequired,
            location: PropTypes.object.isRequired,
            onSortChange: PropTypes.func.isRequired,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            showAirDate: PropTypes.bool,
            showCaptions: PropTypes.bool.isRequired,
            superType: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            titleId: PropTypes.number.isRequired,
            videos: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            canAddAllToCart: false,
            canAddToCart: false,
            canDownloadAllAssets: false,
            canDownloadFull: false,
            canDownloadLoRes: false,
            canDownloadSource: false,
            canInstantOrderVideos: false,
            showAirDate: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            assetNumber: 0,
            showSort: false,
            showAddAllToCartWarning: false
        };

        this.confirmAddAllToCart = this.confirmAddAllToCart.bind(this);
        this.getViewAllLink = this.getViewAllLink.bind(this);
        this.handleAddAllToCart = this.handleAddAllToCart.bind(this);
        this.handleDownloadAllAssets = this.handleDownloadAllAssets.bind(this);
        this.handleHideAddAllToCartWarning = this.handleHideAddAllToCartWarning.bind(this);
        this.handleSelectSort = this.handleSelectSort.bind(this);
        this.handleShowAddAllToCartWarning = this.handleShowAddAllToCartWarning.bind(this);
        this.handleToggleSort = this.handleToggleSort.bind(this);
        this.handleViewImageClick = this.handleViewImageClick.bind(this);

        return;
    }

    static contextType = MessagesContext;

    confirmAddAllToCart() {
        const query = QueryString.parse(this.props.location.search);
        let isSuperTypeSelected = false;
        if (this.props.assetFacetCounts.getIn([this.props.titleId, this.props.superType.key]) !== undefined) {
            let assetFacetCounts = this.props.assetFacetCounts.getIn([this.props.titleId, this.props.superType.key]);
            isSuperTypeSelected = this.props.superType.ALL.filter(
                t => assetFacetCounts.get(t, 0) > 0
            ).every(
                t => this.props.contentTypeFilters.includes(t)
            );
        }
        if (!query.vct || isSuperTypeSelected) {
            OrderActions.addAllToCart(this.props.titleId, this.props.images.getIn([0, 'imageSuperType']));
        } else {
            OrderActions.addAllToCartByContentType(this.props.titleId, Array.from(this.props.contentTypeFilters));
        }

        this.handleHideAddAllToCartWarning();
    }

    getDownloadRenditionType() {
        if (this.props.canDownloadSource) {
            return OrderConstants.DOWNLOAD_RENDITION_TYPE.Source.type;
        } if (this.props.canDownloadFull) {
            return OrderConstants.DOWNLOAD_RENDITION_TYPE.HiRes.type;
        } if (this.props.canDownloadLoRes) {
            return OrderConstants.DOWNLOAD_RENDITION_TYPE.LoRes.type;
        }
        return undefined; // leave unspecified - it's an optional parameter for the API after all
    }

    getViewAllLink() {
        let CONTENT_TYPE, query;
        switch (this.props.assetType) {
        case (AssetTitleConstants.ASSET_CATEGORY.VIDEO):
            CONTENT_TYPE = Object.values(
                AssetTitleConstants.CONTENT_TYPES.VIDEO
            ).filter(
                contentType => contentType.key === this.props.superType
            )[0];

            let sortFieldName = 'relevance';
            let sortDirection = 'asc';
            if (this.props.showAirDate && this.props.superType === AssetTitleConstants.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.key) {
                sortFieldName = 'titlereleasedate';
                sortDirection = 'desc';
            }

            query = {
                offset: 0,
                vct: CONTENT_TYPE.ALL,
                'sort-direction': sortDirection,
                'sort-field-name': sortFieldName
            };

            return `/titles/${this.props.titleId}/videos?${QueryString.stringify(query)}`;

        case (AssetTitleConstants.ASSET_CATEGORY.IMAGE):
            CONTENT_TYPE = Object.values(
                AssetTitleConstants.CONTENT_TYPES.IMAGE
            ).filter(
                contentType => contentType.key === this.props.superType.key
            )[0];

            query = {
                offset: 0,
                vct: CONTENT_TYPE.ALL,
                'keep-scroll': true
            };

            return `/titles/${this.props.titleId}/images?${QueryString.stringify(query)}`;
        }
    }

    handleAddAllToCart() {
        let assetCount = 0;
        this.props.contentTypeFilters.forEach(ct => {
            const ctCount = this.props.assetFacetCounts.getIn([this.props.titleId, this.props.superType.key]).get(ct) || 0;
            assetCount += ctCount;
        });
        if (assetCount > 100) {
            this.setState(() => ({
                assetNumber: assetCount,
                showAddAllToCartWarning: true
            }));
        } else {
            this.confirmAddAllToCart();
        }
    }

    handleDownloadAllAssets() {
        TitleActions.downloadAllAssets(
            this.props.titleId,
            AssetTypeConstants.ASSET_TYPE[this.props.assetType],
            this.props.superType.ALL,
            this.getDownloadRenditionType()
        );
    }

    handleHideAddAllToCartWarning() {
        this.setState(() => ({
            showAddAllToCartWarning: false
        }));
    }

    handleSelectSort(eventKey) {
        let sortFilter = eventKey.split(':');
        this.props.onSortChange(sortFilter[0], sortFilter[1]);
        return;
    }

    handleShowAddAllToCartWarning() {
        this.setState(() => ({
            showAddAllToCartWarning: true
        }));
    }

    handleToggleSort() {
        this.setState(prevState => ({
            showSort: !prevState.showSort
        }));

        return;
    }

    handleViewImageClick(image) {
        AssetTitleActions.openGallery(image);
    }

    render() {
        const query = QueryString.parse(this.props.location.search);
        let assets, assetRows, getAssetThumbnail, renderAssets, rowTitle, sortDropdown, viewAll;

        const sectionTitleByType = {
            [AssetTitleConstants.ASSET_CATEGORY.VIDEO]: this.context.intl.messages[`title.videos.content-types.${this.props.superType}`],
            [AssetTitleConstants.ASSET_CATEGORY.IMAGE]: this.props.title
        };

        const sectionTitle = sectionTitleByType[this.props.assetType];

        if (!query.vct) {
            viewAll = (
                <Link
                    aria-label={`${sectionTitle} ${this.context.intl.messages['common.view-all']}`}
                    className="btn btn-primary"
                    to={this.getViewAllLink()}
                >
                    {this.context.intl.messages['common.view-all']}&nbsp;<b>&gt;</b>
                </Link>
            );
        } else {
            let airDateAsc, airDateDesc;
            if (this.props.showAirDate && this.props.assetType === AssetTitleConstants.ASSET_CATEGORY.VIDEO) {
                airDateAsc = <MenuItem eventKey="titlereleasedate:asc" active={(query['sort-field-name'] === 'titlereleasedate' && query['sort-direction'] === 'asc')}>
                    {this.context.intl.messages['title.video-list.filter.air-date-asc']}
                </MenuItem>;
                airDateDesc = <MenuItem eventKey="titlereleasedate:desc" active={(query['sort-field-name'] === 'titlereleasedate' && query['sort-direction'] === 'desc')}>
                    {this.context.intl.messages['title.video-list.filter.air-date-desc']}
                </MenuItem>;
            }
            sortDropdown = (
                <DropdownButton
                    bsStyle="default"
                    className="videos-sort-button"
                    onToggle={this.handleToggleSort}
                    open={this.state.showSort}
                    onSelect={this.handleSelectSort}
                    title="Sort"
                >
                    <MenuItem eventKey="relevance:asc" active={(query['sort-field-name'] === 'relevance')} >
                        {this.context.intl.messages['title.asset-list.filter.relevance']}
                    </MenuItem>
                    {airDateAsc}
                    {airDateDesc}
                    <MenuItem eventKey="createdDate:asc" active={(query['sort-field-name'] === 'createdDate') && (query['sort-direction'] === 'asc')}>
                        {this.context.intl.messages['title.asset-list.filter.date-added-asc']}
                    </MenuItem>
                    <MenuItem eventKey="createdDate:desc" active={(query['sort-field-name'] === 'createdDate') && (query['sort-direction'] === 'desc')}>
                        {this.context.intl.messages['title.asset-list.filter.date-added-desc']}
                    </MenuItem>
                </DropdownButton>
            );
        }

        switch (this.props.assetType) {
        case (AssetTitleConstants.ASSET_CATEGORY.VIDEO):
            assets = this.props.videos;
            rowTitle = 'videos';

            let showAirDateInSection = false;
            if (this.props.showAirDate && this.props.superType === AssetTitleConstants.CONTENT_TYPES.VIDEO.FULL_EPISODE_OR_FEATURE.key) {
                showAirDateInSection = true;
            }

            getAssetThumbnail = video => (
                <VideoThumbnail
                    canAddToCart={this.props.canAddToCart}
                    canInstantOrderVideos={this.props.canInstantOrderVideos}
                    serieNavigation={this.props.serieNavigation}
                    showAirDate={showAirDateInSection}
                    title={this.props.title}
                    titleId={this.props.titleId}
                    video={video}
                />
            );

            assetRows = BreakIntoGroups(assets.get('results'), 4).map((assetRow, i) => {
                return (
                    <div className="row" key={i}>
                        <div className="col-md-6">
                            <div className="row">
                                {getAssetThumbnail(assetRow.get(0))}
                                {getAssetThumbnail(assetRow.get(1))}
                            </div>
                        </div>
                        <div className="clearfix visible-xs"></div>
                        <div className="col-md-6">
                            <div className="row">
                                {getAssetThumbnail(assetRow.get(2))}
                                {getAssetThumbnail(assetRow.get(3))}
                            </div>
                        </div>
                    </div>
                );
            });

            renderAssets = (
                <div>
                    <h4 className="h3">{this.context.intl.messages[`title.${rowTitle}.content-types.${this.props.superType}`]}&nbsp;<small>{viewAll}{sortDropdown}</small></h4>
                    {assetRows.toJS()}
                </div>
            );
            break;

        case (AssetTitleConstants.ASSET_CATEGORY.IMAGE):
            let addAllToCart;
            let downloadAllAssets;
            assets = this.props.images;
            if (!assets) {return null;}

            if (this.props.groupKey) {
                assets = AssetTitleActions.filterByGroupKey(assets, this.props.groupKey);
            }

            if (!assets.size) {return null;}

            if (this.props.canAddAllToCart) {
                addAllToCart = (
                    <Button className="btn-secondary pull-right" onClick={this.handleAddAllToCart}>
                        <span><span className="glyphicon glyphicon-shopping-cart" /> {this.context.intl.messages['common.add-to-cart-all']}</span>
                    </Button>
                );
            }

            if (this.props.canDownloadAllAssets) {
                downloadAllAssets = (
                    <Button className="btn-secondary pull-right" onClick={this.handleDownloadAllAssets}>
                        <span><span className="glyphicon glyphicon-download-alt"/> {this.context.intl.messages['common.download-all']}</span>
                    </Button>
                );
            }

            assetRows = BreakIntoGroups(assets, 6).map((assetRow, i) => {
                return (
                    <div className="row" key={i}>
                        {BreakIntoGroups(assetRow, 3).map((imageCol, j) => {
                            return (
                                <div className="col-md-6" key={j}>
                                    <div className="row">
                                        <GalleryThumbnail
                                            assetType={this.props.assetType}
                                            canAddToCart={this.props.canAddToCart}
                                            image={imageCol.get(0)}
                                            onViewImageClick={this.handleViewImageClick.bind(this, imageCol.get(0))}
                                            showCaptions={this.props.showCaptions}
                                            titleId={this.props.titleId}/>
                                        <GalleryThumbnail
                                            assetType={this.props.assetType}
                                            canAddToCart={this.props.canAddToCart}
                                            image={imageCol.get(1)}
                                            onViewImageClick={this.handleViewImageClick.bind(this, imageCol.get(1))}
                                            showCaptions={this.props.showCaptions}
                                            titleId={this.props.titleId}/>
                                        <div className="clearfix visible-xs"></div>
                                        <GalleryThumbnail
                                            assetType={this.props.assetType}
                                            canAddToCart={this.props.canAddToCart}
                                            image={imageCol.get(2)}
                                            onViewImageClick={this.handleViewImageClick.bind(this, imageCol.get(2))}
                                            showCaptions={this.props.showCaptions}
                                            titleId={this.props.titleId}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            });

            renderAssets = (
                <div>
                    <h4 className="h3">{this.props.title}&nbsp;<small>{viewAll}{sortDropdown}{addAllToCart}{downloadAllAssets}</small></h4>
                    {assetRows}
                </div>
            );
            break;
        }

        const assetNumber = this.state.assetNumber;
        const addAllToCartWarningModal = <Modal show={this.state.showAddAllToCartWarning} onHide={this.handleHideAddAllToCartWarning}>
            <Modal.Header className="bg-gray" closeButton>
                <Modal.Title className="modal-title text-center">
                    {this.context.intl.messages['common.are-you-sure']}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{this.context.intl.messages['cart.add.all-images.warning'].replace('{}', assetNumber)}</p>
            </Modal.Body>
            <Modal.Footer className=" padding-all-20">
                <Button bsStyle="primary" className="pull-right" onClick={this.confirmAddAllToCart}>
                    {this.context.intl.messages['common.ok']}
                </Button>
                <Button bsStyle="default" className="btn-secondary margin-right-5 pull-left" onClick={this.handleHideAddAllToCartWarning}>
                    {this.context.intl.messages['common.close']}</Button>
            </Modal.Footer>
        </Modal>;

        return (
            <div>
                {addAllToCartWarningModal}
                {renderAssets}
            </div>
        );
    }
}
