/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import React from 'react';
import SwaggerUi, {presets} from 'swagger-ui';
import url from 'url';

import {SwaggerActions} from './swagger-actions';
import SwaggerStore from './swagger-store';
import Config from '../config/config';
import {ACCEPT_HEADER} from '../request';

import 'swagger-ui/dist/swagger-ui.css';

class Swagger extends React.Component {
    static calculateState() {
        return {
            spec: SwaggerStore.getState().get('spec')
        };
    }

    static getStores() {
        return [SwaggerStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidMount() {
        SwaggerActions.get();
        this.initSwagger(this.state);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.spec !== nextState.spec;
    }

    componentDidUpdate() {
        this.initSwagger(this.state);
    }

    initSwagger(state) {
        SwaggerUi({
            deepLinking: true,
            docExpansion: 'none',
            dom_id: '#swagger-container',
            filter: true,
            operationsSorter: 'alpha',
            presets: [presets.apis],
            requestInterceptor: r => {
                if (Config.Environment === Config.Environments.PRODUCTION) {
                    // This won't break Swagger, it will just load a nice gif :)
                    r.url = 'https://media.giphy.com/media/ToMjGpOjkiEjzJ1ZaJG/giphy.gif';
                    return r;
                }

                const parsedURL = url.parse(r.url);
                r.url = url.resolve(
                    Config.ApiBaseUrl,
                    // Remove the initial slash and the extra " v1|2|3" of the
                    // URL version name required by Swagger ¬_¬
                    parsedURL.path.substring(1).replace(/%20v\d$/, '')
                );
                r.headers.accept = ACCEPT_HEADER;
                // Also, we need to get the vN, if any, to set the right Accept
                // header version.
                const vN = parsedURL.path.match(/%20(v\d)$/);
                if (vN) {
                    r.headers.accept = `application/vnd.wbtv-${vN[1]}+json`;
                }
                r.headers.Authorization = localStorage.getItem('__token');
                return r;
            },
            spec: state.spec.toJS(),
            tagsSorter : 'alpha'
        });

        return;
    }

    render() {
        return (
            <div id="swagger-container"/>
        );
    }
}

export default Container.create(Swagger);

export {Swagger};
