/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './index.less';

export default class HarryPotterPromoPackages extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.harry-potter/promo-packages">
                <div className="op_container_harry_potter">
                    <div style={{backgroundColor: '#1b1e22', color: '#FFF'}}>
                        <div className="container padding-all-20">
                            <div className="row margin-bottom-20">
                                <div className="col-md-5"></div>
                                <div className="col-md-2">
                                    <a href="/harry-potter">
                                        <img
                                            src={require('./images/logo-harry-potter.png')}
                                            className="img-responsive"
                                        />
                                    </a>
                                </div>
                                <div className="col-md-5"></div>
                                <div className="col-md-12 text-center" style={{marginTop: '20px'}}>
                                    <a
                                        href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/DESIGN+PACKAGES+AND+OVERVIEW/WW_TV_Toolkit_Design_Package_LoRes.pdf"
                                        className="btn btn-lg btn-gray">
                                        <i className="glyphicon glyphicon-download-alt"></i> Design Package
                                        Overview &amp; Toolkit
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container padding-all-20">
                        <div className="row">
                            <div className="col-md-4 padding-all-20" style={{borderRight: '1px solid #DDD'}}>
                                <img
                                    src={require('./temp-images/ww-animated-promo-packages.gif')}
                                    className="img-responsive thumbnail-border"
                                    alt="..."
                                />
                            </div>
                            <div className="col-md-8 padding-all-20">
                                <h1>Promo Packages</h1>
                                <hr />
                                <h3 className="h3">Individual Films</h3>
                                <p>
                                    Each of the eight Harry Potter films have a bespoke package,
                                    containing backgrounds, icons and custom end pages. Each film&apos;s
                                    package also includes a unique iconographic holding device for key
                                    imagery related to the themes and storyline of that particular film.
                                    The end pages feature the official film logos and fonts, using the
                                    original letter form for each logo with an intentionally flat
                                    treatment in order for each logo to feel cohesive within this special
                                    Design Package.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/INDIVIDUAL+FILMS/PROMOPKG-Sorcerer'sStone.zip">
                                            Harry Potter And The Sorcerer&apos;s Stone / Harry Potter And The
                                            Philosopher&apos;s Stone
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/INDIVIDUAL+FILMS/PROMOPKG-ChamberOfSecrets.zip">
                                            Harry Potter And The Chamber of Secrets
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/INDIVIDUAL+FILMS/PROMOPKG-PrisonerOfAzkaban.zip">
                                            Harry Potter And The Prisoner of Azkaban
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/INDIVIDUAL+FILMS/PROMOPKG-GobletOfFire.zip">
                                            Harry Potter And The Goblet of Fire
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/INDIVIDUAL+FILMS/PROMOPKG-OderOfThePheonix.zip">
                                            Harry Potter And The Order of the Phoenix
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/INDIVIDUAL+FILMS/PROMOPKG-HalfBloodPrince.zip">
                                            Harry Potter And The Half-Blood Prince
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/INDIVIDUAL+FILMS/PROMOPKG-DeathlyHallowsPart1.zip">
                                            Harry Potter And The Deathly Hallows, Pt. 1
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/INDIVIDUAL+FILMS/PROMOPKG-DeathlyHallowsPart2.zip">
                                            Harry Potter And The Deathly Hallows, Pt. 2
                                        </a>
                                    </li>
                                </ul>
                                <h3 className="h3">
                                    Tentpole & Stunt Programming
                                    <small>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/PROMOPKG-TNTPOLESTUNTPRO.zip">
                                            <i className="glyphicon glyphicon-download-alt"></i> Download All
                                        </a>
                                    </small>
                                </h3>
                                <p>
                                    Stunts, special events, and marathons have a similar look and feel.
                                    Each package also includes an icon holding shape tied to the themes of
                                    the stunt, filled with related icons and imagery.
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/TENTPOLE+AND+STUNT+PROGRAMMING/Harry+Potter+Wizarding+Weekend.zip	">
                                            Harry Potter Wizarding Weekend
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/TENTPOLE+AND+STUNT+PROGRAMMING/Back+to+Hogwarts.zip	">
                                            Back to Hogwarts
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/TENTPOLE+AND+STUNT+PROGRAMMING/Dark+Arts.zip">
                                            Dark Arts
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/TENTPOLE+AND+STUNT+PROGRAMMING/Wizarding+World+Week.zip">
                                            Harry Potter Week
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://brainiac-prod-pages.s3-us-west-2.amazonaws.com/page/harry-potter/PROMO+PACKAGES/TENTPOLE+AND+STUNT+PROGRAMMING/Christmas.zip">
                                            Christmas
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </DocumentTitle>

        );
    }
}
