/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {FooterActions} from './footer-actions';
import FooterStore from './footer-store';
import {version as AppVersion} from '../../../package.json';
import {HomeConstants} from '../../home/home-actions';
import {MessagesContext} from '../../messages/messages-context';
import SessionStore from '../../session/session-store';

import './footer.less';

class FooterLink extends React.Component {
    static get propTypes() {
        return {
            external: PropTypes.bool,
            linkDescription: PropTypes.string.isRequired,
            linkUrl: PropTypes.string.isRequired
        };
    }

    static get defaultProps() {
        return {
            external: false
        };
    }

    static contextType = MessagesContext;

    render() {
        let description = <div className="caption-description-alone" dangerouslySetInnerHTML={{__html: this.props.linkDescription}} />;
        let link;
        if (this.props.external) {
            link = <a href={this.props.linkUrl} rel="noopener noreferrer" target="_blank">{description}</a>;
        } else {
            link = <Link to={this.props.linkUrl}>{description}</Link>;
        }
        return (
            <li className="footer-links">{link}</li>
        );
    }
}

class FooterGroup extends Component {

    static get propTypes() {
        return {
            group: PropTypes.instanceOf(Immutable.Map).isRequired,
            md: PropTypes.number.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false
        };

        this.handleAction = this.handleAction.bind(this);
    }

    static contextType = MessagesContext;

    handleAction() {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));
        return;
    }

    render() {
        let groupLinks = [];
        if (this.props.group.get('items')) {
            groupLinks = this.props.group.get('items').map((linkBody, k) => (
                <FooterLink key={k}
                    linkDescription={linkBody.get('description')}
                    linkUrl={linkBody.get('targetUrl')}
                    external={linkBody.get('targetType') === HomeConstants.TARGET_TYPE.EXTERNAL_WEB_PAGE.id}
                />
            )).toJS();
        }

        return (
            <Col md={this.props.md} xs={12}>
                <div
                    className="footer-toggle clickable h3"
                    data-toggle="collapse"
                    onClick={this.handleAction}
                    onKeyPress={this.handleAction}
                    role="button"
                    tabIndex="0"
                >
                    {this.context.intl.messages[`footer.navmenu.dropdown.${this.props.group.get('displayName').toLowerCase()}`]} <span className="caret"/>
                </div>
                <ul className={ClassNames('footer-list list-unstyled', {'active': this.state.collapsed === false})}>
                    {groupLinks}
                </ul>
            </Col>
        );
    }
}

class Footer extends Component {

    static get propTypes() {
        return {
            appVersion: PropTypes.string,
            copyrightOnly: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            appVersion: AppVersion,
            copyrightOnly: false
        };
    }

    static calculateState() {
        return {
            defaultCopyright: SessionStore.getState().get('defaultCopyright'),
            footerSections: FooterStore.getState().get('footerSections')
        };
    }

    static getStores() {
        return [FooterStore, SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        if (!this.props.copyrightOnly) {
            FooterActions.get();
        }
    }

    render() {
        let menu;
        let footerGroups = ['about', 'catalogs', 'tools', 'internal'].map(n => {
            return this.state.footerSections.get(n);
        }).filter(
            fg => !!fg
        ).filter(
            fg => !!fg.get('items').size
        ).map(
            (fs, i, arr) => <FooterGroup key={fs.get('name')} group={fs} md={12/arr.length}/>
        );

        if (!this.props.copyrightOnly) {
            menu = <div className="footer-nav">
                <div className="container padding-x-20">
                    <div className="row">
                        {footerGroups}
                    </div>
                </div>
            </div>;
        }

        return (
            <footer className="sticky-footer-container">
                {menu}
                <nav className="footer-copyright">
                    <div className="container padding-all-20 text-center">
                        {this.state.defaultCopyright}
                        <small className="app-version" aria-hidden="true">v{this.props.appVersion}</small>
                    </div>
                </nav>
            </footer>
        );
    }
}

export default Container.create(Footer);
export {Footer, FooterGroup, FooterLink};
