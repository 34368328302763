/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import React from 'react';
import {Modal} from 'react-bootstrap';

import Config from '../config/config';
import {MessagesContext} from '../messages/messages-context';
import NotificationStore from '../notification/notification-store';


class LoginRedirectModal extends React.Component {
    static calculateState() {
        return {
            show: NotificationStore.getState().get('loginRedirectModal')
        };
    }

    static getStores() {
        return [NotificationStore];
    }

    static contextType = MessagesContext;

    /*istanbul ignore next */render() {
        return (
            <Modal show={this.state.show}>
                <Modal.Header className="bg-wb-blue">
                    <Modal.Title className="text-center">{this.context.intl.messages['login.redirect.press-site.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <br />
                    <p dangerouslySetInnerHTML={{
                        __html: this.context.intl.messages['login.redirect.press-site.modal.body'].replace(
                            '%url', `${Config.Partners.PRESS_SITE.baseUrl}/login`
                        )
                    }}/>
                </Modal.Body>
                <Modal.Footer/>
            </Modal>
        );
    }
}

export default Container.create(LoginRedirectModal);

export {LoginRedirectModal};
