/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_TYPES} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';

import {SubscribedContentActions} from './subscribed-content-actions';
import SubscribedContentStore from './subscribed-content-store';
import {AssetTypeConstants} from '../../../asset-types/asset-type-constants';
import DocumentTitle from '../../../common/document-title';
import {MessagesContext} from '../../../messages/messages-context';
import Pagination from '../../../pagination';
import SessionStore from '../../../session/session-store';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/app/dataTables.brainiac.css');

// Load jQuery and register the datatables plugin.
/* eslint-disable @typescript-eslint/no-var-requires */
let jQuery = require('jquery');
require('datatables.net-responsive-bs');

class SubscribedContent extends Component {
    static calculateState() {
        return {
            assets: SubscribedContentStore.getState().get('assets'),
            offset: SubscribedContentStore.getState().get('offset'),
            size: SubscribedContentStore.getState().get('size'),
            subscribedContent: SubscribedContentStore.getState().get('subscribedContent'),
            totalCount: SubscribedContentStore.getState().get('totalCount')
        };
    }

    static getStores() {
        return [SubscribedContentStore];
    }

    constructor(props) {
        super(props);

        this.state = {
            assetId: undefined,
            assetType: undefined,
            showRemoveModal: false,
            userId: SessionStore.getState().getIn(['authUser', 'id'])
        };

        this.handleRowTrigger = this.handleRowTrigger.bind(this);
        this.hideDetailDialog = this.hideDetailDialog.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.removeDetail = this.removeDetail.bind(this);
        this.updateRows = this.updateRows.bind(this);
    }

    componentDidMount() {
        //get subscriptions
        SubscribedContentActions.getContent(this.state.userId);

        this.$table = jQuery('#subscribed-content-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This column will
                // contain the button to show the responsive data.
                className: 'control',
                targets: -1,
                width: 20
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        this.updateRows(this.state);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.showRemoveModal !== this.state.showRemoveModal ||
            nextState.subscribedContent.get('details').size !== this.state.subscribedContent.get('details').size ||
            nextState.assets !== this.state.assets) {
            return true;
        }
        return false;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        this.updateRows(nextState);
        return;
    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        return;
    }

    static contextType = MessagesContext;

    handleRowTrigger(event) {
        if (~event.target.className.indexOf('remove-detail')) {
            this.setState({
                showRemoveModal: true,
                assetType: parseInt(event.target.getAttribute('assetType'), 10),
                assetId: parseInt(event.target.getAttribute('assetId'), 10)
            });
        }

        return;
    }

    hideDetailDialog() {
        this.setState({
            showRemoveModal: false,
            assetType: 0,
            assetId: 0
        });
    }

    onPageChange(offset) {
        this.setState({
            offset
        });
        SubscribedContentActions.getContent(this.state.userId, offset);
        return;
    }

    removeDetail() {
        SubscribedContentActions.removeSubscribedContent(this.state.userId, this.state.assetType, this.state.assetId, this.state.offset);
        this.hideDetailDialog();
    }

    updateRows(state) {
        this.$tableAPI.clear();
        if (state?.assets?.size) {
            let details = state.subscribedContent.get('details');
            let assets = state.assets;
            let tableData = Immutable.List(details.map(detail => {
                let detailId = detail.get('assetId');
                let contentType = '';
                let url = assets.getIn([detailId, 'assetName']) || '';
                if (assets.getIn([detailId, 'imageSuperType']) || assets.getIn([detailId, 'contentType'])) {
                    switch (detail.get('assetType')) {
                    case AssetTypeConstants.ASSET_TYPE.IMAGE:
                        contentType = AssetTypeConstants.IMAGE_CONTENT_TYPE[assets.getIn([detailId, 'imageSuperType'])];
                        url = `<a href="/titles/${detail.get('titleId')}/images/${detail.get('assetId')}">${assets.getIn([detailId, 'assetName']) || ''}</a>`;
                        break;
                    case AssetTypeConstants.ASSET_TYPE.VIDEO:
                        contentType = AssetTypeConstants.VIDEO_CONTENT_TYPE[assets.getIn([detailId, 'contentType'])].description;
                        url = `<a href="/titles/${detail.get('titleId')}/videos/${detail.get('assetId')}">${assets.getIn([detailId, 'assetName']) || ''}</a>`;
                        break;
                    case AssetTypeConstants.ASSET_TYPE.AUDIO:
                        contentType = assets.getIn([detailId, 'contentType']);
                        break;
                    case AssetTypeConstants.ASSET_TYPE.MERCHANDISE:
                        contentType = AssetTypeConstants.MERCHANDISE_CONTENT_TYPE[assets.getIn([detailId, 'contentType'])];
                        break;
                    default:
                        contentType = DOCUMENT_TYPES[assets.getIn([detailId, 'contentType'])];
                    }
                }
                return Immutable.Map({
                    assetType: detail.get('assetType'),
                    assetId: detail.get('assetId'),
                    contentType: contentType,
                    subscriptionType: AssetTypeConstants.ASSET_TYPE_SHOW_NAMES[detail.get('assetType')],
                    subscriptionDate: Moment(detail.get('createdDate')).format(this.context.intl.messages['date-format']),
                    url: url
                });
            }));

            // Add sorted data to the jQuery datatable.
            tableData.forEach(data => {
                this.$tableAPI.row.add([
                    data.get('url'),
                    data.get('contentType'),
                    data.get('subscriptionType'),
                    data.get('subscriptionDate'),
                    `<a class="text-default-color"><span class="glyphicon glyphicon-trash remove-detail" assetType=${data.get('assetType')} assetId=${data.get('assetId')}/></a>`,
                    ''
                ]);
                return;
            });
        }
        this.$tableAPI.draw(false);
        return;
    }

    render() {
        return (
            <DocumentTitle
                message="document-titles.my-account.subscribed-content"
            >
                <div>
                    <Modal show={this.state?.showRemoveModal} onHide={this.hideDetailDialog}>
                        <Modal.Header className="bg-gray" closeButton>
                            <Modal.Title className="modal-title text-center">
                                {this.context.intl.messages['orders.tab.confirm-remove-content.dialog.title']}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{this.context.intl.messages['orders.tab.confirm-remove-content.dialog.description']}</p>
                        </Modal.Body>
                        <Modal.Footer className=" padding-all-20">
                            <Button bsStyle="primary" className="pull-right" onClick={this.removeDetail}>
                                {this.context.intl.messages['common.delete']}
                            </Button>
                            <Button bsStyle="default" className="btn-secondary margin-right-5 pull-left" onClick={this.hideDetailDialog}>
                                {this.context.intl.messages['common.close']}</Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="container padding-y-10 padding-x-20" id="hide-this">
                        <div className="row">
                            <h3 className="padding-y-10">
                                <span className="glyphicon glyphicon-check"/>
                                {this.context.intl.messages['orders.tab.subscribed-content.title.subscribed-content']}
                            </h3>
                            <table id="subscribed-content-table" className="table table-striped responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            {this.context.intl.messages['orders.tab.subscribed-content.table.title.asset-name']}
                                        </th>
                                        <th scope="col">
                                            {this.context.intl.messages['orders.tab.subscribed-content.table.title.content-type']}
                                        </th>
                                        <th scope="col">
                                            {this.context.intl.messages['orders.tab.subscribed-content.table.title.subscription-type']}
                                        </th>
                                        <th scope="col">
                                            {this.context.intl.messages['orders.tab.subscribed-content.table.title.subscribed-date']}
                                        </th>
                                        <th className="no-sort icon-column sorting_disabled" rowSpan="1" colSpan="1" scope="col">
                                            <span className="glyphicon glyphicon-trash"/>
                                        </th>
                                        <th className="no-sort" scope="col"/>
                                    </tr>
                                </thead>
                                <tbody onClick={this.handleRowTrigger} onKeyUp={this.handleRowTrigger} tabIndex="0" />
                            </table>
                            <div className="row">
                                <div className="text-center col-md-12">
                                    <Pagination
                                        className="margin-top-10"
                                        offset={this.state?.offset}
                                        onChange={this.onPageChange}
                                        size={this.state?.size}
                                        total={this.state?.totalCount}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(SubscribedContent);

export {SubscribedContent};

