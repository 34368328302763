/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';

import Result from './result';
import ResultClip from './result/result-clip';
import ResultsRow from './results-row';
import {SearchConstants} from './search-actions';
import SearchList from './search-list';
import SearchListWrapper from './search-list-wrapper';
import ClipsGrid from '../common/clips/clips-grid';
import ClipsGridLoading from '../common/clips/clips-grid-loading';
import ClipsList from '../common/clips/clips-list';
import {PageLayoutActions} from '../layout/page-layout/page-layout-actions';
import {MessagesContext} from '../messages/messages-context';
import {RouterActions} from '../router/router-actions';
import CreateClipModal from '../titles/clip/create-clip-modal';
import {BreakIntoGroups} from '../utils/utils';

class SearchSection extends Component {

    static get propTypes() {
        return {
            assets: PropTypes.instanceOf(Immutable.Map),
            canAddClipToCart: PropTypes.bool,
            canDownloadAsSpreadsheet: PropTypes.bool,
            currentClip: PropTypes.instanceOf(Immutable.Map),
            displayMode: PropTypes.oneOf([
                SearchConstants.DISPLAY_MODES.GRID,
                SearchConstants.DISPLAY_MODES.LIST
            ]).isRequired,
            downloadSearchUrl: PropTypes.string,
            hub: PropTypes.string.isRequired,
            isCreateClipModalVisible: PropTypes.bool,
            location: PropTypes.object.isRequired,
            showTitle: PropTypes.bool.isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.Map),
            title: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            assets: undefined,
            canAddClipToCart: false,
            canDownloadAsSpreadsheet: false,
            currentClip: null,
            downloadSearchUrl: undefined,
            isCreateClipModalVisible: false,
            thumbnails: undefined
        };
    }

    constructor(props) {
        super(props);

        this.getPlaceholders = this.getPlaceholders.bind(this);
    }

    static contextType = MessagesContext;

    getPlaceholders() {
        if (this.props.title === 'moments') {
            return <ClipsGridLoading />;
        }

        let placeholders = [];
        // Only create placeholders in GRID view.
        if (this.props.displayMode !== SearchConstants.DISPLAY_MODES.GRID) {
            return placeholders;
        }

        const query = QueryString.parse(this.props.location.search);
        let size = query.size || 8;
        for (let i = 0; i < size; i+=8) {
            placeholders.push(
                <div className="row" key={i}>
                    {[0, 1].map(x => (
                        <div className="col-xs-12 col-lg-6" key={x}>
                            <div className="row">
                                <Result thumbnails={Immutable.Map()} type="loading"/>
                                <Result thumbnails={Immutable.Map()} type="loading"/>
                                <Result thumbnails={Immutable.Map()} type="loading"/>
                                <Result thumbnails={Immutable.Map()} type="loading"/>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return placeholders;
    }

    handleViewAll(query) {
        RouterActions.redirect(`search/${this.props.title}?${QueryString.stringify(query)}`);
        PageLayoutActions.changeSearchBarDropdown(this.props.title);
    }

    render() {
        let title;
        if (this.props.hub === 'search') {
            if (this.props.assets.get('loading')) {
                // Display the loading row of assets.
                if (this.props.showTitle) {
                    title = (
                        <h3>
                            {this.context.intl.messages[`search.results.${this.props.title}`]}
                        </h3>
                    );
                }

                let rows = this.getPlaceholders();

                return (
                    <div>
                        {title}
                        {rows}
                    </div>
                );
            }

            if (this.props.assets.get('results').size === 0 && this.props.showTitle) {
                // Display the no results found header.
                return (
                    <h3>
                        {this.context.intl.messages[`search.results.${this.props.title}`]}
                        <small> {this.context.intl.messages['search.results.none-found']}</small>
                    </h3>
                );
            }
        }

        if (this.props.hub === 'search' && this.props.showTitle) {
            // The view all links show 40 items, just like the hubs.
            const q = QueryString.parse(this.props.location.search);
            let query = Object.assign({}, q);
            query.size = 40;

            if (this.props.title !== 'titles') {
                ['end-run-time', 'start-run-time'].forEach(p => delete query[p]);
            }

            let viewAll;
            if (this.props.assets.get('results').size > 7) {
                let label = `${this.context.intl.messages[`search.results.${this.props.title}`]} ${this.context.intl.messages['search.results.view-all']}`;

                viewAll = (
                    <button className="btn btn-sm bg-wb-blue" aria-label={label} onClick={this.handleViewAll.bind(this, query)}>
                        {this.context.intl.messages['search.results.view-all']} <span className="glyphicon glyphicon-chevron-right"/>
                    </button>
                );
            }

            title = (
                <h3>
                    {this.context.intl.messages[`search.results.${this.props.title}`]}
                    <small> {this.props.assets.get('totalCount')} {this.context.intl.messages[`search.results.${this.props.title}`]}</small>
                    {viewAll}
                </h3>
            );
        }

        const q = QueryString.parse(this.props.location.search).q;

        let view;
        switch (this.props.displayMode) {
        default:
        case SearchConstants.DISPLAY_MODES.GRID: {
            const content = BreakIntoGroups(this.props.assets.get('results'), 8).map((resGroup, i) => {
                if (this.props.title === 'moments') {
                    return (
                        <ClipsGrid
                            clips={resGroup}
                            key={i}
                            renderClipItem={(clip) => <ResultClip clip={clip} thumbnails={this.props.thumbnails} canAddToCart={this.props.canAddClipToCart} />}
                        />
                    );
                }
                return (
                    <ResultsRow
                        key={i}
                        results={resGroup}
                        thumbnails={this.props.thumbnails}
                        type={this.props.title}
                    />
                );
            });

            view = (
                <div>
                    {title}
                    {content}
                </div>
            );

            if (!this.props.showTitle) {
                view = (
                    <div>
                        <SearchListWrapper
                            title={title}
                            canDownloadAsSpreadsheet={this.props.canDownloadAsSpreadsheet}
                            downloadSearchUrl={this.props.downloadSearchUrl}
                            showDownloadSearchButton={this.props.title !== 'moments'}
                        >
                            {content}
                        </SearchListWrapper>
                    </div>
                );
            }
            break;
        }
        case SearchConstants.DISPLAY_MODES.LIST:
            if (this.props.title === 'moments') {
                view = (
                    <SearchListWrapper
                        title={title}
                        downloadSearchUrl={this.props.downloadSearchUrl}
                        showDownloadSearchButton={this.props.title !== 'moments'}
                    >
                        <ClipsList
                            clips={this.props.assets.get('results')}
                            canAddToCart={this.props.canAddClipToCart}
                            thumbnails={this.props.thumbnails}
                        />
                        <CreateClipModal clip={this.props.currentClip} searchTerm={q} show={this.props.isCreateClipModalVisible} />
                    </SearchListWrapper>
                );
                break;
            }

            let columns = [];
            // Configure the list columns shown
            switch (this.props.title) {
            case 'titles':
                if (this.props.hub !== 'film') {
                    // TV, Animation, etc
                    columns = ['title', 'titleType', 'runtime', 'releaseDate', 'mpmNumber', 'genres', 'language', 'numSeasons', 'numEpisodes'];
                } else {
                    // Film hub - do not show numSeasons/numEpisodes
                    columns = ['title', 'titleType', 'runtime', 'releaseDate', 'mpmNumber', 'genres', 'language'];
                }
                break;
            case 'images':
            case 'videos':
            case 'documents':
                columns = ['assetName', 'assetTitle', 'assetDateCreated'];
                break;
            case 'talent':
                columns = ['talentName', 'talentCreditedAs'];
                break;
            case 'episodes':
                columns = ['title', 'titleType', 'runtime', 'releaseDate', 'mpmNumber', 'genres', 'language'];
                break;
            }

            view = (
                <SearchListWrapper
                    title={title}
                    canDownloadAsSpreadsheet={this.props.canDownloadAsSpreadsheet}
                    downloadSearchUrl={this.props.downloadSearchUrl}
                    showDownloadSearchButton={this.props.title !== 'moments'}
                >
                    <SearchList
                        accordion={false}
                        assets={this.props.assets.get('results')}
                        columns={columns}
                        name="searchlist"
                    />
                </SearchListWrapper>
            );
            break;
        }

        return (
            <>
                {view}
                {this.props.title === 'moments' &&
                <CreateClipModal clip={this.props.currentClip} searchTerm={q} show={this.props.isCreateClipModalVisible} />}
            </>
        );
    }
}

export default SearchSection;
