/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';
import {Link} from 'react-router-dom';
import url from 'url';

import {MessagesContext} from '../messages/messages-context';
import SessionStore from '../session/session-store';


export default class StationSummit extends React.Component {
    componentDidMount() {
        window.location.href = this.getRedirectionUrl();
    }

    static contextType = MessagesContext;

    getRedirectionUrl() {
        let authEncoded = window.btoa(SessionStore.getState().get('authUser').get('id') + '_' + SessionStore.getState().get('__token'));

        return url.format(
            `https://sitcoms.wbtvd.com/SSO?auth=${authEncoded}`
        );
    }

    render() {
        let RedirectUrl = this.getRedirectionUrl();

        return (
            <div>
                <div className="row title-header">
                    <div className="title-header-text text-center">
                        <h1><strong>{this.context.intl.messages['station-summit.redirect.header']}</strong></h1>
                        <p><Link to={RedirectUrl}>{this.context.intl.messages['station-summit.redirect.text']}</Link></p>
                    </div>
                </div>
            </div>
        );
    }
}
