/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Row, Col} from 'reactstrap';

import DocumentTitle from '../../common/document-title';
import {MessagesContext} from '../../messages/messages-context';
import {TalentConstants} from '../../talent/talent-actions';
import ImageLoader from '../../titles/asset-title/images/image-loader';
import DigitalSalesDecksStore from '../dsd-store';

class TalentList extends Component {
    static get propTypes() {
        return {
            talentList: PropTypes.instanceOf(Immutable.List).isRequired,
            title: PropTypes.string.isRequired,
            role: PropTypes.number.isRequired
        };
    }

    constructor(props) {
        super(props);
    }

    static contextType = MessagesContext;

    render() {
        let talents;
        switch (true) {
        case TalentConstants.ROLE.ACTOR === this.props.role:
            talents = this.props.talentList.map(talentItem => {
                let actor;
                let credited = this.props.title;
                if (talentItem.get('characterNameInTitle')) {
                    credited = talentItem.get('characterNameInTitle');
                }
                if (talentItem.get('defaultImagePortraitThumbnailUrl')) {
                    actor = (
                        <Row>
                            <Col xs={3}>
                                <div className="margin-y-10">
                                    <div className="responsive-container">
                                        <div className="vertical-spacer">
                                            <div className="img-container">
                                                <ImageLoader
                                                    alt={talentItem.get('displayTalentFullName')}
                                                    className="img-responsive"
                                                    height="200"
                                                    src={talentItem.get('defaultImagePortraitThumbnailUrl')}
                                                    width="200"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={5}>
                                <div className="margin-y-15">
                                    <h3>{talentItem.get('displayTalentFullName')}</h3>
                                    <h5>{credited}</h5>
                                </div>
                            </Col>
                        </Row>
                    );
                } else {
                    actor = (
                        <Row>
                            <Col xs={3}>
                                <div className="margin-y-15 text-center">
                                    <h3>{talentItem.get('displayTalentFullName')}</h3>
                                </div>
                            </Col>
                            <Col xs={5}>
                                <div className="margin-y-15">
                                    <h5>{credited}</h5>
                                </div>
                            </Col>
                        </Row>
                    );
                }
                return <div className="talent-list">{actor}</div>;
            });
            break;
        default:
            talents = this.props.talentList.map(talentItem => {
                return (
                    <div className="talent-list">
                        <h3>{talentItem.get('displayTalentFullName')}</h3>
                    </div>
                );
            });
            break;
        }

        return (
            <div className="margin-y-10">
                <h5>{this.props.title}</h5>
                {talents}
            </div>
        );
    }
}

export class Talent extends Component {
    static get propTypes() {
        return {
            match: PropTypes.object.isRequired,
        };
    }

    static calculateState() {
        return {
            titles: DigitalSalesDecksStore.getState().get('titles')
        };
    }

    static getStores() {
        return [DigitalSalesDecksStore];
    }

    constructor(props) {
        super(props);
    }

    static contextType = MessagesContext;

    render() {
        const sections = [{
            title: this.context.intl.messages['digital-sales-decks.tab.talent.actor'],
            role: TalentConstants.ROLE.ACTOR
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.director'],
            role: TalentConstants.ROLE.DIRECTOR
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.producer'],
            role: TalentConstants.ROLE.PRODUCER
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.voice-talent'],
            role: TalentConstants.ROLE.VOICE_TALENT
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.host'],
            role: TalentConstants.ROLE.HOST
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.writer'],
            role: TalentConstants.ROLE.WRITER
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.musician'],
            role: TalentConstants.ROLE.MUSICIAN
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.choreographer'],
            role: TalentConstants.ROLE.CHOREOGRAPHER
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.executive-producer'],
            role: TalentConstants.ROLE.EXECUTIVE_PRODUCER
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.guest-star'],
            role: TalentConstants.ROLE.GUEST_STAR
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.author'],
            role: TalentConstants.ROLE.AUTHOR
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.animated-character'],
            role: TalentConstants.ROLE.ANIMATED_CHARACTER
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.misc-crew'],
            role: TalentConstants.ROLE.MISC_CREW
        }, {
            title: this.context.intl.messages['digital-sales-decks.tab.talent.creator'],
            role: TalentConstants.ROLE.CREATOR
        }];

        let talentSections;
        let activeTitle = this.state?.titles.find(title => title.get('id').toString() === this.props.match.params.titleId);
        /* istanbul ignore if */
        if (activeTitle && activeTitle.get('talent')) {
            const talents = activeTitle.get('talent');
            talentSections = sections.map(section => {
                const items = talents.get(section.role.toString());
                if (!items || (items.size === 0)) {
                    return null;
                }
                return (
                    <TalentList
                        title={section.title}
                        talentList={items}
                        role={section.role}
                    />
                );
            });
        }

        return (
            <DocumentTitle
                message="document-titles.dsd.talent"
            >
                <section className="talent">
                    {talentSections}
                </section>
            </DocumentTitle>
        );
    }
}

export default Container.create(Talent);

export {TalentList};
