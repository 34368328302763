/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {Button, Row, Col} from 'react-bootstrap';

import DocumentTitle from '../../common/document-title';
import LandingLayout from '../../layout/landing';
import {MessagesContext} from '../../messages/messages-context';
import {Alert} from '../../notification/alert';
import MFAEnrollmentEmailModal from '../../notification/mfa/mfa-enrollment-email-modal';
import MFAPushNotificationModal from '../../notification/mfa/mfa-push-notification-modal';
import {SessionActions} from '../../session/session-actions';
import SessionStore from '../../session/session-store';

class LoginLink extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            login: SessionStore.getState().get('login')
        };
    }

    static getStores() {
        return [SessionStore];
    }

    constructor(props) {
        super(props);
        this.state = this.constructor.calculateState();
    }

    componentDidMount() {
        const query = QueryString.parse(this.props.location.search);

        SessionActions.loginWithOneTimeLink(
            query['secure-login-token'],
            SessionStore.getPartner()
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.login !== this.state.login;
    }

    static contextType = MessagesContext;

    render() {
        const loginButtonText = this.context.intl.messages['login.login.in-progress'];
        const loginButtonProgress = (
            <i className="far fa-sync-alt fa-spin" />
        );

        return (
            <DocumentTitle
                message="document-titles.one-time-login"
            >
                <LandingLayout>
                    <Alert/>
                    <MFAEnrollmentEmailModal
                        email={this.state.login.get('email')}
                    />
                    <MFAPushNotificationModal />
                    <Col className="box" sm={4} smOffset={4}>
                        <Row>
                            <Col sm={12}>
                                <div className="padding-y-10">
                                    {this.context.intl.messages['one-time-login.please-wait']}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Button
                                    block
                                    bsSize="lg"
                                    bsStyle="primary"
                                    disabled={true}
                                    type="submit"
                                >{loginButtonText} {loginButtonProgress}</Button>
                            </Col>
                        </Row>
                    </Col>
                </LandingLayout>
            </DocumentTitle>
        );
    }

}

export default Container.create(LoginLink);
export {LoginLink};
