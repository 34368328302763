/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Partner} from '@wbdt-sie/brainiac-web-common';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';

import MultiBrandAPI from './multi-brand-api';
import Config from '../../config/config';
import LoadingOverlay from '../components/loading-overlay';
import {SessionSlice} from '../session/session-slice';


type PartnerType = typeof Partner;
interface Event {
    key: string
}

export default function UserPartnersList() {
    const {data: partners, isLoading, error} = MultiBrandAPI.useGetAllPartnersQuery();
    const [postToken, {isLoading: isLoadingPost, data: securityRequestResult}] = MultiBrandAPI.useNewPartnerTokenPostMutation();
    const [selectedPartner, setSelectedPartner] = useState<PartnerType>();

    if (isLoading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return <FormattedMessage
            defaultMessage="Something went wrong getting all the partners for the user. Please try again later..."
            description="Error message when the Get All Partners API fails."
            id="multi.brand.get.partners.api.error"
        />;
    }

    if (!partners) {
        return <FormattedMessage
            defaultMessage="Something went wrong. The request to retreive all partners has finished but no partners have been found!"
            description="Error message when the Get All Partners API succeeded but the list is empty."
            id="multi.brand.get.partners.api.empty"
        />;
    }

    if (securityRequestResult) {
        window.location.assign(`${selectedPartner?.baseUrl}/login?sso=${securityRequestResult.secureLoginToken}&partnerId=${selectedPartner?.id}&partnerKey=${selectedPartner?.key}`);
    }

    const displayPartners = Object.values<PartnerType>(Config.Partners).filter((p: PartnerType) => {
        const clientId = p.id;
        return partners.find((i: PartnerType) => i.clientId === clientId) as PartnerType;
    });

    const handleNavigateToPartner = (partner: PartnerType) => {
        const auth = SessionSlice.getInitialState().token;
        setSelectedPartner(partner);
        postToken({
            id: partner.id,
            token: auth
        });
    };

    const handleNavigateToPartnerEnter = (event: Event, partner: PartnerType) => {
        if (event.key === 'Enter') {
            handleNavigateToPartner(partner);
        }
    };

    return (
        <>
            <LoadingOverlay show={isLoadingPost}/>
            <div className="flex flex-wrap width-full mb-6 justify-center">
                {displayPartners.map( (partner, index) => {
                    const logo = partner.logo;

                    // STUDIO-10590 expect the logo images to be in / folder (auto copied during build process)
                    const logoUrl = `url(/${logo})`;
                    return (
                        <div
                            className="aspect-video h-40 bg-black-background-solid m-2 p-5 hover:bg-black-background-title cursor-pointer"
                            onClick={() => handleNavigateToPartner(partner)}
                            onKeyPress={(event) => handleNavigateToPartnerEnter(event, partner)}
                            key={partner.key}
                            role="button"
                            tabIndex={index}
                        >
                            <div className="bg[logoUrl] h-full w-full bg-no-repeat bg-contain bg-center" style={{backgroundImage: logoUrl}}/>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
