/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';

import LandingAPI from '../layout/landing/landing-api';
import MultiBrandAPI from '../multi-brand/multi-brand-api';
import {MultiBrandSlice} from '../multi-brand/multi-brand-slice';
import {SessionSlice} from '../session/session-slice';
import TitleAPI from '../titles/title-api';

const apis = [
    MultiBrandAPI,
    TitleAPI,
    LandingAPI
];

const reducers: any = {};
apis.forEach(a => {
    reducers[a.reducerPath] = a.reducer;
});
const Store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apis.map(a => a.middleware)),
    reducer: {
        brands: MultiBrandSlice.reducer,
        session: SessionSlice.reducer,
        ...reducers
    }
});

// Optional, but required for refetchOnFocus/refetchOnReconnect behaviors.
setupListeners(Store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch;

export default Store;
