/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import {HelpActions} from './help-actions';
import HelpStore from './help-store';
import {PlayerActions} from '../player/player-actions';
import PlayerStore from '../player/player-store';
import VideoOverlay from '../player/video-overlay';
import Preloader from '../preloader';
import ImageLoader, {ImageLoaderPlaceholders} from '../titles/asset-title/images/image-loader';
import {BreakIntoGroups, GetThumbnail} from '../utils/utils';
import './help.less';

class HelpVideo extends React.Component {
    static get propTypes() {
        return {
            item: PropTypes.instanceOf(Immutable.Map)
        };
    }

    static get defaultProps() {
        return {
            item: null
        };
    }

    showPlayer(video) {
        PlayerActions.showPlayer(video);
    }

    render() {
        if (!this.props.item) {
            return null;
        }

        let thumbnail = GetThumbnail(this.props.item.getIn(['video', 'thumbnails']), 400);
        let image;
        /* istanbul ignore next */
        if (thumbnail) {
            image = thumbnail.get('thumbnailUrl');
        }

        return (
            <div className="video-box col-md-4">
                <div className="padding-all-10 margin-bottom-10">
                    <div
                        className="video-thumbnail"
                        onClick={this.showPlayer.bind(this, this.props.item.get('video'))}
                        role="presentation"
                    >
                        <ImageLoader
                            alt={this.props.item.getIn(['video', 'assetComment'])}
                            className="img-responsive"
                            placeholder={ImageLoaderPlaceholders.HORIZONTAL}
                            src={image}
                        />

                        <span className="glyphicon glyphicon-play-circle thumbnail-play-icon"/>
                    </div>

                    <h4 dangerouslySetInnerHTML={{__html: this.props.item.get('description')}} />
                </div>
            </div>
        );
    }
}

class Help extends React.Component {
    static calculateState() {
        return {
            help: HelpStore.getState().get('helpPublishingList'),
            isLoading: HelpStore.getState().get('isLoading'),
            showOverlay: PlayerStore.getState().get('showOverlay'),
            video: PlayerStore.getState().get('video'),
            watchlist: PlayerStore.getState().get('watchlist')
        };
    }

    static getStores() {
        return [PlayerStore, HelpStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidMount() {
        HelpActions.get();
    }

    render() {
        return (
            <div>
                <Preloader
                    fixed
                    show={this.state.isLoading}
                />

                <VideoOverlay
                    noAddToCart
                    noShare
                    video={/* istanbul ignore next */ this.state.video.size === 0 ? undefined : this.state.video}
                    visible={this.state.showOverlay}
                    watchlist={this.state.watchlist}
                    titleId={0} // TODO: react-17-upgrade missing required prop, test this
                />

                <div className="help-header">
                    <div className="container">
                        <img className="img-responsive" alt="" src={require('./help-page-header.jpg')}/>
                    </div>
                </div>

                <div className="container padding-y-10 padding-x-10 padding-x-20">
                    <div className="row">
                        <h1>{this.state.help.get('displayName')}</h1>
                        <p dangerouslySetInnerHTML={{__html: this.state.help.get('description')}}/>
                    </div>

                    {BreakIntoGroups(this.state.help.get('items'), 3).map(/* istanbul ignore next */(itemsRow, i) => /* istanbul ignore next */(
                        <div className="help-content row" key={i}>
                            <HelpVideo item={itemsRow.get(0)} />
                            <HelpVideo item={itemsRow.get(1)} />
                            <HelpVideo item={itemsRow.get(2)} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

}

export default Container.create(Help);
export {Help, HelpVideo};
