/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

import {AssetTypeConstants} from '../asset-types/asset-type-constants';
import {MessagesContext} from '../messages/messages-context';
import {TitleConstants} from '../titles/title-actions';
import {FormatReleaseDate} from '../utils/utils';

import 'datatables.net-responsive-bs/css/responsive.bootstrap.css';
import '../styles/app/dataTables.brainiac.css';
import '../styles/app/responsive.brainiac.css';

export const createHeaders = function(headerName, showName, className) {
    return (
        <th className={className}
            key={headerName}
            scope="col"
        >
            {showName}
        </th>
    );
};

/**
 * Trims the text and add ellipsis if is greater than 20 characters
 * @param  {string} text
 * @param  {int} max characters | 20
 * @return {string}
 */
export const trim = function(text, max) {
    max = max || 20;
    if (text.length >= max) {
        text = text.slice(0, max) + '...';
    }
    return text.trim();
};

/**
* Compose link to asset
*/
export const getAssetLink = function(asset) {
    /* istanbul ignore if */
    if (!asset) {return '';}

    const assetName = asset.get('assetName');
    let titleId = '';
    if (asset.get('browseTitleList') && asset.get('browseTitleList').size) {
        titleId = asset.get('browseTitleList').first().get('id');
    } else {
        // Handle assets that are missing browseTitleList
        return assetName;
    }

    switch (asset.get('assetType')) {
    case AssetTypeConstants.ASSET_TYPE.IMAGE:
        return (<a href={`/titles/${titleId}/images/${asset.get('id')}`} title={assetName}>{assetName}</a>);
    case AssetTypeConstants.ASSET_TYPE.VIDEO:
        return (<a href={`/titles/${titleId}/videos/${asset.get('id')}`} title={assetName}>{assetName}</a>);
    // treat all others as documents
    default:
        return (<a href={`/titles/${titleId}/documents`} title={assetName}>{assetName}</a>);
    }
};

/**
 * Column definitions.
 */
const COLUMNS = {
    assetName: {
        get: function(asset) {
            return getAssetLink(asset);
        },
        header: function(context) {
            return createHeaders('assetName', context.messages['search-list.columns.assetName.title']);
        }
    },
    assetTitle: {
        get: function(asset) {
            if (asset.get('browseTitleList').size) {
                return asset.get('browseTitleList').first().get('name');
            }
            return asset.get('assetName');

        },
        header: function(context) {
            return createHeaders('assetTitle', context.messages['search-list.columns.assetTitle.title']);
        }
    },
    assetDateCreated: {
        get: function(asset, assets, index, context) {
            if (asset.get('createdDate')) {
                return Moment.utc(asset.get('createdDate')).format(context.messages['date-format']);
            }

            return '';
        },
        header: function(context) {
            return createHeaders('assetDateCreated', context.messages['search-list.columns.assetDateCreated.title']);
        }
    },
    talentName: {
        get: function(talent) {
            return (<a href={`/talent/${talent.get('talentId')}`} title={talent.get('fullName')}>{trim(talent.get('fullName'))}</a>);
        },
        header: function(context) {
            return createHeaders('talentName', context.messages['search-list.columns.talentName.title']);
        }
    },
    talentCreditedAs: {
        get: function(talent) {
            if (talent.get('aka', Immutable.List()).size) {
                return talent.get('aka').toArray().map(t => t.get('fullName')).join(', ');
            }
            return;
        },
        header: function(context) {
            return createHeaders('talentCreditedAs', context.messages['search-list.columns.talentCreditedAs.title']);
        }
    },
    title: {
        get: function(title) {
            let resultName = title.get('displayName');
            let tooltipResultName = resultName;
            let categoryGroup = Object.keys(TitleConstants.TITLE_TYPES).reduce((c, t) => {
                if (TitleConstants.TITLE_TYPES[t].id === title.get('category')) {
                    return TitleConstants.TITLE_TYPES[t].categoryGroup;
                }
                return c;
            }, undefined);

            switch (categoryGroup) {
            case TitleConstants.TITLE_CATEGORY_GROUPS.SEASON:
                tooltipResultName = resultName + ' - Season ' + title.get('season') || '';
                resultName = <>{`${resultName} - `}<i>{`Season ${title.get('season')}`}</i></>;
                break;

            case TitleConstants.TITLE_CATEGORY_GROUPS.EPISODE:
                tooltipResultName = resultName + ' - ' + title.get('parentTitleDisplayName') || '' + ' ' + title.get('fullRunningOrder') || '';
                resultName = <>{`${resultName} - `}<i>{`${title.get('parentTitleDisplayName') || ''} ${title.get('fullRunningOrder') || ''}`}</i></>;
                break;
            }
            return (<a href={`/titles/${title.get('id')}`} title={tooltipResultName}>{resultName}</a>);
        },
        header: function(context) {
            return createHeaders('title', context.messages['search-list.columns.title.title']);
        }
    },
    titleType: {
        get: function(asset) {
            return asset.getIn(['details', 'categoryName']);
        },
        header: function(context) {
            return createHeaders('titleType', context.messages['search-list.columns.title-type.title']);
        }
    },
    runtime: {
        get: function(asset, assets, index, context) {
            let runTime = asset.getIn(['details', 'runTime']);
            if (runTime) {
                return runTime + ' ' + context.messages['search-list.columns.runtime.minutes'];
            }

            return;
        },
        header: function(context) {
            return createHeaders('runtime', context.messages['search-list.columns.runtime.title']);
        }
    },
    releaseDate: {
        get: function(asset) {
            if (!asset.get('titleReleaseDate')) {
                return '';
            }
            if (asset.get('titleReleaseDateType') === TitleConstants.RELEASE_DATE_TYPES.DISPLAY_NAME.id) {
                return FormatReleaseDate(asset.get('titleReleaseDateDisplayName'), asset.get('titleReleaseDateType'), '');
            } else {
                return FormatReleaseDate(Moment(asset.get('titleReleaseDate')), asset.get('titleReleaseDateType'), '');
            }
        },
        header: function(context) {
            return createHeaders('releaseDate', context.messages['search-list.columns.release-date.title']);
        }
    },
    mpmNumber: {
        get: function(asset) {
            return asset.get('mpmNumber');
        },
        header: function(context) {
            return createHeaders('mpmNumber', context.messages['common.mpm']);
        }
    },
    genres: {
        get: function(asset) {
            if (asset.get('genres')) {
                return asset.get('genres').join(', ');
            }
        },
        header: function(context) {
            return createHeaders('genres', context.messages['search-list.columns.genres.title']);
        }
    },
    language: {
        get: function(asset) {
            if (asset.get('languages')) {
                return asset.get('languages').join(', ');
            }
        },
        header: function(context) {
            return createHeaders('language', context.messages['search-list.columns.language.title']);
        }
    },
    numSeasons: {
        get: function(asset) {
            let count = asset.get('seasonCount') || '';
            return count;
        },
        header: function(context) {
            return createHeaders('numSeasons', context.messages['search-list.columns.num-seasons.title']);
        }
    },
    numEpisodes: {
        get: function(asset) {
            let count = asset.get('episodeCount') || '';
            return count;
        },
        header: function(context) {
            return createHeaders('numEpisodes', context.messages['search-list.columns.num-episodes.title']);
        }
    },
};

class SearchList extends PureComponent {
    static get propTypes() {
        return {
            accordion: PropTypes.bool,
            assets: PropTypes.instanceOf(Immutable.List).isRequired,
            columns: PropTypes.array.isRequired,
            name: PropTypes.string.isRequired,
            number: PropTypes.number.isRequired
        };
    }

    static get defaultProps() {
        return {
            accordion: false
        };
    }

    static contextType = MessagesContext;

    getTableId() {
        let tableId = this.props.name;
        if (this.props.number !== undefined) {
            tableId += `-${this.props.number}`;
        }
        return tableId;
    }

    render() {
        let tableId = this.getTableId();
        let header;
        if (this.props.accordion) {
            header = (
                <h3>
                    <a
                        className="text-default-color accordion-header"
                        href={`#list-assets-${tableId}-accordion`}
                        data-toggle="collapse"
                    >&nbsp;
                        <small>
                            <span className="glyphicon glyphicon-chevron-down" />
                        </small>
                    </a>
                </h3>
            );
        }

        let rows = this.props.assets.map( (asset, i) => {
            let cells = this.props.columns.map( (c, ind) => {
                let column = COLUMNS[c];
                return (<td key={i + '-' + ind}>{column.get(asset, this.props.assets, i, this.context.intl)}</td>);
            });
            return (<tr key={i}>{cells}</tr>);
        });
        return (
            <div>
                {header}
                <div id={`list-assets-${tableId}-accordion`} className={ClassNames('accordion', {collapse: this.props.accordion})}>
                    <table id={`list-assets-${tableId}-table`} className="table table-striped">
                        <thead>
                            <tr>
                                {this.props.columns.map(c => {
                                    let column = COLUMNS[c];
                                    return column.header(this.context.intl);
                                })}
                            </tr>
                        </thead>
                        <tbody onClick={this.handleRowClick} onKeyUp={this.handleRowClick}>{rows}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default SearchList;
