/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import style from './gallery-thumbnail.module.less';

class GalleryThumbnail extends Component {

    static get propTypes() {
        return {
            merchandise: PropTypes.instanceOf(Immutable.Map).isRequired,
            showCaptions: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            showCaptions: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            openMenu: false
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.merchandise !== nextProps.merchandise ||
            this.props.showCaptions !== nextProps.showCaptions) {
            return true;
        }
        return this.state.openMenu !== nextState.openMenu;
    }

    render() {
        if (!this.props.merchandise) {return null;}
        let captions;
        if (this.props.showCaptions) {
            let merchNameText = this.props.merchandise.get('assetComment') || this.props.merchandise.get('name');
            let merchName = `<b class="${style.titleBold}">${merchNameText}</b>`;
            let description = this.props.merchandise.get('description') || '';
            let ellipsis = '';
            if (description.length > 120) {
                ellipsis = '...';
            }
            let price = this.props.merchandise.get('price');
            if (price) {
                price = price.toFixed(2);
                price = '<b>$' + price + '</b>';
            }
            captions = <div>
                <div className={ClassNames('thumbnail-caption-long show', style.title)} dangerouslySetInnerHTML={{__html: merchName}}></div>
                <div className="thumbnail-caption-long show innerHTML" dangerouslySetInnerHTML={{__html: description.slice(0, 120) + ellipsis}}></div>
                <div className={ClassNames('thumbnail-caption-short show', style.price)} dangerouslySetInnerHTML={{__html: price}}></div>
            </div>;
        }
        // FIXME: this link returns 404 because it is not authorized.
        let imageURL = `asset/merchandise/${this.props.merchandise.get('id')}/file`;
        let previewImage;
        if (this.props.merchandise.get('filePath')) {
            previewImage = (
                <div
                    role="button"
                    tabIndex="0"
                >
                    <img
                        alt={captions}
                        className="responsive-image"
                        src={imageURL}
                    />
                </div>
            );
        }
        return (
            <div className={ClassNames('col-xs-6 col-sm-3 thumbnail-container box', style.box)}>
                <div className={style.merchitem}>
                    <div className="responsive-container">
                        <div className="vertical-spacer">
                        </div>
                        <div className="img-container">
                            {previewImage}
                        </div>
                    </div>
                    {captions}
                </div>
            </div>
        );
    }

}
export default GalleryThumbnail;
