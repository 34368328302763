/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import ReactSelect from 'react-select';

import 'bootstrap/js/collapse';
import 'bootstrap/js/dropdown';
import {OrderActions} from './order-actions';
import {OrderConstants} from './order-constants';
import OrderStore from './order-store';
import withSetSearchFilters from '../common/search-filters/hocs/with-set-search-filters';
import {GetAttr} from '../common/utils/utils';
import DatePicker from '../form/date-picker';
import Dropdown from '../form/dropdown';
import {MessagesContext} from '../messages/messages-context';
import {Debounce, IsMobile} from '../utils/utils';

class DateRangeOptions extends React.Component {
    static get propTypes() {
        return {
            className: PropTypes.string,
            dateName: PropTypes.string.isRequired,
            location: PropTypes.object.isRequired,
            setFilter: PropTypes.func.isRequired,
            title: PropTypes.string.isRequired,
        };
    }

    static get defaultProps() {
        return {
            className: '',
        };
    }

    static contextType = MessagesContext;

    render() {
        const query = QueryString.parse(this.props.location.search);
        let endDate = `end-${this.props.dateName}`;
        let startDate = `start-${this.props.dateName}`;
        return (
            <Dropdown
                className={this.props.className}
                title={this.props.title}
            >
                <li className="padding-all-10">
                    <label>{this.context.intl.messages['orders.filters.navbar.titles.date.from']}</label>
                    <DatePicker
                        className="search-criteria-input form-control"
                        onChange={this.props.setFilter.bind(this, startDate)}
                        selected={query[startDate] || ''}
                    />
                </li>
                <li className="padding-all-10">
                    <label>{this.context.intl.messages['orders.filters.navbar.titles.date.to']}</label>
                    <DatePicker
                        className="search-criteria-input form-control"
                        onChange={this.props.setFilter.bind(this, endDate)}
                        selected={query[endDate] || ''}
                    />
                </li>
            </Dropdown>
        );
    }
}

const DateRangeOptionsWithSetSearchFilters = withSetSearchFilters(DateRangeOptions);

class OrderFilters extends React.Component {
    static get propTypes() {
        return {
            clientOptions: PropTypes.instanceOf(Immutable.List).isRequired,
            location: PropTypes.object.isRequired,
            requesterOptions: PropTypes.instanceOf(Immutable.List).isRequired,
            setFilter: PropTypes.func.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.searchClient = Debounce(this.searchClient.bind(this), 200);
        this.searchRequester = Debounce(this.searchRequester.bind(this), 200);

        return;
    }

    componentDidMount() {
        const query = QueryString.parse(this.props.location.search);
        let clients = query['client-user-id'];
        if (clients) {
            /* istanbul ignore else */
            if (!Array.isArray(clients)) {
                clients = [clients];
            }
            OrderActions.getUserFiltersById(OrderConstants.ORDER.FILTERS.CLIENT, clients);
        } else {
            OrderActions.getUserFilters(OrderConstants.ORDER.FILTERS.CLIENT);
        }
        let requesters = query['requester-user-id'];
        if (requesters) {
            /* istanbul ignore else */
            if (!Array.isArray(requesters)) {
                requesters = [requesters];
            }
            OrderActions.getUserFiltersById(OrderConstants.ORDER.FILTERS.REQUESTER, requesters);
        } else {
            OrderActions.getUserFilters(OrderConstants.ORDER.FILTERS.REQUESTER);
        }
        return;
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.location.search !== nextProps.location.search ||
            this.props.location.pathname !== nextProps.location.pathname ||
            this.props.clientOptions !== nextProps.clientOptions ||
            this.props.requesterOptions !== nextProps.requesterOptions) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    /* istanbul ignore next */
    filterUser(option, term) {
        return term.split(' ').every(t =>
            !!option.data.fullName.toLowerCase().match(t.toLowerCase()) ||
            !!option.data.email.toLowerCase().match(t.toLowerCase())
        );
    }

    /* istanbul ignore next */
    initNavbar(elem) {
        jQuery(elem);
        return;
    }

    searchClient(term) {
        OrderActions.getUserFilters(OrderConstants.ORDER.FILTERS.CLIENT, term);
        return;
    }

    searchRequester(term) {
        OrderActions.getUserFilters(OrderConstants.ORDER.FILTERS.REQUESTER, term);
        return;
    }

    render() {
        const query = QueryString.parse(this.props.location.search);
        let dateAdded;
        let dueDate;
        let statusFilters;
        let filters = OrderConstants.ORDER_FILTERS[this.props.location.pathname];

        /* istanbul ignore else */
        if (!IsMobile.any()) {
            dateAdded = <DateRangeOptionsWithSetSearchFilters
                className={{
                    active: query['start-order-date'] || query['end-order-date'],
                    disabled: !filters.dateAdded
                }}
                dateName="order-date"
                location={this.props.location}
                title={this.context.intl.messages['orders.filters.navbar.titles.date_added']}
            />;
            dueDate = <DateRangeOptionsWithSetSearchFilters
                className={{
                    active: query['start-due-date'] || query['end-due-date'],
                    disabled: !filters.dueDate
                }}
                dateName="due-date"
                location={this.props.location}
                title={this.context.intl.messages['orders.filters.navbar.titles.due_date']}
            />;
        }

        let selects = {
            'client-user-id': [],
            'requester-user-id': []
        };

        /* istanbul ignore next */
        Object.keys(selects).forEach(f => {
            let values = query[f];
            if (values) {
                if (!Array.isArray(values)) {
                    values = [values];
                }
                switch (f) {
                case 'client-user-id':
                    selects['client-user-id'] = values.map(v => {
                        let id = parseInt(v, 10);
                        let user = this.props.clientOptions.find(option => option.get('clientId') === id) || Immutable.Map();
                        return {
                            clientId: id,
                            email: user.get('email'),
                            fullName: user.get('fullName')
                        };
                    });
                    break;
                case 'requester-user-id':
                    selects['requester-user-id'] = values.map(v => {
                        let id = parseInt(v, 10);
                        let user = this.props.requesterOptions.find(option => option.get('requesterId') === id) || Immutable.Map();
                        return {
                            requesterId: id,
                            email: user.get('email'),
                            fullName: user.get('fullName')
                        };
                    });
                    break;
                default:
                    selects[f] = this.state[`${f}Options`].filter(o => values.indexOf(o.get('name')) !== -1).toJS();
                    break;
                }
            }
            return;
        });

        /* istanbul ignore else */
        if (this.props.location.pathname === '/orders/metadata') {
            statusFilters = (
                <li className="padding-x-10">
                    <div className="checkbox">
                        <label>
                            <input
                                checked={!!query.active}
                                onChange={this.props.setFilter.bind(this, 'active')}
                                type="checkbox"
                                value="active"
                            /> {this.context.intl.messages['orders.filters.navbar.titles.order-status.active']}
                        </label>
                    </div>
                    <div className="checkbox">
                        <label>
                            <input
                                checked={!!query.complete}
                                onChange={this.props.setFilter.bind(this, 'complete')}
                                type="checkbox"
                                value="complete"
                            /> {this.context.intl.messages['orders.filters.navbar.titles.order-status.complete']}
                        </label>
                    </div>
                    <div className="checkbox">
                        <label>
                            <input
                                checked={!!query.onHold}
                                onChange={this.props.setFilter.bind(this, 'onHold')}
                                type="checkbox"
                                value="onHold"
                            /> {this.context.intl.messages['orders.filters.navbar.titles.order-status.on-hold']}
                        </label>
                    </div>
                </li>
            );
        } else if (filters.status) {
            statusFilters = (
                <li className="padding-x-10">
                    <div className="checkbox">
                        <label className={ClassNames({disabled: !filters.status[0]})}>
                            <input
                                checked={!!query.approved}
                                disabled={!filters.status[0]}
                                onChange={this.props.setFilter.bind(this, 'approved')}
                                type="checkbox"
                                value="approved"
                            /> {this.context.intl.messages['orders.filters.navbar.titles.order-status.approved']}
                        </label>
                    </div>
                    <div className="checkbox">
                        <label className={ClassNames({disabled: !filters.status[1]})}>
                            <input
                                checked={!!query.fulfilled}
                                disabled={!filters.status[1]}
                                onChange={this.props.setFilter.bind(this, 'fulfilled')}
                                type="checkbox"
                                value="fulfilled"
                            /> {this.context.intl.messages['orders.filters.navbar.titles.order-status.fulfilled']}
                        </label>
                    </div>
                    <div className="checkbox">
                        <label className={ClassNames({disabled: !filters.status[2]})}>
                            <input
                                checked={!!query.denied}
                                disabled={!filters.status[2]}
                                onChange={this.props.setFilter.bind(this, 'denied')}
                                type="checkbox"
                                value="denied"
                            /> {this.context.intl.messages['orders.filters.navbar.titles.order-status.denied']}
                        </label>
                    </div>
                    <div className="checkbox">
                        <label className={ClassNames({disabled: !filters.status[3]})}>
                            <input
                                checked={!!query.fulfillment}
                                disabled={!filters.status[3]}
                                onChange={this.props.setFilter.bind(this, 'fulfillment')}
                                type="checkbox"
                                value="fulfillment"
                            /> {this.context.intl.messages['orders.filters.navbar.titles.order-status.fulfillment']}
                        </label>
                    </div>
                    <div className="checkbox">
                        <label className={ClassNames({disabled: !filters.status[4]})}>
                            <input
                                checked={!!query.requested}
                                disabled={!filters.status[4]}
                                onChange={this.props.setFilter.bind(this, 'requested')}
                                type="checkbox"
                                value="requested"
                            /> {this.context.intl.messages['orders.filters.navbar.titles.order-status.requested']}
                        </label>
                    </div>
                </li>
            );
        }

        return (
            <div className="container-fluid search-criteria-container">
                <div className="row">
                    <div className="col-xs-12">
                        <button type="button" className="btn btn-block navbar-toggle collapsed" style={{position: 'relative', textAlign: 'left'}} data-toggle="collapse" data-target="#navbar-secondary" aria-expanded="false" aria-controls="navbar">
                            Filter Orders
                            <span className="glyphicon glyphicon-cog" />
                        </button>
                        <div id="navbar-secondary" className="navbar-collapse collapse" aria-expanded="false">
                            <ul className="nav navbar-nav">
                                <li className="dropdown" style={{lineHeight: '20px', padding: '25px', fontWeight: 700}}>{this.context.intl.messages['orders.filters.navbar.filter-by']}</li>
                                <Dropdown
                                    className={{
                                        active: query.active || query.approved || query.complete || query.denied || query.fulfilled || query.fulfillment || query.onHold || query.requested,
                                        disabled: !filters.status,
                                    }}
                                    title={this.context.intl.messages['orders.filters.navbar.titles.order-status']}
                                >
                                    {statusFilters}
                                </Dropdown>
                                <Dropdown
                                    className={{active: query['order-number'], disabled: !filters.orderNumber}}
                                    title={this.context.intl.messages['orders.filters.navbar.titles.order-number']}
                                >
                                    <li className="padding-all-10">
                                        <input
                                            className="search-criteria-input"
                                            name="order-number"
                                            onChange={this.props.setFilter.bind(this, 'order-number')}
                                            type="text"
                                            value={query['order-number']}
                                        />
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    className={{active: query.name, disabled: !filters.orderName}}
                                    title={this.context.intl.messages['orders.filters.navbar.titles.order-name']}
                                >
                                    <li className="padding-all-10">
                                        <input
                                            className="search-criteria-input"
                                            name="name"
                                            onChange={this.props.setFilter.bind(this, 'name')}
                                            type="text"
                                            value={query.name}
                                        />
                                    </li>
                                </Dropdown>
                                {dateAdded}
                                {dueDate}
                                <Dropdown
                                    className={{active: query['requester-user-id'], disabled: !filters.requester}}
                                    title={this.context.intl.messages['orders.filters.navbar.titles.requester']}
                                >
                                    <li className="padding-all-10">
                                        <ReactSelect
                                            className="search-criteria-input"
                                            filterOption={this.filterUser}
                                            getOptionLabel={GetAttr('fullName')}
                                            getOptionValue={GetAttr('requesterId')}
                                            isMulti
                                            name="requester-user-id"
                                            onChange={this.props.setFilter.bind(this, 'requester-user-id', 'requesterId', true)}
                                            onInputChange={this.searchRequester}
                                            options={this.props.requesterOptions.toJS()}
                                            value={selects.requesterId}
                                        />
                                    </li>
                                </Dropdown>
                                <Dropdown
                                    className={{active: query['client-user-id'], disabled: !filters.client}}
                                    title={this.context.intl.messages['orders.filters.navbar.titles.client']}
                                >
                                    <li className="padding-all-10">
                                        <ReactSelect
                                            className="search-criteria-input"
                                            filterOption={this.filterUser}
                                            getOptionLabel={GetAttr('fullName')}
                                            getOptionValue={GetAttr('fullName')}
                                            isMulti
                                            name="client-user-id"
                                            onChange={this.props.setFilter.bind(this, 'client-user-id', 'clientId', true)}
                                            onInputChange={this.searchClient}
                                            options={this.props.clientOptions.toJS()}
                                            value={selects.clientId}
                                        />
                                    </li>
                                </Dropdown>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const OrderFiltersWithSetSearchFilters = withSetSearchFilters(OrderFilters);

class OrderFiltersWrapper extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [OrderStore];
    }

    static calculateState() {
        return {
            clientOptions: OrderStore.getState().getIn(['filters', 'client']),
            requesterOptions: OrderStore.getState().getIn(['filters', 'requester'])
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    render() {
        return (
            <OrderFiltersWithSetSearchFilters
                clientOptions={this.state.clientOptions}
                location={this.props.location}
                requesterOptions={this.state.requesterOptions}
            />
        );
    }
}

export default Container.create(OrderFiltersWrapper);

export {
    DateRangeOptionsWithSetSearchFilters as DateRangeOptions,
    OrderFiltersWithSetSearchFilters as OrderFilters,
    OrderFiltersWrapper as OrderFiltersWrapper
};
