/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Brand, Partner} from '@wbdt-sie/brainiac-web-common';
import React, {useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

import MultiBrandAPI from './multi-brand-api';
import {MultiBrandSlice} from './multi-brand-slice';
import LoadingOverlay from '../components/loading-overlay';
import Modal from '../components/modal/modal';

import Config from '~/src/config/config';

type BrandType = typeof Brand;
type PartnerType = typeof Partner;
interface Event {
    key: string
}

export default function UserPartnersList() {
    const allBrands = MultiBrandSlice.getInitialState().brands;
    const availableBrands = allBrands.filter((c: any) => {
        const brandConfig = Object.values<PartnerType>(Config.Partners).find((p: PartnerType) => p.id === c.clientId);
        return !brandConfig?.preventSignup;
    });

    const [putRequest, {isLoading: isLoadingPut, isSuccess: isPutSuccess}] = MultiBrandAPI.usePutSecurityRequestMutation();

    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [reasonForRequest, setReasonForRequest] = useState<string>('');
    const [selectedBrand, setSelectedBrand] = useState<BrandType>();

    useEffect(() => {
        if (isPutSuccess) {
            setReasonForRequest('');
            setIsInfoModalOpen(true);
        }
    }, [isPutSuccess]);

    const handleSelectBrandEnter = (event: Event, brand: BrandType) => {
        if (event.key === 'Enter') {
            handleSelectBrand(brand);
        }
    };

    const handleSelectBrand = (brand: BrandType) => {
        setReasonForRequest('');
        setIsFormModalOpen(true);
        setSelectedBrand(brand);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>)=> {
        setReasonForRequest(e.target.value);
    };

    const submitRequest = () => {
        setIsFormModalOpen(false);
        putRequest(`${selectedBrand?.name} ${reasonForRequest}`);
    };

    return (
        <>
            <Modal
                show={isFormModalOpen}
                onHide={() => setIsFormModalOpen(false)}
                title= {<FormattedMessage
                    defaultMessage="Request Access"
                    description="Description of header of modal for requesting access to a brand portal."
                    id="multi.brand.request.access.modal.title"
                />}
                disableSubmit={!reasonForRequest.length}
                onSubmit={submitRequest}
            >
                <div id="request-access-modal-content" className="">
                    <p className="font-bold font-default text-sm">
                        <FormattedMessage
                            defaultMessage="Brand Portal"
                            description="Selected brand portal to request access title."
                            id="multi.brand.request.access.modal.selected.title"/>
                    </p>
                    <p className="font-default text-sm">{selectedBrand?.name}</p>
                    {/* TODO: make this a component */}
                    <div className="flex flex-row justify-center items-center py-5">
                        <div className="flex w-full flex-row justify-center items-center border-t border-gray-light" />
                    </div>
                    <p>
                        <label htmlFor="reason-for-request" className="font-bold font-default text-sm">
                            <FormattedMessage
                                defaultMessage="Reason for access:"
                                description="Label for input about reason for request access to brand portal."
                                id="multi.brand.request.access.modal.selected.input"/>
                        </label> <span className="text-red-solid">*</span>
                    </p>
                    <textarea
                        className="w-full border border-gray-active p-3 text-gray-active"
                        id="reason-for-request"
                        onChange={handleOnChange}
                        placeholder="Please provide a short description for why you require access"
                    />
                </div>
            </Modal>
            <Modal
                show={isInfoModalOpen}
                onHide={() => setIsInfoModalOpen(false)}
                title= {<FormattedMessage
                    defaultMessage="Request Submitted"
                    description="Header of modal informing success on submit of request for permission."
                    id="multi.brand.request.info.modal.title"
                />}
            >
                <div id="request-access-info-modal-content" className="">
                    <p>
                        <FormattedMessage
                            defaultMessage="Your request was submitted successfully. Your request will be reviewed within one business day."
                            description="Content of modal informing success on submit of request for permission."
                            id="multi.brand.request.access.modal.selected.description"/>
                    </p>
                </div>
            </Modal>
            <LoadingOverlay show={isLoadingPut}/>
            <div className="flex flex-wrap width-full mb-6 justify-center">
                {availableBrands.map((brand, index) => {
                    const logoSourceUrl = brand.logoSourceS3Path || undefined;
                    return (
                        <div
                            className="aspect-video h-28 bg-black-background-solid m-2 p-5 hover:bg-black-background-title cursor-pointer"
                            onClick={() => handleSelectBrand(brand)}
                            onKeyPress={(event) => handleSelectBrandEnter(event, brand)}
                            key={brand.id}
                            role="button"
                            tabIndex={index}
                        >
                            <div className="h-full w-full bg-no-repeat bg-contain bg-center" style={{backgroundImage: `url(${logoSourceUrl})`}}/>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
