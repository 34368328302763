/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import AssetRightsIcon from '../../../assets/asset-rights';
import {MessagesContext} from '../../../messages/messages-context';
import {IsNotTriggerKey} from '../../../utils/utils';
import {ClipActions} from '../../clip/clip-actions';

export default class ClipThumbnailToolbar extends React.PureComponent {
    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool.isRequired,
            clip: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleId: PropTypes.number.isRequired,
        };
    }

    constructor(props) {
        super(props);

        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    static contextType = MessagesContext;

    handleAddToCart(ev) {
        if (ev && IsNotTriggerKey(ev)) {
            ev.preventDefault();

            return;
        }

        const {clip, titleId} = this.props;
        ClipActions.createClipFromMoment(clip.set('titleId', titleId));
    }

    render() {
        const {canAddToCart, clip} = this.props;
        let addToCart;
        if (canAddToCart) {
            addToCart = (
                <span
                    aria-label={this.context.intl.messages['title.player.add-new-moment']}
                    className="glyphicon glyphicon-shopping-cart"
                    data-testid="add-to-cart-btn"
                    onClick={this.handleAddToCart}
                    onKeyUp={this.handleAddToCart}
                    role="button"
                    tabIndex={0}
                />
            );
        }

        return (
            <div className="video-toolbar">
                <AssetRightsIcon video={clip}/>
                {addToCart}
            </div>
        );
    }
}
