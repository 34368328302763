/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {isEpisode, isSerie} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {Glyphicon} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {MessagesContext} from '../../messages/messages-context';

require('../../../node_modules/bootstrap/js/dropdown');

class SerieNavigation extends React.Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleId: PropTypes.number.isRequired
        };
    }

    static contextType = MessagesContext;

    initDropdowns(elem) {
        /* istanbul ignore else */
        if (!elem) {return;}
        // jQuery(elem).find('.dropdown-toggle').dropdown();
    }

    render() {
        const where = this.props.serieNavigation.getIn(['serieMap', `${this.props.titleId}`]);
        let episodesTitle = this.context.intl.messages['title.nav.sections.episodes.title'];
        let seasonsTitle = this.context.intl.messages['title.nav.sections.season.title'];
        let seasonsLinks;
        let seriesLink;
        let episodesLinks;
        let season;
        let assetPath = '';
        if (this.props.location) {
            let pathnames = this.props.location.pathname.split('/');
            if (pathnames.length > 3) {
                assetPath = '/' + pathnames[3];
            }
        }
        if (!this.props.serieNavigation || !this.props.serieNavigation.get('serieMap') || !this.props.titleId) {
            // render null if data is not available
            return null;
        }
        /* STUDIO-13678: this condition should most likely be removed, I can't find a possible case scenario */
        /* istanbul ignore next */
        if (!where) {
            // outdated navigation map, this could be 2 things:
            // 1) serie changed
            // 2) not a serie/season/episode just render null
            return null;
        }

        const query = {...QueryString.parse(location.search)};
        // reset current page to be sure that server will return something
        delete query.offset;
        if (where) {
            if (isSerie(where)) {
                seasonsTitle = this.context.intl.messages['title.nav.sections.series.title'];
            } else {
                if (this.props.serieNavigation.get('seasons')) {
                    season = this.props.serieNavigation.getIn(['seasons', where.get(0)]);
                    seasonsTitle = this.context.intl.messages['title.nav.sections.season.title']+ ' ' + season.get('seasonNumber');
                    episodesLinks = season.get('episodes').map( (e, i) => {
                        return (
                            <li key={i}><Link to={`/titles/${e.get('titleId')}${assetPath}?${QueryString.stringify(query)}`}>{this.context.intl.messages['title.nav.sections.episode.title']} {e.get('episodeNumber')}</Link></li>
                        );
                    });
                } else {
                    seasonsTitle = this.context.intl.messages['title.nav.sections.series.title'];
                    let episode = this.props.serieNavigation.get('episodes').find(e => e.get('childTitleId') === this.props.titleId);
                    episodesTitle = this.context.intl.messages['title.nav.sections.episode.title']+ ' ' + episode.get('orderWithinParent');
                }
            }
            if (isEpisode(where)) {
                let episode = this.props.serieNavigation.getIn(['seasons', where.get(0), 'episodes', where.get(1)]);
                episodesTitle = this.context.intl.messages['title.nav.sections.episode.title']+ ' ' + episode.get('episodeNumber');
            }
        }

        if (this.props.serieNavigation.get('seasons')) {
            seasonsLinks = this.props.serieNavigation.get('seasons').map((s, i) => {
                return (
                    <li key={i}><Link to={`/titles/${s.get('titleId')}${assetPath}?${QueryString.stringify(query)}`}>{this.context.intl.messages['title.nav.sections.season.title']} {s.get('seasonNumber')}</Link></li>
                );
            });
        } else {
            episodesLinks = this.props.serieNavigation.get('episodes').map( (e, i) => {
                return (
                    <li key={i}><Link to={`/titles/${e.get('childTitleId')}${assetPath}?${QueryString.stringify(query)}`}>{this.context.intl.messages['title.nav.sections.episode.title']} {e.get('orderWithinParent')}</Link></li>
                );
            });
        }

        seriesLink = <li><Link to={`/titles/${this.props.serieNavigation.get('titleId')}${assetPath}?${QueryString.stringify(query)}`}>{this.context.intl.messages['title.nav.sections.series.title']}</Link></li>;

        return (
            <div className="padding-top-15 padding-x-15 pull-right-sm-up margin-all-0" ref={this.initDropdowns}>
                <div className="row">
                    <div className="col-xs-6">
                        <div className="dropdown">
                            <button
                                className="btn btn-block btn-secondary dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                disabled={!seriesLink}
                            >{seasonsTitle} <span className="caret"></span></button>
                            <ul className="dropdown-menu">
                                {seriesLink}
                                {seasonsLinks}
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-6" style={{paddingLeft:'0px'}}>
                        <div className="dropdown">
                            <button
                                className="btn btn-block btn-secondary dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                disabled={!episodesLinks || !episodesLinks.size}
                            >{episodesTitle} <span className="caret"></span></button>
                            <ul className="dropdown-menu auto-columns pull-right">
                                {episodesLinks}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class EpisodeNavigation extends React.Component {
    static get propTypes() {
        return {
            serieNavigation: PropTypes.instanceOf(Immutable.Map).isRequired,
            titleId: PropTypes.number.isRequired,
            location: PropTypes.object,
            useCurrentSearch: PropTypes.bool
        };
    }

    static get defaultProps() {
        return {
            location: {
                pathname: ''
            },
            useCurrentSearch: false
        };
    }

    static contextType = MessagesContext;

    render() {
        // if no episode => show nothing
        if (!this.props.serieNavigation || !this.props.serieNavigation.get('serieMap') || !this.props.titleId) {
            // render null if data is not available
            return null;
        }

        let assetPath = '';
        if (this.props.location) {
            let pathnames = this.props.location.pathname.split('/');
            if (pathnames.length > 3) {
                assetPath = '/' + pathnames[3];
                if (this.props.useCurrentSearch) {
                    assetPath += this.props.location.search;
                }
            }
        }

        let where = this.props.serieNavigation.getIn(['serieMap', `${this.props.titleId}`]);

        // mini series episode check
        let hasSeason = this.props.serieNavigation.get('seasons') !== undefined;
        let hasEpisode = this.props.serieNavigation.get('episodes') !== undefined;
        let isMiniSerieEpisode = !hasSeason && hasEpisode && this.props.serieNavigation.get('titleId') !== parseInt(this.props.titleId, 10);

        if (!where || where.size !== 2 && !isMiniSerieEpisode) {
            return null;
        }

        let episodeOrder;
        let episodes;
        let previous = '';
        let next = '';

        if (isMiniSerieEpisode) {
            episodes = this.props.serieNavigation.get('episodes');
            episodeOrder = where.get(0);
            if (episodeOrder !== 0) {
                previous = `/titles/${episodes.getIn([episodeOrder - 1, 'childTitleId'])}${assetPath}`;
            }
            if (episodeOrder + 1 < episodes.size) {
                next = `/titles/${episodes.getIn([episodeOrder + 1, 'childTitleId'])}${assetPath}`;
            }
        } else {
            episodes = this.props.serieNavigation.getIn(['seasons', where.get(0), 'episodes']);
            episodeOrder = where.get(1);
            if (episodeOrder !== 0) {
                previous = `/titles/${episodes.getIn([episodeOrder - 1, 'titleId'])}${assetPath}`;
            }
            if (episodeOrder + 1 < episodes.size) {
                next = `/titles/${episodes.getIn([episodeOrder + 1, 'titleId'])}${assetPath}`;
            }
        }

        return (<span className="inline-block">
            <Link to={previous}
                disabled={!previous}
                className="btn btn-default btn-secondary btn-sm">
                <Glyphicon glyph="chevron-left" />{this.context.intl.messages['title.nav.sections.episode.prev']}
            </Link>
            <Link to={next}
                disabled={!next}
                className="btn btn-default btn-secondary btn-sm">{this.context.intl.messages['title.nav.sections.episode.next']}
                <Glyphicon glyph="chevron-right" />
            </Link>
        </span>);
    }
}

export {
    EpisodeNavigation,
    SerieNavigation
};
