/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, Grid} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {MessagesContext} from '../../messages/messages-context';
import AssetTitleStore from '../../titles/asset-title/asset-title-store';

class TalentNav extends Component {
    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            talentId: PropTypes.string.isRequired
        };
    }

    static calculateState() {
        return {
            images: AssetTitleStore.getState().get('images')
        };
    }

    static getStores() {
        return [AssetTitleStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign(
            this.constructor.calculateState(),
            {
                links: Immutable.Map({
                    info: '/account',
                    subscriptions: '/account/subscriptions',
                    subscribedContent: '/account/subscribed-content',
                    onboarding: '/account/onboarding',
                    myOrders: '/account/my-orders',
                    mySavedCarts: '/account/my-saved-carts',
                    mySavedSearches: '/account/my-saved-searches',
                    downloadHistory: '/account/download-history',
                    metadata: '/account/metadata'
                })
            }
        );

        this.isActive = this.isActive.bind(this);

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.location.pathname !== nextProps.location.pathname ||
            this.props.talentId !== nextProps.talentId) {
            return true;
        }

        if (this.state.images !== nextState.images) {
            return true;
        }

        return false;
    }

    static contextType = MessagesContext;

    /**
     * Returns "active" if current path (location.pathname)
     * matches the provided link.
     */
    isActive(links, linkName) {
        if (this.props.location.pathname === links.get(linkName)) {
            return 'active';
        }

        return;
    }

    render() {
        let links = Immutable.Map({
            audio: `/talent/${this.props.talentId}/audio`,
            documents: `/talent/${this.props.talentId}/documents`,
            images: `/talent/${this.props.talentId}/images`,
            info: `/talent/${this.props.talentId}`,
            merchandise: `/talent/${this.props.talentId}/merchandise`,
            videos: `/talent/${this.props.talentId}/videos`
        });

        let isInfoPage = this.props.location.pathname === links.get('info');

        let talentNav = [];
        // Remove comments to enable the other links.
        [/*'videos', 'images', 'documents', 'audio', 'merchandise'*/].forEach((assetName, i) => {
            if (this.state[assetName].size || !isInfoPage) {
                talentNav.push(
                    <li key={i}>
                        <Link className={ClassNames('title-nav-link', this.isActive(links, assetName))} to={links.get(assetName)}>{this.context.intl.messages[`title.nav.sections.${assetName}.title`]}</Link>
                    </li>
                );
            }
        });

        return (
            <div className="title-nav">
                <Grid>
                    <hr className="visible-xs-block" />

                    <div className="pull-left-sm-up">
                        <Button type="button" block className="title-nav-collapsed navbar-toggle collapsed text-uppercase padding-top-0 padding-left-10 padding-bottom-20 margin-all-0"
                            data-toggle="collapse"
                            data-target="#navbar-secondary"
                            aria-expanded="false"
                            aria-controls="navbar">
                            {this.context.intl.messages['title.nav.sections.title']} <span className="caret" />
                        </Button>
                        <div id="navbar-secondary" className="navbar-collapse collapse" aria-expanded="false">
                            <ul className="nav navbar-nav">
                                <li>
                                    <Link className={ClassNames('title-nav-link', this.isActive(links, 'info'))} to={links.get('info')}>{this.context.intl.messages['title.nav.sections.info.title']}</Link>
                                </li>
                                {talentNav}
                            </ul>
                        </div>
                    </div>
                </Grid>
            </div>
        );
    }

}

export default Container.create(TalentNav);
export {TalentNav};
