/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {ModalShareActions} from './modal-share-actions';
import ModalShareStore from './modal-share-store';
import {MessagesContext} from '../../messages/messages-context';
import {RouterActions} from '../../router/router-actions';
import SearchStore from '../../search/search-store';
import {Debounce} from '../../utils/utils';

class ShareModal extends React.Component {
    static get propTypes() {
        return {
            canIncludeTitleApiUrl: PropTypes.bool,
            close: PropTypes.func.isRequired,
            data: PropTypes.object,
            done: PropTypes.func.isRequired,
            location: PropTypes.object,
            show: PropTypes.bool.isRequired,
        };
    }

    static get defaultProps() {
        return {
            canIncludeTitleApiUrl: false,
            data: undefined,
            location: {
                search: ''
            }
        };
    }

    static calculateState() {
        return {
            usersList: ModalShareStore.getState().get('usersList'),
        };
    }

    static getStores() {
        return [ModalShareStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            email: '',
            includeApiUrl: false
        }, this.constructor.calculateState());

        this.clearEmail = this.clearEmail.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleIncludeApiUrlChange = this.handleIncludeApiUrlChange.bind(this);
        this.handleSearchClients = Debounce(this.handleSearchClients.bind(this), 200);
        this.onClose = this.onClose.bind(this);
        this.onShare = this.onShare.bind(this);
        this.selectedUser = this.selectedUser.bind(this);
    }

    static contextType = MessagesContext;

    clearEmail() {
        this.setState({email: ''});
        ModalShareActions.clear();
    }

    handleEmailChange(event) {
        event.preventDefault();
        this.setState({email: event.target.value});
    }

    handleIncludeApiUrlChange(event) {
        this.setState({includeApiUrl: event.target.checked});

        let base = SearchStore.getState().getIn(['titles', 'downloadUrlBase']);
        let query = SearchStore.getState().getIn(['titles', 'downloadUrlQuery']).toJS();
        let routerQuery = QueryString.parse(this.props.location.search);

        if (event.target.checked) {
            routerQuery['title-api-url'] = `${base}?${QueryString.stringify(query)}`;
        } else {
            delete routerQuery['title-api-url'];
        }

        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(routerQuery)}`, true);
    }

    handleSearchClients(user) {
        if (user !== '') {
            ModalShareActions.getUsers(user);
        }
    }

    onClose() {
        this.props.close();
        this.clearEmail();
    }

    onShare() {
        let shareUrl = this.props.data?.shareUrl || window.location.href;
        let shareId = this.props.data?.shareId || '';
        this.props.done(this.state.email, shareUrl, shareId);
        this.clearEmail();
    }

    selectedUser(value) {
        this.setState(() => ({
            email: value.email
        }));
    }

    render() {
        let shareUrl = this.props.data?.shareUrl || window.location.href;
        let isDisabled = !this.state.email;
        let includeApiUrl;
        if (this.props.canIncludeTitleApiUrl) {
            includeApiUrl = (
                <div className="form-group checkbox">
                    <label>
                        <input
                            type="checkbox"
                            value={this.state.includeApiUrl}
                            onChange={this.handleIncludeApiUrlChange}
                        /> {this.context.intl.messages['title.modal.share.include-api-url']}
                    </label>
                </div>
            );
        }

        return (
            <Modal show={this.props.show} onHide={this.onClose}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center text-uppercase">{this.context.intl.messages['title.modal.share.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label>{this.context.intl.messages['title.modal.share.send-email']}:</label>
                            <ReactSelect
                                getOptionLabel={/* istanbul ignore next */u => u.email}
                                getOptionKey={/* istanbul ignore next */u => u.id}
                                getOptionValue={/* istanbul ignore next */u => u.id}
                                onInputChange={this.handleSearchClients}
                                onChange={this.selectedUser}
                                options={this.state.usersList.toJS()}
                            />
                        </div>
                        {includeApiUrl}
                        <div className="form-group">
                            <label>{this.context.intl.messages['title.modal.share.copy-url']}:</label>
                            <input type="text" className="form-control" value={shareUrl} readOnly />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className=" padding-all-20">
                    <Button bsStyle="default" className="pull-left btn-secondary" onClick={this.onClose}>{this.context.intl.messages['common.close']}</Button>
                    <Button bsStyle="primary" disabled={isDisabled} className="pull-right" onClick={this.onShare}><i className="glyphicon glyphicon-transfer" />&nbsp;{this.context.intl.messages['common.share']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default Container.create(ShareModal);
export {ShareModal};
