/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

// Title Types used for the filter select.
export default [{
    id: '1',
    value: 'Theatrical Features'
}, {
    id: '2',
    value: 'Animated Features'
}, {
    id: '3',
    value: 'Made-For-Video Features'
}, {
    id: '4',
    value: 'Half Hour Series Season'
}, {
    id: '5',
    value: 'One Hour Series Season'
}, {
    id: '6',
    value: 'Talk Show Season'
}, {
    id: '7',
    value: 'Game Show Season'
}, {
    id: '8',
    value: 'Animated Series Season'
}, {
    id: '9',
    value: 'Individual Cartoons'
}, {
    id: '10',
    value: 'Mini Series'
}, {
    id: '11',
    value: 'Made-For-TV Movies - Network'
}, {
    id: '12',
    value: 'Made-For-TV Movies - Cable'
}, {
    id: '13',
    value: 'Made-For-TV Movies - Pay TV'
}, {
    id: '14',
    value: 'Specials'
}, {
    id: '15',
    value: 'Animated Specials'
}, {
    id: '16',
    value: 'Comedy Specials'
}, {
    id: '17',
    value: 'Music Specials'
}, {
    id: '18',
    value: 'Sports Programming'
}, {
    id: '19',
    value: 'Documentaries'
}, {
    id: '20',
    value: 'Short Programs'
}, {
    id: '21',
    value: 'Making Of'
}, {
    id: '23',
    value: 'Episode'
}, {
    id: '24',
    value: 'Series - Half Hour'
}, {
    id: '25',
    value: 'Series - One Hour'
}, {
    id: '26',
    value: 'Talk Shows'
}, {
    id: '27',
    value: 'Game Shows'
}, {
    id: '28',
    value: 'Animated Series'
}, {
    id: '29',
    value: 'Format Rights'
}, {
    id: '30',
    value: 'Reality'
}, {
    id: '31',
    value: 'Reality Season'
}, {
    id: '32',
    value: 'Direct To Video'
}, {
    id: '33',
    value: 'Made-For-Video Animation'
}, {
    id: '34',
    value: 'Individual Cartoons - Season'
}, {
    id: '35',
    value: 'Short Programs - Season'
}, {
    id: '36',
    value: 'Event'
}, {
    id: '37',
    value: 'Other Products'
}, {
    id: '38',
    value: 'Segment'
}, {
    id: '39',
    value: 'Programming Package'
}, {
    id: '40',
    value: 'HBO Films'
}, {
    id: '41',
    value: 'Documentary Films'
}, {
    id: '42',
    value: 'Limited Series'
}, {
    id: '43',
    value: 'Docuseries'
}, {
    id: '44',
    value: 'Limited Series Season'
}, {
    id: '45',
    value: 'Docuseries Season'
}, {
    id: '46',
    value: 'Warner Bros: HBO Max Release'
}];
