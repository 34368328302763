/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './crime-package.less';

export default class CrimePackage extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.crime-package">
                <div className="container op_container_crime_package">

                    <div id="op_header" className="row">

                        <div id="op-header-img">
                            <div id="header-btns">
                                <a className="btn-trailer btn-header reel" href="/titles/1843185/videos/1915466" rel="noopener noreferrer" target="_blank"><img src="https://wbtvd.com/page/crime-package/banner_btn_sizzlereel.png" alt="" /></a>
                                <a className="btn-header presentation" href="https://wbtvd.com/page/crime-package/WB_Procedural_And_Crime.pdf" rel="noopener noreferrer" target="_blank"><img src="https://wbtvd.com/page/crime-package/banner_btn_presentation.png" alt="" /></a>
                            </div>
                        </div>
                    </div>

                    <div id="op_content" className="row clearfix">

                        <h2 className="h2_crime_package">Procedural Dramas</h2>
                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/284342" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Closer-The_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/284342" className="btn btn-seriesinfo seriesinfo_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Series Info</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/179036" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Cold_Case_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/179036" className="btn btn-seriesinfo seriesinfo_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Series Info</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/1166819" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Major_Crimes_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/1166819" className="btn btn-seriesinfo seriesinfo_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Series Info</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/612782" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Mentalist_The_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/612782" className="btn btn-seriesinfo seriesinfo_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Series Info</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/936785" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Rizzoli_And_Isles_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/936785" className="btn btn-seriesinfo seriesinfo_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Series Info</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/75088" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Without_A_Trace_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/75088" className="btn btn-seriesinfo seriesinfo_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Series Info</span></a></div>
                        </div>
                    </div>
                    <div id="op_content" className="row clearfix">
                        <h2 className="h2_crime_package">Theatrical</h2>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/45860" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Absolute_Power_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/45860" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/45860/videos/1916709" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/74416" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Blood_Work_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/74416" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/74416/videos/1174382" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/74017" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Conspiracy_Theory_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/74017" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/74017/videos/1200490" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/1201537" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Gangster_Squad_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/1201537" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/1201537/videos/1335045" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/47054" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Goodfellas.jpg)'}}></a>
                            <div className="btns"><a href="/titles/47054" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/47054/videos/980880" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/47153" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Just_Cause_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/47153" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/47153/videos/1176585" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/260081" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Kiss_Kiss_Bang_Bang_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/260081" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/260081/videos/1257165" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/74443" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Murder_By_Numbers_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/74443" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/74443/videos/1165630" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/170114" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Mystic_River_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/170114" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/170114/videos/1615833" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48729" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Pelican_Brief_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48729" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/48729/videos/1187389" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48836" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Presumed_Innocent_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48836" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/48836/videos/1247419" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>


                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48908" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Rookie_The.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48908" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/48908/videos/1176582" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/48916" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Seven_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/48916" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/48916/videos/966777" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/227484" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Taking_Lives_27x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/227484" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/227484/videos/1486685" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/898021" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/Town_The_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/898021" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/898021/videos/1557007" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/49632" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/True_Crime_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/49632" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/49632/videos/1281886" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>

                        <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                            <a className="poster" href="/titles/49895" rel="noopener noreferrer" target="_blank" style={{backgroundImage: 'url(https://wbtvd.com/page/crime-package/US_Marshalls_227x304.jpg)'}}></a>
                            <div className="btns"><a href="/titles/49895" className="btn btn-showinfo info_class" rel="noopener noreferrer" target="_blank"><span>&#8227; info</span></a><a href="/titles/49895/videos/979204" className="btn btn-trailer vid_class" rel="noopener noreferrer" target="_blank"><span>&#8227; Trailer</span></a></div>
                        </div>
                    </div>

                    <div id="op_footer" className="row">
                        For more information or to order the Crime Does Pay Programming Package, please email <a href="mailto:Mike.Shackleton@warnerbros.com?subject=Crime%20Does%20Pay%20Programming%20Package">Mike.Shackleton@warnerbros.com</a>
                    </div>
                </div>
            </DocumentTitle>

        );
    }
}
