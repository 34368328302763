/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {useCallback, useMemo} from 'react';

import useQueryParamValues from '../../search-filters/hooks/use-query-param-values';
import useSetSearchFilters from '../../search-filters/hooks/use-set-search-filters';
import {FACET_NAMES} from '../constants';

/**
 * @typedef {typeof import("../types").Facet} Facet
 */

/**
 * @typedef {typeof import("../types").FacetWithGroup} FacetWithGroup
 */

/**
 * @callback OnChange
 * @param {FacetWithGroup[]} currentValue
 * @param {{ action: string, removedValue?: Facet, selectedValue?: Facet }} actionData
 */

/**
 * @returns {{ onChange: OnChange }}
 */
const useTalentCharacterFacet = () => {
    const {setFilter} = useSetSearchFilters();
    const characterValue = useQueryParamValues(FACET_NAMES.CHARACTER);
    const talentValue = useQueryParamValues(FACET_NAMES.TALENT);
    const talentCharacterValue = useQueryParamValues(FACET_NAMES.TALENT_CHARACTER);

    const value = useMemo(() => {
        return ([
            ...characterValue.map(v => ({key: v, group: FACET_NAMES.CHARACTER})),
            ...talentValue.map(v => ({key: v, group: FACET_NAMES.TALENT})),
            ...talentCharacterValue.map(v => ({key: v, group: FACET_NAMES.TALENT_CHARACTER})),
        ]);
    }, [characterValue, talentValue, talentCharacterValue]);

    /**
     * @type {OnChange}
     */
    const onChange = useCallback((currentValue, actionData) => {
        let actionValue;

        if (actionData.action === 'remove-value') {
            actionValue = actionData.removedValue;
        }
        if (actionData.action === 'select-option') {
            actionValue = actionData.option;
        }

        const {group: queryName} = actionValue;
        const queryValue = currentValue.filter(({group}) => group === queryName);
        setFilter(queryName, 'key', true, queryValue);
    }, [setFilter]);

    return {
        onChange,
        value,
    };
};

export default useTalentCharacterFacet;
