/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {GetAttr} from '../../../common/utils/utils';
import Validations from '../../../common/validations/validations';
import {FormItem, FormRow, FormSection} from '../../../form/form';
import TerritorySelect from '../../../lookup/territory/territory-select';
import {MessagesContext} from '../../../messages/messages-context';
import {AlertsWarnings} from '../../../notification/alert-warning';
import {AccountActions} from '../../account-actions';
import AccountStore, {AccountUpdateValidations} from '../../account-store';
import {DeliveryConstants} from '../delivery/delivery-actions';

class AccountUpdate extends React.Component {
    static get propTypes() {
        return {
            account: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            updatedAccount: AccountStore.getState().get('updatedAccount'),
            show: AccountStore.getState().get('showUpdateModal')
        };
    }

    static getStores() {
        return [AccountStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.getHandleSelect = this.getHandleSelect.bind(this);
        this.hide = this.hide.bind(this);
        this.save = this.save.bind(this);
        this.update = this.update.bind(this);

        return;
    }

    static contextType = MessagesContext;

    getHandleSelect(attr) {
        return (value) => {
            AccountActions.updateAttr(attr, value?.id);
        };
    }

    hide() {
        AccountActions.showUpdateModal(false);
    }

    save() {
        let updatedAccountToCompare = this.state.updatedAccount.delete('defaultLanguageCode');
        let accountToCompare = this.props.account.delete('defaultLanguageCode');
        let compareToRequest = updatedAccountToCompare.equals(accountToCompare);

        if (this.state.updatedAccount.get('defaultLanguageCode') !== this.props.account.get('defaultLanguageCode')) {
            AccountActions.changeLanguage(this.state.updatedAccount);
        }

        if (!compareToRequest) {
            AccountActions.requestUpdate(this.state.updatedAccount);
        }

        AccountActions.showUpdateModal(false);
    }

    update(attr, value) {
        if (attr === 'country') {
            value = value?.get('name') || /* istanbul ignore next */'';
        }

        AccountActions.updateAttr(attr, value);
        return;
    }

    render() {
        let account = this.state.updatedAccount;
        let selectedLanguage = DeliveryConstants.EXPORT_LANGUAGES.find((item) => {
            return item.id === account.get('defaultLanguageCode');
        });
        //STUDIO-STUDIO-12294
        let disabled = false;
        let warning;
        let validations = Validations.validate(account, AccountUpdateValidations);
        /* istanbul ignore else */
        if (validations.length) {
            disabled = true;
            warning = (<AlertsWarnings title={this.context.intl.messages['account.tab.info.update.error.msg']} validations={validations} />);
        }

        return (
            <Modal show={this.state.show} onHide={this.hide}>
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="modal-title text-center">{this.context.intl.messages['account.tab.info.update.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {warning}
                        <FormSection title={this.context.intl.messages['account.tab.info.update.msg']}>
                            <FormRow>
                                <FormItem
                                    type="string"
                                    attr="name"
                                    label={this.context.intl.messages['account.tab.info.firstname']}
                                    model={account}
                                    required
                                    setter={this.update}
                                    validations={AccountUpdateValidations.name.validations}
                                />
                                <FormItem
                                    type="string"
                                    attr="lastName"
                                    label={this.context.intl.messages['account.tab.info.lastname']}
                                    model={account}
                                    required
                                    setter={this.update}
                                    validations={AccountUpdateValidations.lastName.validations}
                                />
                            </FormRow>
                            <FormRow>
                                <FormItem
                                    type="string"
                                    attr="email"
                                    label={this.context.intl.messages['account.tab.info.email']}
                                    model={account}
                                    required
                                    setter={this.update}
                                    validations={AccountUpdateValidations.email.validations}
                                />
                            </FormRow>
                            <FormRow>
                                <FormItem
                                    type="string"
                                    attr="company"
                                    label={this.context.intl.messages['account.tab.info.company']}
                                    model={account}
                                    setter={this.update}
                                    validations={AccountUpdateValidations.company.validations}
                                />
                                <FormGroup>
                                    <ControlLabel>{this.context.intl.messages['account.tab.info.language']}</ControlLabel>
                                    <ReactSelect
                                        getOptionLabel={GetAttr('name')}
                                        getOptionValue={GetAttr('id')}
                                        isClearable={false}
                                        isMulti={false}
                                        name="languages"
                                        onChange={this.getHandleSelect('defaultLanguageCode')}
                                        options={DeliveryConstants.EXPORT_LANGUAGES}
                                        value={selectedLanguage}
                                    />
                                </FormGroup>
                            </FormRow>
                            <FormRow>
                                <TerritorySelect
                                    attr="country"
                                    clearable={false}
                                    label={this.context.intl.messages['account.tab.info.country']}
                                    model={account}
                                    name="country"
                                    valueKey="name"
                                    placeholder={this.context.intl.messages['signup.form.label.country.placeholder']}
                                    setter={this.update}
                                />
                            </FormRow>
                        </FormSection>
                    </div>
                </Modal.Body>
                <Modal.Footer className=" padding-all-20">
                    <Button
                        bsStyle="default"
                        className="pull-left btn-secondary"
                        onClick={this.hide}>{this.context.intl.messages['common.close']}</Button>
                    <Button
                        bsStyle="primary"
                        disabled={disabled}
                        onClick={this.save}>{this.context.intl.messages['account.tab.info.update.save_request']}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(AccountUpdate);
export {AccountUpdate};
