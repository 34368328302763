/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Col, Glyphicon, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {MessagesContext} from '../../../../messages/messages-context';
import Preloader from '../../../../preloader';
import {BreakIntoGroups} from '../../../../utils/utils';
import {AssetTitleConstants} from '../../asset-title-actions';
import ImageLoader from '../image-loader';

class ImageThumbnail extends React.Component {
    static get propTypes() {
        return {
            entityName: PropTypes.string.isRequired,
            entityType: PropTypes.oneOf([AssetTitleConstants.ENTITY_TYPE.TITLE, AssetTitleConstants.ENTITY_TYPE.TALENT]).isRequired,
            id: PropTypes.number.isRequired,
            image: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    shouldComponentUpdate(nextProps) {
        if (
            this.props.image !== nextProps.image ||
            this.props.id !== nextProps.id ||
            this.props.entityType !== nextProps.entityType
        ) {
            return true;
        }
        return false;
    }


    render() {

        let imageThumbnail;
        if (this.props.entityType === AssetTitleConstants.ENTITY_TYPE.TITLE) {
            imageThumbnail = (
                <Link to={`/titles/${this.props.id}/images/${this.props.image.get('assetId')}`}>
                    <ImageLoader
                        alt={`${this.props.entityName} - Image ${this.props.image.get('assetId')}`}
                        className="img-responsive"
                        src={this.props.image.get('thumbnailUrl')}
                    />
                </Link>
            );
        } else {
            imageThumbnail = (
                <ImageLoader
                    alt={`${this.props.entityName} - Image ${this.props.image.get('assetId')}`}
                    className="img-responsive"
                    src={this.props.image.get('thumbnailUrl')}
                />
            );
        }
        return (
            <Col xs={6} sm={3} className="thumbnail-container">
                <Preloader
                    bsSize="sm"
                    fixed
                    show={this.props.image.get('thumbnailUrl') === undefined}
                >
                    <div className="responsive-container">
                        <div className="vertical-spacer" />
                        <div className="img-container">
                            {imageThumbnail}
                        </div>
                    </div>
                </Preloader>
            </Col>
        );
    }
}

export default class ImageList extends React.Component {
    static get propTypes() {
        return {
            entityName: PropTypes.string,
            entityType: PropTypes.oneOf([AssetTitleConstants.ENTITY_TYPE.TITLE, AssetTitleConstants.ENTITY_TYPE.TALENT]).isRequired,
            id: PropTypes.number.isRequired,
            images: PropTypes.instanceOf(Immutable.List).isRequired,
            title: PropTypes.string
        };
    }

    static get defaultProps() {
        return {
            entityName: 'title.image-list.title',
            title: 'title.image-list.title'
        };
    }

    static contextType = MessagesContext;

    render() {
        let link;
        if (this.props.entityType === AssetTitleConstants.ENTITY_TYPE.TITLE) {
            link = (
                <Link to={`/titles/${this.props.id}/images`}>
                    <Button bsSize="sm" bsStyle="primary">
                        {this.context.intl.messages['common.view-all']} <Glyphicon glyph="chevron-right" />
                    </Button>
                </Link>
            );
        }

        return (
            <div className="container padding-y-10 padding-x-10">
                <hr />
                <h3>
                    {this.context.intl.messages[this.props.title]}
                    {link}
                </h3>
                <div className="row">
                    {BreakIntoGroups(this.props.images.slice(0, 8), 4).map((imageRow, i) => {
                        return (
                            <Col xs={12} md={6} key={i}>
                                <Row>
                                    {imageRow.map((imageCol, j) => {
                                        return (
                                            <ImageThumbnail
                                                entityName={this.props.entityName}
                                                entityType={this.props.entityType}
                                                id={this.props.id}
                                                image={imageCol}
                                                key={j}
                                            />
                                        );
                                    })}
                                </Row>
                            </Col>
                        );
                    })}
                </div>
            </div>
        );
    }
}
