/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, Grid, Row} from 'react-bootstrap';
import {TransitionGroup} from 'react-transition-group';

import {LandingActions} from './landing-actions';
import LandingStore from './landing-store';
import {MessagesContext} from '../../messages/messages-context';
import {SessionConstants} from '../../session/session-actions';
import SessionStore from '../../session/session-store';

import './landing.less';

const PARTNER = SessionStore.getPartner();

class Landing extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.object.isRequired
        };
    }

    static getStores() {
        return [LandingStore, SessionStore];
    }

    static calculateState() {
        return {
            defaultCopyright: SessionStore.getState().get('defaultCopyright'),
            currentImageIndex: 0,
            loginBackgrounds: LandingStore.getState().get('loginBackgrounds')
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        return;
    }

    componentDidMount() {
        LandingActions.getLoginContent();

        this.changeBackgroundInterval = setInterval(() => {
            this.setState(prevState => {
                let nextIndex = prevState.currentImageIndex + 1;
                if (nextIndex >= prevState.loginBackgrounds.size) {
                    nextIndex = 0;
                }

                return {
                    currentImageIndex: nextIndex
                };
            });
        }, 6000);

        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.children !== nextProps.children) {
            return true;
        }

        return this.state.loginBackgrounds !== nextState.loginBackgrounds ||
            this.state.currentImageIndex !== nextState.currentImageIndex;
    }

    componentWillUnmount() {
        clearInterval(this.changeBackgroundInterval);
        return;
    }

    static contextType = MessagesContext;

    render() {
        /* istanbul ignore next */
        const backgroundImages = this.state.loginBackgrounds.map((image, i) => {
            let style = {
                backgroundImage: `url(${image.get('url')})`,
            };
            let currentImage = this.state.currentImageIndex;

            let nextImage = 0;
            if (this.state.currentImageIndex + 1 !== this.state.loginBackgrounds.size) {
                nextImage = this.state.currentImageIndex + 1;
            }

            return (
                <div key={i} style={style}
                    className={ClassNames(
                        'bgimage',
                        {
                            'current-image': currentImage === i,
                            'next-image': nextImage === i
                        }
                    )}
                />
            );
        });
        let partnerLogo;
        /* istanbul ignore next */
        if (PARTNER.isBrandActive) {
            partnerLogo = PARTNER.brandLogo;
        } else {
            partnerLogo = PARTNER.logo;
        }

        return (
            <div className="login-page Pos(a) B(0) L(0) R(0) T(0)">
                <div id="wrapper-top" role="presentation">
                    <div className="content">
                        <Grid className="login-form-wrapper">
                            <header className="row text-center col-sm-6 col-sm-offset-3" aria-labelledby="title">
                                <h1>
                                    <span className="sr-only" id="title">
                                        {this.context.intl.messages['login.brand.name']}
                                    </span>
                                    <img
                                        alt=""
                                        src={partnerLogo}
                                        width="60%"
                                    />
                                </h1>
                            </header>
                            <main className="form row" aria-label="Login Form">
                                <TransitionGroup
                                    transitionName="fadeDown"
                                    transitionEnterTimeout={300}
                                    transitionLeaveTimeout={300}>
                                    {this.props.children}
                                </TransitionGroup>
                            </main>
                            <footer aria-label="Copyrights, Terms of Use, and Privacy Policy">
                                <Row>
                                    <Col className="login-footer" sm={4} smOffset={4}>{this.state.defaultCopyright}</Col>
                                </Row>
                                <div className="text-center">
                                    <a
                                        className="margin-x-10"
                                        style={{color: '#dddddd', fontSize: '11px'}}
                                        href={SessionConstants.EXTERNAL_URLS.HELP}
                                        target="_blank">
                                        {this.context.intl.messages['login.help']}
                                    </a>
                                    <a
                                        className="margin-x-10"
                                        style={{color: '#dddddd', fontSize: '11px'}}
                                        href={SessionConstants.EXTERNAL_URLS.TERMS_OF_USE}
                                        target="_blank">
                                        {this.context.intl.messages['login.terms-of-use']}
                                    </a>
                                    <a
                                        className="margin-x-10"
                                        style={{color: '#dddddd', cursor: 'pointer', fontSize: '11px'}}
                                        href={SessionConstants.EXTERNAL_URLS.PRIVACY_POLICY}
                                        target="_blank">
                                        Privacy Policy
                                    </a>
                                </div>
                            </footer>
                        </Grid>
                    </div>
                    <div className="bgimages">
                        {backgroundImages}
                    </div>
                </div>
            </div>
        );
    }
}

export default Container.create(Landing);
export {Landing};
