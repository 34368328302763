/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import {DropdownButton, MenuItem} from 'react-bootstrap';

import {AssetTitleActions} from './asset-title-actions';
import {AssetTypeConstants} from '../../asset-types/asset-type-constants';
import {MessagesContext} from '../../messages/messages-context';
import {OrderActions} from '../../orders/order-actions';
import {IsNotTriggerKey} from '../../utils/utils';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../styles/app/dataTables.brainiac.css');

// Load jQuery and register the datatables plugin.
const jQuery = require('jquery');
require('datatables.net-responsive-bs');

class ListViewTableImage extends Component {
    static get propTypes() {
        return {
            canAddToCart: PropTypes.bool,
            hasRestrictedAuth: PropTypes.bool.isRequired,
            images: PropTypes.instanceOf(Immutable.Map).isRequired,
            imageSuperType: PropTypes.string.isRequired,
            location: PropTypes.object.isRequired,
            onSortChange: PropTypes.func.isRequired,
            sectionImageCount: PropTypes.number.isRequired,
            title: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get defaultProps() {
        return {
            canAddToCart: false
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            id: Math.ceil(Math.random() * 100000),
            showSort: false
        };

        this.handleSelectSort = this.handleSelectSort.bind(this);
        this.handleToggleSort = this.handleToggleSort.bind(this);
        this.updateRows = this.updateRows.bind(this);
        this.getImage = this.getImage.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery(`#list-documents-table-${this.state.id}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }],
            iDisplayLength: 1,
            info: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        this.updateRows(this.props);
        return;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.canAddToCart !== this.props.canAddToCart ||
            nextProps.title !== this.props.title ||
            nextProps.images !== this.props.images ||
            nextState.showSort !== this.state.showSort;
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.updateRows(nextProps);
        return;
    }

    static contextType = MessagesContext;

    handleSelectSort(eventKey) {
        let sortFilter = eventKey.split(':');
        this.props.onSortChange(sortFilter[0], sortFilter[1]);
        return;
    }

    handleToggleSort() {
        this.setState(prevState => ({
            showSort: !prevState.showSort
        }));

        return;
    }

    updateRows(props) {
        this.$tableAPI.clear();
        if (props.images) {
            props.images.get('results').forEach(image => {
                let assetName = image.get('name');
                if (image.get('childStackAssetCount') > 0 ) {
                    assetName += ` (+${image.get('childStackAssetCount')})`;
                }
                let row = [
                    `<a data-image-id="${image.get('assetId')}" href="#"><span class="glyphicon glyphicon-picture show-image"></span></a>`,
                    `<a data-image-id="${image.get('assetId')}" href="#"><span class="show-image">${assetName}</span></a>`,
                    this.context.intl.messages[`asset.image.content-types.${image.get('contentType')}`],
                    ''
                ];
                if (props.canAddToCart) {
                    let addToCart;
                    if (image.get('deliveryType') !== AssetTypeConstants.DELIVERY_TYPES.NEEDS_APPROVAL || this.props.hasRestrictedAuth) {
                        addToCart = `<a data-image-id="${image.get('assetId')}" href="#"><span class="glyphicon glyphicon-shopping-cart image-overlay-cart"></span></a>`;

                        if (image.get('childStackAssetCount') > 0 ) {
                            addToCart =`
                                <a href="#" data-toggle="dropdown" class="dropdown-toggle"><span class="glyphicon glyphicon-shopping-cart"></span></a>
                                <ul class="dropdown-menu dropdown-download-menu">
                                    <li><a data-image-id="${image.get('assetId')}" class="tooltip-link image-overlay-cart ANETA">${this.context.intl.messages['cart.add.image.single']}</a></li>
                                    <li><a data-image-id="${image.get('assetId')}" class="tooltip-link image-overlay-cart image-overlay-cart-all OTHERANETA">${this.context.intl.messages['cart.add.image.multi']}</a></li>
                                </ul>
                            `;
                        }
                    } else {
                        addToCart = `<a data-image-id="${image.get('assetId')}" href="#" disabled><span class="glyphicon glyphicon-ban-circle image-overlay-cart"></span></a>`;
                    }

                    row.splice(2, 0, addToCart);
                }
                this.$tableAPI.row.add(row);
                return;
            });
        }
        this.$tableAPI.draw(false);
        return;
    }

    getImage(event) {
        let imageId = parseInt(event.target.parentNode.getAttribute('data-image-id'), 10);
        return this.props.images.get('results').find(v => v.get('assetId') === imageId);
    }

    /**
     * This function is kind of "special" because it needs to handle
     * events bubbled from the data table rows, these rows cannot use
     * the JSX syntax because they are created by the data table
     * jQuery plugin instead of React.
     */
    handleRowClick(event) {
        if (event && IsNotTriggerKey(event)) {
            return;
        }

        switch (true) {
        case !!~event.target.className.indexOf('show-image'):
            event.preventDefault();
            AssetTitleActions.requestUrlsAndOpenGallery(this.props.images.get('results'), this.getImage(event));
            break;
        case !!~event.target.className.indexOf('image-overlay-cart'):
            event.preventDefault();
            OrderActions.add(AssetTypeConstants.ASSET_TYPE.IMAGE, this.props.title.get('id'), [this.getImage(event)], event.target.className.indexOf('image-overlay-cart-all') !== -1);
            break;
        }

        return;
    }

    render() {
        const query = QueryString.parse(this.props.location.search);
        let sortDropdown = (
            <DropdownButton
                bsStyle="default"
                className="videos-sort-button"
                onToggle={this.handleToggleSort}
                open={this.state.showSort}
                onSelect={this.handleSelectSort}
                title="Sort"
            >
                <MenuItem eventKey="relevance:asc" active={(query['sort-field-name'] === 'relevance')} >Relevance</MenuItem>
                <MenuItem eventKey="createdDate:asc" active={(query['sort-field-name'] === 'createdDate') && (query['sort-direction'] === 'asc')}>Date Added Ascending</MenuItem>
                <MenuItem eventKey="createdDate:desc" active={(query['sort-field-name'] === 'createdDate') && (query['sort-direction'] === 'desc')}>Date Added Descending</MenuItem>
            </DropdownButton>
        );

        return (
            <div className="display-results display-results-table">
                <h3>{this.context.intl.messages[`title.images.content-types.${this.props.imageSuperType}`]}&nbsp;<small>({this.props.sectionImageCount}){sortDropdown}</small></h3>
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                    <table className="table table-striped datatable-responsive dataTable no-footer dtr-column collapsed" id={`list-documents-table-${this.state.id}`}>
                        <thead>
                            <tr role="row">
                                <th scope="col" className="icon-column" rowSpan={1} colSpan={1}></th>
                                <th scope="col" className="" rowSpan={1} colSpan={1}>Image Name</th>
                                {this.props.canAddToCart && <th scope="col" className="icon-column" rowSpan={1} colSpan={1}><span className="glyphicon glyphicon-shopping-cart"></span></th>}
                                <th scope="col" className="" rowSpan={1} colSpan={1}>Type</th>
                                <th scope="col" className="control icon-column" rowSpan={1} colSpan={1} ></th>
                            </tr>
                        </thead>
                        <tbody
                            onClick={this.handleRowClick}
                            onKeyUp={this.handleRowClick}
                            role="button"
                            tabIndex="0"
                        ></tbody>
                    </table>
                </div>
            </div>
        );
    }

}

export default ListViewTableImage;
