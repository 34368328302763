/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained here./thumbnailsn is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {MessagesContext} from '../../messages/messages-context';
import TitleTypes from '../../search/title-types';
import {TitleConstants} from '../../titles/title-actions';
import {FormatReleaseDate} from '../../utils/utils';
import {ImageItem} from '../sections/list/thumbnails';


class Thumbnail extends React.PureComponent {
    static get propTypes() {
        return {
            item: PropTypes.instanceOf(Immutable.Map),
        };
    }

    static get defaultProps() {
        return {
            item: Immutable.Map()
        };
    }

    static contextType = MessagesContext;

    render() {
        if (this.props.item.isEmpty()) {
            return null;
        }

        let src = this.props.item.get('defaultImageHorizontalPreviewUrl');
        if (!this.props.item.get('defaultImageHorizontalPreviewUrl')) {
            src = this.props.item.get('defaultImageHorizontalThumbnailUrl');
        }

        const titleType = TitleTypes.find(type => parseInt(type.id) === this.props.item.get('category')) || {};

        let titleReleaseDate = '-';
        if (this.props.item.get('titleReleaseDateType') === TitleConstants.RELEASE_DATE_TYPES.DISPLAY_NAME.id) {
            titleReleaseDate = FormatReleaseDate(this.props.item.get('titleReleaseDateDisplayName'), this.props.item.get('titleReleaseDateType'));
        } else {
            titleReleaseDate = FormatReleaseDate(Moment(this.props.item.get('titleReleaseDate')), this.props.item.get('titleReleaseDateType'));
        }

        return (
            <Col className="thumbnail-container" sm={6} xs={12}>
                <Link
                    aria-label={this.props.item.get('displayName')}
                    className="thumbnail-link video-thumbnail"
                    to={`/titles/${this.props.item.get('id')}`}
                >
                    <ImageItem src={src} />
                </Link>
                <div className="padding-y-10">
                    <h4>
                        <Link
                            aria-label={this.props.item.get('displayName')}
                            className="thumbnail-link video-thumbnail"
                            to={`/titles/${this.props.item.get('id')}`}
                        >
                            {this.props.item.get('displayName')}
                        </Link>
                        &nbsp;<small>{titleType.value}&nbsp;|&nbsp;{this.context.intl.messages['homepage-titles.title.premiere']}:&nbsp;{titleReleaseDate}</small>
                    </h4>
                    <small><p dangerouslySetInnerHTML={{__html: this.props.item.get('shortSynopsis')}} /></small>
                </div>
            </Col>
        );
    }
}

export default Thumbnail;
