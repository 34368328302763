/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {MultipleTitlesConstants} from './multiple-titles-actions';
import {Dispatcher} from '../../flux-helpers';


class MultipleTitlesStore extends ReduceStore {
    getInitialState() {
        return Immutable.Map({
            titles: Immutable.List(),
            total: 0,
            offset: 0,
            size: 20
        });
    }

    reduce(state, action) {
        switch (action.actionType) {
        case MultipleTitlesConstants.GET.SUCCESS:
            state = state.merge({
                titles: action.titles,
                offset: action.offset,
                size: action.size,
                total: action.total
            });
            break;
        case MultipleTitlesConstants.TITLE.SET:
            const index = state.get('titles').findIndex(t => t.get('id') === action.title.get('id'));
            state = state.mergeIn(['titles', index], {
                defaultImageHorizontalPreviewUrl: action.title.get('defaultImageHorizontalPreviewUrl'),
                defaultImageHorizontalThumbnailUrl: action.title.get('defaultImageHorizontalThumbnailUrl'),
                shortSynopsis: action.title.get('shortSynopsis')
            });
            break;
        }
        return state;
    }
}

export default new MultipleTitlesStore(Dispatcher);
