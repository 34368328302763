/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import LoadingCard from '../../search/result/loading-card';

const ClipsGridLoading = () => {
    return [0, 1].map((item) => (
        <div className="row clips-grid-row" key={item}>
            <div className="col-md-6">
                <div className="row">
                    <LoadingCard className="col-sm-6" ratio="16x9" />
                    <LoadingCard className="col-sm-6" ratio="16x9" />
                </div>
            </div>
            <div className="clearfix visible-xs"/>
            <div className="col-md-6">
                <div className="row">
                    <LoadingCard className="col-sm-6" ratio="16x9" />
                    <LoadingCard className="col-sm-6" ratio="16x9" />
                </div>
            </div>
        </div>
    ));
};

export default ClipsGridLoading;
