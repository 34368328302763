/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import React from 'react';

import DocumentTitle from '~/src/common/document-title';

import './network-45.less';

export default class Network45 extends React.Component {
    constructor(props) {
        super(props);

        return;
    }

    render() {
        return (
            <DocumentTitle message="static.page.title.network45">
                <div className="container op_container_network_45">
                    <div id="op_header" className="row">
                        <div id="network45-header-img">
                            <div id="header-btns">
                                <a
                                    className="btn-trailer btn-header reel"
                                    href="/titles/984814/videos/1733167"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <img
                                        src="https://www.wbtvd.com/page/network45/btn_silver_reel.png"
                                        alt=""
                                    />
                                </a>
                                <a
                                    className="btn-header avail"
                                    href="https://www.wbtvd.com/page/network45/NETWORK45_AVAILS_BOOKLET_VER_A_50_TITLES_FNL.pdf"
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    <img
                                        src="https://www.wbtvd.com/page/network45/btn_silver_avail.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>

                        <div id="op_content" className="row clearfix">
                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1590055"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/33_The_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_33.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1590055/videos/1717946"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1529370"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/American_Sniper_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/American_Sniper.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1529370/videos/1559968"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1506134"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Annabelle_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Annabelle.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1506134/videos/1523331"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1201534"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Argo_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Argo.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1201534/videos/1313843"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1519095"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Black_Mass_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Black_Mass.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1519095/videos/1623107"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1324930"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Conjuring_The_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_Conjuring.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1324930/videos/1401291"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1138739"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Dark_Shadows_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Dark_Shadows.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1138739/videos/1284621"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1405303"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Dolphin_Tale_2_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Dolphin_Tale_2.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1405303/videos/1512939"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1385888"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Edge_of_Tomorrow_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Edge_of_Tomorrow.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1385888/videos/1498976"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1490510"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Entourage_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Entourage.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1490510/videos/1582454"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1490513"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Focus_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Focus.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1490513/videos/1559714"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1555460"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Gallows_The_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_Gallows.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1555460/videos/1603642"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1490516"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Get_Hard_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Get_Hard.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1490516/videos/1562392"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1343779"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Godzilla_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Godzilla.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1343779/videos/1557013"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1143929"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Gravity_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Gravity.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1143929/videos/1405233"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1243434"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Great_Gatsby_The_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_Great_Gatsby.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1243434/videos/1367661"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1363753"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Grudge_Match_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Grudge_Match.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1363753/videos/1561951"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1405300"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Her_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Her.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1405300/videos/1588615"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1281496"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Hobbit_Unexpected_Journey_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Hobbit_An_Unexpected_Journey.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1281496/videos/1747770"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1328096"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Hobbit_Battle_Five_Armies_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Hobbit_The_Battle_of_the_Five_Armies.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1328096/videos/1523886"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1281499"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Hobbit_Desolation_Smaug_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Hobbit_The_Desolation_of_Smaug.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1281499/videos/1398988"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1488470"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Horrible_Bosses_2_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Horrible_Bosses_2.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1488470/videos/1536515"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1488414"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/If_I_Stay_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/If_I_Stay.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1488414/videos/1499125"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1490519"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Heart_of_the_sea_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/In_The_Heart_of_the_Sea.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1490519/videos/1696370"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1488473"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Inherent_Vice_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Inherent_Vice.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1488473/videos/1717955"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1490522"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Intern_The_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_Intern.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1490522/videos/1601903"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1417552"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Into_the_Storm_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Into_The_Storm.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1417552/videos/1717956"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1137536"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Jack_The_Giant_Slayer_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Jack_The_Giant_Slayer.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1137536/videos/1490703"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/969608"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Journey_2_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Journey_2_The_Mysterious_Island.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/969608/videos/1435052"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1405306"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Judge_The_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_Judge.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1405306/videos/1532853"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1385900"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Jupiter_Ascending_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Jupiter_Ascending.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1385900/videos/1717958"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1143932"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Lucky_One_The_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_Lucky_One.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1143932/videos/1557630"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1433548"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Mad_Max_Fury_Road_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Mad_Max_Fury_Road.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1433548/videos/1602436"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1504885"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Magic_Mike_XXL_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Magic_Mike_XXL.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1504885/videos/1595558"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1490530"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Man_From_UNCLE_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_Man_From_Uncle.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1490530/videos/1717962"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1666307"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Our_Brand_is_Crisis_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Our_Brand_Is_Crisis.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1666307/videos/1717963"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1490542"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/PAN_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Pan.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1490542/videos/1616374"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1506627"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Point_Break_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Point_Break.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1506627/videos/1696204"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/974061"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Project_X_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Project_X.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/974061/videos/1673761"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1490536"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Run_all_Night_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Run_All_Night.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1490536/videos/1717964"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1490539"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/San_Andreas_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/San_Andreas.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1490539/videos/1602440"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/974066"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Sherlock_Holmes_Game_of_Shadows_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Sherlock_Holmes_A_Game_of_Shadows.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/974066/videos/1211122"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1385908"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Tammy_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Tammy.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1385908/videos/1512191"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1488479"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/This_Is_Where_I_Leave_You_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/This_Is_Where_I_Leave_You.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1488479/videos/1717965"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/589065"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Time_Travelers_Wife_The_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_Time_Travelers_Wife.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/589065/videos/1670581"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/898021"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Town_The_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/The_Town.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/898021/videos/1557007"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1385911"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Trancendence.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Transcendence.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1385911/videos/1717967"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1504992"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Vacation_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Vacation.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1504992/videos/1605638"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1451821"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/Veronica_Mars_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/Veronica_Mars.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1451821/videos/1526103"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>

                            <div className="content_container col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                <a
                                    className="poster"
                                    href="/titles/1590052"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{backgroundImage: 'url(https://www.wbtvd.com/page/network45/We_Are_Your_Friends_300x383.jpg)'}}></a>
                                <div className="btns">
                                    <a
                                        href="https://www.wbtvd.com/page/network45/We_Are_Your_Friends.pdf"
                                        className="btn btn-pdf"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; PDF</span>
                                    </a>
                                    <a
                                        href="/titles/1590052/videos/1717970"
                                        className="btn btn-trailer vid_class"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        <span>&#8227; Trailer</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p id="demo"></p>
                </div>
            </DocumentTitle>

        );
    }
}
